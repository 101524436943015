/**
 * @generated SignedSource<<bf3755524dc5812d825c561e79eb1eca>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SkillFormAssociatedSkills_AssociatedSkills_Mutation$variables = {
  newChildSkillIDs?: ReadonlyArray<string> | null | undefined;
  newSupersedingSkillIDs?: ReadonlyArray<string> | null | undefined;
  skillID: string;
};
export type SkillFormAssociatedSkills_AssociatedSkills_Mutation$data = {
  readonly changeAssociatedSkills: {
    readonly " $fragmentSpreads": FragmentRefs<"SkillFormAssociatedSkills_skill">;
  } | null | undefined;
};
export type SkillFormAssociatedSkills_AssociatedSkills_Mutation = {
  response: SkillFormAssociatedSkills_AssociatedSkills_Mutation$data;
  variables: SkillFormAssociatedSkills_AssociatedSkills_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "newChildSkillIDs"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "newSupersedingSkillIDs"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "skillID"
},
v3 = [
  {
    "kind": "Variable",
    "name": "newChildSkillIDs",
    "variableName": "newChildSkillIDs"
  },
  {
    "kind": "Variable",
    "name": "newSupersedingSkillIDs",
    "variableName": "newSupersedingSkillIDs"
  },
  {
    "kind": "Variable",
    "name": "skillID",
    "variableName": "skillID"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "nodeID",
  "storageKey": null
},
v6 = [
  (v4/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  },
  (v5/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "SkillFormAssociatedSkills_AssociatedSkills_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "Skill",
        "kind": "LinkedField",
        "name": "changeAssociatedSkills",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "SkillFormAssociatedSkills_skill"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "SkillFormAssociatedSkills_AssociatedSkills_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "Skill",
        "kind": "LinkedField",
        "name": "changeAssociatedSkills",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Skill",
            "kind": "LinkedField",
            "name": "supersedingSkills",
            "plural": true,
            "selections": (v6/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Skill",
            "kind": "LinkedField",
            "name": "childSkills",
            "plural": true,
            "selections": (v6/*: any*/),
            "storageKey": null
          },
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "6debe48658a9f4bc316ff25c18093c6f",
    "id": null,
    "metadata": {},
    "name": "SkillFormAssociatedSkills_AssociatedSkills_Mutation",
    "operationKind": "mutation",
    "text": "mutation SkillFormAssociatedSkills_AssociatedSkills_Mutation(\n  $skillID: ID!\n  $newChildSkillIDs: [ID!]\n  $newSupersedingSkillIDs: [ID!]\n) {\n  changeAssociatedSkills(skillID: $skillID, newChildSkillIDs: $newChildSkillIDs, newSupersedingSkillIDs: $newSupersedingSkillIDs) {\n    ...SkillFormAssociatedSkills_skill\n    nodeID\n  }\n}\n\nfragment SkillFormAssociatedSkills_skill on Skill {\n  id\n  supersedingSkills {\n    id\n    name\n    nodeID\n  }\n  childSkills {\n    id\n    name\n    nodeID\n  }\n}\n"
  }
};
})();

(node as any).hash = "aa8786beff25c6570f855b4dfc6b393d";

export default node;
