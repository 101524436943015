import TocIcon from '@material-ui/icons/Toc';

import CurriculumList from './CurriculumList';
import CurriculumEdit from './CurriculumEdit';
import CurriculumCreate from './CurriculumCreate';

export default {
  list: CurriculumList,
  edit: CurriculumEdit,
  icon: TocIcon,
  create: CurriculumCreate,
};
