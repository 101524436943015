import * as MUI from '@material-ui/core';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import VolumeUpIcon from '@material-ui/icons/VolumeUp';
import React, { useMemo } from 'react';

import bvlogo from '../../src/assets/boldvoice_logo.png';
import salogo from '../../src/assets/speechace_logo.png';
import api from '../api';
import { getScaleColor } from '../gen_conv/GenConvShow';
import AudioLogAccordionPhoneScoreList from './AudioLogAccordionPhoneScoreList';

function AudioLogAccordion({ audioFile, modelName, score, results }) {
  const [isPlaying, setIsPlaying] = React.useState(false);
  const audioRef = React.useRef<HTMLAudioElement | null>(null);

  const { word_score_list: wordScoreList } =
    useMemo(() => {
      if (!results) return null;

      const isObject = typeof results === 'object';
      if (isObject) return results;

      try {
        const parsedData = JSON.parse(results);
        return parsedData;
      } catch (err) {
        return null;
      }
    }, [results]) ?? {};

  const playAudio = async (s3Filename: string) => {
    const signedUrlResponse = await api.getCloudFrontSignedUrl(
      s3Filename,
      'useraudio',
      null,
    );
    const { signedUrl } = signedUrlResponse.json;

    const audio = new Audio(signedUrl);
    setIsPlaying(true);
    audioRef.current = audio;
    audio.play();
    audio.onended = () => {
      setIsPlaying(false);
      audio.remove();
    };
  };

  const pauseAudio = () => {
    if (!audioRef.current) return;
    setIsPlaying(false);
    audioRef.current?.pause();
    audioRef.current?.remove();
    audioRef.current = null;
  };

  const parsedAudioFile = audioFile.replace('useraudio/', '');
  const onClickAudio = (event) => {
    event.stopPropagation();
    if (isPlaying) {
      pauseAudio();
    } else {
      playAudio(parsedAudioFile);
    }
  };

  return (
    <Accordion TransitionProps={{ unmountOnExit: true }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="content"
        id="header"
      >
        <MUI.Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          flex={1}
          gridColumnGap={16}
        >
          {parsedAudioFile ? (
            <MUI.Button onClick={onClickAudio} variant="outlined">
              {isPlaying ? (
                <MUI.CircularProgress
                  size={30}
                  style={{
                    color: 'green',
                    position: 'absolute',
                    top: 7,
                    right: 16,
                    zIndex: 1,
                  }}
                />
              ) : null}
              <VolumeUpIcon
                style={{
                  fontSize: 24,
                  padding: 5,
                  cursor: 'pointer',
                  zIndex: 2,
                }}
              />
            </MUI.Button>
          ) : null}

          <MUI.Box flex={1} display="flex" flexDirection="row" flexWrap="wrap">
            {wordScoreList.map(
              ({ word, quality_score: wordQualityScore }, index) => (
                <MUI.Typography
                  key={index}
                  style={{
                    color: getScaleColor(wordQualityScore),
                  }}
                  variant="body1"
                >
                  {word}&nbsp;
                </MUI.Typography>
              ),
            )}
          </MUI.Box>

          <MUI.Box
            display="flex"
            flexDirection="row"
            gridColumnGap={6}
            justifyContent="center"
            alignItems="center"
          >
            <MUI.Typography
              style={{ color: getScaleColor(score) }}
              variant="body1"
            >
              {score}%
            </MUI.Typography>
            <img
              alt="logo"
              src={modelName === 'boldvoice' ? bvlogo : salogo}
              style={{ height: 20 }}
            />
          </MUI.Box>
        </MUI.Box>
      </AccordionSummary>
      <AccordionDetails>
        <AudioLogAccordionPhoneScoreList wordScoreList={wordScoreList} />
      </AccordionDetails>
    </Accordion>
  );
}

export default AudioLogAccordion;
