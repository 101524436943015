/**
 * @generated SignedSource<<c132cfd56b55f1124db1e3564cc979a7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UserSelect_allUsers_RefetchQuery$variables = {
  searchText?: string | null | undefined;
};
export type UserSelect_allUsers_RefetchQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"UserSelect_allUsers">;
};
export type UserSelect_allUsers_RefetchQuery = {
  response: UserSelect_allUsers_RefetchQuery$data;
  variables: UserSelect_allUsers_RefetchQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": "",
    "kind": "LocalArgument",
    "name": "searchText"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "searchText",
    "variableName": "searchText"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UserSelect_allUsers_RefetchQuery",
    "selections": [
      {
        "args": (v1/*: any*/),
        "kind": "FragmentSpread",
        "name": "UserSelect_allUsers"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UserSelect_allUsers_RefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "QueryAllUsersConnection",
        "kind": "LinkedField",
        "name": "allUsers",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "nodes",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "email",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "firstName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "lastName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "language",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "nodeID",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d159b3d0f0330fb8de71a5e1277034b3",
    "id": null,
    "metadata": {},
    "name": "UserSelect_allUsers_RefetchQuery",
    "operationKind": "query",
    "text": "query UserSelect_allUsers_RefetchQuery(\n  $searchText: String = \"\"\n) {\n  ...UserSelect_allUsers_nhk5B\n}\n\nfragment UserSelect_allUsers_nhk5B on Query {\n  allUsers(searchText: $searchText) {\n    nodes {\n      id\n      email\n      firstName\n      lastName\n      language\n      nodeID\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "528fa370f3de4595cdc7da32295314a1";

export default node;
