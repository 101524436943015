/**
 * @generated SignedSource<<1b03e9f95c6f187f16c6873d25298e53>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MultipleChoiceQuestionsSelect_multipleChoiceQuestionsForString_RefetchQuery$variables = {
  search?: string | null | undefined;
};
export type MultipleChoiceQuestionsSelect_multipleChoiceQuestionsForString_RefetchQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"MultipleChoiceQuestionsSelect_multipleChoiceQuestionsForString">;
};
export type MultipleChoiceQuestionsSelect_multipleChoiceQuestionsForString_RefetchQuery = {
  response: MultipleChoiceQuestionsSelect_multipleChoiceQuestionsForString_RefetchQuery$data;
  variables: MultipleChoiceQuestionsSelect_multipleChoiceQuestionsForString_RefetchQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": "",
    "kind": "LocalArgument",
    "name": "search"
  }
],
v1 = {
  "kind": "Variable",
  "name": "search",
  "variableName": "search"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "MultipleChoiceQuestionsSelect_multipleChoiceQuestionsForString_RefetchQuery",
    "selections": [
      {
        "args": [
          (v1/*: any*/)
        ],
        "kind": "FragmentSpread",
        "name": "MultipleChoiceQuestionsSelect_multipleChoiceQuestionsForString"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "MultipleChoiceQuestionsSelect_multipleChoiceQuestionsForString_RefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Literal",
            "name": "first",
            "value": 20
          },
          (v1/*: any*/)
        ],
        "concreteType": "QueryMultipleChoiceQuestionsForStringConnection",
        "kind": "LinkedField",
        "name": "multipleChoiceQuestionsForString",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "MultipleChoiceQuestion",
            "kind": "LinkedField",
            "name": "nodes",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "internalName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "nodeID",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "0e09ecd94dcba651df3c8e552f996b5b",
    "id": null,
    "metadata": {},
    "name": "MultipleChoiceQuestionsSelect_multipleChoiceQuestionsForString_RefetchQuery",
    "operationKind": "query",
    "text": "query MultipleChoiceQuestionsSelect_multipleChoiceQuestionsForString_RefetchQuery(\n  $search: String = \"\"\n) {\n  ...MultipleChoiceQuestionsSelect_multipleChoiceQuestionsForString_40zwac\n}\n\nfragment MultipleChoiceQuestionsSelect_multipleChoiceQuestionsForString_40zwac on Query {\n  multipleChoiceQuestionsForString(search: $search, first: 20) {\n    nodes {\n      id\n      internalName\n      nodeID\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "b57ff1a2af8a758134e36407d2404902";

export default node;
