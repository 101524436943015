import * as React from 'react';
import { Datagrid, List, TextField, ListProps } from 'react-admin';
import { FC } from 'react';
import { Link, FieldProps, Filter, TextInput } from 'react-admin';

import { ReactElement } from 'react';
import useDynamicPhospherIcon from './useDynamicPhospherIcon';

const TopicConfigLinkField: FC<FieldProps> = (props) =>
  props.record ? (
    <Link
      to={`/content/generative_conversation/topic_config/${props.record.id}`}
      style={{
        alignItems: 'center',
      }}
    >
      {props.record.title
        ? props.record.title
        : props.record.icon
        ? ''
        : props.record.conversation_topic}
    </Link>
  ) : null;

const IconField: FC<FieldProps> = (props) => {
  const PreviewIcon = useDynamicPhospherIcon(props?.record?.icon);
  if (!PreviewIcon) return null;
  return PreviewIcon ? (
    <Link
      to={`/content/generative_conversation/topic_config/${props?.record?.id}`}
      style={{
        alignItems: 'center',
        ...(props?.record?.title ? { color: 'black' } : {}),
      }}
    >
      <PreviewIcon size={30} />
    </Link>
  ) : null;
};

const TopicConfigFilter = (props: any) => (
  <Filter {...props}>
    <TextInput label="Title" source="title" defaultValue="" />
    <TextInput
      label="Conversation Topic"
      source="conversation_topic"
      defaultValue=""
    />
    <TextInput label="Category" source="category" defaultValue="" />
    <TextInput label="Counterparty" source="counterparty" defaultValue="" />
    <TextInput label="Location" source="location" defaultValue="" />
    <TextInput label="Level" source="level" defaultValue="" placeholder="1" />
    <TextInput
      label="Difficulty"
      source="difficulty"
      defaultValue=""
      placeholder="1"
    />
  </Filter>
);

const TopicConfigList = (props: ListProps): ReactElement => (
  <List
    {...props}
    perPage={100}
    filters={<TopicConfigFilter />}
    sort={{ field: 'level', order: 'ASC' }}
  >
    <Datagrid optimized>
      <IconField />
      <TopicConfigLinkField label="Title" />
      <TextField label="Conversation Topic" source="conversation_topic" />
      <TextField label="Partner" source="counterparty" />
      <TextField label="Location" source="location" />
      <TextField label="Category" source="category" />
      <TextField source="level" label="Level" />
      <TextField source="difficulty" label="Difficulty" />
    </Datagrid>
  </List>
);

export default TopicConfigList;
