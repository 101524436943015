import * as React from 'react';
import { FC } from 'react';
import { Link, FieldProps } from 'react-admin';

import SongNameField from './SongNameField';

const SongLinkField: FC<FieldProps> = (props) =>
  props.record ? (
    <Link to={`/content/song/${props.record.songId}`}>
      <SongNameField {...props} />
    </Link>
  ) : null;

SongLinkField.defaultProps = {
  source: 'songId',
  label: 'Song',
  addLabel: true,
};

export default SongLinkField;
