import { TimelineAction, TimelineRow } from '@xzdarcy/react-timeline-editor';
import React, { useEffect, useState } from 'react';
import api from '../../api';

export default function InteractableFactory({
  action,
  row,
}: {
  action: TimelineAction & any;
  row: TimelineRow;
}) {
  switch (row.id) {
    case 'Practice':
      return (
        <div
          style={{
            backgroundColor: 'red',
          }}
        >
          {action?.data?.word}
        </div>
      );
    case 'VideoTableGraphics':
      return (
        <div
          style={{
            backgroundColor: 'blue',
          }}
        >
          Name:
          {action?.data?.headers.reduce(
            (acc: any, item: any) => `${acc} ${item.title}`,
            '',
          )}
        </div>
      );
    case 'VideoMultipleChoice':
      return <div>VideoMultipleChoice</div>;
    case 'VideoTextWithHighlights':
      return (
        <div
          style={{
            backgroundColor: 'orange',
          }}
        >
          Text Highlight: {action?.data?.text}
        </div>
      );
    case 'VideoImage':
      return (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
          }}
        >
          <VideoImagePreview action={action} />
        </div>
      );
    case 'VideoMouthDiagramSet':
      return (
        <div
          style={{
            backgroundColor: 'green',
          }}
        >
          {action?.data.mouthDiagramSet.publicName}&nbsp; (
          {action?.data.mouthDiagramSet.internalName})
        </div>
      );

    default:
      break;
  }
  return <div>unknown</div>;
}

function VideoImagePreview({ action }: { action: any }) {
  const [signedUrl, setSignedUrl] = useState();

  useEffect(() => {
    (async () => {
      if (action?.data?.url === undefined) return;
      const response = await api.getContentImage(action?.data?.url);
      setSignedUrl(response.json.signedUrl);
    })();
  }, []);

  return (
    <img
      src={action?.data?.url.startsWith('http') ? action?.data?.url : signedUrl}
      alt="action item"
      style={{ width: '100%', objectFit: 'contain', height: '100%' }}
    />
  );
}
