/**
 * @generated SignedSource<<c1bc8723189bf93ff809636ba0f7811e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SkillFormAssociatedSkills_skill$data = {
  readonly childSkills: ReadonlyArray<{
    readonly id: string | null | undefined;
    readonly name: string | null | undefined;
  }> | null | undefined;
  readonly id: string | null | undefined;
  readonly supersedingSkills: ReadonlyArray<{
    readonly id: string | null | undefined;
    readonly name: string | null | undefined;
  }> | null | undefined;
  readonly " $fragmentType": "SkillFormAssociatedSkills_skill";
};
export type SkillFormAssociatedSkills_skill$key = {
  readonly " $data"?: SkillFormAssociatedSkills_skill$data;
  readonly " $fragmentSpreads": FragmentRefs<"SkillFormAssociatedSkills_skill">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SkillFormAssociatedSkills_skill",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Skill",
      "kind": "LinkedField",
      "name": "supersedingSkills",
      "plural": true,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Skill",
      "kind": "LinkedField",
      "name": "childSkills",
      "plural": true,
      "selections": (v1/*: any*/),
      "storageKey": null
    }
  ],
  "type": "Skill",
  "abstractKey": null
};
})();

(node as any).hash = "7572d1bf79f5e12bdca42486bfc2d8cc";

export default node;
