import * as React from 'react';
import { FC } from 'react';
// import SongListAside from './SongListAside';
import { ReactElement } from 'react';
import {
  Datagrid,
  Filter,
  FunctionField,
  List,
  ListProps,
  SelectInput,
  TextField,
} from 'react-admin';
import { FieldProps, Link } from 'react-admin';

import AudioField from '../practice/AudioField';

const PhonemeLinkField: FC<FieldProps> = (props) =>
  props.record ? (
    <Link to={`/content/phoneme/${props.record.id}`}>{props.record.text}</Link>
  ) : null;

PhonemeLinkField.defaultProps = {
  source: 'text',
  addLabel: true,
};

const PhonemeFilter = (props: any) => (
  <Filter {...props}>
    <SelectInput
      label="Status"
      source="status"
      choices={[
        { id: 'active', name: 'Active' },
        { id: 'inactive', name: 'Inactive' },
      ]}
    />
  </Filter>
);

const PhonemeList = (props: ListProps): ReactElement => (
  <List
    {...props}
    sort={{ field: 'arpabet', order: 'ASC' }}
    perPage={25}
    filters={<PhonemeFilter />}
  >
    <Datagrid optimized>
      <PhonemeLinkField label="Phoneme" />
      <TextField label="IPA" source="ipa" />
      <TextField label="Arpabet" source="arpabet" />
      <TextField label="Tip" source="tip" />
      <TextField label="Status" source="status" />
      <AudioField />
      <FunctionField
        render={(record: any) => record && record.videos.length}
        label="Num. videos"
      />
    </Datagrid>
  </List>
);

export default PhonemeList;
