/**
 * @generated SignedSource<<71aedeed805cca998679e534b287175b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DLPAssessmentsTaken2_Categories$data = {
  readonly compoundSounds: {
    readonly averageScore: number | null | undefined;
    readonly skillAssessments: ReadonlyArray<{
      readonly id: string | null | undefined;
      readonly " $fragmentSpreads": FragmentRefs<"DLPAssessmentsTaken2_SkillAssessment">;
    }> | null | undefined;
  } | null | undefined;
  readonly consonants: {
    readonly averageScore: number | null | undefined;
    readonly skillAssessments: ReadonlyArray<{
      readonly id: string | null | undefined;
      readonly " $fragmentSpreads": FragmentRefs<"DLPAssessmentsTaken2_SkillAssessment">;
    }> | null | undefined;
  } | null | undefined;
  readonly vowels: {
    readonly averageScore: number | null | undefined;
    readonly skillAssessments: ReadonlyArray<{
      readonly id: string | null | undefined;
      readonly " $fragmentSpreads": FragmentRefs<"DLPAssessmentsTaken2_SkillAssessment">;
    }> | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "DLPAssessmentsTaken2_Categories";
};
export type DLPAssessmentsTaken2_Categories$key = {
  readonly " $data"?: DLPAssessmentsTaken2_Categories$data;
  readonly " $fragmentSpreads": FragmentRefs<"DLPAssessmentsTaken2_Categories">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "averageScore",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "SkillAssessment",
    "kind": "LinkedField",
    "name": "skillAssessments",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "DLPAssessmentsTaken2_SkillAssessment"
      }
    ],
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DLPAssessmentsTaken2_Categories",
  "selections": [
    {
      "alias": "consonants",
      "args": [
        {
          "kind": "Literal",
          "name": "category",
          "value": "CONSONANTS"
        }
      ],
      "concreteType": "SkillCategoryAssessment",
      "kind": "LinkedField",
      "name": "assessmentBySkillCategory",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": "assessmentBySkillCategory(category:\"CONSONANTS\")"
    },
    {
      "alias": "vowels",
      "args": [
        {
          "kind": "Literal",
          "name": "category",
          "value": "VOWELS"
        }
      ],
      "concreteType": "SkillCategoryAssessment",
      "kind": "LinkedField",
      "name": "assessmentBySkillCategory",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": "assessmentBySkillCategory(category:\"VOWELS\")"
    },
    {
      "alias": "compoundSounds",
      "args": [
        {
          "kind": "Literal",
          "name": "category",
          "value": "COMPOUND_SOUNDS"
        }
      ],
      "concreteType": "SkillCategoryAssessment",
      "kind": "LinkedField",
      "name": "assessmentBySkillCategory",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": "assessmentBySkillCategory(category:\"COMPOUND_SOUNDS\")"
    }
  ],
  "type": "AssessmentTaken",
  "abstractKey": null
};
})();

(node as any).hash = "c332cd302fd8245d5a07e6b4d8578ba4";

export default node;
