import React from 'react';
import {
  Datagrid,
  List,
  ListProps,
  TextField,
  Filter,
  TextInput,
} from 'react-admin';
import { FC } from 'react';
import { Link, FieldProps } from 'react-admin';

import { ReactElement } from 'react';
import { MouthDiagramSet } from '.';
import { Box } from '@material-ui/core';
import { useMouthDiagramImages } from './utils';

const MouthDiagramSetField: FC<FieldProps> = (props) =>
  props.record ? (
    <Link to={`/content/mouthDiagramSet/${props.record.id}`}>
      {props.record.publicName}
    </Link>
  ) : null;

const DiagramSetImagePreviewField: FC<FieldProps<MouthDiagramSet>> = ({
  record,
}: {
  record?: MouthDiagramSet;
}) => {
  const { urls } = useMouthDiagramImages(record?.mouthDiagrams);
  return (
    <Box display="flex" gridColumnGap={12}>
      {record?.mouthDiagrams
        ?.sort(
          (a, b) =>
            a.MouthDiagramSetItem.orderNumber -
            b.MouthDiagramSetItem.orderNumber,
        )
        ?.map((diagram) => (
          <img
            alt="diagram"
            style={{ aspectRatio: '16/9', height: 60, borderRadius: 14 }}
            src={urls?.[diagram.imageUrl]}
          />
        ))}
    </Box>
  );
};

const DiagramTimingPreviewField: FC<FieldProps<MouthDiagramSet>> = ({
  record,
}: {
  record?: MouthDiagramSet;
}) => (
  <Box display="flex">
    {record?.mouthDiagrams
      ?.sort(
        (a, b) =>
          a.MouthDiagramSetItem.orderNumber - b.MouthDiagramSetItem.orderNumber,
      )
      ?.map(
        (diagram, idx) =>
          `${idx !== 0 ? ' -> ' : ''}${
            diagram.MouthDiagramSetItem.duration / 1000
          } sec`,
      )}
  </Box>
);

const MouthDiagramFilters = (props: any) => (
  <Filter {...props}>
    <TextInput label="Public Name" source="publicName" />
    <TextInput label="Internal Name" source="internalName" />
  </Filter>
);

const MouthDiagramList = (props: ListProps): ReactElement => (
  <List
    {...props}
    perPage={25}
    filters={<MouthDiagramFilters />}
    sort={{ field: 'createdAt', order: 'DESC' }}
  >
    <Datagrid optimized>
      <MouthDiagramSetField label="Public Name" />
      <TextField label="Internal Name" source="internalName" />
      <DiagramSetImagePreviewField label="Image" />
      <DiagramTimingPreviewField label="Timing" />
    </Datagrid>
  </List>
);

export default MouthDiagramList;
