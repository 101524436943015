import * as React from 'react';
import { FC } from 'react';
// import AssessmentListAside from './AssessmentListAside';
import { ReactElement } from 'react';
import { Datagrid, FunctionField, List, ListProps } from 'react-admin';
import { DateField, FieldProps, Link } from 'react-admin';

const AssessmentLinkField: FC<FieldProps> = (props) =>
  props.record ? (
    <Link to={`/content/assessment/${props.record.id}`}>
      {props.record.name}
    </Link>
  ) : null;

AssessmentLinkField.defaultProps = {
  source: 'name',
  addLabel: true,
};

const AssessmentList = (props: ListProps): ReactElement => (
  <List
    {...props}
    sort={{ field: 'name', order: 'ASC' }}
    perPage={25} // aside={<AssessmentListAside />}
  >
    <Datagrid optimized>
      <AssessmentLinkField label="Name" />
      <FunctionField
        render={(record: any) => record && record.practice_items.length}
        label="Num. Practice"
      />
      <DateField showTime source="createdAt" label="Created" />
    </Datagrid>
  </List>
);

export default AssessmentList;
