/**
 * @generated SignedSource<<3d80c9bba31fecd1dddf210262cd365f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type DLPPlayground_PlaygroundWriteCurriculumMutation$variables = {
  genParams: any;
  userID: string;
};
export type DLPPlayground_PlaygroundWriteCurriculumMutation$data = {
  readonly playgroundWriteCurriculum: {
    readonly __typename: "DLPPlaygroundResult";
  } | null | undefined;
};
export type DLPPlayground_PlaygroundWriteCurriculumMutation = {
  response: DLPPlayground_PlaygroundWriteCurriculumMutation$data;
  variables: DLPPlayground_PlaygroundWriteCurriculumMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "genParams"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "userID"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "genParams",
        "variableName": "genParams"
      },
      {
        "kind": "Variable",
        "name": "userID",
        "variableName": "userID"
      }
    ],
    "concreteType": "DLPPlaygroundResult",
    "kind": "LinkedField",
    "name": "playgroundWriteCurriculum",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "DLPPlayground_PlaygroundWriteCurriculumMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "DLPPlayground_PlaygroundWriteCurriculumMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "8bc62bba9fde96b9346ea33d6ee1d35f",
    "id": null,
    "metadata": {},
    "name": "DLPPlayground_PlaygroundWriteCurriculumMutation",
    "operationKind": "mutation",
    "text": "mutation DLPPlayground_PlaygroundWriteCurriculumMutation(\n  $userID: ID!\n  $genParams: JSONObject!\n) {\n  playgroundWriteCurriculum(userID: $userID, genParams: $genParams) {\n    __typename\n  }\n}\n"
  }
};
})();

(node as any).hash = "65562275e0f04cfae2add7e98469d9bd";

export default node;
