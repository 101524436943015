import { TextareaAutosize } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Icon from '@material-ui/icons/Delete';
import React, { useContext, useState } from 'react';

import { InVideoFormContext } from './InVideoFormProvider';

export default function VideoTextWithHighlightCreationForm() {
  const [targetText, setTargetText] = useState('');
  const [highlights, setHighlights] = useState<any[]>([]);
  const { data, setData } = useContext(InVideoFormContext);

  const updateInternalData = (e: any) => {
    setData({ ...data, [e.target.name]: e.target.value, highlights: [] });
    setTargetText(e.target.value);

    // reset highlights
    setHighlights([]);
  };

  const textSplitByNewLine = targetText?.split(/\n|\r\n/);

  const onSelectionMade = () => {
    const selection = window.getSelection();
    const parent = selection?.anchorNode?.parentElement;
    const parentId = parent?.id;
    const lineNumber = parentId?.match(/\d|\./g)?.join('');
    if (typeof lineNumber === 'string') {
      const lineIndex = parseInt(lineNumber, 10);
      const _startIndex = selection?.anchorOffset;
      const _endIndex = selection?.focusOffset;
      const payloadId = `${lineIndex}-${selection?.anchorOffset}-${selection?.focusOffset}`;

      // if the highlight already exists OR its a null highlight, don't add it
      if (
        highlights.find((highlight) => highlight.id === payloadId) ||
        _startIndex === undefined ||
        _endIndex === undefined ||
        _startIndex === _endIndex
      ) {
        return;
      }
      const isFlipped = _startIndex > _endIndex;
      const startIndex = isFlipped ? _endIndex : _startIndex;
      const endIndex = isFlipped ? _startIndex : _endIndex;

      const payload = {
        id: payloadId,
        lineIndex,
        startIndex,
        endIndex,
      };
      setHighlights([...highlights, payload]);
      setData({ ...data, highlights: [...highlights, payload] });
    }
  };

  const grey = {
    50: '#f6f8fa',
    100: '#eaeef2',
    200: '#d0d7de',
    300: '#afb8c1',
    400: '#8c959f',
    500: '#6e7781',
    600: '#57606a',
    700: '#424a53',
    800: '#32383f',
    900: '#24292f',
  };

  return (
    <div
      style={{
        marginTop: 10,

        display: 'flex',
        flexDirection: 'row',
      }}
    >
      <div
        style={{
          flex: 1,
        }}
      >
        <TextareaAutosize
          rowsMax={5}
          required
          name="text"
          id="text-with-highlights-creation-target-text"
          onChange={updateInternalData}
          style={{
            marginTop: 10,
            fontFamily: 'IBM Plex Sans, sans-serif',
            fontSize: '0.875rem',
            fontWeight: 400,
            lineHeight: 1.5,
            padding: '12px',
            borderRadius: '12px 12px 0 12px',
            border: `1px solid ${grey[700]}`,
          }}
        />
        <Typography variant="body1" style={{ color: grey[400] }}>
          Target Text
        </Typography>
      </div>
      <div
        style={{
          flex: 1,
        }}
      >
        <Typography
          variant="h3"
          style={{
            textAlign: 'center',
          }}
          onMouseUp={onSelectionMade}
        >
          {textSplitByNewLine.map(
            (segment: string, index: number, arr: string[]) => (
              <div id={`text-with-highlights-line-num-${index}`}>
                {segment}
                {index < arr.length - 1 && <br />}
              </div>
            ),
          )}
        </Typography>
        <div style={{ marginTop: 10 }}>
          <table
            style={{
              width: '100%',
              marginTop: 10,
            }}
          >
            <thead>
              {highlights.length > 0 && (
                <tr>
                  <td>Highlight #</td>
                  <td>Text</td>
                </tr>
              )}
            </thead>
            <tbody>
              {highlights.map((highlight, highlightIdx) => {
                const { lineIndex, startIndex, endIndex } = highlight;
                const line = textSplitByNewLine[lineIndex];

                const start = line?.substring(0, startIndex);
                const middle = line?.substring(startIndex, endIndex);
                const end = line?.substring(endIndex, line.length);

                return (
                  <tr>
                    <td>{highlightIdx + 1}</td>
                    <td>
                      {start}
                      <span
                        style={{
                          backgroundColor: '#F5277F',
                          color: 'white',
                        }}
                      >
                        {middle}
                      </span>
                      {end}
                    </td>
                    <td>
                      <Icon
                        style={{
                          cursor: 'pointer',
                          color: 'red',
                        }}
                        onClick={() => {
                          const newHighlights = [...highlights];
                          newHighlights.splice(highlightIdx, 1);
                          setHighlights(newHighlights);
                        }}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          {highlights.length > 0 && (
            <Typography
              variant="body1"
              style={{ color: grey[400], marginTop: 10 }}
            >
              All highlights will show at the same time
            </Typography>
          )}
        </div>
      </div>
    </div>
  );
}
