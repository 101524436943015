import * as MUI from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import graphql from 'babel-plugin-relay/macro';
import React from 'react';
import { useLazyLoadQuery } from 'react-relay';

const ConversationSelect = (props: any) => (
  <React.Suspense
    fallback={
      <ConversationSelectField {...props} isLoading conversations={[]} />
    }
  >
    <ConversationSelectWithData {...props} />
  </React.Suspense>
);

const ConversationSelectWithData = (props: any) => {
  const [isRefreshPending, startRefreshTransition] = React.useTransition();
  const [refreshedQueryOptions, setRefreshedQueryOptions] = React.useState({});

  const data: any = useLazyLoadQuery(
    graphql`
      query ConversationSelect_allConversations_Query {
        allConversations {
          id
          name
          tags
        }
      }
    `,
    {},
    refreshedQueryOptions,
  );

  const refresh = React.useCallback(() => {
    startRefreshTransition(() => {
      setRefreshedQueryOptions((prev: any) => ({
        fetchKey: (prev.fetchKey ?? 0) + 1,
        fetchPolicy: 'network-only',
      }));
    });
  }, [startRefreshTransition, setRefreshedQueryOptions]);

  return (
    <ConversationSelectField
      {...props}
      conversations={data.allConversations}
      onRefresh={refresh}
      isRefreshPending={isRefreshPending}
    />
  );
};

const ConversationSelectField = ({
  conversations,
  onChange,
  value,
  onRefresh,
  isLoading,
  isRefreshPending,
}: any) => {
  const conversationsByID = React.useMemo(
    () =>
      Object.fromEntries(
        conversations.map((conversation: any) => [
          conversation.id,
          conversation,
        ]),
      ),
    [conversations],
  );

  const conversationIDs = React.useMemo(
    () => conversations.map((conversation: any) => conversation.id),
    [conversations],
  );

  return (
    <Autocomplete
      options={conversationIDs}
      getOptionLabel={(conversationID: any) =>
        `${conversationsByID[conversationID]?.name ? `${conversationsByID[conversationID]?.name}${conversationsByID[conversationID].tags ? ` | ${conversationsByID[conversationID].tags}` : ``}` : `Invalid Conversation`}`
      }
      style={{ width: 600 }}
      size="small"
      blurOnSelect
      selectOnFocus
      disabled={isLoading}
      renderOption={(conversationID: any) => {
        const conversation = conversationsByID[conversationID];
        return (
          <MUI.Box display="flex" flexDirection="column">
            {conversation.name}
            {conversation.tags ? ` | ${conversation.tags}` : ``}
            <MUI.Box component="span" color="text.secondary" fontSize={12}>
              {conversation.id}
            </MUI.Box>
          </MUI.Box>
        );
      }}
      renderInput={(params) => (
        <MUI.TextField
          {...params}
          label="Conversation"
          variant="filled"
          required={false}
          margin="normal"
          InputLabelProps={{ shrink: value ? true : undefined }}
          helperText={
            isLoading ? (
              'Loading conversations...'
            ) : isRefreshPending ? (
              'Refreshing conversations...'
            ) : (
              <MUI.Box>
                <MUI.Link
                  style={{ cursor: 'pointer' }}
                  onClick={(e: any) => {
                    onRefresh?.();
                    e.stopPropagation();
                  }}
                >
                  Refresh
                </MUI.Link>{' '}
                conversations{' | '}
                {!!value && (
                  <MUI.Link
                    style={{ cursor: 'pointer' }}
                    href={`/#/content/conversation/${value}`}
                    target="_blank"
                  >
                    Go to conversation
                  </MUI.Link>
                )}
              </MUI.Box>
            )
          }
        />
      )}
      onChange={(e, value) => onChange(value)}
      value={value}
    />
  );
};

export default ConversationSelect;
