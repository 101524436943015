import * as React from 'react';
import { FC } from 'react';
import { FieldProps } from 'react-admin';
import ReactAudioPlayer from 'react-audio-player';
import { Box, Typography } from '@material-ui/core';
import api from '../api';

const AudioField: FC<FieldProps> = (props) => {
  const { record } = props;
  const [audioFile, setAudioFile] = React.useState<any>();
  const [error, setError] = React.useState<any>();

  React.useEffect(() => {
    const getAudioFile = async () => {
      setError(undefined);
      if (record?.audioFile) {
        try {
          const newAudioFile = await api.getAudioFile(
            record?.audioFile.split('/')[1],
          );
          if (newAudioFile.status === 403) {
            const jsonResult = await newAudioFile.json();
            if (!jsonResult?.success) {
              setError('No audio file at URL provided.');
            }
          } else {
            const fileBlob = await newAudioFile?.body?.blob();
            const reader = new FileReader();
            reader.onload = function (e) {
              const base64audio = e?.target?.result;
              if (base64audio) {
                setAudioFile(base64audio);
              } else {
                setError('No audio file at URL provided.');
              }
            };
            fileBlob.end = fileBlob.size;
            reader.readAsDataURL(fileBlob);
          }
        } catch (err: any) {
          setError(err?.message);
        }
      }
    };

    getAudioFile();

    return setAudioFile(null);
  }, [record?.audioFile]);

  const onError = (newError: any) => {
    setError(newError?.message);
  };

  return props.record ? (
    <Box>
      {audioFile && !error && (
        <Box>
          <ReactAudioPlayer controls onError={onError}>
            <source src={audioFile} />
          </ReactAudioPlayer>
        </Box>
      )}

      {error && (
        <Typography variant="body1" gutterBottom>
          Coach Sample Error: {error}
        </Typography>
      )}
    </Box>
  ) : null;
};

AudioField.defaultProps = {
  source: 'audioFile',
  label: 'Audio File',
  addLabel: true,
};

export default AudioField;
