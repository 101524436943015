/**
 * @generated SignedSource<<9f1721abf2fe06ff665b78d8604daec6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SkillAssessments_RegenerateUserContinuousAssessments_Mutation$variables = {
  userID: string;
};
export type SkillAssessments_RegenerateUserContinuousAssessments_Mutation$data = {
  readonly regenerateUserContinuousAssessments: {
    readonly " $fragmentSpreads": FragmentRefs<"SkillAssessments_user">;
  } | null | undefined;
};
export type SkillAssessments_RegenerateUserContinuousAssessments_Mutation = {
  response: SkillAssessments_RegenerateUserContinuousAssessments_Mutation$data;
  variables: SkillAssessments_RegenerateUserContinuousAssessments_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "userID"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "userID",
    "variableName": "userID"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "nodeID",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SkillAssessments_RegenerateUserContinuousAssessments_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "regenerateUserContinuousAssessments",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "SkillAssessments_user"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SkillAssessments_RegenerateUserContinuousAssessments_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "regenerateUserContinuousAssessments",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "UserSkill",
            "kind": "LinkedField",
            "name": "skills",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Skill",
                "kind": "LinkedField",
                "name": "skill",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "SkillAssessment",
                "kind": "LinkedField",
                "name": "skillAssessments",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  (v4/*: any*/),
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "score",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "minScore",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "maxScore",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "sampleSize",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "assessmentTakenID",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "AggregateAssessment",
            "kind": "LinkedField",
            "name": "aggregateAssessments",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "bottomAvg",
                "storageKey": null
              },
              (v4/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "accurateAggregateAssessments",
            "storageKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "357590df0dd194d3282df9cc894a3b27",
    "id": null,
    "metadata": {},
    "name": "SkillAssessments_RegenerateUserContinuousAssessments_Mutation",
    "operationKind": "mutation",
    "text": "mutation SkillAssessments_RegenerateUserContinuousAssessments_Mutation(\n  $userID: ID!\n) {\n  regenerateUserContinuousAssessments(userID: $userID) {\n    ...SkillAssessments_user\n    nodeID\n  }\n}\n\nfragment SkillAssessments_OverallSkill_user on User {\n  aggregateAssessments {\n    createdAt\n    bottomAvg\n    nodeID\n  }\n  accurateAggregateAssessments\n}\n\nfragment SkillAssessments_Skill_user on User {\n  createdAt\n}\n\nfragment SkillAssessments_Skill_userSkill on UserSkill {\n  skill {\n    id\n    name\n    nodeID\n  }\n  skillAssessments {\n    id\n    createdAt\n    score\n    minScore\n    maxScore\n    sampleSize\n    assessmentTakenID\n    nodeID\n  }\n}\n\nfragment SkillAssessments_user on User {\n  createdAt\n  skills {\n    skill {\n      id\n      nodeID\n    }\n    skillAssessments {\n      createdAt\n      nodeID\n    }\n    ...SkillAssessments_Skill_userSkill\n  }\n  ...SkillAssessments_OverallSkill_user\n  ...SkillAssessments_Skill_user\n}\n"
  }
};
})();

(node as any).hash = "dcec2fb6e171190cf9ff291a68943065";

export default node;
