import * as React from 'react';
import { FC } from 'react';
import { FieldProps } from 'react-admin';
import ReactAudioPlayer from 'react-audio-player';
import { Box, Typography } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import api from '../api';

const AudioField: FC<FieldProps> = (props) => {
  const { record } = props;
  const [audioFile, setAudioFile] = React.useState<any>();
  const [aiAudioFile, setAIAudioFile] = React.useState<any>();
  const [error, setError] = React.useState<any>();

  React.useEffect(() => {
    const getAudioFile = async (
      sample: string,
      subfolder: string | null = null,
    ) =>
      /* eslint-disable-next-line */
      new Promise(async (resolve) => {
        setError(undefined);
        try {
          const newAudioFile = await api.getCoachAudio(
            `${encodeURIComponent(sample)}`,
            subfolder,
          );
          if (newAudioFile.status === 403) {
            const jsonResult = await newAudioFile.json();
            if (!jsonResult?.success) {
              setError('No audio file at URL provided.');
              resolve(null);
            }
          } else {
            const fileBlob = await newAudioFile?.body?.blob();
            const reader = new FileReader();
            reader.onload = function (e) {
              const base64audio = e?.target?.result;
              if (base64audio) {
                resolve(base64audio);
              } else {
                setError('No audio file at URL provided.');
                resolve(null);
              }
            };
            reader.readAsDataURL(fileBlob);
          }
        } catch (err: any) {
          setError(err?.message);
          resolve(null);
        }
      });

    const getFiles = async () => {
      if (record?.coachSample) {
        const coachSample = await getAudioFile(record?.coachSample);
        if (coachSample) {
          setAudioFile(coachSample);
        }
      }
      if (record?.fullCoachExample) {
        const coachSample = await getAudioFile(
          record?.fullCoachExample,
          'library',
        );
        if (coachSample) {
          setAudioFile(coachSample);
        }
      }
      if (record?.aiCoachSample) {
        const aiCoachSample = await getAudioFile(record?.aiCoachSample);
        if (aiCoachSample) {
          setAIAudioFile(aiCoachSample);
        }
      }
      if (record?.audioFile) {
        const quipAudioFile = await getAudioFile(
          record?.audioFile,
          'coach_quips',
        );
        if (quipAudioFile) {
          setAudioFile(quipAudioFile);
        }
      }
    };

    getFiles();

    return () => {
      setAudioFile(null);
      setAIAudioFile(null);
    };
  }, [record?.coachSample, record?.aiCoachSample]);

  const onError = (newError: any) => {
    setError(newError?.message);
  };

  return props.record ? (
    <Tooltip
      placement="top"
      title={props.record?.coachSample || props.record?.aiCoachSample}
    >
      <Box>
        {(audioFile || aiAudioFile) && !error && (
          <Box>
            <ReactAudioPlayer controls onError={onError}>
              <source src={audioFile || aiAudioFile} />
            </ReactAudioPlayer>
          </Box>
        )}

        {error && (
          <Typography variant="body1" gutterBottom>
            Coach Sample Error: {error}
          </Typography>
        )}
      </Box>
    </Tooltip>
  ) : null;
};

AudioField.defaultProps = {
  source: 'audioFile',
  label: 'Audio File',
  addLabel: true,
};

export default AudioField;
