import * as React from 'react';
import { FC } from 'react';
import { Link, FieldProps } from 'react-admin';

import UnitNameField from './UnitNameField';

const UnitLinkField: FC<FieldProps> = (props) => {
  const units = props.record?.unitId;

  return units?.length
    ? units.map((unit: any) => (
        <Link key={unit} to={`/content/unit/${unit}`}>
          <UnitNameField {...props} unit={unit} />
        </Link>
      ))
    : null;
};

UnitLinkField.defaultProps = {
  label: 'Unit(s)',
  addLabel: true,
};

export default UnitLinkField;
