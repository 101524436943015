// @ts-nocheck
import { Box, Button, TextField } from '@material-ui/core';
import { FormControlLabel, Switch } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import { useInput } from 'react-admin';
import { useForm } from 'react-final-form';
import styled from 'styled-components';

import api from '../api';
import { difficultyList } from './LessonPracticeInput';
import PracticeSetPracticeAutoComplete from './PracticeSets/PracticeSetPracticeAutoComplete';
import PracticeableList from './PracticeSets/PracticeableList';

const DATA_VALUE_ENUM = {
  NAME: 'name',
  DESCRIPTION: 'description',
  DIFFICULTY: 'difficulty',
  IS_TUTORIAL: 'isTutorial',
};

const PracticeSetInput = (props: any) => {
  const { choices, label } = props;

  const {
    input: { onChange, value: values },
  } = useInput(props);

  // reason why we use selectedChoices is because choices is the super set of loaded records that are "possible choices" for this multi input, whereas the value key from input
  // are the actual values that are part of the input. Choices is not the actively selected values, only value is!
  const selectedChoices = (values || [])
    .map((v: any) => choices.find((c: any) => c.id === v))
    .filter(identity);

  const [dataValues, setDataValues] = React.useState({});

  const { change, getState } = useForm();
  const { initialValues } = getState();

  const setNewDataValue = React.useCallback(
    (type, itemId, newValue) => {
      const obj = dataValues[itemId];
      const newDataValues = {
        ...dataValues,
        [itemId]: {
          ...obj,
          [type]: newValue,
        },
      };

      setDataValues(newDataValues);
    },
    [dataValues],
  );

  const addNewPracticeSet = React.useCallback(async () => {
    const practiceSets = [];

    const addedPracticeSet = {
      difficulty: 'none',
      name: '',
      description: '',
      lessonId: initialValues.id,
    };

    const createdPracticeSet = await api.createPracticeSet(addedPracticeSet);

    if (selectedChoices?.length) {
      selectedChoices.forEach(({ id }) => {
        const newPracticeSet = {
          id,
          difficulty: dataValues[id]?.[DATA_VALUE_ENUM.DIFFICULTY],
          name: dataValues[id]?.[DATA_VALUE_ENUM.NAME],
          description: dataValues[id]?.[DATA_VALUE_ENUM.DESCRIPTION],
          lessonId: initialValues.id,
        };

        practiceSets.push(newPracticeSet);
      });
    }

    practiceSets.push(createdPracticeSet.json);

    const newValues = [
      ...selectedChoices.map((i) => i.id),
      createdPracticeSet.json?.id,
    ];

    change('practiceSetId', newValues);
    change('practice_sets', practiceSets);
  }, [selectedChoices, dataValues, initialValues]);

  const reloadPracticeSet = React.useCallback(() => {
    const practiceSets = [];
    if (selectedChoices?.length) {
      selectedChoices.forEach(({ id }) => {
        const newPracticeSet = {
          id,
          difficulty: dataValues[id]?.[DATA_VALUE_ENUM.DIFFICULTY],
          name: dataValues[id]?.[DATA_VALUE_ENUM.NAME],
          description: dataValues[id]?.[DATA_VALUE_ENUM.DESCRIPTION],
          lessonId: initialValues.id,
        };

        practiceSets.push(newPracticeSet);
      });
    }

    const newValues = [...selectedChoices.map((i) => i.id)];
    // change('practiceSetId', newValues);
    // change('practice_sets', practiceSets);
    onChange([]);
    onChange(newValues);
  }, [selectedChoices, dataValues, initialValues]);

  const removePracticeSet = React.useCallback(
    async (idToRemove) => {
      if (
        window.confirm('Are you sure you want to delete this practice set?')
      ) {
        try {
          const success = await api.deletePracticeSet(idToRemove);
          if (success?.json?.id) {
            const practiceSets = [];

            if (selectedChoices?.length) {
              selectedChoices
                .filter(({ id }) => id !== idToRemove)
                .forEach(({ id }) => {
                  const newPracticeSet = {
                    id,
                    difficulty: dataValues[id]?.[DATA_VALUE_ENUM.DIFFICULTY],
                    name: dataValues[id]?.[DATA_VALUE_ENUM.NAME],
                    description: dataValues[id]?.[DATA_VALUE_ENUM.DESCRIPTION],
                    lessonId: initialValues.id,
                  };

                  practiceSets.push(newPracticeSet);
                });
            }

            const newValues = selectedChoices
              .filter(({ id }) => id !== idToRemove)
              .map(({ id }) => id);

            onChange(newValues);
            // change('practiceSetId', newValues);
            // change('practice_sets', practiceSets);
          } else {
            alert('Error when removing practice set.');
            window.reload();
          }
        } catch (error) {
          console.error(error);
        }
      }
    },
    [selectedChoices, dataValues, initialValues],
  );

  const difficultySort = React.useCallback((a, b) => {
    const order = ['easy', 'medium', 'hard', 'very_hard'];
    return order.indexOf(a.difficulty) - order.indexOf(b.difficulty);
  }, []);

  // initial set up of state
  React.useEffect(() => {
    const newDataValues = {};
    const { practice_sets: practiceSets } = initialValues;
    if (practiceSets?.length) {
      // why use choices if you have access to the record data initially
      practiceSets.forEach((value) => {
        const foundPSet = practiceSets?.find((i) => i.id === value.id);
        newDataValues[value.id] = {
          [DATA_VALUE_ENUM.DIFFICULTY]: foundPSet?.difficulty || 'none',
          [DATA_VALUE_ENUM.NAME]: foundPSet?.name,
          [DATA_VALUE_ENUM.DESCRIPTION]: foundPSet?.description,
          [DATA_VALUE_ENUM.IS_TUTORIAL]: foundPSet?.isTutorial,
        };
      });
    }

    setDataValues(newDataValues);
    change('practice_sets', practiceSets);
  }, [initialValues]);

  // send updates upstream to parent
  React.useEffect(() => {
    const practiceSets = [];
    if (selectedChoices?.length) {
      selectedChoices.forEach((value) => {
        const newPracticeSet = {
          id: value.id,
          difficulty: dataValues[value.id]?.[DATA_VALUE_ENUM.DIFFICULTY],
          name: dataValues[value.id]?.[DATA_VALUE_ENUM.NAME],
          description: dataValues[value.id]?.[DATA_VALUE_ENUM.DESCRIPTION],
          lessonId: initialValues.id,
          isTutorial: dataValues[value.id]?.[DATA_VALUE_ENUM.IS_TUTORIAL],
        };

        practiceSets.push(newPracticeSet);
      });
    }
    if (practiceSets.length) {
      change('practice_sets', practiceSets);
    }
  }, [initialValues, dataValues, selectedChoices]);

  return (
    <List style={{ border: 'none', marginBottom: '1.25rem' }}>
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        px={1}
        pt={2}
        pb={1}
      >
        <Title>{label}</Title>
        <Button onClick={addNewPracticeSet} variant="contained" color="primary">
          Add Practice Set +
        </Button>
      </Box>
      {[...selectedChoices].sort(difficultySort).map((v, i) => (
        <div
          style={{
            padding: '1rem 1rem',
            marginTop: '1rem',
            boxShadow: '15px 2px 23px 8px rgba(0,0,0,0.15)',
          }}
        >
          <PracticeSet
            value={v}
            index={i}
            id={v.id}
            key={v.id}
            dataValues={dataValues}
            setDataValues={setNewDataValue}
            totalItems={selectedChoices.length}
            removePracticeSet={removePracticeSet}
            reloadPracticeSet={reloadPracticeSet}
          />
        </div>
      ))}
    </List>
  );
};

const PracticeSet = (props) => {
  const {
    id,
    value,
    index,
    dataValues,
    setDataValues,
    totalItems,
    removePracticeSet,
    reloadPracticeSet,
  } = props;

  const removePracticeSetCallback = React.useCallback(
    () => removePracticeSet(id),
    [id],
  );

  return (
    <ListItem isLast={totalItems === index + 1}>
      <Box display="flex" flexDirection="column" style={{ marginTop: 5 }}>
        <Box display="flex" style={{ marginBottom: 10 }}>
          <TextField
            id="name"
            type="text"
            label="Name"
            value={dataValues[value.id]?.[DATA_VALUE_ENUM.NAME]}
            style={{ flex: 1 }}
            variant="outlined"
            margin="dense"
            helperText={false}
            onChange={(evt) =>
              setDataValues(DATA_VALUE_ENUM.NAME, value.id, evt.target.value)
            }
          />
          <TextField
            id="description"
            type="text"
            label="Description"
            value={dataValues[value.id]?.[DATA_VALUE_ENUM.DESCRIPTION]}
            multiline
            style={{ flex: 1, marginLeft: 10 }}
            variant="outlined"
            margin="dense"
            helperText={false}
            onChange={(evt) =>
              setDataValues(
                DATA_VALUE_ENUM.DESCRIPTION,
                value.id,
                evt.target.value,
              )
            }
          />
          <Box
            style={{
              borderRadius: '50%',
              borderColor: 'black',
              borderWidth: 1,
              borderStyle: 'solid',
              height: 20,
              width: 20,
              marginLeft: 10,
              cursor: 'pointer',
            }}
          >
            <CloseIcon
              onClick={removePracticeSetCallback}
              style={{
                height: '100%',
                width: '100%',
              }}
            />
          </Box>
        </Box>
        <Box>Difficulty</Box>
        <select
          id="difficulty-value"
          onChange={(evt) =>
            setDataValues(
              DATA_VALUE_ENUM.DIFFICULTY,
              value.id,
              evt.target.value,
            )
          }
          value={dataValues[value.id]?.[DATA_VALUE_ENUM.DIFFICULTY]}
          style={{
            marginRight: 32,
            marginTop: 10,
            marginBottom: 5,
            paddingTop: 10.5,
            paddingBottom: 10.5,
            paddingLeft: 14,
            paddingRight: 14,
            borderRadius: 10,
          }}
        >
          {difficultyList.map((i) => (
            <option key={i.id} value={i.value}>
              {i.name}
            </option>
          ))}
        </select>
      </Box>
      <Box>
        <PracticeableList
          practiceSet={value}
          reloadPracticeSet={reloadPracticeSet}
        />
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <PracticeSetPracticeAutoComplete
            practiceSet={value}
            reloadPracticeSet={reloadPracticeSet}
          />
          {/* <PracticeSetMultipleChoiceAutoComplete
            practiceSet={value}
            reloadPracticeSet={reloadPracticeSet}
          /> */}
        </div>
      </Box>
      <Box>
        <Box ml={5} alignSelf="flex-end">
          <FormControlLabel
            control={
              <Switch
                id="isTutorial"
                checked={dataValues[value.id]?.[DATA_VALUE_ENUM.IS_TUTORIAL]}
                onChange={(evt) =>
                  setDataValues(
                    DATA_VALUE_ENUM.IS_TUTORIAL,
                    value.id,
                    evt.target.checked,
                  )
                }
              />
            }
            label="Is Tutorial"
          />
        </Box>
      </Box>
    </ListItem>
  );
};

export default PracticeSetInput;

const ListItem = styled.div<StyledDivProps>`
  background-color: ${(props) => (props.isDragging ? 'lightgrey' : 'white')};
`;

const List = styled.div<StyledDivProps>`
  padding: 8px;
  border: 1px solid grey;
  background-color: ${(props) => (props.isDraggingOver ? '#2097F3' : 'white')};
`;

const Title = styled.div`
  font-weight: bold;
  font-size: 1.2rem;
  padding: 8px;
`;

interface StyledDivProps {
  isDragging?: boolean;
  isDraggingOver?: boolean;
}

const identity = (v: any) => v;
