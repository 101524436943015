import { Box, Card, CardContent } from '@material-ui/core';
import { Theme, makeStyles } from '@material-ui/core/styles';
import { Styles } from '@material-ui/styles/withStyles';
import React, { FC } from 'react';
import {
  DeleteButton,
  Edit,
  EditProps,
  FieldProps,
  FormDataConsumer,
  FormWithRedirect,
  NumberInput,
  ReferenceInput,
  SaveButton,
  SelectInput,
  TextInput,
  Toolbar,
} from 'react-admin';

import { GoalTypes, MetricTypes, MetricsWithMetricLength } from './constants';

const GoalTemplateTitle: FC<FieldProps> = ({ record }) =>
  record ? <span>Generative Conversation Goal Template</span> : null;

export const styles: Styles<Theme, any> = {
  button: {
    marginRight: 10,
  },
};

const useStyles = makeStyles(styles);

const GoalTemplateForm = (props: any) => {
  const classes = useStyles(props);

  return (
    <FormWithRedirect
      {...props}
      render={(formProps: any) => (
        <Card>
          <form>
            <CardContent>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
              >
                <TextInput
                  autoFocus
                  source="title"
                  label="Title"
                  fullWidth
                  required
                  helperText={false}
                  placeholder="Say five 3+ syllable words, Order a pizza, etc."
                />
                <TextInput
                  autoFocus
                  source="subtitle"
                  label="Subtitle"
                  fullWidth
                  helperText={false}
                  placeholder="Improves your your vocabulary, etc."
                />
                <TextInput
                  autoFocus
                  source="iconFamily"
                  label="Icon Family"
                  fullWidth
                  helperText={false}
                  placeholder="FontAwesome5, MaterialIcons"
                />
                <TextInput
                  autoFocus
                  source="icon"
                  label="Icon Name"
                  fullWidth
                  helperText={false}
                  placeholder="translate, phone, etc."
                />
                <TextInput
                  autoFocus
                  source="completionString"
                  label="Completion String"
                  fullWidth
                  helperText={false}
                  placeholder="Wow! You said/did xyz!"
                />
                <SelectInput
                  source="type"
                  aria-label="Goal Type"
                  choices={GoalTypes.map((_type) => ({
                    id: _type,
                    name: _type,
                  }))}
                />

                <FormDataConsumer>
                  {({ formData }) =>
                    formData.type === 'contains' ||
                    formData.type === 'starting_chars' ||
                    formData.type === 'ending_chars' ? (
                      <TextInput
                        autoFocus
                        source="metric"
                        label="Target String"
                        fullWidth
                        helperText={false}
                        placeholder={
                          formData.type === 'contains'
                            ? "'pizza', 'hello', etc."
                            : 'th, k, ing'
                        }
                      />
                    ) : MetricsWithMetricLength.includes(formData.metric) ? (
                      <Box flexDirection="row" display="flex">
                        <NumberInput
                          source="metricLength"
                          required
                          style={{
                            marginRight: '1em',
                            minWidth: '100%',
                          }}
                          placeholder='e.g. "3" for 3 syllable words'
                          label="Metric Length"
                        />
                        <SelectInput
                          source="metric"
                          choices={MetricTypes.map((metric) => ({
                            id: metric,
                            name: metric,
                          }))}
                        />
                      </Box>
                    ) : (
                      <SelectInput
                        source="metric"
                        choices={MetricTypes.map((metric) => ({
                          id: metric,
                          name: metric,
                        }))}
                      />
                    )
                  }
                </FormDataConsumer>

                <FormDataConsumer>
                  {({ formData }) =>
                    formData.type === 'binomial' ? (
                      <NumberInput
                        source="target"
                        label="Target"
                        helperText={false}
                        value={1}
                        contentEditable={false}
                      />
                    ) : formData.type === 'contains' ? null : (
                      <NumberInput
                        source="target"
                        label="Target"
                        helperText={false}
                      />
                    )
                  }
                </FormDataConsumer>

                <ReferenceInput
                  allowEmpty
                  style={{ width: '50%' }}
                  label="Scenario"
                  source="scenarioId"
                  reference="content/generative_conversation/topic_config"
                  perPage={1000}
                >
                  <SelectInput
                    optionText={(record: any) =>
                      `${record.category} (${record.conversation_topic} @ ${record.location})`
                    }
                  />
                </ReferenceInput>
              </Box>

              <Toolbar
                record={formProps.record}
                basePath={formProps.basePath}
                undoable={formProps.undoable}
                invalid={formProps.invalid}
                handleSubmit={formProps.handleSubmit}
                saving={formProps.saving}
                resource="generative_conversation/goal_template"
                style={{ display: 'flex', justifyContent: 'space-between' }}
              >
                <div>
                  <SaveButton
                    label="Save & Continue"
                    handleSubmitWithRedirect={
                      formProps.handleSubmitWithRedirect ||
                      formProps.handleSubmit
                    }
                    disabled={formProps.disabled}
                    invalid={formProps.invalid}
                    redirect="edit"
                    saving={formProps.saving}
                    submitOnEnter={formProps.submitOnEnter}
                    className={classes.button}
                  />

                  <SaveButton
                    label="Save & Exit"
                    handleSubmitWithRedirect={
                      formProps.handleSubmitWithRedirect ||
                      formProps.handleSubmit
                    }
                    disabled={formProps.disabled}
                    invalid={formProps.invalid}
                    redirect={formProps.redirect}
                    saving={formProps.saving}
                    submitOnEnter={formProps.submitOnEnter}
                    className={classes.button}
                  />
                </div>

                {formProps.record &&
                  typeof formProps.record.id !== 'undefined' && (
                    <DeleteButton
                      basePath={formProps.basePath}
                      record={formProps.record}
                      resource={formProps.resource}
                      undoable={formProps.undoable}
                      mutationMode={formProps.mutationMode}
                    />
                  )}
              </Toolbar>
            </CardContent>
          </form>
        </Card>
      )}
    />
  );
};

const GoalTemplateEdit: FC<EditProps> = (props) => (
  <Edit undoable={false} title={<GoalTemplateTitle />} {...props}>
    <GoalTemplateForm />
  </Edit>
);

export default GoalTemplateEdit;
