// @ts-nocheck
import React from 'react';

import { Card, CardContent } from '@material-ui/core';
import moment from 'moment';

const styles = {
  card: {
    width: '30%',
    margin: '1rem',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
    borderRadius: '10px',
    overflow: 'hidden',
  },
  title: {
    fontSize: '1.5rem',
    fontWeight: 'bold',
    marginBottom: '0.5rem',
  },
  subtitle: {
    fontSize: '1rem',
    marginLeft: '0.5rem',
    color: '#666',
    marginBottom: '1rem',
  },
  info: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0.5rem',
  },
  label: {
    fontSize: '0.8rem',
    color: '#999',
    marginRight: '0.5rem',
  },
  value: {
    fontSize: '1rem',
    fontWeight: 'bold',
  },
};

export default function ScenarioGoalRow({ goal }: { goal: any }) {
  return (
    <Card style={styles.card}>
      <CardContent>
        <span style={styles.title}>{goal.title}</span>
        <span style={styles.subtitle}>{goal.subtitle}</span>
        <div style={styles.info}>
          <span style={styles.label}>Type:</span>
          <span style={styles.value}>{goal.type}</span>
        </div>
        <div style={styles.info}>
          <span style={styles.label}>Metric:</span>
          <span style={styles.value}>
            {goal.metricLength} {goal.metric}
          </span>
        </div>
        <div style={styles.info}>
          <span style={styles.label}>Target:</span>
          <span style={styles.value}>{goal.target}</span>
        </div>
        <div style={styles.info}>
          <span style={styles.label}>Created on:</span>
          <span style={styles.value}>
            {moment(goal.createdAt).format('MMMM Do YYYY')}
          </span>
        </div>
        <div style={styles.info}>
          <span style={styles.label}>Updated on:</span>
          <span style={styles.value}>
            {moment(goal.updatedAt).format('MMMM Do YYYY')}
          </span>
        </div>
      </CardContent>
    </Card>
  );
}
