import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';
import { ReactElement } from 'react';
import {
  Datagrid,
  DateField,
  Filter,
  Link,
  List,
  ListProps,
  TextInput,
} from 'react-admin';

import IPAText from '../components/IPAText';
import MultipleChoiceQuestionCreate from './MultipleChoiceQuestionCreate';
import MultipleChoiceQuestionEdit from './MultipleChoiceQuestionEdit';

const MultipleChoiceQuestionFilter = (props: any) => (
  <Filter {...props}>
    <TextInput label="Internal Name" source="internalName" defaultValue="" />
  </Filter>
);

const MultipleChoiceQuestionList = (props: ListProps): ReactElement => (
  <List
    {...props}
    sort={{ field: 'text', order: 'ASC' }}
    perPage={25}
    filters={<MultipleChoiceQuestionFilter />}
  >
    <Datagrid optimized>
      <MultipleChoiceQuestionField />

      <DateField source="createdAt" label="Created At" />
    </Datagrid>
  </List>
);

const MultipleChoiceQuestionField = (props: any) =>
  props.record ? (
    <Link to={`/g/multiple-choice-questions/${props.record.id}`}>
      <IPAText text={props.record.internalName} />
    </Link>
  ) : null;

MultipleChoiceQuestionField.defaultProps = {
  source: 'internalName',
};

export default {
  list: MultipleChoiceQuestionList,
  edit: MultipleChoiceQuestionEdit,
  create: MultipleChoiceQuestionCreate,
  icon: FormatListNumberedIcon,
};
