import * as MUI from '@material-ui/core';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import { Alert } from '@material-ui/lab';
import graphql from 'babel-plugin-relay/macro';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useFragment, useMutation } from 'react-relay';

import AudioUpload from '../components/AudioUpload';
import Reorderable from '../components/Reorderable';
import { DeleteButton, LoadingButton } from '../components/UserJourney/Buttons';

const MultipleChoiceOptionsEditor = ({ question }: any) => {
  const data = useFragment(
    graphql`
      fragment MultipleChoiceOptionsEditor_question on MultipleChoiceQuestion {
        id

        options {
          id
          order
          ...MultipleChoiceOptionsEditor_option
        }
      }
    `,
    question,
  );

  const [
    clientOrderedMultipleChoiceOptions,
    setClientOrderedMultipleChoiceOptions,
  ] = React.useState(data.options);

  React.useEffect(() => {
    setClientOrderedMultipleChoiceOptions(
      data.options.filter((option: any) => option),
    );
  }, [data.options]);

  const [commitReorderMutation, _isReorderMutationInFlight] = useMutation(
    graphql`
      mutation MultipleChoiceOptionsEditor_Reorder_Mutation($newOrder: [ID!]!) {
        reorderMultipleChoiceOptions(newOrder: $newOrder) {
          ...MultipleChoiceOptionsEditor_question
        }
      }
    `,
  );

  const onSaveOrder = (ids) => {
    commitReorderMutation({
      variables: {
        newOrder:
          ids ??
          clientOrderedMultipleChoiceOptions.map((option: any) => option.id),
      },
    });
  };

  return (
    <MUI.Box style={{ marginBottom: 30, width: '100%' }}>
      <MUI.Typography variant="h6" gutterBottom>
        Options
      </MUI.Typography>

      <Reorderable
        items={data.options}
        onSaveOrder={onSaveOrder}
        resourceName="option"
        droppableID="optionList"
        renderItem={(option: any, index: any, dragHandleProps: any) => (
          <MultipleChoiceOption
            option={option}
            index={index}
            dragHandleProps={dragHandleProps}
          />
        )}
        renderNewItem={(resetNewItem: any) => (
          <NewMultipleChoiceOption
            multipleChoiceQuestionID={data.id}
            reset={resetNewItem}
          />
        )}
      />
    </MUI.Box>
  );
};

const MultipleChoiceOptionCard = ({
  index: _index,
  option,
  errorMessage,
  onSubmit,
  onDelete,
  dragHandleProps,
}: any) => {
  const theme = MUI.useTheme();

  const { register, handleSubmit, control, watch } = useForm({
    defaultValues: {
      text: option?.text,
      audioUrl: option?.audioUrl,
      isCorrectAnswer: option?.isCorrectAnswer ?? false,
    },
  });

  const formFields = watch();

  const isChanged = React.useMemo(() => {
    if (!option) {
      return false;
    }

    return (
      formFields.text !== option.text ||
      formFields.audioUrl !== option.audioUrl ||
      formFields.isCorrectAnswer !== option.isCorrectAnswer
    );
  }, [option, formFields]);

  const isNew = !option;

  return (
    <MUI.Card
      elevation={5}
      variant={isNew ? 'elevation' : 'outlined'}
      style={{
        padding: 15,
        ...(isNew && {
          outlineColor: theme.palette.secondary.light,
          outlineWidth: 2,
          outlineStyle: 'solid',
          borderColor: 'transparent',
        }),
      }}
    >
      <MUI.Box
        display="flex"
        gridColumnGap={5}
        flexDirection="row"
        style={{ width: '100%' }}
      >
        <div
          style={{
            marginLeft: -6,
            display: 'flex',
          }}
          {...dragHandleProps}
        >
          {isNew ? (
            <MUI.Tooltip title="Save option to reorder.">
              <DragIndicatorIcon color="disabled" />
            </MUI.Tooltip>
          ) : (
            <DragIndicatorIcon />
          )}
        </div>
        <MUI.Box
          display="flex"
          gridColumnGap={10}
          alignItems="flex-start"
          width="100%"
        >
          <MUI.Box flexGrow={1}>
            <MUI.Collapse in={!!errorMessage}>
              <MUI.Box marginTop={1}>
                <Alert severity="error">{errorMessage}</Alert>
              </MUI.Box>
            </MUI.Collapse>

            <MUI.Box>
              <MUI.TextField
                label="Option Text"
                {...register('text')}
                variant="filled"
                size="small"
                margin="normal"
                multiline
                style={{ width: '80ch', marginTop: 0 }}
              />
            </MUI.Box>

            <MUI.Box marginTop={1} style={{ width: '80ch' }}>
              <MUI.FormLabel focused={false}>Option Audio</MUI.FormLabel>
              <Controller
                control={control}
                name="audioUrl"
                defaultValue={isNew ? null : option.audioUrl}
                render={({ field: { onChange, value } }) => (
                  <AudioUpload onChange={onChange} value={value} />
                )}
              />
            </MUI.Box>

            <MUI.Box marginTop={1}>
              <Controller
                control={control}
                name="isCorrectAnswer"
                defaultValue={isNew ? false : option.isCorrectAnswer}
                render={({ field: { onChange, value } }) => (
                  <MUI.FormControlLabel
                    control={
                      <MUI.Checkbox
                        checked={value}
                        onChange={onChange}
                        color="primary"
                      />
                    }
                    label="Correct Answer"
                  />
                )}
              />
            </MUI.Box>

            <MUI.Box marginTop={1} gridColumnGap={10} display="flex">
              <LoadingButton
                variant="contained"
                color="secondary"
                size="small"
                type="submit"
                isLoading={false}
                disabled={!isNew && !isChanged}
                onClick={handleSubmit(onSubmit)}
              >
                Save
              </LoadingButton>

              {!isNew && (
                <DeleteButton
                  onDelete={onDelete}
                  content="Are you sure you want to delete this option? This cannot be undone."
                />
              )}
            </MUI.Box>
          </MUI.Box>
        </MUI.Box>
      </MUI.Box>
    </MUI.Card>
  );
};

const NewMultipleChoiceOption = ({ multipleChoiceQuestionID, reset }: any) => {
  const [commitMutation, _isMutationInFlight] = useMutation(graphql`
    mutation MultipleChoiceOptionsEditor_CreateMultipleChoiceOption_Mutation(
      $multipleChoiceQuestionID: ID
      $text: String
      $audioUrl: String
      $isCorrectAnswer: Boolean
    ) {
      raCreateMultipleChoiceOption(
        multipleChoiceQuestionID: $multipleChoiceQuestionID
        text: $text
        audioUrl: $audioUrl
        isCorrectAnswer: $isCorrectAnswer
      ) {
        question {
          options {
            ...MultipleChoiceOptionsEditor_option
          }
        }
      }
    }
  `);

  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);

  const onSubmit = (data: any) => {
    setErrorMessage(null);

    commitMutation({
      variables: {
        multipleChoiceQuestionID,
        text: data.text,
        audioUrl: data.audioUrl,
        isCorrectAnswer: data.isCorrectAnswer,
      },
      onCompleted: (response, errors) => {
        if (errors && errors.length > 0) {
          setErrorMessage(errors[0].message);
          return;
        }

        reset();
      },
    });
  };

  return (
    <MultipleChoiceOptionCard errorMessage={errorMessage} onSubmit={onSubmit} />
  );
};

const MultipleChoiceOption = ({
  option,
  index,
  dragHandleProps,
}: {
  option: any;
  index: any;
  dragHandleProps?: any;
}) => {
  const data = useFragment(
    graphql`
      fragment MultipleChoiceOptionsEditor_option on MultipleChoiceOption {
        id

        text
        audioUrl
        order
        isCorrectAnswer
      }
    `,
    option,
  );

  const [commitMutation, _isMutationInFlight] = useMutation(graphql`
    mutation MultipleChoiceOptionsEditor_MultipleChoiceOption_Mutation(
      $id: ID
      $text: String
      $audioUrl: String
      $isCorrectAnswer: Boolean
    ) {
      raUpdateMultipleChoiceOption(
        id: $id
        text: $text
        audioUrl: $audioUrl
        isCorrectAnswer: $isCorrectAnswer
      ) {
        ...MultipleChoiceOptionsEditor_option
      }
    }
  `);

  const [errorMessage, _setErrorMessage] = React.useState<string | null>(null);

  const onSave = (formData: any) => {
    commitMutation({
      variables: {
        id: data.id,

        text: formData.text,
        audioUrl: formData.audioUrl,
        isCorrectAnswer: formData.isCorrectAnswer,
      },
    });
  };

  const [commitDeleteMutation, _isDeleteMutationInFlight] = useMutation(graphql`
    mutation MultipleChoiceOptionsEditor_MultipleChoiceOptionDelete_Mutation(
      $id: ID
    ) {
      raDeleteMultipleChoiceOption(id: $id) {
        nodeID @deleteRecord
      }
    }
  `);

  const [cachedMultipleChoiceOption, setCachedMultipleChoiceOption] =
    React.useState<null | typeof data>(null);

  const onDelete = () => {
    setCachedMultipleChoiceOption(data);
    commitDeleteMutation({
      variables: {
        id: data.id,
      },
    });
  };

  return (
    <MultipleChoiceOptionCard
      option={data || cachedMultipleChoiceOption}
      index={index}
      errorMessage={errorMessage}
      onSubmit={onSave}
      onDelete={onDelete}
      dragHandleProps={dragHandleProps}
    />
  );
};

export default MultipleChoiceOptionsEditor;
