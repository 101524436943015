import { Box, Typography } from '@material-ui/core';
import graphql from 'babel-plugin-relay/macro';
import React from 'react';
import {
  BooleanInput,
  DeleteButton,
  Edit,
  FormWithRedirect,
  SaveButton,
  TextInput,
  Toolbar,
} from 'react-admin';
import { useLazyLoadQuery } from 'react-relay';

import VideoGroupVideosEditor from './VideoGroupEdit/VideoGroupVideosEditor';

const VideoGroupForm = (props: any) => (
  <FormWithRedirect
    {...props}
    render={(formProps: any) => (
      <form>
        <Box p="1em">
          <Typography variant="h6" gutterBottom>
            Video Group
          </Typography>

          <TextInput
            autoFocus
            source="title"
            label="Title"
            fullWidth
            helperText={false}
          />

          <BooleanInput
            source="prerequisite"
            label="Prerequisite Video (see them once and you never see it again)"
            fullWidth
            helperText={false}
          />
        </Box>
        <Toolbar
          record={formProps.record}
          basePath={formProps.basePath}
          undoable
          invalid={formProps.invalid}
          handleSubmit={formProps.handleSubmit}
          saving={formProps.saving}
          resource="g:VideoGroup"
          style={{ marginTop: 0 }}
        >
          <Box display="flex" justifyContent="space-between" width="100%">
            <SaveButton
              label="Save"
              handleSubmitWithRedirect={
                formProps.handleSubmitWithRedirect || formProps.handleSubmit
              }
              disabled={formProps.disabled}
              invalid={formProps.invalid}
              redirect="edit"
              saving={formProps.saving}
              submitOnEnter={formProps.submitOnEnter}
            />

            {props.record && typeof props.record.id !== 'undefined' && (
              <DeleteButton
                basePath={formProps.basePath}
                record={formProps.record}
                resource={formProps.resource}
                undoable={formProps.undoable}
                mutationMode={formProps.mutationMode}
              />
            )}
          </Box>
        </Toolbar>
      </form>
    )}
  />
);

const VideoGroupEdit = (props: any) => {
  const data: any = useLazyLoadQuery(
    graphql`
      query VideoGroupEditQuery($id: ID) {
        raVideo(id: $id) {
          ...VideoGroupVideosEditor_video
        }
      }
    `,
    { id: props.id },
  );

  return (
    <Box display="flex" flexDirection="column" gridRowGap={20}>
      <Edit {...props}>
        <VideoGroupForm />
      </Edit>

      <VideoGroupVideosEditor videoGroup={data?.raVideo} />
    </Box>
  );
};

export default VideoGroupEdit;
