import * as React from 'react';
import {
  List,
  Datagrid,
  TextField,
  DateField,
  TextInput,
  BooleanInput,
  Filter,
} from 'react-admin';
import UserLinkField from './UserLinkField';

const NotificationLogsFilter = (props: any) => (
  <Filter {...props}>
    <TextInput
      label="User First Name"
      source="User.firstName"
      defaultValue=""
    />
    <TextInput label="User Last Name" source="User.lastName" defaultValue="" />
    <TextInput
      label="Notification ID"
      source="notificationId"
      defaultValue=""
    />
    <TextInput label="Title" source="title" defaultValue="" />
    <TextInput label="Body" source="body" defaultValue="" />
    <BooleanInput label="Notif Seen" source="readAt" defaultValue={false} />
  </Filter>
);

export const NotificationLogsList = (props: any) => (
  <List
    {...props}
    perPage={10}
    sort={{ field: 'createdAt', order: 'DESC' }}
    filters={<NotificationLogsFilter />}
  >
    <Datagrid rowClick="expand">
      <UserLinkField />
      <TextField source="notificationId" label="Notification ID" />
      <TextField source="title" label="Title" />
      <TextField source="body" label="Body" />
      <DateField showTime source="createdAt" label="Sent At" />
      <DateField showTime source="readAt" label="Read" />
    </Datagrid>
  </List>
);

export default NotificationLogsList;
