import * as React from 'react';
import { FC, memo } from 'react';

import { FieldProps } from 'react-admin';

interface Props extends FieldProps {
  unit?: any;
}

const UnitNameField: FC<Props> = ({ record, unit }) => {
  const name = record?.units?.find((unitItem: any) => unitItem.id === unit)
    ?.name;
  const curriculumName = record?.units?.find(
    (unitItem: any) => unitItem.id === unit,
  )?.Curriculum?.name;
  return record && name ? (
    <div>
      {curriculumName} - {name}
    </div>
  ) : null;
};

UnitNameField.defaultProps = {
  source: 'name',
  label: 'Name',
};

export default memo<Props>(UnitNameField);
