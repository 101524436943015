/**
 * @generated SignedSource<<103c426e63d34d925824e5d251d656ab>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type VideoGroupVideosEditor_videoVariant$data = {
  readonly duration: number | null | undefined;
  readonly id: string | null | undefined;
  readonly tags: string | null | undefined;
  readonly thumbnailURL: string | null | undefined;
  readonly title: string | null | undefined;
  readonly variantRules: any | null | undefined;
  readonly " $fragmentType": "VideoGroupVideosEditor_videoVariant";
};
export type VideoGroupVideosEditor_videoVariant$key = {
  readonly " $data"?: VideoGroupVideosEditor_videoVariant$data;
  readonly " $fragmentSpreads": FragmentRefs<"VideoGroupVideosEditor_videoVariant">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "VideoGroupVideosEditor_videoVariant",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "variantRules",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "thumbnailURL",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "duration",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "tags",
      "storageKey": null
    }
  ],
  "type": "VideoVariant",
  "abstractKey": null
};

(node as any).hash = "55f9dbccb0092f1593ca39dd4e477c79";

export default node;
