import * as MUI from '@material-ui/core';
import AddBoxIcon from '@material-ui/icons/AddBox';
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';
import { Alert, AlertTitle } from '@material-ui/lab';
import React from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { TransitionGroup } from 'react-transition-group';

const Reorderable = ({
  items,
  onSaveOrder,
  resourceName,
  droppableID,
  renderItem,
  renderNewItem,
}: any) => {
  const originalOrder = React.useMemo(
    () => items.filter((item: any) => item).map((item: any) => item.id),
    [items],
  );

  const [clientOrderedItems, setClientOrderedItems] = React.useState(items);

  React.useEffect(() => {
    setClientOrderedItems(items.filter((item: any) => item));
  }, [items]);

  const orderChanged = React.useMemo(
    () =>
      originalOrder.join(',') !==
      clientOrderedItems.map((item: any) => item.id).join(','),
    [originalOrder, clientOrderedItems],
  );

  const onDragEnd = (result: any) => {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const newClientOrderedItems = Array.from(clientOrderedItems);
    const [removed] = newClientOrderedItems.splice(result.source.index, 1);
    newClientOrderedItems.splice(result.destination.index, 0, removed);
    setClientOrderedItems(newClientOrderedItems);
  };

  const handleSaveOrder = () => {
    onSaveOrder(clientOrderedItems.map((item: any) => item.id));
  };

  const [showNewItem, setShowNewItem] = React.useState(false);
  const [newItemKey, setNewItemKey] = React.useState(0);

  const resetNewItem = React.useCallback(() => {
    setShowNewItem(false);
    setNewItemKey((prev) => prev + 1);
  }, []);

  const theme = MUI.useTheme();

  return (
    <MUI.Box>
      <MUI.Collapse
        in={orderChanged}
        style={{ position: 'sticky', top: 0, zIndex: 1200 }}
      >
        <MUI.Box
          style={{ backgroundColor: theme.palette.background.default }}
          paddingBottom={2}
          paddingTop={1}
        >
          <Alert
            severity="warning"
            icon={<FormatListNumberedIcon fontSize="inherit" />}
          >
            <AlertTitle>Order Edited</AlertTitle>
            The {resourceName || 'item'} order has been edited, but not saved.
            <MUI.Box marginTop={2}>
              <MUI.Button
                variant="contained"
                color="primary"
                size="small"
                onClick={handleSaveOrder}
              >
                Save Order
              </MUI.Button>
            </MUI.Box>
          </Alert>
        </MUI.Box>
      </MUI.Collapse>

      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId={droppableID}>
          {(provided) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              <TransitionGroup>
                {clientOrderedItems.map((item: any, index: number) => (
                  <MUI.Collapse in={item} key={item.id}>
                    <DraggableItem
                      itemID={item.id}
                      item={item}
                      index={index}
                      renderItem={renderItem}
                    />
                  </MUI.Collapse>
                ))}
                {provided.placeholder}
              </TransitionGroup>
            </div>
          )}
        </Droppable>
      </DragDropContext>

      <MUI.Collapse in={showNewItem} className="MuiCollapse-overflowY">
        <MUI.Box marginTop={2} key={newItemKey}>
          {renderNewItem(resetNewItem)}
        </MUI.Box>
      </MUI.Collapse>

      <MUI.Box marginTop={3}>
        <MUI.Button
          variant="outlined"
          color="secondary"
          startIcon={<AddBoxIcon />}
          onClick={() => setShowNewItem(true)}
        >
          Add {resourceName || 'item'}
        </MUI.Button>
      </MUI.Box>
    </MUI.Box>
  );
};

const DraggableItem = ({ itemID, index, item, renderItem }: any) => (
  <Draggable draggableId={itemID} index={index}>
    {(provided) => (
      <div
        ref={provided.innerRef}
        {...provided.draggableProps}
        style={{ marginBottom: 10, ...provided.draggableProps.style }}
      >
        {renderItem(item, index, provided.dragHandleProps)}
      </div>
    )}
  </Draggable>
);

export default Reorderable;
