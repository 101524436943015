import * as React from 'react';
import { FC, memo } from 'react';

import { FieldProps } from 'react-admin';

interface Props extends FieldProps {
  lessonId?: any;
}

const Line = () => (
  <div style={{ width: '100%', borderBottom: '1px solid #4f3cc9' }} />
);

const LessonNameField: FC<Props> = ({ record, lessonId }) => {
  const name = record?.lessons?.find((lesson: any) => lesson.id === lessonId)
    ?.name;
  return record && name ? (
    <>
      <div>{name}</div>
      <Line />
    </>
  ) : null;
};

LessonNameField.defaultProps = {
  source: 'name',
  label: 'Name',
};

export default memo<Props>(LessonNameField);
