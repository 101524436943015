import * as React from 'react';
import { FC } from 'react';
import { Link, FieldProps } from 'react-admin';

import LessonNameField from './LessonNameField';

const LessonLinkField: FC<FieldProps> = (props) => {
  const lessonIds = props.record?.lessonId;

  return lessonIds?.length
    ? lessonIds.map((lessonId: any) => (
        <Link key={lessonId} to={`/content/lesson/${lessonId}`}>
          <LessonNameField {...props} lessonId={lessonId} />
        </Link>
      ))
    : null;
};

LessonLinkField.defaultProps = {
  label: 'Lesson(s)',
  addLabel: true,
};

export default LessonLinkField;
