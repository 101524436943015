import * as MUI from '@material-ui/core';
import AddBoxIcon from '@material-ui/icons/AddBox';
import { Alert } from '@material-ui/lab';
import graphql from 'babel-plugin-relay/macro';
import React, { useState } from 'react';
import { Link } from 'react-admin';
import { useFragment, useMutation } from 'react-relay';

import JSONField from '../../components/JSONField';
import { DeleteButton } from '../../components/UserJourney/Buttons';
import { VideoVariantSelect } from '../../components/VideoVariantSelect';

const VideoGroupVideosEditor = ({ videoGroup }: any) => {
  const [showNewItem, setShowNewItem] = React.useState(false);
  const [newItemKey, setNewItemKey] = React.useState(0);

  const resetNewItem = React.useCallback(() => {
    setShowNewItem(false);
    setNewItemKey((prev) => prev + 1);
  }, []);

  const data = useFragment(
    graphql`
      fragment VideoGroupVideosEditor_video on Video {
        id

        videoVariants {
          id
          ...VideoGroupVideosEditor_videoVariant
        }
      }
    `,
    videoGroup,
  );

  const [
    commitRemoveVideoVariantMutation,
    _isRemoveVideoVariantMutationInFlight,
  ] = useMutation(graphql`
    mutation VideoGroupVideosEditor_RemoveVideoVariant_Mutation(
      $videoID: ID!
      $id: ID!
    ) {
      removeVideoVariantFromVideo(videoID: $videoID, id: $id) {
        id

        videoVariants {
          id
          ...VideoGroupVideosEditor_videoVariant
        }
      }
    }
  `);

  const onDelete = (videoVariantId) => {
    commitRemoveVideoVariantMutation({
      variables: {
        videoID: data.id,
        id: videoVariantId,
      },
    });
  };

  return (
    <MUI.Box>
      <MUI.Typography variant="h6" gutterBottom>
        Videos
      </MUI.Typography>

      {data.videoVariants.filter(Boolean).map((video: any) => (
        <Video key={video.id} video={video} onDelete={onDelete} />
      ))}

      <MUI.Collapse in={showNewItem} className="MuiCollapse-overflowY">
        <MUI.Box marginTop={2} key={newItemKey}>
          <NewVideoVariant videoID={data.id} reset={resetNewItem} />
        </MUI.Box>
      </MUI.Collapse>

      <MUI.Box marginTop={3}>
        <MUI.Button
          variant="outlined"
          color="secondary"
          startIcon={<AddBoxIcon />}
          onClick={() => setShowNewItem(true)}
        >
          Add Video Variant
        </MUI.Button>
      </MUI.Box>
    </MUI.Box>
  );
};

const NewVideoVariant = ({ videoID, reset }: any) => {
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);

  const [commitMutation, _isMutationInFlight] = useMutation(graphql`
    mutation VideoGroupVideosEditor_AddVideoVariant_Mutation(
      $videoID: ID!
      $id: ID!
    ) {
      addVideoVariantToVideo(videoID: $videoID, id: $id) {
        id

        videoVariants {
          id
          ...VideoGroupVideosEditor_videoVariant
        }
      }
    }
  `);

  const onSubmit = (data: any) => {
    setErrorMessage(null);

    commitMutation({
      variables: {
        videoID,
        id: data.id,
      },
      onCompleted: (response, errors) => {
        if (errors && errors.length > 0) {
          setErrorMessage(errors[0].message);
          return;
        }

        reset();
      },
    });
  };

  return <BaseVideoCard data={{ videoID, errorMessage }} onSave={onSubmit} />;
};

const Video = ({ video, onDelete }: any) => {
  const data = useFragment(
    graphql`
      fragment VideoGroupVideosEditor_videoVariant on VideoVariant {
        id
        variantRules
        title
        thumbnailURL
        duration
        tags
      }
    `,
    video,
  );

  const [variantRules, setVariantRules] = React.useState(
    data.variantRules && JSON.stringify(data.variantRules, null, 2),
  );

  const [commitMutation, _isMutationInFlight] = useMutation(graphql`
    mutation VideoGroupVideosEditorVideoVariantMutation(
      $id: ID
      $variantRules: JSONObject
    ) {
      raUpdateVideoVariant(id: $id, variantRules: $variantRules) {
        ...VideoGroupVideosEditor_videoVariant
      }
    }
  `);

  const onSave = () => {
    commitMutation({
      variables: {
        id: data.id,
        variantRules: JSON.parse(variantRules),
      },
    });
  };

  return (
    <BaseVideoCard
      data={data}
      variantRules={variantRules}
      setVariantRules={setVariantRules}
      onDelete={onDelete}
      onSave={onSave}
    />
  );
};

const BaseVideoCard = ({
  data,
  variantRules,
  setVariantRules,
  onSave,
  onDelete,
}: any) => {
  const isNew = !!data.videoID;
  const [newVideoVariantId, setNewVideoVariantId] = useState();

  const onSaveWithParams = (params) => {
    if (isNew) {
      return onSave({ id: newVideoVariantId });
    }

    return onSave(params);
  };

  return (
    <MUI.Card elevation={1} style={{ marginBottom: 10 }}>
      <MUI.CardContent>
        <MUI.Collapse in={!!data?.errorMessage}>
          <MUI.Box marginTop={1}>
            <Alert severity="error">{data?.errorMessage}</Alert>
          </MUI.Box>
        </MUI.Collapse>
        <MUI.Box display="flex">
          <MUI.Card
            style={{
              height: 110,
              width: 60,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: '#000',
              border: 'none',
            }}
          >
            <img src={data?.thumbnailURL} style={{ maxWidth: '100%' }} />
          </MUI.Card>
          <MUI.Box flexGrow={1} marginLeft={2}>
            <MUI.Box marginBottom={1}>
              {isNew ? (
                <>
                  <MUI.Typography
                    color="textSecondary"
                    style={{ fontSize: 14, marginBottom: 12 }}
                    gutterBottom
                  >
                    New Video Variant
                  </MUI.Typography>

                  <VideoVariantSelect onChange={setNewVideoVariantId} />
                </>
              ) : (
                <>
                  <Link to={`/content/video/${data?.id}`} target="_blank">
                    <MUI.Typography variant="subtitle1">
                      {data?.title}
                    </MUI.Typography>
                  </Link>
                  <MUI.Typography color="textSecondary" variant="body2">
                    Tags: {data?.tags}
                  </MUI.Typography>
                </>
              )}
            </MUI.Box>

            {!isNew && (
              <MUI.Box marginBottom={2}>
                <MUI.Box>
                  <MUI.Typography
                    color="textSecondary"
                    style={{ fontSize: 14 }}
                  >
                    Duration
                  </MUI.Typography>
                </MUI.Box>
                {`${Math.floor(data?.duration / 60)}:${`0${
                  data?.duration % 60
                }`.slice(-2)}`}
              </MUI.Box>
            )}

            {!isNew && (
              <JSONField
                label="Variant Rules (JSON)"
                value={variantRules || ''}
                onChange={(e: any) => setVariantRules(e.target.value)}
              />
            )}

            <MUI.Box
              marginTop={2}
              paddingTop={1}
              gridColumnGap={10}
              display="flex"
            >
              <MUI.Button
                variant="outlined"
                color="primary"
                size="small"
                onClick={onSaveWithParams}
              >
                Save
              </MUI.Button>
              {!isNew && (
                <DeleteButton
                  onDelete={() => onDelete(data?.id)}
                  content="Are you sure you want to delete this video variant? This cannot be undone."
                />
              )}
            </MUI.Box>
          </MUI.Box>
        </MUI.Box>
      </MUI.CardContent>
    </MUI.Card>
  );
};

export default VideoGroupVideosEditor;
