import FontFaceObserver from 'fontfaceobserver';
import { createRoot } from 'react-dom/client';

import App from './App';
import { IPATextQueryProvider } from './components/IPAText';
import { DataProvider } from './contexts';
import './index.css';
import { AppRelayEnvironmentProvider } from './relay';
import * as serviceWorker from './serviceWorker';

const fontObserver = new FontFaceObserver('Roboto', {});
fontObserver.load().then(() => {
  document.body.classList.add('fontLoaded');
});

const MOUNT_NODE = document.getElementById('root');
const root = createRoot(MOUNT_NODE as HTMLElement);

root.render(
  <AppRelayEnvironmentProvider>
    <IPATextQueryProvider>
      <DataProvider>
        <App />
      </DataProvider>
    </IPATextQueryProvider>
  </AppRelayEnvironmentProvider>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
