import * as React from 'react';
import { FC } from 'react';
import { Link, FieldProps } from 'react-admin';

import LessonNameField from './LessonNameField';

const LessonLinkField: FC<FieldProps> = (props) => {
  const lessons = props.record?.lessonId;

  return lessons?.length
    ? lessons.map((lesson: any) => (
        <Link key={lesson} to={`/content/lesson/${lesson}`}>
          <LessonNameField {...props} lesson={lesson} />
        </Link>
      ))
    : null;
};

LessonLinkField.defaultProps = {
  label: 'Lesson(s)',
  addLabel: true,
};

export default LessonLinkField;
