import * as MUI from '@material-ui/core';
import { Box } from '@material-ui/core';
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';
import { Alert, AlertTitle } from '@material-ui/lab';
import graphql from 'babel-plugin-relay/macro';
import React, { useCallback, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useFragment, useMutation } from 'react-relay';

import PhonemesSelect from '../components/PhonemesSelect';

function SkillFormAssociatedPhonemes({ skill }) {
  const data = useFragment(
    graphql`
      fragment SkillFormAssociatedPhonemes_phoneme on Skill {
        id
        phonemes {
          id
          ipa
        }
      }
    `,
    skill,
  );

  const [commitAssociatedPhonemesMutation, _isReorderMutationInFlight] =
    useMutation(graphql`
      mutation SkillFormAssociatedPhonemes_AssociatedPhonemes_Mutation(
        $skillID: ID!
        $newPhonemeIDs: [ID!]!
      ) {
        changeAssociatedPhonemes(
          skillID: $skillID
          newPhonemeIDs: $newPhonemeIDs
        ) {
          ...SkillFormAssociatedPhonemes_phoneme
        }
      }
    `);

  const phonemeIDs = useMemo(
    () => (data?.phonemes ?? [])?.map((i) => i.id),
    [data?.phonemes],
  );

  const { control, watch } = useForm({
    defaultValues: {
      phonemeIDs,
    },
  });

  const formFields = watch();

  const isChanged = React.useMemo(
    () =>
      [...(formFields.phonemeIDs ?? [])].sort().join(',') !==
      [...(phonemeIDs ?? [])].sort().join(','),
    [phonemeIDs, formFields],
  );

  const handleSaveAssociatedPhonemes = useCallback(() => {
    commitAssociatedPhonemesMutation({
      variables: {
        skillID: data.id,
        newPhonemeIDs: formFields.phonemeIDs,
      },
    });
  }, [formFields, data.id]);

  return (
    <MUI.Card>
      <Box style={{ width: '100%', padding: '1em' }}>
        <MUI.FormLabel>Phonemes</MUI.FormLabel>

        <Controller
          control={control}
          name="phonemeIDs"
          defaultValue={phonemeIDs}
          render={({ field: { onChange, onBlur, value } }) => (
            <PhonemesSelect onChange={onChange} onBlur={onBlur} value={value} />
          )}
        />

        <MUI.Collapse
          in={isChanged}
          style={{ position: 'sticky', bottom: 0, zIndex: 1200 }}
        >
          <Box marginBottom={2} />
          <MUI.Box paddingBottom={2} paddingTop={1}>
            <Alert
              severity="warning"
              icon={<FormatListNumberedIcon fontSize="inherit" />}
            >
              <AlertTitle>Phonemes changed</AlertTitle>
              Phonemes have been edited, but not saved.
              <MUI.Box marginTop={2}>
                <MUI.Button
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={handleSaveAssociatedPhonemes}
                >
                  Save Changes
                </MUI.Button>
              </MUI.Box>
            </Alert>
          </MUI.Box>
        </MUI.Collapse>
      </Box>
    </MUI.Card>
  );
}

export default SkillFormAssociatedPhonemes;
