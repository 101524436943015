import * as React from 'react';
import { FC, memo } from 'react';
import { FieldProps } from 'react-admin';

interface Props extends FieldProps {
  Lesson?: any;
}

const Line = () => <div style={{ width: '100%' }} />;

const LessonNameField: FC<Props> = ({ record }) => {
  const name = record?.Lesson?.name;
  return record && name ? (
    <>
      <div>{name}</div>
      <Line />
    </>
  ) : null;
};

LessonNameField.defaultProps = {
  source: 'name',
  label: 'Name',
};

export default memo<Props>(LessonNameField);
