import { Box, Card, CardContent } from '@material-ui/core';
import { Theme, makeStyles } from '@material-ui/core/styles';
import { Styles } from '@material-ui/styles/withStyles';
import * as React from 'react';
import { FC } from 'react';
import {
  AutocompleteArrayInput,
  BooleanInput,
  DeleteButton,
  Edit,
  EditProps,
  FieldProps,
  FormWithRedirect,
  ReferenceArrayInput,
  SaveButton,
  TextInput,
  Toolbar,
} from 'react-admin';

import OrderedArrayInput from '../components/OrderedArrayInput';
import { matchSuggestion } from '../lesson/utils';
import AssessmentPracticeInput from '../components/AssessmentPracticeInput';

const AssessmentTitle: FC<FieldProps> = ({ record }) =>
  record ? (
    <span>
      Assessment &quot;
      {record.name}&quot;
    </span>
  ) : null;

export const styles: Styles<Theme, any> = {
  name: { display: 'inline-block' },
  photo: { display: 'inline-block' },
  spacing: { marginRight: 10 },
  image: {
    margin: '0.5rem',
    maxHeight: '10rem',
  },
  button: {
    marginRight: 10,
  },
};

const useStyles = makeStyles(styles);

const AssessmentForm = (props: any) => {
  const classes = useStyles(props);
  return (
    <FormWithRedirect
      {...props}
      render={(formProps: any) => (
        <Card>
          <form>
            <CardContent>
              <Box>
                <TextInput
                  fullWidth
                  autoFocus
                  source="name"
                  label="Assessment Name"
                  formClassName={classes.name}
                />
              </Box>
              <Box>
                <ReferenceArrayInput
                  filterToQuery={(searchText: any) => ({ name: searchText })}
                  label="Units"
                  source="unitId"
                  reference="content/unit"
                  fullWidth
                >
                  <OrderedArrayInput elementLinkUrl="/#/content/unit/">
                    <AutocompleteArrayInput
                      optionValue="id"
                      optionText={(record: any) =>
                        `${record?.Curriculum?.name} - ${record?.name}`
                      }
                      matchSuggestion={(filterValue: string, choice: any) =>
                        matchSuggestion(filterValue, choice, 'name')
                      }
                    />
                  </OrderedArrayInput>
                </ReferenceArrayInput>
              </Box>
              <Box alignSelf="flex-end">
                <BooleanInput
                  source="isBenchmark"
                  label="Is Benchmarking?"
                  helperText={false}
                />
              </Box>
              <Box>
                <ReferenceArrayInput
                  filterToQuery={(searchText: any) => ({ word: searchText })}
                  label="Practice Items"
                  source="practiceId"
                  reference="content/practice"
                  filter={{ isUserGeneratedContent: false }}
                >
                  <AssessmentPracticeInput>
                    <AutocompleteArrayInput
                      optionValue="id"
                      optionText={(wordRecord: any) =>
                        `${wordRecord?.word} ${
                          wordRecord?.coachSample ? ` ✅` : ` ❌`
                        }`
                      }
                      matchSuggestion={(filterValue: string, choice: any) =>
                        matchSuggestion(filterValue, choice, 'word')
                      }
                    />
                  </AssessmentPracticeInput>
                </ReferenceArrayInput>
              </Box>
              <Toolbar
                record={formProps.record}
                basePath={formProps.basePath}
                undoable={false}
                invalid={formProps.invalid}
                handleSubmit={formProps.handleSubmit}
                saving={formProps.saving}
                resource="practices"
                style={{ display: 'flex', justifyContent: 'space-between' }}
              >
                <div>
                  <SaveButton
                    label="Save & Continue"
                    handleSubmitWithRedirect={
                      formProps.handleSubmitWithRedirect ||
                      formProps.handleSubmit
                    }
                    disabled={formProps.disabled}
                    invalid={formProps.invalid}
                    redirect="edit"
                    saving={formProps.saving}
                    submitOnEnter={formProps.submitOnEnter}
                    className={classes.button}
                  />

                  <SaveButton
                    label="Save & Exit"
                    handleSubmitWithRedirect={
                      formProps.handleSubmitWithRedirect ||
                      formProps.handleSubmit
                    }
                    disabled={formProps.disabled}
                    invalid={formProps.invalid}
                    redirect={formProps.redirect}
                    saving={formProps.saving}
                    submitOnEnter={formProps.submitOnEnter}
                    className={classes.button}
                  />
                </div>

                {formProps.record &&
                  typeof formProps.record.id !== 'undefined' && (
                    <DeleteButton
                      basePath={formProps.basePath}
                      record={formProps.record}
                      resource={formProps.resource}
                      undoable={formProps.undoable}
                      mutationMode={formProps.mutationMode}
                    />
                  )}
              </Toolbar>
            </CardContent>
          </form>
        </Card>
      )}
    />
  );
};

const AssessmentEdit: FC<EditProps> = (props) => (
  <Edit undoable={false} title={<AssessmentTitle />} {...props}>
    <AssessmentForm />
  </Edit>
);

export default AssessmentEdit;
