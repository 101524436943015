import * as MUI from '@material-ui/core';
import * as MUIIcons from '@material-ui/icons';
import Autocomplete from '@material-ui/lab/Autocomplete';
import graphql from 'babel-plugin-relay/macro';
import React from 'react';
import ReactAudioPlayer from 'react-audio-player';
import {
  useFragment,
  useLazyLoadQuery,
  useRefetchableFragment,
} from 'react-relay';

import api from '../api';
import IPADrawer from '../components/IPADrawer';
import IPAText from '../components/IPAText';

const PhoneticSearch = () => {
  const [isPending, startTransition] = React.useTransition();

  const [search, setSearch] = React.useState('');
  const [exclude, setExclude] = React.useState('');
  const [selectedLanguages, setSelectedLanguages] = React.useState<string[]>(
    [],
  );

  const queryRef: any = useLazyLoadQuery(
    graphql`
      query PhoneticSearch_Query {
        ...PhoneticSearch_allLanguages
        ...PhoneticSearch_audioLogsForPhoneticSearch
      }
    `,
    {},
    { fetchPolicy: 'network-only' },
  );

  const allLanguagesData = useFragment(
    graphql`
      fragment PhoneticSearch_allLanguages on Query {
        allLanguages {
          code
          englishName
        }
      }
    `,
    queryRef,
  );

  const [audioLogsData, refetch] = useRefetchableFragment(
    graphql`
      fragment PhoneticSearch_audioLogsForPhoneticSearch on Query
      @refetchable(queryName: "PhoneticSearchRefetchQuery")
      @argumentDefinitions(
        search: { type: "String", defaultValue: "" }
        exclude: { type: "String", defaultValue: "" }
        languages: { type: "[String!]" }
      ) {
        audioLogsForPhoneticSearch(
          search: $search
          exclude: $exclude
          languages: $languages
        ) {
          id
          audioFile

          practice {
            text
          }

          user {
            id

            languages {
              englishName
            }
          }
        }
      }
    `,
    queryRef,
  );

  const audioLogs = audioLogsData?.audioLogsForPhoneticSearch ?? [];

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      startTransition(() => {
        refetch(
          { search, exclude, languages: selectedLanguages },
          { fetchPolicy: 'network-only' },
        );
      });
    }, 500);
    return () => clearTimeout(timeout);
  }, [search, exclude, selectedLanguages]);

  const [isIPADrawerOpen, setIsIPADrawerOpen] = React.useState(false);

  return (
    <MUI.Box
      style={{ height: 'calc(100vh - 100px)' }}
      display="flex"
      flexDirection="column"
    >
      <IPADrawer isOpen={isIPADrawerOpen} setIsOpen={setIsIPADrawerOpen} />
      <MUI.Box marginBottom={3} marginTop={2}>
        <MUI.Typography variant="h4" gutterBottom>
          Audio Log Search
        </MUI.Typography>
      </MUI.Box>

      <MUI.Box>
        <MUI.Box display="flex" gridColumnGap={16}>
          <MUI.TextField
            variant="outlined"
            label="IPA Segment"
            onChange={(e) => setSearch(e.target.value)}
            InputProps={{
              startAdornment: (
                <MUI.InputAdornment position="start" style={{ marginRight: 3 }}>
                  [
                </MUI.InputAdornment>
              ),
              endAdornment: (
                <MUI.InputAdornment position="end" style={{ marginLeft: 3 }}>
                  ]
                </MUI.InputAdornment>
              ),
            }}
            style={{ width: 100 }}
          />

          <MUI.TextField
            variant="outlined"
            label="Exclude Seg."
            onChange={(e) => setExclude(e.target.value)}
            InputProps={{
              startAdornment: (
                <MUI.InputAdornment position="start" style={{ marginRight: 3 }}>
                  [
                </MUI.InputAdornment>
              ),
              endAdornment: (
                <MUI.InputAdornment position="end" style={{ marginLeft: 3 }}>
                  ]
                </MUI.InputAdornment>
              ),
            }}
            style={{ width: 100 }}
          />

          <Autocomplete
            id="combo-box-languages"
            multiple
            options={allLanguagesData.allLanguages}
            getOptionLabel={(option: any) => option.englishName}
            style={{ marginBottom: 10, width: 400 }}
            autoHighlight
            disableCloseOnSelect
            onChange={(_, value) =>
              setSelectedLanguages(value.map((v) => v.code))
            }
            renderInput={(params) => (
              <MUI.TextField
                {...params}
                label="Native Language"
                variant="outlined"
              />
            )}
          />

          {isPending && (
            <MUI.CircularProgress
              size={24}
              style={{ alignSelf: 'center', marginBottom: 10, marginLeft: 10 }}
            />
          )}
        </MUI.Box>
        <MUI.Box style={{ marginTop: -5 }}>
          <MUI.FormHelperText>
            <MUI.Link
              onClick={() => setIsIPADrawerOpen(true)}
              style={{ cursor: 'pointer' }}
            >
              View supported IPA segments.
            </MUI.Link>
          </MUI.FormHelperText>
        </MUI.Box>
      </MUI.Box>

      <MUI.Box display="flex" style={{ minHeight: 0 }} marginTop={2}>
        <MUI.Paper variant="outlined" style={{ width: '100%', height: '100%' }}>
          <MUI.TableContainer style={{ maxHeight: '100%' }}>
            <MUI.Table stickyHeader>
              {audioLogs.length === 0 ? (
                <caption>No results.</caption>
              ) : (
                <caption>Max 201 results, 3 unique users per word.</caption>
              )}
              <MUI.TableHead>
                <MUI.TableRow>
                  <MUI.TableCell>Word</MUI.TableCell>
                  <MUI.TableCell>Audio</MUI.TableCell>
                  <MUI.TableCell>Languages</MUI.TableCell>
                  <MUI.TableCell>Audio Log</MUI.TableCell>
                  <MUI.TableCell>User</MUI.TableCell>
                </MUI.TableRow>
              </MUI.TableHead>
              <MUI.TableBody>
                {audioLogs.map((audioLog) => (
                  <MUI.TableRow key={audioLog.id}>
                    <MUI.TableCell>
                      <MUI.Link
                        href={`/#/g/words/${encodeURIComponent(audioLog.practice.text)}`}
                        target="_blank"
                        color="inherit"
                      >
                        <IPAText text={audioLog.practice.text} />
                      </MUI.Link>
                    </MUI.TableCell>
                    <MUI.TableCell style={{ paddingTop: 5, paddingBottom: 0 }}>
                      <AudioPlayer audioFile={audioLog.audioFile} />
                    </MUI.TableCell>
                    <MUI.TableCell>
                      {audioLog.user.languages
                        ?.map((l) => l.englishName)
                        ?.join(', ')}
                    </MUI.TableCell>
                    <MUI.TableCell>
                      <MUI.Box
                        display="inline-flex"
                        alignItems="center"
                        gridColumnGap={6}
                      >
                        <MUI.Link
                          href={`/#/audiologs?displayedFilters=%7B%22id%22%3Atrue%7D&filter=%7B%22id%22%3A%22${audioLog.id}%22%7D&order=DESC&page=1&perPage=10&sort=createdAt`}
                          target="_blank"
                          color="inherit"
                        >
                          <span
                            style={{
                              fontFamily: 'monospace',
                              fontSize: '0.8rem',
                            }}
                          >
                            {audioLog.id}
                          </span>
                        </MUI.Link>
                        <CopyToClipboard text={audioLog.id} />
                      </MUI.Box>
                    </MUI.TableCell>
                    <MUI.TableCell>
                      <MUI.Box
                        display="inline-flex"
                        alignItems="center"
                        gridColumnGap={6}
                      >
                        <MUI.Link
                          href={`/#/users/${audioLog.user.id}`}
                          target="_blank"
                          color="inherit"
                        >
                          <span
                            style={{
                              fontFamily: 'monospace',
                              fontSize: '0.8rem',
                            }}
                          >
                            {audioLog.user.id}
                          </span>
                        </MUI.Link>
                        <CopyToClipboard text={audioLog.id} />
                      </MUI.Box>
                    </MUI.TableCell>
                  </MUI.TableRow>
                ))}
              </MUI.TableBody>
            </MUI.Table>
          </MUI.TableContainer>
        </MUI.Paper>
      </MUI.Box>
    </MUI.Box>
  );
};

const AudioPlayer = (props: any) => {
  const [audioFile, setAudioFile] = React.useState<any>();
  const [error, setError] = React.useState<any>();

  React.useEffect(() => {
    const getAudioFile = async () => {
      setError(undefined);
      if (props.audioFile) {
        try {
          const newAudioFile = await api.getAudioFile(
            props.audioFile.split('/')[1],
          );
          if (newAudioFile.status === 403) {
            const jsonResult = await newAudioFile.json();
            if (!jsonResult?.success) {
              setError('No audio file at URL provided.');
            }
          } else {
            const fileBlob = await newAudioFile?.body?.blob();
            const reader = new FileReader();
            reader.onload = function (e) {
              const base64audio = e?.target?.result;
              if (base64audio) {
                setAudioFile(base64audio);
              } else {
                setError('No audio file at URL provided.');
              }
            };
            fileBlob.end = fileBlob.size;
            reader.readAsDataURL(fileBlob);
          }
        } catch (err: any) {
          setError(err?.message);
        }
      }
    };

    getAudioFile();

    return setAudioFile(null);
  }, [props.audioFile]);

  const onError = (newError: any) => {
    setError(newError?.message);
  };

  return props.audioFile ? (
    <MUI.Box>
      {audioFile && !error && (
        <MUI.Box>
          <ReactAudioPlayer
            controls
            onError={onError}
            style={{ height: 30, width: 250 }}
          >
            <source src={audioFile} />
          </ReactAudioPlayer>
        </MUI.Box>
      )}

      {error && (
        <MUI.Typography variant="body1" gutterBottom>
          Error: {error}
        </MUI.Typography>
      )}
    </MUI.Box>
  ) : null;
};

const CopyToClipboard = (props) => (
  <MUI.Tooltip title="Copy to Clipboard" placement="top">
    <MUI.IconButton
      color="secondary"
      size="small"
      onClick={() => {
        navigator.clipboard.writeText(props.text);
      }}
    >
      <MUIIcons.FileCopyOutlined style={{ fontSize: 14 }} />
    </MUI.IconButton>
  </MUI.Tooltip>
);

export default PhoneticSearch;
