/**
 * @generated SignedSource<<f5e50a97e3c0d09f7a9d64f7cee67ba1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PracticesSelect_practicesByIDs_RefetchQuery$variables = {
  ids?: ReadonlyArray<string> | null | undefined;
};
export type PracticesSelect_practicesByIDs_RefetchQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"PracticesSelect_practicesByIDs">;
};
export type PracticesSelect_practicesByIDs_RefetchQuery = {
  response: PracticesSelect_practicesByIDs_RefetchQuery$data;
  variables: PracticesSelect_practicesByIDs_RefetchQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": ([]/*: any*/),
    "kind": "LocalArgument",
    "name": "ids"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "ids",
    "variableName": "ids"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PracticesSelect_practicesByIDs_RefetchQuery",
    "selections": [
      {
        "args": (v1/*: any*/),
        "kind": "FragmentSpread",
        "name": "PracticesSelect_practicesByIDs"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PracticesSelect_practicesByIDs_RefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "PracticeText",
        "kind": "LinkedField",
        "name": "practiceTextsByIDs",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "text",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "coachSample",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "aiCoachSample",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isSentence",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "nodeID",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "2a5102f737a1850513073bbdba74f44a",
    "id": null,
    "metadata": {},
    "name": "PracticesSelect_practicesByIDs_RefetchQuery",
    "operationKind": "query",
    "text": "query PracticesSelect_practicesByIDs_RefetchQuery(\n  $ids: [ID!] = []\n) {\n  ...PracticesSelect_practicesByIDs_3pY7A9\n}\n\nfragment PracticesSelect_practicesByIDs_3pY7A9 on Query {\n  practiceTextsByIDs(ids: $ids) {\n    id\n    text\n    coachSample\n    aiCoachSample\n    isSentence\n    nodeID\n  }\n}\n"
  }
};
})();

(node as any).hash = "3dee781a6e905a15a287eb8c75612943";

export default node;
