/**
 * @generated SignedSource<<8f0906a7268dcb013b8218f8accc8a5a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PracticesSelect_Query$variables = {
  initiallySelectedIDs: ReadonlyArray<string>;
};
export type PracticesSelect_Query$data = {
  readonly " $fragmentSpreads": FragmentRefs<"PracticesSelect_practicesByIDs" | "PracticesSelect_practicesForString">;
};
export type PracticesSelect_Query = {
  response: PracticesSelect_Query$data;
  variables: PracticesSelect_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "initiallySelectedIDs"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "ids",
    "variableName": "initiallySelectedIDs"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "text",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "coachSample",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "aiCoachSample",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "nodeID",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PracticesSelect_Query",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "PracticesSelect_practicesForString"
      },
      {
        "args": (v1/*: any*/),
        "kind": "FragmentSpread",
        "name": "PracticesSelect_practicesByIDs"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PracticesSelect_Query",
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Literal",
            "name": "first",
            "value": 20
          },
          {
            "kind": "Literal",
            "name": "search",
            "value": ""
          }
        ],
        "concreteType": "QueryPracticeTextsForStringConnection",
        "kind": "LinkedField",
        "name": "practiceTextsForString",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PracticeText",
            "kind": "LinkedField",
            "name": "nodes",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": "practiceTextsForString(first:20,search:\"\")"
      },
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "PracticeText",
        "kind": "LinkedField",
        "name": "practiceTextsByIDs",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isSentence",
            "storageKey": null
          },
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "40be1000e537299ec0b4c67c18773149",
    "id": null,
    "metadata": {},
    "name": "PracticesSelect_Query",
    "operationKind": "query",
    "text": "query PracticesSelect_Query(\n  $initiallySelectedIDs: [ID!]!\n) {\n  ...PracticesSelect_practicesForString\n  ...PracticesSelect_practicesByIDs_1SqFeW\n}\n\nfragment PracticesSelect_practicesByIDs_1SqFeW on Query {\n  practiceTextsByIDs(ids: $initiallySelectedIDs) {\n    id\n    text\n    coachSample\n    aiCoachSample\n    isSentence\n    nodeID\n  }\n}\n\nfragment PracticesSelect_practicesForString on Query {\n  practiceTextsForString(search: \"\", first: 20) {\n    nodes {\n      id\n      text\n      coachSample\n      aiCoachSample\n      nodeID\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "32e574d057a47c75d802061e7ef3acef";

export default node;
