import React from 'react';
import { Card, Tooltip } from '@material-ui/core';
import styled from 'styled-components';

export const StyledCard = styled(Card)<any>`
  margin-top: 10px;
  display: flex;
  flex: ${(p: any) => (p.fullWidth ? 1 : 0.85)};
  flex-direction: column;
  overflow: visible;
`;

export const TitleParent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
`;

export const TotalParent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 16px;
`;

export const ReferralChartContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 16px;
  margin-bottom: 40px;
`;

const ToBeStyledTooltip = ({ className, ...props }: any) => (
  <Tooltip {...props} classes={{ tooltip: className }} />
);

export const MaterialHTMLTooltip = styled(ToBeStyledTooltip)(() => ({
  backgroundColor: 'white',
  color: 'black',
  maxWidth: 420,
  fontSize: 16,
  borderRadius: 12,
  boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)',
  border: '1px solid rgba(0, 0, 0, 0.2)',
}));
