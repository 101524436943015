import * as MUI from '@material-ui/core';
import React from 'react';

const RadioField = ({ label, value, onChange, disabled, options }: any) => (
  <MUI.FormControl component="fieldset" size="small" disabled={disabled}>
    <MUI.FormLabel focused={false}>{label}</MUI.FormLabel>
    <MUI.RadioGroup value={value} onChange={onChange} row>
      {options.map(
        ({ value: optionValue, label, helperText }: any, index: number) => (
          <MUI.FormControlLabel
            key={index}
            value={optionValue}
            control={<MUI.Radio size="small" />}
            style={{ alignItems: 'flex-start' }}
            label={
              <div style={{ marginTop: 7, maxWidth: '30ch' }}>
                {label}{' '}
                <MUI.FormHelperText style={{ lineHeight: 1.5 }}>
                  {helperText}
                </MUI.FormHelperText>
              </div>
            }
          />
        ),
      )}
    </MUI.RadioGroup>
  </MUI.FormControl>
);

export default RadioField;
