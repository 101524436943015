import * as MUI from '@material-ui/core';
import RemoveIcon from '@material-ui/icons/Remove';
import React from 'react';

function LittlePracticeItemCard({
  practice,
  showRemove,
  onChange,
  value,
}: any) {
  return (
    <MUI.Link
      href={`/#/content/practice/${practice.id}`}
      target="_blank"
      underline="none"
    >
      <MUI.Tooltip title={practice.id} placement="top">
        <MUI.Paper
          key={practice.id}
          elevation={1}
          style={{
            breakInside: 'avoid-column',
            padding: '4px 8px',
            marginBottom: 8,
          }}
          className="MuiPaper-hover"
        >
          <MUI.Box
            display="flex"
            alignItems="flex-start"
            justifyContent="space-between"
          >
            <MUI.Box display="flex" flexDirection="row" gridColumnGap={8}>
              <MUI.Typography
                variant="body2"
                style={{ marginTop: 5, marginBottom: 5 }}
              >
                {practice.text}
                {practice.coachSample || practice.aiCoachSample ? ` 🔊` : ` 🔇`}
              </MUI.Typography>
            </MUI.Box>

            {showRemove && (
              <MUI.IconButton
                size="small"
                onClick={(e) => {
                  e.preventDefault();
                  onChange(value.filter((p: any) => p !== practice.id));
                }}
              >
                <RemoveIcon />
              </MUI.IconButton>
            )}
          </MUI.Box>
        </MUI.Paper>
      </MUI.Tooltip>
    </MUI.Link>
  );
}
export default LittlePracticeItemCard;
