import * as React from 'react';
import { FC } from 'react';
import { Link, FieldProps } from 'react-admin';

import VideoNameField from './VideoNameField';

const VideoLinkField: FC<FieldProps> = (props) => {
  const videos = props.record?.videoId;

  return videos?.length
    ? videos.map((video: any) => (
        <Link key={video} to={`/content/video/${video}`}>
          <VideoNameField {...props} video={video} />
        </Link>
      ))
    : null;
};

VideoLinkField.defaultProps = {
  label: 'Video(s)',
  addLabel: true,
};

export default VideoLinkField;
