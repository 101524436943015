/**
 * @generated SignedSource<<7b7112eb55f77f8d88d6cd8790e446c6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SkillLessonType = "CONVERSATION" | "FOCUS_WORD" | "IDIOM" | "LESSON" | "LINKED_SKILL_LESSON" | "%future added value";
export type SkillLessonsEditor_SkillLesson_Mutation$variables = {
  active?: boolean | null | undefined;
  conversationID?: string | null | undefined;
  id?: string | null | undefined;
  numberPracticeSets?: number | null | undefined;
  practiceTextIDs?: ReadonlyArray<string> | null | undefined;
  skillLessonID?: string | null | undefined;
  type?: SkillLessonType | null | undefined;
  videoID?: string | null | undefined;
};
export type SkillLessonsEditor_SkillLesson_Mutation$data = {
  readonly raUpdateSkillLesson: {
    readonly " $fragmentSpreads": FragmentRefs<"SkillLessonsEditor_skillLesson">;
  } | null | undefined;
};
export type SkillLessonsEditor_SkillLesson_Mutation = {
  response: SkillLessonsEditor_SkillLesson_Mutation$data;
  variables: SkillLessonsEditor_SkillLesson_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "active"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "conversationID"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "numberPracticeSets"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "practiceTextIDs"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "skillLessonID"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "type"
},
v7 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "videoID"
},
v8 = [
  {
    "kind": "Variable",
    "name": "active",
    "variableName": "active"
  },
  {
    "kind": "Variable",
    "name": "conversationID",
    "variableName": "conversationID"
  },
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  },
  {
    "kind": "Variable",
    "name": "numberPracticeSets",
    "variableName": "numberPracticeSets"
  },
  {
    "kind": "Variable",
    "name": "practiceTextIDs",
    "variableName": "practiceTextIDs"
  },
  {
    "kind": "Variable",
    "name": "skillLessonID",
    "variableName": "skillLessonID"
  },
  {
    "kind": "Variable",
    "name": "type",
    "variableName": "type"
  },
  {
    "kind": "Variable",
    "name": "videoID",
    "variableName": "videoID"
  }
],
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "active",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "numberPracticeSets",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "nodeID",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v16 = {
  "alias": "practiceTexts",
  "args": null,
  "concreteType": "PracticeText",
  "kind": "LinkedField",
  "name": "allPracticeTexts",
  "plural": true,
  "selections": [
    (v9/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "text",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "coachSample",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "aiCoachSample",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isSentence",
      "storageKey": null
    },
    (v14/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "SkillLessonsEditor_SkillLesson_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v8/*: any*/),
        "concreteType": "SkillLesson",
        "kind": "LinkedField",
        "name": "raUpdateSkillLesson",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "SkillLessonsEditor_skillLesson"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/),
      (v1/*: any*/),
      (v5/*: any*/),
      (v3/*: any*/),
      (v0/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Operation",
    "name": "SkillLessonsEditor_SkillLesson_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v8/*: any*/),
        "concreteType": "SkillLesson",
        "kind": "LinkedField",
        "name": "raUpdateSkillLesson",
        "plural": false,
        "selections": [
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Conversation",
            "kind": "LinkedField",
            "name": "conversation",
            "plural": false,
            "selections": [
              (v9/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Video",
            "kind": "LinkedField",
            "name": "video",
            "plural": false,
            "selections": [
              (v9/*: any*/),
              (v15/*: any*/),
              (v14/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "SkillLesson",
            "kind": "LinkedField",
            "name": "linkedSkillLesson",
            "plural": false,
            "selections": [
              (v9/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Video",
                "kind": "LinkedField",
                "name": "video",
                "plural": false,
                "selections": [
                  (v15/*: any*/),
                  (v14/*: any*/),
                  (v9/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Conversation",
                "kind": "LinkedField",
                "name": "conversation",
                "plural": false,
                "selections": [
                  (v13/*: any*/),
                  (v14/*: any*/),
                  (v9/*: any*/)
                ],
                "storageKey": null
              },
              (v12/*: any*/),
              (v16/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "SkillLesson",
                "kind": "LinkedField",
                "name": "linkedSkillLesson",
                "plural": false,
                "selections": [
                  (v9/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Video",
                    "kind": "LinkedField",
                    "name": "video",
                    "plural": false,
                    "selections": [
                      (v15/*: any*/),
                      (v14/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Conversation",
                    "kind": "LinkedField",
                    "name": "conversation",
                    "plural": false,
                    "selections": [
                      (v13/*: any*/),
                      (v14/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v12/*: any*/),
                  (v16/*: any*/),
                  (v14/*: any*/)
                ],
                "storageKey": null
              },
              (v14/*: any*/)
            ],
            "storageKey": null
          },
          (v16/*: any*/),
          (v14/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "b802a214b14c8e0d5449870f5f519a9e",
    "id": null,
    "metadata": {},
    "name": "SkillLessonsEditor_SkillLesson_Mutation",
    "operationKind": "mutation",
    "text": "mutation SkillLessonsEditor_SkillLesson_Mutation(\n  $id: ID\n  $type: SkillLessonType\n  $videoID: ID\n  $conversationID: ID\n  $skillLessonID: ID\n  $numberPracticeSets: Int\n  $active: Boolean\n  $practiceTextIDs: [ID!]\n) {\n  raUpdateSkillLesson(id: $id, type: $type, videoID: $videoID, conversationID: $conversationID, skillLessonID: $skillLessonID, numberPracticeSets: $numberPracticeSets, active: $active, practiceTextIDs: $practiceTextIDs) {\n    ...SkillLessonsEditor_skillLesson\n    nodeID\n  }\n}\n\nfragment SkillLessonsEditor_skillLesson on SkillLesson {\n  id\n  type\n  active\n  numberPracticeSets\n  conversation {\n    id\n    name\n    nodeID\n  }\n  video {\n    id\n    title\n    nodeID\n  }\n  linkedSkillLesson {\n    id\n    video {\n      title\n      nodeID\n    }\n    conversation {\n      name\n      nodeID\n    }\n    numberPracticeSets\n    practiceTexts: allPracticeTexts {\n      id\n      text\n      coachSample\n      aiCoachSample\n      isSentence\n      nodeID\n    }\n    ...SkillLessonsEditor_skillLesson_CAyYJ\n    nodeID\n  }\n  practiceTexts: allPracticeTexts {\n    id\n    text\n    coachSample\n    aiCoachSample\n    isSentence\n    nodeID\n  }\n}\n\nfragment SkillLessonsEditor_skillLesson_CAyYJ on SkillLesson {\n  id\n  type\n  active\n  numberPracticeSets\n  conversation {\n    id\n    name\n    nodeID\n  }\n  video {\n    id\n    title\n    nodeID\n  }\n  linkedSkillLesson {\n    id\n    video {\n      title\n      nodeID\n    }\n    conversation {\n      name\n      nodeID\n    }\n    numberPracticeSets\n    practiceTexts: allPracticeTexts {\n      id\n      text\n      coachSample\n      aiCoachSample\n      isSentence\n      nodeID\n    }\n    nodeID\n  }\n  practiceTexts: allPracticeTexts {\n    id\n    text\n    coachSample\n    aiCoachSample\n    isSentence\n    nodeID\n  }\n}\n"
  }
};
})();

(node as any).hash = "3720919d3444492c5515c46ab05e48a6";

export default node;
