/**
 * @generated SignedSource<<3a6934b02339a52139a5ae5d564bbf0a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DLPAssessmentsTaken2_TopSkills$data = {
  readonly topSkillAssessments: ReadonlyArray<{
    readonly id: string | null | undefined;
    readonly " $fragmentSpreads": FragmentRefs<"DLPAssessmentsTaken2_SkillAssessment">;
  }> | null | undefined;
  readonly " $fragmentType": "DLPAssessmentsTaken2_TopSkills";
};
export type DLPAssessmentsTaken2_TopSkills$key = {
  readonly " $data"?: DLPAssessmentsTaken2_TopSkills$data;
  readonly " $fragmentSpreads": FragmentRefs<"DLPAssessmentsTaken2_TopSkills">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DLPAssessmentsTaken2_TopSkills",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "limit",
          "value": 3
        }
      ],
      "concreteType": "SkillAssessment",
      "kind": "LinkedField",
      "name": "topSkillAssessments",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "DLPAssessmentsTaken2_SkillAssessment"
        }
      ],
      "storageKey": "topSkillAssessments(limit:3)"
    }
  ],
  "type": "AssessmentTaken",
  "abstractKey": null
};

(node as any).hash = "8e4f5c726980c0abdab142ae20f02ea7";

export default node;
