import * as React from 'react';
import { FC } from 'react';
import { Card as MuiCard, CardContent } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import {
  FilterList,
  FilterListItem,
  FilterLiveSearch,
  useGetList,
} from 'react-admin';
import curriculum from '../curriculum';

const Card = withStyles((theme) => ({
  root: {
    [theme.breakpoints.up('sm')]: {
      order: -1,
      width: '15em',
      marginRight: '1em',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}))(MuiCard);

const Aside: FC = () => {
  const { data, loading, error } = useGetList('content/curriculum', {
    page: 1,
    perPage: 99999,
  });
  if (loading) return <div />;
  if (error) return <div>Error: {error}</div>;

  return (
    <Card>
      <CardContent>
        <FilterLiveSearch source="name" />

        <FilterList label="Curriculum" icon={<curriculum.icon />}>
          {Object.values(data).map((curriculumItem) => (
            <FilterListItem
              label={curriculumItem.name}
              key={curriculumItem.id}
              value={{ curriculumId: curriculumItem.id }}
            />
          ))}
        </FilterList>
      </CardContent>
    </Card>
  );
};

export default Aside;
