// import { logout } from '@redux/userState';
// import store from '@redux/store';
import { HttpError } from 'react-admin';

const local = 'http://localhost:8000';
export const apiURL =
  process.env.NODE_ENV === 'development'
    ? local
    : process.env.REACT_APP_API_URL;

export const apiURLForReadOnly =
  process.env.NODE_ENV === 'development'
    ? local
    : process.env.REACT_APP_API_URL_ENGAGEMENT;
// console.log('process.env.REACT_APP_API_URL', process.env.REACT_APP_API_URL);
// console.log('apiURL', apiURL);

export type S3BucketFileType =
  | 'coachsamples'
  | 'useraudio'
  | 'user_photos'
  | 'coach_photos'
  | 'useraudio/conversational_segments';

class API {
  token: string | null;

  refreshToken: string | null;

  constructor() {
    const token = localStorage.getItem('token');
    const refreshToken = localStorage.getItem('refresh_token');
    this.token = token;
    this.refreshToken = refreshToken;
  }

  setUserToken = (token: string | null, refreshToken: string | null) => {
    this.token = token;
    this.refreshToken = refreshToken;

    if (token) {
      localStorage.setItem('token', token);
    }

    if (refreshToken) {
      localStorage.setItem('refresh_token', refreshToken);
    }
  };

  request = async ({
    url,
    method = 'GET',
    body = {},
    options,
    headers = {},
    isJson = true,
    isReadOnly = false,
  }: any) => {
    options = options ?? this.defaultOptions(headers);
    // console.log(url, body);
    // console.log(`${apiURL}${url}`);
    // console.log(method);
    // console.log(options);
    // console.log({ isReadOnly });
    const data = {
      method,
      ...(Object.keys(body).length && { body }),
      ...(body instanceof FormData && { body }),
      ...options,
    };

    const res = await fetch(
      `${isReadOnly ? apiURLForReadOnly : apiURL}${url}`,
      data,
    );

    if (res.status === 401) {
      const refreshedToken = await this.refreshTokenFromServer();
      const refreshTokenJson = await refreshedToken.json();

      if (refreshTokenJson.tokens) {
        const refreshData = {
          method,
          ...(Object.keys(body).length && { body }),
          ...(body instanceof FormData && { body }),
          headers: {
            ...options.headers,
            ...(this.token && {
              Authorization: `Bearer ${refreshTokenJson?.tokens?.accessToken}`,
            }),
          },
        };

        const postRefreshRes = await fetch(res.url, refreshData);

        this.setUserToken(
          refreshTokenJson?.tokens?.accessToken,
          refreshTokenJson?.tokens?.refreshToken,
        );

        if (isJson) {
          const refreshedVal = await postRefreshRes.json();
          return {
            status: postRefreshRes.status,
            body: null,
            json: refreshedVal,
            headers: postRefreshRes.headers,
          };
        }
        return {
          status: postRefreshRes.status,
          body: postRefreshRes,
          json: null,
          headers: postRefreshRes.headers,
        };
      }

      this.logOut();
      return refreshTokenJson;
    }

    if (isJson) {
      const text = await res.text();

      let json;
      try {
        json = JSON.parse(text);
      } catch (e) {
        // not json, no big deal
      }
      if (res.status < 200 || res.status >= 300) {
        return Promise.reject(
          new HttpError(
            (json && json.message) || res.statusText,
            res.status,
            json,
          ),
        );
      }

      return {
        status: res.status,
        body: null,
        json,
        headers: res.headers,
      };
    }

    return { status: res.status, body: res, json: null, headers: res.headers };
  };

  logOut = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('refresh_token');
    this.token = null;
    this.refreshToken = null;
    window.location.assign('#/login');
  };

  isTokenExpired = () => {
    const rawToken = localStorage.getItem('token');

    if (!rawToken) {
      return true;
    }

    const base64Url = rawToken.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split('')
        .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
        .join(''),
    );

    const token = JSON.parse(jsonPayload);

    return token.exp - 10 < Date.now() / 1000;
  };

  refreshTokenFromServer = () =>
    fetch(`${apiURL}/api/v1/auth/refresh-token`, {
      method: 'POST',
      headers: {
        ...this.defaultOptions().headers,
      },
      body: JSON.stringify({ refreshToken: this.refreshToken }),
    });

  checkToken = () =>
    fetch(`${apiURL}/api/v1/auth/check-token`, {
      method: 'POST',
      headers: {
        ...this.defaultOptions().headers,
      },
      body: JSON.stringify({ refreshToken: this.refreshToken }),
    });

  defaultOptions = (override = {}) => ({
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      ...override,
      ...(this.token && { Authorization: `Bearer ${this.token}` }),
    },
  });

  getCoachAudio = (coachSample: any, subfolder: any = null) =>
    this.request({
      method: 'GET',
      url: `/api/v1/audio/${coachSample}${
        subfolder ? `?folder=${subfolder}` : ''
      }`,
      isJson: false,
      isReadOnly: true,
    });

  getProfilePicture = (fileName: any) =>
    this.request({
      method: 'GET',
      url: `/api/v1/profile/picture/${fileName}`,
      isJson: false,
      isReadOnly: true,
    });

  getContentImage = (fileName: any) =>
    this.request({
      method: 'GET',
      url: `/api/v1/content/image/${fileName}`,
      isJson: true,
      isReadOnly: true,
    });

  removeProfilePicture = (userId: any) =>
    this.request({
      method: 'DELETE',
      url: `/api/v1/profile/picture/${userId}`,
      isJson: false,
    });

  getAudioFile = (audioFile: any) =>
    this.request({
      method: 'GET',
      url: `/api/v1/audiologs/file/${audioFile}`,
      isJson: false,
      isReadOnly: true,
    });

  getCloudFrontSignedUrl = (
    fileName: string,
    type: S3BucketFileType,
    folder: string | null,
  ) => {
    const url = `/api/v1/audio/cloudfront_signed?fileName=${fileName?.replace(
      '+',
      '%2B',
    )}&type=${type}${folder ? `&folder=${folder}` : ''}`;

    return this.request({ method: 'GET', url });
  };

  grantPromoSub = (userId: any, duration: any, entitlement: any) =>
    this.request({
      method: 'GET',
      url: `/api/v1/users/grant_promo/${userId}/${duration}/${entitlement}`,
    });

  refreshSub = (userId: any) =>
    this.request({
      method: 'GET',
      url: `/api/v1/users/resync/${userId}`,
    });

  addRemoveUserUnit = (args: any) =>
    this.request({
      method: 'POST',
      url: `/api/v1/users/units`,
      body: JSON.stringify(args),
    });

  clearAllProgress = (userId: any) =>
    this.request({
      method: 'GET',
      url: `/api/v1/users/clear_all_progress/${userId}`,
    });

  getMainStatsForDashbord = (
    startDate: string,
    endDate: string,
    referralSourceForApi: string,
    phoneOS: string | null,
    productDuration: string | null,
    timeZone: string,
    country: string | undefined,
    region: string | undefined,
    dateType: string | undefined,
  ) =>
    this.request({
      isReadOnly: true,
      method: 'POST',
      url: `/api/v1/dashboard/main_stats`,
      body: JSON.stringify({
        startDate,
        endDate,
        referralSourceForApi,
        phoneOS,
        productDuration,
        timeZone,
        country,
        region,
        dateType,
      }),
    });

  getEngagementStats = (
    startDate: string,
    endDate: string,
    referralSourceForApi: string,
    phoneOS: string | null,
    timeZone: string,
    type: string,
    daysForWeeklyActive: number,
    daysForMonthlyActive: number,
    maxDaysForWeeklyActive: number,
    maxDaysForMonthlyActive: number,
    subscriberOnly: boolean,
    subscriptionStatuses: (string | undefined)[],
  ) =>
    this.request({
      isReadOnly: true,
      method: 'POST',
      url: `/api/v1/dashboard/engagement_stats`,
      body: JSON.stringify({
        startDate,
        endDate,
        referralSourceForApi,
        phoneOS,
        timeZone,
        type,
        daysForWeeklyActive,
        daysForMonthlyActive,
        maxDaysForWeeklyActive,
        maxDaysForMonthlyActive,
        subscriberOnly,
        subscriptionStatuses,
      }),
    });

  getAdClickDashboard = (startDate: string, endDate: string) =>
    this.request({
      isReadOnly: true,
      method: 'GET',
      url: `/api/v1/dashboard/ads/?startDate=${startDate}&endDate=${endDate}`,
    });

  getWeeklyStreaks = (
    startDate: string,
    endDate: string,
    referralSourceForApi: string,
    phoneOS: string | null,
    timeZone: string,
    subscriberOnly: boolean,
    subscriptionStatuses: (string | undefined)[],
    sinceStart: boolean,
  ) =>
    this.request({
      isReadOnly: true,
      method: 'POST',
      url: `/api/v1/dashboard/weekly_streak`,
      body: JSON.stringify({
        startDate,
        endDate,
        referralSourceForApi,
        phoneOS,
        timeZone,
        subscriberOnly,
        subscriptionStatuses,
        sinceStart,
      }),
    });

  getRetentionStats = (
    startDate: string,
    endDate: string,
    referralSourceForApi: string,
    phoneOS: string | null,
    timeZone: string,
    subscriptionStatuses: (string | undefined)[],
  ) =>
    this.request({
      isReadOnly: true,
      method: 'POST',
      url: `/api/v1/dashboard/retention`,
      body: JSON.stringify({
        startDate,
        endDate,
        referralSourceForApi,
        phoneOS,
        timeZone,
        subscriptionStatuses,
      }),
    });

  getUserReferralSourceStats = (
    startDate: string,
    endDate: string,
    phoneOS: string | null,
    productDuration: string | null,
    timeZone: string,
    country: string | undefined,
    region: string | undefined,
  ) =>
    this.request({
      isReadOnly: true,
      method: 'POST',
      url: `/api/v1/dashboard/secondary_stats/referral_stats`,
      body: JSON.stringify({
        startDate,
        endDate,
        phoneOS,
        productDuration,
        timeZone,
        country,
        region,
      }),
    });

  getSubStatsForCategory = (
    category: string,
    startDate: string,
    endDate: string,
    referralSourceForApi: string,
    phoneOS: string | null,
    productDuration: string | null,
    timeZone: string,
    country: string | undefined,
    region: string | undefined,
  ) =>
    this.request({
      isReadOnly: true,
      method: 'POST',
      url: `/api/v1/dashboard/secondary_stats/${category}`,
      body: JSON.stringify({
        startDate,
        endDate,
        referralSourceForApi,
        phoneOS,
        productDuration,
        timeZone,
        country,
        region,
      }),
    });

  submitUnitCopy = (unitId: string, selectedCurriculums: any) =>
    this.request({
      method: 'POST',
      url: `/api/v1/content/unit/copy`,
      body: JSON.stringify({
        unitId,
        selectedCurriculums,
      }),
    });

  submitLessonCopy = (lessonId: string, selectedUnits: any) =>
    this.request({
      method: 'POST',
      url: `/api/v1/content/lesson/copy`,
      body: JSON.stringify({
        lessonId,
        selectedUnits,
      }),
    });

  // AUTH ROUTES
  loginAdmin = (args: any) =>
    this.request({
      method: 'POST',
      url: `/api/v1/auth/login_admin`,
      body: JSON.stringify(args),
    });

  logout = (args: any) =>
    this.request({
      method: 'POST',
      url: `/api/v1/auth/logout`,
      body: JSON.stringify(args),
    });

  profile = () =>
    this.request({
      method: 'GET',
      url: `/api/v1/profile`,
      isReadOnly: true,
    });

  sendPushNotificationWithFilter = (args: any) =>
    this.request({
      method: 'POST',
      url: `/api/v1/users/send_push_notification`,
      body: JSON.stringify(args),
    });

  getSubscriptionCancellationRatesForTrialsStartedOnDay = (day: string) =>
    this.request({
      isReadOnly: true,
      method: 'GET',
      url: `/api/v1/dashboard/secondary_stats/unsubscribes_for_trial_day?day=${encodeURIComponent(
        day,
      )}`,
    });

  createPracticeSet = (args: any) =>
    this.request({
      method: 'POST',
      url: '/api/v1/content/practice_set',
      body: JSON.stringify(args),
    });

  patchPracticeSet = (args: any) =>
    this.request({
      method: 'PATCH',
      url: `/api/v1/content/practice_set/${args.id}`,
      body: JSON.stringify(args),
    });

  deletePracticeSet = (practiceSetId: string) =>
    this.request({
      method: 'DELETE',
      url: `/api/v1/content/practice_set/${practiceSetId}`,
    });

  reorderPracticeSetContent = (practiceSetId: string, contentItemsIds: any[]) =>
    this.request({
      method: 'POST',
      url: `/api/v1/content/practice_set/${practiceSetId}/reorder/`,
      body: JSON.stringify({ items: contentItemsIds }),
    });

  deletePracticeSetContent = (
    practiceSetId: string,
    practiceSetContentId: string,
  ) =>
    this.request({
      method: 'DELETE',
      url: `/api/v1/content/practice_set/${practiceSetId}/content/${practiceSetContentId}`,
    });

  refreshCoachScore = (practiceId: string) =>
    this.request({
      method: 'POST',
      url: `/api/v1/content/practice/refresh_coach_score`,
      body: JSON.stringify({ practiceId }),
    });

  addUnitToEndForAllUsersInCurriculum = (
    unitId: string,
    curriculumId: string,
  ) =>
    this.request({
      method: 'POST',
      url: `/api/v1/content/unit/add_to_all_users`,
      body: JSON.stringify({ unitId, curriculumId }),
    });

  deleteUnitFromAllUsersInCurriculum = (unitId: string, curriculumId: string) =>
    this.request({
      method: 'POST',
      url: `/api/v1/content/unit/remove_unit_from_all_user_imprints`,
      body: JSON.stringify({ unitId, curriculumId }),
    });

  updateReferralRedemption = (
    redemptionId: number,
    payload: {
      paidOut: boolean;
    },
  ) =>
    this.request({
      method: 'PUT',
      url: `/api/v1/logs/referral_code_redemptions/${redemptionId}`,
      body: JSON.stringify(payload),
    });

  clearRedisCache = () =>
    this.request({ method: 'GET', url: `/api/v1/admin_actions/clear_redis` });

  getStatsForSubscribersByLanguage = (
    startDate: string,
    endDate: string,
    phoneOS: string | null,
    timeZone: string,
  ) =>
    this.request({
      isReadOnly: true,
      method: 'POST',
      url: `/api/v1/dashboard/secondary_stats/subscribers_by_language`,
      body: JSON.stringify({
        startDate,
        endDate,
        phoneOS,
        timeZone,
      }),
    });

  getStatsForSubscribersByCountry = (
    startDate: string,
    endDate: string,
    phoneOS: string | null,
    timeZone: string,
  ) =>
    this.request({
      isReadOnly: true,
      method: 'POST',
      url: `/api/v1/dashboard/secondary_stats/subscribers_by_country`,
      body: JSON.stringify({
        startDate,
        endDate,
        phoneOS,
        timeZone,
      }),
    });

  createVideoImage = (videoId: string, formData: any) => {
    const options = {
      ...this.defaultOptions(),
    };
    // @ts-ignore
    delete options.headers['Content-Type'];
    return this.request({
      method: 'POST',
      url: `/api/v1/content/video/${videoId}/interactables/images/`,
      body: formData,
      options,
    });
  };

  patchVideoImage = (videoId: string, imageId: string, args: any) =>
    this.request({
      method: 'PATCH',
      url: `/api/v1/content/video/${videoId}/interactables/images/${imageId}`,
      body: JSON.stringify(args),
    });

  deleteVideoImage = (videoId: string, imageId: string) =>
    this.request({
      method: 'DELETE',
      url: `/api/v1/content/video/${videoId}/interactables/images/${imageId}`,
    });

  createVideoTableGraphics = (videoId: string, args: any) =>
    this.request({
      method: 'POST',
      url: `/api/v1/content/video/${videoId}/interactables/tables/`,
      body: JSON.stringify(args),
    });

  patchVideoTableGraphics = (videoId: string, tableId: string, args: any) =>
    this.request({
      method: 'PATCH',
      url: `/api/v1/content/video/${videoId}/interactables/tables/${tableId}`,
      body: JSON.stringify(args),
    });

  deleteVideoTableGraphics = (videoId: string, tableId: string) =>
    this.request({
      method: 'DELETE',
      url: `/api/v1/content/video/${videoId}/interactables/tables/${tableId}`,
    });

  createVideoTextWithHighlight = (videoId: string, args: any) =>
    this.request({
      method: 'POST',
      url: `/api/v1/content/video/${videoId}/interactables/text-highlights/`,
      body: JSON.stringify(args),
    });

  patchVideoTextWithHighlight = (videoId: string, textId: string, args: any) =>
    this.request({
      method: 'PATCH',
      url: `/api/v1/content/video/${videoId}/interactables/text-highlights/${textId}`,
      body: JSON.stringify(args),
    });

  deleteVideoTextWithHighlight = (videoId: string, textId: string) =>
    this.request({
      method: 'DELETE',
      url: `/api/v1/content/video/${videoId}/interactables/text-highlights/${textId}`,
    });

  createVideoMouthDiagramSet = (videoId: string, args: any) =>
    this.request({
      method: 'POST',
      url: `/api/v1/content/video/${videoId}/interactables/video_mouth_diagram_sets/`,
      body: JSON.stringify(args),
    });

  patchVideoMouthDiagramSet = (
    videoId: string,
    videoDiagramSetId: string,
    args: any,
  ) =>
    this.request({
      method: 'PATCH',
      url: `/api/v1/content/video/${videoId}/interactables/video_mouth_diagram_sets/${videoDiagramSetId}`,
      body: JSON.stringify(args),
    });

  deleteVideoMouthDiagramSet = (videoId: string, videoDiagramSetId: string) =>
    this.request({
      method: 'DELETE',
      url: `/api/v1/content/video/${videoId}/interactables/video_mouth_diagram_sets/${videoDiagramSetId}`,
    });

  createVideoSummaryPoint = (videoId: string, args: any) =>
    this.request({
      method: 'POST',
      url: `/api/v1/content/video/${videoId}/summary-points/`,
      body: JSON.stringify(args),
    });

  patchVideoSummaryPoint = (
    videoId: string,
    summaryPointId: string,
    args: any,
  ) =>
    this.request({
      method: 'PATCH',
      url: `/api/v1/content/video/${videoId}/summary-points/${summaryPointId}`,
      body: JSON.stringify(args),
    });

  deleteVideoSummaryPoint = (videoId: string, summaryPointId: string) =>
    this.request({
      method: 'DELETE',
      url: `/api/v1/content/video/${videoId}/summary-points/${summaryPointId}`,
    });
}

const api = new API();
export default api;
