/**
 * @generated SignedSource<<ec7a7e5a4ba98bc40583642d22ca2356>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SkillFormAssociatedPhonemes_AssociatedPhonemes_Mutation$variables = {
  newPhonemeIDs: ReadonlyArray<string>;
  skillID: string;
};
export type SkillFormAssociatedPhonemes_AssociatedPhonemes_Mutation$data = {
  readonly changeAssociatedPhonemes: {
    readonly " $fragmentSpreads": FragmentRefs<"SkillFormAssociatedPhonemes_phoneme">;
  } | null | undefined;
};
export type SkillFormAssociatedPhonemes_AssociatedPhonemes_Mutation = {
  response: SkillFormAssociatedPhonemes_AssociatedPhonemes_Mutation$data;
  variables: SkillFormAssociatedPhonemes_AssociatedPhonemes_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "newPhonemeIDs"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "skillID"
},
v2 = [
  {
    "kind": "Variable",
    "name": "newPhonemeIDs",
    "variableName": "newPhonemeIDs"
  },
  {
    "kind": "Variable",
    "name": "skillID",
    "variableName": "skillID"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "nodeID",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "SkillFormAssociatedPhonemes_AssociatedPhonemes_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Skill",
        "kind": "LinkedField",
        "name": "changeAssociatedPhonemes",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "SkillFormAssociatedPhonemes_phoneme"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "SkillFormAssociatedPhonemes_AssociatedPhonemes_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Skill",
        "kind": "LinkedField",
        "name": "changeAssociatedPhonemes",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Phoneme",
            "kind": "LinkedField",
            "name": "phonemes",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "ipa",
                "storageKey": null
              },
              (v4/*: any*/)
            ],
            "storageKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "cd532de97872ba6c0ae8adb4a0dcd449",
    "id": null,
    "metadata": {},
    "name": "SkillFormAssociatedPhonemes_AssociatedPhonemes_Mutation",
    "operationKind": "mutation",
    "text": "mutation SkillFormAssociatedPhonemes_AssociatedPhonemes_Mutation(\n  $skillID: ID!\n  $newPhonemeIDs: [ID!]!\n) {\n  changeAssociatedPhonemes(skillID: $skillID, newPhonemeIDs: $newPhonemeIDs) {\n    ...SkillFormAssociatedPhonemes_phoneme\n    nodeID\n  }\n}\n\nfragment SkillFormAssociatedPhonemes_phoneme on Skill {\n  id\n  phonemes {\n    id\n    ipa\n    nodeID\n  }\n}\n"
  }
};
})();

(node as any).hash = "99ead5106b983e99fd4eec62ba84bcfe";

export default node;
