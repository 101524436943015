import * as React from 'react';
import {
  BooleanField,
  BooleanInput,
  Datagrid,
  DateField,
  Filter,
  List,
  SelectInput,
  TextField,
  TextInput,
} from 'react-admin';

import AudioLogLinkField from './AudioLogLinkField';
import LessonLinkField from './LessonLinkField';
import MultipleChoiceLinkField from './MultipleChoiceLinkField';
import PracticeLinkField from './PracticeLinkField';
import UserLinkField from './UserLinkField';

// import IssueReportShow from './IssueReportShow';

const IssueReportFilter = (props: any) => (
  <Filter {...props}>
    <TextInput
      label="User First Name"
      source="User.firstName"
      defaultValue=""
    />
    <TextInput label="User Last Name" source="User.lastName" defaultValue="" />
    <TextInput label="Issue Type" source="message" defaultValue="" />
    <SelectInput
      label="Transcription Status"
      source="transcriptionStatus"
      choices={[
        { id: 'NOT_REVIEWED', name: 'Not Reviewed' },
        { id: 'TRANSCRIBED', name: 'Transcribed' },
        { id: 'SKIPPED', name: 'Skipped' },
      ]}
    />
    <TextInput label="Lesson Name" source="Lesson.name" defaultValue="" />
    <TextInput label="Practice Name" source="Practice.word" defaultValue="" />
    <TextInput label="User Language" source="User.language" defaultValue="" />
    <BooleanInput
      label="Is Skip Lesson?"
      source="hasContentId"
      defaultValue={false}
    />
    <SelectInput
      label="Model"
      source="modelName"
      choices={[
        { id: 'speechace', name: 'Speechace' },
        { id: 'boldvoice', name: 'BoldVoice' },
      ]}
    />
  </Filter>
);

export const IssueReportList = (props: any) => (
  <List
    {...props}
    perPage={10}
    sort={{ field: 'createdAt', order: 'DESC' }}
    filters={<IssueReportFilter />}
  >
    <Datagrid rowClick="expand" /* expand={<IssueReportShow />} */>
      <UserLinkField />
      <LessonLinkField />
      <PracticeLinkField />
      <MultipleChoiceLinkField />
      <AudioLogLinkField />
      <TextField source="message" label="Issue" />
      <BooleanField source="inVideo" label="In Video" />
      <DateField showTime source="createdAt" label="Date" />
    </Datagrid>
  </List>
);

export default IssueReportList;
