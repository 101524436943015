/* eslint-disable no-case-declarations */
import React, { useContext, useEffect, useState } from 'react';
import { InVideoFormContext } from './InVideoFormProvider';
import { Button } from '@material-ui/core';
import Icon from '@material-ui/icons/Delete';
import 'ka-table/style.css';

import { DataType, EditingMode, SortingMode } from 'ka-table/enums';

import { Table, useTable } from 'ka-table';
import { useNotify } from 'react-admin';
import { IHeadCellProps } from 'ka-table/props';

const HeadCell: React.FC<IHeadCellProps & any> = ({
  column: { title, subtitle, phonemeId },
  onDelete,
  allowDelete,
}) => {
  const [isHovered, setHovered] = useState(false);

  return (
    <div
      onMouseEnter={() => setHovered(!!allowDelete)}
      onMouseLeave={() => setHovered(false)}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <div>
        <div>{title}</div>
        <div
          style={{
            fontSize: 12,
            color: 'gray',
          }}
        >
          {subtitle}
        </div>
        {phonemeId ? (
          <div
            style={{
              fontSize: 12,
              color: 'gray',
            }}
          >
            <a
              href={`${window.location.protocol}${window.location.host}/#/content/phoneme/${phonemeId}`}
              target="_blank"
            >
              {phonemeId}
            </a>
          </div>
        ) : null}
      </div>
      {isHovered && onDelete && (
        <Icon
          style={{
            paddingRight: 10,
            margin: '0 10px',
            cursor: 'pointer',
            color: 'red',
          }}
          onClick={() => onDelete()}
        />
      )}
    </div>
  );
};

export default function TableCreationForm({
  initialHeaders = [],
  initialRows = [],
}) {
  const { setData } = useContext(InVideoFormContext);
  // data should be organized into header and data

  const [headers, setHeaders] = useState<any[]>(initialHeaders);
  const [rows, setRows] = useState<any[]>(initialRows);

  const notify = useNotify();

  const table = useTable({
    onDispatch: (action) => {
      switch (action.type) {
        case 'UpdateCellValue':
          const { rowKeyValue, columnKey, value } = action;
          const newRows = [...rows];
          const row = newRows.find((r) => r.id === rowKeyValue);
          if (row) {
            row[columnKey] = value;
          }
          setRows(newRows);
          break;
        default:
          break;
      }
    },
  });

  const addColumn = (title: string, subtitle?: string, phonemeId?: string) => {
    const newHeaders = [
      ...headers,
      {
        key: title,
        title,
        isEditable: true,
        subtitle,
        phonemeId,
        dataType: DataType.String,
      },
    ];

    if (headers.length > 2) {
      notify('We only support up to 3 columns at this point');
    } else {
      setHeaders(newHeaders);
    }
  };

  const addRow = () => {
    const newRow: any = {};
    headers.forEach((header) => {
      newRow[header.key] = 'Click Me';
    });
    newRow.isEditable = true;
    newRow.id = rows.length + 1;
    setRows([...rows, newRow]);
  };

  useEffect(() => {
    const sanitizedHeaders = headers.map((header) => ({
      title: header.title,
      subtitle: header.subtitle,
      ...(header?.phonemeId ? { phonemeId: header.phonemeId } : {}),
    }));
    const sanitizedRows = rows.map((row) => {
      // needs to be an array of items
      const rowItems: any[] = [];
      headers.forEach((header) => {
        rowItems.push({
          text: row[header.key],
        });
      });

      return rowItems;
    });
    if (setData !== undefined) {
      setData({
        headers: sanitizedHeaders,
        data: sanitizedRows,
      });
    }
  }, [headers, rows, setData]);

  // console.log('headers', headers);
  // console.log('rows', rows);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        marginTop: 20,
      }}
    >
      {initialRows.length === 0 ? (
        <div style={{ marginBottom: 10 }}>
          <Button
            onClick={() => {
              const title = window.prompt('1 of 3: Column Title');
              if (!title) {
                return;
              }
              const subtitle =
                window.prompt('2 of 3: Column Subtitle') || undefined;
              const phonemeId =
                window.prompt('3 of 3: Phoneme ID') || undefined;

              if (title) {
                addColumn(title, subtitle, phonemeId);
              }
            }}
            type="button"
            variant="outlined"
            style={{
              marginRight: 10,
            }}
          >
            Add Column
          </Button>
          <Button
            type="button"
            variant="outlined"
            onClick={() => {
              addRow();
            }}
          >
            Add Row
          </Button>
        </div>
      ) : null}
      <Table
        table={table}
        columns={headers}
        data={rows}
        editingMode={EditingMode.Cell}
        height={500}
        rowKeyField="id"
        sortingMode={SortingMode.Single}
        controlledPropsKeys={['columns', 'data']}
        editableCells={[
          {
            columnKey: 'title',
            rowKeyValue: 2,
          },
        ]}
        childComponents={{
          headCell: {
            content: (props) => (
              <HeadCell
                {...props}
                onDelete={() => {
                  setHeaders(headers.filter((h) => h.key !== props.column.key));
                }}
                allowDelete={initialRows.length === 0}
              />
            ),
          },
        }}
      />
    </div>
  );
}
