import {
  Button,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import { Checkbox, Typography } from '@material-ui/core';
// import DashboardChartData from '../dashboard/DashboardChartData';
import moment from 'moment';
import React from 'react';
import styled from 'styled-components';

import api from '../api';
import { TIMEZONE_LIST } from '../dashboard/Dashboard';
import DashboardChart from '../dashboard/DashboardChart';
import DateScrubberFilter from '../dashboard/DateScrubberFilter';
import { PHONE_OS_LIST, SUB_STATUS_LIST } from '../users/UserList';
import ReactCohortGraph from './retention';

const ENGAGEMENT_TYPES = {
  DAILY: 'daily',
  WEEKLY: 'weekly',
  MONTHLY: 'monthly',
};

const DetailTypes = {
  DAILY: 'activeOnDay',
  WEEKLY: 'activeDuringRollingWeek',
  MONTHLY: 'activeDuringRollingMonth',
};

export const CREATED_AT_TYPES = {
  BOTH: 'both',
  STACKED: 'stacked',
  SAME_PERIOD: 'same_period',
  NOT_SAME_PERIOD: 'not_same_period',
};

const LIMITED_SUB_STATUS_LIST = SUB_STATUS_LIST.filter(
  (i) => i.id !== 'trial_no_credit_card',
);

const Dashboard = () => {
  const [engagementStats, setEngagementStats] = React.useState([]);
  const [weeklyStreakStats, setWeeklyStreakStats] = React.useState([]);
  const [retentionStats, setRetentionStats] = React.useState();

  const [referralSource, setReferralSource] = React.useState('');
  const [referralSourceForApi, setReferralSourceForApi] = React.useState('');
  const [phoneOS, updatePhoneOS] = React.useState('none');
  const [timeZone, updateTimezone] = React.useState('utc');
  const [type, setType] = React.useState(ENGAGEMENT_TYPES.DAILY);
  const [createdAtType, setCreatedAtType] = React.useState(
    CREATED_AT_TYPES.BOTH,
  );
  const [subscriptionStatuses, setSubscriptionStatuses] = React.useState<any>(
    LIMITED_SUB_STATUS_LIST.map((i) => i.id),
  );
  const [daysForWeeklyActive, setDaysForWeeklyActive] = React.useState<any>(3);
  const [maxDaysForWeeklyActive, setMaxDaysForWeeklyActive] =
    React.useState<any>(7);
  const [daysForMonthlyActive, setDaysForMonthlyActive] =
    React.useState<any>(10);
  const [maxDaysForMonthlyActive, setMaxDaysForMonthlyActive] =
    React.useState<any>(31);

  const [subscriberOnly, setSubscriberOnly] = React.useState(false);
  const [isLoadingEngagement, setIsLoadingEngagement] = React.useState(false);
  const [isLoadingWeeklyStreaks, setIsLoadingWeeklyStreaks] =
    React.useState(false);
  const [isLoadingRetention, setIsLoadingRetention] = React.useState(false);

  const [startDate, setStartDate] = React.useState<any>(
    moment().subtract(7, 'day').format('YYYY-MM-DD'),
  );
  const [endDate, setEndDate] = React.useState<any>(
    moment().format('YYYY-MM-DD'),
  );

  const [numberLabelsOn, setNumberLabelsOn] = React.useState(true);

  const [dateRangeId, setDateRangeId] = React.useState('week');
  const [showAsPercentOfTotal, setShowAsPercentOfTotal] = React.useState(false);

  const [sinceStart, setSinceStart] = React.useState(false);

  const updateReferralSourceLastValue = React.useRef();
  const updateReferralSource = (newReferralSource: any) => {
    updateReferralSourceLastValue.current = newReferralSource;
    setReferralSource(newReferralSource);
    setTimeout(() => {
      if (newReferralSource === updateReferralSourceLastValue.current) {
        setReferralSourceForApi(newReferralSource);
      }
    }, 1500);
  };

  // const [
  //   checkedForSavedProperties,
  //   setCheckedForSavedProperties,
  // ] = React.useState(false);

  React.useEffect(() => {
    const savedProperties = localStorage.getItem('savedProperties');
    if (savedProperties) {
      const parsedSavedProperties = JSON.parse(savedProperties);
      const {
        startDate,
        endDate,
        referralSourceForApi,
        phoneOS,
        type,
        timeZone,
        daysForWeeklyActive,
        maxDaysForWeeklyActive,
        daysForMonthlyActive,
        maxDaysForMonthlyActive,
        subscriberOnly,
        subscriptionStatuses,
        showAsPercentOfTotal,
        numberLabelsOn,
        dateRangeId,
        createdAtType,
      } = parsedSavedProperties;

      if (startDate) {
        setStartDate(startDate);
      }
      if (endDate) {
        setEndDate(endDate);
      }
      if (referralSourceForApi) {
        setReferralSource(referralSourceForApi);
        setReferralSourceForApi(referralSourceForApi);
      }
      if (phoneOS) {
        updatePhoneOS(phoneOS);
      }
      if (type) {
        setType(type);
      }
      if (timeZone) {
        updateTimezone(timeZone);
      }
      if (daysForWeeklyActive) {
        setDaysForWeeklyActive(daysForWeeklyActive);
      }
      if (daysForMonthlyActive) {
        setDaysForMonthlyActive(daysForMonthlyActive);
      }
      if (maxDaysForWeeklyActive) {
        setMaxDaysForWeeklyActive(maxDaysForWeeklyActive);
      }
      if (maxDaysForMonthlyActive) {
        setMaxDaysForMonthlyActive(maxDaysForMonthlyActive);
      }
      if (subscriberOnly) {
        setSubscriberOnly(subscriberOnly);
      }
      if (subscriptionStatuses) {
        setSubscriptionStatuses(subscriptionStatuses);
      }
      if (showAsPercentOfTotal) {
        setShowAsPercentOfTotal(showAsPercentOfTotal);
      }
      if (numberLabelsOn) {
        setNumberLabelsOn(numberLabelsOn);
      }
      if (dateRangeId) {
        setDateRangeId(dateRangeId);
      }
      if (createdAtType) {
        setCreatedAtType(createdAtType);
      }
    }

    // setCheckedForSavedProperties(true);
  }, []);

  const getStats = async (statsType: string) => {
    try {
      const savedProperties = {
        startDate,
        endDate,
        referralSourceForApi,
        phoneOS,
        type,
        timeZone,
        daysForWeeklyActive,
        maxDaysForWeeklyActive,
        daysForMonthlyActive,
        maxDaysForMonthlyActive,
        subscriberOnly,
        subscriptionStatuses,
        showAsPercentOfTotal,
        numberLabelsOn,
        dateRangeId,
        createdAtType,
        sinceStart,
      };

      localStorage.setItem('savedProperties', JSON.stringify(savedProperties));

      if (statsType === 'engagement') {
        setIsLoadingEngagement(true);
        const newEngagementStats = await api.getEngagementStats(
          startDate,
          endDate,
          referralSourceForApi,
          phoneOS === 'none' ? null : phoneOS,
          TIMEZONE_LIST?.find((i) => i.id === timeZone)?.value || 'UTC',
          type,
          daysForWeeklyActive,
          daysForMonthlyActive,
          maxDaysForWeeklyActive,
          maxDaysForMonthlyActive,
          subscriberOnly,
          subscriptionStatuses,
        );
        if (newEngagementStats.status === 200) {
          setEngagementStats(newEngagementStats.json);
        }
      } else if (statsType === 'weeklyStreak') {
        setIsLoadingWeeklyStreaks(true);
        const newWeeklyStreakStats = await api.getWeeklyStreaks(
          startDate,
          endDate,
          referralSourceForApi,
          phoneOS === 'none' ? null : phoneOS,
          TIMEZONE_LIST?.find((i) => i.id === timeZone)?.value || 'UTC',
          subscriberOnly,
          subscriptionStatuses,
          sinceStart,
        );
        if (newWeeklyStreakStats.status === 200) {
          setWeeklyStreakStats(newWeeklyStreakStats.json);
        }
      } else if (statsType === 'retention') {
        setIsLoadingRetention(true);
        const newRetentionData = await api.getRetentionStats(
          startDate,
          endDate,
          referralSourceForApi,
          phoneOS === 'none' ? null : phoneOS,
          TIMEZONE_LIST?.find((i) => i.id === timeZone)?.value || 'UTC',
          subscriptionStatuses,
        );
        if (newRetentionData.status === 200) {
          setRetentionStats(newRetentionData.json);
        }
      }
    } catch (err) {
      console.log('err', err);
    } finally {
      setIsLoadingEngagement(false);
      setIsLoadingWeeklyStreaks(false);
      setIsLoadingRetention(false);
    }
  };

  // React.useEffect(() => {
  //   if (checkedForSavedProperties) {
  //     getStats(true, true);
  //   }
  // }, [
  //   // startDate,
  //   // endDate,
  //   // referralSourceForApi,
  //   // phoneOS,
  //   // timeZone,
  //   // type,
  //   // daysForWeeklyActive,
  //   // daysForMonthlyActive,
  //   // subscriberOnly,
  //   // subscriptionStatuses,
  //   checkedForSavedProperties,
  // ]);

  const activeDetailType =
    type === 'daily'
      ? DetailTypes.DAILY
      : type === 'weekly'
        ? DetailTypes.WEEKLY
        : DetailTypes.MONTHLY;

  const subtitle = React.useMemo(() => {
    const startOfText = `Number of Users Active`;
    const numDaysText =
      type === 'daily'
        ? ``
        : type === 'weekly'
          ? `${daysForWeeklyActive} Day${daysForWeeklyActive !== 1 ? `s` : ``}`
          : `${daysForMonthlyActive} Day${daysForMonthlyActive !== 1 ? `s` : ``}`;
    const inTheLast =
      type === 'daily'
        ? ``
        : type === 'weekly'
          ? `In the Last Week`
          : `In the Last Month`;

    const finisher = type !== 'daily' ? 'From Each Date' : 'On Each Date';

    const subtitleText = `${startOfText} ${numDaysText} ${inTheLast} ${finisher}`;

    return subtitleText;
  }, [type, daysForWeeklyActive, daysForMonthlyActive]);

  return (
    <Container>
      <TopLevelContainer>
        <DateContainer>
          <DateScrubberFilter
            startDate={startDate}
            endDate={endDate}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
          />
          <TextField
            id="referralSource"
            label="Referral Source"
            type="text"
            value={referralSource}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(event) => updateReferralSource(event.target.value)}
            style={{ marginRight: 10 }}
          />
          <TextField
            id="phoneOS"
            label="Phone OS"
            select
            value={phoneOS}
            SelectProps={{
              native: true,
            }}
            onChange={(event) => updatePhoneOS(event.target.value)}
            style={{ marginRight: 10, minWidth: 100 }}
          >
            {[{ id: 'none', name: 'All' }, ...PHONE_OS_LIST].map((osItem) => (
              <option key={osItem.id} value={osItem.id}>
                {osItem.name}
              </option>
            ))}
          </TextField>
          <TextField
            id="timeZone"
            label="Time Zone"
            select
            value={timeZone}
            SelectProps={{
              native: true,
            }}
            onChange={(event) => updateTimezone(event.target.value)}
            style={{ marginRight: 10, minWidth: 100 }}
          >
            {TIMEZONE_LIST.map((item) => (
              <option key={item.id} value={item.id}>
                {item.name}
              </option>
            ))}
          </TextField>
          <TextField
            id="createdAtType"
            label="Signed Up Time"
            select
            value={createdAtType}
            SelectProps={{
              native: true,
            }}
            onChange={(event) => setCreatedAtType(event.target.value)}
            style={{ marginRight: 10, minWidth: 100 }}
          >
            {Object.keys(CREATED_AT_TYPES).map((item) => (
              // @ts-ignore please
              <option key={item} value={CREATED_AT_TYPES[item]}>
                {item}
              </option>
            ))}
          </TextField>
        </DateContainer>
        <CheckboxContainer>
          <Checkbox
            checked={numberLabelsOn}
            onChange={(event: any) => setNumberLabelsOn(event.target.checked)}
          />
          <Typography
            style={{ cursor: 'pointer' }}
            onClick={() =>
              setNumberLabelsOn((existingValue: boolean) => !existingValue)
            }
          >
            Toggle Number Labels
          </Typography>
        </CheckboxContainer>
      </TopLevelContainer>
      <TopLevelContainer
        style={{ marginTop: 10, justifyContent: 'flex-start' }}
      >
        <CheckboxContainer>
          <TextField
            id="type"
            label="Engagement Type"
            select
            value={type}
            SelectProps={{
              native: true,
            }}
            onChange={(event) => setType(event.target.value)}
            style={{ marginRight: 10, minWidth: 150 }}
          >
            {Object.keys(ENGAGEMENT_TYPES).map((engamenetType) => (
              <option
                key={engamenetType}
                // @ts-ignore please
                value={ENGAGEMENT_TYPES[engamenetType]}
              >
                {engamenetType}
              </option>
            ))}
          </TextField>
          {type === 'weekly' && (
            <TextField
              id="daysForWeeklyActive"
              label="Days For Weekly Active"
              type="number"
              value={daysForWeeklyActive}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(event) => setDaysForWeeklyActive(event.target.value)}
              style={{ marginRight: 10, minWidth: 200 }}
            />
          )}
          {type === 'weekly' && (
            <TextField
              id="maxDaysForWeeklyActive"
              label="Max Days For Weekly Active"
              type="number"
              value={maxDaysForWeeklyActive}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(event) =>
                setMaxDaysForWeeklyActive(event.target.value)
              }
              style={{ marginRight: 10, minWidth: 200 }}
            />
          )}
          {type === 'monthly' && (
            <TextField
              id="daysForMonthlyActive"
              label="Days For Monthly Active"
              type="number"
              value={daysForMonthlyActive}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(event) => setDaysForMonthlyActive(event.target.value)}
              style={{ marginRight: 10, minWidth: 200 }}
            />
          )}
          {type === 'monthly' && (
            <TextField
              id="maxDaysForMonthlyActive"
              label="Max Days For Monthly Active"
              type="number"
              value={maxDaysForMonthlyActive}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(event) =>
                setMaxDaysForMonthlyActive(event.target.value)
              }
              style={{ marginRight: 10, minWidth: 200 }}
            />
          )}
        </CheckboxContainer>
        <CheckboxContainer>
          <StyledFormControl>
            <InputLabel id="subscription-statuses-label">
              Subscription Statuses
            </InputLabel>
            <Select
              labelId="subscription-statuses-label"
              id="subscription-statuses"
              multiple
              value={subscriptionStatuses}
              onChange={(event) => {
                const { value }: { value: any } = event.target;

                if (value.length) {
                  setSubscriberOnly(false);
                }

                if (value[value.length - 1] === 'all') {
                  const areAllAlreadySelected =
                    subscriptionStatuses.length ===
                    LIMITED_SUB_STATUS_LIST.length;
                  setSubscriptionStatuses(
                    areAllAlreadySelected
                      ? []
                      : LIMITED_SUB_STATUS_LIST.map((i: any) => i.id),
                  );
                  return;
                }
                setSubscriptionStatuses(event.target.value);
              }}
              renderValue={(selected: any) => {
                const selectedNames = selected.map((i: any) => {
                  const name: string =
                    LIMITED_SUB_STATUS_LIST.find((j) => j.id === i)?.name || '';
                  return name.length >= 10
                    ? `${name.substring(0, 10)}...`
                    : name;
                });
                return selectedNames.join('; ');
              }}
              style={{ marginRight: 10, minWidth: 250 }}
            >
              <MenuItem value="all">
                <Checkbox
                  checked={
                    LIMITED_SUB_STATUS_LIST.length > 0 &&
                    subscriptionStatuses.length ===
                      LIMITED_SUB_STATUS_LIST.length
                  }
                  indeterminate={
                    subscriptionStatuses.length > 0 &&
                    subscriptionStatuses.length < LIMITED_SUB_STATUS_LIST.length
                  }
                />
                <ListItemText primary="Select All" />
              </MenuItem>
              {LIMITED_SUB_STATUS_LIST.map(({ id, name }) => (
                <MenuItem key={id} value={id}>
                  <Checkbox checked={subscriptionStatuses.indexOf(id) > -1} />
                  <ListItemText primary={name} />
                </MenuItem>
              ))}
            </Select>
          </StyledFormControl>

          <Checkbox
            checked={subscriberOnly}
            onChange={(event: any) => {
              if (subscriptionStatuses?.length && event.target.checked) {
                setSubscriptionStatuses([]);
              }
              setSubscriberOnly(event.target.checked);
            }}
          />
          <Typography
            style={{ cursor: 'pointer' }}
            onClick={() => {
              setSubscriberOnly((existingValue: boolean) => {
                if (subscriptionStatuses?.length && !existingValue) {
                  setSubscriptionStatuses([]);
                }
                return !existingValue;
              });
            }}
          >
            Toggle Subscriber Only
          </Typography>

          <Checkbox
            checked={showAsPercentOfTotal}
            onChange={(event: any) =>
              setShowAsPercentOfTotal(event.target.checked)
            }
          />
          <Typography
            style={{ cursor: 'pointer' }}
            onClick={() =>
              setShowAsPercentOfTotal(
                (existingValue: boolean) => !existingValue,
              )
            }
          >
            Show as % of total
          </Typography>

          <Checkbox
            checked={sinceStart}
            onChange={(event: any) => setSinceStart(event.target.checked)}
          />
          <Typography
            style={{ cursor: 'pointer' }}
            onClick={() =>
              setSinceStart((existingValue: boolean) => !existingValue)
            }
          >
            Weekly Streak Since Acct Created
          </Typography>
        </CheckboxContainer>
      </TopLevelContainer>

      <Button
        onClick={() => getStats('retention')}
        variant="contained"
        color="primary"
        style={{ marginTop: 10, marginBottom: 10, maxWidth: 250 }}
      >
        Refresh Retention Stats{' '}
        {isLoadingRetention && `(loading... please wait...)`}
      </Button>

      <ChartDataContainer>
        <ReactCohortGraph
          showHeaderValues
          data={{ days: retentionStats }}
          defaultValueType="percent"
          columnLabel="Weeks"
          customEmptyDataMessage={
            <h3>Press Refresh Retention Stats to load data!</h3>
          }
        />
      </ChartDataContainer>

      <Button
        onClick={() => getStats('engagement')}
        variant="contained"
        color="primary"
        style={{ marginTop: 10, maxWidth: 250 }}
      >
        Refresh Engagement Stats
      </Button>

      <ChartDataContainer>
        <DashboardChart
          title="Engagement"
          subtitle={subtitle}
          dataKey={`${activeDetailType}Num`}
          data={engagementStats}
          numberLabelsOn={numberLabelsOn}
          detailType={activeDetailType}
          engagementType={type}
          // noTotal
          isOpen
          isLoading={isLoadingEngagement}
          createdAtType={createdAtType}
          showAsPercentOfTotal={showAsPercentOfTotal}
          // weekly
        />
        {/* <DashboardChartData
          title="Records"
          dataKey={`${activeDetailType}Data`}
          dataLabel="Active On"
          data={engagementStats}
          detailType={activeDetailType}
          engagementType={type}
          isOpen
        /> */}
      </ChartDataContainer>
      <Button
        onClick={() => getStats('weeklyStreak')}
        variant="contained"
        color="primary"
        style={{ marginTop: 10, maxWidth: 350 }}
      >
        Refresh Weekly Streak Stats
      </Button>
      <ChartDataContainer>
        <DashboardChart
          title="Weekly Streaks"
          dataKey="weeklyStreaksNum"
          data={weeklyStreakStats}
          numberLabelsOn={numberLabelsOn}
          detailType="weeklyStreaks"
          noTotal
          isOpen
          isLoading={isLoadingWeeklyStreaks}
          showAsPercentOfTotal={showAsPercentOfTotal}
          weekly
        />
        {/* <DashboardChartData
          title="Records"
          dataKey="weeklyStreaksData"
          dataLabel="Weekly Streak Count"
          data={weeklyStreakStats}
          detailType="weeklyStreaks"
          isOpen
          weekly
        /> */}
      </ChartDataContainer>
    </Container>
  );
};

export default Dashboard;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const ChartDataContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
`;

const DateContainer = styled.div`
  flex: 1.25;
  display: flex;
  align-items: center;
`;

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
`;

const TopLevelContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
`;

const StyledFormControl = styled(FormControl)`
  margin-right: 5px;
`;
