import * as MUI from '@material-ui/core';
import * as MUIColors from '@material-ui/core/colors';
import * as MUIIcons from '@material-ui/icons';
import graphql from 'babel-plugin-relay/macro';
import _ from 'lodash';
import React from 'react';
import { useLazyLoadQuery } from 'react-relay';

const IPADrawerQuery = graphql`
  query IPADrawer_Query {
    bvSegments {
      ipa
      aliases
      types
      representations {
        alphabet
        alphabetDisplayName
        representation
      }
    }
  }
`;

const IPADrawer = React.memo((props: any) => {
  const { isOpen, setIsOpen } = props;

  const data: any = useLazyLoadQuery(IPADrawerQuery, {});

  const [isDrawerRight, setIsDrawerRight] = React.useState(true);

  return (
    <MUI.Drawer
      open={isOpen}
      anchor={isDrawerRight ? 'right' : 'left'}
      variant="persistent"
      onClose={() => setIsOpen(false)}
      style={{ zIndex: 1200 }}
      PaperProps={{ elevation: 2 }}
    >
      <MUI.Toolbar />
      <MUI.Box
        padding={2}
        paddingTop={0}
        display="flex"
        flexDirection="column"
        height="100%"
        width={530}
        gridRowGap={22}
      >
        <MUI.Box>
          <MUI.ButtonGroup
            size="small"
            variant="outlined"
            color="secondary"
            style={{ float: isDrawerRight ? 'left' : 'right' }}
          >
            {isDrawerRight && (
              <MUI.Tooltip title="Move to Left Side">
                <MUI.Button onClick={() => setIsDrawerRight(false)}>
                  <MUIIcons.KeyboardArrowLeft />
                </MUI.Button>
              </MUI.Tooltip>
            )}
            <MUI.Button onClick={() => setIsOpen(false)}>
              Close Drawer
            </MUI.Button>
            {!isDrawerRight && (
              <MUI.Tooltip title="Move to Right Side">
                <MUI.Button onClick={() => setIsDrawerRight(true)}>
                  <MUIIcons.KeyboardArrowRight />
                </MUI.Button>
              </MUI.Tooltip>
            )}
          </MUI.ButtonGroup>
        </MUI.Box>
        <MUI.Box>
          <IPATable bvSegments={data.bvSegments} />
        </MUI.Box>
      </MUI.Box>
    </MUI.Drawer>
  );
});

const IPATable = React.memo(({ bvSegments }: any) => {
  const ordered = React.useMemo(
    () =>
      _.orderBy(bvSegments, [
        (s) => !s.types.includes('vowels'),
        (s) => s.types.includes('r-colored'),
        (s) => s.types.includes('diphthongs'),
        (s) => !s.types.includes('affricates'),
        (s) => !s.types.includes('fricatives'),
        (s) => !s.types.includes('stops'),
        'ipa',
      ]),
    [bvSegments],
  );

  return (
    <MUI.Paper variant="outlined" style={{ width: '100%' }}>
      <MUI.TableContainer style={{ maxHeight: 'calc(100vh - 150px)' }}>
        <MUI.Table stickyHeader size="small">
          <MUI.TableHead>
            <MUI.TableRow>
              <MUI.TableCell>IPA</MUI.TableCell>
              <MUI.TableCell>Arpabet</MUI.TableCell>
              <MUI.TableCell>Speechace</MUI.TableCell>
              <MUI.TableCell>BoldVoice V1</MUI.TableCell>
              <MUI.TableCell>BoldVoice V2</MUI.TableCell>
            </MUI.TableRow>
          </MUI.TableHead>
          <MUI.TableBody>
            {ordered.map((segment) => (
              <MUI.TableRow key={segment.ipa}>
                <MUI.TableCell style={{ paddingTop: 2, paddingBottom: 2 }}>
                  <span style={{ fontSize: '1.1em' }}>
                    <CopyableRepresentation text={segment.ipa} em />
                  </span>
                </MUI.TableCell>
                {['arpabet', 'speechace', 'boldvoice1', 'boldvoice2'].map(
                  (alphabet) => (
                    <MUI.TableCell style={{ paddingTop: 2, paddingBottom: 2 }}>
                      <CopyableRepresentation
                        text={
                          segment?.representations?.find(
                            (r) => r.alphabet === alphabet,
                          )?.representation
                        }
                      />
                    </MUI.TableCell>
                  ),
                )}
              </MUI.TableRow>
            ))}
          </MUI.TableBody>
        </MUI.Table>
      </MUI.TableContainer>
    </MUI.Paper>
  );
});

const CopyableRepresentation = ({ text, em }: any) => {
  const [recentlyCopied, setRecentlyCopied] = React.useState(false);

  if (!text) {
    return null;
  }

  return (
    <MUI.Tooltip
      title={recentlyCopied ? 'Copied!' : 'Click to copy to clipboard.'}
      placement="top"
    >
      <span
        onClick={() => {
          navigator.clipboard.writeText(text);
          setRecentlyCopied(true);
          setTimeout(() => setRecentlyCopied(false), 2000);
        }}
        style={{
          cursor: 'default',
          backgroundColor: em ? MUIColors.indigo[50] : 'rgba(0, 0, 0, 5%)',
          borderColor: MUIColors.indigo[100],
          borderWidth: em ? 1 : 0,
          borderStyle: 'solid',
          paddingLeft: 5,
          paddingRight: 5,
          borderRadius: 2,
        }}
      >
        {text}
      </span>
    </MUI.Tooltip>
  );
};

export default IPADrawer;
