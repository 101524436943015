import { Box, Card, CardContent } from '@material-ui/core';
import { Theme, makeStyles } from '@material-ui/core/styles';
import { Styles } from '@material-ui/styles/withStyles';
import { FC } from 'react';
import {
  AutocompleteArrayInput,
  Datagrid,
  DeleteButton,
  Edit,
  EditButton,
  EditProps,
  FieldProps,
  FormWithRedirect,
  FunctionField,
  NumberField,
  ReferenceArrayInput,
  ReferenceInput,
  ReferenceManyField,
  SaveButton,
  SelectInput,
  TextField,
  TextInput,
  Toolbar,
} from 'react-admin';

// import { apiURL } from '../api';
import OrderedArrayInput from '../components/OrderedArrayInput';
import { matchSuggestion } from '../lesson/utils';
import { TESTING_VARIANTS } from '../unit/UnitEdit';

const Separator = () => <Box pt="1em" />;

const CurriculumTitle: FC<FieldProps> = ({ record }) =>
  record ? (
    <span>
      Curriculum &quot;
      {record.name}&quot;
    </span>
  ) : null;

export const styles: Styles<Theme, any> = {
  name: { display: 'inline-block' },
  photo: { display: 'inline-block' },
  image: {
    margin: '0.5rem',
    maxHeight: '10rem',
  },
  button: {
    marginRight: 10,
  },
};

const useStyles = makeStyles(styles);

// const ImageContentField = (props: any) => {
//   const coverPhoto = useRecordContext(props);
//   const classes = useStyles(props);
//   const url = coverPhoto?.src || `${apiURL}/api/v1/image/${coverPhoto}`;
//   return <img src={url} alt="img" className={classes.image} />;
// };

const InnerUnitList = (props: any) => {
  const { ids } = props;
  return ids?.length ? (
    <Datagrid>
      <TextField style={{ flex: 1 }} source="name" label="Unit Name" />
      <NumberField source="unitNumber" label="Unit Number" />
      <FunctionField
        render={(record: any) =>
          record?.testingVariant
            ? TESTING_VARIANTS?.find((i) => i.id === record.testingVariant)
                ?.name
            : null
        }
        label="Testing Variant"
      />
      <TextField source="testName" label="Experiment Name" />
      <EditButton />
    </Datagrid>
  ) : (
    <div>No units for this curriculum</div>
  );
};

const CurriculumForm = (props: any) => {
  const classes = useStyles(props);
  return (
    <FormWithRedirect
      {...props}
      render={(formProps: any) => (
        <Card>
          <form>
            <CardContent>
              <Box>
                <TextInput fullWidth source="name" />
              </Box>
              <Separator />
              <Box>
                <TextInput fullWidth source="languagesSupported" />
              </Box>
              <Separator />
              {/* <Box>
                <ImageInput
                  source="coverPhoto"
                  label="Cover Photo"
                  accept="image/*"
                  formClassName={classes.photo}
                >
                  <ImageContentField source="src" title="Cover Photo" />
                </ImageInput>
              </Box> */}
              <Box>
                <ReferenceInput
                  allowEmpty
                  label="Preview Video"
                  source="previewVideoId"
                  reference="content/video"
                >
                  <SelectInput optionText="name" />
                </ReferenceInput>
              </Box>
              <Box>
                <ReferenceManyField
                  sort={{ field: 'unitNumber', order: 'ASC' }}
                  reference="content/unit"
                  target="curriculumId"
                  label="Units"
                  perPage={50}
                  fullWidth
                >
                  <InnerUnitList />
                </ReferenceManyField>
              </Box>

              <Box>
                <ReferenceArrayInput
                  filterToQuery={(searchText: any) => ({ name: searchText })}
                  label="Coaches"
                  source="coachId"
                  reference="coach"
                >
                  <OrderedArrayInput elementLinkUrl="/#/coach/">
                    <AutocompleteArrayInput
                      optionValue="id"
                      optionText="name"
                      matchSuggestion={(filterValue: string, choice: any) =>
                        matchSuggestion(filterValue, choice, 'name')
                      }
                    />
                  </OrderedArrayInput>
                </ReferenceArrayInput>
              </Box>

              {/* <Box>
                <ReferenceArrayInput
                  filterToQuery={(searchText: any) => ({ name: searchText })}
                  label="Word of the Day Lessons"
                  source="lessonWordOfTheDayId"
                  reference="content/lesson"
                >
                  <OrderedArrayInput>
                    <AutocompleteArrayInput
                      optionValue="id"
                      optionText="name"
                      matchSuggestion={(filterValue: string, choice: any) =>
                        matchSuggestion(filterValue, choice, 'name')
                      }
                    />
                  </OrderedArrayInput>
                </ReferenceArrayInput>
              </Box> */}

              <Toolbar
                record={formProps.record}
                basePath={formProps.basePath}
                undoable={false}
                invalid={formProps.invalid}
                handleSubmit={formProps.handleSubmit}
                saving={formProps.saving}
                resource="practices"
                style={{ display: 'flex', justifyContent: 'space-between' }}
              >
                <div>
                  <SaveButton
                    label="Save & Continue"
                    handleSubmitWithRedirect={
                      formProps.handleSubmitWithRedirect ||
                      formProps.handleSubmit
                    }
                    disabled={formProps.disabled}
                    invalid={formProps.invalid}
                    redirect="edit"
                    saving={formProps.saving}
                    submitOnEnter={formProps.submitOnEnter}
                    className={classes.button}
                  />

                  <SaveButton
                    label="Save & Exit"
                    handleSubmitWithRedirect={
                      formProps.handleSubmitWithRedirect ||
                      formProps.handleSubmit
                    }
                    disabled={formProps.disabled}
                    invalid={formProps.invalid}
                    redirect={formProps.redirect}
                    saving={formProps.saving}
                    submitOnEnter={formProps.submitOnEnter}
                    className={classes.button}
                  />
                </div>

                {formProps.record &&
                  typeof formProps.record.id !== 'undefined' && (
                    <DeleteButton
                      basePath={formProps.basePath}
                      record={formProps.record}
                      resource={formProps.resource}
                      undoable={formProps.undoable}
                      mutationMode={formProps.mutationMode}
                    />
                  )}
              </Toolbar>
            </CardContent>
          </form>
        </Card>
      )}
    />
  );
};

const CurriculumEdit: FC<EditProps> = (props) => (
  <Edit undoable={false} title={<CurriculumTitle />} {...props}>
    <CurriculumForm />
  </Edit>
);

export default CurriculumEdit;
