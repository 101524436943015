import * as React from 'react';
import { FC, memo } from 'react';

import { FieldProps } from 'react-admin';

interface Props extends FieldProps {
  phoneme?: any;
}

const PhonemeNameField: FC<Props> = ({ record, phoneme }) => {
  const found = record?.phonemes?.find(
    (phonemeItem: any) => phonemeItem.id === phoneme.id,
  );
  const { arpabet, ipa, text } = found;
  return record && arpabet ? (
    <div>{`${text} | ${ipa} | ${arpabet}`}</div>
  ) : null;
};

// PhonemeNameField.defaultProps = {
//   source: 'arpabet',
//   label: 'Phoneme',
// };

export default memo<Props>(PhonemeNameField);
