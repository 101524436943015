import * as React from 'react';
import { FC } from 'react';
import { Link, FieldProps } from 'react-admin';

const AudioLogLinkField: FC<FieldProps> = (props) =>
  props.record ? (
    <Link
      to={`/audiologs?displayedFilters=${encodeURIComponent(
        `{"User.id":true,"requestId":true}`,
      )}&filter=${encodeURIComponent(
        `{"User":{"id":"${props.record.userId}"},"requestId":"${props.record.requestId}"}`,
      )}&order=DESC&page=1&perPage=10&sort=createdAt`}
    >
      <div>{props.record?.requestId ? 'Link' : ''}</div>
    </Link>
  ) : null;

AudioLogLinkField.defaultProps = {
  source: 'requestId',
  label: 'Audio Log Link',
  addLabel: true,
};

export default AudioLogLinkField;
