import * as React from 'react';
import { FC, memo } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { FieldProps } from 'react-admin';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
  },
  avatar: {
    marginRight: theme.spacing(1),
    marginTop: -theme.spacing(0.5),
    marginBottom: -theme.spacing(0.5),
  },
}));

type Props = FieldProps;

const PracticeNameField: FC<Props> = ({ record }) => {
  const classes = useStyles();
  return record ? (
    <div className={classes.root}>{record.Video?.name}</div>
  ) : null;
};

PracticeNameField.defaultProps = {
  source: 'word',
  label: 'Text',
};

export default memo<Props>(PracticeNameField);
