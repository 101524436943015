import * as React from 'react';
import { FC } from 'react';
import { Link, FieldProps } from 'react-admin';

import NoNameField from './NoNameField';

const UserLinkField: FC<FieldProps> = (props) =>
  props.record ? (
    <Link to={`/provisional_users/${props.record?.id}`}>
      <NoNameField {...props} />
    </Link>
  ) : null;

UserLinkField.defaultProps = {
  source: 'firstName',
  label: 'Record',
  addLabel: true,
};

export default UserLinkField;
