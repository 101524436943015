/**
 * @generated SignedSource<<94c6009c414bcfae7c8835d340f7eb88>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MultipleChoicePromptSelect_multipleChoicePromptsByIDs$data = {
  readonly multipleChoicePromptsByIDs: ReadonlyArray<{
    readonly id: string | null | undefined;
    readonly text: string | null | undefined;
  }> | null | undefined;
  readonly " $fragmentType": "MultipleChoicePromptSelect_multipleChoicePromptsByIDs";
};
export type MultipleChoicePromptSelect_multipleChoicePromptsByIDs$key = {
  readonly " $data"?: MultipleChoicePromptSelect_multipleChoicePromptsByIDs$data;
  readonly " $fragmentSpreads": FragmentRefs<"MultipleChoicePromptSelect_multipleChoicePromptsByIDs">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": [],
      "kind": "LocalArgument",
      "name": "ids"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [],
      "operation": require('./MultipleChoicePromptSelect_multipleChoicePromptsByIDs_RefetchQuery.graphql')
    }
  },
  "name": "MultipleChoicePromptSelect_multipleChoicePromptsByIDs",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "ids",
          "variableName": "ids"
        }
      ],
      "concreteType": "MultipleChoicePrompt",
      "kind": "LinkedField",
      "name": "multipleChoicePromptsByIDs",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "text",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "aaabba48581a12b9c521e12487af8963";

export default node;
