import { useEffect, useRef } from 'react';

// you can either pass a single number delay and the callback fn will be called with no arguemnts
// or you can pass a delay sequence array as a number[] and the callback fn will be called with the index of the delay

export default function useInterval(
  callback: (() => void) | ((index?: number) => void),
  delay: number | number[],
  active = true,
) {
  const savedCallback = useRef<(index?: number) => void>();
  const intervalRef = useRef<any>();
  const delayIndex = useRef<number>(0);
  // const now = Date.now();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    function tick() {
      // console.log('tick', (Date.now() - now).toString());

      if (savedCallback.current === undefined) return;
      if (Array.isArray(delay)) {
        const upcomingIndex = (delayIndex.current + 1) % delay.length;
        delayIndex.current = upcomingIndex;
        savedCallback.current(delayIndex.current); // should you call the array n+1 or n?, settling on n+1, fn should invoked on the transition to the next stage
        const nextDelay = delay[delayIndex.current];
        clearInterval(intervalRef.current);
        intervalRef.current = setInterval(tick, nextDelay);
      } else {
        savedCallback?.current();
      }
    }
    // console.log('useInterval setup', delay, active);

    if (delay !== null && active) {
      if (Array.isArray(delay)) {
        intervalRef.current = setInterval(tick, delay[delayIndex.current]);
      } else {
        intervalRef.current = setInterval(tick, delay);
      }
    }

    if (!active && intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
        intervalRef.current = null;
        delayIndex.current = 0;
      }
    };
  }, [Array.isArray(delay) ? JSON.stringify(delay) : delay, active]);
}
