/**
 * @generated SignedSource<<6785a9cd5a2e1a2b2a00993af5384dde>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SkillFormAssociatedMultipleChoiceQuestions_AssociatedMultipleChoiceQuestions_Mutation$variables = {
  multipleChoiceQuestionIDs: ReadonlyArray<string>;
  skillID: string;
};
export type SkillFormAssociatedMultipleChoiceQuestions_AssociatedMultipleChoiceQuestions_Mutation$data = {
  readonly setSkillMultipleChoiceQuestions: {
    readonly " $fragmentSpreads": FragmentRefs<"SkillFormAssociatedMultipleChoiceQuestions_skill">;
  } | null | undefined;
};
export type SkillFormAssociatedMultipleChoiceQuestions_AssociatedMultipleChoiceQuestions_Mutation = {
  response: SkillFormAssociatedMultipleChoiceQuestions_AssociatedMultipleChoiceQuestions_Mutation$data;
  variables: SkillFormAssociatedMultipleChoiceQuestions_AssociatedMultipleChoiceQuestions_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "multipleChoiceQuestionIDs"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "skillID"
},
v2 = [
  {
    "kind": "Variable",
    "name": "multipleChoiceQuestionIDs",
    "variableName": "multipleChoiceQuestionIDs"
  },
  {
    "kind": "Variable",
    "name": "skillID",
    "variableName": "skillID"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "nodeID",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "SkillFormAssociatedMultipleChoiceQuestions_AssociatedMultipleChoiceQuestions_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Skill",
        "kind": "LinkedField",
        "name": "setSkillMultipleChoiceQuestions",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "SkillFormAssociatedMultipleChoiceQuestions_skill"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "SkillFormAssociatedMultipleChoiceQuestions_AssociatedMultipleChoiceQuestions_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Skill",
        "kind": "LinkedField",
        "name": "setSkillMultipleChoiceQuestions",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "MultipleChoiceQuestion",
            "kind": "LinkedField",
            "name": "allMultipleChoiceQuestions",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "text",
                "storageKey": null
              },
              (v4/*: any*/)
            ],
            "storageKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "1263afb25c0d294409c478afac0f2dcd",
    "id": null,
    "metadata": {},
    "name": "SkillFormAssociatedMultipleChoiceQuestions_AssociatedMultipleChoiceQuestions_Mutation",
    "operationKind": "mutation",
    "text": "mutation SkillFormAssociatedMultipleChoiceQuestions_AssociatedMultipleChoiceQuestions_Mutation(\n  $skillID: ID!\n  $multipleChoiceQuestionIDs: [ID!]!\n) {\n  setSkillMultipleChoiceQuestions(skillID: $skillID, multipleChoiceQuestionIDs: $multipleChoiceQuestionIDs) {\n    ...SkillFormAssociatedMultipleChoiceQuestions_skill\n    nodeID\n  }\n}\n\nfragment SkillFormAssociatedMultipleChoiceQuestions_skill on Skill {\n  id\n  allMultipleChoiceQuestions {\n    id\n    text\n    nodeID\n  }\n}\n"
  }
};
})();

(node as any).hash = "e757fcc399463580c41c4f6743ee6501";

export default node;
