import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Modal,
} from '@material-ui/core';
import { Theme, makeStyles } from '@material-ui/core/styles';
import { Styles } from '@material-ui/styles/withStyles';
import * as React from 'react';
import { FC } from 'react';
import {
  AutocompleteArrayInput,
  BooleanInput,
  DateTimeInput,
  DeleteButton,
  Edit,
  EditProps,
  FieldProps,
  FormWithRedirect,
  ReferenceArrayInput,
  SaveButton,
  TextInput,
  Toolbar,
  useGetList,
} from 'react-admin';

import api from '../api';
import ChallengeOrderInput from '../components/ChallengeOrderInput';
import PracticeSetInput from '../components/PracticeSetInput';
import { matchSuggestion } from './utils';

const LessonTitle: FC<FieldProps> = ({ record }) =>
  record ? (
    <span>
      Lesson &quot;
      {record.name}&quot;
    </span>
  ) : null;

export const styles: Styles<Theme, any> = {
  name: { display: 'flex', flex: 1, marginRight: 10 },
  photo: { display: 'inline-block' },
  image: {
    margin: '0.5rem',
    maxHeight: '10rem',
  },
  button: {
    marginRight: 10,
  },
  paper: {
    position: 'absolute',
    width: '75%',
    height: 500,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    margin: 'auto',
    backgroundColor: 'white',
    padding: 40,
    overflow: 'scroll',
  },
};

const useStyles = makeStyles(styles);

const LessonForm = (props: any) => {
  const classes = useStyles(props);
  const [modalOpen, setModalOpen] = React.useState(false);
  const { data, loading, error } = useGetList('content/unit', {
    page: 1,
    perPage: 99999,
  });

  const [selectedUnits, setSelectedUnits] = React.useState<any>({});

  const bringUpCopyModal = React.useCallback(() => {
    setModalOpen(true);
  }, []);

  const closeCopyModal = React.useCallback(() => {
    setModalOpen(false);
    setSelectedUnits({});
  }, []);

  const submitCopy = React.useCallback(async () => {
    const newObject = Object.assign(
      {},
      ...Object.keys(selectedUnits)
        .filter((unitId: any) => selectedUnits[unitId])
        .map((unitId: any) => ({ [unitId]: true })),
    );
    const lessonId = props?.record?.id;
    try {
      const submittedCopy = await api.submitLessonCopy(lessonId, newObject);
      if (submittedCopy.status === 200) {
        alert('Copy successful.');
      } else {
        alert('Not successful, check & try again.');
      }
    } catch (err) {
      alert('Not successful, check & try again.');
    }

    closeCopyModal();
  }, [selectedUnits, props?.record?.id]);

  const selectAll = React.useCallback(() => {
    const newObject = Object.assign(
      {},
      ...Object.keys(data)
        // .filter(
        //   (curriculumItemId: any) =>
        //     curriculumItemId !== props?.record?.curriculumId,
        // )
        .map((unitId: any) => ({ [unitId]: true })),
    );
    setSelectedUnits(newObject);
  }, [data]);

  const unselectAll = React.useCallback(() => {
    setSelectedUnits({});
  }, []);

  const isSelectedAll = React.useMemo(() => {
    const numberSelectedUnits = Object.values(selectedUnits || {})?.filter(
      (item) => item,
    ).length;
    const numberTotalUnits = Object.keys(data)?.length;
    return numberSelectedUnits === numberTotalUnits;
  }, [selectedUnits, data]);

  const updateCheckboxes = React.useCallback((event, unitId) => {
    const newCheckStatus = event.target.checked;
    setSelectedUnits((existingSelectedUnits: any) => ({
      ...existingSelectedUnits,
      [unitId]: newCheckStatus,
    }));
  }, []);

  return (
    <>
      <Modal open={modalOpen} onClose={closeCopyModal}>
        <div className={classes.paper}>
          <div>Choose which units to copy lesson to:</div>
          <div style={{ paddingTop: 20 }}>
            <Button
              onClick={isSelectedAll ? unselectAll : selectAll}
              variant="contained"
              color="primary"
            >
              {isSelectedAll ? `Unselect All` : `Select All`}
            </Button>
          </div>
          <div style={{ paddingTop: 20 }}>
            <Button onClick={submitCopy} variant="contained" color="primary">
              Submit
            </Button>
          </div>
          {loading ? (
            <div>Loading...</div>
          ) : error ? (
            <div>Error occurred</div>
          ) : (
            <div style={{ paddingTop: 20 }}>
              {Object.values(data).map((unitItem: any) => {
                const isChecked = !!selectedUnits?.[unitItem?.id];
                return (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}
                  >
                    <Checkbox
                      checked={isChecked}
                      onChange={(event: any) =>
                        updateCheckboxes(event, unitItem?.id)
                      }
                    />
                    <div>
                      {unitItem.Curriculum?.name} - #{unitItem?.unitNumber} -{' '}
                      {unitItem?.name}
                    </div>
                  </div>
                );
              })}
            </div>
          )}

          <div style={{ paddingTop: 20 }}>
            <Button onClick={submitCopy} variant="contained" color="primary">
              Submit
            </Button>
          </div>
        </div>
      </Modal>
      <FormWithRedirect
        {...props}
        render={(formProps: any) => (
          <Card>
            <form>
              <CardContent>
                <Box
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <TextInput
                    autoFocus
                    source="name"
                    label="Lesson Name"
                    className={classes.name}
                    helperText={false}
                  />
                  <Button
                    onClick={bringUpCopyModal}
                    variant="contained"
                    color="primary"
                  >
                    Copy Lesson
                  </Button>
                </Box>
                <Box display="flex" flexDirection="row" alignItems="flex-start">
                  <Box flex={1}>
                    <TextInput
                      source="tags"
                      label="Tags"
                      fullWidth
                      helperText={false}
                    />
                  </Box>
                </Box>
                <Box display="flex" flexDirection="row" alignItems="flex-start">
                  <Box flex={1}>
                    <TextInput
                      multiline
                      source="notes"
                      label="Notes"
                      fullWidth
                      helperText={false}
                    />
                  </Box>
                </Box>
                <Box display="flex" flexDirection="row" alignItems="flex-start">
                  <Box alignSelf="flex-end">
                    <BooleanInput
                      source="requiresSubscription"
                      label="Requires Subscription?"
                      helperText={false}
                    />
                  </Box>
                  <Box ml={5} alignSelf="flex-end">
                    <BooleanInput
                      source="isFocusWord"
                      label="Is Focus Word?"
                      helperText={false}
                    />
                  </Box>
                  <Box ml={5} alignSelf="flex-end">
                    <BooleanInput
                      source="isIdiom"
                      label="Is Idiom?"
                      helperText={false}
                    />
                  </Box>
                  <Box ml={5} alignSelf="flex-end">
                    <BooleanInput
                      source="isTutorial"
                      label="Is Tutorial?"
                      helperText={false}
                    />
                    {props?.record?.isTutorial && (
                      <TextInput
                        multiline
                        source="tutorialLanguage"
                        label="Tutorial Language"
                        fullWidth
                        helperText={false}
                      />
                    )}
                  </Box>
                  <Box ml={5}>
                    <DateTimeInput
                      source="goesPublicTime"
                      label="Publicly Viewable At"
                      helperText={false}
                    />
                  </Box>
                </Box>
                <Box>
                  <ReferenceArrayInput
                    filterToQuery={(searchText: any) => ({ text: searchText })}
                    label="Phonemes"
                    source="phonemeId"
                    reference="content/phoneme"
                    fullWidth
                    helperText={false}
                  >
                    <AutocompleteArrayInput
                      optionValue="id"
                      optionText={(record: any) =>
                        `${record?.text} | ${record?.ipa} | ${record?.arpabet}`
                      }
                      matchSuggestion={(filterValue: string, choice: any) =>
                        matchSuggestion(filterValue, choice, 'text')
                      }
                    />
                  </ReferenceArrayInput>
                </Box>

                <Box
                  style={{
                    width: '100%',
                    backgroundColor: 'black',
                    height: 2,
                    marginTop: 15,
                    marginBottom: 5,
                  }}
                />

                <Box style={{ marginBottom: 5 }}>
                  <div>Lesson Content</div>
                </Box>

                <Box>
                  <ReferenceArrayInput
                    filterToQuery={(searchText: any) => ({
                      contentType: searchText,
                    })}
                    label="Order of Challenges"
                    source="challengeId"
                    reference="content/challenges"
                    fullWidth
                    helperText={false}
                    disabled
                  >
                    <ChallengeOrderInput>
                      <AutocompleteArrayInput
                        debounce={750}
                        optionValue="id"
                        optionText={(record: any) =>
                          `${record?.contentType}: ${
                            record?.content?.name
                          } (id: ${record?.id?.slice(0, 4)})`
                        }
                        disabled
                      />
                    </ChallengeOrderInput>
                  </ReferenceArrayInput>
                </Box>

                <Box>
                  <ReferenceArrayInput
                    filterToQuery={(searchText: any) => ({ name: searchText })}
                    label="Conversations"
                    source="conversationId"
                    reference="content/conversation"
                    fullWidth
                    helperText={false}
                  >
                    <AutocompleteArrayInput
                      debounce={750}
                      optionValue="id"
                      optionText={(record: any) =>
                        `${record?.name} (id: ${record?.id?.slice(0, 4)})`
                      }
                      allowDuplicates
                      matchSuggestion={(filterValue: string, choice: any) =>
                        matchSuggestion(filterValue, choice, 'name')
                      }
                    />
                  </ReferenceArrayInput>
                </Box>

                <Box>
                  <div
                    style={{
                      marginTop: '10px',
                    }}
                  />
                  <ReferenceArrayInput
                    filterToQuery={(searchText: any) => ({
                      name: searchText,
                    })}
                    label="Rapidfire"
                    source="rapidfireId"
                    reference="content/rapidfire"
                    fullWidth
                    helperText={false}
                  >
                    <AutocompleteArrayInput
                      debounce={750}
                      optionValue="id"
                      optionText={(record: any) =>
                        `${record?.name} (id: ${record?.id?.slice(0, 4)})`
                      }
                      allowDuplicates
                      matchSuggestion={(filterValue: string, choice: any) =>
                        matchSuggestion(filterValue, choice, 'name')
                      }
                    />
                  </ReferenceArrayInput>
                </Box>

                <Box>
                  <ReferenceArrayInput
                    filterToQuery={(searchText: any) => ({ name: searchText })}
                    label="Videos"
                    source="videoId"
                    reference="content/video"
                    fullWidth
                    helperText={false}
                  >
                    <AutocompleteArrayInput
                      optionValue="id"
                      optionText={(record: any) =>
                        `${record?.name} ${
                          record?.tags ? `- ${record?.tags}` : ``
                        } (id: ${record?.id?.slice(0, 4)})`
                      }
                      allowDuplicates
                      matchSuggestion={(filterValue: string, choice: any) =>
                        matchSuggestion(filterValue, choice, 'name')
                      }
                    />
                  </ReferenceArrayInput>
                </Box>

                <Box>
                  <ReferenceArrayInput
                    filterToQuery={(searchText: any) => ({ name: searchText })}
                    label="Practice Sets"
                    source="practiceSetId"
                    reference="content/practice_set"
                    fullWidth
                    filter={{ lessonId: props?.record?.id }}
                    helperText={false}
                  >
                    <PracticeSetInput lessonId={props?.record?.id} />
                  </ReferenceArrayInput>
                </Box>

                {/* <Box>
                  <ReferenceArrayInput
                    filterToQuery={(searchText: any) => ({
                      word: searchText,
                    })}
                    label="Practice Items"
                    source="practiceId"
                    reference="content/practice"
                    fullWidth
                    filter={{
                      isUserGeneratedContent: false,
                    }}
                    helperText={false}
                  >
                    <LessonPracticeInput
                      lessonId={props?.record?.id}
                      showPracticeSets={props?.record?.practice_sets?.length}
                    >
                      <AutocompleteArrayInput
                        debounce={750}
                        optionValue="id"
                        optionText={(record: any) =>
                          `${record?.word} ${
                            record?.coachSample ? ` ✅` : ` ❌`
                          } (id: ${record?.id?.slice(0, 4)})`
                        }
                        allowDuplicates
                        matchSuggestion={(filterValue: string, choice: any) =>
                          matchSuggestion(filterValue, choice, 'word')
                        }
                      />
                    </LessonPracticeInput>
                  </ReferenceArrayInput>

                  <Card>
                    <CardContent>
                      <DifficultyParent>
                        {difficultyList.map((di: any) => (
                          <div key={di}>
                            Num. {di.name}:{' '}
                            {
                              formProps?.record?.practice_items?.filter(
                                (i: any) =>
                                  i.LessonPractice?.difficulty === di.id ||
                                  i.LessonPractice?.difficulty === di.value,
                              ).length
                            }
                          </div>
                        ))}
                      </DifficultyParent>
                    </CardContent>
                  </Card>
                </Box> */}

                <Toolbar
                  record={formProps.record}
                  basePath={formProps.basePath}
                  undoable={false}
                  invalid={formProps.invalid}
                  handleSubmit={formProps.handleSubmit}
                  saving={formProps.saving}
                  resource="practices"
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <div>
                    <SaveButton
                      id="lesson-save-and-continue-btn"
                      label="Save & Continue"
                      handleSubmitWithRedirect={
                        formProps.handleSubmitWithRedirect ||
                        formProps.handleSubmit
                      }
                      disabled={formProps.disabled}
                      invalid={formProps.invalid}
                      redirect="edit"
                      saving={formProps.saving}
                      submitOnEnter={formProps.submitOnEnter}
                      className={classes.button}
                    />

                    <SaveButton
                      label="Save & Exit"
                      handleSubmitWithRedirect={
                        formProps.handleSubmitWithRedirect ||
                        formProps.handleSubmit
                      }
                      disabled={formProps.disabled}
                      invalid={formProps.invalid}
                      redirect={formProps.redirect}
                      saving={formProps.saving}
                      submitOnEnter={formProps.submitOnEnter}
                      className={classes.button}
                    />
                  </div>

                  {formProps.record &&
                    typeof formProps.record.id !== 'undefined' && (
                      <DeleteButton
                        basePath={formProps.basePath}
                        record={formProps.record}
                        resource={formProps.resource}
                        undoable={formProps.undoable}
                        mutationMode={formProps.mutationMode}
                      />
                    )}
                </Toolbar>
              </CardContent>
            </form>
          </Card>
        )}
      />
    </>
  );
};

const LessonEdit: FC<EditProps> = (props) => (
  <Edit undoable={false} title={<LessonTitle />} {...props}>
    <LessonForm />
  </Edit>
);

export default LessonEdit;
