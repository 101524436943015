import * as MUI from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import graphql from 'babel-plugin-relay/macro';
import React from 'react';
import { useLazyLoadQuery } from 'react-relay';

const VideoSelect = (props: any) => (
  <React.Suspense
    fallback={<VideoSelectField {...props} isLoading videos={[]} />}
  >
    <VideoSelectWithData {...props} />
  </React.Suspense>
);

const VideoSelectWithData = (props: any) => {
  const [isRefreshPending, startRefreshTransition] = React.useTransition();
  const [refreshedQueryOptions, setRefreshedQueryOptions] = React.useState({});

  const data: any = useLazyLoadQuery(
    graphql`
      query VideoSelect_allVideos_Query {
        allVideos {
          id
          title
        }
      }
    `,
    {},
    refreshedQueryOptions,
  );

  const refresh = React.useCallback(() => {
    startRefreshTransition(() => {
      setRefreshedQueryOptions((prev: any) => ({
        fetchKey: (prev.fetchKey ?? 0) + 1,
        fetchPolicy: 'network-only',
      }));
    });
  }, [startRefreshTransition, setRefreshedQueryOptions]);

  return (
    <VideoSelectField
      {...props}
      videos={data.allVideos}
      onRefresh={refresh}
      isRefreshPending={isRefreshPending}
    />
  );
};

const VideoSelectField = ({
  videos,
  onChange,
  value,
  onRefresh,
  isLoading,
  isRefreshPending,
}: any) => {
  const videosByID = React.useMemo(
    () => Object.fromEntries(videos.map((video: any) => [video.id, video])),
    [videos],
  );

  const videoIDs = React.useMemo(
    () => videos.map((video: any) => video.id),
    [videos],
  );

  return (
    <Autocomplete
      options={videoIDs}
      getOptionLabel={(videoID: any) =>
        videosByID[videoID]?.title || 'Invalid Video'
      }
      style={{ width: 600 }}
      size="small"
      blurOnSelect
      selectOnFocus
      disabled={isLoading}
      renderOption={(videoID: any) => {
        const video = videosByID[videoID];
        return (
          <MUI.Box display="flex" flexDirection="column">
            {video.title}
            <MUI.Box component="span" color="text.secondary" fontSize={12}>
              {video.id}
            </MUI.Box>
          </MUI.Box>
        );
      }}
      renderInput={(params) => (
        <MUI.TextField
          {...params}
          label="Video"
          variant="filled"
          required={false}
          margin="normal"
          InputLabelProps={{ shrink: value ? true : undefined }}
          helperText={
            isLoading ? (
              'Loading videos...'
            ) : isRefreshPending ? (
              'Refreshing videos...'
            ) : (
              <MUI.Box>
                <MUI.Link
                  style={{ cursor: 'pointer' }}
                  onClick={(e: any) => {
                    onRefresh?.();
                    e.stopPropagation();
                  }}
                >
                  Refresh
                </MUI.Link>{' '}
                videos{' | '}
                {!!value && (
                  <MUI.Link
                    style={{ cursor: 'pointer' }}
                    href={`/#/g/videos/${value}`}
                    target="_blank"
                  >
                    Go to video group
                  </MUI.Link>
                )}
              </MUI.Box>
            )
          }
        />
      )}
      onChange={(e, value) => onChange(value)}
      value={value}
    />
  );
};

export default VideoSelect;
