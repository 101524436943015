import * as React from 'react';
import { FC } from 'react';
import {
  Create,
  CreateProps,
  SimpleForm,
  TextInput,
  required,
} from 'react-admin';

import { makeStyles, Theme } from '@material-ui/core/styles';
import { Styles } from '@material-ui/styles/withStyles';

export const styles: Styles<Theme, any> = {
  name: { display: 'inline-block' },
  number: { display: 'inline-block', marginLeft: 10 },
};

const useStyles = makeStyles(styles);

const PhonemeCreate: FC<CreateProps> = (props) => {
  const classes = useStyles(props);

  return (
    <Create {...props}>
      <SimpleForm initialValues={{}}>
        <TextInput
          fullWidth
          autoFocus
          source="arpabet"
          label="Arpabet"
          className={classes.name}
          validate={requiredValidate}
        />
        <TextInput
          fullWidth
          autoFocus
          source="unicode"
          label="Unicode"
          className={classes.name}
          validate={requiredValidate}
        />
        <TextInput
          fullWidth
          autoFocus
          source="ipa"
          label="Ipa"
          className={classes.name}
          validate={requiredValidate}
        />
        <TextInput
          fullWidth
          autoFocus
          source="text"
          label="Text"
          className={classes.name}
          validate={requiredValidate}
        />
        <TextInput
          fullWidth
          autoFocus
          source="tip"
          label="Tip"
          className={classes.name}
          validate={requiredValidate}
        />

        {/* <JsonInput
          source="target_sounds"
          jsonString={false}
          reactJsonOptions={{
            name: 'Target Sounds',
            collapsed: false,
            enableClipboard: true,
            displayDataTypes: false,
            sortKeys: true,
            displayObjectSize: false,
          }}
        /> */}
      </SimpleForm>
    </Create>
  );
};

const requiredValidate = [required()];

export default PhonemeCreate;
