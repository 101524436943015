/* eslint-disable @typescript-eslint/no-unused-vars */
// @ts-nocheck
import { Button, Typography } from '@material-ui/core';
import React from 'react';
import { BooleanInput, NumberInput, useRefresh } from 'react-admin';
import { Form } from 'react-final-form';

import api from '../../../api';

function HighlightedString({ inputString, highlights }: any) {
  const renderHighlightedLine = (line, index) => {
    const lineElements = [];

    for (let i = 0; i < line.length; i++) {
      const letter = line[i];

      const isHighlighted = highlights.some(
        (highlight) =>
          highlight.lineIndex === index &&
          i >= highlight.startIndex &&
          i < highlight.endIndex,
      );

      lineElements.push(
        <span
          key={`${index}-${i}`}
          style={{
            backgroundColor: isHighlighted ? '#F5277F' : 'transparent',
            color: isHighlighted ? 'white' : 'black',
          }}
        >
          {letter === ' ' ? '\u00A0' : letter}
        </span>,
      );
    }

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          marginBottom: 4,
        }}
        key={index}
      >
        {lineElements}
      </div>
    );
  };

  const lines = inputString.split('\n');

  return (
    <div>{lines.map((line, index) => renderHighlightedLine(line, index))}</div>
  );
}

export default function TextWithHighlightsEditForm({
  record,
}: {
  record: any;
}) {
  const refresh = useRefresh();

  const handleSave = async (data: any) => {
    const { id, videoId, createdAt, updatedAt, graphicType, ...rest } = data;

    try {
      const response = await api.patchVideoTextWithHighlight(
        data.videoId,
        data.id,
        rest,
      );

      if (response.status < 300) {
        refresh();
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  const handleDelete = async () => {
    const response = window.confirm('Are you sure you want to do that?');

    if (response) {
      try {
        await api.deleteVideoTextWithHighlight(record?.videoId, record.id);
        refresh();
      } catch (error) {
        console.log('error', error);
      }
    }
  };

  const textSplitByNewLine = record?.text?.split('\n') || [];
  const highlights = record?.highlights || [];

  return (
    <>
      <Typography variant="h3">Text with Highlights</Typography>
      <Form
        initialValues={record}
        onSubmit={handleSave}
        render={({ handleSubmit }: any) => (
          <form
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
            onSubmit={handleSubmit}
          >
            <div
              style={{
                minWidth: 300,
                marginRight: 10,
              }}
            >
              <NumberInput
                label="From"
                source="from"
                style={{ marginRight: 12 }}
              />
              <NumberInput label="To" source="to" />
              <BooleanInput label="Is In Ms" source="isInMs" />
            </div>

            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  onClick={handleSubmit}
                >
                  Save
                </Button>
              </div>
              {record && typeof record.id !== 'undefined' && (
                <Button
                  style={{
                    backgroundColor: 'red',
                    color: 'white',
                  }}
                  onClick={handleDelete}
                >
                  Delete
                </Button>
              )}
            </div>
          </form>
        )}
      />
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Typography
          variant="h3"
          style={{
            textAlign: 'center',
          }}
        >
          <HighlightedString
            inputString={record?.text}
            highlights={highlights}
          />
        </Typography>
        <div style={{ marginTop: 10 }}>
          <table
            style={{
              width: '100%',
              marginTop: 10,
            }}
          >
            <thead>
              {highlights.length > 0 && (
                <tr>
                  <td>Highlight #</td>
                  <td align="center">Text</td>
                </tr>
              )}
            </thead>
            <tbody>
              {highlights.map((highlight, highlightIdx) => {
                const { lineIndex, startIndex, endIndex } = highlight;
                const line = textSplitByNewLine[lineIndex];

                const start = line?.substring(0, startIndex);
                const middle = line?.substring(startIndex, endIndex);
                const end = line?.substring(endIndex, line.length);

                return (
                  <tr
                    key={`highlight-item-${lineIndex}-${startIndex}-${endIndex}`}
                  >
                    <td>{highlightIdx + 1}</td>
                    <td>
                      {start}
                      <span
                        style={{
                          backgroundColor: '#F5277F',
                          color: 'white',
                        }}
                      >
                        {middle}
                      </span>
                      {end}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      <p style={{ color: 'gray' }}>
        Text with highlights are not editable once created, can do if there is
        demand
      </p>
    </>
  );
}
