import { Box, Card, CardContent, Typography } from '@material-ui/core';
import { Theme, makeStyles } from '@material-ui/core/styles';
import { Styles } from '@material-ui/styles/withStyles';
import * as React from 'react';
import { FC } from 'react';
import {
  AutocompleteArrayInput,
  CheckboxGroupInput,
  DeleteButton,
  Edit,
  EditProps,
  FieldProps,
  FormWithRedirect,
  ReferenceArrayInput,
  SaveButton,
  SelectInput,
  TextInput,
  Toolbar,
  useRecordContext,
} from 'react-admin';
import { JsonInput } from 'react-admin-json-view';

import OrderedArrayInput from '../components/OrderedArrayInput';
import { matchSuggestion } from '../lesson/utils';

const PhonemeIpa: FC<FieldProps> = ({ record }) =>
  record ? (
    <span>
      Phoneme &quot;
      {record.ipa}&quot;
    </span>
  ) : null;

export const styles: Styles<Theme, any> = {
  name: { display: 'inline-block' },
  photo: { display: 'inline-block' },
  spacing: { marginRight: 10 },
  image: {
    margin: '0.5rem',
    maxHeight: '10rem',
  },
  button: {
    marginRight: 10,
  },
};

const useStyles = makeStyles(styles);

const PhonemeForm = (props: any) => {
  const classes = useStyles(props);
  const record = useRecordContext();

  const videoChoices = props.record?.videos?.map((video: any) => ({
    id: video.id,
    name: `${video.name} ${video?.tags ? `- ${video?.tags}` : ``}`,
  }));

  record.phonemeVideoForCloseUpIds = record?.videos
    ?.filter((v: any) => v.PhonemeVideo?.isPhoneCloseupDemo)
    ?.map((v: any) => v.id);

  return (
    <FormWithRedirect
      {...props}
      render={(formProps: any) => (
        <Card>
          <form>
            <CardContent>
              <Box>
                <TextInput
                  fullWidth
                  autoFocus
                  source="text"
                  label="Text"
                  className={classes.name}
                />
              </Box>
              <Box>
                <TextInput
                  fullWidth
                  autoFocus
                  source="arpabet"
                  label="Arpabet"
                  className={classes.name}
                />
              </Box>

              <JsonInput
                source="singleSoundExample"
                record={props.record}
                jsonString={false}
                reactJsonOptions={{
                  name: 'singleSoundExample',
                  collapsed: false,
                  enableClipboard: true,
                  displayDataTypes: false,
                  sortKeys: true,
                  displayObjectSize: true,
                }}
              />
              <Box>
                <TextInput
                  fullWidth
                  autoFocus
                  source="unicode"
                  label="Unicode"
                  className={classes.name}
                />
              </Box>
              <Box>
                <TextInput
                  fullWidth
                  autoFocus
                  source="ipa"
                  label="Ipa"
                  className={classes.name}
                />
              </Box>
              <Box>
                <TextInput
                  fullWidth
                  autoFocus
                  source="tip"
                  label="Tip"
                  className={classes.name}
                />
              </Box>
              <Box>
                <SelectInput
                  fullWidth
                  autoFocus
                  source="status"
                  label="Status"
                  choices={[
                    { id: 'active', name: 'Active' },
                    { id: 'inactive', name: 'Inactive' },
                  ]}
                  defaultValue="active"
                  className={classes.name}
                />
              </Box>

              <Box>
                <ReferenceArrayInput
                  filterToQuery={(searchText: any) => ({ name: searchText })}
                  label="Videos"
                  source="videoId"
                  reference="content/video"
                  fullWidth
                >
                  <OrderedArrayInput elementLinkUrl="/#/content/video/">
                    <AutocompleteArrayInput
                      optionValue="id"
                      optionText={(record2: any) =>
                        `${record2?.name} ${
                          record2?.tags ? `- ${record2?.tags}` : ``
                        }`
                      }
                      matchSuggestion={(filterValue: string, choice: any) =>
                        matchSuggestion(filterValue, choice, 'name')
                      }
                    />
                  </OrderedArrayInput>
                </ReferenceArrayInput>
                <CheckboxGroupInput
                  row={false}
                  choices={videoChoices}
                  source="phonemeVideoForCloseUpIds"
                />
              </Box>
              <Box>
                <Typography variant="h6" gutterBottom align="left">
                  Phoneme Examples
                </Typography>
                <TextInput
                  fullWidth
                  autoFocus
                  source="fullCoachExample"
                  label="Full Coach sample url"
                  className={classes.name}
                />
                <JsonInput
                  source="examples"
                  record={props.record}
                  jsonString={false}
                  reactJsonOptions={{
                    name: 'examples',
                    collapsed: false,
                    enableClipboard: true,
                    displayDataTypes: false,
                    sortKeys: true,
                    displayObjectSize: true,
                  }}
                />
                <Typography variant="h6" gutterBottom align="left">
                  Related Phonemes, (just an array of ids)
                </Typography>

                <JsonInput
                  source="related"
                  record={props.record}
                  jsonString={false}
                  reactJsonOptions={{
                    name: 'related',
                    collapsed: false,
                    enableClipboard: true,
                    displayDataTypes: false,
                    sortKeys: true,
                    displayObjectSize: true,
                  }}
                />
                <div style={{ height: 100 }}></div>
              </Box>

              <Toolbar
                record={formProps.record}
                basePath={formProps.basePath}
                mutationMode="undoable"
                invalid={formProps.invalid}
                handleSubmit={formProps.handleSubmit}
                saving={formProps.saving}
                resource="phonemes"
                style={{ display: 'flex', justifyContent: 'space-between' }}
              >
                <div>
                  <SaveButton
                    label="Save & Continue"
                    handleSubmitWithRedirect={
                      formProps.handleSubmitWithRedirect ||
                      formProps.handleSubmit
                    }
                    disabled={formProps.disabled}
                    invalid={formProps.invalid}
                    redirect="edit"
                    saving={formProps.saving}
                    submitOnEnter={formProps.submitOnEnter}
                    className={classes.button}
                  />

                  <SaveButton
                    label="Save & Exit"
                    handleSubmitWithRedirect={
                      formProps.handleSubmitWithRedirect ||
                      formProps.handleSubmit
                    }
                    disabled={formProps.disabled}
                    invalid={formProps.invalid}
                    redirect={formProps.redirect}
                    saving={formProps.saving}
                    submitOnEnter={formProps.submitOnEnter}
                    className={classes.button}
                  />
                </div>

                {formProps.record &&
                  typeof formProps.record.id !== 'undefined' && (
                    <DeleteButton
                      basePath={formProps.basePath}
                      record={formProps.record}
                      resource={formProps.resource}
                      mutationMode={formProps.mutationMode}
                    />
                  )}
              </Toolbar>
            </CardContent>
          </form>
        </Card>
      )}
    />
  );
};

const PhonemeEdit: FC<EditProps> = (props) => (
  <Edit mutationMode="pessimistic" title={<PhonemeIpa />} {...props}>
    <PhonemeForm />
  </Edit>
);

export default PhonemeEdit;
