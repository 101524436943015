import * as React from 'react';
import {
  List,
  Datagrid,
  TextField,
  Filter,
  NumberInput,
  TextInput,
  SelectInput,
  BooleanInput,
} from 'react-admin';

import UserLinkField from './UserLinkField';
import RecordLinkField from './RecordLinkField';
import { PHONE_OS_LIST, SUB_STATUS_LIST } from '../users/UserList';

const UserStatsFilter = (props: any) => (
  <Filter {...props}>
    <TextInput label="Email" source="User.email" defaultValue="" />
    <TextInput label="First Name" source="User.firstName" defaultValue="" />
    <TextInput label="Last Name" source="User.lastName" defaultValue="" />
    <SelectInput
      source="User.phoneOS"
      label="Phone OS"
      choices={PHONE_OS_LIST}
    />
    <TextInput label="Referral Source" source="User.referral" defaultValue="" />
    <TextInput label="Language" source="User.language" defaultValue="" />
    <BooleanInput
      label="Trial Or Subscribed"
      source="User.isSubscriber"
      defaultValue={false}
    />
    <SelectInput
      label="Sub Status"
      source="User.subStatus"
      choices={SUB_STATUS_LIST}
      defaultValue={null}
    />
    <NumberInput label="Practice Items >=" source="uniquePracticeItemsGte" />
    <NumberInput label="Practice Items  <=" source="uniquePracticeItemsLte" />

    <NumberInput
      label="7 Days Rolling Average >="
      source="averageScoreAllPracticeSevenDaysRollingGte"
    />
    <NumberInput
      label="7 Days Rolling Average <="
      source="averageScoreAllPracticeSevenDaysRollingLte"
    />
    <NumberInput label="Stars >=" source="starsCountGte" />
    <NumberInput label="Stars <=" source="starsCountLte" />
    <NumberInput label="Streak >=" source="streakCountGte" />
    <NumberInput label="Streak <=" source="streakCountLte" />
    <NumberInput label="Active Days >=" source="activeDaysCountGte" />
    <NumberInput label="Active Days <=" source="activeDaysCountLte" />
    <NumberInput
      label="Practice Count (all practice) >="
      source="allPracticeCountGte"
    />
    <NumberInput
      label="Practice Count (all practice) <="
      source="allPracticeCountLte"
    />
    <NumberInput
      label="Average Score (all practice) >="
      source="averageScoreAllPracticeGte"
    />
    <NumberInput
      label="Average Score (all practice) <="
      source="averageScoreAllPracticeLte"
    />

    <NumberInput
      label="Practice Count (practice set) >="
      source="practiceSetPracticeCountGte"
    />
    <NumberInput
      label="Practice Count (practice set) <="
      source="practiceSetPracticeCountLte"
    />
    <NumberInput
      label="Average Score (practice set) >="
      source="averageScorePracticeSetPracticeGte"
    />
    <NumberInput
      label="Average Score (practice set) <="
      source="averageScorePracticeSetPracticeLte"
    />

    <NumberInput
      label="Practice Count (in video) >="
      source="inVideoPracticeCountGte"
    />
    <NumberInput
      label="Practice Count (in video) <="
      source="inVideoPracticeCountLte"
    />
    <NumberInput
      label="Average Score (in video) >="
      source="averageScoreInVideoPracticeGte"
    />
    <NumberInput
      label="Average Score (in video) <="
      source="averageScoreInVideoPracticeLte"
    />

    <NumberInput
      label="Practice Count (conversations) >="
      source="conversationPracticeCountGte"
    />
    <NumberInput
      label="Practice Count (conversations) <="
      source="conversationPracticeCountLte"
    />
    <NumberInput
      label="Average Score (conversations) >="
      source="averageScoreConversationPracticeGte"
    />
    <NumberInput
      label="Average Score (conversations) <="
      source="averageScoreConversationPracticeLte"
    />
  </Filter>
);

export const UserStatsList = (props: any) => (
  <List
    {...props}
    sort={{ field: 'createdAt', order: 'DESC' }}
    filters={<UserStatsFilter />}
  >
    <Datagrid>
      <RecordLinkField sortable={false} />
      <UserLinkField sortable={false} />
      <TextField source="uniquePracticeItems" label="Practice Items" />
      <TextField source="allPracticeCount" label="Practice Count" />
      <TextField source="averageScoreAllPractice" label="Average Score" />
      <TextField
        source="averageScoreAllPracticeSevenDaysRolling"
        label="7 Days Rolling Average"
      />
      <TextField source="starsCount" label="Stars" />
      <TextField source="streakCount" label="Streak" />
      <TextField source="activeDaysCount" label="Active Days" />
    </Datagrid>
  </List>
);

export default UserStatsList;
