import * as MUI from '@material-ui/core';
import RemoveIcon from '@material-ui/icons/Remove';
import { Autocomplete } from '@material-ui/lab';
import graphql from 'babel-plugin-relay/macro';
import React from 'react';
import { useLazyLoadQuery, useRefetchableFragment } from 'react-relay';

const PhonemesSelect = (props: any) => (
  <React.Suspense
    fallback={
      <PhonemesSelectField
        {...props}
        isLoading
        selectedPhonemes={[]}
        phonemes={[]}
      />
    }
  >
    <PhonemesSelectWithData {...props} />
  </React.Suspense>
);

const PhonemesSelectWithData = (props: any) => {
  const [_isPending, startTransition] = React.useTransition();

  const initiallySelectedIDs = React.useMemo(() => props.value ?? [], []);

  const data: any = useLazyLoadQuery(
    graphql`
      query PhonemesSelect_Query($initiallySelectedIDs: [ID!]!) {
        ...PhonemesSelect_phonemesForString
        ...PhonemesSelect_phonemesByIDs @arguments(ids: $initiallySelectedIDs)
      }
    `,
    { initiallySelectedIDs },
    {},
  );

  const [searchedData, refetch] = useRefetchableFragment(
    graphql`
      fragment PhonemesSelect_phonemesForString on Query
      @refetchable(queryName: "PhonemesSelect_phonemesForString_RefetchQuery")
      @argumentDefinitions(search: { type: "String", defaultValue: "" }) {
        phonemesForString(search: $search, first: 20) {
          nodes {
            id
            arpabet
            ipa
          }
        }
      }
    `,
    data,
  );

  const refetchSearch = React.useCallback(
    (search: string) => {
      refetch({ search }, { fetchPolicy: 'network-only' });
    },
    [refetch],
  );

  const [selectedData, refetchSelected] = useRefetchableFragment(
    graphql`
      fragment PhonemesSelect_phonemesByIDs on Query
      @refetchable(queryName: "PhonemesSelect_phonemesByIDs_RefetchQuery")
      @argumentDefinitions(ids: { type: "[ID!]", defaultValue: [] }) {
        phonemesByID(ids: $ids) {
          id
          arpabet
          ipa
        }
      }
    `,
    data,
  );

  React.useEffect(() => {
    startTransition(() => {
      refetchSelected({ ids: props.value }, { fetchPolicy: 'network-only' });
    });
  }, [props.value, refetchSelected, startTransition]);

  const sortedSelectedPhonemes = React.useMemo(
    () =>
      [...selectedData.phonemesByID].sort((a: any, b: any) =>
        a.arpabet.localeCompare(b.arpabet),
      ),
    [selectedData.phonemesByID],
  );

  return (
    <>
      <PhonemesSelectField
        {...props}
        phonemes={searchedData.phonemesForString.nodes}
        selectedPhonemes={selectedData.phonemesByID}
        refetchSearch={refetchSearch}
      />

      {!(props.disabled && sortedSelectedPhonemes.length === 0) && (
        <MUI.Box marginTop={1}>
          <MUI.Typography variant="body2" color="textSecondary">
            {sortedSelectedPhonemes.length}{' '}
            {`phoneme${sortedSelectedPhonemes.length === 1 ? '' : 's'}`} (A-Z)
          </MUI.Typography>

          <MUI.Box marginTop={1} style={{ columnWidth: 250, columnGap: 14 }}>
            {sortedSelectedPhonemes?.map((phoneme: any, index: any) => (
              <MUI.Link
                href={`/#/g/phonemes/${phoneme.id}`}
                target="_blank"
                key={index}
              >
                <MUI.Paper
                  key={phoneme.id}
                  elevation={1}
                  style={{
                    breakInside: 'avoid-column',
                    padding: '6px 8px',
                    marginBottom: 8,
                  }}
                >
                  <MUI.Box
                    display="flex"
                    alignItems="flex-start"
                    justifyContent="space-between"
                  >
                    <MUI.Box
                      display="flex"
                      flexDirection="row"
                      gridColumnGap={8}
                    >
                      <MUI.Typography variant="body1" style={{ marginTop: 3 }}>
                        {phoneme.arpabet} | {phoneme.ipa}
                      </MUI.Typography>
                    </MUI.Box>
                    <MUI.IconButton
                      size="small"
                      onClick={(e) => {
                        e.preventDefault();
                        props.onChange(
                          props.value.filter((p: any) => p !== phoneme.id),
                        );
                      }}
                    >
                      <RemoveIcon />
                    </MUI.IconButton>
                  </MUI.Box>
                  <MUI.Typography
                    variant="caption"
                    color="textSecondary"
                    style={{ display: 'block' }}
                  >
                    {phoneme.id}
                  </MUI.Typography>
                </MUI.Paper>
              </MUI.Link>
            ))}
          </MUI.Box>
        </MUI.Box>
      )}
    </>
  );
};

const PhonemesSelectField = ({
  phonemes,
  selectedPhonemes,
  refetchSearch,
  onChange,
  isLoading,
  value,
  disabled,
}: any) => {
  const [_isPending, startTransition] = React.useTransition();

  const phonemesByID = React.useMemo(
    () =>
      Object.fromEntries(
        [...phonemes, ...selectedPhonemes].map((phoneme: any) => [
          phoneme.id,
          phoneme,
        ]),
      ),
    [phonemes, selectedPhonemes],
  );

  const phonemeIDs = React.useMemo(
    () => phonemes.map((phoneme: any) => phoneme.id),
    [phonemes],
  );

  const onInputChange = (e: any, value: any) => {
    startTransition(() => {
      refetchSearch(value);
    });
  };

  return (
    <Autocomplete
      multiple
      autoHighlight
      blurOnSelect={false}
      options={phonemeIDs}
      filterOptions={(x) => x}
      renderTags={() => null}
      closeIcon={null}
      getOptionLabel={(phonemeID: any) =>
        phonemesByID[phonemeID]?.arpabet || 'Invalid Phoneme'
      }
      style={{ width: 600 }}
      debug
      size="small"
      selectOnFocus
      loading={isLoading}
      renderOption={(phonemeID: any) => {
        const phoneme = phonemesByID[phonemeID];
        return (
          <MUI.Box display="flex" flexDirection="column">
            {phoneme.arpabet} | {phoneme.ipa}
            <MUI.Box component="span" color="text.secondary" fontSize={12}>
              {phoneme.id}
            </MUI.Box>
          </MUI.Box>
        );
      }}
      renderInput={(params) => (
        <MUI.TextField
          {...params}
          variant="filled"
          margin="normal"
          label="Search for phonemes (arpabet)"
        />
      )}
      onInputChange={onInputChange}
      onChange={(e, value, reason) => {
        if (reason !== 'remove-option') {
          onChange?.(value);
        }
      }}
      value={value}
      disabled={disabled}
    />
  );
};

export default PhonemesSelect;
