import VideocamIcon from '@material-ui/icons/Videocam';

import VideoList from './VideoList';
import VideoEdit from './VideoEdit';
import VideoCreate from './VideoCreate';

export default {
  list: VideoList,
  edit: VideoEdit,
  icon: VideocamIcon,
  create: VideoCreate,
};
