/**
 * @generated SignedSource<<b33f4a525b693d78d96caf39e596a26f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type QueuesQuery$variables = Record<PropertyKey, never>;
export type QueuesQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"Queues_recentSystemJobs">;
};
export type QueuesQuery = {
  response: QueuesQuery$data;
  variables: QueuesQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "nodeID",
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "id",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "status",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "enqueuedAt",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "startedAt",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "endedAt",
    "storageKey": null
  },
  (v0/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "QueuesQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "Queues_recentSystemJobs"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "QueuesQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "SystemJobQueue",
        "kind": "LinkedField",
        "name": "systemJobQueues",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "active",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "SystemJob",
            "kind": "LinkedField",
            "name": "recentJobs",
            "plural": true,
            "selections": (v1/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "SystemJob",
            "kind": "LinkedField",
            "name": "failedJobs",
            "plural": true,
            "selections": (v1/*: any*/),
            "storageKey": null
          },
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "1c19a78068f3cf8f236b8f944cc03ea5",
    "id": null,
    "metadata": {},
    "name": "QueuesQuery",
    "operationKind": "query",
    "text": "query QueuesQuery {\n  ...Queues_recentSystemJobs\n}\n\nfragment Queues_Queue on SystemJobQueue {\n  name\n  active\n  recentJobs {\n    id\n    status\n    enqueuedAt\n    startedAt\n    endedAt\n    nodeID\n  }\n  failedJobs {\n    id\n    status\n    enqueuedAt\n    startedAt\n    endedAt\n    nodeID\n  }\n}\n\nfragment Queues_recentSystemJobs on Query {\n  systemJobQueues {\n    name\n    ...Queues_Queue\n    nodeID\n  }\n}\n"
  }
};
})();

(node as any).hash = "6a5033e838c5599c089ea42e6b1f7fcc";

export default node;
