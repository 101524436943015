import * as React from 'react';
import { FC } from 'react';
import { Link, FieldProps } from 'react-admin';

import VideoNameField from './VideoNameField';

const VideoLinkField: FC<FieldProps> = (props) =>
  props.record ? (
    <Link to={`/content/video/${props.record.videoId}`}>
      <VideoNameField {...props} />
    </Link>
  ) : null;

VideoLinkField.defaultProps = {
  source: 'videoId',
  label: 'Video',
  addLabel: true,
};

export default VideoLinkField;
