import { Typography } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';
import { Styles } from '@material-ui/styles/withStyles';
import { FC } from 'react';
import { Create, SimpleForm } from 'react-admin';

import MultipleChoicePromptFormFields from './MultipleChoicePromptFormFields';

export const styles: Styles<Theme, any> = {
  name: { display: 'inline-block' },
};

const MultipleChoicePromptCreate: FC = (props: any) => (
  <Create {...props}>
    <SimpleForm>
      <Typography variant="h6" gutterBottom>
        Multiple Choice Prompt
      </Typography>

      <MultipleChoicePromptFormFields />
    </SimpleForm>
  </Create>
);

export default MultipleChoicePromptCreate;
