/**
 * @generated SignedSource<<3e5bfe5e4404f1968c1b2fba155532f5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type VideoVariantSelect_NewVideoVariant_allVideoVariants_Query$variables = Record<PropertyKey, never>;
export type VideoVariantSelect_NewVideoVariant_allVideoVariants_Query$data = {
  readonly allVideoVariants: ReadonlyArray<{
    readonly id: string | null | undefined;
    readonly tags: string | null | undefined;
    readonly title: string | null | undefined;
  }> | null | undefined;
};
export type VideoVariantSelect_NewVideoVariant_allVideoVariants_Query = {
  response: VideoVariantSelect_NewVideoVariant_allVideoVariants_Query$data;
  variables: VideoVariantSelect_NewVideoVariant_allVideoVariants_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "tags",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "VideoVariantSelect_NewVideoVariant_allVideoVariants_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "VideoVariant",
        "kind": "LinkedField",
        "name": "allVideoVariants",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "VideoVariantSelect_NewVideoVariant_allVideoVariants_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "VideoVariant",
        "kind": "LinkedField",
        "name": "allVideoVariants",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "nodeID",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "bcee5f2086ed1a6e607b0c75e505cce4",
    "id": null,
    "metadata": {},
    "name": "VideoVariantSelect_NewVideoVariant_allVideoVariants_Query",
    "operationKind": "query",
    "text": "query VideoVariantSelect_NewVideoVariant_allVideoVariants_Query {\n  allVideoVariants {\n    id\n    title\n    tags\n    nodeID\n  }\n}\n"
  }
};
})();

(node as any).hash = "7a76b271703ddedfa11ce381e02716f4";

export default node;
