// @ts-nocheck
import React, { useState } from 'react';
import { useInput, useNotify } from 'react-admin';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useForm } from 'react-final-form';
import styled from 'styled-components';

function dateRangeOverlaps(a_start, a_end, b_start, b_end) {
  if (a_start < b_start && b_start < a_end) return true; // b starts in a
  if (a_start < b_end && b_end < a_end) return true; // b ends in a
  if (b_start < a_start && a_end < b_end) return true; // a in b
  return false;
}

function multipleDateRangeOverlaps(timeEntries) {
  let i = 0;
  let j = 0;
  const timeIntervals = Array.from(Object.values(timeEntries)).filter(
    (entry) => entry.from != null && entry.to != null,
  );

  if (timeIntervals != null && timeIntervals.length > 1)
    for (i = 0; i < timeIntervals.length - 1; i += 1) {
      for (j = i + 1; j < timeIntervals.length; j += 1) {
        if (
          dateRangeOverlaps(
            timeIntervals[i].isInMs
              ? timeIntervals[i].from
              : timeIntervals[i].from * 1000,
            timeIntervals[i].isInMs
              ? timeIntervals[i].to
              : timeIntervals[i].to * 1000,
            timeIntervals[j].isInMs
              ? timeIntervals[j].from
              : timeIntervals[j].from * 1000,
            timeIntervals[j].isInMs
              ? timeIntervals[j].to
              : timeIntervals[j].to * 1000,
          )
        )
          return true;
      }
    }
  return false;
}

const VideoPracticeInput = (props: any) => {
  const { choices, label } = props;
  const [fromToValues, setFromToValues] = React.useState([]);
  const [isOverlap, setIsOverlap] = React.useState(false);
  const {
    input: { onChange, value: values },
  } = useInput(props);
  const notify = useNotify();
  const { change, getState } = useForm();
  const { initialValues } = getState();

  const children = React.cloneElement(props.children, props);
  const selectedChoices = (values || [])
    .map((v: any) => choices.find((c: any) => c.id === v))
    .filter(identity);

  // initial set up of fromToValues from initialValues.practice_items
  React.useEffect(() => {
    const newFromToValues = [];
    const { practice_items } = initialValues;

    if (values?.length) {
      values.forEach((value, valueIndex) => {
        newFromToValues[valueIndex] = {
          from:
            (practice_items?.[valueIndex]?.VideoPractice?.isInMs
              ? practice_items?.[valueIndex]?.VideoPractice?.from
              : practice_items?.[valueIndex]?.VideoPractice?.from * 1000) ?? 0,
          to:
            (practice_items?.[valueIndex]?.VideoPractice?.isInMs
              ? practice_items?.[valueIndex]?.VideoPractice?.to
              : practice_items?.[valueIndex]?.VideoPractice?.to * 1000) ?? 1000,
          isInMs: true,
        };
      });
    }

    setFromToValues(newFromToValues);
  }, []);
  // updates fromToValues and associations
  React.useEffect(() => {
    const practice_items = [];
    if (values?.length) {
      values.forEach((value, valueIndex) => {
        const newPracticeItem = {
          id: value,
          index: valueIndex,
          VideoPractice: {
            practiceId: value,
            from: fromToValues[valueIndex]?.from ?? 0,
            to: fromToValues[valueIndex]?.to ?? 1000,
            isInMs: fromToValues[valueIndex]?.isInMs ?? true,
          },
        };

        practice_items.push(newPracticeItem);
      });
    }

    change('practice_items', practice_items);
  }, [values, fromToValues]);

  const setNewFromToValue = (type, itemIndex, newValue) => {
    const otherType = type === 'from' ? 'to' : 'from';
    const otherTypeValue = fromToValues[itemIndex]?.[otherType];
    if (type === 'from' && newValue * 1000 >= otherTypeValue) {
      notify(
        'From value must be smaller than To value for the same practice item!',
      );
    } else if (type === 'to' && newValue * 1000 <= otherTypeValue) {
      notify(
        'To value must be greater than From value for the same practice item!',
      );
    }

    if (
      initialValues?.duration &&
      Number.isInteger(initialValues?.duration) &&
      newValue > initialValues.duration
    ) {
      notify(
        `${type} value must be less than the duration of the video! (which is ${initialValues.duration} seconds)`,
      );
    }

    const newFromToValues = [...fromToValues];
    newFromToValues[itemIndex] = {
      from:
        type === 'from' ? newValue * 1000 : fromToValues[itemIndex]?.from || 0,
      to: type === 'to' ? newValue * 1000 : fromToValues[itemIndex]?.to || 1000,
      isInMs: fromToValues[itemIndex]?.isInMs ?? true,
    };

    const isAnOverlap = multipleDateRangeOverlaps(newFromToValues);
    if (isAnOverlap) {
      setIsOverlap(true);
    } else {
      setIsOverlap(false);
    }

    setFromToValues(newFromToValues);
  };

  const onDragEnd = (result: any) => {
    const { destination, source } = result;

    if (!destination || destination.index === source.index) return;

    const newValues = Array.from(values);

    const splicedValue = newValues.splice(source.index, 1);
    newValues.splice(destination.index, 0, splicedValue?.[0]);

    const existingFromToValues = [...fromToValues];

    const fromToSplicedValue = existingFromToValues.splice(source.index, 1);
    existingFromToValues.splice(destination.index, 0, fromToSplicedValue?.[0]);

    setFromToValues(existingFromToValues);
    onChange(newValues);
  };

  return (
    <>
      {children}
      {selectedChoices.length ? (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="index">
            {(provided, snapshot) => (
              <List
                component="nav"
                ref={provided.innerRef}
                isDraggingOver={snapshot.isDraggingOver}
                {...provided.droppableProps}
              >
                <Title>{label} - Timestamps During Video Playback</Title>
                {selectedChoices.map((v, i) => (
                  <ChoiceItem
                    setNewFromToValue={setNewFromToValue}
                    fromToValues={fromToValues}
                    value={v}
                    index={i}
                    key={i.toString()}
                  />
                ))}
                <ErrorItem>
                  {isOverlap &&
                    "The time ranges provided overlap, this will cause problems. Please don't do that."}
                </ErrorItem>
                {provided.placeholder}
              </List>
            )}
          </Droppable>
        </DragDropContext>
      ) : null}
    </>
  );
};

const timeStringToSeconds = (timeString: string) => {
  if (timeString.includes(':')) {
    const timeParts = timeString.split(':');

    if (timeParts.length !== 2) {
      return "Invalid time format. Please use the format 'minutes:seconds'.";
    }

    const minutes = parseInt(timeParts[0], 10);

    const hasMilliseconds = timeParts[1].split('.').length > 1;
    const seconds = hasMilliseconds
      ? parseFloat(timeParts[1])
      : parseInt(timeParts[1], 10);

    if (Number.isNaN(minutes) || Number.isNaN(seconds)) {
      return 'Invalid input. Please provide valid numbers for minutes and seconds.';
    }

    return minutes * 60 + seconds;
  }
  // if its just '30', should be interpreted as 30 seconds
  const seconds = parseInt(timeString, 10);

  if (Number.isNaN(seconds)) {
    return 'Invalid input. Please provide a valid number for seconds.';
  }

  return seconds;
};

const secondsToTimeString = (inputNumber: number, isInMs: boolean) => {
  const seconds = isInMs ? inputNumber / 1000 : inputNumber;
  const minutes = Math.floor(seconds / 60);
  let remainingSeconds = Math.round((seconds * 1000) % (60 * 1000)) / 1000;
  // pad seconds with 0 if only one digit
  if (remainingSeconds < 10) {
    remainingSeconds = `0${remainingSeconds}`;
  }

  return `${minutes}:${remainingSeconds}`;
};

const ChoiceItem = (props) => {
  const { value, index, fromToValues, setNewFromToValue } = props;
  const [errorFrom, setErrorFrom] = useState();
  const [errorTo, setErrorTo] = useState();
  const notify = useNotify();

  if (fromToValues[index] === undefined) return null;

  return (
    <Draggable isDragDisabled draggableId={index.toString()} index={index}>
      {(provided, snapshot) => (
        <ListItem
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          isDragging={snapshot.isDragging}
        >
          <FlexRow>
            <Item flex={1}>
              #{index + 1} -{' '}
              <a href={`/#/content/practice/${value.id}`} target="_blank">
                <b>{value.word}</b>
              </a>
            </Item>
            <Item flex={1}>
              From:{' '}
              <input
                style={{
                  ...(errorFrom ? { borderWidth: 3, borderColor: 'red' } : {}),
                }}
                type="text"
                onBlur={(evt) => {
                  setErrorFrom(null);
                  const result = timeStringToSeconds(evt.target.value);
                  if (typeof result === 'string') {
                    notify(result, 'error');
                    setErrorFrom(result);
                  } else {
                    setNewFromToValue('from', index, result);
                  }
                }}
                defaultValue={secondsToTimeString(
                  fromToValues[index]?.from,
                  fromToValues[index]?.isInMs,
                )}
              />{' '}
              <span
                style={{
                  color: '#00000088',
                  fontSize: 12,
                }}
              >
                {fromToValues[index]?.isInMs
                  ? fromToValues[index]?.from / 1000
                  : fromToValues[index]?.from}{' '}
                seconds
              </span>
            </Item>
            <Item flex={1}>
              To:{' '}
              <input
                style={{
                  ...(errorTo ? { borderWidth: 3, borderColor: 'red' } : {}),
                }}
                type="text"
                onBlur={(evt) => {
                  setErrorTo(null);
                  const result = timeStringToSeconds(evt.target.value);
                  if (typeof result === 'string') {
                    notify(result, 'error');
                    setErrorTo(result);
                  } else {
                    setNewFromToValue('to', index, result);
                  }
                }}
                defaultValue={secondsToTimeString(
                  fromToValues[index]?.to,
                  fromToValues[index]?.isInMs,
                )}
              />{' '}
              <span
                style={{
                  color: '#00000088',
                  fontSize: 12,
                }}
              >
                {fromToValues[index]?.isInMs
                  ? fromToValues[index]?.to / 1000
                  : fromToValues[index]?.to}{' '}
                seconds
              </span>
            </Item>
          </FlexRow>
        </ListItem>
      )}
    </Draggable>
  );
};

export default VideoPracticeInput;

const ErrorItem = styled.div`
  margin: 0px 8px;
  color: red;
  font-weight: bold;
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
`;

const Item = styled.div`
  ${(p) => p.flex && `flex: ${p.flex};`}
`;

const ListItem = styled.div<StyledDivProps>`
  padding: 8px;
  margin: 8px;
  border: 1px solid grey;
  background-color: ${(props) => (props.isDragging ? 'lightgrey' : 'white')};
  border-radius: 16px;
`;

const List = styled.div<StyledDivProps>`
  padding: 8px;
  border: 1px solid grey;
  background-color: ${(props) => (props.isDraggingOver ? '#2097F3' : 'white')};
`;

const Title = styled.div`
  font-weight: bold;
  font-size: 1.2rem;
  padding: 8px;
`;

interface StyledDivProps {
  isDragging?: boolean;
  isDraggingOver?: boolean;
}

const identity = (v: any) => v;
