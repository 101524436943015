import * as React from 'react';
import { FC } from 'react';
import { Link, FieldProps } from 'react-admin';

import PhonemeNameField from './PhonemeNameField';

const PhonemeLinkField: FC<FieldProps> = (props) => {
  const phonemes = props.record?.phonemes;

  return phonemes?.length
    ? phonemes.map((phoneme: any) => (
        <Link key={phoneme.id} to={`/content/phoneme/${phoneme.id}`}>
          <PhonemeNameField {...props} phoneme={phoneme} />
        </Link>
      ))
    : null;
};

PhonemeLinkField.defaultProps = {
  label: 'Phoneme(s)',
  addLabel: true,
};

export default PhonemeLinkField;
