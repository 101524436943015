/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import TableCreationForm from './TableCreationForm';
import { Button, Typography } from '@material-ui/core';
import api from '../../../api';
import { BooleanInput, NumberInput, useRefresh } from 'react-admin';
import { Form } from 'react-final-form';

export default function TableEditForm({ record }: { record: any }) {
  const { headers, data } = record;
  const refresh = useRefresh();
  const remappedHeaders = headers.map((header: any) => ({
    ...header,
    key: header.title,
  }));
  const remappedData = data.map((row: any) => {
    const newRow: any = {};
    headers.forEach((header: any, headerIdx: number) => {
      newRow[header.title] = row[headerIdx].text;
    });
    return newRow;
  });

  const handleSave = async (saveData: any) => {
    const {
      id,
      videoId,
      createdAt,
      updatedAt,
      graphicType,
      ...rest
    } = saveData;

    try {
      const response = await api.patchVideoTableGraphics(
        saveData.videoId,
        saveData.id,
        rest,
      );

      if (response.status < 300) {
        refresh();
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  const handleDelete = async () => {
    const response = window.confirm('Are you sure you want to do that?');

    if (response) {
      try {
        await api.deleteVideoTableGraphics(record?.videoId, record.id);
        refresh();
      } catch (error) {
        console.log('error', error);
      }
    }
  };
  // console.log('headers', remappedHeaders);
  // console.log('data', remappedData);

  return (
    <>
      <Typography variant="h3">Graphic Table</Typography>
      <Form
        initialValues={record}
        onSubmit={handleSave}
        render={({ handleSubmit }: any) => (
          <form
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
            onSubmit={handleSubmit}
          >
            <div
              style={{
                minWidth: 300,
                marginRight: 10,
              }}
            >
              <NumberInput
                label="From"
                source="from"
                style={{ marginRight: 12 }}
              />
              <NumberInput label="To" source="to" />
              <BooleanInput label="Is In Ms" source="isInMs" />
            </div>

            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  onClick={handleSubmit}
                >
                  Save
                </Button>
              </div>
              {record && typeof record.id !== 'undefined' && (
                <Button
                  style={{
                    backgroundColor: 'red',
                    color: 'white',
                  }}
                  onClick={handleDelete}
                >
                  Delete
                </Button>
              )}
            </div>
          </form>
        )}
      />

      <TableCreationForm
        initialHeaders={remappedHeaders}
        initialRows={remappedData}
      />
      <p style={{ color: 'gray' }}>
        Table not editable once created, can do if there is demand
      </p>
    </>
  );
}
