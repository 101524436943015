/**
 * @generated SignedSource<<1d9640f78f1083b09f6548a91b428d39>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UserSelect_userByID$data = {
  readonly userByID: {
    readonly email: string | null | undefined;
    readonly firstName: string | null | undefined;
    readonly id: string | null | undefined;
    readonly language: string | null | undefined;
    readonly lastName: string | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "UserSelect_userByID";
};
export type UserSelect_userByID$key = {
  readonly " $data"?: UserSelect_userByID$data;
  readonly " $fragmentSpreads": FragmentRefs<"UserSelect_userByID">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "id"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [],
      "operation": require('./UserSelect_userByID_RefetchQuery.graphql')
    }
  },
  "name": "UserSelect_userByID",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "id",
          "variableName": "id"
        }
      ],
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "userByID",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "email",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "firstName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "lastName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "language",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "d66ec88fed224c2f75bde2f2c06d1697";

export default node;
