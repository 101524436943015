import React, { useState } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import WhatshotIcon from '@material-ui/icons/Whatshot';
import ViewDayIcon from '@material-ui/icons/ViewDay';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import api from '../../api';

const reorder = (list: any[], startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export default function PracticeableList({
  practiceSet,
  reloadPracticeSet,
}: any) {
  const [internalItems, setInternalItems] = useState<any>([]);

  const removePracticableFromPracticeSet = React.useCallback(
    async (practiceSetContentToRemove: string) => {
      try {
        await api.deletePracticeSetContent(
          practiceSet.id,
          practiceSetContentToRemove,
        );

        reloadPracticeSet();
      } catch (error) {
        console.log('error deleting practice set', error);
      }
    },
    [practiceSet],
  );

  const getContentIdentifier = React.useCallback((item: any) => {
    try {
      switch (item.contentType) {
        case 'Practice':
          return item.content.word;
        case 'MultipleChoice':
          return item.content.prompt;
        case 'FillInTheBlank':
          return item.content.prompt;
        default:
          return 'unknown';
      }
    } catch (error) {
      return 'error';
    }
  }, []);

  const getIconForItem = React.useCallback((contentType: string) => {
    switch (contentType) {
      case 'Practice':
        return <ViewDayIcon />;
        break;
      case 'MultipleChoice':
        return <WhatshotIcon />;

      default:
        return '';
        break;
    }
  }, []);

  const getItemStyle = React.useCallback(
    (isDragging: boolean, draggableStyle: any) => ({
      // some basic styles to make the items look a bit nicer
      display: 'flex',
      userSelect: 'none',
      padding: '.35rem 0',
      margin: `0 0 ${10}px 0`,

      // styles we need to apply on draggables
      ...draggableStyle,
    }),
    [],
  );

  const onDragEnd = React.useCallback(
    async (result: any) => {
      const { destination, source } = result;
      if (!destination || destination.index === source.index) return;
      try {
        const ordered = reorder(
          internalItems,
          result.source.index,
          result.destination.index,
        );
        setInternalItems(ordered);
        await api.reorderPracticeSetContent(
          practiceSet.id,
          ordered.map((i: any) => i.id),
        );
      } catch (error) {
        console.log('error reordering practice set', error);
      }
    },
    [internalItems, practiceSet],
  );

  React.useEffect(() => {
    const sortedItems = [...(practiceSet?.items || [])].sort(
      (a: any, b: any) => a.order - b.order,
    );
    setInternalItems(sortedItems);
  }, [practiceSet?.items]);

  return (
    <div>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="dropabble">
          {(providedRoot) => (
            <div {...providedRoot.droppableProps} ref={providedRoot.innerRef}>
              <div>
                <h3>Content items</h3>
              </div>
              {internalItems.map((item: any, idx: number) => (
                <Draggable key={item.id} draggableId={item.id} index={idx}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style,
                      )}
                    >
                      <div style={{ display: 'flex', flex: 1 }}>
                        <div
                          style={{
                            flex: 1,
                            display: 'flex',
                            padding: '10px 10px',
                            background: snapshot.isDragging
                              ? '#F77E51'
                              : 'rgb(200 212 210)',
                            transition: 'background 0.4s ease',
                          }}
                        >
                          <div
                            style={{
                              padding: '0 10px',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              fontSize: '1.5rem',
                            }}
                          >
                            #{idx + 1}
                          </div>
                          <div
                            style={{
                              padding: '0 15px',
                              display: 'flex',
                              alignItems: 'center',
                              flex: 1,
                            }}
                          >
                            {getIconForItem(item.contentType)}
                            <div style={{ marginLeft: '5px' }} />
                            {item.contentType}
                          </div>
                          <div
                            style={{
                              display: 'flex',
                              padding: '10px 15px',
                              alignItems: 'center',
                              flex: 3,
                            }}
                          >
                            <a
                              href={`/#/content/${
                                item.contentType === 'Practice'
                                  ? `practice`
                                  : `multiplechoice`
                              }/${item.contentId}`}
                              target="_blank"
                            >
                              {getContentIdentifier(item)}
                            </a>
                          </div>
                          <div
                            style={{
                              display: 'flex',
                              padding: '10px 15px',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}
                          ></div>
                        </div>
                        <div
                          style={{
                            flex: 1,
                            padding: '10px 15px',
                            alignItems: 'center',
                            display: 'flex',
                            cursor: 'pointer',
                          }}
                        >
                          <CloseIcon
                            onClick={() => {
                              removePracticableFromPracticeSet(item.id);
                            }}
                            style={{
                              height: '30px',
                              width: '30px',
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </Draggable>
              ))}
              {providedRoot.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
}
