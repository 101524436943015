/**
 * @generated SignedSource<<ce5cfe618c15c56960aa1e59abd07a37>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type DLPPlayground_params_Query$variables = Record<PropertyKey, never>;
export type DLPPlayground_params_Query$data = {
  readonly playgroundGenParams: any | null | undefined;
};
export type DLPPlayground_params_Query = {
  response: DLPPlayground_params_Query$data;
  variables: DLPPlayground_params_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "playgroundGenParams",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "DLPPlayground_params_Query",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "DLPPlayground_params_Query",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "a0e232a2bbe624ffd7d3c8df3667d428",
    "id": null,
    "metadata": {},
    "name": "DLPPlayground_params_Query",
    "operationKind": "query",
    "text": "query DLPPlayground_params_Query {\n  playgroundGenParams\n}\n"
  }
};
})();

(node as any).hash = "52c03c5a274dba8c265ff32491d9a5a6";

export default node;
