import * as React from 'react';
import { FC } from 'react';
import {
  Datagrid,
  List,
  ListProps,
  FunctionField,
  BooleanField,
  Link,
  FieldProps,
  Filter,
  TextInput,
  BooleanInput,
} from 'react-admin';
import { ReactElement } from 'react';
import UserLinkField from '../common/UserLinkField';
import {
  getReferralCodeNumClicks,
  getReferralCodeNumSignUps,
  getReferralOwedSum,
  getReferralTotalEarnings,
} from '../common/utils';

const ReferralLinkField: FC<FieldProps> = (props) =>
  props.record ? (
    <Link to={`/logs/referral_codes/${props.record.id}`}>
      {props.record.slug}
    </Link>
  ) : null;

ReferralLinkField.defaultProps = {
  label: 'Referral Code',
};

const ReferralCodeFilter = (props: any) => (
  <Filter {...props}>
    <TextInput label="Referral Code" source="slug" defaultValue="" />
    <TextInput label="Owner Id" source="owner.id" defaultValue="" />
    <TextInput label="Owner Email" source="owner.email" defaultValue="" />
    <BooleanInput label="Affiliate?" source="isAffiliate" defaultValue="" />
    <BooleanInput
      label="At least one redemption?"
      source="atLeastOneRedemption"
      defaultValue=""
    />
    <BooleanInput label="Owned > $0" source="ownedMoney" />
  </Filter>
);

const ReferralCodeList = (props: ListProps): ReactElement => (
  <List
    {...props}
    sort={{ field: 'createdAt', order: 'DESC' }}
    perPage={25}
    filters={<ReferralCodeFilter />}
  >
    <Datagrid optimized>
      <ReferralLinkField />
      <UserLinkField label="Owner" source="owner" />
      <BooleanField label="Affiliate?" source="isAffiliate" />

      <FunctionField
        render={(record: any) => getReferralCodeNumClicks(record)}
        label="Num Clicks"
      />
      <FunctionField
        render={(record: any) => getReferralCodeNumSignUps(record)}
        label="Num Sign ups"
      />
      <FunctionField
        render={(record: any) => getReferralOwedSum(record)}
        label="Owed"
      />
      <FunctionField
        render={(record: any) => getReferralTotalEarnings(record)}
        label="Total Earnings"
      />
    </Datagrid>
  </List>
);

export default ReferralCodeList;
