import * as React from 'react';
import { FC } from 'react';
import {
  Create,
  CreateProps,
  SimpleForm,
  TextInput,
  required,
  SelectInput,
} from 'react-admin';

import { makeStyles, Theme } from '@material-ui/core/styles';
import { Styles } from '@material-ui/styles/withStyles';
import { Box } from '@material-ui/core';

export const styles: Styles<Theme, any> = {
  name: { display: 'flex', flex: 2, marginRight: '1em' },
  url: { display: 'flex', flex: 1 },
  flex: { display: 'flex' },
};

const useStyles = makeStyles(styles);

const VideoCreate: FC<CreateProps> = (props) => {
  const classes = useStyles(props);

  return (
    <Create {...props}>
      <SimpleForm autoComplete="off">
        <Box display="flex" style={{ width: '100%' }}>
          <TextInput
            autoFocus
            source="name"
            label="Video Name"
            className={classes.name}
            validate={requiredValidate}
          />

          <SelectInput
            labelId="select_hosting_provider_label"
            id="select_hosting_provider"
            source="hosting_provider"
            defaultValue="vimeo"
            style={{ minWidth: 240, marginLeft: '1rem' }}
            validate={requiredValidate}
            choices={[
              { id: 'vimeo', name: 'vimeo' },
              { id: 'muse', name: 'muse' },
            ]}
          />
        </Box>
        <Box>
          <TextInput
            source="url"
            label="Vimeo Video ID"
            className={classes.url}
          />
          <div style={{ marginBottom: 20 }}>-- OR --</div>
          <TextInput
            source="muse_url"
            label="Muse HLS m3u8 URL"
            className={classes.url}
          />
        </Box>
      </SimpleForm>
    </Create>
  );
};

const requiredValidate = [required()];

export default VideoCreate;
