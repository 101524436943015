/**
 * @generated SignedSource<<6e000492e17ba43b7869910d987fd01a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type SkillLessonType = "CONVERSATION" | "FOCUS_WORD" | "IDIOM" | "LESSON" | "LINKED_SKILL_LESSON" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type SkillLessonsEditor_skillLesson$data = {
  readonly active: boolean | null | undefined;
  readonly conversation: {
    readonly id: string | null | undefined;
    readonly name: string | null | undefined;
  } | null | undefined;
  readonly id: string | null | undefined;
  readonly linkedSkillLesson: {
    readonly conversation: {
      readonly name: string | null | undefined;
    } | null | undefined;
    readonly id: string | null | undefined;
    readonly numberPracticeSets: number | null | undefined;
    readonly practiceTexts: ReadonlyArray<{
      readonly aiCoachSample: string | null | undefined;
      readonly coachSample: string | null | undefined;
      readonly id: string | null | undefined;
      readonly isSentence: boolean | null | undefined;
      readonly text: string | null | undefined;
    }> | null | undefined;
    readonly video: {
      readonly title: string | null | undefined;
    } | null | undefined;
    readonly " $fragmentSpreads": FragmentRefs<"SkillLessonsEditor_skillLesson">;
  } | null | undefined;
  readonly numberPracticeSets: number | null | undefined;
  readonly practiceTexts: ReadonlyArray<{
    readonly aiCoachSample: string | null | undefined;
    readonly coachSample: string | null | undefined;
    readonly id: string | null | undefined;
    readonly isSentence: boolean | null | undefined;
    readonly text: string | null | undefined;
  }> | null | undefined;
  readonly type: SkillLessonType | null | undefined;
  readonly video: {
    readonly id: string | null | undefined;
    readonly title: string | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "SkillLessonsEditor_skillLesson";
};
export type SkillLessonsEditor_skillLesson$key = {
  readonly " $data"?: SkillLessonsEditor_skillLesson$data;
  readonly " $fragmentSpreads": FragmentRefs<"SkillLessonsEditor_skillLesson">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "numberPracticeSets",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v4 = {
  "alias": "practiceTexts",
  "args": null,
  "concreteType": "PracticeText",
  "kind": "LinkedField",
  "name": "allPracticeTexts",
  "plural": true,
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "text",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "coachSample",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "aiCoachSample",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isSentence",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": true,
      "kind": "LocalArgument",
      "name": "loadLinkedSkillLesson"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "SkillLessonsEditor_skillLesson",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "active",
      "storageKey": null
    },
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Conversation",
      "kind": "LinkedField",
      "name": "conversation",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v2/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Video",
      "kind": "LinkedField",
      "name": "video",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v3/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SkillLesson",
      "kind": "LinkedField",
      "name": "linkedSkillLesson",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "Video",
          "kind": "LinkedField",
          "name": "video",
          "plural": false,
          "selections": [
            (v3/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Conversation",
          "kind": "LinkedField",
          "name": "conversation",
          "plural": false,
          "selections": [
            (v2/*: any*/)
          ],
          "storageKey": null
        },
        (v1/*: any*/),
        (v4/*: any*/),
        {
          "condition": "loadLinkedSkillLesson",
          "kind": "Condition",
          "passingValue": true,
          "selections": [
            {
              "args": [
                {
                  "kind": "Literal",
                  "name": "loadLinkedSkillLesson",
                  "value": false
                }
              ],
              "kind": "FragmentSpread",
              "name": "SkillLessonsEditor_skillLesson"
            }
          ]
        }
      ],
      "storageKey": null
    },
    (v4/*: any*/)
  ],
  "type": "SkillLesson",
  "abstractKey": null
};
})();

(node as any).hash = "c4acc4be89b46f4743e573906a94ea5f";

export default node;
