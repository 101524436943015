import React, { Dispatch, SetStateAction, useCallback, useState } from 'react';

import styled from 'styled-components';

import {
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
} from '@material-ui/core';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import moment from 'moment';

export const dateSelectionOptions = [
  {
    id: 'custom',
    name: 'Custom Range',
  },
  {
    id: 'today',
    name: 'Today',
    fromDate: moment().format('YYYY-MM-DD'),
    toDate: moment().format('YYYY-MM-DD'),
  },
  {
    id: 'yesterday',
    name: 'Yesterday',
    fromDate: moment().subtract(1, 'day').format('YYYY-MM-DD'),
    toDate: moment().subtract(1, 'day').format('YYYY-MM-DD'),
  },
  {
    id: 'week',
    name: 'Last 7 Days',
    fromDate: moment().subtract(7, 'd').format('YYYY-MM-DD'),
    toDate: moment().format('YYYY-MM-DD'),
  },
  {
    id: 'current_calendar_week',
    name: 'Current calendar week',
    fromDate: moment().startOf('week').format('YYYY-MM-DD'),
    toDate: moment().endOf('week').format('YYYY-MM-DD'),
  },
  {
    id: 'last_week',
    name: 'Previous 7 Days',
    fromDate: moment().subtract(15, 'd').format('YYYY-MM-DD'),
    toDate: moment().subtract(8, 'd').format('YYYY-MM-DD'),
  },
  {
    id: 'last_month',
    name: 'Last 30 days',
    fromDate: moment().subtract(30, 'd').format('YYYY-MM-DD'),
    toDate: moment().format('YYYY-MM-DD'),
  },
  {
    id: 'previous_month',
    name: 'Previous 30 days',
    fromDate: moment().subtract(61, 'd').format('YYYY-MM-DD'),
    toDate: moment().subtract(31, 'd').format('YYYY-MM-DD'),
  },
  {
    id: 'current_calendar_month',
    name: 'Current calendar month',
    fromDate: moment().startOf('month').format('YYYY-MM-DD'),
    toDate: moment().endOf('month').format('YYYY-MM-DD'),
  },
  {
    id: 'last_calendar_month',
    name: 'Last calendar month',
    fromDate: moment()
      .startOf('month')
      .subtract(1, 'month')
      .format('YYYY-MM-DD'),
    toDate: moment().endOf('month').subtract(1, 'month').format('YYYY-MM-DD'),
  },
  {
    id: 'last_three_months',
    name: 'Last three months',
    fromDate: moment().subtract(3, 'month').format('YYYY-MM-DD'),
    toDate: moment().format('YYYY-MM-DD'),
  },
  {
    id: 'last_six_months',
    name: 'Last six months',
    fromDate: moment().subtract(6, 'month').format('YYYY-MM-DD'),
    toDate: moment().format('YYYY-MM-DD'),
  },
  {
    id: 'last_year',
    name: 'Last year',
    fromDate: moment().subtract(1, 'year').format('YYYY-MM-DD'),
    toDate: moment().format('YYYY-MM-DD'),
  },
];

export const previousTimePeriod = (
  startDate: any,
  endDate: any,
  setStartDate: any,
  setEndDate: any,
  setDateRangeId: any,
) => {
  const currentStartDate = startDate;
  const currentEndDate = endDate;

  const isFirstAndLastDaysOfMonth =
    moment(startDate).startOf('month').isSame(moment(startDate), 'date') &&
    moment(endDate).endOf('month').isSame(moment(endDate), 'date');

  const differenceBetweenEndAndStart = isFirstAndLastDaysOfMonth
    ? 1
    : Math.round(
        moment
          .duration(moment(currentEndDate).diff(moment(currentStartDate)))
          .as('days') + 1,
      );
  const newStartDate = moment(currentStartDate)
    .subtract(
      differenceBetweenEndAndStart,
      isFirstAndLastDaysOfMonth ? 'month' : 'days',
    )
    .startOf(isFirstAndLastDaysOfMonth ? 'month' : 'day')
    .format('YYYY-MM-DD');
  const newEndDate = moment(currentEndDate)
    .subtract(
      differenceBetweenEndAndStart,
      isFirstAndLastDaysOfMonth ? 'month' : 'days',
    )
    .endOf(isFirstAndLastDaysOfMonth ? 'month' : 'day')
    .format('YYYY-MM-DD');

  setStartDate(newStartDate);
  setEndDate(newEndDate);
  setDateRangeId('custom');
};

export const nextTimePeriod = (
  startDate: string,
  endDate: string,
  setStartDate: Dispatch<SetStateAction<string | undefined>>,
  setEndDate: Dispatch<SetStateAction<string | undefined>>,
  setDateRangeId: Dispatch<SetStateAction<string>>,
) => {
  const currentStartDate = startDate;
  const currentEndDate = endDate;

  const isFirstAndLastDaysOfMonth =
    moment(startDate).startOf('month').isSame(moment(startDate), 'date') &&
    moment(endDate).endOf('month').isSame(moment(endDate), 'date');

  const differenceBetweenEndAndStart = isFirstAndLastDaysOfMonth
    ? 1
    : Math.round(
        moment
          .duration(moment(currentEndDate).diff(moment(currentStartDate)))
          .as('days') + 1,
      );
  const newStartDate = moment(currentStartDate)
    .add(
      differenceBetweenEndAndStart,
      isFirstAndLastDaysOfMonth ? 'month' : 'days',
    )
    .startOf(isFirstAndLastDaysOfMonth ? 'month' : 'day')
    .format('YYYY-MM-DD');
  const newEndDate = moment(currentEndDate)
    .add(
      differenceBetweenEndAndStart,
      isFirstAndLastDaysOfMonth ? 'month' : 'days',
    )
    .endOf(isFirstAndLastDaysOfMonth ? 'month' : 'day')
    .format('YYYY-MM-DD');

  setStartDate(newStartDate);
  setEndDate(newEndDate);
  setDateRangeId('custom');
};

export default function DateScrubberFilter({
  startDate,
  endDate,
  setStartDate,
  setEndDate,
}: {
  startDate: string;
  endDate: string;
  setStartDate: Dispatch<SetStateAction<string | undefined>>;
  setEndDate: Dispatch<SetStateAction<string | undefined>>;
}) {
  const [dateRangeId, setDateRangeId] = useState('week');

  const handleSelectChange = useCallback((event) => {
    const newChosenDateRange = dateSelectionOptions.find(
      (item: any) => item.id === event.target.value,
    );
    if (newChosenDateRange) {
      setDateRangeId(newChosenDateRange.id);
      setStartDate(newChosenDateRange.fromDate);
      setEndDate(newChosenDateRange.toDate);
    }
  }, []);

  const handleStartDateChange = useCallback((event) => {
    const newDate = event.target.value;
    if (newDate) {
      setStartDate(newDate);
      setDateRangeId('custom');
    }
  }, []);

  const handleEndDateChange = useCallback((event) => {
    const newDate = event.target.value;
    if (newDate) {
      setEndDate(newDate);
      setDateRangeId('custom');
    }
  }, []);

  const previousTimePeriodFn = useCallback(
    () =>
      previousTimePeriod(
        startDate,
        endDate,
        setStartDate,
        setEndDate,
        setDateRangeId,
      ),
    [startDate, endDate, setStartDate, setEndDate, setDateRangeId],
  );

  const nextTimePeriodFn = useCallback(
    () =>
      nextTimePeriod(
        startDate,
        endDate,
        setStartDate,
        setEndDate,
        setDateRangeId,
      ),
    [startDate, endDate, setStartDate, setEndDate, setDateRangeId],
  );

  return (
    <DateScrubberContainer>
      <StyledFormControl>
        <InputLabel id="date_range_selection_label">Date range</InputLabel>
        <Select
          labelId="date_range_selection_label"
          id="date_range_selection"
          value={dateRangeId}
          onChange={handleSelectChange}
          defaultValue="last_month"
          style={{ minWidth: 140 }}
        >
          {dateSelectionOptions
            .filter((i: any) => dateRangeId === 'custom' || i.id !== 'custom')
            .map((option: any) => (
              <MenuItem key={option.id} value={option.id}>
                {option.name}
              </MenuItem>
            ))}
        </Select>
      </StyledFormControl>
      <div style={{ display: 'flex', flexDirection: 'row', marginRight: 10 }}>
        <Button onClick={previousTimePeriodFn}>
          <KeyboardArrowLeft fontSize="large" />
        </Button>
        <Button onClick={nextTimePeriodFn}>
          <KeyboardArrowRight fontSize="large" />
        </Button>
      </div>
      <TextField
        id="startDate"
        label="Start Date"
        type="date"
        value={startDate}
        InputLabelProps={{
          shrink: true,
        }}
        onChange={handleStartDateChange}
        style={{ marginRight: 10 }}
      />
      <TextField
        id="endDate"
        label="End Date"
        type="date"
        value={endDate}
        InputLabelProps={{
          shrink: true,
        }}
        onChange={handleEndDateChange}
        style={{ marginRight: 10 }}
      />
    </DateScrubberContainer>
  );
}

const DateScrubberContainer = styled.div({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
});

const StyledFormControl = styled(FormControl)({
  marginRight: 5,
});
