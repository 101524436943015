/**
 * @generated SignedSource<<19ba1a017437cf734be260511ae87e49>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type WordEdit_Pronunciation$data = {
  readonly active: boolean | null | undefined;
  readonly bvIPA: string | null | undefined;
  readonly bvIPAUnsyllabified: string | null | undefined;
  readonly default: boolean | null | undefined;
  readonly form: string | null | undefined;
  readonly id: string | null | undefined;
  readonly sourceEdges: ReadonlyArray<{
    readonly id: string | null | undefined;
    readonly pronunciationSource: {
      readonly name: string | null | undefined;
    } | null | undefined;
    readonly " $fragmentSpreads": FragmentRefs<"WordEdit_SourceEdge">;
  }> | null | undefined;
  readonly " $fragmentType": "WordEdit_Pronunciation";
};
export type WordEdit_Pronunciation$key = {
  readonly " $data"?: WordEdit_Pronunciation$data;
  readonly " $fragmentSpreads": FragmentRefs<"WordEdit_Pronunciation">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "WordEdit_Pronunciation",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "bvIPAUnsyllabified",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "bvIPA",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "active",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "default",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "form",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PronunciationPronunciationSource",
      "kind": "LinkedField",
      "name": "sourceEdges",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "PronunciationSources",
          "kind": "LinkedField",
          "name": "pronunciationSource",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "WordEdit_SourceEdge"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Pronunciation",
  "abstractKey": null
};
})();

(node as any).hash = "6267c7c037f59f069f93b9963e954c43";

export default node;
