import DescriptionIcon from '@material-ui/icons/Description';

import UnitList from './UnitList';
import UnitEdit from './UnitEdit';
import UnitCreate from './UnitCreate';

export default {
  list: UnitList,
  edit: UnitEdit,
  icon: DescriptionIcon,
  create: UnitCreate,
};
