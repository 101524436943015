// @ts-nocheck

import React from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import styled from 'styled-components';
import { useInput } from 'react-admin';
import { useForm } from 'react-final-form';

export const speakerList = [
  // {
  //   id: 'none',
  //   value: null,
  //   name: 'Unassigned',
  // },
  {
    id: 'other',
    value: 'other',
    name: 'other',
  },
  {
    id: 'user',
    value: 'user',
    name: 'user',
  },
];

const ConversationPracticeInput = (props: any) => {
  const { id, choices, label } = props;
  const [speakerValues, setSpeakerValues] = React.useState({});
  const {
    input: { onChange, value: values },
  } = useInput(props);
  const { change, getState } = useForm();
  const { initialValues } = getState();
  const children = React.cloneElement(props.children, props);
  const selectedChoices = (values || [])
    .map((v: any) => choices.find((c: any) => c.id === v))
    .filter(identity);

  // initial set up of fromToValues from initialValues.items
  React.useEffect(() => {
    const newSpeakerValues = {};
    const { items } = initialValues;
    if (values?.length) {
      values.forEach((value) => {
        newSpeakerValues[value] = {
          whoSpeaks:
            items?.find((i) => i.id === value)?.ConversationPractice
              ?.whoSpeaks || 'other',
        };
      });
    }
    setSpeakerValues(newSpeakerValues);
  }, [values]);

  // updates fromToValues and associations
  React.useEffect(() => {
    const practice_items = [];
    if (values?.length) {
      values.forEach((value) => {
        const newPracticeItem = {
          id: value,
          ConversationPractice: {
            practiceId: value,
            whoSpeaks: speakerValues[value]?.whoSpeaks,
          },
        };
        practice_items.push(newPracticeItem);
      });
    }
    change('items', practice_items);
  }, [values, speakerValues]);

  const setNewSpeakerValue = (itemId, newValue) => {
    const newSpeakerValues = {
      ...speakerValues,
      [itemId]: {
        whoSpeaks: newValue === 'other' ? 'other' : newValue,
      },
    };
    setSpeakerValues(newSpeakerValues);
  };

  const onDragEnd = (result: any) => {
    const { destination, source, draggableId } = result;
    if (!destination || destination.index === source.index) return;
    const newValues = Array.from(values);
    newValues.splice(source.index, 1);
    newValues.splice(destination.index, 0, draggableId);
    onChange(newValues);
  };

  return (
    <>
      {children}
      {selectedChoices.length ? (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId={id}>
            {(provided, snapshot) => (
              <List
                component="nav"
                ref={provided.innerRef}
                isDraggingOver={snapshot.isDraggingOver}
                {...provided.droppableProps}
              >
                <Title>{label}</Title>
                {selectedChoices.map((v, i) => (
                  <ChoiceItem
                    setNewSpeakerValue={setNewSpeakerValue}
                    speakerValues={speakerValues}
                    value={v}
                    index={i}
                    key={v.id}
                  />
                ))}
                {provided.placeholder}
              </List>
            )}
          </Droppable>
        </DragDropContext>
      ) : null}
    </>
  );
};

const ChoiceItem = (props) => {
  const { value, index, speakerValues, setNewSpeakerValue } = props;
  return (
    <Draggable draggableId={value.id} index={index}>
      {(provided, snapshot) => (
        <ListItem
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          isDragging={snapshot.isDragging}
        >
          <FlexRow>
            <Item>
              #{index + 1} -{' '}
              <a href={`/#/content/practice/${value.id}`} target="_blank">
                <b>{value.word}</b>
              </a>
            </Item>
            {!value?.isInstruction && (
              <Item flex={1} style={{ marginLeft: 10 }}>
                whoSpeaks:{' '}
                <select
                  onChange={(evt) =>
                    setNewSpeakerValue(value.id, evt.target.value)
                  }
                  value={speakerValues[value.id]?.whoSpeaks}
                >
                  {speakerList.map((i) => (
                    <option value={i.value}>{i.name}</option>
                  ))}
                </select>
              </Item>
            )}
          </FlexRow>
        </ListItem>
      )}
    </Draggable>
  );
};

export default ConversationPracticeInput;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
`;

const Item = styled.div`
  ${(p) => p.flex && `flex: ${p.flex};`}
`;

const ListItem = styled.div<StyledDivProps>`
  padding: 8px;
  margin: 8px;
  border: 1px solid grey;
  background-color: ${(props) => (props.isDragging ? 'lightgrey' : 'white')};
  border-radius: 16px;
`;

const List = styled.div<StyledDivProps>`
  padding: 8px;
  border: 1px solid grey;
  background-color: ${(props) => (props.isDraggingOver ? '#2097F3' : 'white')};
`;

const Title = styled.div`
  font-weight: bold;
  font-size: 1.2rem;
  padding: 8px;
`;

interface StyledDivProps {
  isDragging?: boolean;
  isDraggingOver?: boolean;
}

const identity = (v: any) => v;
