import React, { useState, useEffect, useMemo } from 'react';
import moment from 'moment';

import { CREATED_AT_TYPES } from '../../engagement/EngagementDashboard';
import api from '../../api';
import { LineChart, Line, XAxis, YAxis } from 'recharts';
import { subscriptionOptions } from '../../users/UserList';

const validDataKeysForTooltip = [
  'trialStartsNum', // the og key
  'trialCancelledFromTrialStartNum', // trials cancelled of the ones started on same day
  'trialConvertedToPaidNum', // trials converted from trials started on same day
  'trialRefundedNum', // etc
  'trialFailedPaymentNum', // etc
  'trialPendingNum', // etc
];

export default function DashboardToolTip(props: any) {
  const {
    name,
    payload,
    label,
    weekly,
    engagementType,
    createdAtType,
    detailType,
    showAsPercentOfTotal,
  } = props;

  const [getTrialUnsubDataPending, setGetTrialUnsubDataPending] = useState(
    false,
  );
  const [unsubscribeData, setUnsubscribeData] = useState<any>({}); // <date label, data>

  let hasChart: boolean;
  if (
    payload &&
    payload.length > 0 &&
    validDataKeysForTooltip.includes(payload[0].dataKey)
  ) {
    // trial start tooltip
    hasChart = true;
  } else {
    hasChart = false;
  }

  useEffect(() => {
    (async () => {
      if (hasChart && !(label in unsubscribeData)) {
        try {
          setGetTrialUnsubDataPending(true);
          const serverReadableLabel = moment(label, 'YYYY-MM-DD').format(
            'MM-DD-YYYY',
          );
          const result = await api.getSubscriptionCancellationRatesForTrialsStartedOnDay(
            serverReadableLabel,
          );
          if (result.status === 200) {
            const nextSevenDays = Object.keys(result.json.nextSevenDays)
              .map((day) => ({
                date: moment(day, 'MM-DD-YYYY'),
                count: result.json.nextSevenDays[day],
                name: day,
              }))
              .sort((a, b) => a.date.valueOf() - b.date.valueOf());
            setUnsubscribeData({
              ...unsubscribeData,
              [label]: nextSevenDays,
            });
          }
          setGetTrialUnsubDataPending(false);
        } catch (error) {
          console.log('error getting trial unsubscribe data', error);
          setGetTrialUnsubDataPending(false);
        }
      }
    })();
  }, [label, hasChart]);

  const renderTitleData = () => {
    if (label && weekly) {
      const year = label.substring(0, 4);
      const week = label.substring(4, 6);
      const weekMoment = moment().set({
        year,
        week,
      });
      return `Wk${week};${year} (${weekMoment
        .startOf('week')
        .format('M/D')}-${weekMoment.endOf('week').format('M/D')})`;
    }
    return moment(label).format('MMM D, YYYY');
  };

  const breakdownData = useMemo(() => {
    if (!payload || payload.length === 0) {
      return {};
    }
    const value = payload[0].payload;
    const { dataKey } = payload[0];

    if (validDataKeysForTooltip.includes(dataKey)) {
      const { trialStartsData } = value;
      if (!trialStartsData) {
        return {};
      }

      const breakdownTrialsByProduct = trialStartsData.reduce(
        (acc: any, item: any) => {
          // @ts-ignore please
          const normalNameOfProduct = subscriptionOptions[item.key];
          if (!acc?.[item.key]) {
            acc[item.key] = { num: 1, name: normalNameOfProduct };
            return acc;
          }
          acc[item.key].num += 1;
          return acc;
        },
        {},
      );
      const breakdownTrialsByProductString = Object.keys(
        breakdownTrialsByProduct,
      ).map((i) => [
        `${breakdownTrialsByProduct[i].name}`,
        `${breakdownTrialsByProduct[i].num}`,
      ]);

      const breakdownConvertedByProduct = trialStartsData
        .filter((i: any) => i.period_type === 'normal' && !i.refunded_at)
        .reduce((acc: any, item: any) => {
          // @ts-ignore please
          const normalNameOfProduct = subscriptionOptions[item.key];
          if (!acc?.[item.key]) {
            acc[item.key] = { num: 1, name: normalNameOfProduct };
            return acc;
          }
          acc[item.key].num += 1;
          return acc;
        }, {});

      const breakdownConvertedByProductString = Object.keys(
        breakdownConvertedByProduct,
      ).map((i) => [
        `${breakdownConvertedByProduct[i].name}`,
        `${breakdownConvertedByProduct[i].num}`,
      ]);

      return {
        breakdownTrialsByProductString,
        breakdownConvertedByProductString,
      };
    }
    return {};
  }, [payload]);

  const displayData = useMemo(() => {
    if (!payload || payload.length === 0) {
      return '';
    }
    const value = payload[0].payload;
    const { dataKey } = payload[0];

    if (validDataKeysForTooltip.includes(dataKey)) {
      const { trialStartsData } = value;
      const trialStartNum = value?.trialStartsNum;
      if (!trialStartsData) {
        return ['', ''];
      }

      const cancelledTrialsInThisSet = trialStartsData.filter(
        (i: any) =>
          (i.period_type === 'trial' &&
            i.unsubscribe_detected_at &&
            !i.billing_issues_detected_at) ||
          (i.store === 'no-paywall' &&
            moment(i.original_purchase_date)
              .add(7, 'days')
              .isSameOrBefore(moment())),
      )?.length;

      const newFailedPayments = trialStartsData.filter(
        (i: any) => i.period_type === 'trial' && i.billing_issues_detected_at,
      )?.length;

      const convertedTrialsInThisSet = trialStartsData.filter(
        (i: any) => i.period_type === 'normal' && !i.refunded_at,
      )?.length;

      const refundedInThisSet = trialStartsData.filter(
        (i: any) => i.period_type === 'normal' && i.refunded_at,
      )?.length;

      const percentConverted = trialStartNum
        ? 100 -
          Math.round(
            ((cancelledTrialsInThisSet +
              newFailedPayments +
              refundedInThisSet) /
              trialStartNum) *
              100,
          )
        : 0;
      return [
        [`Start`, `Cancel`, `Conv`, `Refund`, `Failed`, `✅%`],
        [
          `${trialStartNum}`,
          `${cancelledTrialsInThisSet}`,
          `${convertedTrialsInThisSet}`,
          `${refundedInThisSet}`,
          `${newFailedPayments}`,
          `${percentConverted}%`,
        ],
      ];
    }
    if (engagementType && createdAtType !== CREATED_AT_TYPES.BOTH) {
      return [
        name?.includes('CreatedSamePeriod')
          ? createdAtType === CREATED_AT_TYPES.STACKED
            ? `${payload[dataKey]} \n${value}`
            : `${value}`
          : value,
        name?.includes('CreatedSamePeriod')
          ? createdAtType === CREATED_AT_TYPES.STACKED
            ? `Total \nCreated Same Period `
            : `Created Same Period `
          : `Not Created Same Period `,
      ];
    }

    return [
      `${showAsPercentOfTotal ? `Percent: \nNumber` : `Number`}:${
        engagementType || weekly ? ` \nTotal: ` : ``
      }`,
      `${
        showAsPercentOfTotal
          ? `${value[dataKey]}% \n${payload[`${detailType}Num`]}`
          : value[dataKey]
      }${engagementType || weekly ? ` \n${value.totalUsers}` : ``}`,
    ];
  }, [payload, engagementType, createdAtType]);

  const chartData: any = unsubscribeData?.[label] || [];

  return (
    <div
      style={{
        backgroundColor: 'white',
        padding: 10,
        marginTop: 150,
        zIndex: 20,
        border: '1px gray solid',
      }}
    >
      <div
        style={{
          display: hasChart ? 'flex' : 'block',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <div style={{ minWidth: 100, marginLeft: hasChart ? 20 : 0 }}>
          <div>{renderTitleData()}</div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <span>
              {Array.isArray(displayData[0])
                ? displayData[0].map((item: any) => <div>{item}</div>)
                : displayData[0]}
            </span>
            <span>
              {Array.isArray(displayData[1])
                ? displayData[1].map((item: any) => <div>{item}</div>)
                : displayData[1]}
            </span>
          </div>
        </div>
        {hasChart && (
          <div
            style={{
              minWidth: 400,
              minHeight: 160,
              display: 'flex',
              justifyContent: 'center',
              padding: '0 1.5rem',
              backgroundColor: 'white',
            }}
          >
            <div style={{ margin: '0 20px' }}>
              {getTrialUnsubDataPending ? (
                <div style={{ height: 211 }} />
              ) : (
                <>
                  <p
                    style={{
                      textAlign: 'center',
                      fontSize: '1.2rem',
                      flex: 1,
                    }}
                  >
                    Unsubscribe counts by day
                  </p>
                  <LineChart width={300} height={150} data={chartData}>
                    <XAxis
                      dataKey="name"
                      name="Date"
                      tickFormatter={(date) =>
                        moment(date, 'MM-DD-YYYY').format('MMM DD')
                      }
                    />

                    <YAxis name="Cancel count" />
                    <Line type="monotone" dataKey="count" stroke="#8884d8" />
                  </LineChart>
                </>
              )}
            </div>
          </div>
        )}
      </div>
      {validDataKeysForTooltip.includes(payload?.[0]?.dataKey) && (
        <>
          <div style={{ whiteSpace: 'pre' }}>
            Trial Breakdown: {`\n`}
            {breakdownData.breakdownTrialsByProductString?.length ? (
              [...breakdownData.breakdownTrialsByProductString]
                .sort((a, b) =>
                  parseInt(a[1], 10) > parseInt(b[1], 10) ? -1 : 1,
                )
                .map((item) => (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      maxWidth: 300,
                    }}
                  >
                    <span>{item[0]}</span>
                    <span>{item[1]}</span>
                  </div>
                ))
            ) : (
              <div>None</div>
            )}
          </div>
          <div style={{ whiteSpace: 'pre', marginTop: 10 }}>
            Converted Breakdown: {`\n`}
            {breakdownData.breakdownConvertedByProductString?.length ? (
              [...breakdownData.breakdownConvertedByProductString]
                .sort((a, b) =>
                  parseInt(a[1], 10) > parseInt(b[1], 10) ? -1 : 1,
                )
                .map((item) => {
                  const associatedTrialStartNum = breakdownData?.breakdownTrialsByProductString?.find(
                    (i) => i[0] === item[0],
                  )?.[1];
                  const convPercent = Math.round(
                    (parseInt(item[1], 10) /
                      parseInt(associatedTrialStartNum ?? '1', 10) ?? 1) * 100,
                  );

                  return (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        maxWidth: 300,
                      }}
                    >
                      <span>{item[0]}</span>
                      <span>
                        {item[1]} ({convPercent}%)
                      </span>
                    </div>
                  );
                })
            ) : (
              <div>None</div>
            )}
          </div>
        </>
      )}
    </div>
  );
}
