import { AuthProvider } from 'react-admin';
import api from './api';

const authProvider: AuthProvider = {
  login: ({ username, password }) =>
    api
      .loginAdmin({ email: username, password })
      .then((result) => {
        const { json } = result;
        if (json.errors || json.message) {
          throw new Error(json.message);
        }

        const { accessToken, refreshToken } = json?.tokens;
        api.setUserToken(accessToken, refreshToken);
        localStorage.setItem('token', accessToken);
        localStorage.setItem('refresh_token', refreshToken);

        return Promise.resolve();
      })
      .catch((error) => Promise.reject(error)),
  logout: () =>
    api
      .logout({ refreshToken: api.refreshToken })
      .then(() => {
        localStorage.removeItem('token');
        localStorage.removeItem('refresh_token');
        api.setUserToken(null, null);
        return Promise.resolve();
      })
      .catch((error) => Promise.reject(error)),
  checkError: () => Promise.resolve(),
  checkAuth: () =>
    localStorage.getItem('token') ? Promise.resolve() : Promise.reject(),
  getIdentity: () =>
    api
      .profile()
      .then((result) => {
        const { json } = result;
        if (json.errors || json.message) {
          throw new Error(json.message);
        }

        const newUser = {
          ...result,
          fullName: `${json.firstName} ${json.lastName}`,
        };

        return Promise.resolve(newUser);
      })
      .catch((error) => Promise.reject(error)),
  getPermissions: () => Promise.reject(Error('Unknown method')),
};

export default authProvider;
