/**
 * @generated SignedSource<<e47d7db6b408754d167375a1d2a518c2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SkillsTextScan_result$data = {
  readonly scanTextForSkills: ReadonlyArray<{
    readonly skill: {
      readonly id: string | null | undefined;
    } | null | undefined;
    readonly " $fragmentSpreads": FragmentRefs<"SkillsTextScan_SkillMatch">;
  }> | null | undefined;
  readonly " $fragmentType": "SkillsTextScan_result";
};
export type SkillsTextScan_result$key = {
  readonly " $data"?: SkillsTextScan_result$data;
  readonly " $fragmentSpreads": FragmentRefs<"SkillsTextScan_result">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": "",
      "kind": "LocalArgument",
      "name": "text"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [],
      "operation": require('./SkillsTextScanRefetchQuery.graphql')
    }
  },
  "name": "SkillsTextScan_result",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "text",
          "variableName": "text"
        }
      ],
      "concreteType": "SkillsTextScanMatch",
      "kind": "LinkedField",
      "name": "scanTextForSkills",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Skill",
          "kind": "LinkedField",
          "name": "skill",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "id",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SkillsTextScan_SkillMatch"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "f67d5fc5dec78c13647c8fb7a9ab7a11";

export default node;
