import * as React from 'react';
import { FC, memo } from 'react';
import { FieldProps } from 'react-admin';

interface Props extends FieldProps {
  PracticeSet?: any;
}

const PracticeSetNameField: FC<Props> = ({ record }) => {
  const name = record?.PracticeSet?.name;

  return record && name ? <div>{name}</div> : null;
};

PracticeSetNameField.defaultProps = {
  source: 'name',
  label: 'Name',
};

export default memo<Props>(PracticeSetNameField);
