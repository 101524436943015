import * as React from 'react';
import { FC } from 'react';
import { Link, FieldProps } from 'react-admin';

import PhonemeNameField from './PhonemeNameField';

const PhonemeLinkField: FC<FieldProps> = (props) => {
  const phonemeIds = props.record?.phonemeId;

  return phonemeIds?.length
    ? phonemeIds.map((phonemeId: any) => (
        <Link key={phonemeId} to={`/content/phoneme/${phonemeId}`}>
          <PhonemeNameField {...props} phonemeId={phonemeId} />
        </Link>
      ))
    : null;
};

PhonemeLinkField.defaultProps = {
  label: 'Phoneme(s)',
  addLabel: true,
};

export default PhonemeLinkField;
