import * as MUI from '@material-ui/core';
import { Autocomplete, createFilterOptions } from '@material-ui/lab';
import graphql from 'babel-plugin-relay/macro';
import _ from 'lodash';
import React from 'react';
import { useLazyLoadQuery, useRefetchableFragment } from 'react-relay';

const UserSelect = (props: any) => (
  <React.Suspense
    fallback={<UserSelectField {...props} isLoading users={[]} />}
  >
    <UserSelectWithData {...props} />
  </React.Suspense>
);

const UserSelectWithData = (props: any) => {
  const [isPending, startTransition] = React.useTransition();

  const initiallySelectedID = React.useMemo(() => props.value ?? '', []);

  const data: any = useLazyLoadQuery(
    graphql`
      query UserSelect_NewUser_allUsers_Query($initiallySelectedID: ID) {
        ...UserSelect_allUsers
        ...UserSelect_userByID @arguments(id: $initiallySelectedID)
      }
    `,
    { initiallySelectedID },
    {},
  );

  const [searchedData, refetch] = useRefetchableFragment(
    graphql`
      fragment UserSelect_allUsers on Query
      @refetchable(queryName: "UserSelect_allUsers_RefetchQuery")
      @argumentDefinitions(searchText: { type: "String", defaultValue: "" }) {
        allUsers(searchText: $searchText) {
          nodes {
            id
            email
            firstName
            lastName
            language
          }
        }
      }
    `,
    data,
  );

  const refetchSearch = React.useCallback(
    _.debounce((searchText: string) => {
      if (searchText === '') return;
      startTransition(() => {
        refetch({ searchText }, { fetchPolicy: 'network-only' });
      });
    }, 250),
    [refetch],
  );

  const [selectedData, refetchSelected] = useRefetchableFragment(
    graphql`
      fragment UserSelect_userByID on Query
      @refetchable(queryName: "UserSelect_userByID_RefetchQuery")
      @argumentDefinitions(id: { type: "ID" }) {
        userByID(id: $id) {
          id
          email
          firstName
          lastName
          language
        }
      }
    `,
    data,
  );

  React.useEffect(() => {
    startTransition(() => {
      refetchSelected({ id: props.value }, { fetchPolicy: 'network-only' });
    });
  }, [props.value, refetchSelected, startTransition]);

  return (
    <UserSelectField
      {...props}
      users={searchedData.allUsers.nodes}
      selectedUser={selectedData.userByID}
      refetchSearch={refetchSearch}
      isLoading={isPending}
    />
  );
};

const UserSelectField = ({
  users,
  selectedUser,
  refetchSearch,
  onChange,
  isLoading,
  value,
}: any) => {
  const usersByID = React.useMemo(
    () =>
      Object.fromEntries(
        [...users, ...(selectedUser ? [selectedUser] : [])]?.map(
          (user: any) => [user.id, user],
        ),
      ),
    [users, selectedUser],
  );

  const userIDs = React.useMemo(
    () => users?.map((user: any) => user.id),
    [users],
  );

  const filterOptions = React.useMemo(
    () =>
      createFilterOptions({
        stringify: (userID: any) =>
          `${usersByID[userID].email} ${usersByID[userID].id}`,
      }),
    [usersByID],
  );

  return (
    <Autocomplete
      options={userIDs}
      filterOptions={filterOptions}
      getOptionLabel={(userID: any) =>
        usersByID[userID]
          ? `${usersByID[userID]?.firstName}${usersByID[userID]?.lastName ? ` ${usersByID[userID]?.lastName}` : ``} (${usersByID[userID]?.email ?? 'No email'})`
          : ''
      }
      style={{ maxWidth: 600 }}
      size="small"
      disableClearable
      blurOnSelect
      onInputChange={(event, searchText, reason) => {
        if (reason !== 'reset') {
          refetchSearch(searchText);
        }
      }}
      selectOnFocus
      loading={isLoading}
      // disabled={isLoading}
      renderOption={(userId: any) => {
        const user = usersByID[userId];
        return (
          <MUI.Box display="flex" flexDirection="column">
            {user.firstName}
            {user.lastName ? ` ${user.lastName}` : ``} ({user.email})
            <MUI.Box component="span" color="text.secondary" fontSize={12}>
              {user.id} — {user.language}
            </MUI.Box>
          </MUI.Box>
        );
      }}
      renderInput={(params) => (
        <MUI.TextField
          {...params}
          label="Search for Email or UUID"
          variant="filled"
          margin="none"
          InputLabelProps={{ shrink: value ? true : undefined }}
        />
      )}
      onChange={(e, value) => {
        if (value) {
          onChange?.(value);
        }
      }}
      value={value}
    />
  );
};

export default UserSelect;
