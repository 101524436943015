import SchoolIcon from '@material-ui/icons/School';

import LessonList from './LessonList';
import LessonEdit from './LessonEdit';
import LessonCreate from './LessonCreate';

export default {
  list: LessonList,
  edit: LessonEdit,
  icon: SchoolIcon,
  create: LessonCreate,
};
