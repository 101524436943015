import { Box, Button, Input, Typography, colors } from '@material-ui/core';
import { MagnifyingGlass } from '@phosphor-icons/react';
import moment from 'moment';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import DateScrubberFilter from '../dashboard/DateScrubberFilter';
import AdDataTable from './AdDataTable';
import AdCard from './MockAdCard';

export default function AdsTrackingPlayground() {
  const [tableSearchText, setTableSearchText] = useState('');
  const [startDate, setStartDate] = useState<any>(
    moment()
      .subtract(moment().utcOffset(), 'minutes')
      .subtract(7, 'd')
      .format('YYYY-MM-DD'),
  );
  const [endDate, setEndDate] = useState<any>(
    moment().subtract(moment().utcOffset(), 'minutes').format('YYYY-MM-DD'),
  );

  const [shouldUseDefaultColumnOrder, setShouldUseDefaultColumnOrder] =
    useState(false);

  const [adCardsVisisble, setAdCardsVisisble] = useState(false);

  const sidebarOpen = useSelector((state: any) => state.admin.ui.sidebarOpen);

  return (
    <Box
      paddingTop=".75rem"
      style={{
        maxWidth: `calc(100vw - ${sidebarOpen ? '260px' : '70px'})`,
      }}
    >
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <Box display="flex" flexDirection="row" gridColumnGap="1rem">
          <DateScrubberFilter
            startDate={startDate}
            endDate={endDate}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
          />
          <Input
            startAdornment={
              <Box
                alignItems="center"
                justifyContent="center"
                marginRight=".5rem"
              >
                <MagnifyingGlass size={24} color={colors.grey['600']} />
              </Box>
            }
            placeholder="Search"
            value={tableSearchText}
            onChange={(e) => setTableSearchText(e.target.value)}
          />
        </Box>
        <Button
          variant="text"
          onClick={() => setShouldUseDefaultColumnOrder(true)}
        >
          <Typography variant="button">Reset</Typography>
        </Button>
      </Box>
      <AdDataTable
        startDate={startDate}
        endDate={endDate}
        shouldUseDefaultColumnOrder={shouldUseDefaultColumnOrder}
        globalFilter={tableSearchText}
      />
      <Box marginTop="1.5rem">
        <Box display="flex" gridColumnGap="1rem" marginBottom="1rem">
          <Typography variant="h5">Generate ad links</Typography>
          <Button
            variant="outlined"
            onClick={() => setAdCardsVisisble(!adCardsVisisble)}
          >
            <Typography variant="button">
              {adCardsVisisble ? 'Hide' : 'Show'}
            </Typography>
          </Button>
        </Box>
        {adCardsVisisble ? (
          <Box display="flex" gridColumnGap="1rem">
            <AdCard variant="ig" />
            <AdCard variant="adwords" />
          </Box>
        ) : null}
      </Box>
    </Box>
  );
}
