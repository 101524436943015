import { RelayEnvironmentProvider } from 'react-relay';
import { Environment, Network, RecordSource, Store } from 'relay-runtime';

import api, { apiURL } from './api';

/**
 * Custom fetch function to handle GraphQL requests for a Relay environment.
 *
 * This function is responsible for sending GraphQL requests over the network and returning
 * the response data. It can be customized to integrate with different network libraries or
 * to add authentication headers as needed.
 *
 * @param {RequestParameters} params - The GraphQL request parameters to send to the server.
 * @param {Variables} variables - Variables used in the GraphQL query.
 */
async function fetchFunction(params, variables) {
  if (api.isTokenExpired()) {
    const refreshTokenJSON = await (await api.refreshTokenFromServer()).json();

    if (refreshTokenJSON.tokens) {
      api.setUserToken(
        refreshTokenJSON.tokens.accessToken,
        refreshTokenJSON.tokens.refreshToken,
      );
    } else {
      api.logOut();
      throw new Error('Refresh token expired');
    }
  }

  return fetch(`${apiURL}/api/v2/admin/graphql`, {
    method: 'POST',
    headers: [
      ['Content-Type', 'application/json'],
      ['Authorization', `Bearer ${localStorage.getItem('token')}`],
    ],
    body: JSON.stringify({
      query: params.text,
      variables,
    }),
  }).then((response) => response.json());
}

/**
 * Creates a new Relay environment instance for managing
 * (fetching, storing) GraphQL data.
 */
function createEnvironment() {
  const network = Network.create(fetchFunction);
  const store = new Store(new RecordSource());
  return new Environment({ store, network, getDataID: (node) => node.nodeID });
}

export const relayEnvironement = createEnvironment();

export function AppRelayEnvironmentProvider({ children }) {
  return (
    <RelayEnvironmentProvider environment={relayEnvironement}>
      {children}
    </RelayEnvironmentProvider>
  );
}
