import * as MUI from '@material-ui/core';
import RemoveIcon from '@material-ui/icons/Remove';
import { Autocomplete } from '@material-ui/lab';
import graphql from 'babel-plugin-relay/macro';
import React from 'react';
import { useLazyLoadQuery, useRefetchableFragment } from 'react-relay';

const SkillsSelect = (props: any) => (
  <React.Suspense
    fallback={
      <SkillsSelectField {...props} isLoading selectedSkills={[]} skills={[]} />
    }
  >
    <SkillsSelectWithData {...props} />
  </React.Suspense>
);

const SkillsSelectWithData = (props: any) => {
  const [_isPending, startTransition] = React.useTransition();

  const initiallySelectedIDs = React.useMemo(() => props.value ?? [], []);

  const data: any = useLazyLoadQuery(
    graphql`
      query SupersedingSkillsSelect_Query($initiallySelectedIDs: [ID!]!) {
        ...SupersedingSkillsSelect_skillsForString
        ...SupersedingSkillsSelect_skillsByIDs
          @arguments(ids: $initiallySelectedIDs)
      }
    `,
    { initiallySelectedIDs },
    {},
  );

  const [searchedData, refetch] = useRefetchableFragment(
    graphql`
      fragment SupersedingSkillsSelect_skillsForString on Query
      @refetchable(queryName: "SkillsSelect_skillsForString_RefetchQuery")
      @argumentDefinitions(search: { type: "String", defaultValue: "" }) {
        skillsForString(search: $search, first: 20) {
          nodes {
            id
            name
          }
        }
      }
    `,
    data,
  );

  const refetchSearch = React.useCallback(
    (search: string) => {
      refetch({ search }, { fetchPolicy: 'network-only' });
    },
    [refetch],
  );

  const [selectedData, refetchSelected] = useRefetchableFragment(
    graphql`
      fragment SupersedingSkillsSelect_skillsByIDs on Query
      @refetchable(queryName: "SkillsSelect_skillsByIDs_RefetchQuery")
      @argumentDefinitions(ids: { type: "[ID!]", defaultValue: [] }) {
        skillsByID(ids: $ids) {
          id
          name
        }
      }
    `,
    data,
  );

  React.useEffect(() => {
    startTransition(() => {
      refetchSelected({ ids: props.value }, { fetchPolicy: 'network-only' });
    });
  }, [props.value, refetchSelected, startTransition]);

  const sortedSelectedSkills = React.useMemo(
    () =>
      [...selectedData.skillsByID].sort((a: any, b: any) =>
        a.name.localeCompare(b.name),
      ),
    [selectedData.skillsByID],
  );

  return (
    <>
      <SkillsSelectField
        {...props}
        skills={searchedData.skillsForString.nodes}
        selectedSkills={selectedData.skillsByID}
        refetchSearch={refetchSearch}
      />

      {!(props.disabled && sortedSelectedSkills.length === 0) && (
        <MUI.Box marginTop={1}>
          <MUI.Typography variant="body2" color="textSecondary">
            {sortedSelectedSkills.length}{' '}
            {`skill${sortedSelectedSkills.length === 1 ? '' : 's'}`} (A-Z)
          </MUI.Typography>

          <MUI.Box marginTop={1} style={{ columnWidth: 250, columnGap: 14 }}>
            {sortedSelectedSkills?.map((skill: any, index: any) => (
              <MUI.Link
                href={`/#/g/skills/${skill.id}`}
                target="_blank"
                key={index}
              >
                <MUI.Paper
                  key={skill.id}
                  elevation={1}
                  style={{
                    breakInside: 'avoid-column',
                    padding: '6px 8px',
                    marginBottom: 8,
                  }}
                >
                  <MUI.Box
                    display="flex"
                    alignItems="flex-start"
                    justifyContent="space-between"
                  >
                    <MUI.Box
                      display="flex"
                      flexDirection="row"
                      gridColumnGap={8}
                    >
                      <MUI.Typography variant="body1" style={{ marginTop: 3 }}>
                        {skill.name}
                      </MUI.Typography>
                    </MUI.Box>
                    <MUI.IconButton
                      size="small"
                      onClick={(e) => {
                        e.preventDefault();
                        props.onChange(
                          props.value.filter((p: any) => p !== skill.id),
                        );
                      }}
                    >
                      <RemoveIcon />
                    </MUI.IconButton>
                  </MUI.Box>
                  <MUI.Typography
                    variant="caption"
                    color="textSecondary"
                    style={{ display: 'block' }}
                  >
                    {skill.id}
                  </MUI.Typography>
                </MUI.Paper>
              </MUI.Link>
            ))}
          </MUI.Box>
        </MUI.Box>
      )}
    </>
  );
};

const SkillsSelectField = ({
  skills,
  selectedSkills,
  refetchSearch,
  onChange,
  isLoading,
  value,
  disabled,
}: any) => {
  const [_isPending, startTransition] = React.useTransition();

  const skillsByID = React.useMemo(
    () =>
      Object.fromEntries(
        [...skills, ...selectedSkills].map((skill: any) => [skill.id, skill]),
      ),
    [skills, selectedSkills],
  );

  const skillIDs = React.useMemo(
    () => skills.map((skill: any) => skill.id),
    [skills],
  );

  const onInputChange = (e: any, value: any) => {
    startTransition(() => {
      refetchSearch(value);
    });
  };

  return (
    <Autocomplete
      multiple
      autoHighlight
      blurOnSelect={false}
      options={skillIDs}
      filterOptions={(x) => x}
      renderTags={() => null}
      closeIcon={null}
      getOptionLabel={(skillID: any) =>
        skillsByID[skillID]?.name || 'Invalid Skill'
      }
      style={{ width: 600 }}
      debug
      size="small"
      selectOnFocus
      loading={isLoading}
      renderOption={(skillID: any) => {
        const skill = skillsByID[skillID];
        return (
          <MUI.Box display="flex" flexDirection="column">
            {skill.name}
            <MUI.Box component="span" color="text.secondary" fontSize={12}>
              {skill.id}
            </MUI.Box>
          </MUI.Box>
        );
      }}
      renderInput={(params) => (
        <MUI.TextField
          {...params}
          variant="filled"
          margin="normal"
          label="Search for skills"
        />
      )}
      onInputChange={onInputChange}
      onChange={(e, value, reason) => {
        if (reason !== 'remove-option') {
          onChange?.(value);
        }
      }}
      value={value}
      disabled={disabled}
    />
  );
};

export default SkillsSelect;
