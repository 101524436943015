/**
 * @generated SignedSource<<d1881626d88c5913a1412ff021a8a4c6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SkillPathsDisplay_Query$variables = Record<PropertyKey, never>;
export type SkillPathsDisplay_Query$data = {
  readonly raAllSkills: ReadonlyArray<{
    readonly active: boolean | null | undefined;
    readonly activeSkillLessonsNumber: number | null | undefined;
    readonly categories: any | null | undefined;
    readonly id: string | null | undefined;
    readonly name: string | null | undefined;
    readonly skillLessonsNumber: number | null | undefined;
    readonly " $fragmentSpreads": FragmentRefs<"SkillPathsDisplay_skill">;
  }> | null | undefined;
};
export type SkillPathsDisplay_Query = {
  response: SkillPathsDisplay_Query$data;
  variables: SkillPathsDisplay_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "Literal",
    "name": "perPage",
    "value": 1000
  },
  {
    "kind": "Literal",
    "name": "sortField",
    "value": "name"
  },
  {
    "kind": "Literal",
    "name": "sortOrder",
    "value": "asc"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "categories",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "active",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "skillLessonsNumber",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "activeSkillLessonsNumber",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "introductory",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "nodeID",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "concreteType": "Conversation",
  "kind": "LinkedField",
  "name": "conversation",
  "plural": false,
  "selections": [
    (v1/*: any*/),
    (v2/*: any*/),
    (v10/*: any*/)
  ],
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "numberPracticeSets",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "practiceTextsNumber",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "SkillPathsDisplay_Query",
    "selections": [
      {
        "alias": null,
        "args": (v0/*: any*/),
        "concreteType": "Skill",
        "kind": "LinkedField",
        "name": "raAllSkills",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "SkillPathsDisplay_skill"
          }
        ],
        "storageKey": "raAllSkills(perPage:1000,sortField:\"name\",sortOrder:\"asc\")"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "SkillPathsDisplay_Query",
    "selections": [
      {
        "alias": null,
        "args": (v0/*: any*/),
        "concreteType": "Skill",
        "kind": "LinkedField",
        "name": "raAllSkills",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "SkillLesson",
            "kind": "LinkedField",
            "name": "skillLessons",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "order",
                "storageKey": null
              },
              (v7/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Video",
                "kind": "LinkedField",
                "name": "video",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "VideoVariant",
                    "kind": "LinkedField",
                    "name": "videoVariants",
                    "plural": true,
                    "selections": [
                      (v1/*: any*/),
                      (v10/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v10/*: any*/)
                ],
                "storageKey": null
              },
              (v11/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "SkillLesson",
                "kind": "LinkedField",
                "name": "linkedSkillLesson",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  (v7/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Video",
                    "kind": "LinkedField",
                    "name": "video",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      (v8/*: any*/),
                      (v9/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "VideoVariant",
                        "kind": "LinkedField",
                        "name": "videoVariants",
                        "plural": true,
                        "selections": [
                          (v1/*: any*/),
                          (v8/*: any*/),
                          (v10/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v10/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v10/*: any*/)
                ],
                "storageKey": null
              },
              (v10/*: any*/)
            ],
            "storageKey": null
          },
          (v10/*: any*/)
        ],
        "storageKey": "raAllSkills(perPage:1000,sortField:\"name\",sortOrder:\"asc\")"
      }
    ]
  },
  "params": {
    "cacheID": "8566a1c92e0baae9ff494b9547c4ff37",
    "id": null,
    "metadata": {},
    "name": "SkillPathsDisplay_Query",
    "operationKind": "query",
    "text": "query SkillPathsDisplay_Query {\n  raAllSkills(perPage: 1000, sortField: \"name\", sortOrder: \"asc\") {\n    id\n    name\n    categories\n    active\n    skillLessonsNumber\n    activeSkillLessonsNumber\n    ...SkillPathsDisplay_skill\n    nodeID\n  }\n}\n\nfragment SkillPathsDisplay_skill on Skill {\n  id\n  name\n  categories\n  active\n  skillLessonsNumber\n  activeSkillLessonsNumber\n  skillLessons {\n    id\n    order\n    type\n    active\n    video {\n      id\n      title\n      introductory\n      videoVariants {\n        id\n        nodeID\n      }\n      nodeID\n    }\n    conversation {\n      id\n      name\n      nodeID\n    }\n    numberPracticeSets\n    practiceTextsNumber\n    linkedSkillLesson {\n      id\n      type\n      video {\n        id\n        title\n        introductory\n        videoVariants {\n          id\n          title\n          nodeID\n        }\n        nodeID\n      }\n      conversation {\n        id\n        name\n        nodeID\n      }\n      numberPracticeSets\n      practiceTextsNumber\n      nodeID\n    }\n    nodeID\n  }\n}\n"
  }
};
})();

(node as any).hash = "d7f255c87f7f1c713a230baa3b53ea40";

export default node;
