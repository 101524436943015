import VideoLibraryIcon from '@material-ui/icons/VideoLibrary';
import { ReactElement } from 'react';
import {
  BooleanInput,
  Datagrid,
  DateField,
  Filter,
  Link,
  List,
  ListProps,
  NumberField,
  TextInput,
} from 'react-admin';

import VideoGroupCreate from './VideoGroupCreate';
import VideoGroupEdit from './VideoGroupEdit';

const VideoGroupFilter = (props: any) => (
  <Filter {...props}>
    <TextInput label="Title" source="title" defaultValue="" />
    <BooleanInput label="Prerequisite" source="prerequisite" defaultValue />
  </Filter>
);

const VideoGroupList = (props: ListProps): ReactElement => (
  <List
    {...props}
    sort={{ field: 'title', order: 'ASC' }}
    perPage={25}
    filters={<VideoGroupFilter />}
  >
    <Datagrid optimized>
      <VideoGroupField />
      <NumberField source="numberOfVariants" title="Num. of Variants" />
      <DateField source="createdAt" label="Created At" />
    </Datagrid>
  </List>
);

const VideoGroupField = (props: any) =>
  props.record ? (
    <Link to={`/g/videos/${props.record.id}`}>{props.record.title}</Link>
  ) : null;

VideoGroupField.defaultProps = {
  source: 'title',
  addLabel: true,
};

export default {
  list: VideoGroupList,
  edit: VideoGroupEdit,
  create: VideoGroupCreate,
  icon: VideoLibraryIcon,
};
