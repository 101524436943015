import * as React from 'react';
import {
  List,
  Datagrid,
  TextField,
  DateField,
  TextInput,
  Filter,
  BooleanField,
  BooleanInput,
  NumberInput,
} from 'react-admin';
import UserLinkField from './UserLinkField';

const UniqueCodesFilter = (props: any) => (
  <Filter {...props}>
    <TextInput
      label="User First Name"
      source="User.firstName"
      defaultValue=""
    />
    <TextInput label="User Last Name" source="User.lastName" defaultValue="" />
    <TextInput label="User Email" source="User.email" defaultValue="" />
    <NumberInput lavel="Duration (Months)" source="duration_in_months" />
    <TextInput label="Code" source="code" defaultValue="" />
    <BooleanInput label="Redeemed" source="redeemed" />
  </Filter>
);

export const UniqueCodesList = (props: any) => (
  <List
    {...props}
    perPage={10}
    sort={{ field: 'createdAt', order: 'DESC' }}
    filters={<UniqueCodesFilter />}
  >
    <Datagrid rowClick="expand">
      <UserLinkField />
      <TextField source="code" label="Code" />
      <TextField source="duration_in_months" label="Duration (Months)" />
      <BooleanField source="redeemed" label="Redeemed?" />
      <DateField
        showTime
        source="createdAt"
        label="Created At (your timezone)"
      />
      <DateField
        showTime
        source="updatedAt"
        label="Redeemed At (your timezone)"
      />
    </Datagrid>
  </List>
);

export default UniqueCodesList;
