import * as React from 'react';
import {
  List,
  Datagrid,
  DateField,
  TextInput,
  Filter,
  NumberField,
  FunctionField,
  NumberInput,
  SelectInput,
  BooleanInput,
} from 'react-admin';
import UserLinkField from './UserLinkField';

import LessonLinkField from './LessonLinkField';
import UnitLinkField from './UnitLinkField';
import PracticeSetLinkField from './PracticeSetLinkField';
import { PHONE_OS_LIST, SUB_STATUS_LIST } from '../users/UserList';

export const FELT_IMPROVEMENT_LIST = [
  { id: '10', name: 'Yes' },
  { id: '0', name: 'No' },
  { id: '-10', name: 'Skip' },
];

const UserImprovementFilter = (props: any) => (
  <Filter {...props}>
    <TextInput
      label="User First Name"
      source="User.firstName"
      defaultValue=""
    />
    <TextInput label="User Last Name" source="User.lastName" defaultValue="" />
    <TextInput label="User Email" source="User.email" defaultValue="" />
    <TextInput label="User Language" source="User.language" defaultValue="" />
    <BooleanInput
      label="User Is Trial Or Subscriber"
      source="User.isSubscriber"
      defaultValue={false}
    />
    <SelectInput
      label="User Sub Status"
      source="User.subStatus"
      choices={SUB_STATUS_LIST}
    />
    <SelectInput
      label="User PhoneOS"
      source="User.phoneOS"
      choices={PHONE_OS_LIST}
    />

    <NumberInput
      label="Average Score >="
      source="averageScoreGreaterThanOrEquals"
    />
    <NumberInput
      label="Average Score <="
      source="averageScoreLessThanOrEquals"
    />

    <NumberInput
      label="Average Improvement >="
      source="improvementGreaterThanOrEquals"
    />
    <NumberInput
      label="Average Improvement <="
      source="improvementLessThanOrEquals"
    />

    <SelectInput
      source="improvementRating"
      label="Felt Improvement?"
      choices={FELT_IMPROVEMENT_LIST}
    />

    <TextInput label="Unit Id" source="unitId" defaultValue="" />
    <TextInput label="Lesson Id" source="lessonId" defaultValue="" />
    <TextInput label="Practice Set Id" source="practiceSetId" defaultValue="" />
  </Filter>
);

export const UserImprovementList = (props: any) => (
  <List
    {...props}
    perPage={10}
    sort={{ field: 'createdAt', order: 'DESC' }}
    filters={<UserImprovementFilter />}
  >
    <Datagrid rowClick="expand">
      <UserLinkField />
      <UnitLinkField />
      <LessonLinkField />
      <PracticeSetLinkField />
      <NumberField source="averageScore" />
      <NumberField source="averageImprovement" />
      <FunctionField
        render={(record: any) => {
          const { improvementRating } = record;
          return improvementRating === 10
            ? 'Yes'
            : improvementRating === 0
            ? 'No'
            : 'Skip';
        }}
        label="Felt Improvement?"
      />
      <DateField showTime source="createdAt" label="Date" />
    </Datagrid>
  </List>
);

export default UserImprovementList;
