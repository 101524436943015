// @ts-nocheck

import React from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import styled from 'styled-components';
import { useInput } from 'react-admin';
import { useForm } from 'react-final-form';

const AssessmentPracticeInput = (props: any) => {
  const { id, choices, label } = props;
  const [languageValues, setLanguageValues] = React.useState({});

  const {
    input: { onChange, value: values },
  } = useInput(props);

  const { change, getState } = useForm();
  const { initialValues } = getState();

  const children = React.cloneElement(props.children, props);
  const selectedChoices = React.useMemo(
    () =>
      (values || [])
        .map((v: any) => choices.find((c: any) => c.id === v))
        .filter(identity),
    [values, choices],
  );

  // initial set up of fromToValues from initialValues.practice_items
  React.useEffect(() => {
    const newLanguageValues = {};
    const { practice_items } = initialValues;

    if (values?.length) {
      values.forEach((value) => {
        newLanguageValues[value] = {
          language:
            practice_items?.find((i) => i.id === value)?.AssessmentPractice
              ?.language || null,
        };
      });
    }

    setLanguageValues(newLanguageValues);
  }, [JSON.stringify(values)]);

  // updates fromToValues and associations
  React.useEffect(() => {
    const practice_items = [];
    if (values?.length) {
      values.forEach((value) => {
        const newPracticeItem = {
          id: value,

          AssessmentPractice: {
            practiceId: value,
            language: languageValues[value]?.language,
          },
        };

        practice_items.push(newPracticeItem);
      });
    }
    console.log('practice_items', practice_items);
    change('practice_items', practice_items);
  }, [values, languageValues]);

  const setNewLanguageValue = (itemId, newValue) => {
    const newLanguageValues = {
      ...languageValues,
      [itemId]: {
        language: newValue === 'Unassigned' ? null : newValue,
      },
    };

    setLanguageValues(newLanguageValues);
  };

  const onDragEnd = (result: any) => {
    const { destination, source, draggableId } = result;

    if (!destination || destination.index === source.index) return;

    const newValues = Array.from(values);
    newValues.splice(source.index, 1);
    newValues.splice(destination.index, 0, draggableId);

    onChange(newValues);
  };

  return (
    <>
      {children}
      {selectedChoices.length ? (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId={id}>
            {(provided, snapshot) => (
              <List
                component="nav"
                ref={provided.innerRef}
                isDraggingOver={snapshot.isDraggingOver}
                {...provided.droppableProps}
              >
                <span style={{ display: 'flex' }}>
                  <Title>{label}</Title>
                </span>
                {selectedChoices.map((v, i) => (
                  <ChoiceItem
                    value={v}
                    index={i}
                    key={v.id}
                    languageValues={languageValues}
                    setNewLanguageValue={setNewLanguageValue}
                  />
                ))}
                {provided.placeholder}
              </List>
            )}
          </Droppable>
        </DragDropContext>
      ) : null}
    </>
  );
};

const ChoiceItem = (props) => {
  const { value, index, languageValues, setNewLanguageValue } = props;
  return (
    <Draggable draggableId={value.id} index={index}>
      {(provided, snapshot) => (
        <ListItem
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          isDragging={snapshot.isDragging}
        >
          <FlexRow>
            <Item flex={1}>
              #{index + 1} -{' '}
              <a href={`/#/content/practice/${value.id}`} target="_blank">
                <b>{value.word}</b>
              </a>
            </Item>

            <Item flex={2} style={{ marginLeft: 10 }}>
              Language:{' '}
              <input
                style={{ minWidth: 200 }}
                onChange={(evt) =>
                  setNewLanguageValue(value.id, evt.target.value)
                }
                value={languageValues[value.id]?.language}
              />
            </Item>
          </FlexRow>
        </ListItem>
      )}
    </Draggable>
  );
};

export default AssessmentPracticeInput;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
`;

const Item = styled.div`
  ${(p) => p.flex && `flex: ${p.flex};`}
`;

const ListItem = styled.div<StyledDivProps>`
  padding: 8px;
  margin: 8px;
  border: 1px solid grey;
  background-color: ${(props) => (props.isDragging ? 'lightgrey' : 'white')};
  border-radius: 16px;
`;

const List = styled.div<StyledDivProps>`
  padding: 8px;
  border: 1px solid grey;
  background-color: ${(props) => (props.isDraggingOver ? '#2097F3' : 'white')};
`;

const Title = styled.div`
  font-weight: bold;
  font-size: 1.2rem;
  padding: 8px;
`;

interface StyledDivProps {
  isDragging?: boolean;
  isDraggingOver?: boolean;
}

const identity = (v: any) => v;
