import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Modal,
} from '@material-ui/core';
import { Theme, makeStyles } from '@material-ui/core/styles';
import { Styles } from '@material-ui/styles/withStyles';
import * as React from 'react';
import { FC } from 'react';
import {
  AutocompleteArrayInput,
  DeleteButton,
  Edit,
  EditProps,
  FieldProps,
  FormWithRedirect,
  NumberInput,
  ReferenceArrayInput,
  ReferenceInput,
  SaveButton,
  SelectInput,
  TextInput,
  Toolbar,
  required,
  useGetList,
} from 'react-admin';

import api from '../api';
import OrderedArrayInput from '../components/OrderedArrayInput';
import { matchSuggestion } from '../lesson/utils';

export const TESTING_VARIANTS = [
  { id: 'default', name: 'Default (A)' },
  { id: 'variant', name: 'Variant (B)' },
];

const UnitTitle: FC<FieldProps> = ({ record }) =>
  record ? (
    <span>
      Unit &quot;
      {record.name}&quot;
    </span>
  ) : null;

export const styles: Styles<Theme, any> = {
  name: { display: 'flex', flex: 1, marginRight: 10 },
  description: { display: 'inline-block' },
  photo: { display: 'inline-block' },
  spacing: { marginRight: 10 },
  image: {
    margin: '0.5rem',
    maxHeight: '10rem',
  },
  button: {
    marginRight: 10,
  },
  paper: {
    position: 'absolute',
    width: '75%',
    height: 500,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    margin: 'auto',
    backgroundColor: 'white',
    padding: 40,
    overflow: 'scroll',
  },
};

const useStyles = makeStyles(styles);

const UnitForm = (props: any) => {
  const classes = useStyles(props);
  const [modalOpen, setModalOpen] = React.useState(false);
  const { data, loading, error } = useGetList('content/curriculum');

  const [selectedCurriculums, setSelectedCurriculums] = React.useState<any>({});

  const bringUpCopyModal = React.useCallback(() => {
    setModalOpen(true);
  }, []);

  const askForCopyToAllUsers = React.useCallback(async () => {
    const curriculumName = data?.[props?.record?.curriculumId]?.name;
    if (
      window.confirm(
        `Are you sure you want to add this unit to the end of their units for all people who are currently in the ${curriculumName} curriculum?`,
      )
    ) {
      try {
        const result = await api.addUnitToEndForAllUsersInCurriculum(
          props?.record?.id,
          props?.record?.curriculumId,
        );
        if (result.status === 200) {
          alert(
            `Request submitted. Running for ${result.json?.numberToProcess} people so may take a while. dont try it again for a bit`,
          );
        } else {
          alert(`Error occurred, gotta check the logs don't run it again`);
        }
      } catch (err) {
        alert(`Error occurred, maybe it timed out idk`);
      }
    }
  }, [props?.record?.curriculumId, props?.record?.id, data]);

  const askForDeleteFromAllUsers = React.useCallback(async () => {
    const curriculumName = data?.[props?.record?.curriculumId]?.name;
    if (
      window.confirm(
        `Are you 100% sure you want to remove ${props?.record?.name} from all imprints of ${curriculumName} (id: ${props?.record?.curriculumId})`,
      )
    ) {
      try {
        const result = await api.deleteUnitFromAllUsersInCurriculum(
          props?.record?.id,
          props?.record?.curriculumId,
        );
        if (result.status === 200) {
          alert(
            `Request submitted. Running for ${result.json?.numberToProcess} people so may take a while. dont try it again for a bit`,
          );
        } else {
          alert(`Error occurred, gotta check the logs don't run it again`);
        }
      } catch (err) {
        alert(`Error occurred: ${err}`);
      }
    }
  }, [props?.record?.curriculumId, props?.record?.id, data]);

  const closeCopyModal = React.useCallback(() => {
    setModalOpen(false);
    setSelectedCurriculums({});
  }, []);

  const submitCopy = React.useCallback(async () => {
    const newObject = Object.assign(
      {},
      ...Object.keys(selectedCurriculums)
        .filter((curriculumId: any) => selectedCurriculums[curriculumId])
        .map((curriculumId: any) => ({ [curriculumId]: true })),
    );
    const unitId = props?.record?.id;
    try {
      const submittedCopy = await api.submitUnitCopy(unitId, newObject);
      if (submittedCopy.status === 200) {
        alert('Copy successful.');
      } else {
        alert('Not successful, check & try again.');
      }
    } catch (err) {
      alert('Not successful, check & try again.');
    }

    closeCopyModal();
  }, [selectedCurriculums, props?.record?.id]);

  const selectAll = React.useCallback(() => {
    const newObject = Object.assign(
      {},
      ...Object.keys(data)
        // .filter(
        //   (curriculumItemId: any) =>
        //     curriculumItemId !== props?.record?.curriculumId,
        // )
        .map((curriculumId: any) => ({ [curriculumId]: true })),
    );
    setSelectedCurriculums(newObject);
  }, [data, props?.record?.curriculumId]);

  const unselectAll = React.useCallback(() => {
    setSelectedCurriculums({});
  }, []);

  const isSelectedAll = React.useMemo(() => {
    const numberSelectedCurriculums = Object.values(
      selectedCurriculums || {},
    )?.filter((item) => item).length;
    const numberTotalCurriculums = Object.keys(data)?.length;
    return numberSelectedCurriculums === numberTotalCurriculums;
  }, [selectedCurriculums, data]);

  const updateCheckboxes = React.useCallback((event, curriculumId) => {
    const newCheckStatus = event.target.checked;
    setSelectedCurriculums((existingSelectedCurriculums: any) => ({
      ...existingSelectedCurriculums,
      [curriculumId]: newCheckStatus,
    }));
  }, []);
  return (
    <>
      <Modal open={modalOpen} onClose={closeCopyModal}>
        <div className={classes.paper}>
          <div>Choose which curriculums to copy unit to:</div>
          <div style={{ paddingTop: 20 }}>
            <Button
              onClick={isSelectedAll ? unselectAll : selectAll}
              variant="contained"
              color="primary"
            >
              {isSelectedAll ? `Unselect All` : `Select All`}
            </Button>
          </div>
          <div style={{ paddingTop: 20 }}>
            <Button onClick={submitCopy} variant="contained" color="primary">
              Submit
            </Button>
          </div>
          {loading ? (
            <div>Loading...</div>
          ) : error ? (
            <div>Error occurred</div>
          ) : (
            <div style={{ paddingTop: 20 }}>
              {Object.values(data)
                // .filter(
                //   (curriculumItem: any) =>
                //     curriculumItem?.id !== props?.record?.curriculumId,
                // )
                .sort((a) => (a?.id === props?.record?.curriculumId ? -1 : 1))
                .map((curriculumItem: any) => {
                  const isChecked = !!selectedCurriculums?.[curriculumItem?.id];
                  return (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                      }}
                    >
                      <Checkbox
                        checked={isChecked}
                        onChange={(event: any) =>
                          updateCheckboxes(event, curriculumItem?.id)
                        }
                      />
                      <div>
                        {curriculumItem?.name}{' '}
                        {curriculumItem?.id === props?.record?.curriculumId
                          ? `(SAME CURRICULUM: added to end as a Variant B)`
                          : null}
                      </div>
                    </div>
                  );
                })}
            </div>
          )}

          <div style={{ paddingTop: 20 }}>
            <Button onClick={submitCopy} variant="contained" color="primary">
              Submit
            </Button>
          </div>
        </div>
      </Modal>
      <FormWithRedirect
        {...props}
        render={(formProps: any) => (
          <Card>
            <form>
              <CardContent>
                <Box
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <TextInput
                    autoFocus
                    source="name"
                    label="Unit Name"
                    className={classes.name}
                    validate={requiredValidate}
                  />
                  <Button
                    onClick={bringUpCopyModal}
                    variant="contained"
                    color="primary"
                    style={{ marginRight: 10 }}
                  >
                    Copy Unit
                  </Button>
                  <Button
                    onClick={askForCopyToAllUsers}
                    variant="contained"
                    color="primary"
                    style={{ marginRight: 10 }}
                  >
                    + All users
                  </Button>
                  <Button
                    onClick={askForDeleteFromAllUsers}
                    variant="contained"
                    className="ra-delete-button"
                    style={{
                      color: 'red',
                    }}
                  >
                    Remove From all Users
                  </Button>
                </Box>
                <Box>
                  <TextInput
                    fullWidth
                    source="description"
                    label="Unit Description"
                    className={classes.description}
                  />
                </Box>
                <Box>
                  <TextInput
                    multiline
                    fullWidth
                    source="notes"
                    label="Notes"
                    className={classes.description}
                  />
                </Box>
                <Box display="flex" style={{ width: '100%' }}>
                  <ReferenceInput
                    style={{ width: '50%' }}
                    label="Curriculum"
                    source="curriculumId"
                    reference="content/curriculum"
                    validate={requiredValidate}
                  >
                    <SelectInput optionText="name" />
                  </ReferenceInput>
                  <NumberInput
                    source="unitNumber"
                    label="Unit Number"
                    style={{ marginLeft: 10 }}
                    validate={requiredValidate}
                  />
                  <SelectInput
                    label="Testing Variant"
                    source="testingVariant"
                    helperText={false}
                    choices={TESTING_VARIANTS}
                    allowEmpty
                    style={{ marginLeft: 10 }}
                  />
                  <TextInput
                    source="testName"
                    label="Experiment Name"
                    className={classes.description}
                    style={{ marginLeft: 10 }}
                  />
                </Box>
                <Box>
                  <ReferenceArrayInput
                    filterToQuery={(searchText: any) => ({ name: searchText })}
                    label="Lessons"
                    source="lessonId"
                    reference="content/lesson"
                  >
                    <OrderedArrayInput elementLinkUrl="/#/content/lesson/">
                      <AutocompleteArrayInput
                        optionValue="id"
                        optionText={(record: any) =>
                          `${record?.name} ${
                            record?.tags ? `- ${record?.tags}` : ``
                          } (id: ${record?.id?.slice(0, 4)})`
                        }
                        matchSuggestion={(filterValue: string, choice: any) =>
                          matchSuggestion(filterValue, choice, 'name')
                        }
                      />
                    </OrderedArrayInput>
                  </ReferenceArrayInput>
                </Box>

                <Toolbar
                  record={formProps.record}
                  basePath={formProps.basePath}
                  undoable={false}
                  invalid={formProps.invalid}
                  handleSubmit={formProps.handleSubmit}
                  saving={formProps.saving}
                  resource="practices"
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <div>
                    <SaveButton
                      label="Save & Continue"
                      handleSubmitWithRedirect={
                        formProps.handleSubmitWithRedirect ||
                        formProps.handleSubmit
                      }
                      disabled={formProps.disabled}
                      invalid={formProps.invalid}
                      redirect="edit"
                      saving={formProps.saving}
                      submitOnEnter={formProps.submitOnEnter}
                      className={classes.button}
                    />

                    <SaveButton
                      label="Save & Exit"
                      handleSubmitWithRedirect={
                        formProps.handleSubmitWithRedirect ||
                        formProps.handleSubmit
                      }
                      disabled={formProps.disabled}
                      invalid={formProps.invalid}
                      redirect={formProps.redirect}
                      saving={formProps.saving}
                      submitOnEnter={formProps.submitOnEnter}
                      className={classes.button}
                    />
                  </div>

                  {formProps.record &&
                    typeof formProps.record.id !== 'undefined' && (
                      <DeleteButton
                        basePath={formProps.basePath}
                        record={formProps.record}
                        resource={formProps.resource}
                        undoable={formProps.undoable}
                        mutationMode={formProps.mutationMode}
                      />
                    )}
                </Toolbar>
              </CardContent>
            </form>
          </Card>
        )}
      />
    </>
  );
};

const UnitEdit: FC<EditProps> = (props) => (
  <Edit undoable={false} title={<UnitTitle />} {...props}>
    <UnitForm />
  </Edit>
);

const requiredValidate = [required()];

export default UnitEdit;
