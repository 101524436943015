/**
 * @generated SignedSource<<cdf42523de86382435e26537b63398ca>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type WordEdit_addToBoldVoice_Mutation$variables = {
  pronunciationID: string;
};
export type WordEdit_addToBoldVoice_Mutation$data = {
  readonly addPronunciationToSource: {
    readonly word: {
      readonly " $fragmentSpreads": FragmentRefs<"WordEdit_word">;
    } | null | undefined;
  } | null | undefined;
};
export type WordEdit_addToBoldVoice_Mutation = {
  response: WordEdit_addToBoldVoice_Mutation$data;
  variables: WordEdit_addToBoldVoice_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "pronunciationID"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "pronunciationID",
    "variableName": "pronunciationID"
  },
  {
    "kind": "Literal",
    "name": "sourceName",
    "value": "boldvoice"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "nodeID",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "WordEdit_addToBoldVoice_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Pronunciation",
        "kind": "LinkedField",
        "name": "addPronunciationToSource",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Word",
            "kind": "LinkedField",
            "name": "word",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "WordEdit_word"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "WordEdit_addToBoldVoice_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Pronunciation",
        "kind": "LinkedField",
        "name": "addPronunciationToSource",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Word",
            "kind": "LinkedField",
            "name": "word",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "text",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Pronunciation",
                "kind": "LinkedField",
                "name": "pronunciations",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "active",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "default",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "bvIPAUnsyllabified",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "bvIPA",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "form",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PronunciationPronunciationSource",
                    "kind": "LinkedField",
                    "name": "sourceEdges",
                    "plural": true,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PronunciationSources",
                        "kind": "LinkedField",
                        "name": "pronunciationSource",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "name",
                            "storageKey": null
                          },
                          (v3/*: any*/),
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "original",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "sourceDefault",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "removed",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "autoRemoved",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PartOfSpeech",
                        "kind": "LinkedField",
                        "name": "partsOfSpeech",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "tag",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "description",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Pronunciation",
                        "kind": "LinkedField",
                        "name": "pronunciation",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          (v3/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "80a14882170710292ddc7356c2aca59b",
    "id": null,
    "metadata": {},
    "name": "WordEdit_addToBoldVoice_Mutation",
    "operationKind": "mutation",
    "text": "mutation WordEdit_addToBoldVoice_Mutation(\n  $pronunciationID: ID!\n) {\n  addPronunciationToSource(pronunciationID: $pronunciationID, sourceName: \"boldvoice\") {\n    word {\n      ...WordEdit_word\n      nodeID\n    }\n    nodeID\n  }\n}\n\nfragment WordEdit_Pronunciation on Pronunciation {\n  id\n  bvIPAUnsyllabified\n  bvIPA\n  active\n  default\n  form\n  sourceEdges {\n    id\n    pronunciationSource {\n      name\n      nodeID\n    }\n    ...WordEdit_SourceEdge\n    nodeID\n  }\n}\n\nfragment WordEdit_SourceEdge on PronunciationPronunciationSource {\n  id\n  original\n  sourceDefault\n  removed\n  autoRemoved\n  partsOfSpeech {\n    tag\n    description\n  }\n  pronunciation {\n    id\n    nodeID\n  }\n  pronunciationSource {\n    id\n    name\n    nodeID\n  }\n}\n\nfragment WordEdit_word on Word {\n  text\n  pronunciations {\n    id\n    active\n    default\n    ...WordEdit_Pronunciation\n    nodeID\n  }\n}\n"
  }
};
})();

(node as any).hash = "4ec6a0102770edc509aa7ba5c4bae5cc";

export default node;
