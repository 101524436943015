/**
 * @generated SignedSource<<81f8116daf20b6ea9f318be9d32dc296>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SkillsSelect_skillsByIDs_RefetchQuery$variables = {
  ids?: ReadonlyArray<string> | null | undefined;
};
export type SkillsSelect_skillsByIDs_RefetchQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"SupersedingSkillsSelect_skillsByIDs">;
};
export type SkillsSelect_skillsByIDs_RefetchQuery = {
  response: SkillsSelect_skillsByIDs_RefetchQuery$data;
  variables: SkillsSelect_skillsByIDs_RefetchQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": ([]/*: any*/),
    "kind": "LocalArgument",
    "name": "ids"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "ids",
    "variableName": "ids"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SkillsSelect_skillsByIDs_RefetchQuery",
    "selections": [
      {
        "args": (v1/*: any*/),
        "kind": "FragmentSpread",
        "name": "SupersedingSkillsSelect_skillsByIDs"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SkillsSelect_skillsByIDs_RefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Skill",
        "kind": "LinkedField",
        "name": "skillsByID",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "nodeID",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a032aa5614975aed3b4e2f99a1e5d227",
    "id": null,
    "metadata": {},
    "name": "SkillsSelect_skillsByIDs_RefetchQuery",
    "operationKind": "query",
    "text": "query SkillsSelect_skillsByIDs_RefetchQuery(\n  $ids: [ID!] = []\n) {\n  ...SupersedingSkillsSelect_skillsByIDs_3pY7A9\n}\n\nfragment SupersedingSkillsSelect_skillsByIDs_3pY7A9 on Query {\n  skillsByID(ids: $ids) {\n    id\n    name\n    nodeID\n  }\n}\n"
  }
};
})();

(node as any).hash = "d54dadf03f1f0473ae0f665c3a337d25";

export default node;
