import * as React from 'react';
import {
  List,
  DateField,
  TextField,
  Datagrid,
  FieldProps,
  Filter,
  NumberInput,
  NullableBooleanInput,
  TextInput,
} from 'react-admin';
import { Link } from 'react-router-dom';

const TruncatedIdField: React.FC<FieldProps> = (props) =>
  props.record ? (
    <Link to={`/content/tutorial_config/${props.record.id}`}>
      {String(props?.record?.id).slice(0, 6)}
    </Link>
  ) : null;

TruncatedIdField.defaultProps = {};

const QuipFilter = (props: any) => (
  <Filter {...props}>
    <TextInput label="Blurb" source="blurb" />
    <NumberInput label="State Key" source="stateKey" />
    <TextInput label="language" source="language" />
    <NullableBooleanInput label="Is for Sentence" source="isForSentence" />
  </Filter>
);

export const TutorialConfigList = (props: any) => (
  <List
    {...props}
    perPage={25}
    sort={{ field: 'createdAt', order: 'DESC' }}
    filters={<QuipFilter />}
  >
    <Datagrid optimized>
      <TruncatedIdField label="Id" />
      <TextField source="language" />

      <DateField showTime source="createdAt" label="Created At" />
    </Datagrid>
  </List>
);

export default TutorialConfigList;
