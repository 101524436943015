import * as React from 'react';
import { FC, memo } from 'react';

import { FieldProps } from 'react-admin';

interface Props extends FieldProps {
  curriculum?: any;
}

const CurriculumNameField: FC<Props> = ({ record, curriculum }) => {
  const name = record?.curricula?.find(
    (curriculumItem: any) => curriculumItem.id === curriculum,
  )?.name;
  return record && name ? <div>{name}</div> : null;
};

CurriculumNameField.defaultProps = {
  source: 'name',
  label: 'Name',
};

export default memo<Props>(CurriculumNameField);
