import Icon from '@material-ui/icons/LibraryMusic';

import SongList from './SongList';
import SongEdit from './SongEdit';
import SongCreate from './SongCreate';

export default {
  list: SongList,
  edit: SongEdit,
  icon: Icon,
  create: SongCreate,
};
