import * as React from 'react';
import { FC } from 'react';
import {
  Create,
  CreateProps,
  SimpleForm,
  TextInput,
  required,
} from 'react-admin';

import { makeStyles, Theme } from '@material-ui/core/styles';
import { Styles } from '@material-ui/styles/withStyles';
import Typography from '@material-ui/core/Typography';

export const styles: Styles<Theme, any> = {
  name: { display: 'inline-block' },
  number: { display: 'inline-block', marginLeft: 10 },
};

const useStyles = makeStyles(styles);

const ReferralCodeCreate: FC<CreateProps> = (props) => {
  const classes = useStyles(props);

  const transform = (data: any) => ({
    ...data,
    description: '',
    payout_amount: 1000,
  });

  return (
    <Create {...props} transform={transform}>
      <SimpleForm initialValues={{}}>
        <Typography variant="h5" gutterBottom>
          Create referral code for user
        </Typography>
        <TextInput
          fullWidth
          autoFocus
          source="slug"
          label="Referral Code"
          className={classes.name}
          validate={requiredValidate}
          multiline
        />
        <TextInput
          fullWidth
          autoFocus
          source="ownerId"
          label="User Id"
          className={classes.name}
          validate={requiredValidate}
          multiline
        />
        {/* eh just use a string input */}
        {/* <ReferenceInput
          label="User Id"
          source="userId"
          reference="users"
          validate={[required()]}
          fullWidth
          autoFocus
        >
          <SelectInput optionText="name" />
        </ReferenceInput> */}
        <Typography variant="caption" gutterBottom>
          After you create the referral code, just refresh the page if it
          crashes
        </Typography>
      </SimpleForm>
    </Create>
  );
};

const requiredValidate = [required()];

export default ReferralCodeCreate;
