import * as React from 'react';
import {
  List,
  Datagrid,
  DateField,
  TextInput,
  Filter,
  BooleanInput,
  BooleanField,
  NumberField,
} from 'react-admin';
import UserLinkField from './UserLinkField';
import SongLinkField from './SongLinkField';
import SongsTakenShow from './SongsTakenShow';
import AudioField from './AudioField';

const SongsTakenFilter = (props: any) => (
  <Filter {...props}>
    <TextInput
      label="User First Name"
      source="User.firstName"
      defaultValue=""
    />
    <TextInput label="User Last Name" source="User.lastName" defaultValue="" />
    <BooleanInput label="Completed" source="completed" />
  </Filter>
);

export const SongsTakenList = (props: any) => (
  <List
    {...props}
    perPage={10}
    sort={{ field: 'createdAt', order: 'DESC' }}
    filters={<SongsTakenFilter />}
  >
    <Datagrid rowClick="expand" expand={<SongsTakenShow />}>
      <UserLinkField />
      <SongLinkField />
      <AudioField />
      <NumberField
        source="overallAverageScore"
        options={{ style: 'percent' }}
      />
      <BooleanField source="completed" />
      <DateField showTime source="createdAt" label="Date Taken" />
    </Datagrid>
  </List>
);

export default SongsTakenList;
