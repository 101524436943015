import { Box, Card, CardContent } from '@material-ui/core';
import { Theme, makeStyles } from '@material-ui/core/styles';
import { DataGrid } from '@material-ui/data-grid';
import { Styles } from '@material-ui/styles/withStyles';
import * as React from 'react';
import { FC } from 'react';
import {
  DeleteButton,
  Edit,
  EditProps,
  FieldProps,
  FormWithRedirect,
  ImageInput,
  ReferenceInput,
  SaveButton,
  SelectInput,
  TextInput,
  Toolbar,
  useRecordContext,
} from 'react-admin';

import { apiURL } from '../api';
import CreditQuickCreateButton from './CreditQuickCreateButton';

const Separator = () => <Box pt="1em" />;
const CoachTitle: FC<FieldProps> = ({ record }) =>
  record ? (
    <span>
      Coach &quot;
      {record.name}&quot;
    </span>
  ) : null;

export const styles: Styles<Theme, any> = {
  name: { display: 'inline-block' },
  photo: { display: 'inline-block' },
  spacing: { marginRight: 10 },
  image: {
    margin: '0.5rem',
    maxHeight: '10rem',
  },
  button: {
    marginRight: 10,
  },
};

const useStyles = makeStyles(styles);

const ImageContentField = (props: any) => {
  const image = useRecordContext(props);
  const classes = useStyles(props);
  const url = image?.src || `${apiURL}/api/v1/image/coach/${image}`;
  return <img src={url} alt="img" className={classes.image} />;
};

const CoachForm = (props: any) => {
  const classes = useStyles(props);
  return (
    <FormWithRedirect
      {...props}
      render={(formProps: any) => (
        <Card>
          <form>
            <CardContent>
              <Box>
                <TextInput
                  fullWidth
                  autoFocus
                  source="name"
                  label="Coach Name"
                  formClassName={classes.name}
                />
              </Box>

              <Box>
                <TextInput
                  fullWidth
                  multiline
                  source="description"
                  label="Description"
                  formClassName={classes.name}
                />
              </Box>

              <Box display="flex" style={{ width: '100%' }}>
                <ReferenceInput
                  allowEmpty
                  style={{ width: '50%' }}
                  label="Intro Video"
                  source="videoId"
                  reference="content/video"
                >
                  <SelectInput optionText="name" />
                </ReferenceInput>
              </Box>

              <Separator />
              <Box>
                <ImageInput
                  source="image"
                  label="Image"
                  accept="image/*"
                  formClassName={classes.photo}
                >
                  <ImageContentField source="src" title="Image" />
                </ImageInput>
              </Box>
              <Box>
                <div style={{ display: 'flex' }}>
                  <h1 style={{ flex: 1 }}>Coach credits</h1>
                  <CreditQuickCreateButton
                    coachId={props?.record?.id}
                    onChange={() => {
                      window.location.reload();
                    }}
                  />
                </div>
                <DataGrid
                  autoHeight
                  rows={props?.record?.credits || []}
                  columns={[
                    {
                      field: 'credit.title',
                      headerName: 'Title',
                      flex: 1,
                      valueGetter: ({ row }) => row?.title,
                      renderCell: (params: any) => (
                        <span className="table-cell-trucate">
                          {params.row?.title}
                        </span>
                      ),
                    },
                    {
                      field: 'credit.image',
                      headerName: 'Image',
                      flex: 1,
                      valueGetter: ({ row }) => row?.image,
                      renderCell: (params: any) => (
                        <div>
                          <img
                            alt="credit company logo"
                            style={{
                              height: '50%',
                              width: '80%',
                              objectFit: 'contain',
                            }}
                            src={params?.row?.image}
                          />
                        </div>
                      ),
                    },
                    {
                      field: 'credit.delete',
                      headerName: 'Delete',
                      renderCell: (row) => (
                        <DeleteButton
                          basePath={formProps.basePath}
                          record={row}
                          redirect={false}
                          resource="coach/credits"
                        />
                      ),
                    },
                  ]}
                />
              </Box>

              <Toolbar
                record={formProps.record}
                basePath={formProps.basePath}
                undoable={false}
                invalid={formProps.invalid}
                handleSubmit={formProps.handleSubmit}
                saving={formProps.saving}
                resource="practices"
                style={{ display: 'flex', justifyContent: 'space-between' }}
              >
                <div>
                  <SaveButton
                    label="Save & Continue"
                    handleSubmitWithRedirect={
                      formProps.handleSubmitWithRedirect ||
                      formProps.handleSubmit
                    }
                    disabled={formProps.disabled}
                    invalid={formProps.invalid}
                    redirect="edit"
                    saving={formProps.saving}
                    submitOnEnter={formProps.submitOnEnter}
                    className={classes.button}
                  />

                  <SaveButton
                    label="Save & Exit"
                    handleSubmitWithRedirect={
                      formProps.handleSubmitWithRedirect ||
                      formProps.handleSubmit
                    }
                    disabled={formProps.disabled}
                    invalid={formProps.invalid}
                    redirect={formProps.redirect}
                    saving={formProps.saving}
                    submitOnEnter={formProps.submitOnEnter}
                    className={classes.button}
                  />
                </div>

                {formProps.record &&
                  typeof formProps.record.id !== 'undefined' && (
                    <DeleteButton
                      basePath={formProps.basePath}
                      record={formProps.record}
                      resource={formProps.resource}
                      undoable={formProps.undoable}
                      mutationMode={formProps.mutationMode}
                    />
                  )}
              </Toolbar>
            </CardContent>
          </form>
        </Card>
      )}
    />
  );
};

const CoachEdit: FC<EditProps> = (props) => (
  <Edit undoable={false} title={<CoachTitle />} {...props}>
    <CoachForm />
  </Edit>
);

export default CoachEdit;
