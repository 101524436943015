/**
 * @generated SignedSource<<abde72ada7bcbdb228b1597e8a440d75>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PracticesSelect_practicesForString_RefetchQuery$variables = {
  search?: string | null | undefined;
};
export type PracticesSelect_practicesForString_RefetchQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"PracticesSelect_practicesForString">;
};
export type PracticesSelect_practicesForString_RefetchQuery = {
  response: PracticesSelect_practicesForString_RefetchQuery$data;
  variables: PracticesSelect_practicesForString_RefetchQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": "",
    "kind": "LocalArgument",
    "name": "search"
  }
],
v1 = {
  "kind": "Variable",
  "name": "search",
  "variableName": "search"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PracticesSelect_practicesForString_RefetchQuery",
    "selections": [
      {
        "args": [
          (v1/*: any*/)
        ],
        "kind": "FragmentSpread",
        "name": "PracticesSelect_practicesForString"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PracticesSelect_practicesForString_RefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Literal",
            "name": "first",
            "value": 20
          },
          (v1/*: any*/)
        ],
        "concreteType": "QueryPracticeTextsForStringConnection",
        "kind": "LinkedField",
        "name": "practiceTextsForString",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PracticeText",
            "kind": "LinkedField",
            "name": "nodes",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "text",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "coachSample",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "aiCoachSample",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "nodeID",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "06017cb870dc96b8a2c134f789592714",
    "id": null,
    "metadata": {},
    "name": "PracticesSelect_practicesForString_RefetchQuery",
    "operationKind": "query",
    "text": "query PracticesSelect_practicesForString_RefetchQuery(\n  $search: String = \"\"\n) {\n  ...PracticesSelect_practicesForString_40zwac\n}\n\nfragment PracticesSelect_practicesForString_40zwac on Query {\n  practiceTextsForString(search: $search, first: 20) {\n    nodes {\n      id\n      text\n      coachSample\n      aiCoachSample\n      nodeID\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "8f429b538cc185116cbd12e922e851c8";

export default node;
