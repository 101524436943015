import * as React from 'react';
import { Layout, LayoutProps, Sidebar } from 'react-admin';
import { useSelector } from 'react-redux';

import AppBar from './AppBar';
import Menu from './Menu';
import { darkTheme, lightTheme } from './themes';

const CustomSidebar = (props: any) => <Sidebar {...props} size={200} />;

export default ({ children, ...props }: LayoutProps) => {
  const theme = useSelector((state: any) =>
    state.theme === 'dark' ? darkTheme : lightTheme,
  );
  return (
    <Layout
      {...props}
      appBar={AppBar}
      sidebar={CustomSidebar}
      menu={Menu}
      theme={theme}
    >
      <React.Suspense fallback={null}>{children}</React.Suspense>
    </Layout>
  );
};
