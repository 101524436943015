/**
 * @generated SignedSource<<9ac38cdf95668ad0b1ae065a6574844f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MultipleChoiceOptionsEditor_Reorder_Mutation$variables = {
  newOrder: ReadonlyArray<string>;
};
export type MultipleChoiceOptionsEditor_Reorder_Mutation$data = {
  readonly reorderMultipleChoiceOptions: {
    readonly " $fragmentSpreads": FragmentRefs<"MultipleChoiceOptionsEditor_question">;
  } | null | undefined;
};
export type MultipleChoiceOptionsEditor_Reorder_Mutation = {
  response: MultipleChoiceOptionsEditor_Reorder_Mutation$data;
  variables: MultipleChoiceOptionsEditor_Reorder_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "newOrder"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "newOrder",
    "variableName": "newOrder"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "nodeID",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "MultipleChoiceOptionsEditor_Reorder_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "MultipleChoiceQuestion",
        "kind": "LinkedField",
        "name": "reorderMultipleChoiceOptions",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "MultipleChoiceOptionsEditor_question"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "MultipleChoiceOptionsEditor_Reorder_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "MultipleChoiceQuestion",
        "kind": "LinkedField",
        "name": "reorderMultipleChoiceOptions",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "MultipleChoiceOption",
            "kind": "LinkedField",
            "name": "options",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "order",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "text",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "audioUrl",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isCorrectAnswer",
                "storageKey": null
              },
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "3209cf26356ef2e4abad487d71b0d6e7",
    "id": null,
    "metadata": {},
    "name": "MultipleChoiceOptionsEditor_Reorder_Mutation",
    "operationKind": "mutation",
    "text": "mutation MultipleChoiceOptionsEditor_Reorder_Mutation(\n  $newOrder: [ID!]!\n) {\n  reorderMultipleChoiceOptions(newOrder: $newOrder) {\n    ...MultipleChoiceOptionsEditor_question\n    nodeID\n  }\n}\n\nfragment MultipleChoiceOptionsEditor_option on MultipleChoiceOption {\n  id\n  text\n  audioUrl\n  order\n  isCorrectAnswer\n}\n\nfragment MultipleChoiceOptionsEditor_question on MultipleChoiceQuestion {\n  id\n  options {\n    id\n    order\n    ...MultipleChoiceOptionsEditor_option\n    nodeID\n  }\n}\n"
  }
};
})();

(node as any).hash = "57c7802162d8131a7aaf3ed6efde15c5";

export default node;
