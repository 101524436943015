import * as React from 'react';
import { FC, memo } from 'react';

import { FieldProps } from 'react-admin';

interface Props extends FieldProps {
  phonemeId?: any;
}

const PhonemeNameField: FC<Props> = ({ record, phonemeId }) => {
  const found = record?.phonemes?.find(
    (phoneme: any) => phoneme.id === phonemeId,
  );
  const { arpabet, ipa, text } = found;

  return record && arpabet ? (
    <div>{`${text} | ${ipa} | ${arpabet}`}</div>
  ) : null;
};

PhonemeNameField.defaultProps = {
  source: 'name',
  label: 'Name',
};

export default memo<Props>(PhonemeNameField);
