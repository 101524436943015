import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  Timeline,
  TimelineAction,
  TimelineRow,
  TimelineState,
} from '@xzdarcy/react-timeline-editor';
import { Button, Modal, Paper } from '@material-ui/core';
import { IVP_TYPES } from './constants';
import InteractableFactory from './InteractableFactory';
import InteractableCreationFormFactory from './InteractableCreationFormFactory';
import InteractableEditFormFactory from './forms/InteractableEditFormFactory';
import { useNotify } from 'react-admin';
import './timeline-editor.css';

const HumanReadableScale = (props: { scale: number }) => {
  const { scale } = props;
  const min = parseInt(`${scale / 60}`, 10);
  const second = `${scale % 60}`.padStart(2, '0');
  return <>{`${min}:${second}`}</>;
};

export default function VideoTimelineEditor({ record }: { record: any }) {
  const { duration, interactiveItems } = record;

  const domRef = useRef<HTMLDivElement>(null);
  const timelineState = useRef<TimelineState>(null);
  const notify = useNotify();

  const [creationModalOpen, setCreationModalOpen] = useState(false);
  const [creationRow, setCreationRow] = useState<TimelineRow | null>(null);
  const [creationTime, setCreationTime] = useState<number | null>(null);

  const [
    selectedVideoInteractable,
    setSelectedVideoInteractable,
  ] = useState<any>(null);

  const itemsBucketedByType = interactiveItems?.reduce(
    (acc: any, item: any) => {
      acc[item.graphicType] = acc[item.graphicType] || [];
      acc[item.graphicType].push(item);
      return acc;
    },
    {},
  );

  const constructEditorData = useMemo(() => {
    const editorData: TimelineRow[] = [];
    Object.keys(IVP_TYPES).forEach((key) => {
      // @ts-ignore
      const allActions = itemsBucketedByType?.[key] || [];

      const formattedActions: TimelineAction[] = allActions.map(
        (action: any) => ({
          id: action.id,
          start: action.isInMs ? action.from / 1000 : action.from,
          end: action.isInMs ? action.to / 1000 : action.to,
          data: action,
          flexible: key !== 'Practice',
          movable: key !== 'Practice',
        }),
      );

      editorData.push({
        id: key,
        actions: formattedActions,
      });
    });
    return editorData;
  }, [interactiveItems]);

  const [editorData, setEditorData] = useState<TimelineRow[]>(
    constructEditorData,
  );

  const openCreationModal = (row: TimelineRow, time: number) => {
    if (row.id === 'VideoMultipleChoice') {
      // if we add a few multiple choice via pgadmin, they'll still show up in the timeline
      // but we're not supporting their creation/management just yet
      notify('Multiple choice not built yet', 'error');
      return;
    }
    if (row.id === 'Practice') {
      // practice should be modified the old way. No reason to stymie development just to redo an existing thing
      notify('Please use the in video practice editor above', 'error');
      return;
    }

    setCreationModalOpen(true);
    setCreationRow(row);
    setCreationTime(Math.round(time)); // Math.round(time * 1e2) / 1e2);
  };

  const closeCreationModal = () => {
    setCreationModalOpen(false);
    setCreationRow(null);
    setCreationTime(null);
  };

  const openEditModal = (action: TimelineAction) => {
    setSelectedVideoInteractable(action);
  };

  const closeEditModal = () => {
    // check if the to and from time of the item differ from the underlying constructedEditorData
    // if theres a mismatch, alert the user because something wasnt saved
    const referenceData = selectedVideoInteractable?.data || {};
    let { from, to } = referenceData;
    if (referenceData.isInMs) {
      from /= 1000;
      to /= 1000;
    }

    if (
      selectedVideoInteractable.start !== from ||
      selectedVideoInteractable.end !== to
    ) {
      alert(
        'You made changes to the item time stamps. Make sure to click save in the popup',
      );
    }

    setSelectedVideoInteractable(null);
  };

  useEffect(() => {
    setEditorData(constructEditorData);
  }, [constructEditorData]);

  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: 15,
      }}
    >
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          position: 'relative',
          backgroundColor: '#191b1d',
        }}
      >
        <Timeline
          ref={timelineState}
          style={{ width: '100%', height: 700 }}
          onChange={(editedStuff: TimelineRow[]) => setEditorData(editedStuff)}
          editorData={editorData}
          effects={{}}
          scale={10} // 10 seconds per 100px
          scaleSplitCount={10}
          hideCursor
          rowHeight={100}
          dragLine
          maxScaleCount={duration / 10 + 1}
          // onContextMenuRow={(e, { row, time }) => {
          //   e.preventDefault();
          //   openCreationContextMenu(row, time);
          // }}
          onDoubleClickRow={(e, { row, time }) => {
            e.preventDefault();
            openCreationModal(row, time);
          }}
          onClickAction={(e, { action, row }) => {
            // row, time
            e.preventDefault();
            if (row.id === 'Practice') {
              notify('Please use the in video practice editor above', 'error');
            } else {
              openEditModal(action);
            }
          }}
          autoScroll
          startLeft={100}
          getActionRender={(action, row) => (
            <InteractableFactory action={action} row={row} />
          )}
          getScaleRender={(scale) => <HumanReadableScale scale={scale} />}
        />
        <div
          style={{
            position: 'absolute',
            pointerEvents: 'none',
            left: 0,
          }}
        >
          <div
            style={{
              width: '150px',
              marginTop: '42px',

              flex: '0 1 auto',
              overflow: 'overlay',
              padding: '0 10px',
            }}
            ref={domRef}
            onScroll={(e) => {
              const target = e.target as HTMLDivElement;
              timelineState?.current?.setScrollTop(target.scrollTop);
            }}
          >
            {Object.keys(IVP_TYPES).map((key) => {
              // @ts-ignore
              const label = IVP_TYPES?.[key];
              return (
                <div
                  key={label}
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    color: 'white',

                    height: 100,
                  }}
                >
                  {label}
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <Modal
        open={creationModalOpen}
        onClose={closeCreationModal}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Paper
          elevation={3}
          style={{
            backgroundColor: 'white',
            padding: '20px',
            minWidth: '40%',
            borderRadius: '5px',
            maxWidth: '80%',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <Button onClick={closeCreationModal} variant="contained">
              Close
            </Button>
          </div>

          <InteractableCreationFormFactory
            category={creationRow?.id}
            startTime={creationTime}
            record={record}
          />
        </Paper>
      </Modal>
      {/* editing modal */}
      <Modal
        open={!!selectedVideoInteractable}
        onClose={closeEditModal}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Paper
          elevation={3}
          style={{
            backgroundColor: 'white',
            padding: '20px',
            minWidth: '40%',
            borderRadius: '5px',
            maxWidth: '80%',
            position: 'relative',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              position: 'absolute',
              right: 20,
            }}
          >
            <Button onClick={closeEditModal} variant="contained">
              Close
            </Button>
          </div>
          <InteractableEditFormFactory
            interactable={selectedVideoInteractable}
          />
        </Paper>
      </Modal>
    </div>
  );
}
