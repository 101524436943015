import * as React from 'react';
import { Datagrid, List, TextField, ListProps, NumberField } from 'react-admin';
import { FC } from 'react';
import { Link, FieldProps, Filter, TextInput } from 'react-admin';

import { ReactElement } from 'react';

const RapidfireLinkField: FC<FieldProps> = (props) =>
  props.record ? (
    <Link
      to={`/content/rapidfire/${props.record.id}`}
      style={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      {props.record.name}
    </Link>
  ) : null;

const RapidfireFilter = (props: any) => (
  <Filter {...props}>
    <TextInput label="Tags" source="tags" defaultValue="" />
  </Filter>
);

const RapidfireList = (props: ListProps): ReactElement => (
  <List {...props} perPage={25} filters={<RapidfireFilter />}>
    <Datagrid optimized>
      <RapidfireLinkField label="Name" />
      <TextField source="description" label="Description" />
      <NumberField source="quantity" label="Quantity" />
    </Datagrid>
  </List>
);

export default RapidfireList;
