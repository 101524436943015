import { Typography } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';
import { Styles } from '@material-ui/styles/withStyles';
import { FC } from 'react';
import { Create, CreateProps, SimpleForm } from 'react-admin';

import WordFormFields from './WordFormFields';

export const styles: Styles<Theme, any> = {
  name: { display: 'inline-block' },
};

const WordCreate: FC<CreateProps> = (props: any) => (
  <Create {...props}>
    <SimpleForm>
      <Typography variant="h6" gutterBottom>
        Word
      </Typography>

      <WordFormFields />
    </SimpleForm>
  </Create>
);

export default WordCreate;
