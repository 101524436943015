import * as React from 'react';
import { FC, memo } from 'react';
import { FieldProps } from 'react-admin';

interface Props extends FieldProps {
  Unit?: any;
}

const UnitNameField: FC<Props> = ({ record }) => {
  const name = record?.Unit?.name;
  const unitNumber = record?.Unit?.unitNumber;
  const curriculumName = record?.Unit?.Curriculum?.name;

  return record && name ? (
    <div>
      {curriculumName}
      {curriculumName ? ' - ' : ''}({unitNumber}) {name}
    </div>
  ) : null;
};

UnitNameField.defaultProps = {
  source: 'name',
  label: 'Name',
};

export default memo<Props>(UnitNameField);
