import * as React from 'react';
import { FC } from 'react';
import {
  BooleanInput,
  Create,
  CreateProps,
  SelectInput,
  SimpleForm,
  TextInput,
} from 'react-admin';

import { Box } from '@material-ui/core';

async function getIcon(iconName: any) {
  try {
    // @ts-ignore
    const { [iconName]: iconModule } = await import('@phosphor-icons/react');

    // Check if the icon module exists
    if (!iconModule) {
      throw new Error(`Icon ${iconName} not found`);
    }

    return iconModule;
  } catch (error) {
    console.error(`Error loading icon ${iconName}:`, error);
    return null;
  }
}

const TopicConfigCreate: FC<CreateProps> = (props) => {
  const [PreviewIcon, setPreviewIcon] = React.useState<any>();

  return (
    <Create {...props}>
      <SimpleForm autoComplete="off">
        <TextInput
          autoFocus
          fullWidth
          source="title"
          label="Title"
          helperText={false}
          placeholder="The Family Dog"
        />
        <TextInput
          source="subtitle"
          label="Subtitle"
          fullWidth
          helperText={false}
          placeholder="Chat with a sibling in a coffee shop"
        />
        <TextInput
          source="category"
          label="Category"
          fullWidth
          helperText={false}
          placeholder="Work, School, Cooking, etc."
        />
        <TextInput
          source="counterpartyArticle"
          label="Counterparty Article"
          fullWidth
          helperText={false}
          placeholder="the, a, an, your, etc. (will read as 'Kai {ARTICLE} {COUNTERPARTY})"
        />
        <TextInput
          source="counterparty"
          label="Counterparty"
          fullWidth
          helperText={false}
          placeholder='e.g. "your boss", "your teacher", "your friend"'
        />
        <TextInput
          source="location"
          label="Location"
          fullWidth
          helperText={false}
          placeholder='e.g. "at work", "at school", "at home"'
          required
        />
        <TextInput
          source="conversation_topic"
          label="Conversation Topic"
          fullWidth
          helperText={false}
          placeholder='e.g. "your weekend plans", "your favorite food"'
          required
        />
        <BooleanInput
          source="requiresSubscription"
          label="Requires Pro Subscription?"
          helperText={false}
        />
        <Box display="flex" flexDirection="row">
          <TextInput
            source="icon"
            label="Icon"
            onChange={(event: any) => {
              const iconName = event.target.value;
              getIcon(iconName).then((icon) => {
                setPreviewIcon(icon);
              });
            }}
            helperText="https://phosphoricons.com/"
          />
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
          >
            {PreviewIcon ? <PreviewIcon size={40} /> : null}
            <div
              style={{
                marginRight: 10,
                marginLeft: 10,
                fontSize: '.8em',
                color: 'gray',
              }}
            >
              Icon Preview
            </div>
          </Box>
        </Box>
        <TextInput
          defaultValue={60}
          source="duration"
          style={{ display: 'none' }}
        />
        <TextInput
          fullWidth
          source="level"
          label="Level"
          helperText="The bucket that the scenario will be grouped into. Think of this as the 'Unit' of the scenario. Used to organize scenarios in the UI, not sent to the model."
        />
        <SelectInput
          fullWidth
          source="difficulty"
          label="Difficulty"
          helperText="The order of the scenario within the level. Mainly used for sorting scenarios within a level, not shown to the user, represented as integers."
          choices={[
            { id: '1', name: 'Easy' },
            { id: '2', name: 'Medium' },
            { id: '3', name: 'Hard' },
            { id: '4', name: 'Very Hard' },
          ]}
        />
        <TextInput
          fullWidth
          source="seedingText"
          label="Seeding Text"
          helperText="Fed to GPT before the first chat message. Use this to steer the conversation to this Scenario specifically"
        />
      </SimpleForm>
    </Create>
  );
};

export default TopicConfigCreate;
