/**
 * @generated SignedSource<<e00a9237eeacb94476cf97cd73ddfb2d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MultipleChoiceOptionsEditor_option$data = {
  readonly audioUrl: string | null | undefined;
  readonly id: string | null | undefined;
  readonly isCorrectAnswer: boolean | null | undefined;
  readonly order: number | null | undefined;
  readonly text: string | null | undefined;
  readonly " $fragmentType": "MultipleChoiceOptionsEditor_option";
};
export type MultipleChoiceOptionsEditor_option$key = {
  readonly " $data"?: MultipleChoiceOptionsEditor_option$data;
  readonly " $fragmentSpreads": FragmentRefs<"MultipleChoiceOptionsEditor_option">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MultipleChoiceOptionsEditor_option",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "text",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "audioUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "order",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isCorrectAnswer",
      "storageKey": null
    }
  ],
  "type": "MultipleChoiceOption",
  "abstractKey": null
};

(node as any).hash = "2f0e12a0103053175c779566241f1841";

export default node;
