import { Box, Tooltip } from '@material-ui/core';
import FacebookIcon from '@material-ui/icons/Facebook';
import { Table, useTable } from 'ka-table';
import {
  ActionType,
  DataType,
  EditingMode,
  FilteringMode,
  SortDirection,
  SortingMode,
} from 'ka-table/enums';
import { Column } from 'ka-table/models';
import { ICellTextProps } from 'ka-table/props';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
// @ts-ignore
import { CSVLink } from 'react-csv';

import api from '../api';
import usePrevious from '../utils/hooks/usePrevious';
import './styles.css';

const siteSourcesFacebook = ['fb', 'ig', 'msg', 'an'];
const siteSourcesGoogle = ['adwords', 'youtube', 'display_network'];
const OPTION_KEY = 'state-storing-ads-table-option';
const savedOptions = { ...JSON.parse(localStorage.getItem(OPTION_KEY) || '0') };

const FacebookMarkedCell: React.FC<ICellTextProps> = ({ column, rowData }) => (
  <Tooltip title="Data linked" arrow>
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <FacebookIcon color="primary" style={{ marginRight: '.25rem' }} />
      {rowData[column.key]}
    </div>
  </Tooltip>
);

// little shocked there isnt a google icon in our material ui (there is one in mui)
const GoogleMarkedCell: React.FC<ICellTextProps> = ({ column, rowData }) => (
  <Tooltip title="Data linked" arrow>
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <svg
        style={{ marginRight: '.25rem' }}
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width="23"
        height="23"
        viewBox="0 0 48 48"
      >
        <path
          fill="#FFC107"
          d="M43.611,20.083H42V20H24v8h11.303c-1.649,4.657-6.08,8-11.303,8c-6.627,0-12-5.373-12-12c0-6.627,5.373-12,12-12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C12.955,4,4,12.955,4,24c0,11.045,8.955,20,20,20c11.045,0,20-8.955,20-20C44,22.659,43.862,21.35,43.611,20.083z"
        ></path>
        <path
          fill="#FF3D00"
          d="M6.306,14.691l6.571,4.819C14.655,15.108,18.961,12,24,12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C16.318,4,9.656,8.337,6.306,14.691z"
        ></path>
        <path
          fill="#4CAF50"
          d="M24,44c5.166,0,9.86-1.977,13.409-5.192l-6.19-5.238C29.211,35.091,26.715,36,24,36c-5.202,0-9.619-3.317-11.283-7.946l-6.522,5.025C9.505,39.556,16.227,44,24,44z"
        ></path>
        <path
          fill="#1976D2"
          d="M43.611,20.083H42V20H24v8h11.303c-0.792,2.237-2.231,4.166-4.087,5.571c0.001-0.001,0.002-0.001,0.003-0.002l6.19,5.238C36.971,39.205,44,34,44,24C44,22.659,43.862,21.35,43.611,20.083z"
        ></path>
      </svg>
      {rowData[column.key]}
    </div>
  </Tooltip>
);

function replaceNullColumns(jsonData: any) {
  const nullColumns = ['adCreative', 'platform', 'campaign', 'adGroup'];
  const nullColumnsData = jsonData.json.map((item: any) => {
    const newItem = { ...item };
    nullColumns.forEach((col) => {
      if (newItem[col] === null) {
        newItem[col] = '';
      }
    });
    return newItem;
  });
  return nullColumnsData;
}

export default function AdDataTable({
  startDate,
  endDate,
  shouldUseDefaultColumnOrder = false,
  globalFilter,
}: {
  startDate: string;
  endDate: string;
  shouldUseDefaultColumnOrder?: boolean;
  globalFilter?: string;
}) {
  const [data, setData] = useState([]);

  const prevStartDate = usePrevious(startDate);
  const prevEndDate = usePrevious(endDate);

  const table = useTable({
    onDispatch: async (action, newProps) => {
      if (action.type === ActionType.ComponentDidMount) {
        table.showLoading();
        try {
          const jsonData = await api.getAdClickDashboard(startDate, endDate);
          // replace nulls with empty strings for columns that are column-header-filterable

          const nullColumnsData = replaceNullColumns(jsonData);
          setData(nullColumnsData);
        } catch (e) {
          console.log(e);
        }
        table.hideLoading();
      }
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { data, ...settingsWithoutData } = newProps;
      localStorage.setItem(OPTION_KEY, JSON.stringify(settingsWithoutData));
    },
  });

  const headerFilterListItems = (
    {
      data,
    }: {
      data?: any[] | undefined;
    },
    key: string,
  ) => {
    const validData =
      data
        ?.filter(
          (item) =>
            item[key] !== null &&
            item[key] !== undefined &&
            typeof item[key] === 'string' &&
            item[key] !== '',
        )
        ?.map((item) => item[key].trim()) || [];

    const uniqueData = _.uniq(validData);

    return uniqueData;
  };

  const defaultColumns = [
    {
      key: 'adCreative',
      title: 'Creative Name',
      dataType: DataType.String,
      isFilterable: true,
      width: 200,
      headerFilterListItems: ({ data }: { data?: any[] | undefined }) =>
        headerFilterListItems({ data }, 'adCreative'),
    },
    {
      key: 'platform',
      title: 'Platform',
      dataType: DataType.String,
      isFilterable: true,
      width: 150,
      headerFilterListItems: ({ data }: { data?: any[] | undefined }) =>
        headerFilterListItems({ data }, 'platform'),
    },
    {
      key: 'campaign',
      title: 'Campaign',
      dataType: DataType.String,
      isFilterable: true,
      width: 150,
      headerFilterListItems: ({ data }: { data?: any[] | undefined }) =>
        headerFilterListItems({ data }, 'campaign'),
    },
    {
      key: 'adGroup',
      title: 'Ad group',
      dataType: DataType.String,
      isFilterable: true,
      width: 150,
      headerFilterListItems: ({ data }: { data?: any[] | undefined }) =>
        headerFilterListItems({ data }, 'adGroup'),
    },
    {
      key: 'anon',
      title: 'Clicks',
      dataType: DataType.Number,
      sortDirection: SortDirection.Descend,
      isFilterable: false,
      width: 150,
    },
    {
      key: 'clicks', // facebook reported clicks
      title: 'Reported Clicks',
      dataType: DataType.Number,
      isFilterable: false,
      width: 150,
    },
    {
      key: 'uniqueClicks', // facebook reported clicks
      title: 'Reported Unique Clicks',
      dataType: DataType.Number,
      isFilterable: false,
      width: 150,
    },
    {
      key: 'impressions',
      title: 'Impressions',
      dataType: DataType.Number,
      isFilterable: false,
      width: 150,
    },
    {
      key: 'spend',
      title: 'Total Spend',
      dataType: DataType.Number,
      isFilterable: false,
      isMoney: true,
      width: 150,
    },
    {
      key: 'cpm',
      title: 'CPM',
      dataType: DataType.Number,
      isFilterable: false,
      width: 150,
    },
    {
      key: 'reportedCostPerClick',
      title: 'Reported CPC',
      dataType: DataType.Number,
      isFilterable: false,
      isMoney: true,
      width: 150,
    },
    {
      key: 'internalCostPerClick',
      title: 'Internal CPC',
      dataType: DataType.Number,
      isFilterable: false,
      isMoney: true,
      width: 150,
    },
    {
      key: 'download_linked',
      title: 'App Installs',
      dataType: DataType.Number,
      isFilterable: false,
      width: 150,
    },
    {
      key: 'costPerInstall',
      title: 'Cost / Install',
      dataType: DataType.Number,
      isFilterable: false,
      isMoney: true,
      width: 150,
    },
    {
      key: 'profile_linked',
      title: 'Registrations',
      dataType: DataType.Number,
      isFilterable: false,
      width: 150,
    },
    {
      key: 'trials',
      title: 'Trials',
      dataType: DataType.Number,
      isFilterable: false,
      width: 150,
    },
    {
      key: 'costPerTrialStart',
      title: 'Cost / Trial',
      dataType: DataType.Number,
      isFilterable: false,
      isMoney: true,
      width: 150,
    },
    {
      key: 'failed_payments',
      title: 'Failed Payments',
      dataType: DataType.Number,
      isFilterable: false,
      width: 150,
    },
    {
      key: 'canceled_trials',
      title: 'Canceled Trials',
      dataType: DataType.Number,
      isFilterable: false,
      width: 150,
    },
    {
      key: 'subscriptions',
      title: 'Subscriptions',
      dataType: DataType.Number,
      isFilterable: false,
      width: 150,
    },
    {
      key: 'costPerSubscription',
      title: 'Cost / Subscription',
      dataType: DataType.Number,
      isFilterable: false,
      isMoney: true,
      width: 150,
    },

    {
      key: 'refunds',
      title: 'Refunds',
      dataType: DataType.Number,
      isFilterable: false,
      width: 150,
    },
  ];

  const extendedFilterData = (data: any, filterValue: string | undefined) =>
    data.filter((item: any) => {
      if (filterValue === undefined) {
        return true;
      }
      const filterText = filterValue.toLowerCase();
      const text =
        `${item.adCreative} ${item.platform} ${item.campaign} ${item.adGroup}`.toLowerCase();
      return text.indexOf(filterText) !== -1;
    });

  useEffect(() => {
    if (
      (startDate !== prevStartDate || endDate !== prevEndDate) &&
      prevStartDate !== undefined &&
      prevEndDate !== undefined
    ) {
      (async () => {
        table.showLoading();
        const jsonData = await api.getAdClickDashboard(startDate, endDate);
        const nullColumnsData = replaceNullColumns(jsonData);
        setData(nullColumnsData);
        table.hideLoading();
      })();
    }
  }, [startDate, endDate, data]);

  useEffect(() => {
    if (shouldUseDefaultColumnOrder) {
      localStorage.removeItem(OPTION_KEY);
      table.changeProps({
        ...table.props,
        columns: defaultColumns,
      });
    }
  }, [shouldUseDefaultColumnOrder]);

  return (
    <div
      style={{
        marginTop: '.5rem',
        flex: 1,
        overflowX: 'auto',
      }}
    >
      <Table
        table={table}
        filteringMode={FilteringMode.HeaderFilter}
        paging={{
          enabled: true,
          pageSize: 20,
          pageIndex: 0,
        }}
        extendedSort={(_data, columns) => {
          let sortedColumns = columns.filter((c) => c.sortDirection);
          if (sortedColumns.length === 0) {
            return _data;
          }
          sortedColumns = _.orderBy(sortedColumns, ['sortIndex'], ['asc']);
          const iteratee = sortedColumns.map((c) => c.key);
          const order = sortedColumns.map((c) =>
            c.sortDirection === SortDirection.Ascend ? 'asc' : 'desc',
          );
          return _.orderBy(_data, iteratee, order);
        }}
        sortingMode={SortingMode.MultipleTripleStateRemote}
        data={data}
        editingMode={EditingMode.None}
        rowKeyField="id"
        columnResizing
        columnReordering
        extendedFilter={(data) => extendedFilterData(data, globalFilter)}
        columns={defaultColumns}
        {...savedOptions}
        format={({ column, value }) => {
          if (value === undefined) {
            return value;
          }
          if (column.key === 'impressions') {
            return value.toLocaleString();
          }

          if (
            column.key === 'spend' ||
            column.key === 'cpm' ||
            column.key.toLowerCase().includes('cost')
          ) {
            if (value === null) {
              return '';
            }
            return `$${value.toFixed(2)}`;
          }

          if (column.dataType === DataType.Date) {
            return (
              value &&
              value.toLocaleDateString('en', {
                month: '2-digit',
                day: '2-digit',
                year: 'numeric',
              })
            );
          }
        }}
        childComponents={{
          cellText: {
            content: ({ column, rowData, ...props }) => {
              switch (column.key) {
                case 'adCreative':
                  if (
                    rowData.impressions !== null &&
                    siteSourcesFacebook.indexOf(rowData.platform) !== -1
                  ) {
                    return (
                      <FacebookMarkedCell
                        {...props}
                        column={column}
                        rowData={rowData}
                      />
                    );
                  }
                  if (
                    rowData.impressions !== null &&
                    siteSourcesGoogle.indexOf(rowData.platform) !== -1
                  ) {
                    return (
                      <GoogleMarkedCell
                        {...props}
                        column={column}
                        rowData={rowData}
                      />
                    );
                  }

                  break;

                default:
                  break;
              }
            },
          },
          summaryCell: {
            content: ({ column }: { column: Column | any }) => {
              switch (column.dataType) {
                case DataType.Number:
                  return (
                    <>
                      <b>
                        Total: {column.isMoney ? '$' : ''}
                        {data
                          .reduce((acc, curr) => {
                            if (curr[column.key] === undefined) {
                              return acc;
                            }
                            return acc + Number(curr[column.key]);
                          }, 0)
                          .toFixed(column.isMoney ? 2 : 0)}
                      </b>
                      <br />
                    </>
                  );
                default:
                  return <></>;
              }
            },
          },
        }}
      />
      <Box display="flex" justifyContent="flex-end">
        <CSVLink
          data={data}
          headers={defaultColumns.map((c: any) => ({
            label: c.title,
            key: c.key,
          }))}
          filename={`ads-dash-${startDate}-to-${endDate}.csv`}
          enclosingCharacter=""
          separator=";"
        >
          Download .csv
        </CSVLink>
      </Box>
    </div>
  );
}
