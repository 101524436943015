import {
  InputLabel,
  Modal,
  Button as NormalButton,
  TextField as NormalTextField,
  Select,
} from '@material-ui/core';
import { Theme, makeStyles } from '@material-ui/core/styles';
import NotificationsIcon from '@material-ui/icons/Notifications';
import { Styles } from '@material-ui/styles/withStyles';
import moment from 'moment';
import * as React from 'react';
import {
  BooleanInput,
  Button,
  CreateButton,
  Datagrid,
  DateField,
  DateInput,
  ExportButton,
  Filter, // NumberInput,
  FunctionField,
  List,
  SelectInput,
  TextField,
  TextInput,
  TopToolbar,
  sanitizeListRestProps,
  useListContext,
} from 'react-admin';

import api from '../api';
import CurriculumLinkField from './CurriculumLinkField';
import UserLinkField from './UserLinkField';

export const PHONE_OS_LIST = [
  { id: 'ios', name: 'iOS' },
  { id: 'android', name: 'Android' },
  { id: 'web', name: 'Web' },
];

export const USER_TYPE_LIST = [
  { id: 'admin', name: 'Admin (Full Access)' },
  { id: 'admin_read_only', name: 'Admin (Read Only)' },
  { id: 'user', name: 'User' },
];

export const SUB_STATUS_LIST = [
  { id: 'subscribed', name: 'Subscribed', category: 'good' },
  {
    id: 'subscribed_not_renew',
    name: 'Subscribed, Not Renewing',
    category: 'medium',
  },
  { id: 'trial', name: 'Trial', category: 'good' },
  // { id: 'trial_no_credit_card', name: 'Trial (No CC)' },

  { id: 'failed_payment', name: 'Failed Payment', category: 'bad' },
  { id: 'trial_not_renew', name: 'Trial, Not Renewing', category: 'medium' },
  { id: 'expired_trial', name: 'Expired Trial', category: 'bad' },
  { id: 'expired_paid', name: 'Expired Paid', category: 'bad' },
  { id: 'refunded', name: 'Refunded', category: 'bad' },
  { id: 'never_subscribed', name: 'Never Subscribed', category: 'neutral' },
  { id: 'promo', name: 'Promo', category: 'good' },
];

export const subscriptionOptions = {
  // prod
  // ios
  'com.wellocution.iosapp.subscription.oneyear': 'Annual, iOS, $89.99',
  'com.wellocution.iosapp.subscription.monthlyten': 'Monthly, iOS, $14.99',
  'com.wellocution.androidapp.subscription.monthlyfifteen.09.01.2021':
    'Monthly, Android, $14.99',
  'com.wellocution.androidapp.subscription.oneyear.09.01.2021':
    'Annual, Android, $89.99',

  prod_Kc7eKEzKeaswAi: 'Monthly, Stripe, $14.99',
  prod_Kc7d6WImV7Qnwz: 'Annual, Stripe, $89.99',
  prod_NM9x0EYGEaHizv: 'Monthly, Stripe, $24.99',
  prod_NM9v2VmMxEZHk4: 'Annual, Stripe, $149.99',
  prod_OcngDEHhqC1tQb: 'Annual Pro, Stripe, $199.99',
  prod_NMytXHxNxYk2FO: 'Annual, Stripe, $99.99',
  prod_NfeGhS0AiJyXds: 'Annual, Stripe, $119.99',

  'com.wellocution.iosapp.subscription.onemonth':
    'Monthly, iOS, $9.99, original',
  'com.wellocution.iosapp.subscription.onemonth.nocreditcardfreetrial':
    'Monthly, iOS, $14.99, no trial',
  'com.wellocution.iosapp.subscription.monthone': 'Monthly, iOS, $24.99',
  'com.wellocution.iosapp.subscription.monthone.nofreetrial':
    'Monthly, iOS, $24.99, no trial',
  'com.wellocution.iosapp.subscription.monthlyplan': 'Monthly, iOS, $19.99',
  'com.wellocution.iosapp.subscription.threemonths': '3 months, iOS, $24.99',
  'com.wellocution.iosapp.subscription.sixmonths': '6 months, iOS, $59.99',
  'com.wellocution.iosapp.subscription.oneyear.nocreditcardfreetrial':
    'Annual, iOS, $89.99, no trial',
  'com.wellocution.iosapp.subscription.yearone': 'Annual, iOS, $149.99',
  'com.wellocution.iosapp.subscription.yearonepro': 'Annual Pro, iOS, $199.99',
  'com.wellocution.iosapp.subscription.yearone.nofreetrial':
    'Annual, iOS, $149.99, no trial',
  'com.wellocution.iosapp.subscription.yearlyplan': 'Annual, iOS, $119.99',
  'com.wellocution.iosapp.subscription.yearplan': 'Annual, iOS, $119.99',
  'com.wellocution.iosapp.subscription.subyear': 'Annual, iOS, $134.99',
  'com.wellocution.iosapp.subscription.yearsub': 'Annual, iOS, $99.99',
  'com.wellocution.iosapp.subscription.monththree': '3 months, iOS, $79.99',
  'com.wellocution.iosapp.subscription.oneyearfor168': 'Annual, iOS, $167.99',
  'com.wellocution.iosapp.subscription.onemonthfor30': 'Monthly, iOS, $29.99',

  // android
  'com.wellocution.android.oneyear.230214': 'Annual, Android, $149.99',
  'com.wellocution.android.yearonepro': 'Annual Pro, Android, $199.99',
  'com.wellocution.android.onemonth.230214': 'Monthly, Android, $24.99',
  'com.wellocution.android.oneyearnt.230214':
    'Annual, Android, $149.99, no trial',
  'com.wellocution.androidapp.subscription.monthlyten':
    'Monthly, Android, $9.99, original',
  'com.wellocution.androidapp.subscription.onemonth':
    'Monthly, Android, $14.99, original',
  'com.wellocution.androidapp.subscription.onemonth.nocreditcardfreetrial':
    'Monthly, Android, $14.99, no trial',
  'com.wellocution.androidapp.subscription.threemonths':
    '3 months, Android, $24.99',
  'com.wellocution.androidapp.subscription.sixmonths':
    '6 months, Android, $44.99',
  'com.wellocution.androidapp.subscription.sixmonths.09.01.2021':
    '6 months, Android, $59.99',
  'com.wellocution.androidapp.subscription.oneyear': 'Annual, Android, $69.99',
  'com.wellocution.androidapp.subscription.oneyear.nocreditcardfreetrial':
    'Annual, Android, $89.99, no trial',
  'com.wellocution.android.oneyear30.230214': 'Annual, Android, $99.99', // $99.99 USD for discount from $149.99'
  'com.wellocution.android.oneyear20.230407': 'Annual, Android, $119.99', // $119.99 USD for discount from $149.99'

  // prod
  prod_JgTkoK6CHdi7vA: '6 months, Stripe, $49.99',
  none: 'None',
};

const devSubscriptionOptions = {
  // dev
  // ios
  'com.wellocution.subscription.onemonth': 'Monthly, iOS, DEV',
  'com.wellocution.subscription.onemonth.nocreditcardfreetrial':
    'Monthly, iOS, DEV, no trial',
  'com.wellocution.subscription.threemonths': '3 months, iOS, DEV',
  'com.wellocution.subscription.oneyear': 'Annual, iOS, DEV',
  'com.wellocution.subscription.oneyear.nocreditcardfreetrial':
    'Annual, iOS, DEV, no trial',
  'com.wellocution.pro.onemonth': 'Monthly, iOS, DEV, Pro',
  'com.wellocution.pro.threemonths': '3 months, iOS, DEV, Pro',
  'com.wellocution.subscription.monththree': '3 months, iOS, DEV',

  // android
  'com.wellocution.android.subscription.onemonth': 'Monthly, Android, DEV',
  'com.wellocution.android.subscription.threemonths': '3 months, Android, DEV',
  'com.wellocution.android.prosubscription.onemonth':
    'Monthly, Android, DEV, Pro',
  'com.wellocution.android.prosubscription.threemonths':
    '3 months, Android, DEV, Pro',
  'com.wellocution.android.subscription.oneyear': 'Annual, Android, DEV',

  // Stripe
  // dev
  prod_KZWCYuwoKxxdg5: 'Monthly, Stripe, $14.99, DEV',
  prod_KZWDOXFyggkdOx: 'Annual, Stripe, $89.99, DEV',
};

const subscriptionOptionsToUse =
  process.env.NODE_ENV === 'development' &&
  !window.location.href.includes('localhost:3000')
    ? devSubscriptionOptions
    : subscriptionOptions;

const subscriptionOptionsList = Object.keys(subscriptionOptionsToUse).map(
  (option: string) => ({
    id: option,
    // @ts-ignore please
    name: subscriptionOptionsToUse[option],
  }),
);

const subscriptionDurationsList = [
  {
    id: subscriptionOptionsList
      .filter((i) => i.name.includes('None'))
      .map((i) => i.id)
      .join(','),
    name: 'None',
  },
  {
    id: subscriptionOptionsList
      .filter((i) => i.name.includes('Monthly'))
      .map((i) => i.id)
      .join(','),
    name: 'Monthly',
  },
  {
    id: subscriptionOptionsList
      .filter((i) => i.name.includes('Annual'))
      .map((i) => i.id)
      .join(','),
    name: 'Annual',
  },
  {
    id: subscriptionOptionsList
      .filter((i) => i.name.includes('3 months'))
      .map((i) => i.id)
      .join(','),
    name: '3 months',
  },
  {
    id: subscriptionOptionsList
      .filter((i) => i.name.includes('6 months'))
      .map((i) => i.id)
      .join(','),
    name: '6 months',
  },
];

export const subStatusCustomRender = (record: any) => {
  const { subStatus, startedFreeTrial, UniqueCode } = record;
  const activeSubscriptionIdentifier =
    record?.subscriptionData?.activeSubscriptions?.subscription
      ?.product_identifier;

  const entitlementSubscriptionIdentifier =
    record?.subscriptionData?.entitlements?.subscription?.product_identifier;

  const identifier =
    activeSubscriptionIdentifier || entitlementSubscriptionIdentifier;
  const subscriptionData = record?.subscriptionData?.subscriptions[identifier];
  const isPromotional = subscriptionData?.store === 'promotional';
  const hasUniqueCode = UniqueCode?.id;

  let componentToReturn;
  switch (subStatus) {
    case 'never_subscribed':
      componentToReturn = <div>❌</div>;
      break;

    case 'failed_payment':
      componentToReturn = (
        <>
          ❓, Failed payment:{' '}
          {moment(subscriptionData?.billing_issues_detected_at).format(
            'MM/DD/YY',
          )}
        </>
      );
      break;

    case 'promo':
      componentToReturn = `Promo${hasUniqueCode ? `(c)` : ``}`;
      break;

    case 'expired_trial':
      componentToReturn = (
        <div>
          👎
          {`, Trial ${startedFreeTrial ? `(No CC)` : ``} exp: ${moment(
            subscriptionData?.expires_date ||
              moment(startedFreeTrial).add(7, 'days'),
          ).format('MM/DD/YY')}`}
        </div>
      );
      break;

    case 'expired_paid':
      componentToReturn = (
        <div>
          👎
          {`, ${isPromotional ? `Promo` : `Paid`} (exp: ${moment(
            subscriptionData.expires_date,
          ).format('MM/DD/YY')})`}
        </div>
      );
      break;

    case 'refunded':
      componentToReturn = (
        <div>
          👎 💸
          {`, ${`Paid`} (exp: ${moment(subscriptionData.expires_date).format(
            'MM/DD/YY',
          )})`}
        </div>
      );
      break;

    case 'trial_not_renew':
      componentToReturn = (
        <div>
          Trial, cancelled
          <br />
          👎{' '}
          {moment(subscriptionData?.unsubscribe_detected_at).format('MM/DD/YY')}
          <br />❌ {moment(subscriptionData?.expires_date).format('MM/DD/YY')}
        </div>
      );
      break;

    case 'subscribed_not_renew':
      componentToReturn = (
        <div>
          ✅ {moment(subscriptionData?.purchase_date).format('MM/DD/YY')}
          <>
            <br />
            👎{' '}
            {moment(subscriptionData?.unsubscribe_detected_at).format(
              'MM/DD/YY',
            )}
            <br />❌ {moment(subscriptionData?.expires_date).format('MM/DD/YY')}
          </>
        </div>
      );
      break;

    case 'trial':
      componentToReturn = (
        <div>
          Trial, {startedFreeTrial ? `(No CC)` : null}{' '}
          <>
            👍 conv:{' '}
            {moment(
              subscriptionData?.expires_date ||
                moment(startedFreeTrial).add(7, 'days'),
            ).format('MM/DD/YY')}
          </>
        </div>
      );
      break;

    case 'subscribed':
      componentToReturn = (
        <div>
          ✅ {moment(subscriptionData?.purchase_date).format('MM/DD/YY')}
          <div>
            {moment(subscriptionData?.purchase_date).isAfter(
              moment(subscriptionData?.original_purchase_date).add(1, 'month'),
            ) && `(renewal)`}
          </div>
        </div>
      );
      break;

    default:
      break;
  }
  return componentToReturn;
};

const UserFilter = (props: any) => (
  <Filter {...props}>
    <TextInput label="Email" source="email" defaultValue="" />
    <TextInput label="Phone Number" source="phoneNumber" defaultValue="" />
    <TextInput label="First Name" source="firstName" defaultValue="" />
    <TextInput label="Last Name" source="lastName" defaultValue="" />
    <TextInput label="IP Address" source="ipAddress" defaultValue="" />
    <TextInput label="Push Token" source="pushToken" defaultValue="" />
    <BooleanInput
      label="Push Allowed"
      source="pushPermission"
      defaultValue={false}
    />
    <BooleanInput
      label="Audio Allowed"
      source="audioPermission"
      defaultValue={false}
    />
    <SelectInput source="phoneOS" label="Phone OS" choices={PHONE_OS_LIST} />
    <SelectInput
      source="originalPhoneOS"
      label="Original Phone OS"
      choices={PHONE_OS_LIST}
    />
    <SelectInput source="role" label="Role" choices={USER_TYPE_LIST} />
    <BooleanInput label="Active" source="isActive" defaultValue={false} />
    <TextInput label="Referral Source" source="referral" defaultValue="" />
    <TextInput label="Country Code" source="country_code" defaultValue="" />
    <TextInput label="Region" source="region" defaultValue="" />
    <TextInput label="City" source="city" defaultValue="" />
    <TextInput label="Language" source="language" defaultValue="" />
    <TextInput
      label="Feature Flags Include"
      source="featureFlagsListInclude"
      defaultValue=""
    />
    <TextInput
      label="Feature Flags Exclude"
      source="featureFlagsListExclude"
      defaultValue=""
    />
    <BooleanInput
      label="Trial Or Subscribed"
      source="isSubscriber"
      defaultValue={false}
    />
    <SelectInput
      label="Sub Status"
      source="subStatus"
      choices={SUB_STATUS_LIST}
      defaultValue={null}
    />
    <SelectInput
      label="Product"
      source="subProduct"
      choices={subscriptionOptionsList}
      defaultValue={null}
    />
    <SelectInput
      label="Product Duration"
      source="productDuration"
      choices={subscriptionDurationsList}
      defaultValue={null}
    />
    <BooleanInput
      label="Skipped Trial?"
      source="skippedTrial"
      defaultValue={false}
    />
    <DateInput label="Joined From (UTC)" source="createdAtAfter" />
    <DateInput label="Joined To (UTC)" source="createdAtBefore" />

    <DateInput label="Last Seen From (UTC)" source="lastSeenAfter" />
    <DateInput label="Last Seen To (UTC)" source="lastSeenBefore" />

    <BooleanInput label="Apple Login?" source="appleId" defaultValue={false} />
    <BooleanInput
      label="Facebook Login?"
      source="facebookId"
      defaultValue={false}
    />
    <BooleanInput
      label="Google Login?"
      source="googleId"
      defaultValue={false}
    />
    <BooleanInput
      label="Redeemed Unique Code?"
      source="redeemedUniqueCode"
      defaultValue={false}
    />

    {/* <NumberInput label="Video Progress >=" source="videoProgressNumGte" />
    <NumberInput label="Video Progress <=" source="videoProgressNumLte" />

    <NumberInput label="Practice Progress >=" source="practiceProgressNumGte" />
    <NumberInput label="Practice Progress <=" source="practiceProgressNumLte" />

    <NumberInput label="Activity % >=" source="activityPercentGte" />
    <NumberInput label="Activity % <=" source="activityPercentLte" /> */}
  </Filter>
);

export const styles: Styles<Theme, any> = {
  paper: {
    position: 'absolute',
    width: '75%',
    height: 500,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    margin: 'auto',
    backgroundColor: 'white',
    padding: 40,
    overflow: 'scroll',
  },
};

const useStyles = makeStyles(styles);

const ListActions = (props: any) => {
  const classes = useStyles(props);
  const { className, filters, ...rest } = props;
  const {
    currentSort,
    resource,
    displayedFilters,
    filterValues,
    // hasCreate,
    basePath,
    // selectedIds,
    showFilter,
    total,
  } = useListContext();

  const [modalOpen, setModalOpen] = React.useState(false);

  const [title, setTitle] = React.useState('');
  const [body, setBody] = React.useState('');
  const [notificationId, setNotificationId] = React.useState('');
  const [discountCode, setDiscountCode] = React.useState('');
  const [pushUrl, setPushUrl] = React.useState('');

  const bringUpModalForPushNotificationSend = React.useCallback(() => {
    setModalOpen(true);
  }, [displayedFilters]);

  const closeModal = React.useCallback(() => {
    setModalOpen(false);
  }, []);

  const submitCopy = React.useCallback(async () => {
    if (!title && !body) {
      alert('Provide either a title or body, or both.');
      return;
    }

    if (!notificationId) {
      alert(
        'Please enter a notificationId so you can uniquely identify this sending of push notifications in the Notification Logs table.',
      );
      return;
    }

    if (
      window.confirm(
        `Are you sure you want to send this push notification to ${total} users?`,
      )
    ) {
      try {
        const result = await api.sendPushNotificationWithFilter({
          filterValues,
          title,
          body,
          notificationId,
          discountCode,
          pushUrl,
        });

        alert(`${result?.json?.status}`);
        if (result?.json?.success) {
          closeModal();
        }
      } catch (err) {
        alert(`Error occurred.`);
      }
    }
  }, [
    total,
    filterValues,
    title,
    body,
    notificationId,
    discountCode,
    pushUrl,
    closeModal,
  ]);

  const hasFilters = React.useMemo(
    () => !!Object.keys(filterValues)?.length,
    [filterValues],
  );

  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      <Modal open={modalOpen} onClose={closeModal}>
        <div className={classes.paper}>
          <div>
            Provide the following data to send push notifications to {total}{' '}
            users (if they gave push permission)
            {hasFilters &&
              `, using the following user filters ${JSON.stringify(
                filterValues,
              )}`}
            :
          </div>
          <div style={{ paddingTop: 20 }}>
            <NormalTextField
              id="title"
              label="Title"
              type="text"
              value={title}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(event) => setTitle(event.target.value)}
              style={{ marginRight: 10 }}
              fullWidth
            />
          </div>
          <div style={{ paddingTop: 20 }}>
            <NormalTextField
              id="body"
              label="Body"
              type="text"
              value={body}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(event) => setBody(event.target.value)}
              style={{ marginRight: 10 }}
              fullWidth
            />
          </div>
          <div style={{ paddingTop: 20 }}>
            <NormalTextField
              id="notificationId"
              label="Notification ID (Enter a unique ID for filtering in Notification Logs Table)"
              type="text"
              value={notificationId}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(event) => setNotificationId(event.target.value)}
              style={{ marginRight: 10 }}
              fullWidth
            />
          </div>
          <div
            style={{
              paddingTop: 20,
              display: 'flex',
              flexDirection: 'row',
              flex: 1,
            }}
          >
            <div style={{ flex: 2 }}>
              <InputLabel id="demo-simple-select-label">
                Discount Code
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={discountCode}
                onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                  setDiscountCode(event.target.value as string);
                }}
                style={{ width: '100%' }}
                label="Discount Code"
              >
                {[
                  { id: '', name: '', selected: true },
                  { id: 'FEEDBACK20', name: 'Feedback 20%' },
                  { id: 'FEEDBACK50', name: 'Feedback 50%' },
                  { id: 'YC', name: 'YC 25%' },
                  { id: 'NEWYEAR', name: 'New Year 25%' },
                  { id: 'RESOLUTION', name: 'Resolution 50%' },
                  { id: 'HOLIDAY25', name: 'Holiday 25%' },
                  { id: 'BREX20', name: 'Brex 20%' },
                ].map((option) => (
                  <option value={option.id} selected={option.selected}>
                    {option.name}
                  </option>
                ))}
              </Select>
            </div>
            <div
              style={{
                flex: 1,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              - OR -
            </div>
            <div style={{ flex: 2 }}>
              <NormalTextField
                id="pushUrl"
                label="Url for push notification to open to"
                type="text"
                value={pushUrl}
                placeholder="https://"
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(event) => setPushUrl(event.target.value)}
                style={{ marginRight: 10 }}
                fullWidth
              />
            </div>
          </div>

          <div style={{ paddingTop: 20 }}>
            <NormalButton
              onClick={submitCopy}
              variant="contained"
              color="primary"
            >
              Submit
            </NormalButton>
          </div>
        </div>
      </Modal>
      <Button onClick={bringUpModalForPushNotificationSend} label="Send Push">
        <NotificationsIcon />
      </Button>
      {filters &&
        React.cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      <CreateButton basePath={basePath} />
      <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filterValues={filterValues}
        maxResults={10000}
      />
    </TopToolbar>
  );
};

export const UserList = (props: any) => (
  <List
    {...props}
    sort={{ field: 'createdAt', order: 'DESC' }}
    filters={<UserFilter />}
    actions={<ListActions />}
  >
    <Datagrid>
      <UserLinkField />
      <TextField source="email" label="Email" />
      <CurriculumLinkField />
      <FunctionField label="Sub Status" render={subStatusCustomRender} />
      <FunctionField
        label="Product"
        render={(record: any) => (
          // @ts-ignore
          <div>{subscriptionOptionsToUse[record.subProduct]}</div>
        )}
      />
      <TextField
        sortable={false}
        source="VideoProgressCount"
        label="Video Progress Num"
      />
      <TextField
        sortable={false}
        source="PracticeProgressCount"
        label="Practice Progress Num"
      />
      <FunctionField
        render={(record: any) => (
          <div>
            {record.activeDaysCount} / {record.daysSinceCreated}
          </div>
        )}
        label="Activity"
      />
      <TextField source="phoneOS" label="Phone OS" />
      <TextField source="country_code" label="Country Code" />
      <TextField source="referral" label="Referral Source" />
      <DateField showTime source="lastSeen" label="Last Seen" />
      <DateField source="createdAt" label="Joined" />
    </Datagrid>
  </List>
);

export default UserList;
