import * as MUI from '@material-ui/core';
import RemoveIcon from '@material-ui/icons/Remove';
import { Autocomplete } from '@material-ui/lab';
import graphql from 'babel-plugin-relay/macro';
import React from 'react';
import { useLazyLoadQuery, useRefetchableFragment } from 'react-relay';

const MultipleChoiceQuestionsSelect = (props: any) => (
  <React.Suspense
    fallback={
      <MultipleChoiceQuestionsSelectField
        {...props}
        isLoading
        selectedMultipleChoiceQuestions={[]}
        multipleChoiceQuestions={[]}
      />
    }
  >
    <MultipleChoiceQuestionsSelectWithData {...props} />
  </React.Suspense>
);

const MultipleChoiceQuestionsSelectWithData = (props: any) => {
  const [_isPending, startTransition] = React.useTransition();

  const initiallySelectedIDs = React.useMemo(() => props.value ?? [], []);

  const data: any = useLazyLoadQuery(
    graphql`
      query MultipleChoiceQuestionsSelect_Query($initiallySelectedIDs: [ID!]!) {
        ...MultipleChoiceQuestionsSelect_multipleChoiceQuestionsForString
        ...MultipleChoiceQuestionsSelect_multipleChoiceQuestionsByIDs
          @arguments(ids: $initiallySelectedIDs)
      }
    `,
    { initiallySelectedIDs },
    {},
  );

  const [searchedData, refetch] = useRefetchableFragment(
    graphql`
      fragment MultipleChoiceQuestionsSelect_multipleChoiceQuestionsForString on Query
      @refetchable(
        queryName: "MultipleChoiceQuestionsSelect_multipleChoiceQuestionsForString_RefetchQuery"
      )
      @argumentDefinitions(search: { type: "String", defaultValue: "" }) {
        multipleChoiceQuestionsForString(search: $search, first: 20) {
          nodes {
            id
            internalName
          }
        }
      }
    `,
    data,
  );

  const refetchSearch = React.useCallback(
    (search: string) => {
      refetch({ search }, { fetchPolicy: 'network-only' });
    },
    [refetch],
  );

  const [selectedData, refetchSelected] = useRefetchableFragment(
    graphql`
      fragment MultipleChoiceQuestionsSelect_multipleChoiceQuestionsByIDs on Query
      @refetchable(
        queryName: "MultipleChoiceQuestionsSelect_multipleChoiceQuestionsByIDs_RefetchQuery"
      )
      @argumentDefinitions(ids: { type: "[ID!]", defaultValue: [] }) {
        multipleChoiceQuestionsByIDs(ids: $ids) {
          id
          internalName
        }
      }
    `,
    data,
  );

  React.useEffect(() => {
    startTransition(() => {
      refetchSelected({ ids: props.value }, { fetchPolicy: 'network-only' });
    });
  }, [props.value, refetchSelected, startTransition]);

  const sortedSelectedMultipleChoiceQuestions = React.useMemo(
    () =>
      [...selectedData.multipleChoiceQuestionsByIDs].sort((a: any, b: any) =>
        a.internalName.localeCompare(b.internalName),
      ),
    [selectedData.multipleChoiceQuestionsByIDs],
  );

  return (
    <>
      <MultipleChoiceQuestionsSelectField
        {...props}
        multipleChoiceQuestions={
          searchedData.multipleChoiceQuestionsForString.nodes
        }
        selectedMultipleChoiceQuestions={
          selectedData.multipleChoiceQuestionsByIDs
        }
        refetchSearch={refetchSearch}
      />

      {!(
        props.disabled && sortedSelectedMultipleChoiceQuestions.length === 0
      ) && (
        <MUI.Box marginTop={1}>
          <MUI.Typography variant="body2" color="textSecondary">
            {sortedSelectedMultipleChoiceQuestions.length}{' '}
            {`multiple choice question${sortedSelectedMultipleChoiceQuestions.length === 1 ? '' : 's'}`}{' '}
            (A-Z)
          </MUI.Typography>

          <MUI.Box marginTop={1} style={{ columnWidth: 250, columnGap: 14 }}>
            {sortedSelectedMultipleChoiceQuestions?.map(
              (multipleChoiceQuestion: any, index: any) => (
                <MUI.Link
                  href={`/#/g/multiple-choice-questions/${multipleChoiceQuestion.id}`}
                  target="_blank"
                  key={index}
                >
                  <MUI.Paper
                    key={multipleChoiceQuestion.id}
                    elevation={1}
                    style={{
                      breakInside: 'avoid-column',
                      padding: '6px 8px',
                      marginBottom: 8,
                    }}
                  >
                    <MUI.Box
                      display="flex"
                      alignItems="flex-start"
                      justifyContent="space-between"
                    >
                      <MUI.Box
                        display="flex"
                        flexDirection="row"
                        gridColumnGap={8}
                      >
                        <MUI.Typography
                          variant="body1"
                          style={{ marginTop: 3 }}
                        >
                          {multipleChoiceQuestion.internalName}
                        </MUI.Typography>
                      </MUI.Box>
                      <MUI.IconButton
                        size="small"
                        onClick={(e) => {
                          e.preventDefault();
                          props.onChange(
                            props.value.filter(
                              (p: any) => p !== multipleChoiceQuestion.id,
                            ),
                          );
                        }}
                      >
                        <RemoveIcon />
                      </MUI.IconButton>
                    </MUI.Box>
                    <MUI.Typography
                      variant="caption"
                      color="textSecondary"
                      style={{ display: 'block' }}
                    >
                      {multipleChoiceQuestion.id}
                    </MUI.Typography>
                  </MUI.Paper>
                </MUI.Link>
              ),
            )}
          </MUI.Box>
        </MUI.Box>
      )}
    </>
  );
};

const MultipleChoiceQuestionsSelectField = ({
  multipleChoiceQuestions,
  selectedMultipleChoiceQuestions,
  refetchSearch,
  onChange,
  isLoading,
  value,
  disabled,
}: any) => {
  const [_isPending, startTransition] = React.useTransition();

  const multipleChoiceQuestionsByID = React.useMemo(
    () =>
      Object.fromEntries(
        [...multipleChoiceQuestions, ...selectedMultipleChoiceQuestions].map(
          (multipleChoiceQuestion: any) => [
            multipleChoiceQuestion.id,
            multipleChoiceQuestion,
          ],
        ),
      ),
    [multipleChoiceQuestions, selectedMultipleChoiceQuestions],
  );

  const multipleChoiceQuestionIDs = React.useMemo(
    () =>
      multipleChoiceQuestions.map(
        (multipleChoiceQuestion: any) => multipleChoiceQuestion.id,
      ),
    [multipleChoiceQuestions],
  );

  const onInputChange = (e: any, value: any) => {
    startTransition(() => {
      refetchSearch(value);
    });
  };

  return (
    <Autocomplete
      multiple
      autoHighlight
      blurOnSelect={false}
      options={multipleChoiceQuestionIDs}
      filterOptions={(x) => x}
      renderTags={() => null}
      closeIcon={null}
      getOptionLabel={(multipleChoiceQuestionID: any) =>
        multipleChoiceQuestionsByID[multipleChoiceQuestionID]?.internalName ||
        'Invalid multiple choice question'
      }
      style={{ width: 600 }}
      debug
      size="small"
      selectOnFocus
      loading={isLoading}
      renderOption={(multipleChoiceQuestionID: any) => {
        const multipleChoiceQuestion =
          multipleChoiceQuestionsByID[multipleChoiceQuestionID];
        return (
          <MUI.Box display="flex" flexDirection="column">
            {multipleChoiceQuestion.internalName}
            <MUI.Box component="span" color="text.secondary" fontSize={12}>
              {multipleChoiceQuestion.id}
            </MUI.Box>
          </MUI.Box>
        );
      }}
      renderInput={(params) => (
        <MUI.TextField
          {...params}
          variant="filled"
          margin="normal"
          label="Search for multiple choice questions"
        />
      )}
      onInputChange={onInputChange}
      onChange={(e, value, reason) => {
        if (reason !== 'remove-option') {
          onChange?.(value);
        }
      }}
      value={value}
      disabled={disabled}
    />
  );
};

export default MultipleChoiceQuestionsSelect;
