import * as React from 'react';
import { Datagrid, List, ListProps } from 'react-admin';
import { FC } from 'react';
import { Link, FieldProps, TextField } from 'react-admin';

// import CurriculumLinkField from './CurriculumLinkField';

// import CoachListAside from './CoachListAside';
import { ReactElement } from 'react';

const CoachLinkField: FC<FieldProps> = (props) =>
  props.record ? (
    <Link to={`/coach/${props.record.id}`}>{props.record.name}</Link>
  ) : null;

CoachLinkField.defaultProps = {
  source: 'name',
  addLabel: true,
};

const CoachList = (props: ListProps): ReactElement => (
  <List {...props} sort={{ field: 'name', order: 'ASC' }} perPage={25}>
    <Datagrid optimized>
      <CoachLinkField label="Name" />
      <TextField source="description" label="Description" />
      {/* <CurriculumLinkField /> */}
    </Datagrid>
  </List>
);

export default CoachList;
