/**
 * @generated SignedSource<<78cfe6724736af7fafe146695a8e399b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type VideoGroupVideosEditor_RemoveVideoVariant_Mutation$variables = {
  id: string;
  videoID: string;
};
export type VideoGroupVideosEditor_RemoveVideoVariant_Mutation$data = {
  readonly removeVideoVariantFromVideo: {
    readonly id: string | null | undefined;
    readonly videoVariants: ReadonlyArray<{
      readonly id: string | null | undefined;
      readonly " $fragmentSpreads": FragmentRefs<"VideoGroupVideosEditor_videoVariant">;
    }> | null | undefined;
  } | null | undefined;
};
export type VideoGroupVideosEditor_RemoveVideoVariant_Mutation = {
  response: VideoGroupVideosEditor_RemoveVideoVariant_Mutation$data;
  variables: VideoGroupVideosEditor_RemoveVideoVariant_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "videoID"
},
v2 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  },
  {
    "kind": "Variable",
    "name": "videoID",
    "variableName": "videoID"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "nodeID",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "VideoGroupVideosEditor_RemoveVideoVariant_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Video",
        "kind": "LinkedField",
        "name": "removeVideoVariantFromVideo",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "VideoVariant",
            "kind": "LinkedField",
            "name": "videoVariants",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "VideoGroupVideosEditor_videoVariant"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "VideoGroupVideosEditor_RemoveVideoVariant_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Video",
        "kind": "LinkedField",
        "name": "removeVideoVariantFromVideo",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "VideoVariant",
            "kind": "LinkedField",
            "name": "videoVariants",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "variantRules",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "title",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "thumbnailURL",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "duration",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "tags",
                "storageKey": null
              },
              (v4/*: any*/)
            ],
            "storageKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e04d387185a970168c2c50abafae53f6",
    "id": null,
    "metadata": {},
    "name": "VideoGroupVideosEditor_RemoveVideoVariant_Mutation",
    "operationKind": "mutation",
    "text": "mutation VideoGroupVideosEditor_RemoveVideoVariant_Mutation(\n  $videoID: ID!\n  $id: ID!\n) {\n  removeVideoVariantFromVideo(videoID: $videoID, id: $id) {\n    id\n    videoVariants {\n      id\n      ...VideoGroupVideosEditor_videoVariant\n      nodeID\n    }\n    nodeID\n  }\n}\n\nfragment VideoGroupVideosEditor_videoVariant on VideoVariant {\n  id\n  variantRules\n  title\n  thumbnailURL\n  duration\n  tags\n}\n"
  }
};
})();

(node as any).hash = "097207390fdbc40a4d5ffcb0302b7bbe";

export default node;
