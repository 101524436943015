/**
 * @generated SignedSource<<27f5e6b9a920f5c4673e2ae31d03f35d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PhoneticSearch_Query$variables = Record<PropertyKey, never>;
export type PhoneticSearch_Query$data = {
  readonly " $fragmentSpreads": FragmentRefs<"PhoneticSearch_allLanguages" | "PhoneticSearch_audioLogsForPhoneticSearch">;
};
export type PhoneticSearch_Query = {
  response: PhoneticSearch_Query$data;
  variables: PhoneticSearch_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "englishName",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "nodeID",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "PhoneticSearch_Query",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "PhoneticSearch_allLanguages"
      },
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "PhoneticSearch_audioLogsForPhoneticSearch"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "PhoneticSearch_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Language",
        "kind": "LinkedField",
        "name": "allLanguages",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "code",
            "storageKey": null
          },
          (v0/*: any*/),
          (v1/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": [
          {
            "kind": "Literal",
            "name": "exclude",
            "value": ""
          },
          {
            "kind": "Literal",
            "name": "search",
            "value": ""
          }
        ],
        "concreteType": "AudioLog",
        "kind": "LinkedField",
        "name": "audioLogsForPhoneticSearch",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "audioFile",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PracticeText",
            "kind": "LinkedField",
            "name": "practice",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "text",
                "storageKey": null
              },
              (v1/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Language",
                "kind": "LinkedField",
                "name": "languages",
                "plural": true,
                "selections": [
                  (v0/*: any*/),
                  (v1/*: any*/)
                ],
                "storageKey": null
              },
              (v1/*: any*/)
            ],
            "storageKey": null
          },
          (v1/*: any*/)
        ],
        "storageKey": "audioLogsForPhoneticSearch(exclude:\"\",search:\"\")"
      }
    ]
  },
  "params": {
    "cacheID": "c1893acc164f465bb341837f8f463de3",
    "id": null,
    "metadata": {},
    "name": "PhoneticSearch_Query",
    "operationKind": "query",
    "text": "query PhoneticSearch_Query {\n  ...PhoneticSearch_allLanguages\n  ...PhoneticSearch_audioLogsForPhoneticSearch\n}\n\nfragment PhoneticSearch_allLanguages on Query {\n  allLanguages {\n    code\n    englishName\n    nodeID\n  }\n}\n\nfragment PhoneticSearch_audioLogsForPhoneticSearch on Query {\n  audioLogsForPhoneticSearch(search: \"\", exclude: \"\") {\n    id\n    audioFile\n    practice {\n      text\n      nodeID\n    }\n    user {\n      id\n      languages {\n        englishName\n        nodeID\n      }\n      nodeID\n    }\n    nodeID\n  }\n}\n"
  }
};
})();

(node as any).hash = "ade56c9c4ff1cd1c48e5ed1bcdacf49c";

export default node;
