/**
 * @generated SignedSource<<662598ddde6a31125bd8e013a13ff359>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SkillAssessments_OverallSkill_user$data = {
  readonly accurateAggregateAssessments: ReadonlyArray<any> | null | undefined;
  readonly aggregateAssessments: ReadonlyArray<{
    readonly bottomAvg: number | null | undefined;
    readonly createdAt: any | null | undefined;
  }> | null | undefined;
  readonly " $fragmentType": "SkillAssessments_OverallSkill_user";
};
export type SkillAssessments_OverallSkill_user$key = {
  readonly " $data"?: SkillAssessments_OverallSkill_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"SkillAssessments_OverallSkill_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SkillAssessments_OverallSkill_user",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "AggregateAssessment",
      "kind": "LinkedField",
      "name": "aggregateAssessments",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "createdAt",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "bottomAvg",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "accurateAggregateAssessments",
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "1a0567c4e1fc76a517241031e6596f8c";

export default node;
