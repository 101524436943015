/**
 * @generated SignedSource<<7daf86f95198593d14630353eca8f5d7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type WordEdit_updateSourceEdge_Mutation$variables = {
  partsOfSpeech?: ReadonlyArray<string> | null | undefined;
  pronunciationID: string;
  pronunciationSourceID: string;
  removed?: boolean | null | undefined;
  sourceDefault?: boolean | null | undefined;
};
export type WordEdit_updateSourceEdge_Mutation$data = {
  readonly updatePronunciationSourceEdge: {
    readonly pronunciation: {
      readonly word: {
        readonly " $fragmentSpreads": FragmentRefs<"WordEdit_word">;
      } | null | undefined;
    } | null | undefined;
  } | null | undefined;
};
export type WordEdit_updateSourceEdge_Mutation = {
  response: WordEdit_updateSourceEdge_Mutation$data;
  variables: WordEdit_updateSourceEdge_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "partsOfSpeech"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "pronunciationID"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "pronunciationSourceID"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "removed"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "sourceDefault"
},
v5 = [
  {
    "kind": "Variable",
    "name": "partsOfSpeech",
    "variableName": "partsOfSpeech"
  },
  {
    "kind": "Variable",
    "name": "pronunciationID",
    "variableName": "pronunciationID"
  },
  {
    "kind": "Variable",
    "name": "pronunciationSourceID",
    "variableName": "pronunciationSourceID"
  },
  {
    "kind": "Variable",
    "name": "removed",
    "variableName": "removed"
  },
  {
    "kind": "Variable",
    "name": "sourceDefault",
    "variableName": "sourceDefault"
  }
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "nodeID",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "WordEdit_updateSourceEdge_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": "PronunciationPronunciationSource",
        "kind": "LinkedField",
        "name": "updatePronunciationSourceEdge",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Pronunciation",
            "kind": "LinkedField",
            "name": "pronunciation",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Word",
                "kind": "LinkedField",
                "name": "word",
                "plural": false,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "WordEdit_word"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "WordEdit_updateSourceEdge_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": "PronunciationPronunciationSource",
        "kind": "LinkedField",
        "name": "updatePronunciationSourceEdge",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Pronunciation",
            "kind": "LinkedField",
            "name": "pronunciation",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Word",
                "kind": "LinkedField",
                "name": "word",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "text",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Pronunciation",
                    "kind": "LinkedField",
                    "name": "pronunciations",
                    "plural": true,
                    "selections": [
                      (v6/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "active",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "default",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "bvIPAUnsyllabified",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "bvIPA",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "form",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PronunciationPronunciationSource",
                        "kind": "LinkedField",
                        "name": "sourceEdges",
                        "plural": true,
                        "selections": [
                          (v6/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "PronunciationSources",
                            "kind": "LinkedField",
                            "name": "pronunciationSource",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "name",
                                "storageKey": null
                              },
                              (v7/*: any*/),
                              (v6/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "original",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "sourceDefault",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "removed",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "autoRemoved",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "PartOfSpeech",
                            "kind": "LinkedField",
                            "name": "partsOfSpeech",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "tag",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "description",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Pronunciation",
                            "kind": "LinkedField",
                            "name": "pronunciation",
                            "plural": false,
                            "selections": [
                              (v6/*: any*/),
                              (v7/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v7/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v7/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v7/*: any*/)
                ],
                "storageKey": null
              },
              (v7/*: any*/)
            ],
            "storageKey": null
          },
          (v7/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c8b0ab292453f83a5085e7be5ea3e65d",
    "id": null,
    "metadata": {},
    "name": "WordEdit_updateSourceEdge_Mutation",
    "operationKind": "mutation",
    "text": "mutation WordEdit_updateSourceEdge_Mutation(\n  $pronunciationID: ID!\n  $pronunciationSourceID: ID!\n  $removed: Boolean\n  $sourceDefault: Boolean\n  $partsOfSpeech: [String!]\n) {\n  updatePronunciationSourceEdge(pronunciationID: $pronunciationID, pronunciationSourceID: $pronunciationSourceID, removed: $removed, sourceDefault: $sourceDefault, partsOfSpeech: $partsOfSpeech) {\n    pronunciation {\n      word {\n        ...WordEdit_word\n        nodeID\n      }\n      nodeID\n    }\n    nodeID\n  }\n}\n\nfragment WordEdit_Pronunciation on Pronunciation {\n  id\n  bvIPAUnsyllabified\n  bvIPA\n  active\n  default\n  form\n  sourceEdges {\n    id\n    pronunciationSource {\n      name\n      nodeID\n    }\n    ...WordEdit_SourceEdge\n    nodeID\n  }\n}\n\nfragment WordEdit_SourceEdge on PronunciationPronunciationSource {\n  id\n  original\n  sourceDefault\n  removed\n  autoRemoved\n  partsOfSpeech {\n    tag\n    description\n  }\n  pronunciation {\n    id\n    nodeID\n  }\n  pronunciationSource {\n    id\n    name\n    nodeID\n  }\n}\n\nfragment WordEdit_word on Word {\n  text\n  pronunciations {\n    id\n    active\n    default\n    ...WordEdit_Pronunciation\n    nodeID\n  }\n}\n"
  }
};
})();

(node as any).hash = "e1ed155654d2ba53e079b7ec1851aabe";

export default node;
