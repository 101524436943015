import * as React from 'react';
import { Datagrid, List, ListProps, Filter, TextInput } from 'react-admin';
import { FC } from 'react';
import { Link, FieldProps } from 'react-admin';

import { ReactElement } from 'react';
import { useMouthDiagramImages } from '../mouthDiagramSet/utils';

const MouthDiagramLinkField: FC<FieldProps> = (props) =>
  props.record ? (
    <Link to={`/content/mouthDiagram/${props.record.id}`}>
      {props.record.imageUrl}
    </Link>
  ) : null;

const DiagramImageField: FC<FieldProps> = ({ record }: { record?: any }) => {
  const [clicked, setClicked] = React.useState(false);
  const { urls } = useMouthDiagramImages([record]);
  return record?.imageUrl ? (
    <img
      alt="diagram"
      onClick={() => setClicked(!clicked)}
      style={{
        width: clicked ? 480 : 160,
        aspectRatio: '16/9',
        borderRadius: 14,
        cursor: 'pointer',
      }}
      src={urls?.[record.imageUrl]}
    />
  ) : null;
};

const MouthDiagramFilters = (props: any) => (
  <Filter {...props}>
    <TextInput label="Image Url" source="imageUrl" />
    <TextInput label="Square Image Url" source="squareImageUrl" />
  </Filter>
);

const MouthDiagramList = (props: ListProps): ReactElement => (
  <List {...props} perPage={25} filters={<MouthDiagramFilters />}>
    <Datagrid optimized>
      <MouthDiagramLinkField label="Image Url" />
      <DiagramImageField label="Preview" />
    </Datagrid>
  </List>
);

export default MouthDiagramList;
