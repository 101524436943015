/**
 * @generated SignedSource<<97367d7af9210cf05bda2118dc8e319c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PhonemesSelect_Query$variables = {
  initiallySelectedIDs: ReadonlyArray<string>;
};
export type PhonemesSelect_Query$data = {
  readonly " $fragmentSpreads": FragmentRefs<"PhonemesSelect_phonemesByIDs" | "PhonemesSelect_phonemesForString">;
};
export type PhonemesSelect_Query = {
  response: PhonemesSelect_Query$data;
  variables: PhonemesSelect_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "initiallySelectedIDs"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "ids",
    "variableName": "initiallySelectedIDs"
  }
],
v2 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "id",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "arpabet",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "ipa",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "nodeID",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PhonemesSelect_Query",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "PhonemesSelect_phonemesForString"
      },
      {
        "args": (v1/*: any*/),
        "kind": "FragmentSpread",
        "name": "PhonemesSelect_phonemesByIDs"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PhonemesSelect_Query",
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Literal",
            "name": "first",
            "value": 20
          },
          {
            "kind": "Literal",
            "name": "search",
            "value": ""
          }
        ],
        "concreteType": "QueryPhonemesForStringConnection",
        "kind": "LinkedField",
        "name": "phonemesForString",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Phoneme",
            "kind": "LinkedField",
            "name": "nodes",
            "plural": true,
            "selections": (v2/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": "phonemesForString(first:20,search:\"\")"
      },
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Phoneme",
        "kind": "LinkedField",
        "name": "phonemesByID",
        "plural": true,
        "selections": (v2/*: any*/),
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "dd4da28f91af4c252061f884833dd353",
    "id": null,
    "metadata": {},
    "name": "PhonemesSelect_Query",
    "operationKind": "query",
    "text": "query PhonemesSelect_Query(\n  $initiallySelectedIDs: [ID!]!\n) {\n  ...PhonemesSelect_phonemesForString\n  ...PhonemesSelect_phonemesByIDs_1SqFeW\n}\n\nfragment PhonemesSelect_phonemesByIDs_1SqFeW on Query {\n  phonemesByID(ids: $initiallySelectedIDs) {\n    id\n    arpabet\n    ipa\n    nodeID\n  }\n}\n\nfragment PhonemesSelect_phonemesForString on Query {\n  phonemesForString(search: \"\", first: 20) {\n    nodes {\n      id\n      arpabet\n      ipa\n      nodeID\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "44e9a6b78e95e080a6b779c1e053eb2f";

export default node;
