/**
 * @generated SignedSource<<9abd9a471325b49aed01e2e9e2a75ae1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SkillsTextScanRefetchQuery$variables = {
  text?: string | null | undefined;
};
export type SkillsTextScanRefetchQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"SkillsTextScan_result">;
};
export type SkillsTextScanRefetchQuery = {
  response: SkillsTextScanRefetchQuery$data;
  variables: SkillsTextScanRefetchQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": "",
    "kind": "LocalArgument",
    "name": "text"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "text",
    "variableName": "text"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "nodeID",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SkillsTextScanRefetchQuery",
    "selections": [
      {
        "args": (v1/*: any*/),
        "kind": "FragmentSpread",
        "name": "SkillsTextScan_result"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SkillsTextScanRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SkillsTextScanMatch",
        "kind": "LinkedField",
        "name": "scanTextForSkills",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Skill",
            "kind": "LinkedField",
            "name": "skill",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Word",
            "kind": "LinkedField",
            "name": "words",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "text",
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "2fbbff9415cd18a62789b3b9a3fc826c",
    "id": null,
    "metadata": {},
    "name": "SkillsTextScanRefetchQuery",
    "operationKind": "query",
    "text": "query SkillsTextScanRefetchQuery(\n  $text: String = \"\"\n) {\n  ...SkillsTextScan_result_1EemdR\n}\n\nfragment SkillsTextScan_SkillMatch on SkillsTextScanMatch {\n  skill {\n    id\n    name\n    nodeID\n  }\n  words {\n    text\n    nodeID\n  }\n}\n\nfragment SkillsTextScan_result_1EemdR on Query {\n  scanTextForSkills(text: $text) {\n    skill {\n      id\n      nodeID\n    }\n    ...SkillsTextScan_SkillMatch\n  }\n}\n"
  }
};
})();

(node as any).hash = "f67d5fc5dec78c13647c8fb7a9ab7a11";

export default node;
