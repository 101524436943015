import { Box, Card, CardContent } from '@material-ui/core';
import { Theme, makeStyles } from '@material-ui/core/styles';
import { Styles } from '@material-ui/styles/withStyles';
import * as React from 'react';
import { FC } from 'react';
import {
  DeleteButton,
  Edit,
  EditProps,
  FieldProps,
  FormWithRedirect,
  SaveButton,
  TextInput,
  Toolbar,
} from 'react-admin';
import { JsonInput } from 'react-admin-json-view';

const TutorialConfigTitle: FC<FieldProps> = ({ record }) =>
  record ? (
    <span>
      TutorialConfig: &quot;
      {record?.language}&quot;
    </span>
  ) : null;

export const styles: Styles<Theme, any> = {
  name: { display: 'inline-block' },
  photo: { display: 'inline-block' },
  spacing: { marginRight: 10 },
  image: {
    margin: '0.5rem',
    maxHeight: '10rem',
  },
  button: {
    marginRight: 10,
  },
};

const useStyles = makeStyles(styles);

const TutorialConfigForm = (props: any) => {
  const classes = useStyles(props);
  return (
    <FormWithRedirect
      {...props}
      render={(formProps: any) => (
        <Card>
          <form>
            <CardContent>
              <Box>
                <TextInput
                  fullWidth
                  source="language"
                  label="Language"
                  className={classes.name}
                  autoFocus
                />
              </Box>

              <JsonInput
                source="factoids"
                record={props.record}
                jsonString={false}
                reactJsonOptions={{
                  name: 'Factoids',
                  collapsed: false,
                  enableClipboard: true,
                  displayDataTypes: false,
                  sortKeys: true,
                  displayObjectSize: true,
                }}
              />

              <JsonInput
                source="visualTimelineContent"
                record={props.record}
                jsonString={false}
                reactJsonOptions={{
                  name: 'Visual Timeline Content',
                  collapsed: false,
                  enableClipboard: true,
                  displayDataTypes: false,
                  sortKeys: true,
                  displayObjectSize: true,
                }}
              />

              <Toolbar
                record={formProps.record}
                basePath={formProps.basePath}
                undoable={false}
                invalid={formProps.invalid}
                handleSubmit={formProps.handleSubmit}
                saving={formProps.saving}
                resource="content/tutorial_config"
                style={{ display: 'flex', justifyContent: 'space-between' }}
              >
                <div>
                  <SaveButton
                    label="Save & Continue"
                    handleSubmitWithRedirect={
                      formProps.handleSubmitWithRedirect ||
                      formProps.handleSubmit
                    }
                    disabled={formProps.disabled}
                    invalid={formProps.invalid}
                    redirect="edit"
                    saving={formProps.saving}
                    submitOnEnter={formProps.submitOnEnter}
                    className={classes.button}
                  />

                  <SaveButton
                    label="Save & Exit"
                    handleSubmitWithRedirect={
                      formProps.handleSubmitWithRedirect ||
                      formProps.handleSubmit
                    }
                    disabled={formProps.disabled}
                    invalid={formProps.invalid}
                    redirect={formProps.redirect}
                    saving={formProps.saving}
                    submitOnEnter={formProps.submitOnEnter}
                    className={classes.button}
                  />
                </div>

                {formProps.record &&
                  typeof formProps.record.id !== 'undefined' && (
                    <DeleteButton
                      basePath={formProps.basePath}
                      record={formProps.record}
                      resource={formProps.resource}
                      undoable={formProps.undoable}
                      mutationMode={formProps.mutationMode}
                    />
                  )}
              </Toolbar>
            </CardContent>
          </form>
        </Card>
      )}
    />
  );
};

const TutorialConfigEdit: FC<EditProps> = (props) => (
  <Edit undoable={false} title={<TutorialConfigTitle />} {...props}>
    <TutorialConfigForm />
  </Edit>
);

export default TutorialConfigEdit;
