/**
 * @generated SignedSource<<3db364532bdb3d52b05ff2d5d6c4758a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PhonemesSelect_phonemesByIDs$data = {
  readonly phonemesByID: ReadonlyArray<{
    readonly arpabet: string | null | undefined;
    readonly id: string | null | undefined;
    readonly ipa: string | null | undefined;
  }> | null | undefined;
  readonly " $fragmentType": "PhonemesSelect_phonemesByIDs";
};
export type PhonemesSelect_phonemesByIDs$key = {
  readonly " $data"?: PhonemesSelect_phonemesByIDs$data;
  readonly " $fragmentSpreads": FragmentRefs<"PhonemesSelect_phonemesByIDs">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": [],
      "kind": "LocalArgument",
      "name": "ids"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [],
      "operation": require('./PhonemesSelect_phonemesByIDs_RefetchQuery.graphql')
    }
  },
  "name": "PhonemesSelect_phonemesByIDs",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "ids",
          "variableName": "ids"
        }
      ],
      "concreteType": "Phoneme",
      "kind": "LinkedField",
      "name": "phonemesByID",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "arpabet",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "ipa",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "0c4bf6e8cd4a6ba6127aeec1a3aedddf";

export default node;
