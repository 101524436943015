/**
 * @generated SignedSource<<ccdd39f493cef8e51cb160661e8b79a9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MultipleChoicePromptSelect_multipleChoicePromptsForString$data = {
  readonly multipleChoicePromptsForString: {
    readonly nodes: ReadonlyArray<{
      readonly id: string | null | undefined;
      readonly text: string | null | undefined;
    } | null | undefined>;
  } | null | undefined;
  readonly " $fragmentType": "MultipleChoicePromptSelect_multipleChoicePromptsForString";
};
export type MultipleChoicePromptSelect_multipleChoicePromptsForString$key = {
  readonly " $data"?: MultipleChoicePromptSelect_multipleChoicePromptsForString$data;
  readonly " $fragmentSpreads": FragmentRefs<"MultipleChoicePromptSelect_multipleChoicePromptsForString">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": "",
      "kind": "LocalArgument",
      "name": "search"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [],
      "operation": require('./MultipleChoicePromptSelect_multipleChoicePromptsForString_RefetchQuery.graphql')
    }
  },
  "name": "MultipleChoicePromptSelect_multipleChoicePromptsForString",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 20
        },
        {
          "kind": "Variable",
          "name": "search",
          "variableName": "search"
        }
      ],
      "concreteType": "QueryMultipleChoicePromptsForStringConnection",
      "kind": "LinkedField",
      "name": "multipleChoicePromptsForString",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "MultipleChoicePrompt",
          "kind": "LinkedField",
          "name": "nodes",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "id",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "text",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "3a57afb1c221073c3a1fb06d902a3b23";

export default node;
