/**
 * @generated SignedSource<<69e0d278996cae91d66b38a034caead6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MultipleChoiceQuestionsSelect_multipleChoiceQuestionsByIDs$data = {
  readonly multipleChoiceQuestionsByIDs: ReadonlyArray<{
    readonly id: string | null | undefined;
    readonly internalName: string | null | undefined;
  }> | null | undefined;
  readonly " $fragmentType": "MultipleChoiceQuestionsSelect_multipleChoiceQuestionsByIDs";
};
export type MultipleChoiceQuestionsSelect_multipleChoiceQuestionsByIDs$key = {
  readonly " $data"?: MultipleChoiceQuestionsSelect_multipleChoiceQuestionsByIDs$data;
  readonly " $fragmentSpreads": FragmentRefs<"MultipleChoiceQuestionsSelect_multipleChoiceQuestionsByIDs">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": [],
      "kind": "LocalArgument",
      "name": "ids"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [],
      "operation": require('./MultipleChoiceQuestionsSelect_multipleChoiceQuestionsByIDs_RefetchQuery.graphql')
    }
  },
  "name": "MultipleChoiceQuestionsSelect_multipleChoiceQuestionsByIDs",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "ids",
          "variableName": "ids"
        }
      ],
      "concreteType": "MultipleChoiceQuestion",
      "kind": "LinkedField",
      "name": "multipleChoiceQuestionsByIDs",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "internalName",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "64a10fca92faad4a4282e8800f3d91dc";

export default node;
