/**
 * @generated SignedSource<<cf905167d48ac197e72196794f02e093>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type VideoGroupEditQuery$variables = {
  id?: string | null | undefined;
};
export type VideoGroupEditQuery$data = {
  readonly raVideo: {
    readonly " $fragmentSpreads": FragmentRefs<"VideoGroupVideosEditor_video">;
  } | null | undefined;
};
export type VideoGroupEditQuery = {
  response: VideoGroupEditQuery$data;
  variables: VideoGroupEditQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "nodeID",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "VideoGroupEditQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Video",
        "kind": "LinkedField",
        "name": "raVideo",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "VideoGroupVideosEditor_video"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "VideoGroupEditQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Video",
        "kind": "LinkedField",
        "name": "raVideo",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "VideoVariant",
            "kind": "LinkedField",
            "name": "videoVariants",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "variantRules",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "title",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "thumbnailURL",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "duration",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "tags",
                "storageKey": null
              },
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "31e5921cba350e045d62925366a18b1c",
    "id": null,
    "metadata": {},
    "name": "VideoGroupEditQuery",
    "operationKind": "query",
    "text": "query VideoGroupEditQuery(\n  $id: ID\n) {\n  raVideo(id: $id) {\n    ...VideoGroupVideosEditor_video\n    nodeID\n  }\n}\n\nfragment VideoGroupVideosEditor_video on Video {\n  id\n  videoVariants {\n    id\n    ...VideoGroupVideosEditor_videoVariant\n    nodeID\n  }\n}\n\nfragment VideoGroupVideosEditor_videoVariant on VideoVariant {\n  id\n  variantRules\n  title\n  thumbnailURL\n  duration\n  tags\n}\n"
  }
};
})();

(node as any).hash = "3a326914273b29657e91d6d78a1a127f";

export default node;
