/**
 * @generated SignedSource<<9ae66645cbf2ebb4ab432a55e135ed93>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DLPAssessmentsTaken2_BottomSkills$data = {
  readonly bottomSkillAssessments: ReadonlyArray<{
    readonly id: string | null | undefined;
    readonly " $fragmentSpreads": FragmentRefs<"DLPAssessmentsTaken2_SkillAssessment">;
  }> | null | undefined;
  readonly " $fragmentType": "DLPAssessmentsTaken2_BottomSkills";
};
export type DLPAssessmentsTaken2_BottomSkills$key = {
  readonly " $data"?: DLPAssessmentsTaken2_BottomSkills$data;
  readonly " $fragmentSpreads": FragmentRefs<"DLPAssessmentsTaken2_BottomSkills">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DLPAssessmentsTaken2_BottomSkills",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "limit",
          "value": 5
        }
      ],
      "concreteType": "SkillAssessment",
      "kind": "LinkedField",
      "name": "bottomSkillAssessments",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "DLPAssessmentsTaken2_SkillAssessment"
        }
      ],
      "storageKey": "bottomSkillAssessments(limit:5)"
    }
  ],
  "type": "AssessmentTaken",
  "abstractKey": null
};

(node as any).hash = "41780ca852e1c4e083af5c3a754030fe";

export default node;
