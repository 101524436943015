/**
 * @generated SignedSource<<88308f8b1d9fdb598df183a89d9f6c08>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PhoneticSearch_audioLogsForPhoneticSearch$data = {
  readonly audioLogsForPhoneticSearch: ReadonlyArray<{
    readonly audioFile: string | null | undefined;
    readonly id: string | null | undefined;
    readonly practice: {
      readonly text: string | null | undefined;
    } | null | undefined;
    readonly user: {
      readonly id: string | null | undefined;
      readonly languages: ReadonlyArray<{
        readonly englishName: string | null | undefined;
      }> | null | undefined;
    } | null | undefined;
  }> | null | undefined;
  readonly " $fragmentType": "PhoneticSearch_audioLogsForPhoneticSearch";
};
export type PhoneticSearch_audioLogsForPhoneticSearch$key = {
  readonly " $data"?: PhoneticSearch_audioLogsForPhoneticSearch$data;
  readonly " $fragmentSpreads": FragmentRefs<"PhoneticSearch_audioLogsForPhoneticSearch">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": "",
      "kind": "LocalArgument",
      "name": "exclude"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "languages"
    },
    {
      "defaultValue": "",
      "kind": "LocalArgument",
      "name": "search"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [],
      "operation": require('./PhoneticSearchRefetchQuery.graphql')
    }
  },
  "name": "PhoneticSearch_audioLogsForPhoneticSearch",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "exclude",
          "variableName": "exclude"
        },
        {
          "kind": "Variable",
          "name": "languages",
          "variableName": "languages"
        },
        {
          "kind": "Variable",
          "name": "search",
          "variableName": "search"
        }
      ],
      "concreteType": "AudioLog",
      "kind": "LinkedField",
      "name": "audioLogsForPhoneticSearch",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "audioFile",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PracticeText",
          "kind": "LinkedField",
          "name": "practice",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "text",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "User",
          "kind": "LinkedField",
          "name": "user",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "Language",
              "kind": "LinkedField",
              "name": "languages",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "englishName",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "b808d2f15ca5bb1c07a5803971794452";

export default node;
