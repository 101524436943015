import { Box, Card, CardContent } from '@material-ui/core';
import { Theme, makeStyles } from '@material-ui/core/styles';
import { Styles } from '@material-ui/styles/withStyles';
import { FC } from 'react';
import {
  AutocompleteArrayInput,
  DeleteButton,
  Edit,
  EditProps,
  FieldProps,
  FormWithRedirect,
  ReferenceArrayInput,
  SaveButton,
  TextInput,
  Toolbar,
} from 'react-admin';

import OrderedArrayInput from '../components/OrderedArrayInput';
import AudioField from '../practice/AudioField';

const RapidfireTitle: FC<FieldProps> = ({ record }) =>
  record ? (
    <span>
      Rapidfire &quot;
      {record.name}&quot;
    </span>
  ) : null;

export const styles: Styles<Theme, any> = {
  name: { display: 'inline' },
  photo: { display: 'inline-block' },
  image: {
    margin: '0.5rem',
    maxHeight: '10rem',
  },
  button: {
    marginRight: 10,
  },
};

const useStyles = makeStyles(styles);

const escapeRegExp = (value: any) =>
  value ? value.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') : ''; // $& means the whole matched string

export const matchSuggestion = (
  filterValue: string,
  choice: any,
  property: string,
) => {
  const isStrictSearch =
    filterValue.charAt(0) === `"` &&
    filterValue.charAt(filterValue.length - 1) === `"`;
  if (isStrictSearch) {
    if (
      choice?.[property]?.toLowerCase() ===
      filterValue.replaceAll(`"`, '')?.toLowerCase()
    ) {
      return true;
    }
    return false;
  }
  if (choice?.[property]?.match(new RegExp(escapeRegExp(filterValue), 'i'))) {
    return true;
  }
  return false;
};

const RapidfireForm = (props: any) => {
  const classes = useStyles(props);

  return (
    <FormWithRedirect
      {...props}
      render={(formProps: any) => (
        <Card>
          <form>
            <CardContent>
              <Box display="flex" flexDirection="row" alignItems="flex-start">
                <Box flex={1}>
                  <TextInput
                    autoFocus
                    source="name"
                    label="Name"
                    fullWidth
                    helperText={false}
                  />
                  <TextInput
                    autoFocus
                    source="description"
                    label="Description"
                    fullWidth
                    helperText="Optional"
                  />
                </Box>
              </Box>
              <Box display="flex" flexDirection="row" alignItems="flex-start">
                <Box flex={1}>
                  <AudioField sortBy="promptAudioFile" />
                  <TextInput
                    source="quantity"
                    label="Quantity"
                    fullWidth
                    helperText={false}
                  />
                </Box>
              </Box>

              <Box>
                <ReferenceArrayInput
                  filterToQuery={(searchText: any) => ({ name: searchText })}
                  label="Lessons"
                  source="lessonId"
                  reference="content/lesson"
                >
                  <OrderedArrayInput elementLinkUrl="/#/content/lesson/">
                    <AutocompleteArrayInput
                      optionValue="id"
                      optionText={(record: any) =>
                        `${record?.name} ${
                          record?.tags ? `- ${record?.tags}` : ``
                        } (id: ${record?.id?.slice(0, 4)})`
                      }
                      matchSuggestion={(filterValue: string, choice: any) =>
                        matchSuggestion(filterValue, choice, 'name')
                      }
                    />
                  </OrderedArrayInput>
                </ReferenceArrayInput>
              </Box>

              <Toolbar
                record={formProps.record}
                basePath={formProps.basePath}
                undoable={false}
                invalid={formProps.invalid}
                handleSubmit={formProps.handleSubmit}
                saving={formProps.saving}
                resource="rapidfire"
                style={{ display: 'flex', justifyContent: 'space-between' }}
              >
                <div>
                  <SaveButton
                    label="Save & Continue"
                    handleSubmitWithRedirect={
                      formProps.handleSubmitWithRedirect ||
                      formProps.handleSubmit
                    }
                    disabled={formProps.disabled}
                    invalid={formProps.invalid}
                    redirect="edit"
                    saving={formProps.saving}
                    submitOnEnter={formProps.submitOnEnter}
                    className={classes.button}
                  />

                  <SaveButton
                    label="Save & Exit"
                    handleSubmitWithRedirect={
                      formProps.handleSubmitWithRedirect ||
                      formProps.handleSubmit
                    }
                    disabled={formProps.disabled}
                    invalid={formProps.invalid}
                    redirect={formProps.redirect}
                    saving={formProps.saving}
                    submitOnEnter={formProps.submitOnEnter}
                    className={classes.button}
                  />
                </div>

                {formProps.record &&
                  typeof formProps.record.id !== 'undefined' && (
                    <DeleteButton
                      basePath={formProps.basePath}
                      record={formProps.record}
                      resource={formProps.resource}
                      undoable={formProps.undoable}
                      mutationMode={formProps.mutationMode}
                    />
                  )}
              </Toolbar>
            </CardContent>
          </form>
        </Card>
      )}
    />
  );
};

const LessonEdit: FC<EditProps> = (props) => (
  <Edit undoable={false} title={<RapidfireTitle />} {...props}>
    <RapidfireForm />
  </Edit>
);

export default LessonEdit;
