import * as React from 'react';
import {
  List,
  Datagrid,
  TextField,
  DateField,
  Link,
  Filter,
  TextInput,
  BooleanInput,
} from 'react-admin';
import UserLinkField from './UserLinkField';

type Lesson = {
  type: string;
  lessonId: string;
  difficulty: string;
  contentType: string;
};

const LessonLinkField: React.FC<any> = (props) => {
  const index = props.num;
  try {
    if (props.record.lessons) {
      const targetLesson: Lesson = props.record.lessons[index];
      return (
        <Link to={`/content/lesson/${targetLesson.lessonId}`}>
          {`${targetLesson.contentType}`}
        </Link>
      );
    }
  } catch (err) {
    console.log(err);
  }

  return null;
};

const DailyLessonFilter = (props: any) => (
  <Filter {...props}>
    <TextInput label="User ID" source="userId" defaultValue="" />
    <BooleanInput label="Completed?" source="completed" defaultValue={false} />
  </Filter>
);

export const DailyLessonList = (props: any) => (
  <List
    {...props}
    perPage={10}
    sort={{ field: 'createdAt', order: 'DESC' }}
    filters={<DailyLessonFilter />}
  >
    <Datagrid rowClick="expand" /* expand={<DailyLessonShow />} */>
      <UserLinkField />
      <LessonLinkField label="Lesson 1" record={props.record} num={0} />
      <LessonLinkField label="Lesson 2" record={props.record} num={1} />
      <TextField source="completed" label="completed" />
      <TextField source="isRefreshDailyGoal" label="Refreshed?" />
      <DateField showTime source="createdAt" label="Created at" />
      <TextField source="reaction" label="User reaction" />
    </Datagrid>
  </List>
);

export default DailyLessonList;
