/**
 * @generated SignedSource<<dfbfcdd52e9f26af1989ccd9826af0d8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PhoneticSearchRefetchQuery$variables = {
  exclude?: string | null | undefined;
  languages?: ReadonlyArray<string> | null | undefined;
  search?: string | null | undefined;
};
export type PhoneticSearchRefetchQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"PhoneticSearch_audioLogsForPhoneticSearch">;
};
export type PhoneticSearchRefetchQuery = {
  response: PhoneticSearchRefetchQuery$data;
  variables: PhoneticSearchRefetchQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": "",
    "kind": "LocalArgument",
    "name": "exclude"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "languages"
  },
  {
    "defaultValue": "",
    "kind": "LocalArgument",
    "name": "search"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "exclude",
    "variableName": "exclude"
  },
  {
    "kind": "Variable",
    "name": "languages",
    "variableName": "languages"
  },
  {
    "kind": "Variable",
    "name": "search",
    "variableName": "search"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "nodeID",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PhoneticSearchRefetchQuery",
    "selections": [
      {
        "args": (v1/*: any*/),
        "kind": "FragmentSpread",
        "name": "PhoneticSearch_audioLogsForPhoneticSearch"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PhoneticSearchRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AudioLog",
        "kind": "LinkedField",
        "name": "audioLogsForPhoneticSearch",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "audioFile",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PracticeText",
            "kind": "LinkedField",
            "name": "practice",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "text",
                "storageKey": null
              },
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Language",
                "kind": "LinkedField",
                "name": "languages",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "englishName",
                    "storageKey": null
                  },
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "346c40305afe6d770ee8282b820fda55",
    "id": null,
    "metadata": {},
    "name": "PhoneticSearchRefetchQuery",
    "operationKind": "query",
    "text": "query PhoneticSearchRefetchQuery(\n  $exclude: String = \"\"\n  $languages: [String!]\n  $search: String = \"\"\n) {\n  ...PhoneticSearch_audioLogsForPhoneticSearch_4CuxD5\n}\n\nfragment PhoneticSearch_audioLogsForPhoneticSearch_4CuxD5 on Query {\n  audioLogsForPhoneticSearch(search: $search, exclude: $exclude, languages: $languages) {\n    id\n    audioFile\n    practice {\n      text\n      nodeID\n    }\n    user {\n      id\n      languages {\n        englishName\n        nodeID\n      }\n      nodeID\n    }\n    nodeID\n  }\n}\n"
  }
};
})();

(node as any).hash = "b808d2f15ca5bb1c07a5803971794452";

export default node;
