import Icon from '@material-ui/icons/RecordVoiceOver';

import ReferralCodeList from './ReferralCodeList';
import ReferralCodeEdit from './ReferralCodeEdit';
import ReferralCodeCreate from './ReferralCodeCreate';

export default {
  list: ReferralCodeList,
  edit: ReferralCodeEdit,
  create: ReferralCodeCreate,
  icon: Icon,
};

// import VpnKeyIcon from '@mui/icons-material/VpnKey';
