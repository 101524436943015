import { Box, Typography } from '@material-ui/core';
import {
  DeleteButton,
  Edit,
  FormWithRedirect,
  SaveButton,
  Toolbar,
} from 'react-admin';

import MultipleChoicePromptFormFields from './MultipleChoicePromptFormFields';

const MultipleChoicePromptForm = (props: any) => (
  <FormWithRedirect
    {...props}
    render={(formProps: any) => (
      <form>
        <Box p="1em">
          <Typography variant="h6" gutterBottom>
            Multiple Choice Prompt
          </Typography>

          <MultipleChoicePromptFormFields
            autogenerated={props.record.autogenerated}
          />
        </Box>
        <Toolbar
          record={formProps.record}
          basePath={formProps.basePath}
          undoable
          invalid={formProps.invalid}
          handleSubmit={formProps.handleSubmit}
          saving={formProps.saving}
          resource="g:MultipleChoicePrompt"
          style={{ marginTop: 0 }}
        >
          <Box display="flex" justifyContent="space-between" width="100%">
            <SaveButton
              label="Save"
              handleSubmitWithRedirect={
                formProps.handleSubmitWithRedirect || formProps.handleSubmit
              }
              disabled={formProps.disabled}
              invalid={formProps.invalid}
              redirect="edit"
              saving={formProps.saving}
              submitOnEnter={formProps.submitOnEnter}
            />

            {props.record && typeof props.record.id !== 'undefined' && (
              <DeleteButton
                basePath={formProps.basePath}
                record={formProps.record}
                resource={formProps.resource}
                undoable={formProps.undoable}
                mutationMode={formProps.mutationMode}
              />
            )}
          </Box>
        </Toolbar>
      </form>
    )}
  />
);

const MultipleChoicePromptEdit = (props: any) => {
  return (
    <Box display="flex" flexDirection="column" gridRowGap={20}>
      <Edit {...props}>
        <MultipleChoicePromptForm />
      </Edit>
    </Box>
  );
};

export default MultipleChoicePromptEdit;
