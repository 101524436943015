export const GoalTypes = [
  'count',
  // 'manual',
  'binomial',
  'contains',
  'starting_chars',
  'ending_chars',
];

export const MetricTypes = [
  'phonemes',
  'syllables',
  'words',
  'sentences',
  'utterances',
  'seconds',
  'fixes',
];

export const MetricsWithMetricLength = ['syllables', 'words'];
