import * as React from 'react';
import { FC } from 'react';
import { Link, FieldProps } from 'react-admin';

import FullNameField from './FullNameField';

const UserLinkField: FC<FieldProps> = (props) => {
  if (props.record && props.source) {
    const user = props.record[props.source];
    return (
      <Link to={`/users/${user.id}`}>
        <FullNameField record={user} />
      </Link>
    );
  }
  return null;
};

UserLinkField.defaultProps = {
  source: 'firstName',
  label: 'User',
  addLabel: true,
};

export default UserLinkField;
