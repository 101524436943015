type User = any; // TODO: add common user type

export type Redemption = {
  id: string;
  redeemer: User;
  userId: string;
  provisionalUserId: string;
  isProvisional: boolean;
  subscriptionDuration: string;
  payoutRequirementSatisfied: boolean;
  payoutRequirementSatisfiedAt: string;
  paidOut: boolean;
  paidOutAt: string;
  paidOutBy: string;
  signedUpOn: string;
  createdAt: string;
  updatedAt: string;
};

export type ReferralCode = {
  id: string;
  redemptions: Redemption[];
  slug: string;
  ownerId: string;
  owner?: User;
  description: string;
  createdAt: string;
  updatedAt: string;
  payout_timeout: number;
  payout_amount: number;
  isAffiliate: boolean;
  payout_substatus_threshold: string;
  payout_type: string;
  redeemer_discount: string;

  // computed
  unique_clicks_count: number;
  signup_count: number;
  subscriber_count: number;
  currently_owed_to_owner: number;
  paid_out_to_owner: number;
  total_owner_earnings: number;
  number_of_payouts_in_timeout: number;
  earnings_per_day: Record<string, number>;
};

// eh maybe these should be federated from the backend
export const getReferralCodeNumClicks = (code: ReferralCode) =>
  code.unique_clicks_count;
export const getReferralCodeNumSignUps = (code: ReferralCode) =>
  code.signup_count;
export const getReferralOwedSum = (code: ReferralCode) =>
  `$${code.currently_owed_to_owner / 100}`;

export const getReferralTotalEarnings = (code: ReferralCode) =>
  `$${code.total_owner_earnings / 100}`;

export const maybePluralize = (count: number, noun: string, suffix = 's') =>
  `${noun}${count !== 1 ? suffix : ''}`;
