/**
 * @generated SignedSource<<00e69c6d88312fe75a1a34a05439a01b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MultipleChoiceQuestionEditQuery$variables = {
  id?: string | null | undefined;
};
export type MultipleChoiceQuestionEditQuery$data = {
  readonly raMultipleChoiceQuestion: {
    readonly id: string | null | undefined;
    readonly " $fragmentSpreads": FragmentRefs<"MultipleChoiceOptionsEditor_question">;
  } | null | undefined;
};
export type MultipleChoiceQuestionEditQuery = {
  response: MultipleChoiceQuestionEditQuery$data;
  variables: MultipleChoiceQuestionEditQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "nodeID",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "MultipleChoiceQuestionEditQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "MultipleChoiceQuestion",
        "kind": "LinkedField",
        "name": "raMultipleChoiceQuestion",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "MultipleChoiceOptionsEditor_question"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "MultipleChoiceQuestionEditQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "MultipleChoiceQuestion",
        "kind": "LinkedField",
        "name": "raMultipleChoiceQuestion",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "MultipleChoiceOption",
            "kind": "LinkedField",
            "name": "options",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "order",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "text",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "audioUrl",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isCorrectAnswer",
                "storageKey": null
              },
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "be518e1a3af0d4675baf7d0462c51edf",
    "id": null,
    "metadata": {},
    "name": "MultipleChoiceQuestionEditQuery",
    "operationKind": "query",
    "text": "query MultipleChoiceQuestionEditQuery(\n  $id: ID\n) {\n  raMultipleChoiceQuestion(id: $id) {\n    id\n    ...MultipleChoiceOptionsEditor_question\n    nodeID\n  }\n}\n\nfragment MultipleChoiceOptionsEditor_option on MultipleChoiceOption {\n  id\n  text\n  audioUrl\n  order\n  isCorrectAnswer\n}\n\nfragment MultipleChoiceOptionsEditor_question on MultipleChoiceQuestion {\n  id\n  options {\n    id\n    order\n    ...MultipleChoiceOptionsEditor_option\n    nodeID\n  }\n}\n"
  }
};
})();

(node as any).hash = "b241e491b4e3f5ab07cf30d016933c1c";

export default node;
