import ShortText from '@material-ui/icons/ShortText';
import { ReactElement } from 'react';
import {
  Datagrid,
  Filter,
  Link,
  List,
  ListProps,
  TextInput,
} from 'react-admin';

import IPAText from '../components/IPAText';
import MultipleChoicePromptCreate from './MultipleChoicePromptCreate';
import MultipleChoicePromptEdit from './MultipleChoicePromptEdit';

const MultipleChoicePromptFilter = (props: any) => (
  <Filter {...props}>
    <TextInput label="Text" source="text" defaultValue="" />
  </Filter>
);

const MultipleChoicePromptList = (props: ListProps): ReactElement => (
  <List
    {...props}
    sort={{ field: 'text', order: 'ASC' }}
    perPage={25}
    filters={<MultipleChoicePromptFilter />}
  >
    <Datagrid optimized>
      <MultipleChoicePromptField />
    </Datagrid>
  </List>
);

const MultipleChoicePromptField = (props: any) =>
  props.record ? (
    <Link to={`/g/multiple-choice-prompts/${props.record.id}`}>
      <IPAText text={props.record.text} />
    </Link>
  ) : null;

MultipleChoicePromptField.defaultProps = {
  source: 'text',
  addLabel: true,
};

export default {
  list: MultipleChoicePromptList,
  edit: MultipleChoicePromptEdit,
  create: MultipleChoicePromptCreate,
  icon: ShortText,
};
