// @ts-nocheck
import * as MUI from '@material-ui/core';
import { ArrowLeft, ArrowRight } from '@material-ui/icons';
import React, { useMemo } from 'react';

import { getScaleColor } from '../gen_conv/GenConvShow';

export function applyPhoneStress(phoneme, stress) {
  return phoneme === 'ah' && stress != null
    ? `${phoneme}${stress === 2 ? 1 : stress}`
    : phoneme;
}

function AudioLogAccordionPhoneScoreList({
  wordScoreList,
  camelCase,
  showHorizontalList = true,
}: any) {
  const [activeWordIndex, setActiveWordIndex] = React.useState(0);
  const activeWord =
    useMemo(
      () => wordScoreList[activeWordIndex],
      [wordScoreList, activeWordIndex],
    ) ?? {};

  const {
    [camelCase ? 'syllableScoreList' : 'syllable_score_list']:
      syllableScoreList,
    [camelCase ? 'phoneScoreList' : 'phone_score_list']: phoneScoreList,
    [camelCase ? 'qualityScore' : 'quality_score']: wordQualityScore,
  } = activeWord;

  const parsedSyllableScoreList = useMemo(() => {
    if (!syllableScoreList) return null;
    let runningIndex = 0;

    const updatedSyllableScoreList = syllableScoreList?.map((syllable) => {
      const selectedPhones = phoneScoreList?.slice(
        runningIndex,
        runningIndex + syllable[camelCase ? 'phoneCount' : 'phone_count'],
      );

      runningIndex += syllable[camelCase ? 'phoneCount' : 'phone_count'];

      return {
        ...syllable,
        phones: selectedPhones,
      };
    });
    return updatedSyllableScoreList;
  }, [syllableScoreList, phoneScoreList]);

  const hasPrevious = activeWordIndex > 0;
  const hasNext = activeWordIndex < wordScoreList.length - 1;
  const goToPrevious = () => {
    setActiveWordIndex((current) => current - 1);
  };
  const goToNext = () => {
    setActiveWordIndex((current) => current + 1);
  };

  return (
    <MUI.Box marginBottom={2}>
      {showHorizontalList && (
        <MUI.Box
          display="flex"
          flexDirection="row"
          maxWidth={500}
          style={{ overflowX: 'scroll' }}
          gridColumnGap={6}
          paddingBottom={4}
        >
          {wordScoreList?.map(
            (
              {
                word,
                [camelCase ? 'qualityScore' : 'quality_score']:
                  wordQualityScore,
              },
              index,
            ) => (
              <MUI.Box key={index}>
                <MUI.Button
                  variant="outlined"
                  onClick={() => setActiveWordIndex(index)}
                >
                  <MUI.Typography
                    style={{
                      color: getScaleColor(wordQualityScore),
                      textTransform: 'initial',
                      whiteSpace: 'nowrap',
                    }}
                    variant="body1"
                  >
                    {word}&nbsp;
                  </MUI.Typography>
                </MUI.Button>
              </MUI.Box>
            ),
          )}
        </MUI.Box>
      )}

      <MUI.Box
        display="flex"
        flexDirection="column"
        style={{ backgroundColor: 'rgb(242, 243, 247)' }}
        borderRadius={12}
        padding={2}
      >
        <MUI.Box alignItems="center" justifyContent="center" flex={1}>
          <MUI.Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <MUI.Button
              variant="contained"
              onClick={goToPrevious}
              disabled={!hasPrevious}
            >
              <ArrowLeft />
            </MUI.Button>
            <MUI.Typography
              style={{
                textAlign: 'center',
                color: getScaleColor(wordQualityScore),
                paddingBottom: 10,
              }}
              variant="subtitle1"
            >
              {Math.round(wordQualityScore)}%
            </MUI.Typography>
            <MUI.Button
              variant="contained"
              onClick={goToNext}
              disabled={!hasNext}
            >
              <ArrowRight />
            </MUI.Button>
          </MUI.Box>
          <MUI.Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="center"
          >
            {parsedSyllableScoreList?.map(
              (
                {
                  letters,
                  phones,
                  [camelCase ? 'stressLevel' : 'stress_level']:
                    syllableStressLevel,
                },
                index,
                array,
              ) => (
                <React.Fragment key={index}>
                  <MUI.Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                  >
                    <MUI.Typography variant="subtitle1">
                      {letters}
                    </MUI.Typography>
                    <MUI.Box flexDirection="row" display="flex">
                      {phones.map(
                        (
                          {
                            phone,
                            [camelCase ? 'stressLevel' : 'stress_level']:
                              stressLevel,
                            [camelCase ? 'qualityScore' : 'quality_score']:
                              phoneQualityScore,
                          },
                          index,
                          array,
                        ) => (
                          <MUI.Typography
                            key={index}
                            variant="subtitle1"
                            style={{
                              color: getScaleColor(phoneQualityScore),
                              textDecorationLine:
                                syllableStressLevel === 1
                                  ? 'underline'
                                  : 'none',
                              textDecorationColor: 'black',
                            }}
                          >
                            {applyPhoneStress(phone, stressLevel)}
                            {index !== array.length - 1 ? '.' : ``}
                          </MUI.Typography>
                        ),
                      )}
                    </MUI.Box>
                  </MUI.Box>
                  {index !== array.length - 1 && (
                    <MUI.Box
                      style={{
                        width: 1,
                        height: 45,
                        backgroundColor: 'gray',
                        marginLeft: 5,
                        marginRight: 5,
                      }}
                    />
                  )}
                </React.Fragment>
              ),
            )}
          </MUI.Box>
        </MUI.Box>
        {parsedSyllableScoreList?.map(({ letters, phones }, index, array) => (
          <MUI.Box
            key={index}
            display="flex"
            flexDirection="row"
            borderColor="gray"
            borderTop={1}
            borderBottom={index === array.length - 1 ? 1 : 0}
            paddingY={1}
            alignItems="center"
            gridColumnGap={12}
          >
            <MUI.Box flex={1}>
              <MUI.Typography variant="body1">{letters}</MUI.Typography>
            </MUI.Box>
            <MUI.Box
              flex={11}
              display="flex"
              flexDirection="column"
              gridRowGap={10}
            >
              {phones?.map(
                (
                  {
                    phone,
                    [camelCase ? 'stressLevel' : 'stress_level']: stressLevel,
                    [camelCase
                      ? 'predictedStressLevel'
                      : 'predicted_stress_level']: predictedStressLevel,
                    [camelCase ? 'soundMostLike' : 'sound_most_like']:
                      soundMostLike,
                    [camelCase ? 'qualityScore' : 'quality_score']:
                      phoneQualityScore,
                  },
                  index,
                ) => (
                  <PhoneRow
                    key={index}
                    phone={phone}
                    stressLevel={stressLevel}
                    predictedStressLevel={predictedStressLevel}
                    soundMostLike={soundMostLike}
                    phoneQualityScore={phoneQualityScore}
                  />
                ),
              )}
            </MUI.Box>
          </MUI.Box>
        ))}
      </MUI.Box>
    </MUI.Box>
  );
}

export default AudioLogAccordionPhoneScoreList;

function PhoneRow({
  phone,
  stressLevel,
  predictedStressLevel,
  soundMostLike,
  phoneQualityScore,
}) {
  const adjustedPhone = useMemo(
    () => applyPhoneStress(phone, stressLevel),
    [phone, stressLevel],
  );

  const adjustedSoundMostLike = useMemo(
    () => applyPhoneStress(soundMostLike, predictedStressLevel),
    [soundMostLike, predictedStressLevel],
  );

  return (
    <MUI.Box
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
    >
      <MUI.Box
        display="flex"
        flexDirection="row"
        gridColumnGap={10}
        flex={1}
        alignItems="center"
      >
        <MUI.Typography
          variant="body1"
          style={{ flex: 1, color: getScaleColor(phoneQualityScore) }}
        >
          {adjustedPhone}
        </MUI.Typography>
        <MUI.Typography variant="body1" style={{ flex: 8 }}>
          {!!adjustedSoundMostLike && adjustedPhone !== adjustedSoundMostLike
            ? `Sounds like ${adjustedSoundMostLike}`
            : ``}
        </MUI.Typography>
      </MUI.Box>
      <MUI.Box
        padding={0.5}
        borderRadius={4}
        style={{
          backgroundColor: getScaleColor(phoneQualityScore),
        }}
      >
        <MUI.Typography variant="body1" style={{ color: 'white' }}>
          {Math.round(phoneQualityScore)}%
        </MUI.Typography>
      </MUI.Box>
    </MUI.Box>
  );
}
