import * as MUI from '@material-ui/core';
import graphql from 'babel-plugin-relay/macro';
import _ from 'lodash';
import React from 'react';
import { loadQuery, usePreloadedQuery, useRelayEnvironment } from 'react-relay';

const IPAText = React.memo((props: { text: string }) => {
  const preloadedQueryRef = React.useContext(IPATextQueryContext);

  if (!preloadedQueryRef) {
    return <span>{props.text}</span>;
  }

  return (
    <React.Suspense fallback={<span>{props.text}</span>}>
      <span>
        <IPATextInner {...props} />
      </span>
    </React.Suspense>
  );
});

const IPATextQueryContext = React.createContext<any>(null);

const IPATextQuery = graphql`
  query IPAText_Query {
    bvSegments {
      ipa
      aliases
      representations {
        alphabetDisplayName
        representation
      }
    }
  }
`;

const IPATextInner = ({ text }) => {
  const preloadedQueryRef = React.useContext(IPATextQueryContext);

  const data = usePreloadedQuery(IPATextQuery, preloadedQueryRef as any, {});

  if (!text) return null;

  return renderer((data as any).bvSegments)(text);
};

const renderer = _.memoize((bvSegments) => {
  const nonSegmentCharsPattern = 'ˌ|ˈ|ː|ˑ| |\\.';

  const segmentPattern = _.orderBy(
    bvSegments.flatMap((segment: any) => [segment.ipa]).map(escapeRegExp),
    (s) => -s.length,
  ).join('|');

  const regex = new RegExp(
    `\\[((?:${segmentPattern}|${nonSegmentCharsPattern})+)\\]`,
  );

  const segmentsRegex = new RegExp(
    `${segmentPattern}|${nonSegmentCharsPattern}`,
    'g',
  );

  const nonSegmentCharsRegex = new RegExp(`^${nonSegmentCharsPattern}$`);

  const segmentsByIPA = Object.fromEntries(
    bvSegments.flatMap((segment: any) =>
      [segment.ipa].map((ipa) => [ipa, segment]),
    ),
  );

  return (text) =>
    text.split(regex).map((part, index) => {
      if (index % 2 === 0) {
        return part;
      }

      const segments = (part.match(segmentsRegex) ?? []).map(
        (segmentString) => {
          return segmentString.match(nonSegmentCharsRegex)
            ? segmentString
            : segmentsByIPA[segmentString];
        },
      );

      return (
        <span
          style={{
            cursor: 'inherit',
            whiteSpace: 'nowrap',
          }}
          key={index}
        >
          <span style={{ opacity: 0.4 }}>[</span>

          {segments.map((segment, segmentsIndex) => (
            <span
              key={segmentsIndex}
              style={{
                ...(segmentsIndex !== 0 && {
                  paddingLeft: '0.08em',
                }),
              }}
            >
              {typeof segment === 'string' ? (
                <span
                  key={segmentsIndex}
                  style={{
                    fontWeight: 600,
                    fontFamily: 'auto',
                    ...(segment === ' ' && {
                      display: 'inline-block',
                      whiteSpace: 'pre',
                      width: '0.5em',
                    }),
                  }}
                >
                  {segment}
                </span>
              ) : (
                <MUI.Tooltip
                  arrow
                  title={
                    <div>
                      <div
                        style={{
                          fontSize: '1.3em',
                          // borderBottom: '1px solid #999',
                          paddingBottom: '4px',
                        }}
                      >
                        {segment.aliases.map((alias, i) => (
                          <React.Fragment key={i}>
                            {' '}
                            <span style={{ opacity: 1 }}>[</span>
                            <span style={{ fontWeight: 600 }}>{alias}</span>
                            <span style={{ opacity: 1 }}>]</span>
                          </React.Fragment>
                        ))}
                      </div>
                      <div
                        style={{
                          marginTop: '7px',
                        }}
                      >
                        {segment.representations.map((representation) => (
                          <div key={representation.representation}>
                            <div
                              style={{
                                display: 'inline-block',
                                minWidth: '70px',
                              }}
                            >
                              {representation.alphabetDisplayName}
                            </div>
                            <div style={{ display: 'inline-block' }}>
                              {representation.representation}
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  }
                >
                  <span
                    style={{
                      backgroundColor: 'hsl(248.14deg 30.67% 25.21% / 8%)',
                      paddingLeft: '0.05em',
                      paddingRight: '0.05em',
                      borderRadius: '0.15em',
                      // fontWeight: 550,
                    }}
                    key={segmentsIndex}
                  >
                    {segment.ipa}
                  </span>
                </MUI.Tooltip>
              )}
            </span>
          ))}

          <span style={{ opacity: 0.4 }}>]</span>
        </span>
      );
    });
});

const escapeRegExp = (string) => string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

export const IPATextQueryProvider = ({ children }) => {
  const relayEnvironment = useRelayEnvironment();

  const preloadedQueryRef = React.useMemo(
    () => loadQuery(relayEnvironment, IPATextQuery, {}),
    [],
  );

  return (
    <IPATextQueryContext.Provider value={preloadedQueryRef}>
      {children}
    </IPATextQueryContext.Provider>
  );
};

export default IPAText;
