/**
 * @generated SignedSource<<61a0b5231581bd399685d5ce36ee1d77>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type IPADrawer_Query$variables = Record<PropertyKey, never>;
export type IPADrawer_Query$data = {
  readonly bvSegments: ReadonlyArray<{
    readonly aliases: ReadonlyArray<string> | null | undefined;
    readonly ipa: string | null | undefined;
    readonly representations: ReadonlyArray<{
      readonly alphabet: string | null | undefined;
      readonly alphabetDisplayName: string | null | undefined;
      readonly representation: string | null | undefined;
    }> | null | undefined;
    readonly types: ReadonlyArray<string> | null | undefined;
  }> | null | undefined;
};
export type IPADrawer_Query = {
  response: IPADrawer_Query$data;
  variables: IPADrawer_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ipa",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "aliases",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "types",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "SegmentRepresentation",
  "kind": "LinkedField",
  "name": "representations",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "alphabet",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "alphabetDisplayName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "representation",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "IPADrawer_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Segment",
        "kind": "LinkedField",
        "name": "bvSegments",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "IPADrawer_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Segment",
        "kind": "LinkedField",
        "name": "bvSegments",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "nodeID",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "8d3bb84b195591569ecdd23f118e8159",
    "id": null,
    "metadata": {},
    "name": "IPADrawer_Query",
    "operationKind": "query",
    "text": "query IPADrawer_Query {\n  bvSegments {\n    ipa\n    aliases\n    types\n    representations {\n      alphabet\n      alphabetDisplayName\n      representation\n    }\n    nodeID\n  }\n}\n"
  }
};
})();

(node as any).hash = "9f5bace344f6043329fcc84ea81ea2c2";

export default node;
