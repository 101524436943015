import AddAPhotoRounded from '@material-ui/icons/AddAPhotoRounded';

import MouthDiagramList from './MouthDiagramList';
import MouthDiagramEdit from './MouthDiagramEdit';
import MouthDiagramCreate from './MouthDiagramCreate';

export type MouthDiagram = {
  id: string;
  imageUrl: string;
  squareImageUrl: string;
  adminNotes: string;
  createdAt: Date;
  updatedAt: Date;
  MouthDiagramSetItem: {
    duration: number;
    orderNumber: number;
  };
};

export default {
  list: MouthDiagramList,
  edit: MouthDiagramEdit,
  icon: AddAPhotoRounded,
  create: MouthDiagramCreate,
};
