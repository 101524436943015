import * as React from 'react';
import { FC } from 'react';
import { Link, FieldProps } from 'react-admin';

import PracticeNameField from './PracticeNameField';

const PracticeLinkField: FC<FieldProps> = (props) =>
  props.record ? (
    <Link to={`/content/practice/${props.record.wordId}`}>
      <PracticeNameField {...props} />
    </Link>
  ) : null;

PracticeLinkField.defaultProps = {
  source: 'wordId',
  label: 'Practice Item',
  addLabel: true,
};

export default PracticeLinkField;
