import { Box, Card, CardContent, Typography } from '@material-ui/core';
import { Theme, makeStyles } from '@material-ui/core/styles';
import { Styles } from '@material-ui/styles/withStyles';
import * as React from 'react';
import { FC } from 'react';
import {
  DeleteButton,
  Edit,
  EditProps,
  FieldProps,
  FormWithRedirect,
  SaveButton,
  TextInput,
  Toolbar,
} from 'react-admin';
import { JsonField } from 'react-admin-json-view';

import FullNameField from './FullNameField';
import UserLinkField from './UserLinkField';

const UserEdit: FC<EditProps> = (props) => (
  <Edit undoable={false} title={<UserTitle />} component="div" {...props}>
    <UserForm />
  </Edit>
);

export const styles: Styles<Theme, any> = {
  name: { display: 'inline-block' },
  url: { display: 'block' },
  image: {
    margin: '0.5rem',
    maxHeight: '10rem',
  },
  button: {
    marginRight: 10,
  },
  select: {
    flex: 1,
    marginRight: 10,
  },
};

const useStyles = makeStyles(styles);

const UserTitle: FC<FieldProps> = ({ record }) =>
  record ? <FullNameField record={record} size="32" /> : null;

const UserForm = (props: any) => {
  const classes = useStyles(props);
  const { record } = props;

  return (
    <FormWithRedirect
      {...props}
      render={(formProps: any) => (
        <Card>
          <form>
            <CardContent>
              <Box display={{ md: 'block', lg: 'flex' }}>
                <Box flex={2} mr={{ md: 0, lg: '1em' }}>
                  {record?.User ? (
                    <>
                      <Typography variant="h6" gutterBottom>
                        Linked User:
                      </Typography>

                      <Box display={{ xs: 'block', sm: 'flex' }}>
                        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                          <UserLinkField record={record} />
                        </Box>
                      </Box>

                      <Box mt="1em" />
                    </>
                  ) : null}

                  <Typography variant="h6" gutterBottom>
                    User Stats
                  </Typography>

                  <Box display={{ xs: 'block', sm: 'flex' }}>
                    <Box mr={{ xs: 0, sm: '0.5em' }}>
                      <TextInput
                        label="Stars"
                        source="starsCount"
                        helperText={false}
                        disabled
                        style={{ minWidth: 250 }}
                      />
                    </Box>
                    <Box
                      display={{ xs: 'block', sm: 'flex' }}
                      style={{ marginTop: 10 }}
                    >
                      <JsonField
                        source="starsCountHistory"
                        record={record}
                        jsonString={false}
                        reactJsonOptions={{
                          name: 'Star History',
                          collapsed: true,
                          enableClipboard: true,
                          displayDataTypes: false,
                          sortKeys: true,
                          displayObjectSize: false,
                        }}
                      />
                    </Box>
                  </Box>

                  <Box display={{ xs: 'block', sm: 'flex' }}>
                    <Box mr={{ xs: 0, sm: '0.5em' }}>
                      <TextInput
                        label="Practice Items"
                        source="uniquePracticeItems"
                        helperText={false}
                        disabled
                        style={{ minWidth: 250 }}
                      />
                    </Box>
                    <Box mr={{ xs: 0, sm: '0.5em' }}>
                      <TextInput
                        label="Words"
                        source="words"
                        helperText={false}
                        disabled
                        style={{ minWidth: 250 }}
                      />
                    </Box>

                    <Box mr={{ xs: 0, sm: '0.5em' }}>
                      <TextInput
                        label="Sentences"
                        source="sentences"
                        helperText={false}
                        disabled
                        style={{ minWidth: 250 }}
                      />
                    </Box>
                  </Box>

                  <Box display={{ xs: 'block', sm: 'flex' }}>
                    <Box mr={{ xs: 0, sm: '0.5em' }}>
                      <TextInput
                        label="Active Days"
                        source="activeDaysCount"
                        helperText={false}
                        disabled
                        style={{ minWidth: 250 }}
                      />
                    </Box>
                    <Box mr={{ xs: 0, sm: '0.5em' }}>
                      <TextInput
                        label="Streak"
                        source="streakCount"
                        helperText={false}
                        disabled
                        style={{ minWidth: 250 }}
                      />
                    </Box>
                    <Box
                      display={{ xs: 'block', sm: 'flex' }}
                      style={{ marginTop: 10 }}
                    >
                      <JsonField
                        source="streakCountHistory"
                        record={record}
                        jsonString={false}
                        reactJsonOptions={{
                          name: 'Streaks History',
                          collapsed: true,
                          enableClipboard: true,
                          displayDataTypes: false,
                          sortKeys: true,
                          displayObjectSize: false,
                        }}
                      />
                    </Box>
                  </Box>

                  <Box display={{ xs: 'block', sm: 'flex' }}>
                    <Box mr={{ xs: 0, sm: '0.5em' }}>
                      <TextInput
                        label="7 Day Rolling Average Score"
                        source="averageScoreAllPracticeSevenDaysRolling"
                        helperText={false}
                        disabled
                        style={{ minWidth: 250 }}
                      />
                    </Box>
                    <Box
                      display={{ xs: 'block', sm: 'flex' }}
                      style={{ marginTop: 10 }}
                    >
                      <JsonField
                        source="averageScoreAllPracticeHistorySevenDaysRolling"
                        record={record}
                        jsonString={false}
                        reactJsonOptions={{
                          name: '7 Day Rolling Average Score History',
                          collapsed: true,
                          enableClipboard: true,
                          displayDataTypes: false,
                          sortKeys: true,
                          displayObjectSize: false,
                        }}
                      />
                    </Box>
                  </Box>

                  <Box display={{ xs: 'block', sm: 'flex' }}>
                    <Box mr={{ xs: 0, sm: '0.5em' }}>
                      <TextInput
                        label="Practice Count (all practice)"
                        source="allPracticeCount"
                        helperText={false}
                        disabled
                        style={{ minWidth: 250 }}
                      />
                    </Box>
                    <Box mr={{ xs: 0, sm: '0.5em' }}>
                      <TextInput
                        label="Average Score (all practice)"
                        source="averageScoreAllPractice"
                        helperText={false}
                        disabled
                        style={{ minWidth: 250 }}
                      />
                    </Box>
                    <Box
                      display={{ xs: 'block', sm: 'flex' }}
                      style={{ marginTop: 10 }}
                    >
                      <JsonField
                        source="averageScoreAllPracticeHistory"
                        record={record}
                        jsonString={false}
                        reactJsonOptions={{
                          name: 'Average Score History',
                          collapsed: true,
                          enableClipboard: true,
                          displayDataTypes: false,
                          sortKeys: true,
                          displayObjectSize: false,
                        }}
                      />
                    </Box>
                  </Box>

                  <Box display={{ xs: 'block', sm: 'flex' }}>
                    <Box mr={{ xs: 0, sm: '0.5em' }}>
                      <TextInput
                        label="Practice Count (practice set)"
                        source="practiceSetPracticeCount"
                        helperText={false}
                        disabled
                        style={{ minWidth: 250 }}
                      />
                    </Box>
                    <Box mr={{ xs: 0, sm: '0.5em' }}>
                      <TextInput
                        label="Average Score (practice set)"
                        source="averageScorePracticeSetPractice"
                        helperText={false}
                        disabled
                        style={{ minWidth: 250 }}
                      />
                    </Box>
                    <Box
                      display={{ xs: 'block', sm: 'flex' }}
                      style={{ marginTop: 10 }}
                    >
                      <JsonField
                        source="averageScorePracticeSetPracticeHistory"
                        record={record}
                        jsonString={false}
                        reactJsonOptions={{
                          name: 'Practice Set History',
                          collapsed: true,
                          enableClipboard: true,
                          displayDataTypes: false,
                          sortKeys: true,
                          displayObjectSize: false,
                        }}
                      />
                    </Box>
                  </Box>

                  <Box display={{ xs: 'block', sm: 'flex' }}>
                    <Box mr={{ xs: 0, sm: '0.5em' }}>
                      <TextInput
                        label="Practice Count (in video)"
                        source="inVideoPracticeCount"
                        helperText={false}
                        disabled
                        style={{ minWidth: 250 }}
                      />
                    </Box>
                    <Box mr={{ xs: 0, sm: '0.5em' }}>
                      <TextInput
                        label="Average Score (in video)"
                        source="averageScoreInVideoPractice"
                        helperText={false}
                        disabled
                        style={{ minWidth: 250 }}
                      />
                    </Box>
                    <Box
                      display={{ xs: 'block', sm: 'flex' }}
                      style={{ marginTop: 10 }}
                    >
                      <JsonField
                        source="averageScoreInVideoPracticeHistory"
                        record={record}
                        jsonString={false}
                        reactJsonOptions={{
                          name: 'In Video History',
                          collapsed: true,
                          enableClipboard: true,
                          displayDataTypes: false,
                          sortKeys: true,
                          displayObjectSize: false,
                        }}
                      />
                    </Box>
                  </Box>

                  <Box display={{ xs: 'block', sm: 'flex' }}>
                    <Box mr={{ xs: 0, sm: '0.5em' }}>
                      <TextInput
                        label="Practice Count (conversations)"
                        source="conversationPracticeCount"
                        helperText={false}
                        disabled
                        style={{ minWidth: 250 }}
                      />
                    </Box>
                    <Box mr={{ xs: 0, sm: '0.5em' }}>
                      <TextInput
                        label="Average Score (conversations)"
                        source="averageScoreConversationPractice"
                        helperText={false}
                        disabled
                        style={{ minWidth: 250 }}
                      />
                    </Box>
                    <Box
                      display={{ xs: 'block', sm: 'flex' }}
                      style={{ marginTop: 10 }}
                    >
                      <JsonField
                        source="averageScoreConversationPracticeHistory"
                        record={record}
                        jsonString={false}
                        reactJsonOptions={{
                          name: 'Conversations History',
                          collapsed: true,
                          enableClipboard: true,
                          displayDataTypes: false,
                          sortKeys: true,
                          displayObjectSize: false,
                        }}
                      />
                    </Box>
                  </Box>

                  <Box display={{ xs: 'block', sm: 'flex' }}>
                    <Box
                      display={{ xs: 'block', sm: 'flex' }}
                      style={{ marginTop: 10 }}
                    >
                      <JsonField
                        source="averageScorePerSound"
                        record={record}
                        jsonString={false}
                        reactJsonOptions={{
                          name: 'Average Per Sound',
                          collapsed: true,
                          enableClipboard: true,
                          displayDataTypes: false,
                          sortKeys: true,
                          displayObjectSize: false,
                        }}
                      />
                    </Box>
                    <Box
                      display={{ xs: 'block', sm: 'flex' }}
                      style={{ marginTop: 10 }}
                    >
                      <JsonField
                        source="averageScorePerSoundHistory"
                        record={record}
                        jsonString={false}
                        reactJsonOptions={{
                          name: 'Per Sound History',
                          collapsed: true,
                          enableClipboard: true,
                          displayDataTypes: false,
                          sortKeys: true,
                          displayObjectSize: false,
                        }}
                      />
                    </Box>
                  </Box>
                </Box>
              </Box>
            </CardContent>
            <Toolbar
              record={formProps.record}
              basePath={formProps.basePath}
              undoable
              invalid={formProps.invalid}
              handleSubmit={formProps.handleSubmit}
              saving={formProps.saving}
              resource="users"
              style={{ display: 'flex', justifyContent: 'space-between' }}
            >
              <div>
                <SaveButton
                  label="Save & Continue"
                  handleSubmitWithRedirect={
                    formProps.handleSubmitWithRedirect || formProps.handleSubmit
                  }
                  disabled={formProps.disabled}
                  invalid={formProps.invalid}
                  redirect="edit"
                  saving={formProps.saving}
                  submitOnEnter={formProps.submitOnEnter}
                  className={classes.button}
                />

                <SaveButton
                  label="Save & Exit"
                  handleSubmitWithRedirect={
                    formProps.handleSubmitWithRedirect || formProps.handleSubmit
                  }
                  disabled={formProps.disabled}
                  invalid={formProps.invalid}
                  redirect={formProps.redirect}
                  saving={formProps.saving}
                  submitOnEnter={formProps.submitOnEnter}
                  className={classes.button}
                />
              </div>

              <div>
                {formProps.record &&
                  typeof formProps.record.id !== 'undefined' && (
                    <DeleteButton
                      basePath={formProps.basePath}
                      record={formProps.record}
                      resource={formProps.resource}
                      undoable={formProps.undoable}
                      mutationMode={formProps.mutationMode}
                    />
                  )}
              </div>
            </Toolbar>
          </form>
        </Card>
      )}
    />
  );
};

export default UserEdit;
