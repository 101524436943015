/**
 * @generated SignedSource<<d824cd68d4928400f2db52c359fc3d55>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PhonemesSelect_phonemesByIDs_RefetchQuery$variables = {
  ids?: ReadonlyArray<string> | null | undefined;
};
export type PhonemesSelect_phonemesByIDs_RefetchQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"PhonemesSelect_phonemesByIDs">;
};
export type PhonemesSelect_phonemesByIDs_RefetchQuery = {
  response: PhonemesSelect_phonemesByIDs_RefetchQuery$data;
  variables: PhonemesSelect_phonemesByIDs_RefetchQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": ([]/*: any*/),
    "kind": "LocalArgument",
    "name": "ids"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "ids",
    "variableName": "ids"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PhonemesSelect_phonemesByIDs_RefetchQuery",
    "selections": [
      {
        "args": (v1/*: any*/),
        "kind": "FragmentSpread",
        "name": "PhonemesSelect_phonemesByIDs"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PhonemesSelect_phonemesByIDs_RefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Phoneme",
        "kind": "LinkedField",
        "name": "phonemesByID",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "arpabet",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "ipa",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "nodeID",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a2d43c1e2ed0b734f7710b6ba4a484b6",
    "id": null,
    "metadata": {},
    "name": "PhonemesSelect_phonemesByIDs_RefetchQuery",
    "operationKind": "query",
    "text": "query PhonemesSelect_phonemesByIDs_RefetchQuery(\n  $ids: [ID!] = []\n) {\n  ...PhonemesSelect_phonemesByIDs_3pY7A9\n}\n\nfragment PhonemesSelect_phonemesByIDs_3pY7A9 on Query {\n  phonemesByID(ids: $ids) {\n    id\n    arpabet\n    ipa\n    nodeID\n  }\n}\n"
  }
};
})();

(node as any).hash = "0c4bf6e8cd4a6ba6127aeec1a3aedddf";

export default node;
