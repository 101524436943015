/**
 * @generated SignedSource<<09f4c561a5e3202212173dc1161160cf>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SkillAssessments_SkillAssessmentDetails_Query$variables = {
  skillAssessmentID: string;
};
export type SkillAssessments_SkillAssessmentDetails_Query$data = {
  readonly skillAssessmentByID: {
    readonly bottomAudioLogExamples: ReadonlyArray<{
      readonly " $fragmentSpreads": FragmentRefs<"DLPAssessmentsTaken2_SkillAssessmentExample">;
    }> | null | undefined;
    readonly id: string | null | undefined;
    readonly rawPosterior: ReadonlyArray<number> | null | undefined;
    readonly topAudioLogExamples: ReadonlyArray<{
      readonly " $fragmentSpreads": FragmentRefs<"DLPAssessmentsTaken2_SkillAssessmentExample">;
    }> | null | undefined;
  } | null | undefined;
};
export type SkillAssessments_SkillAssessmentDetails_Query = {
  response: SkillAssessments_SkillAssessmentDetails_Query$data;
  variables: SkillAssessments_SkillAssessmentDetails_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "skillAssessmentID"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "skillAssessmentID"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rawPosterior",
  "storageKey": null
},
v4 = [
  {
    "args": null,
    "kind": "FragmentSpread",
    "name": "DLPAssessmentsTaken2_SkillAssessmentExample"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "nodeID",
  "storageKey": null
},
v6 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "speechaceExtents",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "AudioLog",
    "kind": "LinkedField",
    "name": "audioLog",
    "plural": false,
    "selections": [
      (v2/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "speechaceParsed",
        "storageKey": null
      },
      (v5/*: any*/)
    ],
    "storageKey": null
  },
  (v5/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SkillAssessments_SkillAssessmentDetails_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SkillAssessment",
        "kind": "LinkedField",
        "name": "skillAssessmentByID",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "SkillAssessmentExample",
            "kind": "LinkedField",
            "name": "topAudioLogExamples",
            "plural": true,
            "selections": (v4/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "SkillAssessmentExample",
            "kind": "LinkedField",
            "name": "bottomAudioLogExamples",
            "plural": true,
            "selections": (v4/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SkillAssessments_SkillAssessmentDetails_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SkillAssessment",
        "kind": "LinkedField",
        "name": "skillAssessmentByID",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "SkillAssessmentExample",
            "kind": "LinkedField",
            "name": "topAudioLogExamples",
            "plural": true,
            "selections": (v6/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "SkillAssessmentExample",
            "kind": "LinkedField",
            "name": "bottomAudioLogExamples",
            "plural": true,
            "selections": (v6/*: any*/),
            "storageKey": null
          },
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "6d126994e96721be6bafceac1a1629ec",
    "id": null,
    "metadata": {},
    "name": "SkillAssessments_SkillAssessmentDetails_Query",
    "operationKind": "query",
    "text": "query SkillAssessments_SkillAssessmentDetails_Query(\n  $skillAssessmentID: ID!\n) {\n  skillAssessmentByID(id: $skillAssessmentID) {\n    id\n    rawPosterior\n    topAudioLogExamples {\n      ...DLPAssessmentsTaken2_SkillAssessmentExample\n      nodeID\n    }\n    bottomAudioLogExamples {\n      ...DLPAssessmentsTaken2_SkillAssessmentExample\n      nodeID\n    }\n    nodeID\n  }\n}\n\nfragment DLPAssessmentsTaken2_SkillAssessmentExample on SkillAssessmentExample {\n  speechaceExtents\n  audioLog {\n    id\n    speechaceParsed\n    nodeID\n  }\n}\n"
  }
};
})();

(node as any).hash = "fadf161ae6763e1698aede5be4459609";

export default node;
