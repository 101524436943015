import GradeIcon from '@material-ui/icons/Grade';

import AssessmentList from './AssessmentList';
import AssessmentEdit from './AssessmentEdit';
import AssessmentCreate from './AssessmentCreate';

export default {
  list: AssessmentList,
  edit: AssessmentEdit,
  icon: GradeIcon,
  create: AssessmentCreate,
};
