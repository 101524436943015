/**
 * @generated SignedSource<<bc493e1b18575c9c9f26f7b304f354f2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type SkillLessonSelect_allSkillLessons_Query$variables = Record<PropertyKey, never>;
export type SkillLessonSelect_allSkillLessons_Query$data = {
  readonly allNonLinkedSkillLessons: ReadonlyArray<{
    readonly conversation: {
      readonly name: string | null | undefined;
    } | null | undefined;
    readonly id: string | null | undefined;
    readonly skill: {
      readonly id: string | null | undefined;
    } | null | undefined;
    readonly video: {
      readonly title: string | null | undefined;
    } | null | undefined;
  }> | null | undefined;
};
export type SkillLessonSelect_allSkillLessons_Query = {
  response: SkillLessonSelect_allSkillLessons_Query$data;
  variables: SkillLessonSelect_allSkillLessons_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "nodeID",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "SkillLessonSelect_allSkillLessons_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "SkillLesson",
        "kind": "LinkedField",
        "name": "allNonLinkedSkillLessons",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Video",
            "kind": "LinkedField",
            "name": "video",
            "plural": false,
            "selections": [
              (v1/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Conversation",
            "kind": "LinkedField",
            "name": "conversation",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Skill",
            "kind": "LinkedField",
            "name": "skill",
            "plural": false,
            "selections": [
              (v0/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "SkillLessonSelect_allSkillLessons_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "SkillLesson",
        "kind": "LinkedField",
        "name": "allNonLinkedSkillLessons",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Video",
            "kind": "LinkedField",
            "name": "video",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Conversation",
            "kind": "LinkedField",
            "name": "conversation",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Skill",
            "kind": "LinkedField",
            "name": "skill",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "814ca11379b0f36fe96d14d4aba3af87",
    "id": null,
    "metadata": {},
    "name": "SkillLessonSelect_allSkillLessons_Query",
    "operationKind": "query",
    "text": "query SkillLessonSelect_allSkillLessons_Query {\n  allNonLinkedSkillLessons {\n    id\n    video {\n      title\n      nodeID\n    }\n    conversation {\n      name\n      nodeID\n    }\n    skill {\n      id\n      nodeID\n    }\n    nodeID\n  }\n}\n"
  }
};
})();

(node as any).hash = "f05a7ec49929f852de4c7ea41ced8e6b";

export default node;
