import * as React from 'react';
import { FC } from 'react';
import {
  Create,
  CreateProps,
  SimpleForm,
  TextInput,
  NumberInput,
  ReferenceInput,
  SelectInput,
  required,
} from 'react-admin';

import { makeStyles, Theme } from '@material-ui/core/styles';
import { Styles } from '@material-ui/styles/withStyles';
import { Box } from '@material-ui/core';
import { TESTING_VARIANTS } from './UnitEdit';

export const styles: Styles<Theme, any> = {
  name: { display: 'inline-block' },
  number: { display: 'inline-block', marginLeft: 10 },
};

const useStyles = makeStyles(styles);

const UserCreate: FC<CreateProps> = (props) => {
  const classes = useStyles(props);

  return (
    <Create {...props}>
      <SimpleForm autoComplete="off">
        <TextInput
          fullWidth
          autoFocus
          source="name"
          label="Unit Name"
          className={classes.name}
          validate={requiredValidate}
        />
        <Box display="flex" style={{ width: '100%' }}>
          <ReferenceInput
            validate={requiredValidate}
            style={{ width: '50%' }}
            label="Curriculum"
            source="curriculumId"
            reference="content/curriculum"
          >
            <SelectInput optionText="name" />
          </ReferenceInput>
          <NumberInput
            source="unitNumber"
            label="Unit Number"
            className={classes.number}
            validate={requiredValidate}
          />
          <SelectInput
            label="Testing Variant"
            source="testingVariant"
            helperText={false}
            choices={TESTING_VARIANTS}
            allowEmpty
            style={{ marginLeft: 10 }}
          />
          <TextInput
            source="testName"
            label="Experiment Name"
            className={classes.description}
            style={{ marginLeft: 10 }}
          />
        </Box>
      </SimpleForm>
    </Create>
  );
};

const requiredValidate = [required()];

export default UserCreate;
