import * as React from 'react';
import { FC } from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { FieldProps } from 'react-admin';
import { stringify } from 'query-string';

import lesson from '../lesson';

const useStyles = makeStyles({
  icon: { paddingRight: '0.5em' },
  link: {
    display: 'inline-flex',
    alignItems: 'center',
  },
});

const LinkToRelatedLessons: FC<FieldProps> = ({ record }) => {
  const classes = useStyles();
  return record ? (
    <Button
      size="small"
      color="primary"
      component={Link}
      to={{
        pathname: '/content/lesson',
        search: stringify({
          filter: JSON.stringify({ Unit: { curriculumId: record.id } }),
        }),
      }}
      className={classes.link}
    >
      <lesson.icon className={classes.icon} />
      Lessons
    </Button>
  ) : null;
};

export default LinkToRelatedLessons;
