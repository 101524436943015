import * as React from 'react';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { makeStyles } from '@material-ui/core/styles';
import { useShowController } from 'react-admin';

const VideoFeedbackShow = (props: any) => {
  const { record } = useShowController(props);
  const classes = useStyles();

  const text = record?.feedbackText || 'No comments provided.';

  return (
    <Card className={classes.root}>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography gutterBottom>{text}</Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default VideoFeedbackShow;

const useStyles = makeStyles({
  root: { margin: 'auto' },
  spacer: { height: 20 },
  invoices: { margin: '10px 0' },
});
