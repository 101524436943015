import * as React from 'react';
import {
  List,
  Datagrid,
  TextField,
  DateField,
  TextInput,
  Filter,
} from 'react-admin';
import UserLinkField from './UserLinkField';
// import EmailLogsShow from './EmailLogsShow';

const EmailLogsFilter = (props: any) => (
  <Filter {...props}>
    <TextInput
      label="User First Name"
      source="User.firstName"
      defaultValue=""
    />
    <TextInput label="User Last Name" source="User.lastName" defaultValue="" />
    <TextInput label="Email ID" source="emailId" defaultValue="" />
    <TextInput label="Subject" source="emailSubject" defaultValue="" />
  </Filter>
);

export const EmailLogsList = (props: any) => (
  <List
    {...props}
    perPage={10}
    sort={{ field: 'createdAt', order: 'DESC' }}
    filters={<EmailLogsFilter />}
  >
    <Datagrid rowClick="expand" /* expand={<EmailLogsShow />} */>
      <UserLinkField />
      <TextField source="emailId" label="Email ID" />
      <TextField source="emailSubject" label="Subject" />
      <DateField showTime source="createdAt" label="Sent At" />
    </Datagrid>
  </List>
);

export default EmailLogsList;
