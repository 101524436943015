import * as React from 'react';
import { FC } from 'react';
import { Link, FieldProps } from 'react-admin';

const ReferralCodeLinkField: FC<FieldProps> = (props) => {
  if (props.record && props.source) {
    const referralCodeId = props.record[props.source];

    return (
      <Link to={`/logs/referral_codes/${referralCodeId}`}>
        <div>{props.record?.ReferralCode?.slug}</div>
      </Link>
    );
  }
  return null;
};

export default ReferralCodeLinkField;
