/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { Button, Typography } from '@material-ui/core';
import {
  BooleanInput,
  NumberInput,
  ReferenceInput,
  SelectInput,
  useRefresh,
} from 'react-admin';
import api from '../../../api';
import MouthDiagramsDisplay from '../../../components/MouthDiagramsDisplay';
import { Form } from 'react-final-form';
import { MouthDiagramSet } from '../../../mouthDiagramSet';

const MouthDiagramSetEditForm = ({ record }: any) => {
  const refresh = useRefresh();

  const handleSave = async (data: any) => {
    const { id, videoId, createdAt, updatedAt, graphicType, ...rest } = data;

    try {
      const response = await api.patchVideoMouthDiagramSet(
        data.videoId,
        data.id,
        rest,
      );

      if (response.status < 300) {
        refresh();
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  const handleDelete = async () => {
    const response = window.confirm('Are you sure you want to do that?');

    if (response) {
      try {
        await api.deleteVideoMouthDiagramSet(record?.videoId, record.id);
        refresh();
      } catch (error) {
        console.log('error', error);
      }
    }
  };

  return (
    <Form
      initialValues={record}
      onSubmit={handleSave}
      render={({ handleSubmit }: any) => (
        <form
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
          onSubmit={handleSubmit}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Typography variant="h6" gutterBottom>
              Video Mouth Diagram Set from {record.from} to {record.to}
            </Typography>

            <div
              style={{
                minWidth: 300,
                marginRight: 10,
              }}
            >
              <NumberInput
                label="From"
                source="from"
                style={{ marginRight: 12 }}
              />
              <NumberInput label="To" source="to" />
              <BooleanInput label="Is In Ms" source="isInMs" />
              <BooleanInput
                label="Should Auto Pause?"
                source="shouldAutoPause"
              />
              <BooleanInput
                label="Should be included in Video Summary?"
                source="shouldBeIncludedInSummary"
              />
            </div>
            {record?.mouthDiagramSet ? (
              <MouthDiagramsDisplay
                diagrams={record.mouthDiagramSet.mouthDiagrams}
              />
            ) : null}
            <ReferenceInput
              label="Mouth Diagram Set"
              source="mouthDiagramSetId"
              reference="content/mouthDiagramSet"
              sort={{ field: 'internalName', order: 'ASC' }}
              perPage={1000}
              fullWidth
            >
              <SelectInput
                optionValue="id"
                source="mouthDiagramSetId"
                optionText={(rec: MouthDiagramSet) =>
                  `${rec.publicName} / ${rec.internalName}`
                }
              />
            </ReferenceInput>
            {record.mouthDiagramSet?.publicName ? (
              <Typography variant="body1" gutterBottom>
                Public Name: {record.mouthDiagramSet?.publicName}
              </Typography>
            ) : null}
            {record.mouthDiagramSet?.internalName ? (
              <Typography variant="body1" gutterBottom>
                Internal Name: {record.mouthDiagramSet?.internalName}
              </Typography>
            ) : null}

            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Button variant="contained" type="submit" onClick={handleSubmit}>
                Save
              </Button>
              {record && typeof record.id !== 'undefined' && (
                <Button onClick={handleDelete}>Delete</Button>
              )}
            </div>
          </div>
        </form>
      )}
    />
  );
};

export default MouthDiagramSetEditForm;
