/**
 * @generated SignedSource<<208ca1cb7a430df5c454429b8ede2f13>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MultipleChoiceOptionsEditor_MultipleChoiceOption_Mutation$variables = {
  audioUrl?: string | null | undefined;
  id?: string | null | undefined;
  isCorrectAnswer?: boolean | null | undefined;
  text?: string | null | undefined;
};
export type MultipleChoiceOptionsEditor_MultipleChoiceOption_Mutation$data = {
  readonly raUpdateMultipleChoiceOption: {
    readonly " $fragmentSpreads": FragmentRefs<"MultipleChoiceOptionsEditor_option">;
  } | null | undefined;
};
export type MultipleChoiceOptionsEditor_MultipleChoiceOption_Mutation = {
  response: MultipleChoiceOptionsEditor_MultipleChoiceOption_Mutation$data;
  variables: MultipleChoiceOptionsEditor_MultipleChoiceOption_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "audioUrl"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isCorrectAnswer"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "text"
},
v4 = [
  {
    "kind": "Variable",
    "name": "audioUrl",
    "variableName": "audioUrl"
  },
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  },
  {
    "kind": "Variable",
    "name": "isCorrectAnswer",
    "variableName": "isCorrectAnswer"
  },
  {
    "kind": "Variable",
    "name": "text",
    "variableName": "text"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "MultipleChoiceOptionsEditor_MultipleChoiceOption_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "MultipleChoiceOption",
        "kind": "LinkedField",
        "name": "raUpdateMultipleChoiceOption",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "MultipleChoiceOptionsEditor_option"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v3/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "MultipleChoiceOptionsEditor_MultipleChoiceOption_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "MultipleChoiceOption",
        "kind": "LinkedField",
        "name": "raUpdateMultipleChoiceOption",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "text",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "audioUrl",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "order",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isCorrectAnswer",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "nodeID",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "0249321d0d48297d4514a712b3ee0138",
    "id": null,
    "metadata": {},
    "name": "MultipleChoiceOptionsEditor_MultipleChoiceOption_Mutation",
    "operationKind": "mutation",
    "text": "mutation MultipleChoiceOptionsEditor_MultipleChoiceOption_Mutation(\n  $id: ID\n  $text: String\n  $audioUrl: String\n  $isCorrectAnswer: Boolean\n) {\n  raUpdateMultipleChoiceOption(id: $id, text: $text, audioUrl: $audioUrl, isCorrectAnswer: $isCorrectAnswer) {\n    ...MultipleChoiceOptionsEditor_option\n    nodeID\n  }\n}\n\nfragment MultipleChoiceOptionsEditor_option on MultipleChoiceOption {\n  id\n  text\n  audioUrl\n  order\n  isCorrectAnswer\n}\n"
  }
};
})();

(node as any).hash = "c0a3b10636cb7fab45ebb7582c7ba6a2";

export default node;
