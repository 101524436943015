import * as MUI from '@material-ui/core';
import * as MUIColors from '@material-ui/core/colors';
import * as MUIIcons from '@material-ui/icons';
import React from 'react';
import { useInput } from 'react-admin';
import { useDropzone } from 'react-dropzone';

import api from '../api';

const AudioUpload = (props: {
  value?: string | null;
  onChange?: (value: string | null) => void;
}) => {
  const [droppedFile, setDroppedFile] = React.useState<File | null>(null);
  const [audioURL, setAudioURL] = React.useState<string | null>(
    props.value ?? null,
  );

  const onDrop = React.useCallback(
    async (acceptedFiles) => {
      if (acceptedFiles.length === 0) return;

      setDroppedFile(acceptedFiles[0] ?? null);

      const response = await api.request({
        url: '/api/v1/content/upload',
        method: 'POST',
        body: acceptedFiles[0],
        headers: {
          'Content-Type': 'audio/mpeg',
        },
      });

      setAudioURL(response.json.url);
      setDroppedFile(null);
      props.onChange?.(response.json.url);
    },
    [props.onChange],
  );

  const { getRootProps, getInputProps, isDragAccept } = useDropzone({
    onDrop,
    accept: { 'audio/mpeg': [] },
    multiple: false,
    maxSize: 100_000_000, // 100 MB
  });

  const sharedStyles = {
    padding: 8,
    minHeight: 38,
    borderRadius: 4,
    borderWidth: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 7,
  } as const;

  return audioURL ? (
    <div
      style={{
        ...sharedStyles,
        borderColor: MUIColors.grey[300],
        borderStyle: 'solid',
      }}
    >
      <audio controls style={{ height: 30 }}>
        <source src={audioURL} type="audio/mpeg" />
      </audio>

      <MUI.Button
        onClick={() => {
          setAudioURL(null);
          setDroppedFile(null);
          props.onChange?.(null);
        }}
        style={{ marginLeft: 15 }}
        color="secondary"
      >
        Remove
      </MUI.Button>
    </div>
  ) : droppedFile ? (
    <div
      style={{
        ...sharedStyles,
        borderColor: MUIColors.grey[300],
        borderStyle: 'solid',
        justifyContent: 'center',
      }}
    >
      <MUI.CircularProgress size={20} />

      <MUI.Typography variant="body1" style={{ marginLeft: 15 }}>
        Uploading {droppedFile.name}
      </MUI.Typography>
    </div>
  ) : (
    <div
      {...getRootProps({
        style: {
          ...sharedStyles,
          backgroundColor: MUIColors.grey[100],
          borderColor: isDragAccept
            ? MUIColors.deepPurple[400]
            : MUIColors.grey[400],
          borderStyle: 'dashed',
          justifyContent: 'center',
        },
      })}
    >
      <input {...getInputProps()} />

      <MUIIcons.CloudUpload htmlColor={MUIColors.grey[600]} />

      <MUI.Typography
        variant="body1"
        color="textSecondary"
        style={{ marginLeft: 15 }}
      >
        {isDragAccept
          ? 'Drop the file here.'
          : 'Drag and drop an audio file here, or click to select.'}
      </MUI.Typography>
    </div>
  );
};

export const AudioUploadInput = (props: any) => {
  const {
    input: { onChange, value },
    isRequired,
  } = useInput(props);

  return (
    <MUI.Box marginTop={1}>
      <MUI.FormLabel focused={false} required={props.required ?? isRequired}>
        {props.label}
      </MUI.FormLabel>
      <AudioUpload value={value} onChange={onChange} />
    </MUI.Box>
  );
};

export default AudioUpload;
