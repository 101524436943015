/**
 * @generated SignedSource<<f5e363d4c4b5014343f6318dc75f296a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type WordEdit_SourceEdge_query$data = {
  readonly partsOfSpeech: ReadonlyArray<{
    readonly description: string | null | undefined;
    readonly tag: string | null | undefined;
  }> | null | undefined;
  readonly " $fragmentType": "WordEdit_SourceEdge_query";
};
export type WordEdit_SourceEdge_query$key = {
  readonly " $data"?: WordEdit_SourceEdge_query$data;
  readonly " $fragmentSpreads": FragmentRefs<"WordEdit_SourceEdge_query">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "WordEdit_SourceEdge_query",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "PartOfSpeech",
      "kind": "LinkedField",
      "name": "partsOfSpeech",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "tag",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "description",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "9aa4f2b625ce85a825a64c726ff36199";

export default node;
