import * as React from 'react';
import { FC, memo } from 'react';

import { FieldProps, ReferenceField, TextField } from 'react-admin';

type Props = FieldProps;

const CurriculumNameField: FC<Props> = ({ record }) =>
  record ? (
    <ReferenceField
      label="Curriculum"
      source="curriculumId"
      reference="content/curriculum"
    >
      <TextField source="name" />
    </ReferenceField>
  ) : null;

CurriculumNameField.defaultProps = {
  source: 'name',
  label: 'Name',
};

export default memo<Props>(CurriculumNameField);
