import * as React from 'react';
import { FC } from 'react';
import { FieldProps } from 'react-admin';
import { useNotify } from 'react-admin';

const TruncatedIdField: FC<FieldProps> = (props) => {
  const id = String(props?.record?.id)?.slice(0, 4);
  const notify = useNotify();

  return (
    <span
      style={{
        cursor: 'pointer',
        padding: '0.1rem 0.5rem',
      }}
      onDoubleClick={() => {
        navigator.clipboard
          .writeText(String(props?.record?.id))
          .then(() => {
            notify(`Copied ${props?.record?.id}`);
          })
          .catch(() => {
            notify(`Error copying the id`, 'warning');
          });
      }}
    >
      {id}
    </span>
  );
};

TruncatedIdField.defaultProps = {
  label: 'Manage URL',
  addLabel: true,
};

export default TruncatedIdField;
