import * as React from 'react';
import { FC } from 'react';
import {
  Create,
  CreateProps,
  SimpleForm,
  TextInput,
  NumberInput,
  required,
} from 'react-admin';

import { makeStyles, Theme } from '@material-ui/core/styles';
import { Styles } from '@material-ui/styles/withStyles';
import { JsonInput } from 'react-admin-json-view';

export const styles: Styles<Theme, any> = {
  name: { display: 'inline-block' },
  number: { display: 'inline-block', marginLeft: 10 },
};

const useStyles = makeStyles(styles);

const UserCreate: FC<CreateProps> = (props) => {
  const classes = useStyles(props);

  return (
    <Create {...props}>
      <SimpleForm initialValues={{ target_sounds: [], highscores: [] }}>
        <TextInput
          fullWidth
          autoFocus
          source="title"
          label="Title"
          className={classes.name}
          validate={requiredValidate}
        />
        <TextInput
          fullWidth
          autoFocus
          source="artist"
          label="Arist"
          className={classes.name}
          validate={requiredValidate}
        />
        <TextInput
          fullWidth
          autoFocus
          source="audio_file_name"
          label="Audio File Name"
          className={classes.name}
          validate={requiredValidate}
        />
        <TextInput
          fullWidth
          autoFocus
          source="thumbnail_url"
          label="Thumbnail URL"
          className={classes.name}
          validate={requiredValidate}
        />
        <NumberInput
          fullWidth
          autoFocus
          source="duration"
          label="Duration"
          className={classes.name}
          validate={requiredValidate}
        />
        <JsonInput
          source="target_sounds"
          jsonString={false}
          reactJsonOptions={{
            name: 'Target Sounds',
            collapsed: false,
            enableClipboard: true,
            displayDataTypes: false,
            sortKeys: true,
            displayObjectSize: false,
          }}
        />
        <JsonInput
          source="highscores"
          jsonString={false}
          reactJsonOptions={{
            name: 'High Scores',
            collapsed: false,
            enableClipboard: true,
            displayDataTypes: false,
            sortKeys: true,
            displayObjectSize: false,
          }}
        />
      </SimpleForm>
    </Create>
  );
};

const requiredValidate = [required()];

export default UserCreate;
