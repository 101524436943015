// @ts-nocheck
import React from 'react';
import { useInput } from 'react-admin';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useForm } from 'react-final-form';
import styled from 'styled-components';

export const difficultyList = [
  {
    id: 'none',
    value: null,
    name: 'Unassigned',
  },
  {
    id: 'easy',
    value: 'easy',
    name: 'Easy',
  },
  {
    id: 'medium',
    value: 'medium',
    name: 'Medium',
  },
  {
    id: 'hard',
    value: 'hard',
    name: 'Hard',
  },
  {
    id: 'very_hard',
    value: 'very_hard',
    name: 'Very Hard',
  },
];

const LessonPracticeInput = (props: any) => {
  const {
    id,
    choices,
    label,
    showDifficulties = true,
    showPracticeSets = false,
    lessonId,
  } = props;
  const [difficultyValues, setDifficultyValues] = React.useState({});
  const [practiceSetIds, setPracticeSetIds] = React.useState({});
  const [practiceSets, setPracticeSets] = React.useState([]);
  const {
    input: { onChange, value: values },
  } = useInput(props);

  const { change, getState } = useForm();
  const { initialValues, values: stateValues } = getState();
  // const { setFilters, displayedFilters, ...otherstuff } = useChoicesContext();
  // console.log(otherstuff);

  const children = React.cloneElement(props.children, props);
  const selectedChoices = React.useMemo(
    () =>
      (values || [])
        .map((v: any) => choices.find((c: any) => c.id === v))
        .filter(identity),
    [values, choices],
  );

  // initial set up of fromToValues from initialValues.practice_items
  React.useEffect(() => {
    const newDifficultyValues = {};
    const { practice_items } = initialValues;

    if (values?.length) {
      values.forEach((value) => {
        newDifficultyValues[value] = {
          difficulty:
            practice_items?.find((i) => i.id === value)?.LessonPractice
              ?.difficulty || 'none',
        };
      });
    }

    setDifficultyValues(newDifficultyValues);
  }, [values]);

  React.useEffect(() => {
    if (values?.length && lessonId) {
      const newPracticeSetIds = {};
      const { practice_items, practiceSetId } = initialValues;

      values.forEach((value) => {
        newPracticeSetIds[value] = {
          practiceSetId: practice_items
            .find((i) => i.id === value)
            ?.practice_sets?.find((i) => practiceSetId.includes(i.id))?.id,
        };
      });

      setPracticeSetIds(newPracticeSetIds);
    }
  }, [lessonId, values]);

  const setUnassigned = React.useRef(0);
  React.useEffect(() => {
    const { practice_sets } = stateValues;

    if (setUnassigned.current === practice_sets?.length + 1) return;

    const copyPracticeSets = practice_sets?.length ? [...practice_sets] : [];
    copyPracticeSets?.unshift({
      id: 'none',
      value: undefined,
      name: 'Unassigned',
    });
    setUnassigned.current = copyPracticeSets.length;

    setPracticeSets(copyPracticeSets);
  }, [initialValues]);

  // updates fromToValues and associations
  React.useEffect(() => {
    const practice_items = [];
    if (values?.length) {
      values.forEach((value) => {
        const newPracticeItem = {
          id: value,
          ...(showDifficulties && {
            LessonPractice: {
              practiceId: value,
              difficulty: difficultyValues[value]?.difficulty,
            },
          }),
          ...(showPracticeSets && {
            PracticeSetContent: {
              contentId: value,
              practiceSetId: practiceSetIds[value]?.practiceSetId,
            },
          }),
        };

        practice_items.push(newPracticeItem);
      });
    }

    change('practice_items', practice_items);
  }, [values, difficultyValues, practiceSetIds]);

  const setNewDifficultyValue = (itemId, newValue) => {
    const newDifficultyValues = {
      ...difficultyValues,
      [itemId]: {
        difficulty: newValue === 'Unassigned' ? 'none' : newValue,
      },
    };

    setDifficultyValues(newDifficultyValues);
  };

  const setNewPracticeSetIds = (itemId, newValue) => {
    const newPracticeSetIds = {
      ...practiceSetIds,
      [itemId]: {
        practiceSetId: newValue,
      },
    };

    setPracticeSetIds(newPracticeSetIds);
  };

  const onDragEnd = (result: any) => {
    const { destination, source, draggableId } = result;

    if (!destination || destination.index === source.index) return;

    const newValues = Array.from(values);
    newValues.splice(source.index, 1);
    newValues.splice(destination.index, 0, draggableId);

    onChange(newValues);
  };

  return (
    <>
      {children}
      {selectedChoices.length ? (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId={id}>
            {(provided, snapshot) => (
              <List
                component="nav"
                ref={provided.innerRef}
                isDraggingOver={snapshot.isDraggingOver}
                {...provided.droppableProps}
              >
                <span style={{ display: 'flex' }}>
                  <Title>{label}</Title>
                  <Title
                    style={{
                      color: showPracticeSets ? '#FF9966' : '#000',
                      padding: '8px 8px 8px 0',
                    }}
                  >
                    * Deprecated *
                  </Title>
                </span>
                {selectedChoices.map((v, i) => (
                  <ChoiceItem
                    value={v}
                    index={i}
                    key={v.id}
                    difficultyValues={difficultyValues}
                    setNewDifficultyValue={setNewDifficultyValue}
                    practiceSetIds={practiceSetIds}
                    setNewPracticeSetIds={setNewPracticeSetIds}
                    showDifficulties={showDifficulties}
                    practiceSets={practiceSets}
                  />
                ))}
                {provided.placeholder}
              </List>
            )}
          </Droppable>
        </DragDropContext>
      ) : null}
    </>
  );
};

const ChoiceItem = (props) => {
  const {
    value,
    index,
    difficultyValues,
    setNewDifficultyValue,
    practiceSetIds,
    setNewPracticeSetIds,
    showDifficulties,
    showPracticeSets,
    practiceSets,
  } = props;
  return (
    <Draggable draggableId={value.id} index={index}>
      {(provided, snapshot) => (
        <ListItem
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          isDragging={snapshot.isDragging}
        >
          <FlexRow>
            <Item>
              #{index + 1} -{' '}
              <a href={`/#/content/practice/${value.id}`} target="_blank">
                <b>{value.word}</b>
              </a>
            </Item>
            {!value?.isInstruction && showDifficulties ? (
              <Item flex={1} style={{ marginLeft: 10 }}>
                Difficulty:{' '}
                <select
                  onChange={(evt) =>
                    setNewDifficultyValue(value.id, evt.target.value)
                  }
                  value={difficultyValues[value.id]?.difficulty}
                >
                  {difficultyList.map((i) => (
                    <option value={i.value}>{i.name}</option>
                  ))}
                </select>
              </Item>
            ) : null}

            {!value?.isInstruction && showPracticeSets ? (
              <Item flex={1} style={{ marginLeft: 10 }}>
                Practice Set:{' '}
                <select
                  onChange={(evt) =>
                    setNewPracticeSetIds(value.id, evt.target.value)
                  }
                  value={practiceSetIds?.[value.id]?.practiceSetId}
                >
                  {practiceSets?.map((i) => (
                    <option value={i.id}>{i.name || i.id}</option>
                  ))}
                </select>
              </Item>
            ) : null}
          </FlexRow>
        </ListItem>
      )}
    </Draggable>
  );
};

export default LessonPracticeInput;

// const ErrorItem = styled.div`
//   margin: 0px 8px;
//   color: red;
//   font-weight: bold;
// `;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
`;

const Item = styled.div`
  ${(p) => p.flex && `flex: ${p.flex};`}
`;

const ListItem = styled.div<StyledDivProps>`
  padding: 8px;
  margin: 8px;
  border: 1px solid grey;
  background-color: ${(props) => (props.isDragging ? 'lightgrey' : 'white')};
  border-radius: 16px;
`;

const List = styled.div<StyledDivProps>`
  padding: 8px;
  border: 1px solid grey;
  background-color: ${(props) => (props.isDraggingOver ? '#2097F3' : 'white')};
`;

const Title = styled.div`
  font-weight: bold;
  font-size: 1.2rem;
  padding: 8px;
`;

interface StyledDivProps {
  isDragging?: boolean;
  isDraggingOver?: boolean;
}

const identity = (v: any) => v;
