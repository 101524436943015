/**
 * @generated SignedSource<<14697d45d44d07da3f154c65e9c3065b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Queues_recentSystemJobs$data = {
  readonly systemJobQueues: ReadonlyArray<{
    readonly name: string | null | undefined;
    readonly " $fragmentSpreads": FragmentRefs<"Queues_Queue">;
  }> | null | undefined;
  readonly " $fragmentType": "Queues_recentSystemJobs";
};
export type Queues_recentSystemJobs$key = {
  readonly " $data"?: Queues_recentSystemJobs$data;
  readonly " $fragmentSpreads": FragmentRefs<"Queues_recentSystemJobs">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [],
      "operation": require('./Queues_recentSystemJobs_RefetchQuery.graphql')
    }
  },
  "name": "Queues_recentSystemJobs",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "SystemJobQueue",
      "kind": "LinkedField",
      "name": "systemJobQueues",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "Queues_Queue"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "fa097b3cf461683b64647a1cffbd28a6";

export default node;
