import * as React from 'react';
import { FC } from 'react';
import { Link, FieldProps } from 'react-admin';

import FullNameField from './FullNameField';

const UserLinkField: FC<FieldProps> = (props) =>
  props.record?.User ? (
    <Link to={props.record.User?.id ? `/users/${props.record.User?.id}` : ''}>
      <FullNameField {...props} />
    </Link>
  ) : null;

UserLinkField.defaultProps = {
  source: 'firstName',
  label: 'User',
  addLabel: true,
};

export default UserLinkField;
