import React from 'react';
import { Button, Modal, Paper } from '@material-ui/core';
import GoalTemplateCreate from '../goal_template/GoalTemplateCreate';
import { useRefresh } from 'react-admin';

export default function CreateOrLinkScenarioGoalModal({
  modalOpen,
  onModalClose,
  record,
}: {
  modalOpen: boolean;
  onModalClose: () => void;
  record: any; // its a scenario
}) {
  const close = () => {
    // detemine if we are creating or linking
    onModalClose();
  };

  const refresh = useRefresh();

  const handleSuccess = () => {
    refresh();
    onModalClose();
  };

  return (
    <Modal
      open={modalOpen}
      onClose={onModalClose}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Paper
        elevation={3}
        style={{
          backgroundColor: 'white',
          padding: '20px',
          minWidth: '30%',
          borderRadius: '5px',
        }}
      >
        <GoalTemplateCreate
          scenarioId={record.id}
          handleSuccess={handleSuccess}
          basePath="content/generative_conversation/goal_template"
          resource="content/generative_conversation/goal_template"
        />

        <div style={{ paddingTop: 20 }}>
          <Button onClick={close} variant="contained">
            Close
          </Button>
        </div>
      </Paper>
    </Modal>
  );
}
