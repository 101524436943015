import * as React from 'react';
import { FC } from 'react';
import { Link, FieldProps } from 'react-admin';

import ConversationNameField from './ConversationNameField';

const ConversationLinkField: FC<FieldProps> = (props) => {
  const conversations = props.record?.conversationId;

  return conversations?.length
    ? conversations.map((conversationId: any) => (
        <Link
          key={conversationId}
          to={`/content/conversation/${conversationId}`}
        >
          <ConversationNameField {...props} conversationId={conversationId} />
        </Link>
      ))
    : null;
};

ConversationLinkField.defaultProps = {
  label: 'Conversation(s)',
  addLabel: true,
};

export default ConversationLinkField;
