import { Box, TextField } from '@material-ui/core';
import { Checkbox, Typography } from '@material-ui/core';
import moment from 'moment';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import api from '../api';
import { PHONE_OS_LIST } from '../users/UserList';
import DashboardChart from './DashboardChart';
import DashboardChartData from './DashboardChartData';
import DateScrubberFilter from './DateScrubberFilter';
import MultiChart from './MultiChart';
import ReferralSourceTable from './tables/ReferralSourceTable';
import SubscribersByCountryTable from './tables/SubscribersByCountryTable';
import SubscribersByLanguageTable from './tables/SubscribersByLanguageTable';

// import TrialStartBreakdown from './tooltips/TrialStartBreakdown';

export const DetailTypes = {
  QUICK_RATIO: 'quick_ratio',
  TRIAL_STARTS: 'trail_starts',
  TRAIL_CANCELLATIONS: 'trail_cancelled',
  SUBSCRIPTION_CANCELLATIONS: 'subscription_cancelled',
  SUBSCRIPTION_REFUNDS: 'subscription_refunded',
};

export const TIMEZONE_LIST = [
  {
    id: 'utc',
    name: 'UTC (Compare to RevenueCat)',
    value: 'UTC',
  },
  {
    id: 'new_york',
    name: 'New York (Compare to Mixpanel)',
    value: 'America/New_York',
  },
  {
    id: 'your_local',
    name: `Your Local (${Intl.DateTimeFormat().resolvedOptions().timeZone})`,
    value: Intl.DateTimeFormat().resolvedOptions().timeZone,
  },
];

export const PRODUCT_DURATION_LIST = [
  {
    id: 'none',
    name: 'All',
    value: 'none',
  },
  {
    id: '12',
    name: 'Annual (12)',
    value: '12',
  },
  {
    id: '1',
    name: 'Monthly (1)',
    value: '1',
  },
  {
    id: 'other',
    name: `All others (3,6,??)`,
    value: 'other',
  },
];

const REFRESH_DURATION = 30000;

const Dashboard = () => {
  const [mainStatsForDashbord, setMainStatsForDashbord] = useState<any>([]);
  const [detailedStats, setDetailedStats] = useState([]);
  const [mainSectionsOpen, setMainSectionsOpen] = useState<any>({});
  const [detailsOpen, setDetailsOpen] = useState('');
  const [detailsLoading, setDetailsLoading] = useState(false);
  const [mainStatsLoading, setMainStatsLoading] = useState(false);

  const [referralSource, setReferralSource] = useState('');
  const [referralSourceForApi, setReferralSourceForApi] = useState('');

  const [phoneOS, updatePhoneOS] = useState('none');
  const [timeZone, updateTimezone] = useState('your_local');
  const [productDuration, setProductDuration] = useState('none');
  const [country, setCountry] = useState();
  const [countryForApi, setCountryForApi] = useState();
  const [dateType, setDateType] = useState('original_purchase_date');

  const [region, setRegion] = useState();
  const [regionForApi, setRegionForApi] = useState();

  const [lastRefreshed, setLastRefreshed] = useState<any>();

  const updateReferralSourceLastValue = useRef();
  const updateReferralSource = (newReferralSource: any) => {
    updateReferralSourceLastValue.current = newReferralSource;
    setReferralSource(newReferralSource);
    setTimeout(() => {
      if (newReferralSource === updateReferralSourceLastValue.current) {
        setReferralSourceForApi(newReferralSource);
      }
    }, 1500);
  };

  const updateCountryLastValue = useRef();
  const updateCountry = (newCountry: any) => {
    updateCountryLastValue.current = newCountry;
    setCountry(newCountry);
    setTimeout(() => {
      if (newCountry === updateCountryLastValue.current) {
        setCountryForApi(newCountry);
      }
    }, 1500);
  };

  const updateRegionLastValue = useRef();
  const updateRegion = (newRegion: any) => {
    updateRegionLastValue.current = newRegion;
    setRegion(newRegion);
    setTimeout(() => {
      if (newRegion === updateRegionLastValue.current) {
        setRegionForApi(newRegion);
      }
    }, 1500);
  };

  const [startDate, setStartDate] = useState<any>(
    moment()
      .subtract(moment().utcOffset(), 'minutes')
      .subtract(7, 'd')
      .format('YYYY-MM-DD'),
  );
  const [endDate, setEndDate] = useState<any>(
    moment().subtract(moment().utcOffset(), 'minutes').format('YYYY-MM-DD'),
  );

  const [numberLabelsOn, setNumberLabelsOn] = useState(true);
  const [autoloadOn, setAutoloadOn] = useState(false);

  useEffect(() => {
    const initialMainSectionsOpen = Object.assign(
      {},
      ...Object.values(DetailTypes).map((i, j) => ({
        [i]: j <= 1,
      })),
    );
    setMainSectionsOpen(initialMainSectionsOpen);
  }, []);

  const mainStatsReloadRef = useRef<any>();

  const getMainStatsForDashbord = useCallback(async () => {
    try {
      setMainStatsLoading(true);
      const newMainStatsForDashbord = await api.getMainStatsForDashbord(
        startDate,
        endDate,
        referralSourceForApi,
        phoneOS === 'none' ? null : phoneOS,
        productDuration === 'none' ? null : productDuration,
        TIMEZONE_LIST?.find((i) => i.id === timeZone)?.value || 'UTC',
        countryForApi,
        regionForApi,
        dateType,
      );
      setLastRefreshed(new Date());
      if (newMainStatsForDashbord.status === 200) {
        setMainStatsForDashbord(newMainStatsForDashbord.json);
      }
    } catch (err) {
      console.log('err', err);
    } finally {
      setMainStatsLoading(false);
    }
  }, [
    startDate,
    endDate,
    referralSourceForApi,
    phoneOS,
    productDuration,
    timeZone,
    countryForApi,
    regionForApi,
    dateType,
  ]);

  useEffect(() => {
    getMainStatsForDashbord();
  }, [getMainStatsForDashbord]);

  useEffect(() => {
    if (autoloadOn && !mainStatsReloadRef.current) {
      mainStatsReloadRef.current = setInterval(
        getMainStatsForDashbord,
        REFRESH_DURATION,
      );
    } else if (autoloadOn && mainStatsReloadRef.current) {
      clearInterval(mainStatsReloadRef.current);
      mainStatsReloadRef.current = setInterval(
        getMainStatsForDashbord,
        REFRESH_DURATION,
      );
    } else if (!autoloadOn && mainStatsReloadRef.current) {
      clearInterval(mainStatsReloadRef.current);
      mainStatsReloadRef.current = null;
    }
  }, [getMainStatsForDashbord, autoloadOn]);

  const getSubStats = useCallback(async () => {
    try {
      if (detailsOpen) {
        setDetailsLoading(true);
        const subStats = await api.getSubStatsForCategory(
          detailsOpen,
          startDate,
          endDate,
          referralSourceForApi,
          phoneOS === 'none' ? null : phoneOS,
          productDuration === 'none' ? null : productDuration,
          TIMEZONE_LIST?.find((i) => i.id === timeZone)?.value || 'UTC',
          countryForApi,
          regionForApi,
        );
        if (subStats.status === 200) {
          setDetailedStats(subStats.json);
        }
      }
    } catch (err) {
      console.log('err', err);
    } finally {
      setDetailsLoading(false);
    }
  }, [
    detailsOpen,
    startDate,
    endDate,
    referralSourceForApi,
    phoneOS,
    productDuration,
    timeZone,
    countryForApi,
    regionForApi,
  ]);

  const subStatsReloadRef = useRef<any>();

  useEffect(() => {
    getSubStats();
  }, [getSubStats]);

  useEffect(() => {
    if (autoloadOn && !subStatsReloadRef.current) {
      subStatsReloadRef.current = setInterval(getSubStats, REFRESH_DURATION);
    } else if (autoloadOn && subStatsReloadRef.current) {
      clearInterval(subStatsReloadRef.current);
      subStatsReloadRef.current = setInterval(getSubStats, REFRESH_DURATION);
    } else if (!autoloadOn && subStatsReloadRef.current) {
      clearInterval(subStatsReloadRef.current);
      subStatsReloadRef.current = null;
    }
  }, [getSubStats, autoloadOn]);

  useEffect(
    () => () => {
      if (subStatsReloadRef.current) {
        clearInterval(subStatsReloadRef.current);
      }

      if (mainStatsReloadRef.current) {
        clearInterval(mainStatsReloadRef.current);
      }
    },
    [],
  );

  return (
    <Container>
      <TopLevelContainer
        style={{
          backgroundColor: 'rgb(253 253 253 / 65%)',
          backdropFilter: 'blur(23px)',
          WebkitBackdropFilter: 'blur(23px)',
          zIndex: 100,
          position: 'sticky',
          top: 0,
          paddingTop: 80,
          marginTop: -60,
          paddingBottom: 20,
          marginLeft: -10,
          paddingLeft: 10,
          marginRight: -10,
          paddingRight: 10,
        }}
      >
        <DateContainer>
          <DateScrubberFilter
            startDate={startDate}
            endDate={endDate}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
          />
          <TextField
            id="referralSource"
            label="Referral Source"
            type="text"
            value={referralSource}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(event) => updateReferralSource(event.target.value)}
            style={{ marginRight: 10 }}
          />
          <TextField
            id="country"
            label="Country"
            type="text"
            value={country}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(event) => updateCountry(event.target.value)}
            style={{ marginRight: 10 }}
          />
          <TextField
            id="region"
            label="Region"
            type="text"
            value={region}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(event) => updateRegion(event.target.value)}
            style={{ marginRight: 10 }}
          />
          <TextField
            id="phoneOS"
            label="Phone OS"
            select
            value={phoneOS}
            SelectProps={{
              native: true,
            }}
            onChange={(event) => updatePhoneOS(event.target.value)}
            style={{ marginRight: 10, minWidth: 100 }}
          >
            {[{ id: 'none', name: 'All' }, ...PHONE_OS_LIST].map((osItem) => (
              <option key={osItem.id} value={osItem.id}>
                {osItem.name}
              </option>
            ))}
          </TextField>
          <TextField
            id="productDuration"
            label="Product Duration"
            select
            value={productDuration}
            SelectProps={{
              native: true,
            }}
            onChange={(event) => setProductDuration(event.target.value)}
            style={{ marginRight: 10, minWidth: 100 }}
          >
            {PRODUCT_DURATION_LIST.map((durationItem) => (
              <option key={durationItem.id} value={durationItem.id}>
                {durationItem.name}
              </option>
            ))}
          </TextField>
          <TextField
            id="timeZone"
            label="Time Zone"
            select
            value={timeZone}
            SelectProps={{
              native: true,
            }}
            onChange={(event) => updateTimezone(event.target.value)}
            style={{ marginRight: 10, minWidth: 100 }}
          >
            {TIMEZONE_LIST.map((item) => (
              <option key={item.id} value={item.id}>
                {item.name}
              </option>
            ))}
          </TextField>
        </DateContainer>
        <Box>
          <CheckboxContainer>
            <Checkbox
              checked={autoloadOn}
              onChange={(event: any) => setAutoloadOn(event.target.checked)}
            />
            <Typography
              style={{ cursor: 'pointer' }}
              onClick={() =>
                setAutoloadOn((existingValue: boolean) => !existingValue)
              }
            >
              Reload
            </Typography>
          </CheckboxContainer>
          {autoloadOn && (
            <Typography style={{ paddingLeft: 9 }}>
              Last Update: {moment(lastRefreshed).format('h:mm:ssa')}
            </Typography>
          )}
        </Box>
        <CheckboxContainer>
          <Checkbox
            checked={numberLabelsOn}
            onChange={(event: any) => setNumberLabelsOn(event.target.checked)}
          />
          <Typography
            style={{ cursor: 'pointer' }}
            onClick={() =>
              setNumberLabelsOn((existingValue: boolean) => !existingValue)
            }
          >
            Toggle Labels
          </Typography>
        </CheckboxContainer>
      </TopLevelContainer>
      <ChartDataContainer>
        <DashboardChart
          title="Quick Ratio (Trial Start - Trial Cancelled - Subscription Cancelled)"
          dataKey="quickRatio"
          data={mainStatsForDashbord}
          numberLabelsOn={numberLabelsOn}
          isOpen={mainSectionsOpen[DetailTypes.QUICK_RATIO]}
          setMainSectionsOpen={setMainSectionsOpen}
          detailType={DetailTypes.QUICK_RATIO}
          fullWidth
          isLoading={mainStatsLoading}
        />
      </ChartDataContainer>
      <ChartDataContainer>
        <DashboardChart
          title="Trial Starts"
          dataKey="trialStartsNum"
          data={mainStatsForDashbord}
          numberLabelsOn={numberLabelsOn}
          isOpen={mainSectionsOpen[DetailTypes.TRIAL_STARTS]}
          setMainSectionsOpen={setMainSectionsOpen}
          detailType={DetailTypes.TRIAL_STARTS}
          isLoading={mainStatsLoading}
          dateType={dateType}
          setDateType={setDateType}
        />
        <DashboardChartData
          title="Records"
          dataKey="trialStartsData"
          dataLabel="Trial Start"
          data={mainStatsForDashbord}
          isOpen={mainSectionsOpen[DetailTypes.TRIAL_STARTS]}
          setMainSectionsOpen={setMainSectionsOpen}
          detailType={DetailTypes.TRIAL_STARTS}
          isLoading={mainStatsLoading}
        />
      </ChartDataContainer>
      <ChartDetailsContainer>
        <MultiChart
          title="User Activity Funnel"
          dataKeys={[
            'newSessionStartNum',
            'newAccountsCreatedNum',
            'accountsWithActivityNum',
            'trailStartPostOnboardingNum',
          ]}
          listDataKeys={['trailStartPostOnboardingData']}
          prettyLabels={[
            'New Sessions (post: 10/25)',
            'New Accounts',
            'Active Accounts (activity within 24h)',
            'Trial Post Onboard',
          ]}
          colors={[
            ['#F5307A', '#F99149'],
            ['#307af5', '#9149f9'],
            ['#4959f9', '#30ddf5'],
            ['#F5307A', '#F99149'],
          ]}
          dataLabels={['Trial Start']}
          data={detailedStats}
          numberLabelsOn={numberLabelsOn}
          isOpen={detailsOpen === DetailTypes.TRIAL_STARTS}
          setDetailsOpen={setDetailsOpen}
          detailType={DetailTypes.TRIAL_STARTS}
          isLoading={detailsLoading}
        />
      </ChartDetailsContainer>
      <ChartDataContainer>
        <DashboardChart
          title="Trial Cancelled"
          dataKey="trialCancelledNum"
          data={mainStatsForDashbord}
          numberLabelsOn={numberLabelsOn}
          isOpen={mainSectionsOpen[DetailTypes.TRAIL_CANCELLATIONS]}
          setMainSectionsOpen={setMainSectionsOpen}
          detailType={DetailTypes.TRAIL_CANCELLATIONS}
          isLoading={mainStatsLoading}
        />
        <DashboardChartData
          title="Records"
          dataKey="trialCancelledData"
          dataLabel="Trial Cancelled"
          data={mainStatsForDashbord}
          numberLabelsOn={numberLabelsOn}
          isOpen={mainSectionsOpen[DetailTypes.TRAIL_CANCELLATIONS]}
          setMainSectionsOpen={setMainSectionsOpen}
          detailType={DetailTypes.TRAIL_CANCELLATIONS}
          isLoading={mainStatsLoading}
        />
      </ChartDataContainer>
      {/* <ChartDetailsContainer>
        <MultiChart
          title="User Activity During Trial"
          dataKeys={['zeroProgressOnTrialNum', 'threeDaysActiveOnTrialNum']}
          listDataKeys={[
            'zeroProgressOnTrialData',
            'threeDaysActiveOnTrialData',
          ]}
          prettyLabels={[
            'Zero Progress On Trial',
            'Three Days Active On Trial (or on track)',
          ]}
          colors={[
            ['#F5307A', '#F99149'],
            ['#307af5', '#9149f9'],
          ]}
          dataLabels={['Trial Start', 'Trial Start']}
          data={detailedStats}
          numberLabelsOn={numberLabelsOn}
          isOpen={detailsOpen === DetailTypes.TRAIL_CANCELLATIONS}
          setDetailsOpen={setDetailsOpen}
          detailType={DetailTypes.TRAIL_CANCELLATIONS}
          isLoading={detailsLoading}
        />
      </ChartDetailsContainer> */}
      <ChartDataContainer>
        <DashboardChart
          title="Subscription Cancelled"
          dataKey="subscriptionCancelledNum"
          data={mainStatsForDashbord}
          numberLabelsOn={numberLabelsOn}
          isOpen={mainSectionsOpen[DetailTypes.SUBSCRIPTION_CANCELLATIONS]}
          setMainSectionsOpen={setMainSectionsOpen}
          detailType={DetailTypes.SUBSCRIPTION_CANCELLATIONS}
          isLoading={mainStatsLoading}
        />
        <DashboardChartData
          title="Records"
          dataKey="subscriptionCancelledData"
          dataLabel="Subscription Cancelled"
          data={mainStatsForDashbord}
          isOpen={mainSectionsOpen[DetailTypes.SUBSCRIPTION_CANCELLATIONS]}
          setMainSectionsOpen={setMainSectionsOpen}
          detailType={DetailTypes.SUBSCRIPTION_CANCELLATIONS}
          isLoading={mainStatsLoading}
        />
      </ChartDataContainer>
      <ChartDetailsContainer>
        <MultiChart
          title="Timing of Subscription Cancellations"
          dataKeys={[
            'withinOneMonthNum',
            'afterOneMonthNum',
            'involuntaryChurnNum',
          ]}
          listDataKeys={[
            'withinOneMonthData',
            'afterOneMonthData',
            'involuntaryChurnData',
          ]}
          prettyLabels={[
            'Within One Month Of Subscribing',
            'After One Month Of Subscribing',
            'Billing Issues',
          ]}
          dataLabels={[
            'Subscription Cancelled',
            'Subscription Cancelled',
            'Subscription Cancelled',
          ]}
          colors={[
            ['#F5307A', '#F99149'],
            ['#307af5', '#9149f9'],
            ['#4959f9', '#30ddf5'],
          ]}
          data={detailedStats}
          numberLabelsOn={numberLabelsOn}
          isOpen={detailsOpen === DetailTypes.SUBSCRIPTION_CANCELLATIONS}
          setDetailsOpen={setDetailsOpen}
          detailType={DetailTypes.SUBSCRIPTION_CANCELLATIONS}
          isLoading={detailsLoading}
        />
      </ChartDetailsContainer>
      <ChartDataContainer>
        <DashboardChart
          title="Subscription Refunded"
          dataKey="subscriptionRefundedNum"
          data={mainStatsForDashbord}
          numberLabelsOn={numberLabelsOn}
          isOpen={mainSectionsOpen[DetailTypes.SUBSCRIPTION_REFUNDS]}
          setMainSectionsOpen={setMainSectionsOpen}
          detailType={DetailTypes.SUBSCRIPTION_REFUNDS}
          isLoading={mainStatsLoading}
        />
        <DashboardChartData
          title="Records"
          dataKey="subscriptionRefundedData"
          dataLabel="Subscription Refunded"
          data={mainStatsForDashbord}
          isOpen={mainSectionsOpen[DetailTypes.SUBSCRIPTION_REFUNDS]}
          setMainSectionsOpen={setMainSectionsOpen}
          detailType={DetailTypes.SUBSCRIPTION_REFUNDS}
          isLoading={mainStatsLoading}
        />
      </ChartDataContainer>
      <ChartDetailsContainer>
        <ReferralSourceTable
          startDate={startDate}
          endDate={endDate}
          phoneOS={phoneOS === 'none' ? null : phoneOS}
          productDuration={productDuration === 'none' ? null : productDuration}
          timezone={
            TIMEZONE_LIST?.find((i) => i.id === timeZone)?.value || 'UTC'
          }
          country={countryForApi}
          region={regionForApi}
        />
      </ChartDetailsContainer>
      <ChartDetailsContainer>
        <SubscribersByLanguageTable
          startDate={startDate}
          endDate={endDate}
          phoneOS={phoneOS === 'none' ? null : phoneOS}
          timezone={
            TIMEZONE_LIST?.find((i) => i.id === timeZone)?.value || 'UTC'
          }
        />
      </ChartDetailsContainer>
      <ChartDetailsContainer>
        <SubscribersByCountryTable
          startDate={startDate}
          endDate={endDate}
          phoneOS={phoneOS === 'none' ? null : phoneOS}
          timezone={
            TIMEZONE_LIST?.find((i) => i.id === timeZone)?.value || 'UTC'
          }
        />
      </ChartDetailsContainer>
    </Container>
  );
};

export default Dashboard;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const ChartDetailsContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const ChartDataContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
`;

const DateContainer = styled.div`
  flex: 1.25;
  display: flex;
  align-items: center;
`;

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
`;

const TopLevelContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
`;
