import * as React from 'react';
import { Card, CardHeader, CardContent } from '@material-ui/core';
import {
  ResponsiveContainer,
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Bar,
  Brush,
  LabelList,
} from 'recharts';
import { Checkbox, Typography } from '@material-ui/core';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import { Link } from 'react-router-dom';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import CircularProgress from '@material-ui/core/CircularProgress';

import styled from 'styled-components';
import { DetailTypes } from './Dashboard';

const useStyles = makeStyles((theme) => ({
  cardContent: {
    '& $svg.recharts-surface': {
      overflow: 'visible',
    },
    '& .recharts-label': {
      fontSize: 12,
    },
  },
  root: {
    flex: 0.5,
    marginLeft: 10,
    marginTop: 10,
    height: 304,
  },
  listItem: {
    flex: 0.85,
  },
  secondary: {
    marginRight: '1em',
    color: theme.palette.text.primary,
  },
  scrollList: {
    overflow: 'scroll',
    height: 224,
  },
}));

const MultiChart = (props: any) => {
  const {
    title,
    data,
    dataKeys,
    prettyLabels,
    listDataKeys,
    isOpen,
    setDetailsOpen,
    detailType,
    numberLabelsOn,
    isLoading,
    dataLabels,
    colors,
  } = props;
  const classes = useStyles();

  const [checkboxStates, setCheckboxStates] = React.useState<any>({});

  const [totalValues, setTotalValues] = React.useState<any>({});
  const [startIndex, setStartIndex] = React.useState<any>();
  const [endIndex, setEndIndex] = React.useState<any>();

  const updateVisibleIndexes = React.useCallback((visibleIndexData: any) => {
    const {
      startIndex: newStartIndex,
      endIndex: newEndIndex,
    } = visibleIndexData;
    setStartIndex(newStartIndex);
    setEndIndex(newEndIndex);
  }, []);

  React.useEffect(() => {
    // reset the brush start & end index when data changes due to data / referral soure filters
    setStartIndex(undefined);
    setEndIndex(undefined);
  }, [data, dataKeys]);

  React.useEffect(() => {
    if (isOpen) {
      const newTotalValues = Object.assign(
        {},
        ...dataKeys.map((dataKey: any) => ({
          [dataKey]: data
            .map((item: any, index: number) => {
              if (
                (!startIndex && !endIndex) ||
                (index >= startIndex && index <= endIndex)
              ) {
                return item?.[dataKey];
              }
              return 0;
            })
            .reduce((a: any, b: any) => a + b, 0),
        })),
      );

      setTotalValues(newTotalValues);
    }
  }, [data, dataKeys, startIndex, endIndex, isOpen]);

  React.useEffect(() => {
    const newCheckboxStates = Object.assign(
      {},
      ...dataKeys.map((dataKey: string) => ({ [dataKey]: true })),
    );
    setCheckboxStates(newCheckboxStates);
  }, [dataKeys]);

  const changeCheckboxStates = (dataKey: any, newValue: any) => {
    setCheckboxStates((existingCheckboxStates: any) => ({
      ...existingCheckboxStates,
      [dataKey]: newValue,
    }));
  };

  const toggleExpandCollapse = () => {
    setDetailsOpen((existingDetailOpen: any) => {
      if (existingDetailOpen === detailType) {
        return '';
      }
      return detailType;
    });
  };

  if (!isOpen) {
    return (
      <StyledCard>
        <TitleParent onClick={toggleExpandCollapse}>
          <CardHeader title={title} />
          <ExpandMore />
        </TitleParent>
      </StyledCard>
    );
  }

  return (
    <StyledCard>
      <TitleParent onClick={toggleExpandCollapse}>
        <CardHeader title={title} />
        <ExpandLess />

        {isLoading && <CircularProgress style={{ marginLeft: 10 }} size={20} />}
      </TitleParent>

      <CheckboxesParent>
        {dataKeys.map((dataKey: string, dataKeyIndex: any) => {
          const nameKey = dataKeys.indexOf(dataKey);

          if (checkboxStates[dataKey] === undefined) return null;

          return (
            <SubmetricsColumn key={dataKey}>
              <CheckboxRow>
                <Checkbox
                  checked={checkboxStates[dataKey]}
                  onChange={(event: any) =>
                    changeCheckboxStates(dataKey, event.target.checked)
                  }
                />
                <div
                  style={{
                    background: `linear-gradient(${colors[dataKeyIndex][0]}, ${colors[dataKeyIndex][1]})`,
                    width: 20,
                    height: 20,
                    marginRight: 7.5,
                    borderRadius: 20,
                  }}
                />
                <Typography
                  style={{ cursor: 'pointer' }}
                  onClick={() =>
                    changeCheckboxStates(dataKey, !checkboxStates[dataKey])
                  }
                >
                  {prettyLabels[nameKey]}
                </Typography>
              </CheckboxRow>
              {totalValues?.[dataKey] && !Number.isNaN(totalValues[dataKey]) ? (
                <TotalParent>
                  Total: {totalValues[dataKey]}
                  {totalValues[dataKeys[dataKeyIndex - 1]] &&
                    detailType === DetailTypes.TRIAL_STARTS &&
                    ` (${
                      Math.round(
                        (totalValues[dataKey] /
                          totalValues[dataKeys[dataKeyIndex - 1]]) *
                          1000,
                      ) / 10
                    }%)`}
                </TotalParent>
              ) : null}
            </SubmetricsColumn>
          );
        })}
      </CheckboxesParent>
      <CardContent className={classes.cardContent}>
        <div style={{ width: '100%', height: 200 }}>
          <ResponsiveContainer>
            <BarChart barCategoryGap={4} barGap={12} data={data}>
              {colors.map((color: any, colorIndex: any) => (
                <defs>
                  <linearGradient
                    id={`colorUv${colorIndex}`}
                    x1="0"
                    y1="0"
                    x2="0"
                    y2="1"
                  >
                    <stop offset="5%" stopColor={color[0]} stopOpacity={0.8} />
                    <stop offset="95%" stopColor={color[1]} stopOpacity={0.4} />
                  </linearGradient>
                </defs>
              ))}
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="date"
                name="Date"
                tickFormatter={(date) => moment(date).format('MMM D')}
                // interval={1}
              />
              <YAxis />
              <Tooltip
                cursor={{ strokeDasharray: '3 3' }}
                separator=": "
                formatter={(value: any, name: any) => {
                  const nameKey = dataKeys.indexOf(name);
                  return [value, prettyLabels[nameKey]];
                }}
                labelFormatter={(label: any) =>
                  moment(label).format('MMM D, YYYY')
                }
              />
              <Brush
                dataKey="date"
                height={30}
                stroke="#F5307A"
                onChange={updateVisibleIndexes}
              />
              {dataKeys
                .filter((dataKey: string) => checkboxStates[dataKey])
                .map((dataKey: string, dataKeyIndex: any) => (
                  <Bar
                    key={dataKey}
                    dataKey={dataKey}
                    stroke={colors[dataKeyIndex][0]}
                    strokeWidth={1}
                    fill={`url(#colorUv${dataKeyIndex})`}
                  >
                    {numberLabelsOn && (
                      <LabelList dataKey={dataKey} position="top" />
                    )}
                  </Bar>
                ))}
            </BarChart>
          </ResponsiveContainer>
        </div>
      </CardContent>

      {listDataKeys.map((listDataKey: any, listDataIndex: number) => {
        const formattedListDataKey = listDataKey.replace('Data', 'Num');
        if (!checkboxStates[formattedListDataKey]) return null;
        const nameIndex = dataKeys.indexOf(formattedListDataKey);

        return (
          <ListParent key={listDataKey}>
            <CardHeader title={`Records for ${prettyLabels[nameIndex]}`} />
            <List className={classes.scrollList} dense>
              {[...data]?.reverse()?.map((date: any) =>
                date?.[listDataKey]?.map((record: any) => {
                  let secondaryText = `${dataLabels[listDataIndex]}: ${moment(
                    date.date,
                  ).format('MMM D')}`;

                  const tierSplit = record?.key?.split('.');
                  if (!tierSplit[4]) {
                    secondaryText += ` (Stripe)`;
                  } else {
                    secondaryText += ` (${tierSplit[4]})`;
                  }

                  if (record?.unsubscribe_detected_at) {
                    secondaryText += ' (👎)';
                  }

                  if (record?.period_type === 'normal') {
                    secondaryText += ' (✅)';
                  }

                  if (moment(record?.expires_date).isBefore(moment())) {
                    secondaryText += ' (❌)';
                  }

                  return (
                    <ListItem
                      key={`${date?.date}_${record?.userId}`}
                      button
                      component={Link}
                      to={`/users/${record?.userId}`}
                      target="_blank"
                    >
                      <ListItemText
                        primary={record?.email}
                        secondary={secondaryText}
                        className={classes.listItem}
                      />
                      <ListItemSecondaryAction>
                        <span className={classes.secondary}>
                          {record?.store === 'app_store'
                            ? 'iOS'
                            : record?.store === 'stripe'
                            ? 'Web'
                            : 'Android'}
                        </span>
                      </ListItemSecondaryAction>
                    </ListItem>
                  );
                }),
              )}
            </List>
          </ListParent>
        );
      })}
    </StyledCard>
  );
};

export default MultiChart;

const StyledCard = styled(Card)`
  margin-top: 10px;
  display: flex;
  flex: 0.85;
  flex-direction: column;
  overflow: visible;
`;

const CheckboxesParent = styled.div`
  padding-left: 16px;
  padding-right: 16px;
  display: flex;
  flex-direction: row;
`;

const CheckboxRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ListParent = styled.div`
  padding-left: 16px;
  padding-right: 16px;
`;

const TitleParent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
`;

const TotalParent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 16px;
`;

const SubmetricsColumn = styled.div`
  display: flex;
  flex-direction: column;
`;
