import React, { FC } from 'react';
import {
  Create,
  CreateProps,
  SimpleForm,
  TextInput,
  required,
} from 'react-admin';

import { makeStyles, Theme } from '@material-ui/core/styles';
import { Styles } from '@material-ui/styles/withStyles';

export const styles: Styles<Theme, any> = {
  name: { display: 'inline-block' },
};

const useStyles = makeStyles(styles);

const MouthDiagramCreate: FC<CreateProps> = (props) => {
  const classes = useStyles(props);

  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput
          fullWidth
          autoFocus
          source="imageUrl"
          label="BoldVoice Image Url (Relative Url)"
          className={classes.name}
          validate={requiredValidate}
        />
        <TextInput
          fullWidth
          autoFocus
          source="squareImageUrl"
          label="Square BoldVoice Image Url (Relative Url)"
          className={classes.name}
        />
        <TextInput
          multiline
          source="adminNotes"
          label="Admin Notes"
          fullWidth
          helperText={false}
        />
      </SimpleForm>
    </Create>
  );
};

const requiredValidate = [required()];

export default MouthDiagramCreate;
