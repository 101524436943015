import * as React from 'react';
import { FC } from 'react';
import { Card as MuiCard, CardContent } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import {
  FilterList,
  FilterListItem,
  FilterLiveSearch,
  useGetList,
  useListContext,
} from 'react-admin';
import unit from '../unit';
import curriculum from '../curriculum';

const Card = withStyles((theme) => ({
  root: {
    [theme.breakpoints.up('sm')]: {
      order: -1,
      width: '15em',
      marginRight: '1em',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}))(MuiCard);

const Aside: FC = () => {
  const { filterValues, setFilters } = useListContext();

  const {
    data: curriculumData,
    loading: curriculumLoading,
    error: curriculumError,
  } = useGetList('content/curriculum', { page: 1, perPage: 99999 });

  const { data: unitData, loading: unitLoading, error: unitError } = useGetList(
    'content/unit',
    {
      page: 1,
      perPage: 99999,
    },
    { field: 'id', order: 'DESC' },
    {
      ...(filterValues?.Curriculum?.id && {
        curriculumId: filterValues?.Curriculum?.id || null,
      }),
    },
  );

  const {
    data: lessonData,
    loading: lessonLoading,
    error: lessonError,
  } = useGetList(
    'content/lesson',
    {
      page: 1,
      perPage: 9999,
    },
    { field: 'id', order: 'DESC' },
    {
      Unit: { id: filterValues?.Unit?.id || null },
    },
  );

  React.useEffect(() => {
    if (!filterValues?.Curriculum?.id && filterValues?.Unit?.id) {
      setFilters({}, {});
    }

    if (!filterValues?.Unit?.id && filterValues?.lessonId) {
      setFilters({}, {});
    }
  }, [filterValues]);

  if (unitLoading || curriculumLoading || lessonLoading) return <div />;
  if (unitError || curriculumError || lessonError)
    return <div>Error: {unitError || curriculumError || lessonError}</div>;

  return (
    <Card>
      <CardContent>
        <FilterLiveSearch source="name" />

        <FilterList label="Curriculum" icon={<curriculum.icon />}>
          {Object.values(curriculumData)
            .filter((curriculumItem) => {
              if (
                filterValues?.unitId ||
                (filterValues?.Unit && filterValues?.Unit?.id)
              ) {
                const valueToUse =
                  filterValues?.unitId ||
                  (filterValues?.Unit && filterValues?.Unit?.id);
                const filteredCurriculum = Object.values(unitData).find(
                  (unitItem) => unitItem.id === valueToUse,
                )?.Curriculum;
                if (curriculumItem?.id === filteredCurriculum?.id) {
                  return true;
                }
                return false;
              }

              if (filterValues?.lessonId) {
                const valueToUse = filterValues?.lessonId;
                const curriculumHasLessonId = !!Object.values(unitData).filter(
                  (unitItem) =>
                    unitItem.curriculumId === curriculumItem.id &&
                    !!unitItem.lessons.filter(
                      (lessonItem: any) => lessonItem.id === valueToUse,
                    ).length,
                ).length;
                return !!curriculumHasLessonId;
              }

              return true;
            })
            .sort((a, b) => (a.name > b.name ? 1 : -1))
            .map((curriculumItem) => (
              <FilterListItem
                label={curriculumItem.name}
                key={curriculumItem.id}
                value={{ Curriculum: { id: curriculumItem.id } }}
              />
            ))}
        </FilterList>

        {(filterValues?.Curriculum || filterValues?.Unit) && (
          <FilterList label="Unit" icon={<unit.icon />}>
            {Object.values(unitData)
              .filter((unitItem) => {
                if (filterValues?.Unit && !filterValues?.Unit?.id) {
                  if (
                    unitItem.Curriculum?.id === filterValues.Unit?.curriculumId
                  ) {
                    return true;
                  }
                  return false;
                }

                if (filterValues?.lessonId) {
                  const valueToUse = filterValues?.lessonId;
                  const unitHasLessonId = !!unitItem.lessons.filter(
                    (lessonItem: any) => lessonItem.id === valueToUse,
                  ).length;
                  return !!unitHasLessonId;
                }
                return true;
              })
              .sort((a, b) =>
                a.Curriculum.name === b.Curriculum.name
                  ? a.unitNumber > b.unitNumber
                    ? 1
                    : -1
                  : a.Curriculum.name > b.Curriculum.name
                  ? 1
                  : -1,
              )
              .map((unitItem) => (
                <FilterListItem
                  label={`${unitItem.unitNumber}. ${unitItem.name}`}
                  key={unitItem.id}
                  value={{ Unit: { id: unitItem.id } }}
                />
              ))}
          </FilterList>
        )}

        {filterValues?.Unit?.id && (
          <FilterList label="Lesson" icon={<unit.icon />}>
            {Object.values(lessonData)
              .filter((lessonItem) => {
                if (filterValues?.Unit) {
                  if (
                    filterValues?.Unit?.id &&
                    lessonItem.unitId.includes(filterValues?.Unit?.id)
                  ) {
                    return true;
                  }
                  if (filterValues?.Unit?.curriculumId) {
                    const isPartOfCurriculum = lessonItem.units.filter(
                      (unitItem: any) =>
                        unitItem.curriculumId ===
                        filterValues?.Unit?.curriculumId,
                    ).length;
                    return !!isPartOfCurriculum;
                  }
                  return false;
                }
                return true;
              })
              .sort((a, b) =>
                a?.units?.[0]?.UnitLesson.lessonNumber >
                b?.units?.[0]?.UnitLesson.lessonNumber
                  ? 1
                  : -1,
              )
              .map((lessonItem) => (
                <FilterListItem
                  label={`${lessonItem?.units?.[0]?.UnitLesson.lessonNumber}. ${lessonItem.name}`}
                  key={lessonItem.id}
                  value={{ lessonId: lessonItem.id }}
                />
              ))}
          </FilterList>
        )}
      </CardContent>
    </Card>
  );
};

export default Aside;
