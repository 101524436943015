import * as React from 'react';
import { FC } from 'react';
import { Card as MuiCard, CardContent } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import {
  FilterList,
  FilterListItem,
  FilterLiveSearch,
  useGetList,
  useListContext,
} from 'react-admin';
import unit from '../unit';
import curriculum from '../curriculum';

const Card = withStyles((theme) => ({
  root: {
    [theme.breakpoints.up('sm')]: {
      order: -1,
      width: '15em',
      marginRight: '1em',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}))(MuiCard);

const Aside: FC = () => {
  const { filterValues, setFilters } = useListContext();

  const {
    data: curriculumData,
    loading: curriculumLoading,
    error: curriculumError,
  } = useGetList('content/curriculum', { page: 1, perPage: 99999 });

  const { data: unitData, loading: unitLoading, error: unitError } = useGetList(
    'content/unit',
    {
      page: 1,
      perPage: 99999,
    },
    { field: 'id', order: 'DESC' },
    { curriculumId: filterValues?.Unit?.curriculumId || null },
  );

  React.useEffect(() => {
    if (!filterValues?.Unit?.curriculumId && filterValues?.unitId) {
      setFilters({}, {});
    }
  }, [filterValues]);

  if (unitLoading || curriculumLoading) return <div />;
  if (unitError || curriculumError)
    return <div>Error: {unitError || curriculumError}</div>;

  return (
    <Card>
      <CardContent>
        <FilterLiveSearch source="name" />

        <FilterList label="Curriculum" icon={<curriculum.icon />}>
          {Object.values(curriculumData)
            .filter((curriculumItem) => {
              if (filterValues?.unitId) {
                const filteredCurriculum = Object.values(unitData).find(
                  (unitItem) => unitItem.id === filterValues?.unitId,
                )?.Curriculum;
                if (curriculumItem?.id === filteredCurriculum?.id) {
                  return true;
                }
                return false;
              }
              return true;
            })
            .sort((a, b) => (a.name > b.name ? 1 : -1))
            .map((curriculumItem) => (
              <FilterListItem
                label={curriculumItem.name}
                key={curriculumItem.id}
                value={{ Unit: { curriculumId: curriculumItem.id } }}
              />
            ))}
        </FilterList>

        {filterValues?.Unit?.curriculumId && (
          <FilterList label="Unit" icon={<unit.icon />}>
            {Object.values(unitData)
              .filter((unitItem) => {
                if (filterValues?.Unit) {
                  if (
                    unitItem.Curriculum?.id === filterValues.Unit?.curriculumId
                  ) {
                    return true;
                  }
                  return false;
                }
                return true;
              })
              .sort((a, b) =>
                a.Curriculum.name === b.Curriculum.name
                  ? a.unitNumber > b.unitNumber
                    ? 1
                    : -1
                  : a.Curriculum.name > b.Curriculum.name
                  ? 1
                  : -1,
              )
              .map((unitItem) => (
                <FilterListItem
                  label={`${unitItem.unitNumber}. ${unitItem.name}`}
                  key={unitItem.id}
                  value={{ unitId: unitItem.id }}
                />
              ))}
          </FilterList>
        )}
      </CardContent>
    </Card>
  );
};

export default Aside;
