import { Box, colors } from '@material-ui/core';
import * as React from 'react';
import { FC } from 'react';
import {
  Create,
  CreateProps,
  FormDataConsumer,
  NumberInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
} from 'react-admin';

import { GoalTypes, MetricTypes, MetricsWithMetricLength } from './constants';

const GoalTemplateCreate: FC<
  CreateProps & { scenarioId?: string; handleSuccess?: (response: any) => void }
> = (props) => (
  <Create {...props} onSuccess={props?.handleSuccess}>
    <SimpleForm autoComplete="off" redirect={props.scenarioId ? false : 'edit'}>
      <Box flex={1}>
        <TextInput
          autoFocus
          source="title"
          label="Title"
          fullWidth
          required
          helperText={false}
          placeholder="Say five 3+ syllable words, Order a pizza, etc."
        />
        <TextInput
          source="subtitle"
          label="Subtitle"
          fullWidth
          helperText={false}
          placeholder="Improves your your vocabulary, etc."
        />
        <TextInput
          source="completionString"
          label="Completion String"
          fullWidth
          required
          helperText={false}
          placeholder="Wow! You said/did xyz!"
        />
        <SelectInput
          source="type"
          aria-label="Goal Type"
          choices={GoalTypes.map((_type) => ({
            id: _type,
            name: _type,
          }))}
        />
        <FormDataConsumer>
          {({ formData }) =>
            formData.type === 'contains' ||
            formData.type === 'starting_chars' ||
            formData.type === 'ending_chars' ? (
              <>
                <TextInput
                  source="metric"
                  label="Target String"
                  required
                  fullWidth
                  helperText={false}
                  placeholder={
                    formData.type === 'contains'
                      ? "'pizza', 'hello', etc."
                      : 'th, k, ing'
                  }
                />
                {formData.type === 'starting_chars' ||
                formData.type === 'ending_chars' ? (
                  <NumberInput
                    source="target"
                    required
                    fullWidth
                    label="Required count"
                  />
                ) : null}
              </>
            ) : MetricsWithMetricLength.includes(formData.metric) ? (
              <Box flexDirection="row" display="flex">
                <NumberInput
                  source="metricLength"
                  required
                  style={{
                    marginRight: '1em',
                    minWidth: '100%',
                  }}
                  placeholder='e.g. "3" for 3 syllable words'
                  label="Metric Length"
                />
                <SelectInput
                  source="metric"
                  choices={MetricTypes.map((metric) => ({
                    id: metric,
                    name: metric,
                  }))}
                />
              </Box>
            ) : (
              <SelectInput
                source="metric"
                choices={MetricTypes.map((metric) => ({
                  id: metric,
                  name: metric,
                }))}
              />
            )
          }
        </FormDataConsumer>

        <FormDataConsumer>
          {({ formData }) =>
            formData.type === 'contains' ||
            formData.type === 'starting_chars' ||
            formData.type === 'ending_chars' ? null : formData.type ===
              'binomial' ? (
              <NumberInput
                source="target"
                label="1"
                helperText={false}
                fullWidth
                value={1}
                defaultValue={1}
                contentEditable={false}
              />
            ) : (
              <NumberInput
                source="target"
                label="Target"
                helperText={false}
                fullWidth
              />
            )
          }
        </FormDataConsumer>

        <ReferenceInput
          allowEmpty
          style={{ width: '50%', display: props.scenarioId ? 'none' : 'block' }}
          label="Scenario"
          source="scenarioId"
          reference="content/generative_conversation/topic_config"
          perPage={1000}
        >
          <SelectInput
            initialValue={props.scenarioId ?? null}
            defaultValue={props.scenarioId ?? null}
            disabled={!!props.scenarioId}
            optionText={(record: any) =>
              `${record.category} (${record.conversation_topic} @ ${record.location})`
            }
          />
        </ReferenceInput>
        <Box
          style={{
            marginTop: 15,
            marginBottom: 5,
            fontSize: '1.25em',
            color: colors.green[500],
          }}
        >
          {props.scenarioId ? 'Scenario linked' : null}
        </Box>
      </Box>
    </SimpleForm>
  </Create>
);

export default GoalTemplateCreate;
