import * as MUI from '@material-ui/core';
import { CaretDown, CaretUp } from '@phosphor-icons/react';
import graphql from 'babel-plugin-relay/macro';
import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
  useTransition,
} from 'react';
import { useFragment, useLazyLoadQuery } from 'react-relay';

const SkillPathsWithLoading = () => (
  <React.Suspense
    fallback={
      <MUI.Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        height="100%"
      >
        <MUI.CircularProgress size={100} />
      </MUI.Box>
    }
  >
    <SkillPathsDisplay />
  </React.Suspense>
);

const SkillsPathContext = createContext<any>({});

export function SkillsPathProvider({ children, ...props }) {
  return (
    <SkillsPathContext.Provider value={props}>
      {children}
    </SkillsPathContext.Provider>
  );
}

const sortFieldOptions = [
  {
    id: 'name',
    label: 'Name (A-Z)',
  },
  {
    id: 'categories',
    label: 'By Category',
  },
  {
    id: 'active',
    label: 'By Active',
  },
  {
    id: 'skillLessonsNumber',
    label: '# Lessons',
  },
  {
    id: 'activeSkillLessonsNumber',
    label: 'Active Lessons',
  },
];

const sortOrderOptions = [
  {
    id: 'asc',
    label: 'ASC',
  },
  {
    id: 'desc',
    labe: 'DESC',
  },
];

const SkillPathsDisplay = () => {
  // client side sorting since not all of these values are server side sortable
  const [_isPending, startTransition] = useTransition();
  const [sortField, setSortField] = useState<any>('name');
  const [sortOrder, setSortOrder] = useState<any>('asc');

  const [showInactiveSkillLessons, setShowInactiveSkillLessons] =
    useState(true);
  const [showSkillLessonsZeroPractice, setShowSkillLessonsZeroPractice] =
    useState(true);
  const [
    showSkillLessonsOnlyZeroPractice,
    setShowSkillLessonsOnlyZeroPractice,
  ] = useState(false);
  const [
    showSkillLessonsWithoutVideoVariants,
    setShowSkillLessonsWithoutVideoVariants,
  ] = useState(true);
  const [filterBySkillLessonProperty, setFilterBySkillLessonProperty] =
    useState();

  const skillData: any = useLazyLoadQuery(
    graphql`
      query SkillPathsDisplay_Query {
        raAllSkills(perPage: 1000, sortField: "name", sortOrder: "asc") {
          id
          name
          categories
          active
          skillLessonsNumber
          activeSkillLessonsNumber

          ...SkillPathsDisplay_skill
        }
      }
    `,
    {},
  );

  const sortedSkills = useMemo(
    () =>
      [...skillData.raAllSkills].sort((a, b) =>
        a[sortField] > b[sortField]
          ? sortOrder === 'asc'
            ? 1
            : -1
          : sortOrder === 'asc'
            ? -1
            : 1,
      ),
    [skillData.raAllSkills, sortField, sortOrder],
  );

  const stepsArray = useMemo(() => new Array(30).fill(true), []);

  const chosenSortField = useMemo(
    () => sortFieldOptions.find((o) => o.id === sortField),
    [sortField],
  );
  const chosenSortOrder = useMemo(
    () => sortOrderOptions.find((o) => o.id === sortOrder),
    [sortOrder],
  );

  const changeSortField = useCallback(() => {
    const nextSortFieldIndex =
      sortFieldOptions.findIndex((o) => o.id === sortField) + 1;

    const nextSortFieldId =
      nextSortFieldIndex === sortFieldOptions.length
        ? sortFieldOptions[0].id
        : sortFieldOptions[nextSortFieldIndex].id;

    startTransition(() => {
      setSortField(nextSortFieldId);
    });
  }, [sortField]);

  const changeSortOrder = useCallback(() => {
    const nextSortOrderIndex =
      sortOrderOptions.findIndex((o) => o.id === sortOrder) + 1;

    const nextSortOrder =
      nextSortOrderIndex === sortOrderOptions.length
        ? sortOrderOptions[0].id
        : sortOrderOptions[nextSortOrderIndex].id;

    startTransition(() => {
      setSortOrder(nextSortOrder);
    });
  }, [sortOrder]);

  const toggleShowInactiveSkillLessons = useCallback(() => {
    startTransition(() => {
      setShowInactiveSkillLessons((existing) => !existing);
    });
  }, []);
  const toggleShowSkillLessonsZeroPractice = useCallback(() => {
    startTransition(() => {
      setShowSkillLessonsZeroPractice((existing) => !existing);
      if (showSkillLessonsOnlyZeroPractice) {
        setShowSkillLessonsOnlyZeroPractice(false);
      }
    });
  }, [showSkillLessonsOnlyZeroPractice]);

  const toggleShowSkillLessonsOnlyZeroPractice = useCallback(() => {
    startTransition(() => {
      setShowSkillLessonsOnlyZeroPractice((existing) => !existing);
      if (!showSkillLessonsZeroPractice) {
        setShowSkillLessonsZeroPractice(true);
      }
    });
  }, [showSkillLessonsZeroPractice]);

  const toggleShowSkillLessonsWithoutVideoVariants = useCallback(() => {
    startTransition(() => {
      setShowSkillLessonsWithoutVideoVariants((existing) => !existing);
    });
  }, []);

  const toggleFoundationalSkillLessons = () =>
    toggleSkilLLessonFilter('foundational');
  const toggleInactiveSkillLessons = () => toggleSkilLLessonFilter('inactive');
  const toggleNoVariantsSkillLessons = () =>
    toggleSkilLLessonFilter('noVariants');
  const togglePrimarySkillLesson = () => toggleSkilLLessonFilter('primary');

  const toggleSkilLLessonFilter = useCallback((property) => {
    setFilterBySkillLessonProperty((existing) =>
      existing === property ? undefined : property,
    );
  }, []);

  return (
    <SkillsPathProvider
      showInactiveSkillLessons={showInactiveSkillLessons}
      showSkillLessonsZeroPractice={showSkillLessonsZeroPractice}
      showSkillLessonsOnlyZeroPractice={showSkillLessonsOnlyZeroPractice}
      showSkillLessonsWithoutVideoVariants={
        showSkillLessonsWithoutVideoVariants
      }
      filterBySkillLessonProperty={filterBySkillLessonProperty}
    >
      <MUI.Box style={{ backgroundColor: '#fcfcfe' }}>
        <MUI.Box paddingLeft={4.6}>
          <MUI.Typography variant="h6">Options</MUI.Typography>
          <MUI.Box
            display="flex"
            flexDirection="row"
            gridRowGap={10}
            gridColumnGap={10}
          >
            <MUI.FormControlLabel
              control={
                <MUI.Checkbox
                  checked={showInactiveSkillLessons}
                  onChange={toggleShowInactiveSkillLessons}
                  inputProps={{ 'aria-label': 'Show Inactive Skill Lessons' }}
                />
              }
              label="Show Inactive Skill Lessons"
            />

            <MUI.FormControlLabel
              control={
                <MUI.Checkbox
                  checked={showSkillLessonsZeroPractice}
                  onChange={toggleShowSkillLessonsZeroPractice}
                  inputProps={{
                    'aria-label': 'Show Skill Lessons with 0 Practice',
                  }}
                />
              }
              label="Show Skill Lessons with 0 Practice"
            />

            <MUI.FormControlLabel
              control={
                <MUI.Checkbox
                  checked={showSkillLessonsOnlyZeroPractice}
                  onChange={toggleShowSkillLessonsOnlyZeroPractice}
                  inputProps={{
                    'aria-label': 'Show Skill Lessons, only 0 Practice',
                  }}
                />
              }
              label="Show Skill Lessons, only 0 Practice"
            />
            <MUI.FormControlLabel
              control={
                <MUI.Checkbox
                  checked={showSkillLessonsWithoutVideoVariants}
                  onChange={toggleShowSkillLessonsWithoutVideoVariants}
                  inputProps={{
                    'aria-label': 'Show without Video Variants',
                  }}
                />
              }
              label="Show without Video Variants"
            />
          </MUI.Box>
        </MUI.Box>

        <MUI.Box
          paddingLeft={4.6}
          display="flex"
          flexDirection="row"
          alignItems="center"
          gridRowGap={10}
          gridColumnGap={10}
        >
          <MUI.Typography variant="h6">Filter</MUI.Typography>
          <MUI.Button style={{ padding: 0 }} onClick={togglePrimarySkillLesson}>
            <MUI.Box
              style={{
                width: 60,
                height: 25,
                border: '1px solid blue',

                borderWidth: filterBySkillLessonProperty === 'primary' ? 3 : 1,
                borderRadius: 10,
              }}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <MUI.Typography
                variant="caption"
                style={{ textTransform: 'capitalize' }}
              >
                Primary
              </MUI.Typography>
            </MUI.Box>
          </MUI.Button>
          <MUI.Button
            style={{ padding: 0 }}
            onClick={toggleFoundationalSkillLessons}
          >
            <MUI.Box
              style={{
                width: 80,
                height: 25,
                border: '1px solid red',
                borderWidth:
                  filterBySkillLessonProperty === 'foundational' ? 3 : 1,

                borderRadius: 10,
              }}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <MUI.Typography
                variant="caption"
                style={{ textTransform: 'capitalize' }}
              >
                Foundational
              </MUI.Typography>
            </MUI.Box>
          </MUI.Button>
          <MUI.Button
            style={{ padding: 0 }}
            onClick={toggleInactiveSkillLessons}
          >
            <MUI.Box
              style={{
                width: 60,
                height: 25,
                border: '1px solid transparent',
                borderRadius: 10,
                backgroundColor: '#E4E6ED',
                borderWidth: filterBySkillLessonProperty === 'inactive' ? 3 : 1,
                borderColor:
                  filterBySkillLessonProperty === 'inactive'
                    ? 'black'
                    : 'transparent',
              }}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <MUI.Typography
                variant="caption"
                style={{ textTransform: 'capitalize' }}
              >
                Inactive
              </MUI.Typography>
            </MUI.Box>
          </MUI.Button>
          <MUI.Button
            style={{ padding: 0 }}
            onClick={toggleNoVariantsSkillLessons}
          >
            <MUI.Box
              style={{
                width: 60,
                height: 25,
                border: '1px solid transparent',
                borderRadius: 10,
                backgroundColor: '#B5BAC9',
                borderWidth:
                  filterBySkillLessonProperty === 'noVariants' ? 3 : 1,
                borderColor:
                  filterBySkillLessonProperty === 'noVariants'
                    ? 'black'
                    : 'transparent',
              }}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <MUI.Typography
                variant="caption"
                style={{ textTransform: 'capitalize' }}
              >
                No Videos
              </MUI.Typography>
            </MUI.Box>
          </MUI.Button>
        </MUI.Box>

        <MUI.Box
          style={{
            position: 'sticky',
            top: 0,
            backgroundColor: '#fcfcfe',
            paddingTop: 5,
            marginTop: 0,
            paddingBottom: 0,
            zIndex: 100,
          }}
        >
          <MUI.Box
            display="flex"
            flexDirection="row"
            gridColumnGap={10}
            marginTop={2}
            marginBottom={1}
            paddingLeft={2}
          >
            <MUI.Box style={{ width: 250, paddingLeft: 20 }}>
              <MUI.Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                gridColumnGap={10}
                gridRowGap={10}
              >
                <MUI.Button onClick={changeSortField} style={{ padding: 0 }}>
                  <MUI.Typography
                    style={{ textTransform: 'capitalize' }}
                    variant="h6"
                  >
                    {chosenSortField?.label}{' '}
                  </MUI.Typography>
                </MUI.Button>
                <MUI.Button onClick={changeSortOrder} style={{ padding: 0 }}>
                  {chosenSortOrder?.id === 'asc' ? (
                    <CaretDown size={24} weight="bold" />
                  ) : (
                    <CaretUp size={24} weight="bold" />
                  )}
                </MUI.Button>
              </MUI.Box>
            </MUI.Box>
            <MUI.Divider flexItem orientation="vertical" style={{ width: 3 }} />
            {stepsArray.map((_step, index) => (
              <React.Fragment key={index}>
                <MUI.Box
                  style={{
                    width: 150,
                    paddingLeft: 10,
                    paddingRight: 10,
                    border: '1px solid transparent',
                  }}
                >
                  <MUI.Typography variant="h6">Step {index + 1}</MUI.Typography>
                </MUI.Box>
                <MUI.Divider flexItem orientation="vertical" />
              </React.Fragment>
            ))}
          </MUI.Box>
          <MUI.Divider style={{ height: 3 }} />
        </MUI.Box>

        <MUI.Box
          display="flex"
          flexDirection="column"
          gridColumnGap={20}
          gridRowGap={10}
        >
          {sortedSkills?.map((skill: any) => (
            <React.Fragment key={skill.id}>
              <MUI.Divider />
              <SkillRow skill={skill} />
            </React.Fragment>
          ))}
        </MUI.Box>
      </MUI.Box>
    </SkillsPathProvider>
  );
};

export default SkillPathsWithLoading;

const SkillRow = ({ skill }) => {
  const {
    showInactiveSkillLessons,
    showSkillLessonsZeroPractice,
    showSkillLessonsOnlyZeroPractice,
    showSkillLessonsWithoutVideoVariants,
    filterBySkillLessonProperty,
  } = useContext(SkillsPathContext);

  const data = useFragment(
    graphql`
      fragment SkillPathsDisplay_skill on Skill {
        id
        name
        categories
        active
        skillLessonsNumber
        activeSkillLessonsNumber

        skillLessons {
          id
          order

          type

          active

          video {
            id
            title
            introductory
            videoVariants {
              id
            }
          }
          conversation {
            id
            name
          }
          numberPracticeSets
          practiceTextsNumber

          linkedSkillLesson {
            id
            type

            video {
              id
              title
              introductory
              videoVariants {
                id
                title
              }
            }
            conversation {
              id
              name
            }
            numberPracticeSets

            practiceTextsNumber
          }
        }
      }
    `,
    skill,
  );

  const category = data.categories?.[0]?.replace('_', ' ')?.toLowerCase();

  const sortedSkillLessons = useMemo(
    () => [...data?.skillLessons].sort((a, b) => (a.order > b.order ? 1 : -1)),
    [data?.skillLessons],
  );

  const generatedItemsCount = useMemo(() => {
    return sortedSkillLessons.reduce((a, b) => {
      const mergedSkillLesson = {
        order: b.order,
        active: b.active,
        autogenerated: b.autogenerated,
        type: b.linkedSkillLesson?.type ?? b.type,
        isLinked: !!b.linkedSkillLesson?.id,
        video: b.linkedSkillLesson?.video ?? b.video,
        conversation: b.linkedSkillLesson?.conversation ?? b.conversation,
        numberPracticeSets:
          b.numberPracticeSets ?? b.linkedSkillLesson?.numberPracticeSets,
        practiceTextsNumber:
          (b.practiceTextsNumber || b.linkedSkillLesson?.practiceTextsNumber) ??
          0,
      };

      if (!showInactiveSkillLessons && !mergedSkillLesson.active) return a;
      if (
        !showSkillLessonsZeroPractice &&
        mergedSkillLesson.practiceTextsNumber === 0
      )
        return a;
      if (
        showSkillLessonsOnlyZeroPractice &&
        mergedSkillLesson.practiceTextsNumber !== 0
      ) {
        return a;
      }
      if (
        !showSkillLessonsWithoutVideoVariants &&
        mergedSkillLesson?.video?.videoVariants?.length === 0
      ) {
        return a;
      }

      const numberPracticeSets = mergedSkillLesson?.numberPracticeSets;

      return a + (numberPracticeSets + 1);
    }, 0);
  }, [
    sortedSkillLessons,
    showInactiveSkillLessons,
    showSkillLessonsZeroPractice,
    showSkillLessonsOnlyZeroPractice,
    showSkillLessonsWithoutVideoVariants,
    filterBySkillLessonProperty,
  ]);

  return (
    <MUI.Box
      display="flex"
      flexDirection="row"
      gridColumnGap={10}
      style={{ backgroundColor: !data.active ? '#E4E6ED' : 'transparent' }}
      paddingLeft={2}
    >
      <MUI.Box
        display="flex"
        alignItems="center"
        style={{
          position: 'sticky',
          left: 0,
          paddingLeft: 20,
          backgroundColor: !data.active ? '#E4E6ED' : '#fcfcfe',
        }}
      >
        <MUI.Link
          href={`/#/g/skills/${data.id}`}
          target="_blank"
          style={{ width: 250 }}
        >
          <MUI.Typography variant="body1" style={{ fontWeight: 'bold' }}>
            {data.name}
          </MUI.Typography>
          <MUI.Typography
            variant="body1"
            style={{ textTransform: 'capitalize' }}
          >
            {category}
          </MUI.Typography>
          <MUI.Typography variant="body1">
            Active: {data.activeSkillLessonsNumber}/{data.skillLessonsNumber}
          </MUI.Typography>
          <MUI.Typography variant="body1">
            Generated Items: {generatedItemsCount}
          </MUI.Typography>
        </MUI.Link>
      </MUI.Box>
      <MUI.Divider orientation="vertical" flexItem style={{ width: 3 }} />
      {sortedSkillLessons?.map((skillLesson: any) => (
        <SkillLesson key={skillLesson.id} skillLesson={skillLesson} />
      ))}
    </MUI.Box>
  );
};

const SkillLesson = ({ skillLesson }) => {
  const {
    showInactiveSkillLessons,
    showSkillLessonsZeroPractice,
    showSkillLessonsOnlyZeroPractice,
    showSkillLessonsWithoutVideoVariants,
    filterBySkillLessonProperty,
  } = useContext(SkillsPathContext);
  const data = skillLesson;

  const mergedSkillLesson = useMemo(
    () => ({
      order: data.order,
      active: data.active,
      autogenerated: data.autogenerated,
      type: data.linkedSkillLesson?.type ?? data.type,
      isLinked: !!data.linkedSkillLesson?.id,
      video: data.linkedSkillLesson?.video ?? data.video,
      conversation: data.linkedSkillLesson?.conversation ?? data.conversation,
      numberPracticeSets:
        data.numberPracticeSets ?? data.linkedSkillLesson?.numberPracticeSets,
      practiceTextsNumber:
        (data.practiceTextsNumber ||
          data.linkedSkillLesson?.practiceTextsNumber) ??
        0, // purposely || not ?? because the api returns 0 not null for when the item is a linked lesson and has no practices
    }),
    [data],
  );

  const nameToShow =
    mergedSkillLesson?.conversation?.name ?? mergedSkillLesson?.video?.title;

  const typeToShow = mergedSkillLesson?.type?.toLowerCase()?.replace('_', ' ');

  if (!showInactiveSkillLessons && !mergedSkillLesson.active) return null;
  if (
    !showSkillLessonsZeroPractice &&
    mergedSkillLesson.practiceTextsNumber === 0
  )
    return null;

  if (
    showSkillLessonsOnlyZeroPractice &&
    mergedSkillLesson.practiceTextsNumber !== 0
  ) {
    return null;
  }

  if (
    !showSkillLessonsWithoutVideoVariants &&
    mergedSkillLesson?.video?.videoVariants?.length === 0
  )
    return null;

  if (filterBySkillLessonProperty === 'primary' && mergedSkillLesson.isLinked)
    return null;
  if (
    filterBySkillLessonProperty === 'foundational' &&
    !mergedSkillLesson.video?.introductory
  )
    return null;
  if (filterBySkillLessonProperty === 'inactive' && mergedSkillLesson.active)
    return null;

  if (
    filterBySkillLessonProperty === 'noVariants' &&
    mergedSkillLesson?.video?.videoVariants?.length !== 0
  )
    return null;

  return (
    <>
      <MUI.Box
        style={{
          width: 150,
          backgroundColor: !mergedSkillLesson.active
            ? '#E4E6ED'
            : 'transparent',
          borderWidth: 1,
          borderColor: mergedSkillLesson.video?.introductory
            ? 'red'
            : !mergedSkillLesson.isLinked
              ? 'blue'
              : 'transparent',
          borderStyle: 'solid',
          padding: 10,
          borderRadius: 10,
        }}
        alignItems="center"
        display="flex"
      >
        <MUI.Box>
          <MUI.Link
            href={
              mergedSkillLesson.conversation?.id
                ? `/#/content/conversation/${mergedSkillLesson.conversation.id}`
                : `/#/g/videos/${mergedSkillLesson.video?.id}`
            }
            target="_blank"
          >
            <MUI.Typography
              variant="body2"
              style={{ textTransform: 'capitalize' }}
            >
              {typeToShow}
            </MUI.Typography>
            <MUI.Typography
              variant="body1"
              style={{ textDecorationLine: 'underline' }}
            >
              {nameToShow}
            </MUI.Typography>

            <MUI.Typography variant="body2">
              {mergedSkillLesson.numberPracticeSets + 1} items generated
            </MUI.Typography>
            {mergedSkillLesson?.video?.videoVariants?.length > 0 && (
              <MUI.Typography variant="body2">
                {mergedSkillLesson.video.videoVariants.length} video variants
              </MUI.Typography>
            )}
            {mergedSkillLesson?.conversation?.id && (
              <MUI.Typography variant="body2">1 conversation</MUI.Typography>
            )}

            <MUI.Typography variant="body2">
              {mergedSkillLesson.numberPracticeSets} sets /{' '}
              {mergedSkillLesson.practiceTextsNumber} practices
            </MUI.Typography>
          </MUI.Link>
        </MUI.Box>
      </MUI.Box>
      <MUI.Divider orientation="vertical" flexItem />
    </>
  );
};
