import React, { useState, useEffect } from 'react';
import { FC } from 'react';
import { EditButton, List, ListProps, useListContext } from 'react-admin';
import {
  Grid,
  Card,
  CardMedia,
  CardContent,
  CardActions,
  Typography,
  Button,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import api from '../api';

import LinkToRelatedUnits from './LinkToRelatedUnits';
import LinkToRelatedLessons from './LinkToRelatedLessons';

const useStyles = makeStyles({
  root: {
    marginTop: '1em',
  },
  media: {
    height: 140,
  },
  title: {
    paddingBottom: '0.5em',
  },
  actionSpacer: {
    display: 'flex',
    justifyContent: 'space-around',
  },
});

const CurriculumGrid: FC = (props) => {
  const classes = useStyles(props);
  const { data, ids } = useListContext();

  const [coverPhotoImages, setCoverPhotoImages] = useState<
    Record<string, string>
  >({});

  useEffect(() => {
    ids
      ?.filter((d) => data[d].coverPhoto)
      ?.forEach((d) => {
        api.getContentImage(data[d].coverPhoto).then((response) => {
          setCoverPhotoImages((prev) => ({
            ...prev,
            [d]: response.json.signedUrl,
          }));
        });
      });
  }, [ids?.join(',')]);

  return ids ? (
    <Grid container spacing={2} className={classes.root}>
      {ids.map((id) => (
        <Grid key={id} xs={12} sm={6} md={4} lg={3} xl={2} item>
          <Card>
            {data[id].coverPhoto && (
              <CardMedia
                image={coverPhotoImages?.[id]}
                className={classes.media}
              />
            )}
            <CardContent className={classes.title}>
              <Typography variant="h5" component="h2" align="center">
                {data[id].name}
              </Typography>
            </CardContent>
            <CardContent className={classes.title}>
              <Typography variant="body1" component="h5" align="center">
                Languages:{' '}
                {data[id]?.languagesSupported?.split(',')?.join(', ')}
              </Typography>
            </CardContent>
            <CardActions classes={{ spacing: classes.actionSpacer }}>
              <LinkToRelatedUnits record={data[id]} />
              <LinkToRelatedLessons record={data[id]} />
              <EditButton basePath="/content/curriculum" record={data[id]} />
            </CardActions>
          </Card>
        </Grid>
      ))}
    </Grid>
  ) : null;
};

const CurriculumList: FC<ListProps> = (props) => {
  const confirmClearRedisCache = async () => {
    if (window.confirm('Are you sure you want to clear the Redis cache?')) {
      const result = await api.clearRedisCache();
      if (result?.json?.success) {
        alert('Success');
      } else {
        alert('Not success');
      }
    }
  };
  return (
    <List
      {...props}
      sort={{ field: 'name', order: 'ASC' }}
      perPage={100}
      pagination={false}
      component="div"
    >
      <>
        <Button
          variant="contained"
          color="primary"
          onClick={confirmClearRedisCache}
        >
          Clear Redis Cache
        </Button>
        <CurriculumGrid />
      </>
    </List>
  );
};

export default CurriculumList;
