import { Button } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconContentAdd from '@material-ui/icons/Add';
import IconCancel from '@material-ui/icons/Cancel';
import React, { useState } from 'react';
import {
  FormWithRedirect,
  SaveButton,
  TextInput,
  required,
  useCreate,
  useNotify,
} from 'react-admin';

function CreditQuickCreateButton({
  onChange,
  coachId,
}: {
  onChange: any;
  coachId: any;
}) {
  const [showDialog, setShowDialog] = useState(false);
  const [create, { loading }] = useCreate('coach/credits');
  const notify = useNotify();

  const handleClick = () => {
    setShowDialog(true);
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const handleSubmit = async (values: any) => {
    create(
      { payload: { data: { ...values, coachId } } },
      {
        onSuccess: () => {
          setShowDialog(false);
          onChange();
        },
        onFailure: ({ error }: any) => {
          notify(error.message, 'error');
        },
      },
    );
  };

  return (
    <>
      <Button onClick={handleClick}>
        <IconContentAdd />
        Create new Credit
      </Button>
      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label="Create post"
      >
        <DialogTitle>Create Credit</DialogTitle>

        <FormWithRedirect
          resource="posts"
          save={handleSubmit}
          render={({ handleSubmitWithRedirect, saving }) => (
            <>
              <DialogContent>
                <TextInput source="title" validate={required()} fullWidth />
                <TextInput
                  source="image"
                  placeholder="Image Url"
                  validate={required()}
                  fullWidth
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseClick} disabled={loading}>
                  <IconCancel />
                </Button>
                <SaveButton
                  handleSubmitWithRedirect={handleSubmitWithRedirect}
                  saving={saving}
                  disabled={loading}
                />
              </DialogActions>
            </>
          )}
        />
      </Dialog>
    </>
  );
}

export default CreditQuickCreateButton;
