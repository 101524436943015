import * as React from 'react';
import {
  List,
  Datagrid,
  DateField,
  FunctionField,
  TextInput,
  BooleanInput,
  Filter,
} from 'react-admin';
import UserLinkField from './UserLinkField';
import VideoLinkField from './VideoLinkField';
import VideoFeedbackShow from './VideoFeedbackShow';
import Rating from '@material-ui/lab/Rating';

const VideoFeedbackFilter = (props: any) => (
  <Filter {...props}>
    <TextInput
      label="User First Name"
      source="User.firstName"
      defaultValue=""
    />
    <TextInput label="User Last Name" source="User.lastName" defaultValue="" />
    <TextInput label="Video" source="Video.name" defaultValue="" />
    <BooleanInput label="Has Comments?" source="hasComments" defaultValue />
  </Filter>
);

export const VideoFeedbackList = (props: any) => (
  <List
    {...props}
    perPage={10}
    sort={{ field: 'createdAt', order: 'DESC' }}
    filters={<VideoFeedbackFilter />}
  >
    <Datagrid rowClick="expand" expand={<VideoFeedbackShow />}>
      <UserLinkField />
      <VideoLinkField />
      <FunctionField
        render={(record: any) => {
          const { selectedOption } = record;
          return <Rating readOnly value={selectedOption} />;
        }}
        label="Score"
      />
      <DateField showTime source="createdAt" label="Date" />
    </Datagrid>
  </List>
);

export default VideoFeedbackList;
