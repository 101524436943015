import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Modal,
  Paper,
  TextareaAutosize,
  Typography,
} from '@material-ui/core';
import { Form } from 'react-final-form';
import { DateInput, TextInput, useNotify, useRefresh } from 'react-admin';
import api from '../../../api';
import { grey } from '@material-ui/core/colors';

type VideoSummaryPoint = {
  id: string;
  pointMarkdown: string;
  order: number;
};

export default function CreateEditSummaryPointModal({
  isOpen,
  closeModal,
  point,
  videoId,
  totalPoints,
}: {
  isOpen: boolean;
  closeModal: () => void;
  point: VideoSummaryPoint;
  videoId: string;
  totalPoints: number;
}) {
  const notify = useNotify();
  const refresh = useRefresh();
  const [pointMarkdown, setPointMarkdown] = useState<string>(
    point.pointMarkdown || '',
  );

  useEffect(() => {
    if (point?.pointMarkdown) {
      setPointMarkdown(point.pointMarkdown);
    } else {
      setPointMarkdown('');
    }
  }, [point?.pointMarkdown]);

  const handleSave = async (data: any) => {
    if (point.id) {
      try {
        const response = await api.patchVideoSummaryPoint(videoId, point.id, {
          pointMarkdown,
          order: data.order !== null ? Number(data.order) : point.order,
        });

        if (response.status < 300) {
          closeModal();
          refresh();
        }
      } catch (error) {
        console.log('error', error);
      }
    } else {
      const payload = {
        order:
          data.order !== null && data.order !== undefined
            ? Number(data.order)
            : totalPoints + 1,
        pointMarkdown,
      };

      try {
        const response = await api.createVideoSummaryPoint(videoId, payload);

        if (response.status < 300) {
          closeModal();
          refresh();
          notify('Summary Point Saved', 'info');
        }
      } catch (error) {
        console.log('error', error);
      }
    }
  };

  return (
    <Modal
      open={isOpen}
      onClose={closeModal}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Paper
        elevation={3}
        style={{
          backgroundColor: 'white',
          padding: '20px',
          minWidth: '40%',
          borderRadius: '5px',
          maxWidth: '80%',
          position: 'relative',
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            position: 'absolute',
            right: 20,
          }}
        >
          <Button onClick={closeModal} variant="contained">
            Close
          </Button>
        </div>
        <Box display="flex" flexDirection="column">
          <Form
            initialValues={point}
            onSubmit={handleSave}
            render={({ handleSubmit }: any) => (
              <form
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
                onSubmit={handleSubmit}
              >
                <div
                  style={{
                    minWidth: 300,
                    marginRight: 10,
                  }}
                >
                  <Typography variant="h6" gutterBottom>
                    Summary Point Markdown
                  </Typography>
                  {/* Fuck it, textarea it is, I've tried 4 different markdown libs and none compile */}
                  <TextareaAutosize
                    required
                    name="text"
                    id="text-with-highlights-creation-target-text"
                    onChange={(e: any) => {
                      setPointMarkdown(e.target.value);
                    }}
                    onKeyDown={(e) => {
                      if (e.key === 'Tab') {
                        e.preventDefault();
                        const {
                          selectionStart,
                          selectionEnd,
                        } = e.currentTarget;
                        const newText = `${pointMarkdown.substring(
                          0,
                          selectionStart,
                        )}\t${pointMarkdown.substring(selectionEnd)}`;

                        setPointMarkdown(newText);

                        e.currentTarget.setSelectionRange(
                          selectionStart + 1,
                          selectionStart + 1,
                        );
                      }
                    }}
                    value={pointMarkdown}
                    style={{
                      marginTop: 10,
                      fontFamily: 'IBM Plex Sans, sans-serif',
                      fontSize: '0.875rem',
                      fontWeight: 400,
                      marginBottom: 20,
                      width: '100%',
                      minHeight: '3rem',
                      lineHeight: 1.5,
                      padding: '12px',
                      borderRadius: '12px 12px 0 12px',
                      border: `1px solid ${grey[700]}`,
                    }}
                  />

                  <TextInput
                    source="order"
                    label="Order Number"
                    type="number"
                  />
                  <DateInput
                    disabled
                    label="Created At"
                    source="createdAt"
                    fullWidth
                  />
                  <DateInput
                    disabled
                    label="Updated At"
                    source="updatedAt"
                    fullWidth
                  />
                </div>

                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <div>
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      onClick={handleSubmit}
                    >
                      Save
                    </Button>
                  </div>
                </div>
              </form>
            )}
          />
        </Box>
      </Paper>
    </Modal>
  );
}
