/**
 * @generated SignedSource<<871b67cabd9188043a93b65a8d9e35d3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type ConversationSelect_allConversations_Query$variables = Record<PropertyKey, never>;
export type ConversationSelect_allConversations_Query$data = {
  readonly allConversations: ReadonlyArray<{
    readonly id: string | null | undefined;
    readonly name: string | null | undefined;
    readonly tags: string | null | undefined;
  }> | null | undefined;
};
export type ConversationSelect_allConversations_Query = {
  response: ConversationSelect_allConversations_Query$data;
  variables: ConversationSelect_allConversations_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "tags",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ConversationSelect_allConversations_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Conversation",
        "kind": "LinkedField",
        "name": "allConversations",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "ConversationSelect_allConversations_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Conversation",
        "kind": "LinkedField",
        "name": "allConversations",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "nodeID",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "90785b2e7d89cf60bd555188d3bc3d85",
    "id": null,
    "metadata": {},
    "name": "ConversationSelect_allConversations_Query",
    "operationKind": "query",
    "text": "query ConversationSelect_allConversations_Query {\n  allConversations {\n    id\n    name\n    tags\n    nodeID\n  }\n}\n"
  }
};
})();

(node as any).hash = "80de960a773c763c09c8ac266043dd9d";

export default node;
