import WhatshotIcon from '@material-ui/icons/Whatshot';
import RapidfireList from './RapidfireList';
import RapidfireEdit from './RapidfireEdit';
import RapidfireCreate from './RapidfireCreate';

export default {
  list: RapidfireList,
  edit: RapidfireEdit,
  icon: WhatshotIcon,
  create: RapidfireCreate,
};
