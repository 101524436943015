const escapeRegExp = (value: any) =>
  value ? value.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') : ''; // $& means the whole matched string

export const matchSuggestion = (
  filterValue: string,
  choice: any,
  property: string,
) => {
  const isStrictSearch =
    filterValue.charAt(0) === `"` &&
    filterValue.charAt(filterValue.length - 1) === `"`;
  if (isStrictSearch) {
    if (
      choice?.[property]?.toLowerCase() ===
      filterValue.replaceAll(`"`, '')?.toLowerCase()
    ) {
      return true;
    }
    return false;
  }
  if (choice?.[property]?.match(new RegExp(escapeRegExp(filterValue), 'i'))) {
    return true;
  }
  return false;
};
