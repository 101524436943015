import { useEffect, useState } from 'react';
import api from '../api';
import { MouthDiagram } from '../mouthDiagram';

export const useMouthDiagramImages = (
  diagrams: MouthDiagram[] | undefined,
  square = false,
) => {
  const [urls, setUrls] = useState<Record<string, string>>({});
  useEffect(() => {
    diagrams?.forEach((d) => {
      api
        .getContentImage(square ? d.squareImageUrl : d.imageUrl)
        .then((response) => {
          setUrls((prev) => ({
            ...prev,
            [square ? d.squareImageUrl : d.imageUrl]: response.json.signedUrl,
          }));
        });
    });
  }, [diagrams?.map((d) => d.imageUrl)?.join(',')]);

  return { urls };
};
