/**
 * @generated SignedSource<<ee7c8aac2b99161fe1cd14315ef59d5e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DLPAssessmentsTaken2_Query$variables = {
  userID: string;
};
export type DLPAssessmentsTaken2_Query$data = {
  readonly assessmentsTakenForUser: ReadonlyArray<{
    readonly " $fragmentSpreads": FragmentRefs<"DLPAssessmentsTaken2_AssessmentTaken">;
  }> | null | undefined;
};
export type DLPAssessmentsTaken2_Query = {
  response: DLPAssessmentsTaken2_Query$data;
  variables: DLPAssessmentsTaken2_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "userID"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "userID",
    "variableName": "userID"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DLPAssessmentsTaken2_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AssessmentTaken",
        "kind": "LinkedField",
        "name": "assessmentsTakenForUser",
        "plural": true,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "DLPAssessmentsTaken2_AssessmentTaken"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DLPAssessmentsTaken2_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AssessmentTaken",
        "kind": "LinkedField",
        "name": "assessmentsTakenForUser",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "createdAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "completed",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "averageScore",
            "storageKey": null
          },
          {
            "alias": "oldScores",
            "args": null,
            "kind": "ScalarField",
            "name": "score",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "nodeID",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c90d42eed29fb17186945d82f6ebe03c",
    "id": null,
    "metadata": {},
    "name": "DLPAssessmentsTaken2_Query",
    "operationKind": "query",
    "text": "query DLPAssessmentsTaken2_Query(\n  $userID: ID!\n) {\n  assessmentsTakenForUser(userID: $userID) {\n    ...DLPAssessmentsTaken2_AssessmentTaken\n    nodeID\n  }\n}\n\nfragment DLPAssessmentsTaken2_AssessmentTaken on AssessmentTaken {\n  id\n  createdAt\n  completed\n  averageScore\n  oldScores: score\n}\n"
  }
};
})();

(node as any).hash = "ee10790886323720d10f547b6fb67be3";

export default node;
