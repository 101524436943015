import { Box, Typography } from '@material-ui/core';
import graphql from 'babel-plugin-relay/macro';
import {
  DeleteButton,
  Edit,
  FormWithRedirect,
  SaveButton,
  Toolbar,
} from 'react-admin';
import { useLazyLoadQuery } from 'react-relay';

import MultipleChoiceOptionsEditor from './MultipleChoiceOptionsEditor';
import MultipleChoiceQuestionFormFields from './MultipleChoiceQuestionFormFields';

const MultipleChoiceQuestionForm = (props: any) => (
  <FormWithRedirect
    {...props}
    render={(formProps: any) => (
      <form>
        <Box p="1em">
          <Typography variant="h6" gutterBottom>
            Multiple Choice Question
          </Typography>

          <MultipleChoiceQuestionFormFields
            autogenerated={props.record.autogenerated}
          />
        </Box>
        <Toolbar
          record={formProps.record}
          basePath={formProps.basePath}
          undoable
          invalid={formProps.invalid}
          handleSubmit={formProps.handleSubmit}
          saving={formProps.saving}
          resource="g:MultipleChoiceQuestion"
          style={{ marginTop: 0 }}
        >
          <Box display="flex" justifyContent="space-between" width="100%">
            <SaveButton
              label="Save"
              handleSubmitWithRedirect={
                formProps.handleSubmitWithRedirect || formProps.handleSubmit
              }
              disabled={formProps.disabled}
              invalid={formProps.invalid}
              redirect="edit"
              saving={formProps.saving}
              submitOnEnter={formProps.submitOnEnter}
            />

            {props.record && typeof props.record.id !== 'undefined' && (
              <DeleteButton
                basePath={formProps.basePath}
                record={formProps.record}
                resource={formProps.resource}
                undoable={formProps.undoable}
                mutationMode={formProps.mutationMode}
              />
            )}
          </Box>
        </Toolbar>
      </form>
    )}
  />
);

const MultipleChoiceQuestionEdit = (props: any) => {
  const data: any = useLazyLoadQuery(
    graphql`
      query MultipleChoiceQuestionEditQuery($id: ID) {
        raMultipleChoiceQuestion(id: $id) {
          id

          ...MultipleChoiceOptionsEditor_question
        }
      }
    `,
    { id: props.id },
  );

  return (
    <Box display="flex" flexDirection="column" gridRowGap={20}>
      <Edit {...props}>
        <MultipleChoiceQuestionForm />
      </Edit>

      <MultipleChoiceOptionsEditor question={data?.raMultipleChoiceQuestion} />
    </Box>
  );
};

export default MultipleChoiceQuestionEdit;
