import * as React from 'react';
import {
  List,
  Datagrid,
  DateField,
  TextField,
  TextInput,
  Filter,
  SelectInput,
  BooleanInput,
} from 'react-admin';

import UserLinkField from './UserLinkField';
import RecordLinkField from './RecordLinkField';

export const PHONE_OS_LIST = [
  { id: 'ios', name: 'iOS' },
  { id: 'android', name: 'Android' },
  { id: 'web', name: 'Web' },
];

export const USER_TYPE_LIST = [
  { id: 'admin', name: 'Admin' },
  { id: 'user', name: 'User' },
];

const UserFilter = (props: any) => (
  <Filter {...props}>
    <SelectInput source="phoneOS" label="Phone OS" choices={PHONE_OS_LIST} />
    <TextInput label="IP Address" source="ipAddress" defaultValue="" />
    <TextInput label="Phone Number" source="phoneNumber" defaultValue="" />
    <TextInput label="Referral Source" source="referral" defaultValue="" />
    <TextInput label="Will you commit?" source="commit" defaultValue="" />
    <TextInput label="Motivation" source="motivation" defaultValue="" />
    <TextInput
      label="English Proficiency"
      source="proficiency"
      defaultValue=""
    />
    <TextInput
      label="Accent Confidence"
      source="pronunciation_accent"
      defaultValue=""
    />
    <TextInput label="Language" source="language" defaultValue="" />
    <TextInput label="Development Setting" source="setting" defaultValue="" />
    <BooleanInput
      label="First Open"
      source="isFirstOpen"
      defaultValue={false}
    />
  </Filter>
);

export const UserList = (props: any) => (
  <List
    {...props}
    sort={{ field: 'createdAt', order: 'DESC' }}
    filters={<UserFilter />}
  >
    <Datagrid>
      <RecordLinkField />
      <UserLinkField />
      <TextField source="language" label="Language" />
      <TextField source="referral" label="Referral Source" />
      <TextField source="commit" label="Will you commit?" />
      <TextField source="phoneOS" label="Phone OS" />
      <TextField source="isFirstOpen" label="First Open" />
      <DateField source="createdAt" label="Joined" />
    </Datagrid>
  </List>
);

export default UserList;
