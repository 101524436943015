/**
 * @generated SignedSource<<8faab6b9ac874d426581511c4ed2a182>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type SkillLessonType = "CONVERSATION" | "FOCUS_WORD" | "IDIOM" | "LESSON" | "LINKED_SKILL_LESSON" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type SkillPathsDisplay_skill$data = {
  readonly active: boolean | null | undefined;
  readonly activeSkillLessonsNumber: number | null | undefined;
  readonly categories: any | null | undefined;
  readonly id: string | null | undefined;
  readonly name: string | null | undefined;
  readonly skillLessons: ReadonlyArray<{
    readonly active: boolean | null | undefined;
    readonly conversation: {
      readonly id: string | null | undefined;
      readonly name: string | null | undefined;
    } | null | undefined;
    readonly id: string | null | undefined;
    readonly linkedSkillLesson: {
      readonly conversation: {
        readonly id: string | null | undefined;
        readonly name: string | null | undefined;
      } | null | undefined;
      readonly id: string | null | undefined;
      readonly numberPracticeSets: number | null | undefined;
      readonly practiceTextsNumber: number | null | undefined;
      readonly type: SkillLessonType | null | undefined;
      readonly video: {
        readonly id: string | null | undefined;
        readonly introductory: boolean | null | undefined;
        readonly title: string | null | undefined;
        readonly videoVariants: ReadonlyArray<{
          readonly id: string | null | undefined;
          readonly title: string | null | undefined;
        }> | null | undefined;
      } | null | undefined;
    } | null | undefined;
    readonly numberPracticeSets: number | null | undefined;
    readonly order: number | null | undefined;
    readonly practiceTextsNumber: number | null | undefined;
    readonly type: SkillLessonType | null | undefined;
    readonly video: {
      readonly id: string | null | undefined;
      readonly introductory: boolean | null | undefined;
      readonly title: string | null | undefined;
      readonly videoVariants: ReadonlyArray<{
        readonly id: string | null | undefined;
      }> | null | undefined;
    } | null | undefined;
  }> | null | undefined;
  readonly skillLessonsNumber: number | null | undefined;
  readonly " $fragmentType": "SkillPathsDisplay_skill";
};
export type SkillPathsDisplay_skill$key = {
  readonly " $data"?: SkillPathsDisplay_skill$data;
  readonly " $fragmentSpreads": FragmentRefs<"SkillPathsDisplay_skill">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "active",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "introductory",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "Conversation",
  "kind": "LinkedField",
  "name": "conversation",
  "plural": false,
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/)
  ],
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "numberPracticeSets",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "practiceTextsNumber",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SkillPathsDisplay_skill",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "categories",
      "storageKey": null
    },
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "skillLessonsNumber",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "activeSkillLessonsNumber",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SkillLesson",
      "kind": "LinkedField",
      "name": "skillLessons",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "order",
          "storageKey": null
        },
        (v3/*: any*/),
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "Video",
          "kind": "LinkedField",
          "name": "video",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            (v4/*: any*/),
            (v5/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "VideoVariant",
              "kind": "LinkedField",
              "name": "videoVariants",
              "plural": true,
              "selections": [
                (v0/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        (v6/*: any*/),
        (v7/*: any*/),
        (v8/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "SkillLesson",
          "kind": "LinkedField",
          "name": "linkedSkillLesson",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            (v3/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "Video",
              "kind": "LinkedField",
              "name": "video",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v4/*: any*/),
                (v5/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "VideoVariant",
                  "kind": "LinkedField",
                  "name": "videoVariants",
                  "plural": true,
                  "selections": [
                    (v0/*: any*/),
                    (v4/*: any*/)
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            (v6/*: any*/),
            (v7/*: any*/),
            (v8/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Skill",
  "abstractKey": null
};
})();

(node as any).hash = "36431a6c53b5f350b51c65e7184330b6";

export default node;
