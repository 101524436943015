import TargetIcon from '@material-ui/icons/FlagTwoTone';

import GoalTemplateList from './GoalTemplateList';
import GoalTemplateEdit from './GoalTemplateEdit';
import GoalTemplateCreate from './GoalTemplateCreate';

export default {
  list: GoalTemplateList,
  edit: GoalTemplateEdit,
  icon: TargetIcon,
  create: GoalTemplateCreate,
};
