/**
 * @generated SignedSource<<08beb284077c6910172df2856f764fd0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Queues_SetSystemJobQueueActiveMutation$variables = {
  active: boolean;
  name: string;
};
export type Queues_SetSystemJobQueueActiveMutation$data = {
  readonly setSystemJobQueueActive: {
    readonly " $fragmentSpreads": FragmentRefs<"Queues_Queue">;
  } | null | undefined;
};
export type Queues_SetSystemJobQueueActiveMutation = {
  response: Queues_SetSystemJobQueueActiveMutation$data;
  variables: Queues_SetSystemJobQueueActiveMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "active"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "name"
},
v2 = [
  {
    "kind": "Variable",
    "name": "active",
    "variableName": "active"
  },
  {
    "kind": "Variable",
    "name": "name",
    "variableName": "name"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "nodeID",
  "storageKey": null
},
v4 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "id",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "status",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "enqueuedAt",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "startedAt",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "endedAt",
    "storageKey": null
  },
  (v3/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "Queues_SetSystemJobQueueActiveMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "SystemJobQueue",
        "kind": "LinkedField",
        "name": "setSystemJobQueueActive",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "Queues_Queue"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "Queues_SetSystemJobQueueActiveMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "SystemJobQueue",
        "kind": "LinkedField",
        "name": "setSystemJobQueueActive",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "active",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "SystemJob",
            "kind": "LinkedField",
            "name": "recentJobs",
            "plural": true,
            "selections": (v4/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "SystemJob",
            "kind": "LinkedField",
            "name": "failedJobs",
            "plural": true,
            "selections": (v4/*: any*/),
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "3168d1895849fc522c00028e752a4c8d",
    "id": null,
    "metadata": {},
    "name": "Queues_SetSystemJobQueueActiveMutation",
    "operationKind": "mutation",
    "text": "mutation Queues_SetSystemJobQueueActiveMutation(\n  $name: String!\n  $active: Boolean!\n) {\n  setSystemJobQueueActive(name: $name, active: $active) {\n    ...Queues_Queue\n    nodeID\n  }\n}\n\nfragment Queues_Queue on SystemJobQueue {\n  name\n  active\n  recentJobs {\n    id\n    status\n    enqueuedAt\n    startedAt\n    endedAt\n    nodeID\n  }\n  failedJobs {\n    id\n    status\n    enqueuedAt\n    startedAt\n    endedAt\n    nodeID\n  }\n}\n"
  }
};
})();

(node as any).hash = "f2e25b08b798187ef380623397af28b8";

export default node;
