import * as React from 'react';
import { Datagrid, List, ListProps, FunctionField } from 'react-admin';
import { FC } from 'react';
import { Link, FieldProps, DateField } from 'react-admin';

// import SongListAside from './SongListAside';
import { ReactElement } from 'react';

const SongLinkField: FC<FieldProps> = (props) =>
  props.record ? (
    <Link to={`/content/song/${props.record.id}`}>
      {props.record.title} / {props.record.artist}
    </Link>
  ) : null;

SongLinkField.defaultProps = {
  source: 'name',
  addLabel: true,
};

const SongList = (props: ListProps): ReactElement => (
  <List
    {...props}
    sort={{ field: 'title', order: 'ASC' }}
    perPage={25} // aside={<SongListAside />}
  >
    <Datagrid optimized>
      <SongLinkField label="Title" />
      <FunctionField
        render={(record: any) => record && record.practice_items.length}
        label="Num. Practice"
      />
      <DateField showTime source="createdAt" label="Created" />
    </Datagrid>
  </List>
);

export default SongList;
