/**
 * @generated SignedSource<<4cf9acd10a0e96a08659da21c7263624>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SupersedingSkillsSelect_skillsForString$data = {
  readonly skillsForString: {
    readonly nodes: ReadonlyArray<{
      readonly id: string | null | undefined;
      readonly name: string | null | undefined;
    } | null | undefined>;
  } | null | undefined;
  readonly " $fragmentType": "SupersedingSkillsSelect_skillsForString";
};
export type SupersedingSkillsSelect_skillsForString$key = {
  readonly " $data"?: SupersedingSkillsSelect_skillsForString$data;
  readonly " $fragmentSpreads": FragmentRefs<"SupersedingSkillsSelect_skillsForString">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": "",
      "kind": "LocalArgument",
      "name": "search"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [],
      "operation": require('./SkillsSelect_skillsForString_RefetchQuery.graphql')
    }
  },
  "name": "SupersedingSkillsSelect_skillsForString",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 20
        },
        {
          "kind": "Variable",
          "name": "search",
          "variableName": "search"
        }
      ],
      "concreteType": "QuerySkillsForStringConnection",
      "kind": "LinkedField",
      "name": "skillsForString",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Skill",
          "kind": "LinkedField",
          "name": "nodes",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "id",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "b28c679ed751741fb79f7441fa6a3225";

export default node;
