import * as React from 'react';
import {
  Datagrid,
  DateField,
  List,
  Filter,
  ListProps,
  TextField,
  TextInput,
  BooleanInput,
  FunctionField,
} from 'react-admin';
import { FC } from 'react';
import { Link, FieldProps } from 'react-admin';

import LessonLinkField from './LessonLinkField';
import UrlLinkField from './UrlLinkField';

import VideoListAside from './VideoListAside';
import { ReactElement } from 'react';

const VideoLinkField: FC<FieldProps> = (props) =>
  props.record ? (
    <Link to={`/content/video/${props.record.id}`}>{props.record.name}</Link>
  ) : null;

VideoLinkField.defaultProps = {
  source: 'name',
  addLabel: true,
};

const VideoFilter = (props: any) => (
  <Filter {...props}>
    <TextInput label="Tags" source="tags" defaultValue="" />
    <TextInput label="Video host" source="hosting_provider" defaultValue="" />
    <BooleanInput label="Has Subtitles?" source="hasSubtitles" defaultValue />
  </Filter>
);
const CoachLinkField: FC<FieldProps> = (props) =>
  props.record?.Coach ? (
    <Link to={`/coach/${props.record.coachId}`}>
      {props.record?.Coach?.name}
    </Link>
  ) : null;

CoachLinkField.defaultProps = {
  source: 'coachId',
  addLabel: true,
};

const VideoList = (props: ListProps): ReactElement => (
  <List
    {...props}
    sort={{ field: 'name', order: 'ASC' }}
    perPage={25}
    aside={<VideoListAside />}
    filters={<VideoFilter />}
  >
    <Datagrid optimized>
      <VideoLinkField label="Name" />
      <TextField source="hosting_provider" label="Video Host" />
      <UrlLinkField />
      <TextField source="tags" label="Tags" />
      <CoachLinkField />

      <FunctionField
        render={(record: any) => record && record.practice_items.length}
        label="Num. Practice Items"
      />
      <LessonLinkField />
      <DateField showTime source="createdAt" label="Created" />
    </Datagrid>
  </List>
);

export default VideoList;
