import { Box } from '@material-ui/core';
import { Theme, makeStyles } from '@material-ui/core/styles';
import { Styles } from '@material-ui/styles/withStyles';
import * as React from 'react';
import { FC } from 'react';
import {
  Create,
  CreateProps,
  NullableBooleanInput,
  NumberInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
  required,
} from 'react-admin';

import { MultipleChoicePromptInput } from '../components/MultipleChoicePromptSelect';

export const styles: Styles<Theme, any> = {
  name: { display: 'inline-block' },
  number: { display: 'inline-block', marginLeft: 10 },
};

const useStyles = makeStyles(styles);

const QuipCreate: FC<CreateProps> = (props) => {
  const classes = useStyles(props);

  return (
    <Create {...props}>
      <SimpleForm initialValues={{}}>
        <TextInput
          fullWidth
          autoFocus
          source="blurb"
          label="Blurb"
          className={classes.name}
          validate={requiredValidate}
          multiline
        />
        <ReferenceInput
          label="Coach Id"
          source="coachId"
          reference="coach"
          validate={[required()]}
          fullWidth
          autoFocus
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
        <TextInput
          fullWidth
          autoFocus
          source="audioFile"
          label="Audio File"
          className={classes.name}
          validate={requiredValidate}
        />
        <NumberInput
          fullWidth
          autoFocus
          source="stateKey"
          label="State Key"
          className={classes.name}
          validate={requiredValidate}
        />
        <NumberInput
          fullWidth
          autoFocus
          source="nestedQuipOrder"
          label="Nested Quip Order (starts with 0)"
          className={classes.name}
        />
        <Box>
          <div>
            Sentence Only: Select Yes. Words Only: Select No. Applicable to All:
            Select Empty Option.
          </div>
          <NullableBooleanInput
            fullWidth
            style={{ minWidth: 200 }}
            source="isForSentence"
            label="Is for Sentence"
            className={classes.name}
          />
        </Box>
        <Box>
          <div>
            *If language is empty, then the quip will be used for all langugaes
          </div>
          <TextInput label="Language" source="language" />
        </Box>
        <Box>
          <div>If this quip is for a multiple choice prompt.</div>
          <MultipleChoicePromptInput source="multipleChoicePromptID" />
        </Box>
      </SimpleForm>
    </Create>
  );
};

const requiredValidate = [required()];

export default QuipCreate;
