import * as MUI from '@material-ui/core';
import graphql from 'babel-plugin-relay/macro';
import React from 'react';
import {
  useFragment,
  useLazyLoadQuery,
  useRefetchableFragment,
} from 'react-relay';

import IPAText from '../components/IPAText';

const SkillsTextScan = () => {
  const [_isPending, startTransition] = React.useTransition();

  const [text, setText] = React.useState('');

  const data = useLazyLoadQuery(
    graphql`
      query SkillsTextScan_Query {
        ...SkillsTextScan_result
      }
    `,
    {},
    { fetchPolicy: 'network-only' },
  );

  const [scanData, refetch] = useRefetchableFragment(
    graphql`
      fragment SkillsTextScan_result on Query
      @refetchable(queryName: "SkillsTextScanRefetchQuery")
      @argumentDefinitions(text: { type: "String", defaultValue: "" }) {
        scanTextForSkills(text: $text) {
          skill {
            id
          }

          ...SkillsTextScan_SkillMatch
        }
      }
    `,
    data as any,
  );

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      startTransition(() => {
        refetch({ text }, { fetchPolicy: 'network-only' });
      });
    }, 500);
    return () => clearTimeout(timeout);
  }, [text]);

  return (
    <MUI.Box style={{ height: 'calc(100vh - 200px)' }}>
      <MUI.Box marginBottom={3} marginTop={2}>
        <MUI.Typography variant="h4" gutterBottom>
          Skills Text Scan
        </MUI.Typography>
        <MUI.Typography variant="body1" gutterBottom>
          Enter text to see what skills are covered.
        </MUI.Typography>
      </MUI.Box>

      <MUI.Box
        display="flex"
        flexDirection="row"
        gridColumnGap={16}
        style={{ height: '100%' }}
      >
        <MUI.Box maxWidth={600} flexGrow={1}>
          <MUI.TextField
            variant="outlined"
            multiline
            fullWidth
            minRows={10}
            size="small"
            label="Text"
            style={{ maxHeight: 'calc(100vh - 200px)' }}
            onChange={(e) => setText(e.target.value)}
            // InputProps={{
            //   endAdornment: (
            //     <MUI.InputAdornment
            //       style={{
            //         alignItems: 'flex-end',
            //         alignSelf: 'flex-end',
            //         paddingBottom: 2,
            //         paddingRight: 2,
            //         height: 16,
            //         width: 16,
            //       }}
            //       position="end"
            //     >
            //       <MUI.CircularProgress size={16} />
            //     </MUI.InputAdornment>
            //   ),
            // }}
          />
        </MUI.Box>
        <MUI.Box
          maxWidth={600}
          flexGrow={1}
          style={{
            overflowY: 'scroll',
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
          }}
        >
          <MUI.Box style={{ flexGrow: 1, height: 0 }}>
            {scanData?.scanTextForSkills?.map((match) => (
              <SkillMatch key={match.skill.id} skillMatch={match} />
            ))}
          </MUI.Box>
        </MUI.Box>
      </MUI.Box>
    </MUI.Box>
  );
};

const SkillMatch = (props: any) => {
  const skillMatch = useFragment(
    graphql`
      fragment SkillsTextScan_SkillMatch on SkillsTextScanMatch {
        skill {
          id
          name
        }

        words {
          text
        }
      }
    `,
    props.skillMatch,
  );

  return (
    <MUI.Box marginBottom={0.5}>
      <MUI.Paper variant="outlined" style={{ padding: 4 }}>
        <MUI.Box marginBottom={0.3}>
          <MUI.Typography variant="body1">
            <MUI.Link
              href={`/#/g/skills/${skillMatch.skill.id}`}
              target="_blank"
              color="inherit"
            >
              <IPAText text={skillMatch.skill.name} />
            </MUI.Link>{' '}
            <MUI.Typography color="textSecondary" display="inline">
              — {skillMatch.words.length} word
              {skillMatch.words.length === 1 ? '' : 's'}
            </MUI.Typography>
          </MUI.Typography>
        </MUI.Box>
        <MUI.Box>
          <MUI.Typography variant="body2">
            {skillMatch.words.map((word) => (
              <>
                <div
                  style={{
                    display: 'inline-block',
                    paddingLeft: 3,
                    paddingRight: 3,
                    backgroundColor: 'rgba(50, 45, 84, 0.08)',
                    borderRadius: 3,
                    marginTop: 3,
                  }}
                >
                  <MUI.Link
                    href={`/#/g/words/${encodeURIComponent(word.text)}`}
                    target="_blank"
                    color="inherit"
                  >
                    {word.text}
                  </MUI.Link>
                </div>{' '}
              </>
            ))}
          </MUI.Typography>
        </MUI.Box>
      </MUI.Paper>
    </MUI.Box>
  );
};

export default SkillsTextScan;
