import * as React from 'react';
import { FC } from 'react';
import {
  Create,
  CreateProps,
  SimpleForm,
  TextInput,
  required,
} from 'react-admin';

import { JsonInput } from 'react-admin-json-view';

import { Theme } from '@material-ui/core/styles';
import { Styles } from '@material-ui/styles/withStyles';

export const styles: Styles<Theme, any> = {
  name: { display: 'inline-block' },
  number: { display: 'inline-block', marginLeft: 10 },
};

const TutorialConfigCreate: FC<CreateProps> = (props) => (
  <Create {...props}>
    <SimpleForm initialValues={{ factoids: [], visualTimelineContent: [] }}>
      <TextInput label="Language" source="language" validate={[required()]} />
      <JsonInput
        source="factoids"
        jsonString={false}
        reactJsonOptions={{
          name: 'Factoids',
          collapsed: false,
          enableClipboard: true,
          displayDataTypes: false,
          sortKeys: true,
          displayObjectSize: true,
        }}
      />
      <JsonInput
        source="visualTimelineContent"
        jsonString={false}
        reactJsonOptions={{
          name: 'Visual Timeline Content',
          collapsed: false,
          enableClipboard: true,
          displayDataTypes: false,
          sortKeys: true,
          displayObjectSize: true,
        }}
      />
    </SimpleForm>
  </Create>
);

export default TutorialConfigCreate;
