import * as MUI from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import graphql from 'babel-plugin-relay/macro';
import React from 'react';
import { useLazyLoadQuery } from 'react-relay';

export const VideoVariantSelect = (props: any) => (
  <React.Suspense
    fallback={
      <VideoVariantSelectField {...props} isLoading videoVariants={[]} />
    }
  >
    <VideoVariantSelectWithData {...props} />
  </React.Suspense>
);

const VideoVariantSelectWithData = (props: any) => {
  const [isRefreshPending, startRefreshTransition] = React.useTransition();
  const [refreshedQueryOptions, setRefreshedQueryOptions] = React.useState({});

  const data: any = useLazyLoadQuery(
    graphql`
      query VideoVariantSelect_NewVideoVariant_allVideoVariants_Query {
        allVideoVariants {
          id
          title
          tags
        }
      }
    `,
    {},
    refreshedQueryOptions,
  );

  const refresh = React.useCallback(() => {
    startRefreshTransition(() => {
      setRefreshedQueryOptions((prev: any) => ({
        fetchKey: (prev.fetchKey ?? 0) + 1,
        fetchPolicy: 'network-only',
      }));
    });
  }, [startRefreshTransition, setRefreshedQueryOptions]);

  return (
    <VideoVariantSelectField
      {...props}
      videoVariants={data.allVideoVariants}
      onRefresh={refresh}
      isRefreshPending={isRefreshPending}
    />
  );
};

const VideoVariantSelectField = ({
  videoVariants,
  onChange,
  value,
  onRefresh,
  isLoading,
  isRefreshPending,
}: any) => {
  const videosByID = React.useMemo(
    () =>
      Object.fromEntries(videoVariants.map((video: any) => [video.id, video])),
    [videoVariants],
  );

  const videoIDs = React.useMemo(
    () => videoVariants.map((video: any) => video.id),
    [videoVariants],
  );

  return (
    <Autocomplete
      options={videoIDs}
      getOptionLabel={(videoID: any) =>
        `${videosByID[videoID]?.title}${videosByID[videoID].tags ? ` - ${videosByID[videoID].tags}` : ``}` ??
        'Invalid Video'
      }
      style={{ width: 600 }}
      size="small"
      blurOnSelect
      selectOnFocus
      disabled={isLoading}
      renderOption={(videoID: any) => {
        const video = videosByID[videoID];
        return (
          <MUI.Box display="flex" flexDirection="column">
            {video.title}
            {video.tags ? ` - ${video.tags}` : ``}
            <MUI.Box component="span" color="text.secondary" fontSize={12}>
              {video.id}
            </MUI.Box>
          </MUI.Box>
        );
      }}
      renderInput={(params) => (
        <MUI.TextField
          {...params}
          label="Video"
          variant="filled"
          required
          margin="normal"
          InputLabelProps={{ shrink: value ? true : undefined }}
          helperText={
            isLoading ? (
              'Loading videos...'
            ) : isRefreshPending ? (
              'Refreshing videos...'
            ) : (
              <>
                <MUI.Link
                  style={{ cursor: 'pointer' }}
                  onClick={(e: any) => {
                    onRefresh?.();
                    e.stopPropagation();
                  }}
                >
                  Refresh
                </MUI.Link>{' '}
                videos
              </>
            )
          }
        />
      )}
      onChange={(e, value) => onChange(value)}
      value={value}
    />
  );
};
