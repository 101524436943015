/**
 * @generated SignedSource<<522abca23e6ea4e46c2b0e41e1a81272>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SkillAssessments_user$data = {
  readonly createdAt: any | null | undefined;
  readonly skills: ReadonlyArray<{
    readonly skill: {
      readonly id: string | null | undefined;
    } | null | undefined;
    readonly skillAssessments: ReadonlyArray<{
      readonly createdAt: any | null | undefined;
    }> | null | undefined;
    readonly " $fragmentSpreads": FragmentRefs<"SkillAssessments_Skill_userSkill">;
  }> | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"SkillAssessments_OverallSkill_user" | "SkillAssessments_Skill_user">;
  readonly " $fragmentType": "SkillAssessments_user";
};
export type SkillAssessments_user$key = {
  readonly " $data"?: SkillAssessments_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"SkillAssessments_user">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SkillAssessments_user",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "UserSkill",
      "kind": "LinkedField",
      "name": "skills",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Skill",
          "kind": "LinkedField",
          "name": "skill",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "id",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "SkillAssessment",
          "kind": "LinkedField",
          "name": "skillAssessments",
          "plural": true,
          "selections": [
            (v0/*: any*/)
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SkillAssessments_Skill_userSkill"
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SkillAssessments_OverallSkill_user"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SkillAssessments_Skill_user"
    }
  ],
  "type": "User",
  "abstractKey": null
};
})();

(node as any).hash = "da2f395fa94211f2814450df2455da8f";

export default node;
