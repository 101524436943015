import React, { FC, useEffect } from 'react';
import {
  List,
  Datagrid,
  DateField,
  TextInput,
  Filter,
  TextField,
  NumberField,
  toggleListItemExpand,
  FunctionField,
  BooleanInput,
  FieldProps,
} from 'react-admin';
import UserLinkField from '../audiologs/UserLinkField';

import GenConvShow from './GenConvShow';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import Rating from '@material-ui/lab/Rating/Rating';
import useDynamicPhospherIcon from './topic_config/useDynamicPhospherIcon';
import { Link } from 'react-router-dom';
import { Badge } from '@material-ui/core';
import { Megaphone } from '@phosphor-icons/react';

const ScenarioLinkField: FC<FieldProps> = (props) => {
  const PreviewIcon = useDynamicPhospherIcon(props?.record?.topicConfig?.icon);
  if (!PreviewIcon) return null;
  return PreviewIcon ? (
    <Link
      to={`/content/generative_conversation/topic_config/${props?.record?.topicConfig?.id}`}
      style={{
        alignItems: 'center',
        ...(props?.record?.title ? { color: 'black' } : {}),
      }}
    >
      <PreviewIcon size={30} />
    </Link>
  ) : null;
};

const GenConvFilter = (props: any) => (
  <Filter {...props}>
    <TextInput label="User ID" source="User.id" defaultValue="" />
    <TextInput label="User Email" source="User.email" defaultValue="" />
    <TextInput
      label="User First Name"
      source="User.firstName"
      defaultValue=""
    />
    <TextInput label="User Language" source="User.language" defaultValue="" />

    <TextInput label="Category" source="promptCategory" defaultValue="" />
    <TextInput
      label="User Rating (number 1-5)"
      source="userRating"
      defaultValue=""
    />
    <BooleanInput label="Completed" source="completed" defaultValue="" />
  </Filter>
);

export const GenConvList = (props: any) => {
  const expanded = useSelector(
    (state: RootStateOrAny) =>
      state?.admin?.resources?.['logs/generative_conversation']?.list?.expanded,
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (expanded.length > 1)
      dispatch(
        toggleListItemExpand('logs/generative_conversation', expanded[0]),
      );
  }, [expanded]);

  return (
    <List
      {...props}
      perPage={25}
      sort={{ field: 'createdAt', order: 'DESC' }}
      filters={<GenConvFilter />}
    >
      <Datagrid rowClick="expand" expand={<GenConvShow />} stickyHeader>
        <UserLinkField />
        <TextField source="promptCategory" label="Category" />
        <ScenarioLinkField label="Scenario" />
        <NumberField source="averageScore" label="Average Score" />
        <FunctionField
          align="right"
          textAlign="right"
          render={(record: any) => {
            const { userRating, userTextFeedback } = record;
            // can only leave feedback if you have rated

            return userRating !== null ? (
              <>
                <Rating readOnly value={userRating} />
                {userTextFeedback ? (
                  <Badge
                    style={{ marginLeft: 4 }}
                    badgeContent={<Megaphone />}
                    color="primary"
                  />
                ) : null}
              </>
            ) : null;
          }}
          label="User Rating"
        />
        <FunctionField
          textAlign="right"
          render={(record: any) =>
            record.chatMessageHistory
              .filter((message: any) => message.role === 'user')
              .reduce((acc: number, message: any) => {
                if (message.metaData?.selfCorrectedCount) {
                  return acc + message.metaData.selfCorrectedCount;
                }
                return acc;
              }, 0) || ''
          }
          label="Fixed single word count"
        />
        <FunctionField
          textAlign="right"
          render={(record: any) =>
            record.chatMessageHistory
              .filter((message: any) => message.role === 'user')
              .reduce((acc: number, message: any) => {
                if (message.metaData?.editedCount) {
                  return acc + message.metaData.editedCount;
                }
                return acc;
              }, 0) || ''
          }
          label="Transcript edited count"
        />
        <FunctionField
          textAlign="right"
          render={(record: any) => {
            const { goals } = record;
            if (!goals || goals?.length === 0) return null;
            const completedGoals = goals.filter((goal: any) => goal.completed);
            const precision = 1 / goals.length;
            return (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                }}
              >
                <Rating
                  readOnly
                  value={completedGoals.length / goals.length}
                  precision={precision}
                  size="large"
                  max={1}
                />
                <span style={{ marginLeft: 4 }}>
                  {completedGoals.length} / {goals.length}
                </span>
              </div>
            );
          }}
          label="Goals"
        />

        <FunctionField
          textAlign="right"
          render={(record: any) => {
            const { chatMessageHistory } = record;

            return (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                }}
              >
                <span style={{ marginLeft: 4 }}>
                  {chatMessageHistory?.length ?? 0}
                </span>
              </div>
            );
          }}
          label="Length"
        />

        <DateField showTime source="createdAt" label="Created" />
        <DateField showTime source="updatedAt" label="Updated" />
      </Datagrid>
    </List>
  );
};

export default GenConvList;
