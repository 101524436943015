/**
 * @generated SignedSource<<4cd92ecc0106a2ddb07848efca66eda7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DLPPlayground_userSkill$data = {
  readonly assessmentMaxScore: number | null | undefined;
  readonly assessmentMinScore: number | null | undefined;
  readonly assessmentScore: number | null | undefined;
  readonly isLanguageRelevant: boolean | null | undefined;
  readonly score: number | null | undefined;
  readonly skill: {
    readonly id: string | null | undefined;
    readonly importance: number | null | undefined;
    readonly name: string | null | undefined;
    readonly quickWin: boolean | null | undefined;
    readonly speechaceCanScoreSkillWell: boolean | null | undefined;
  } | null | undefined;
  readonly superseding: ReadonlyArray<{
    readonly " $fragmentSpreads": FragmentRefs<"DLPPlayground_userSkill">;
  }> | null | undefined;
  readonly " $fragmentType": "DLPPlayground_userSkill";
};
export type DLPPlayground_userSkill$key = {
  readonly " $data"?: DLPPlayground_userSkill$data;
  readonly " $fragmentSpreads": FragmentRefs<"DLPPlayground_userSkill">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": true,
      "kind": "LocalArgument",
      "name": "includeSuperseding"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "DLPPlayground_userSkill",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "score",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "assessmentScore",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "assessmentMinScore",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "assessmentMaxScore",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isLanguageRelevant",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Skill",
      "kind": "LinkedField",
      "name": "skill",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "quickWin",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "importance",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "speechaceCanScoreSkillWell",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "DLPPlaygroundUserSkill",
      "kind": "LinkedField",
      "name": "superseding",
      "plural": true,
      "selections": [
        {
          "condition": "includeSuperseding",
          "kind": "Condition",
          "passingValue": true,
          "selections": [
            {
              "args": [
                {
                  "kind": "Literal",
                  "name": "includeSuperseding",
                  "value": false
                }
              ],
              "kind": "FragmentSpread",
              "name": "DLPPlayground_userSkill"
            }
          ]
        }
      ],
      "storageKey": null
    }
  ],
  "type": "DLPPlaygroundUserSkill",
  "abstractKey": null
};

(node as any).hash = "0353d1e751b9f221b67c0701e4091623";

export default node;
