/**
 * @generated SignedSource<<d55b3d09a20541ee875b39f517277283>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Queues_recentSystemJobs_RefetchQuery$variables = Record<PropertyKey, never>;
export type Queues_recentSystemJobs_RefetchQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"Queues_recentSystemJobs">;
};
export type Queues_recentSystemJobs_RefetchQuery = {
  response: Queues_recentSystemJobs_RefetchQuery$data;
  variables: Queues_recentSystemJobs_RefetchQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "nodeID",
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "id",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "status",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "enqueuedAt",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "startedAt",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "endedAt",
    "storageKey": null
  },
  (v0/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "Queues_recentSystemJobs_RefetchQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "Queues_recentSystemJobs"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "Queues_recentSystemJobs_RefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "SystemJobQueue",
        "kind": "LinkedField",
        "name": "systemJobQueues",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "active",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "SystemJob",
            "kind": "LinkedField",
            "name": "recentJobs",
            "plural": true,
            "selections": (v1/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "SystemJob",
            "kind": "LinkedField",
            "name": "failedJobs",
            "plural": true,
            "selections": (v1/*: any*/),
            "storageKey": null
          },
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ae584148eefa9d47b2ef318451b98f3f",
    "id": null,
    "metadata": {},
    "name": "Queues_recentSystemJobs_RefetchQuery",
    "operationKind": "query",
    "text": "query Queues_recentSystemJobs_RefetchQuery {\n  ...Queues_recentSystemJobs\n}\n\nfragment Queues_Queue on SystemJobQueue {\n  name\n  active\n  recentJobs {\n    id\n    status\n    enqueuedAt\n    startedAt\n    endedAt\n    nodeID\n  }\n  failedJobs {\n    id\n    status\n    enqueuedAt\n    startedAt\n    endedAt\n    nodeID\n  }\n}\n\nfragment Queues_recentSystemJobs on Query {\n  systemJobQueues {\n    name\n    ...Queues_Queue\n    nodeID\n  }\n}\n"
  }
};
})();

(node as any).hash = "fa097b3cf461683b64647a1cffbd28a6";

export default node;
