/**
 * @generated SignedSource<<e2323e393cc89c69b0c8e18ecd9b3c20>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UserSelect_allUsers$data = {
  readonly allUsers: {
    readonly nodes: ReadonlyArray<{
      readonly email: string | null | undefined;
      readonly firstName: string | null | undefined;
      readonly id: string | null | undefined;
      readonly language: string | null | undefined;
      readonly lastName: string | null | undefined;
    } | null | undefined>;
  } | null | undefined;
  readonly " $fragmentType": "UserSelect_allUsers";
};
export type UserSelect_allUsers$key = {
  readonly " $data"?: UserSelect_allUsers$data;
  readonly " $fragmentSpreads": FragmentRefs<"UserSelect_allUsers">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": "",
      "kind": "LocalArgument",
      "name": "searchText"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [],
      "operation": require('./UserSelect_allUsers_RefetchQuery.graphql')
    }
  },
  "name": "UserSelect_allUsers",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "searchText",
          "variableName": "searchText"
        }
      ],
      "concreteType": "QueryAllUsersConnection",
      "kind": "LinkedField",
      "name": "allUsers",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "User",
          "kind": "LinkedField",
          "name": "nodes",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "id",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "email",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "firstName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "lastName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "language",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "528fa370f3de4595cdc7da32295314a1";

export default node;
