import * as React from 'react';
import {
  Datagrid,
  DateField,
  List,
  TextField,
  BooleanField,
  ListProps,
  FunctionField,
} from 'react-admin';
import { FC } from 'react';
import { Link, FieldProps, Filter, TextInput } from 'react-admin';

import UnitLinkField from './UnitLinkField';
import PhonemeLinkField from './PhonemeLinkField';

import LessonListAside from './LessonListAside';
import { ReactElement } from 'react';
import TruncatedIdField from '../practice/TruncatedIdField';
import { Tooltip } from '@material-ui/core';

const LessonLinkField: FC<FieldProps> = (props) =>
  props.record ? (
    <Link to={`/content/lesson/${props.record.id}`}>{props.record.name}</Link>
  ) : null;

LessonLinkField.defaultProps = {
  source: 'name',
  addLabel: true,
};

const LessonFilter = (props: any) => (
  <Filter {...props}>
    <TextInput label="Tags" source="tags" defaultValue="" />
  </Filter>
);

const LessonList = (props: ListProps): ReactElement => (
  <List
    {...props}
    sort={{ field: 'name', order: 'ASC' }}
    perPage={25}
    aside={<LessonListAside />}
    filters={<LessonFilter />}
  >
    <Datagrid optimized>
      <TruncatedIdField
        sortable={false}
        label={
          <Tooltip placement="bottom" title="First 4 of UUID">
            <div>Id</div>
          </Tooltip>
        }
      />
      <LessonLinkField label="Name" />
      <UnitLinkField />
      <PhonemeLinkField />
      <TextField source="tags" label="Tags" />
      <FunctionField
        render={(record: any) => record && record.videos.length}
        label="Num. Videos"
      />
      <FunctionField
        render={(record: any) =>
          record &&
          record?.practice_sets
            .map((ps: any) => ps?.items?.length)
            .reduce((a: any, b: any) => a + b, 0)
        }
        label="Num. Practice"
      />
      <FunctionField
        render={(record: any) =>
          record && record?.multiple_choice_items?.length
        }
        label="Num. MC"
      />
      <BooleanField source="requiresSubscription" label="Requires Sub?" />
      <BooleanField source="isTest" label="Is Test?" />
      <DateField showTime source="goesPublicTime" label="Goes Public At" />

      <DateField showTime source="createdAt" label="Created" />
    </Datagrid>
  </List>
);

export default LessonList;
