import { Box, Card, CardContent } from '@material-ui/core';
import { Theme, makeStyles } from '@material-ui/core/styles';
import { Styles } from '@material-ui/styles/withStyles';
import React, { FC } from 'react';
import {
  DeleteButton,
  Edit,
  EditProps,
  FieldProps,
  FormWithRedirect,
  SaveButton,
  TextInput,
  Toolbar,
  required,
  useRecordContext,
} from 'react-admin';

import type { MouthDiagram } from '.';
import { useMouthDiagramImages } from '../mouthDiagramSet/utils';

const MouthDiagramTitle: FC<FieldProps> = ({ record }) => {
  // @ts-ignore
  const { urls } = useMouthDiagramImages(record ? [record] : undefined);
  return record ? (
    <Box display="flex" alignItems="center">
      <img
        alt="diagram"
        style={{
          height: 40,
          aspectRatio: '16/9',
          borderRadius: 14,
        }}
        src={urls?.[record?.imageUrl]}
      />
      <Box ml={1}>{record?.imageUrl.split('/').pop()}</Box>
    </Box>
  ) : null;
};

export const styles: Styles<Theme, any> = {
  name: { display: 'inline-block' },
  photo: { display: 'inline-block' },
  spacing: { marginRight: 10 },
  image: {
    margin: '0.5rem',
    maxHeight: '10rem',
  },
  button: {
    marginRight: 10,
  },
};

const useStyles = makeStyles(styles);

const MouthDiagramForm = (props: any) => {
  const classes = useStyles(props);
  const record: MouthDiagram = useRecordContext();
  const { urls } = useMouthDiagramImages([record]);
  const { urls: squareUrls } = useMouthDiagramImages([record], true);

  return (
    <FormWithRedirect
      {...props}
      render={(formProps: any) => (
        <Card>
          <form>
            <CardContent>
              <Box>
                <TextInput
                  fullWidth
                  autoFocus
                  source="imageUrl"
                  label="BoldVoice Image Url (Relative Url)"
                  className={classes.name}
                  validate={requiredValidate}
                />
                <img
                  alt="diagram"
                  style={{
                    width: 160,
                    height: 90,
                    borderRadius: 14,
                    marginBottom: 10,
                  }}
                  src={urls?.[record?.imageUrl]}
                />
                <TextInput
                  fullWidth
                  autoFocus
                  source="squareImageUrl"
                  label="Square BoldVoice Image Url (Relative Url)"
                  className={classes.name}
                />
                {record?.squareImageUrl ? (
                  <img
                    alt="square diagram"
                    style={{
                      width: 160,
                      aspectRatio: '1.36/1',
                      borderRadius: 14,
                      marginBottom: 10,
                    }}
                    src={squareUrls?.[record?.squareImageUrl]}
                  />
                ) : null}

                <TextInput
                  multiline
                  source="adminNotes"
                  label="Admin Notes"
                  fullWidth
                  helperText={false}
                />
              </Box>

              <Toolbar
                record={formProps.record}
                basePath={formProps.basePath}
                invalid={formProps.invalid}
                handleSubmit={formProps.handleSubmit}
                saving={formProps.saving}
                resource="content/mouthDiagram"
                style={{ display: 'flex', justifyContent: 'space-between' }}
              >
                <div>
                  <SaveButton
                    label="Save & Continue"
                    handleSubmitWithRedirect={
                      formProps.handleSubmitWithRedirect ||
                      formProps.handleSubmit
                    }
                    disabled={formProps.disabled}
                    invalid={formProps.invalid}
                    redirect="edit"
                    saving={formProps.saving}
                    submitOnEnter={formProps.submitOnEnter}
                    className={classes.button}
                  />

                  <SaveButton
                    label="Save & Exit"
                    handleSubmitWithRedirect={
                      formProps.handleSubmitWithRedirect ||
                      formProps.handleSubmit
                    }
                    disabled={formProps.disabled}
                    invalid={formProps.invalid}
                    redirect={formProps.redirect}
                    saving={formProps.saving}
                    submitOnEnter={formProps.submitOnEnter}
                    className={classes.button}
                  />
                </div>

                {formProps.record &&
                  typeof formProps.record.id !== 'undefined' && (
                    <DeleteButton
                      basePath={formProps.basePath}
                      record={formProps.record}
                      resource={formProps.resource}
                      undoable={formProps.undoable}
                      mutationMode={formProps.mutationMode}
                    />
                  )}
              </Toolbar>
            </CardContent>
          </form>
        </Card>
      )}
    />
  );
};

const MouthDiagramEdit: FC<EditProps> = (props) => (
  <Edit mutationMode="optimistic" title={<MouthDiagramTitle />} {...props}>
    <MouthDiagramForm />
  </Edit>
);

const requiredValidate = [required()];
export default MouthDiagramEdit;
