import React from 'react';
import ImageEditForm from './ImageEditForm';
import TableEditForm from './TableEditForm';
import TextWithHighlightsEditForm from './TextWithHighlightsEditForm';
import MouthDiagramSetEditForm from './MouthDiagramSetEditForm';

export default function InteractableEditFormFactory({
  interactable,
}: {
  interactable: any;
}) {
  if (!interactable) {
    return null;
  }
  const { data, start, end } = interactable;

  const commonProps = {
    ...data,
    from: Math.round(start),
    to: Math.round(end),
  };

  switch (interactable.data.graphicType) {
    case 'VideoImage':
      return <ImageEditForm record={commonProps} />;
    case 'VideoTableGraphics':
      return <TableEditForm record={commonProps} />;
    case 'VideoTextWithHighlights':
      return <TextWithHighlightsEditForm record={commonProps} />;
    case 'VideoMouthDiagramSet':
      return <MouthDiagramSetEditForm record={commonProps} />;

    default:
      return null;
  }
}
