import { Typography } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';
import { Styles } from '@material-ui/styles/withStyles';
import { FC } from 'react';
import { Create, SimpleForm } from 'react-admin';

import MultipleChoiceQuestionFormFields from './MultipleChoiceQuestionFormFields';

export const styles: Styles<Theme, any> = {
  name: { display: 'inline-block' },
};

const MultipleChoiceQuestionCreate: FC = (props: any) => (
  <Create {...props}>
    <SimpleForm>
      <Typography variant="h6" gutterBottom>
        Multiple Choice Question
      </Typography>

      <MultipleChoiceQuestionFormFields />
    </SimpleForm>
  </Create>
);

export default MultipleChoiceQuestionCreate;
