import { Paper, ThemeOptions } from '@material-ui/core';

import '../satoshi.css';

export const darkTheme: ThemeOptions = {
  palette: {
    primary: {
      main: '#90caf9',
    },
    secondary: {
      main: '#FBBA72',
    },
    type: 'dark' as const, // Switching the dark mode on is a single property value change.
  },
  overrides: {
    MuiAppBar: {
      colorSecondary: {
        color: '#ffffffb3',
        backgroundColor: '#616161e6',
      },
    },
    MuiButtonBase: {
      root: {
        '&:hover:active::after': {
          // recreate a static ripple color
          // use the currentColor to make it work both for outlined and contained buttons
          // but to dim the background without dimming the text,
          // put another element on top with a limited opacity
          content: '""',
          display: 'block',
          width: '100%',
          height: '100%',
          position: 'absolute',
          top: 0,
          right: 0,
          backgroundColor: 'currentColor',
          opacity: 0.3,
          borderRadius: 'inherit',
        },
      },
    },
  },
  props: {
    MuiButtonBase: {
      // disable ripple for perf reasons
      disableRipple: true,
    },
  },
};

export const lightTheme: ThemeOptions = {
  typography: {
    fontFamily: ['Satoshi', 'Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(
      ',',
    ),
  },
  palette: {
    primary: {
      main: '#4f3cc9',
    },
    secondary: {
      light: '#5f5fc4',
      main: '#283593',
      dark: '#001064',
      contrastText: '#fff',
    },
    background: {
      default: '#fcfcfe',
    },
    type: 'light' as const,
  },
  overrides: {
    // @ts-ignore
    RaMenuItemLink: {
      root: {
        borderLeft: '3px solid #fff',
      },
      active: {
        borderLeft: '3px solid #4f3cc9',
      },
    },
    MuiButton: {
      contained: {
        backgroundColor: '#fff',
        color: '#4f3cc9',
        boxShadow: 'none',
      },
    },
    MuiButtonBase: {
      root: {
        '&:not(.MuiAccordionSummary-root):hover:active::after': {
          // recreate a static ripple color
          // use the currentColor to make it work both for outlined and contained buttons
          // but to dim the background without dimming the text,
          // put another element on top with a limited opacity
          content: '""',
          display: 'block',
          width: '100%',
          height: '100%',
          position: 'absolute',
          top: 0,
          right: 0,
          backgroundColor: 'currentColor',
          opacity: 0.3,
          borderRadius: 'inherit',
        },
      },
    },
    MuiAccordion: {
      root: {
        '&:before': {
          backgroundColor: 'white',
        },
      },
    },
    MuiAppBar: {
      colorSecondary: {
        color: '#808080',
        backgroundColor: '#fff',
      },
    },
    // MuiLinearProgress: {
    //   colorPrimary: {
    //     backgroundColor: '#f5f5f5',
    //   },
    //   barColorPrimary: {
    //     backgroundColor: '#d7d7d7',
    //   },
    // },
    MuiFilledInput: {
      root: {
        backgroundColor: 'rgba(0, 0, 0, 0.04)',
        '&$disabled': {
          backgroundColor: 'rgba(0, 0, 0, 0.04)',
        },
      },
    },
    MuiSnackbarContent: {
      root: {
        border: 'none',
      },
    },
    MuiCollapse: {
      // @ts-ignore
      root: {
        '&.MuiCollapse-overflowY': {
          overflowX: 'visible',
          overflowY: 'clip',
        },
      },
      entered: {
        '&.MuiCollapse-overflowY': {
          overflow: 'visible',
        },
      },
    },

    MuiPaper: {
      root: {
        '&.MuiPaper-elevation1.MuiPaper-hover:hover': {
          // give elevation 3
          boxShadow:
            '0px 3px 3px -2px rgba(0,0,0,0.2),0px 3px 4px 0px rgba(0,0,0,0.14),0px 1px 8px 0px rgba(0,0,0,0.12)',
        },
        '&.MuiPaper-outlined.MuiPaper-hover:hover': {
          backgroundColor: 'rgba(0, 0, 0, 0.01)',
          transition: 'background-color 0.25s',
        },
      },
    },
  },
  props: {
    MuiButtonBase: {
      // disable ripple for perf reasons
      disableRipple: true,
    },
    MuiPaper: {
      elevation: 0,
    },
    MuiCard: {
      elevation: 0,
      variant: 'outlined',
    },
    // @ts-ignore
    MuiAutocomplete: {
      PaperComponent: ({ children, ...props }: any) => (
        <Paper elevation={1} {...props}>
          {children}
        </Paper>
      ),
    },
  },
};
