import React from 'react';
import {
  AutocompleteArrayInput,
  ReferenceArrayInput,
  SimpleForm,
} from 'react-admin';
import api from '../../api';
import { matchSuggestion } from '../../lesson/utils';

export default function PracticeSetPracticeAutoComplete({
  practiceSet,
  reloadPracticeSet,
}: any) {
  const record = practiceSet.items
    .filter((i: any) => i.contentType === 'Practice')
    .map((i: any) => i.contentId);

  const handleSubmit = async ({ practiceId }: any) => {
    try {
      const result = await api.patchPracticeSet({
        id: practiceSet.id,
        practiceId,
      });

      if (result.status === 200) {
        reloadPracticeSet();
        // window.location.reload();
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div style={{ paddingRight: 10, flex: 1 }}>
      <SimpleForm
        record={{
          practiceId: record,
        }}
        resource="content/practice_set"
        save={handleSubmit}
        saving={false}
      >
        <ReferenceArrayInput
          filterToQuery={(searchText: any) => ({
            word: searchText,
          })}
          label={`Practice Items for ${practiceSet.name}`}
          source="practiceId"
          reference="content/practice"
          fullWidth
          filter={{
            isUserGeneratedContent: false,
          }}
          helperText={false}
        >
          <AutocompleteArrayInput
            debounce={750}
            optionValue="id"
            optionText={(option: any) =>
              `${option?.word} ${
                option?.coachSample ? ` ✅` : ` ❌`
              } (id: ${option?.id?.slice(0, 4)})`
            }
            matchSuggestion={(filterValue: string, choice: any) =>
              matchSuggestion(filterValue, choice, 'word')
            }
            inputProps={{ style: { paddingLeft: 0 } }}
          />
        </ReferenceArrayInput>
      </SimpleForm>
    </div>
  );
}
