import * as React from 'react';
import { FC } from 'react';
import {
  Create,
  CreateProps,
  SimpleForm,
  TextInput,
  required,
} from 'react-admin';

import { makeStyles, Theme } from '@material-ui/core/styles';
import { Styles } from '@material-ui/styles/withStyles';

export const styles: Styles<Theme, any> = {
  name: { display: 'inline-block' },
  number: { display: 'inline-block', marginLeft: 10 },
};

const useStyles = makeStyles(styles);

const ConversationCreate: FC<CreateProps> = (props) => {
  const classes = useStyles(props);

  return (
    <Create {...props}>
      <SimpleForm initialValues={{}}>
        <TextInput
          fullWidth
          autoFocus
          source="name"
          label="Name"
          className={classes.name}
          validate={requiredValidate}
        />
        <TextInput
          fullWidth
          source="description"
          label="Description"
          className={classes.name}
          validate={requiredValidate}
        />
        <TextInput
          fullWidth
          source="thumbnail_url"
          label="Thumbnail Url"
          className={classes.name}
          validate={requiredValidate}
        />

        {/* coachid */}
      </SimpleForm>
    </Create>
  );
};

const requiredValidate = [required()];

export default ConversationCreate;
