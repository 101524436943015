import { Typography } from '@material-ui/core';
import { Theme, makeStyles } from '@material-ui/core/styles';
import { Styles } from '@material-ui/styles/withStyles';
import { FC } from 'react';
import { Create, CreateProps, SimpleForm, required } from 'react-admin';

import VideoGroupFormFields from './VideoGroupFormFields';

export const styles: Styles<Theme, any> = {
  name: { display: 'inline-block' },
};

const useStyles = makeStyles(styles);

const VideoGroupCreate: FC<CreateProps> = (props: any) => {
  useStyles(props);

  return (
    <Create {...props}>
      <SimpleForm>
        <Typography variant="h6" gutterBottom>
          Video Group
        </Typography>

        <VideoGroupFormFields />
      </SimpleForm>
    </Create>
  );
};

required();

export default VideoGroupCreate;
