import * as MUI from '@material-ui/core';
import React from 'react';

export const LoadingButton = ({ isLoading, ...props }: any) => (
  <MUI.Button
    {...props}
    disabled={isLoading || props.disabled}
    startIcon={isLoading ? <MUI.CircularProgress size={16} /> : null}
  />
);

export const DeleteButton = ({ onDelete, content, ...props }: any) => {
  const theme = MUI.useTheme();
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <LoadingButton
        variant="outlined"
        size="small"
        isLoading={false}
        style={{
          ...(!props.disabled && {
            color: theme.palette.error.main,
            borderColor: theme.palette.error.main,
          }),
        }}
        {...props}
        onClick={() => setOpen(true)}
      >
        Delete
      </LoadingButton>
      <MUI.Dialog open={open} maxWidth="sm">
        <MUI.DialogContent>
          <MUI.DialogContentText>
            {content || 'Are you sure you want to delete this?'}
          </MUI.DialogContentText>
        </MUI.DialogContent>
        <MUI.DialogActions>
          <MUI.Button onClick={() => setOpen(false)}>Cancel</MUI.Button>
          <MUI.Button
            onClick={() => {
              setOpen(false);
              onDelete();
            }}
            color="primary"
            style={{ color: theme.palette.error.main }}
          >
            Delete
          </MUI.Button>
        </MUI.DialogActions>
      </MUI.Dialog>
    </>
  );
};
