import * as React from 'react';
import { FC } from 'react';
import {
  Create,
  CreateProps,
  SimpleForm,
  TextInput,
  required,
} from 'react-admin';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Styles } from '@material-ui/styles/withStyles';

export const styles: Styles<Theme, any> = {
  name: { display: 'inline-block' },
  number: { display: 'block' },
};

const useStyles = makeStyles(styles);

const LessonCreate: FC<CreateProps> = (props) => {
  const classes = useStyles(props);

  return (
    <Create {...props}>
      <SimpleForm autoComplete="off">
        <TextInput
          autoFocus
          source="name"
          label="Lesson Name"
          className={classes.name}
          validate={requiredValidate}
          fullWidth
        />
        <TextInput
          source="tags"
          label="Tags"
          className={classes.name}
          fullWidth
        />
      </SimpleForm>
    </Create>
  );
};

const requiredValidate = [required()];

export default LessonCreate;
