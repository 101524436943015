import * as React from 'react';
import {
  Datagrid,
  TextField,
  List,
  ListProps,
  FunctionField,
} from 'react-admin';
import { FC } from 'react';
import {
  Link,
  FieldProps,
  Filter,
  DateField,
  TextInput,
  NumberInput,
  SelectInput,
} from 'react-admin';
import { TESTING_VARIANTS } from './UnitEdit';
import CurriculumLinkField from './CurriculumLinkField';

import UnitListAside from './UnitListAside';
import { ReactElement } from 'react';

const UnitLinkField: FC<FieldProps> = (props) =>
  props.record ? (
    <Link to={`/content/unit/${props.record.id}`}>{props.record.name}</Link>
  ) : null;

UnitLinkField.defaultProps = {
  source: 'name',
  addLabel: true,
};

const UnitFilter = (props: any) => (
  <Filter {...props}>
    <TextInput label="Unit Name" source="name" defaultValue="" />
    <NumberInput label="Unit Number" source="unitNumber" defaultValue="" />
    <TextInput label="Experiment Name" source="testName" defaultValue="" />
    <SelectInput
      source="testingVariant"
      label="Testing Variant"
      choices={TESTING_VARIANTS}
    />
  </Filter>
);

const UnitList = (props: ListProps): ReactElement => (
  <List
    {...props}
    sort={{ field: 'name', order: 'ASC' }}
    perPage={25}
    aside={<UnitListAside />}
    filters={<UnitFilter />}
  >
    <Datagrid optimized>
      <UnitLinkField label="Name" />
      <CurriculumLinkField />
      <TextField source="unitNumber" label="Unit Number" />
      <TextField source="testName" label="Experiment Name" />
      <FunctionField
        render={(record: any) =>
          record?.testingVariant
            ? TESTING_VARIANTS?.find((i) => i.id === record.testingVariant)
                ?.name
            : null
        }
        label="Testing Variant"
      />
      <FunctionField
        render={(record: any) => record && record.lessons.length}
        label="Num. Lessons"
      />
      <DateField showTime source="createdAt" label="Created" />
    </Datagrid>
  </List>
);

export default UnitList;
