import { Typography } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';
import { Styles } from '@material-ui/styles/withStyles';
import { FC } from 'react';
import { Create, CreateProps, SimpleForm } from 'react-admin';

import SkillFormFields from './SkillFormFields';

export const styles: Styles<Theme, any> = {
  name: { display: 'inline-block' },
};

const SkillCreate: FC<CreateProps> = (props: any) => (
    <Create {...props}>
      <SimpleForm>
        <Typography variant="h6" gutterBottom>
          Skill
        </Typography>

        <SkillFormFields />
      </SimpleForm>
    </Create>
  );

export default SkillCreate;
