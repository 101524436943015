import * as MUI from '@material-ui/core';
import * as MUIIcons from '@material-ui/icons';
import Autocomplete from '@material-ui/lab/Autocomplete';
import graphql from 'babel-plugin-relay/macro';
import _ from 'lodash';
import React from 'react';
import { useFragment, useLazyLoadQuery, useMutation } from 'react-relay';

import IPAText from '../components/IPAText';

const WordEdit = (props: any) => {
  const data: any = useLazyLoadQuery(
    graphql`
      query WordEdit_Query($id: ID) {
        raWord(id: $id) {
          ...WordEdit_word
        }

        ...WordEdit_SourceEdge_query
      }
    `,
    { id: props.id },
  );

  const word = useFragment(
    graphql`
      fragment WordEdit_word on Word {
        text

        pronunciations {
          id
          active
          default
          ...WordEdit_Pronunciation
        }
      }
    `,
    data.raWord,
  );

  const [pronunciationOrder, setPronunciationOrder] = React.useState<string[]>(
    [],
  );

  const pronunciations = React.useMemo(() => {
    const ordered = _.orderBy(
      word.pronunciations,
      [(p: any) => pronunciationOrder.indexOf(p.id), 'default', 'active'],
      ['asc', 'desc', 'desc'],
    );

    setPronunciationOrder(ordered.map((p) => p.id));

    return ordered;
  }, [word.pronunciations]);

  const [newPronunciation, setNewPronunciation] = React.useState('');

  const [commitAddPronunciation] = useMutation(graphql`
    mutation WordEdit_addPronunciation_Mutation($word: String!, $ipa: String!) {
      addIPAToSource(word: $word, ipa: $ipa, sourceName: "boldvoice") {
        word {
          ...WordEdit_word
        }
      }
    }
  `);

  const onAddIPAToBoldVoice = () => {
    const ipa = `[${newPronunciation}]`.replace('[[', '[').replace(']]', ']');
    commitAddPronunciation({
      variables: { word: word.text, ipa },
    });
  };

  return (
    <MUI.Box display="flex" flexDirection="column" gridRowGap={20}>
      <MUI.Box marginTop={2}>
        <MUI.Typography variant="h4">{word.text}</MUI.Typography>
      </MUI.Box>

      <MUI.Divider />

      <MUI.Box>
        <MUI.Typography variant="h6" gutterBottom>
          Pronunciations
        </MUI.Typography>

        <MUI.Box
          display="flex"
          flexDirection="row"
          gridColumnGap={10}
          marginTop={2.5}
          marginBottom={2}
        >
          <MUI.TextField
            label="New Pronunciation"
            variant="outlined"
            size="small"
            style={{ minWidth: 500 }}
            placeholder="p(1)ɹ(2)oʊ(3).ˌn(4)ʌ(5)n(6).s(7)i(8).ˈeɪ(9).ʃ(10-11)ə(12)n(13)"
            helperText="Phonetic IPA with syllable breaks and extents."
            onChange={(e) => setNewPronunciation(e.target.value)}
            InputProps={{
              startAdornment: (
                <MUI.InputAdornment position="start" style={{ marginRight: 3 }}>
                  [
                </MUI.InputAdornment>
              ),
              endAdornment: (
                <MUI.InputAdornment position="end" style={{ marginLeft: 3 }}>
                  ]
                </MUI.InputAdornment>
              ),
            }}
          />
          <MUI.Button
            variant="outlined"
            color="primary"
            size="small"
            style={{ height: 40 }}
            onClick={onAddIPAToBoldVoice}
          >
            Add to boldvoice
          </MUI.Button>
        </MUI.Box>

        <MUI.Box display="flex" flexDirection="column" gridRowGap={10}>
          {pronunciations.map((pronunciation: any) => (
            <Pronunciation
              key={pronunciation.id}
              pronunciation={pronunciation}
              queryRef={data}
            />
          ))}
        </MUI.Box>
      </MUI.Box>
    </MUI.Box>
  );
};

const Pronunciation = (props: any) => {
  const pronunciationRef = props.pronunciation;

  const pronunciation = useFragment(
    graphql`
      fragment WordEdit_Pronunciation on Pronunciation {
        id
        bvIPAUnsyllabified
        bvIPA
        active
        default
        form
        sourceEdges {
          id
          pronunciationSource {
            name
          }
          ...WordEdit_SourceEdge
        }
      }
    `,
    pronunciationRef,
  );

  const [commitAddToBoldVoice] = useMutation(graphql`
    mutation WordEdit_addToBoldVoice_Mutation($pronunciationID: ID!) {
      addPronunciationToSource(
        pronunciationID: $pronunciationID
        sourceName: "boldvoice"
      ) {
        word {
          ...WordEdit_word
        }
      }
    }
  `);

  const [commitUpdateForm] = useMutation(graphql`
    mutation WordEdit_updateForm_Mutation(
      $pronunciationID: ID!
      $form: String
    ) {
      updatePronunciationForm(pronunciationID: $pronunciationID, form: $form) {
        id
        form
      }
    }
  `);

  return (
    <MUI.Paper variant="outlined">
      <MUI.Box padding={1}>
        <MUI.Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          marginBottom={1.5}
        >
          <MUI.Box marginRight={2}>
            {pronunciation?.default ? (
              <MUI.Tooltip title="Default" placement="right">
                <MUIIcons.Stars
                  htmlColor="#00c853"
                  fontSize="small"
                  style={{ verticalAlign: 'text-bottom' }}
                />
              </MUI.Tooltip>
            ) : pronunciation?.active ? (
              <MUI.Tooltip title="Active" placement="right">
                <MUIIcons.CheckCircle
                  htmlColor="#00c853"
                  fontSize="small"
                  style={{ verticalAlign: 'text-bottom' }}
                />
              </MUI.Tooltip>
            ) : (
              <MUI.Tooltip title="Inactive" placement="right">
                <MUIIcons.Cancel
                  color="disabled"
                  fontSize="small"
                  style={{ verticalAlign: 'text-bottom' }}
                />
              </MUI.Tooltip>
            )}
          </MUI.Box>

          <MUI.Typography variant="body1">
            <IPAText
              text={pronunciation.bvIPA ?? pronunciation.bvIPAUnsyllabified}
            />
          </MUI.Typography>
        </MUI.Box>

        <MUI.Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          marginTop={1}
          marginBottom={1}
        >
          <MUI.Box marginRight={1} style={{ minWidth: 51.875 }}>
            <MUI.Typography variant="subtitle2">Form</MUI.Typography>
          </MUI.Box>

          <MUI.Divider orientation="vertical" flexItem />

          <MUI.RadioGroup
            style={{ marginLeft: 10 }}
            row
            name="form"
            value={pronunciation?.form ?? 'NONE'}
            onChange={(e) => {
              commitUpdateForm({
                variables: {
                  pronunciationID: pronunciation.id,
                  form: e.target.value === 'NONE' ? null : e.target.value,
                },
              });
            }}
          >
            <MUI.FormControlLabel
              disabled={!pronunciation?.active}
              value="NONE"
              control={<MUI.Radio size="small" />}
              label="None"
            />
            <MUI.FormControlLabel
              disabled={!pronunciation?.active}
              value="WEAK"
              control={<MUI.Radio size="small" />}
              label="Weak"
            />
            <MUI.FormControlLabel
              disabled={!pronunciation?.active}
              value="STRONG"
              control={<MUI.Radio size="small" />}
              label="Strong"
            />
          </MUI.RadioGroup>
        </MUI.Box>

        <MUI.Box display="flex" flexDirection="row">
          <MUI.Box marginRight={1}>
            <MUI.Typography variant="subtitle2">Sources</MUI.Typography>
          </MUI.Box>

          <MUI.Divider orientation="vertical" flexItem />

          <MUI.Box
            display="flex"
            flexDirection="column"
            gridRowGap={5}
            marginLeft={1}
          >
            {pronunciation.sourceEdges[0].pronunciationSource.name !==
              'boldvoice' &&
              pronunciation.bvIPA && (
                <>
                  <MUI.Box
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                  >
                    <MUI.Box width={180}>
                      <MUI.Typography variant="body1" style={{ opacity: 0.5 }}>
                        boldvoice
                      </MUI.Typography>
                    </MUI.Box>
                    <MUI.Button
                      size="small"
                      variant="text"
                      color="secondary"
                      style={{ paddingTop: 2, paddingBottom: 2 }}
                      onClick={() =>
                        commitAddToBoldVoice({
                          variables: { pronunciationID: pronunciation.id },
                        })
                      }
                    >
                      Add to boldvoice
                    </MUI.Button>
                  </MUI.Box>
                  <MUI.Divider />
                </>
              )}
            {pronunciation.sourceEdges.map((sourceEdge: any, i) => (
              <React.Fragment key={sourceEdge.id}>
                {i !== 0 && <MUI.Divider />}
                <SourceEdge sourceEdge={sourceEdge} queryRef={props.queryRef} />
              </React.Fragment>
            ))}
          </MUI.Box>
        </MUI.Box>
      </MUI.Box>
    </MUI.Paper>
  );
};

const SourceEdge = (props: any) => {
  const sourceEdgeRef = props.sourceEdge;

  const sourceEdge = useFragment(
    graphql`
      fragment WordEdit_SourceEdge on PronunciationPronunciationSource {
        id
        original
        sourceDefault
        removed
        autoRemoved
        partsOfSpeech {
          tag
          description
        }

        pronunciation {
          id
        }

        pronunciationSource {
          id
          name
        }
      }
    `,
    sourceEdgeRef,
  );

  const queryData = useFragment(
    graphql`
      fragment WordEdit_SourceEdge_query on Query {
        partsOfSpeech {
          tag
          description
        }
      }
    `,
    props.queryRef,
  );

  const allPartsOfSpeech = queryData.partsOfSpeech;

  const allPartsOfSpeechByTag = React.useMemo(
    () => _.keyBy(allPartsOfSpeech, 'tag'),
    [allPartsOfSpeech],
  );

  const [commitUpdateSouceEdge] = useMutation(graphql`
    mutation WordEdit_updateSourceEdge_Mutation(
      $pronunciationID: ID!
      $pronunciationSourceID: ID!
      $removed: Boolean
      $sourceDefault: Boolean
      $partsOfSpeech: [String!]
    ) {
      updatePronunciationSourceEdge(
        pronunciationID: $pronunciationID
        pronunciationSourceID: $pronunciationSourceID
        removed: $removed
        sourceDefault: $sourceDefault
        partsOfSpeech: $partsOfSpeech
      ) {
        pronunciation {
          word {
            ...WordEdit_word
          }
        }
      }
    }
  `);

  return (
    <MUI.Box style={{ opacity: sourceEdge?.autoRemoved ? 0.4 : 1 }}>
      <MUI.Box display="flex" flexDirection="row" alignItems="center">
        <MUI.Box width={180}>
          <MUI.Typography
            variant="body1"
            style={{
              textDecoration: sourceEdge?.autoRemoved ? 'line-through' : 'none',
            }}
          >
            {sourceEdge?.pronunciationSource?.name}
          </MUI.Typography>
        </MUI.Box>

        <MUI.FormControlLabel
          control={
            <MUI.Switch
              checked={sourceEdge?.sourceDefault}
              color="primary"
              size="small"
              onChange={() =>
                commitUpdateSouceEdge({
                  variables: {
                    pronunciationID: sourceEdge?.pronunciation?.id,
                    pronunciationSourceID: sourceEdge?.pronunciationSource?.id,
                    sourceDefault: !sourceEdge?.sourceDefault,
                  },
                })
              }
            />
          }
          label="Source Default"
          style={{ marginRight: 30 }}
          disabled={
            sourceEdge?.pronunciationSource?.name !== 'boldvoice' ||
            sourceEdge?.autoRemoved
          }
        />

        <MUI.FormControlLabel
          control={
            <MUI.Switch
              checked={sourceEdge?.removed}
              onChange={() =>
                commitUpdateSouceEdge({
                  variables: {
                    pronunciationID: sourceEdge?.pronunciation?.id,
                    pronunciationSourceID: sourceEdge?.pronunciationSource?.id,
                    removed: !sourceEdge?.removed,
                  },
                })
              }
              color="primary"
              size="small"
              disabled={sourceEdge?.autoRemoved}
            />
          }
          label="Removed"
        />

        <Autocomplete
          multiple
          disableCloseOnSelect
          openOnFocus
          disabled={
            sourceEdge?.pronunciationSource?.name !== 'boldvoice' ||
            sourceEdge?.autoRemoved
          }
          options={allPartsOfSpeech.map((p) => p.tag)}
          value={sourceEdge?.partsOfSpeech.map((p) => p.tag)}
          getOptionLabel={(option: any) => {
            const partOfSpeech = allPartsOfSpeechByTag[option];
            return `${partOfSpeech.tag}: ${partOfSpeech?.description}`;
          }}
          onChange={(e, newValue) => {
            commitUpdateSouceEdge({
              variables: {
                pronunciationID: sourceEdge?.pronunciation?.id,
                pronunciationSourceID: sourceEdge?.pronunciationSource?.id,
                partsOfSpeech: newValue,
              },
            });
          }}
          renderInput={(params) => (
            <MUI.TextField
              {...params}
              variant="outlined"
              size="small"
              label="Parts of Speech"
              placeholder="Select part of speech"
              style={{ width: 600 }}
            />
          )}
        />
      </MUI.Box>

      <MUI.Box marginBottom={0.5}>
        <pre style={{ marginTop: 0, marginBottom: 0 }}>
          {sourceEdge.original}
        </pre>
      </MUI.Box>
    </MUI.Box>
  );
};

export default WordEdit;
