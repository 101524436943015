import * as React from 'react';
import { FC, memo } from 'react';

import { FieldProps } from 'react-admin';

interface Props extends FieldProps {
  video?: any;
}

const Line = () => (
  <div style={{ width: '100%', borderBottom: '1px solid #4f3cc9' }} />
);

const VideoNameField: FC<Props> = ({ record, video }) => {
  const name = record?.videos?.find((videoItem: any) => videoItem.id === video)
    ?.name;
  return record && name ? (
    <>
      <div>{name}</div>
      <Line />
    </>
  ) : null;
};

VideoNameField.defaultProps = {
  source: 'name',
  label: 'Name',
};

export default memo<Props>(VideoNameField);
