/**
 * @generated SignedSource<<73e1707abb73a5ef654a5e1091ceb726>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MultipleChoiceQuestionsSelect_multipleChoiceQuestionsByIDs_RefetchQuery$variables = {
  ids?: ReadonlyArray<string> | null | undefined;
};
export type MultipleChoiceQuestionsSelect_multipleChoiceQuestionsByIDs_RefetchQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"MultipleChoiceQuestionsSelect_multipleChoiceQuestionsByIDs">;
};
export type MultipleChoiceQuestionsSelect_multipleChoiceQuestionsByIDs_RefetchQuery = {
  response: MultipleChoiceQuestionsSelect_multipleChoiceQuestionsByIDs_RefetchQuery$data;
  variables: MultipleChoiceQuestionsSelect_multipleChoiceQuestionsByIDs_RefetchQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": ([]/*: any*/),
    "kind": "LocalArgument",
    "name": "ids"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "ids",
    "variableName": "ids"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "MultipleChoiceQuestionsSelect_multipleChoiceQuestionsByIDs_RefetchQuery",
    "selections": [
      {
        "args": (v1/*: any*/),
        "kind": "FragmentSpread",
        "name": "MultipleChoiceQuestionsSelect_multipleChoiceQuestionsByIDs"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "MultipleChoiceQuestionsSelect_multipleChoiceQuestionsByIDs_RefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "MultipleChoiceQuestion",
        "kind": "LinkedField",
        "name": "multipleChoiceQuestionsByIDs",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "internalName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "nodeID",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "71fa57912a58435fe2dc12729baad15f",
    "id": null,
    "metadata": {},
    "name": "MultipleChoiceQuestionsSelect_multipleChoiceQuestionsByIDs_RefetchQuery",
    "operationKind": "query",
    "text": "query MultipleChoiceQuestionsSelect_multipleChoiceQuestionsByIDs_RefetchQuery(\n  $ids: [ID!] = []\n) {\n  ...MultipleChoiceQuestionsSelect_multipleChoiceQuestionsByIDs_3pY7A9\n}\n\nfragment MultipleChoiceQuestionsSelect_multipleChoiceQuestionsByIDs_3pY7A9 on Query {\n  multipleChoiceQuestionsByIDs(ids: $ids) {\n    id\n    internalName\n    nodeID\n  }\n}\n"
  }
};
})();

(node as any).hash = "64a10fca92faad4a4282e8800f3d91dc";

export default node;
