import CasinoIcon from '@material-ui/icons/Casino';

import TopicConfigList from './TopicConfigList';
import TopicConfigEdit from './TopicConfigEdit';
import TopicConfigCreate from './TopicConfigCreate';

export default {
  list: TopicConfigList,
  edit: TopicConfigEdit,
  icon: CasinoIcon,
  create: TopicConfigCreate,
};
