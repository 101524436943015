/**
 * @generated SignedSource<<a6596700bf77a9d04fe75fb64ad6c634>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SkillLessonsEditor_Reorder_Mutation$variables = {
  newOrder: ReadonlyArray<string>;
};
export type SkillLessonsEditor_Reorder_Mutation$data = {
  readonly reorderSkillLessons: {
    readonly " $fragmentSpreads": FragmentRefs<"SkillLessonsEditor_skill">;
  } | null | undefined;
};
export type SkillLessonsEditor_Reorder_Mutation = {
  response: SkillLessonsEditor_Reorder_Mutation$data;
  variables: SkillLessonsEditor_Reorder_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "newOrder"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "newOrder",
    "variableName": "newOrder"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "active",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "numberPracticeSets",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "nodeID",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v9 = {
  "alias": "practiceTexts",
  "args": null,
  "concreteType": "PracticeText",
  "kind": "LinkedField",
  "name": "allPracticeTexts",
  "plural": true,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "text",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "coachSample",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "aiCoachSample",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isSentence",
      "storageKey": null
    },
    (v7/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SkillLessonsEditor_Reorder_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Skill",
        "kind": "LinkedField",
        "name": "reorderSkillLessons",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "SkillLessonsEditor_skill"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SkillLessonsEditor_Reorder_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Skill",
        "kind": "LinkedField",
        "name": "reorderSkillLessons",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "SkillLesson",
            "kind": "LinkedField",
            "name": "skillLessons",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "order",
                "storageKey": null
              },
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Conversation",
                "kind": "LinkedField",
                "name": "conversation",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Video",
                "kind": "LinkedField",
                "name": "video",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v8/*: any*/),
                  (v7/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "SkillLesson",
                "kind": "LinkedField",
                "name": "linkedSkillLesson",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Video",
                    "kind": "LinkedField",
                    "name": "video",
                    "plural": false,
                    "selections": [
                      (v8/*: any*/),
                      (v7/*: any*/),
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Conversation",
                    "kind": "LinkedField",
                    "name": "conversation",
                    "plural": false,
                    "selections": [
                      (v6/*: any*/),
                      (v7/*: any*/),
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v5/*: any*/),
                  (v9/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SkillLesson",
                    "kind": "LinkedField",
                    "name": "linkedSkillLesson",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Video",
                        "kind": "LinkedField",
                        "name": "video",
                        "plural": false,
                        "selections": [
                          (v8/*: any*/),
                          (v7/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Conversation",
                        "kind": "LinkedField",
                        "name": "conversation",
                        "plural": false,
                        "selections": [
                          (v6/*: any*/),
                          (v7/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v5/*: any*/),
                      (v9/*: any*/),
                      (v7/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v7/*: any*/)
                ],
                "storageKey": null
              },
              (v9/*: any*/),
              (v7/*: any*/)
            ],
            "storageKey": null
          },
          (v7/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "9224ec733611e3019d6b4e7bd94de7a9",
    "id": null,
    "metadata": {},
    "name": "SkillLessonsEditor_Reorder_Mutation",
    "operationKind": "mutation",
    "text": "mutation SkillLessonsEditor_Reorder_Mutation(\n  $newOrder: [ID!]!\n) {\n  reorderSkillLessons(newOrder: $newOrder) {\n    ...SkillLessonsEditor_skill\n    nodeID\n  }\n}\n\nfragment SkillLessonsEditor_skill on Skill {\n  id\n  skillLessons {\n    id\n    order\n    ...SkillLessonsEditor_skillLesson\n    nodeID\n  }\n}\n\nfragment SkillLessonsEditor_skillLesson on SkillLesson {\n  id\n  type\n  active\n  numberPracticeSets\n  conversation {\n    id\n    name\n    nodeID\n  }\n  video {\n    id\n    title\n    nodeID\n  }\n  linkedSkillLesson {\n    id\n    video {\n      title\n      nodeID\n    }\n    conversation {\n      name\n      nodeID\n    }\n    numberPracticeSets\n    practiceTexts: allPracticeTexts {\n      id\n      text\n      coachSample\n      aiCoachSample\n      isSentence\n      nodeID\n    }\n    ...SkillLessonsEditor_skillLesson_CAyYJ\n    nodeID\n  }\n  practiceTexts: allPracticeTexts {\n    id\n    text\n    coachSample\n    aiCoachSample\n    isSentence\n    nodeID\n  }\n}\n\nfragment SkillLessonsEditor_skillLesson_CAyYJ on SkillLesson {\n  id\n  type\n  active\n  numberPracticeSets\n  conversation {\n    id\n    name\n    nodeID\n  }\n  video {\n    id\n    title\n    nodeID\n  }\n  linkedSkillLesson {\n    id\n    video {\n      title\n      nodeID\n    }\n    conversation {\n      name\n      nodeID\n    }\n    numberPracticeSets\n    practiceTexts: allPracticeTexts {\n      id\n      text\n      coachSample\n      aiCoachSample\n      isSentence\n      nodeID\n    }\n    nodeID\n  }\n  practiceTexts: allPracticeTexts {\n    id\n    text\n    coachSample\n    aiCoachSample\n    isSentence\n    nodeID\n  }\n}\n"
  }
};
})();

(node as any).hash = "ad40eaf404457d2ae5a8cd4c19656dc1";

export default node;
