import * as React from 'react';
import {
  List,
  Datagrid,
  DateField,
  TextField,
  TextInput,
  Filter,
  FunctionField,
  BooleanField,
  BooleanInput,
  SelectInput,
} from 'react-admin';
import UserLinkField from './UserLinkField';
// import CancellationReportShow from './CancellationReportShow';

import { subStatusCustomRender, SUB_STATUS_LIST } from '../users/UserList';

const CancellationReportFilter = (props: any) => (
  <Filter {...props}>
    <TextInput
      label="User First Name"
      source="User.firstName"
      defaultValue=""
    />
    <TextInput label="User Last Name" source="User.lastName" defaultValue="" />
    <TextInput label="User ID" source="User.id" defaultValue="" />
    <SelectInput
      label="Sub Status"
      source="User.subStatus"
      choices={SUB_STATUS_LIST}
      defaultValue={null}
    />
    <TextInput label="Reason" source="reason" defaultValue="" />
    <TextInput label="Message" source="message" defaultValue="" />
    <BooleanInput
      label="Pressed Link to Cancel?"
      source="pressLinkToCancel"
      defaultValue={false}
    />
    <BooleanInput
      label="Saw Discount Offer?"
      source="sawDiscountOffer"
      defaultValue={false}
    />
  </Filter>
);

export const CancellationReportList = (props: any) => (
  <List
    {...props}
    perPage={10}
    sort={{ field: 'createdAt', order: 'DESC' }}
    filters={<CancellationReportFilter />}
  >
    <Datagrid rowClick="expand" /* expand={<CancellationReportShow />} */>
      <UserLinkField />
      <FunctionField
        label="Current Sub Status"
        render={(record: any) => subStatusCustomRender(record?.User)}
      />
      <TextField source="reason" label="Reason" />
      <TextField source="message" label="Message" />
      <BooleanField
        source="pressLinkToCancel"
        label="Pressed Link to Cancel?"
      />
      <BooleanField source="sawDiscountOffer" label="Saw Discount Offer?" />
      <DateField showTime source="createdAt" label="Date" />
    </Datagrid>
  </List>
);

export default CancellationReportList;
