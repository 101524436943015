/**
 * @generated SignedSource<<80a5c3e6d2734f48940ef60428f1fced>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UserSelect_NewUser_allUsers_Query$variables = {
  initiallySelectedID?: string | null | undefined;
};
export type UserSelect_NewUser_allUsers_Query$data = {
  readonly " $fragmentSpreads": FragmentRefs<"UserSelect_allUsers" | "UserSelect_userByID">;
};
export type UserSelect_NewUser_allUsers_Query = {
  response: UserSelect_NewUser_allUsers_Query$data;
  variables: UserSelect_NewUser_allUsers_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "initiallySelectedID"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "initiallySelectedID"
  }
],
v2 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "id",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "email",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "firstName",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "lastName",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "language",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "nodeID",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UserSelect_NewUser_allUsers_Query",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "UserSelect_allUsers"
      },
      {
        "args": (v1/*: any*/),
        "kind": "FragmentSpread",
        "name": "UserSelect_userByID"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UserSelect_NewUser_allUsers_Query",
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Literal",
            "name": "searchText",
            "value": ""
          }
        ],
        "concreteType": "QueryAllUsersConnection",
        "kind": "LinkedField",
        "name": "allUsers",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "nodes",
            "plural": true,
            "selections": (v2/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": "allUsers(searchText:\"\")"
      },
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "userByID",
        "plural": false,
        "selections": (v2/*: any*/),
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c7065b42d5861bd373ee5a97745a2780",
    "id": null,
    "metadata": {},
    "name": "UserSelect_NewUser_allUsers_Query",
    "operationKind": "query",
    "text": "query UserSelect_NewUser_allUsers_Query(\n  $initiallySelectedID: ID\n) {\n  ...UserSelect_allUsers\n  ...UserSelect_userByID_4At1dY\n}\n\nfragment UserSelect_allUsers on Query {\n  allUsers(searchText: \"\") {\n    nodes {\n      id\n      email\n      firstName\n      lastName\n      language\n      nodeID\n    }\n  }\n}\n\nfragment UserSelect_userByID_4At1dY on Query {\n  userByID(id: $initiallySelectedID) {\n    id\n    email\n    firstName\n    lastName\n    language\n    nodeID\n  }\n}\n"
  }
};
})();

(node as any).hash = "df621545ab28572c486df15853f4b0de";

export default node;
