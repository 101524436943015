import * as React from 'react';
import { FC } from 'react';
import { Link, FieldProps } from 'react-admin';

const ReferralCodeLinkField: FC<FieldProps> = (props) => {
  if (props.record) {
    const {
      firstName,
      lastName,
      id: userId,
    } = props.record?.ReferralCode.owner;

    return (
      <Link to={`/users/${userId}`}>
        <div>
          {firstName} {lastName}
        </div>
      </Link>
    );
  }
  return null;
};

export default ReferralCodeLinkField;
