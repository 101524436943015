import * as MUI from '@material-ui/core';
import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver';
import { ReactElement } from 'react';
import React from 'react';
import {
  CreateButton,
  Datagrid,
  ExportButton,
  Filter,
  Link,
  List,
  ListProps,
  NumberInput,
  TextField,
  TextInput,
  TopToolbar,
  sanitizeListRestProps,
  useListContext,
} from 'react-admin';

import IPADrawer from '../components/IPADrawer';
import WordCreate from './WordCreate';
import WordEdit from './WordEdit';

const WordFilter = (props: any) => (
  <Filter {...props}>
    <TextInput
      label="Word (use double quotes for exact search)"
      source="word"
      defaultValue=""
    />
    <TextInput label="Default pronunciation" source="bv_ipa" defaultValue="" />
    <NumberInput label="# pronunciations" source="pronunciations_count" />
  </Filter>
);

const ListActions = (props: any) => {
  const { className, filters, ...rest } = props;
  const {
    currentSort,
    resource,
    displayedFilters,
    filterValues,
    basePath,
    showFilter,
    total,
  } = useListContext();
  const [isIPADrawerOpen, setIsIPADrawerOpen] = React.useState(false);
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      <IPADrawer isOpen={isIPADrawerOpen} setIsOpen={setIsIPADrawerOpen} />
      <div>
        <div>
          {filters &&
            React.cloneElement(filters, {
              resource,
              showFilter,
              displayedFilters,
              filterValues,
              context: 'button',
            })}
          <CreateButton basePath={basePath} />
          <ExportButton
            disabled={total === 0}
            resource={resource}
            sort={currentSort}
            filterValues={filterValues}
            maxResults={10000}
          />
        </div>
        <div>
          <MUI.Box style={{ marginTop: -8 }}>
            <MUI.FormHelperText style={{ textAlign: 'right' }}>
              <MUI.Link
                onClick={() => setIsIPADrawerOpen(true)}
                style={{ cursor: 'pointer' }}
              >
                View supported IPA segments.
              </MUI.Link>
            </MUI.FormHelperText>
          </MUI.Box>
        </div>
      </div>
    </TopToolbar>
  );
};

const WordList = (props: ListProps): ReactElement => (
  <List
    {...props}
    sort={{ field: 'word', order: 'ASC' }}
    perPage={25}
    filters={<WordFilter />}
    actions={<ListActions />}
  >
    <Datagrid optimized>
      <WordField />

      <TextField source="bv_ipa" label="Default pronunciation" />
      <TextField source="pronunciations_count" label="# pronunciations" />
    </Datagrid>
  </List>
);

const WordField = (props: any) =>
  props.record ? (
    <Link to={`/g/words/${props.record.id}`}>{props.record.text}</Link>
  ) : null;

WordField.defaultProps = {
  source: 'word',
  addLabel: true,
};

export default {
  list: WordList,
  edit: WordEdit,
  create: WordCreate,
  icon: RecordVoiceOverIcon,
};
