/**
 * @generated SignedSource<<cb01c962794fa14e15659c80aa1bb57f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type VideoGroupVideosEditorVideoVariantMutation$variables = {
  id?: string | null | undefined;
  variantRules?: any | null | undefined;
};
export type VideoGroupVideosEditorVideoVariantMutation$data = {
  readonly raUpdateVideoVariant: {
    readonly " $fragmentSpreads": FragmentRefs<"VideoGroupVideosEditor_videoVariant">;
  } | null | undefined;
};
export type VideoGroupVideosEditorVideoVariantMutation = {
  response: VideoGroupVideosEditorVideoVariantMutation$data;
  variables: VideoGroupVideosEditorVideoVariantMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "variantRules"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  },
  {
    "kind": "Variable",
    "name": "variantRules",
    "variableName": "variantRules"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "VideoGroupVideosEditorVideoVariantMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "VideoVariant",
        "kind": "LinkedField",
        "name": "raUpdateVideoVariant",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "VideoGroupVideosEditor_videoVariant"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "VideoGroupVideosEditorVideoVariantMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "VideoVariant",
        "kind": "LinkedField",
        "name": "raUpdateVideoVariant",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "variantRules",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "title",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "thumbnailURL",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "duration",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "tags",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "nodeID",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a7be83b9651400f6a2c4b6288ff4b66a",
    "id": null,
    "metadata": {},
    "name": "VideoGroupVideosEditorVideoVariantMutation",
    "operationKind": "mutation",
    "text": "mutation VideoGroupVideosEditorVideoVariantMutation(\n  $id: ID\n  $variantRules: JSONObject\n) {\n  raUpdateVideoVariant(id: $id, variantRules: $variantRules) {\n    ...VideoGroupVideosEditor_videoVariant\n    nodeID\n  }\n}\n\nfragment VideoGroupVideosEditor_videoVariant on VideoVariant {\n  id\n  variantRules\n  title\n  thumbnailURL\n  duration\n  tags\n}\n"
  }
};
})();

(node as any).hash = "6af9ffbac41168af8439cefbe28691af";

export default node;
