import BarChartIcon from '@material-ui/icons/BarChart';

import UserStatsList from './UserStatsList';
import UserStatsEdit from './UserStatsEdit';

const UserIcon = BarChartIcon;

export default {
  icon: UserIcon,
  list: UserStatsList,
  edit: UserStatsEdit,
};
