/**
 * @generated SignedSource<<193e5df737e845ce4108c6b4fe94ec06>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SkillsTextScan_Query$variables = Record<PropertyKey, never>;
export type SkillsTextScan_Query$data = {
  readonly " $fragmentSpreads": FragmentRefs<"SkillsTextScan_result">;
};
export type SkillsTextScan_Query = {
  response: SkillsTextScan_Query$data;
  variables: SkillsTextScan_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "nodeID",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "SkillsTextScan_Query",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "SkillsTextScan_result"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "SkillsTextScan_Query",
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Literal",
            "name": "text",
            "value": ""
          }
        ],
        "concreteType": "SkillsTextScanMatch",
        "kind": "LinkedField",
        "name": "scanTextForSkills",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Skill",
            "kind": "LinkedField",
            "name": "skill",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Word",
            "kind": "LinkedField",
            "name": "words",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "text",
                "storageKey": null
              },
              (v0/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": "scanTextForSkills(text:\"\")"
      }
    ]
  },
  "params": {
    "cacheID": "7da3ca19f23cf3b23582009d5131ffae",
    "id": null,
    "metadata": {},
    "name": "SkillsTextScan_Query",
    "operationKind": "query",
    "text": "query SkillsTextScan_Query {\n  ...SkillsTextScan_result\n}\n\nfragment SkillsTextScan_SkillMatch on SkillsTextScanMatch {\n  skill {\n    id\n    name\n    nodeID\n  }\n  words {\n    text\n    nodeID\n  }\n}\n\nfragment SkillsTextScan_result on Query {\n  scanTextForSkills(text: \"\") {\n    skill {\n      id\n      nodeID\n    }\n    ...SkillsTextScan_SkillMatch\n  }\n}\n"
  }
};
})();

(node as any).hash = "119c3f8f712d8deecddf58c92532fd31";

export default node;
