import * as React from 'react';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import moment from 'moment';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import styled from 'styled-components';
import { CircularProgress } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 0.5,
    marginLeft: 10,
    marginTop: 10,
    height: 304,
  },
  rootWithoutHeight: {
    flex: 0.5,
    marginLeft: 10,
    marginTop: 10,
  },
  listItem: {
    flex: 0.85,
  },
  secondary: {
    marginRight: '1em',
    color: theme.palette.text.primary,
  },
  scrollList: {
    overflow: 'scroll',
    height: 224,
  },
}));

const DashboardChartData = React.memo((props: any) => {
  const {
    title,
    data = [],
    dataKey,
    isOpen,
    setMainSectionsOpen,
    detailType,
    dataLabel,
    weekly,
    isLoading,
  } = props;
  const classes = useStyles();

  const toggleExpandCollapse = () => {
    if (!setMainSectionsOpen) return;
    setMainSectionsOpen((existingMainSectionsOpen: any) => ({
      ...existingMainSectionsOpen,
      [detailType]: !isOpen,
    }));
  };

  if (!isOpen) {
    return (
      <Card className={classes.rootWithoutHeight}>
        <TitleParent onClick={toggleExpandCollapse}>
          <CardHeader title={title} />
          <ExpandMore />
        </TitleParent>
      </Card>
    );
  }

  return (
    <Card className={classes.root}>
      <TitleParent onClick={toggleExpandCollapse}>
        <CardHeader title={title} />
        {setMainSectionsOpen && <ExpandLess />}
        {isLoading && <CircularProgress style={{ marginLeft: 10 }} size={20} />}
      </TitleParent>
      <List className={classes.scrollList} dense>
        {[...data]?.reverse()?.map((date: any) =>
          date?.[dataKey]?.map((record: any) => {
            let secondaryText = '';
            if (record?.key) {
              secondaryText = `${dataLabel}: ${moment(date.date).format(
                'MMM D',
              )}`;

              const tierSplit = record?.key?.split('.');
              if (!tierSplit?.[4]) {
                secondaryText += ` (Stripe)`;
              } else {
                secondaryText += ` (${tierSplit[4]})`;
              }

              if (record?.unsubscribe_detected_at) {
                secondaryText += ' (👎)';
              }

              if (record?.period_type === 'normal') {
                secondaryText += ' (✅)';
              }

              if (moment(record?.expires_date).isBefore(moment())) {
                secondaryText += ' (❌)';
              }

              if (dataKey === 'trialCancelledData') {
                secondaryText += `\nTrial Started: ${moment(
                  record.original_purchase_date,
                ).format('MMM D')}`;
              }
            }

            if (record?.store === 'no-paywall') {
              secondaryText += `Trial Started: ${moment(
                record.original_purchase_date,
              ).format('MMM D')} (no paywall)`;

              if (
                moment(record.original_purchase_date)
                  .add(7, 'days')
                  .isSameOrBefore(moment())
              ) {
                secondaryText += ' (❌)';
              }
            }

            if (record?.listOfDaysProgress && !weekly) {
              secondaryText = `Active on ${record.listOfDaysProgress.length} days during period`;
            }

            if (weekly) {
              const year = record.streakStartedWeek.substring(0, 4);
              const week = record.streakStartedWeek.substring(4, 6);
              const weekMoment = moment().set({
                year,
                week,
              });
              secondaryText = `${record.weeklyStreak} week streak since
                week ${week} of
                ${year} (${weekMoment
                .startOf('week')
                .format('M/D')}-${weekMoment.endOf('week').format('M/D')})`;
            }

            return (
              <ListItem
                key={`${date?.date}_${record?.userId || record?.id}`}
                button
                component={Link}
                to={`/users/${record?.userId || record?.id}`}
                target="_blank"
              >
                <ListItemText
                  primary={`${
                    record?.name?.trim() !== record?.email?.trim()
                      ? record?.name?.trim()
                      : ''
                  } ${record?.email || record?.phoneNumber}`}
                  secondary={secondaryText}
                  className={classes.listItem}
                />
                <ListItemSecondaryAction>
                  <span className={classes.secondary}>
                    {record?.store === 'app_store'
                      ? 'iOS'
                      : record?.store === 'stripe'
                      ? 'Web'
                      : record?.store === 'play_store'
                      ? 'Android'
                      : ''}
                  </span>
                </ListItemSecondaryAction>
              </ListItem>
            );
          }),
        )}
      </List>
    </Card>
  );
});

export default DashboardChartData;

const TitleParent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
`;
