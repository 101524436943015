import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver';
import { ReactElement } from 'react';
import React from 'react';
import {
  BooleanField,
  BooleanInput,
  Datagrid,
  DateField,
  Filter,
  Link,
  List,
  ListProps,
  NumberField,
  NumberInput,
  TextField,
  TextInput,
} from 'react-admin';

import IPAText from '../components/IPAText';
import SkillCreate from './SkillCreate';
import SkillEdit from './SkillEdit';

const SkillFilter = (props: any) => (
  <Filter {...props}>
    <TextInput label="Name" source="name" defaultValue="" />
    <TextInput label="Rules" source="rules" defaultValue="" />
    <NumberInput label="Importance" source="importance" defaultValue="" />
    <BooleanInput label="Active" source="active" defaultValue />
    <BooleanInput label="Quick Win" source="quickWin" defaultValue />
    <BooleanInput
      label="Can Score Well"
      source="speechaceCanScoreSkillWell"
      defaultValue
    />
  </Filter>
);

const SkillList = (props: ListProps): ReactElement => (
  <List
    {...props}
    sort={{ field: 'name', order: 'ASC' }}
    perPage={25}
    filters={<SkillFilter />}
  >
    <Datagrid optimized>
      <SkillField />

      <NumberField source="skillLessonsNumber" label="Num. Lessons" />
      <TextField source="categories" label="Category" />
      <BooleanField source="autogenerated" label="Auto-Generated" />

      <DateField source="createdAt" label="Created At" />
    </Datagrid>
  </List>
);

const SkillField = (props: any) =>
  props.record ? (
    <Link to={`/g/skills/${props.record.id}`}>
      <IPAText text={props.record.name} />
    </Link>
  ) : null;

SkillField.defaultProps = {
  source: 'name',
  addLabel: true,
};

export default {
  list: SkillList,
  edit: SkillEdit,
  create: SkillCreate,
  icon: RecordVoiceOverIcon,
};
