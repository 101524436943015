import { Box, Card, CardContent, Typography } from '@material-ui/core';
import { Theme, makeStyles } from '@material-ui/core/styles';
import { Styles } from '@material-ui/styles/withStyles';
import * as React from 'react';
import { FC } from 'react';
import {
  DeleteButton,
  Edit,
  EditProps,
  FieldProps,
  FormWithRedirect,
  SaveButton,
  SelectInput,
  TextInput,
  Toolbar,
} from 'react-admin';
import { JsonField } from 'react-admin-json-view';

import { PHONE_OS_LIST } from '../users/UserList';
import AvatarField from './AvatarField';
import FullNameField from './FullNameField';
import { validatePasswords } from './UserCreate';
import UserLinkField from './UserLinkField';

const UserEdit: FC<EditProps> = (props) => (
  <Edit undoable={false} title={<UserTitle />} component="div" {...props}>
    <UserForm />
  </Edit>
);

export const styles: Styles<Theme, any> = {
  name: { display: 'inline-block' },
  url: { display: 'block' },
  image: {
    margin: '0.5rem',
    maxHeight: '10rem',
  },
  button: {
    marginRight: 10,
  },
  select: {
    flex: 1,
    marginRight: 10,
  },
};

const useStyles = makeStyles(styles);

const UserTitle: FC<FieldProps> = ({ record }) =>
  record ? <FullNameField record={record} size="32" /> : null;

const UserForm = (props: any) => {
  const classes = useStyles(props);
  const { record } = props;

  return (
    <FormWithRedirect
      {...props}
      validate={validatePasswords}
      render={(formProps: any) => (
        <Card>
          <form>
            <CardContent>
              <Box display={{ md: 'block', lg: 'flex' }}>
                <Box flex={2} mr={{ md: 0, lg: '1em' }}>
                  {record?.User ? (
                    <>
                      <Typography variant="h6" gutterBottom>
                        Linked User:
                      </Typography>

                      <Box display={{ xs: 'block', sm: 'flex' }}>
                        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                          <UserLinkField record={record} />
                        </Box>
                      </Box>

                      <Box mt="1em" />
                    </>
                  ) : null}

                  <Typography variant="h6" gutterBottom>
                    Onboarding Details
                  </Typography>
                  {props?.record?.profilePicture && (
                    <AvatarField
                      includeDelete
                      record={props.record}
                      size="200"
                    />
                  )}

                  <Box display={{ xs: 'block', sm: 'flex' }}>
                    <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                      <TextInput
                        label="Language"
                        source="language"
                        resource="users"
                        fullWidth
                        helperText={false}
                      />
                    </Box>
                    <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                      <TextInput
                        label="Motivation"
                        source="motivation"
                        resource="users"
                        fullWidth
                        helperText={false}
                      />
                    </Box>
                    <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                      <TextInput
                        label="Referral Source"
                        source="referral"
                        resource="users"
                        fullWidth
                        helperText={false}
                      />
                    </Box>
                  </Box>
                  <Box display={{ xs: 'block', sm: 'flex' }}>
                    <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                      <TextInput
                        label="English Proficiency"
                        source="proficiency"
                        resource="users"
                        fullWidth
                        helperText={false}
                      />
                    </Box>
                    <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                      <TextInput
                        label="Accent Confidence"
                        source="pronunciation_accent"
                        resource="users"
                        fullWidth
                        helperText={false}
                      />
                    </Box>
                    <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                      <TextInput
                        label="Desired Improvement"
                        source="setting"
                        resource="users"
                        fullWidth
                        helperText={false}
                      />
                    </Box>
                  </Box>
                  <Box display={{ xs: 'block', sm: 'flex' }}>
                    <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                      <TextInput
                        label="Will you commit?"
                        source="commit"
                        resource="users"
                        fullWidth
                        helperText={false}
                      />
                    </Box>
                    <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                      <TextInput
                        label="Country Code"
                        source="phoneCountryCode"
                        resource="users"
                        fullWidth
                        helperText={false}
                      />
                    </Box>
                    <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                      <TextInput
                        label="Phone Number"
                        source="phoneNumber"
                        resource="users"
                        fullWidth
                        helperText={false}
                      />
                    </Box>
                  </Box>
                  <Box display={{ xs: 'block', sm: 'flex' }}>
                    <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                      <SelectInput
                        label="Phone OS"
                        source="phoneOS"
                        resource="users"
                        helperText={false}
                        choices={PHONE_OS_LIST}
                        allowEmpty
                        fullWidth
                        disabled
                      />
                    </Box>
                    <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                      <SelectInput
                        label="Original Phone OS"
                        source="originalPhoneOS"
                        resource="users"
                        helperText={false}
                        choices={PHONE_OS_LIST}
                        allowEmpty
                        fullWidth
                        disabled
                      />
                    </Box>
                    <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                      <TextInput
                        label="Unique String"
                        source="uniqueString"
                        resource="users"
                        helperText={false}
                        disabled
                        fullWidth
                      />
                    </Box>
                  </Box>
                  <Box
                    display={{ xs: 'block', sm: 'flex' }}
                    style={{ marginTop: 10 }}
                  >
                    <JsonField
                      source="featureFlags"
                      record={record}
                      jsonString={false}
                      reactJsonOptions={{
                        name: 'featureFlags',
                        collapsed: false,
                        enableClipboard: true,
                        displayDataTypes: false,
                        sortKeys: true,
                        displayObjectSize: false,
                      }}
                    />
                  </Box>
                </Box>
              </Box>
            </CardContent>
            <Toolbar
              record={formProps.record}
              basePath={formProps.basePath}
              undoable
              invalid={formProps.invalid}
              handleSubmit={formProps.handleSubmit}
              saving={formProps.saving}
              resource="users"
              style={{ display: 'flex', justifyContent: 'space-between' }}
            >
              <div>
                <SaveButton
                  label="Save & Continue"
                  handleSubmitWithRedirect={
                    formProps.handleSubmitWithRedirect || formProps.handleSubmit
                  }
                  disabled={formProps.disabled}
                  invalid={formProps.invalid}
                  redirect="edit"
                  saving={formProps.saving}
                  submitOnEnter={formProps.submitOnEnter}
                  className={classes.button}
                />

                <SaveButton
                  label="Save & Exit"
                  handleSubmitWithRedirect={
                    formProps.handleSubmitWithRedirect || formProps.handleSubmit
                  }
                  disabled={formProps.disabled}
                  invalid={formProps.invalid}
                  redirect={formProps.redirect}
                  saving={formProps.saving}
                  submitOnEnter={formProps.submitOnEnter}
                  className={classes.button}
                />
              </div>

              <div>
                {formProps.record &&
                  typeof formProps.record.id !== 'undefined' && (
                    <DeleteButton
                      basePath={formProps.basePath}
                      record={formProps.record}
                      resource={formProps.resource}
                      undoable={formProps.undoable}
                      mutationMode={formProps.mutationMode}
                    />
                  )}
              </div>
            </Toolbar>
          </form>
        </Card>
      )}
    />
  );
};

export default UserEdit;
