import { Box, Card, CardContent } from '@material-ui/core';
import { Theme, makeStyles } from '@material-ui/core/styles';
import { Styles } from '@material-ui/styles/withStyles';
import * as React from 'react';
import { FC } from 'react';
import {
  AutocompleteArrayInput,
  DeleteButton,
  Edit,
  EditProps,
  FieldProps,
  FormWithRedirect,
  ReferenceArrayInput,
  ReferenceInput,
  SaveButton,
  SelectInput,
  TextInput,
  Toolbar,
} from 'react-admin';

import ConversationPracticeInput from '../components/ConversationPracticeInput';
import OrderedArrayInput from '../components/OrderedArrayInput';
import { matchSuggestion } from '../lesson/utils';

const ConversationIpa: FC<FieldProps> = ({ record }) =>
  record ? (
    <span>
      Conversation &quot;
      {record.name}&quot;
    </span>
  ) : null;

export const styles: Styles<Theme, any> = {
  name: { display: 'inline-block' },
  photo: { display: 'inline-block' },
  spacing: { marginRight: 10 },
  image: {
    margin: '0.5rem',
    maxHeight: '10rem',
  },
  button: {
    marginRight: 10,
  },
};

const useStyles = makeStyles(styles);

const ConversationForm = (props: any) => {
  const classes = useStyles(props);
  return (
    <FormWithRedirect
      {...props}
      render={(formProps: any) => (
        <Card>
          <form>
            <CardContent>
              <Box>
                <TextInput
                  fullWidth
                  autoFocus
                  source="name"
                  label="Name"
                  className={classes.name}
                />
              </Box>
              <Box>
                <TextInput
                  fullWidth
                  source="description"
                  label="Description"
                  className={classes.name}
                />
              </Box>
              <Box style={{ display: 'flex', flexDirection: 'row' }}>
                <Box style={{ flex: 1 }}>
                  <TextInput
                    fullWidth
                    source="thumbnail_url"
                    label="Thumbnail Url"
                    className={classes.name}
                  />
                </Box>
                <Box style={{ marginLeft: 10 }}>
                  <TextInput
                    source="tags"
                    label="Tags"
                    className={classes.name}
                  />
                </Box>
              </Box>

              <Box>
                <ReferenceInput
                  allowEmpty
                  label="Coach"
                  source="coachId"
                  reference="coach"
                >
                  <SelectInput optionText="name" />
                </ReferenceInput>
              </Box>

              <Box>
                <ReferenceArrayInput
                  filterToQuery={(searchText: any) => ({ text: searchText })}
                  label="Phonemes"
                  source="phonemeId"
                  reference="content/phoneme"
                  fullWidth
                >
                  <OrderedArrayInput elementLinkUrl="/#/content/phoneme/">
                    <AutocompleteArrayInput
                      optionValue="id"
                      optionText={(record: any) =>
                        `${record?.text} | ${record?.ipa} | ${record?.arpabet}`
                      }
                      matchSuggestion={(filterValue: string, choice: any) =>
                        matchSuggestion(filterValue, choice, 'text')
                      }
                    />
                  </OrderedArrayInput>
                </ReferenceArrayInput>
              </Box>

              <Box>
                <ReferenceArrayInput
                  filterToQuery={(searchText: any) => ({ word: searchText })}
                  label="Items"
                  source="practiceId"
                  reference="content/practice"
                  fullWidth
                  filter={{ isUserGeneratedContent: false }}
                >
                  <ConversationPracticeInput>
                    <AutocompleteArrayInput
                      debounce={750}
                      optionValue="id"
                      optionText={(record: any) =>
                        `${record?.word} ${
                          record?.coachSample ? ` ✅` : ` ❌`
                        } (id: ${record?.id?.slice(0, 4)})`
                      }
                      allowDuplicates
                      matchSuggestion={(filterValue: string, choice: any) =>
                        matchSuggestion(filterValue, choice, 'word')
                      }
                    />
                  </ConversationPracticeInput>
                </ReferenceArrayInput>
              </Box>

              <Toolbar
                record={formProps.record}
                basePath={formProps.basePath}
                undoable={false}
                invalid={formProps.invalid}
                handleSubmit={formProps.handleSubmit}
                saving={formProps.saving}
                resource="conversations"
                style={{ display: 'flex', justifyContent: 'space-between' }}
              >
                <div>
                  <SaveButton
                    label="Save & Continue"
                    handleSubmitWithRedirect={
                      formProps.handleSubmitWithRedirect ||
                      formProps.handleSubmit
                    }
                    disabled={formProps.disabled}
                    invalid={formProps.invalid}
                    redirect="edit"
                    saving={formProps.saving}
                    submitOnEnter={formProps.submitOnEnter}
                    className={classes.button}
                  />
                  <SaveButton
                    label="Save & Exit"
                    handleSubmitWithRedirect={
                      formProps.handleSubmitWithRedirect ||
                      formProps.handleSubmit
                    }
                    disabled={formProps.disabled}
                    invalid={formProps.invalid}
                    redirect={formProps.redirect}
                    saving={formProps.saving}
                    submitOnEnter={formProps.submitOnEnter}
                    className={classes.button}
                  />
                </div>
                {formProps.record &&
                  typeof formProps.record.id !== 'undefined' && (
                    <DeleteButton
                      basePath={formProps.basePath}
                      record={formProps.record}
                      resource={formProps.resource}
                      undoable={formProps.undoable}
                      mutationMode={formProps.mutationMode}
                    />
                  )}
              </Toolbar>
            </CardContent>
          </form>
        </Card>
      )}
    />
  );
};

const ConversationEdit: FC<EditProps> = (props) => (
  <Edit undoable={false} title={<ConversationIpa />} {...props}>
    <ConversationForm />
  </Edit>
);

export default ConversationEdit;
