import * as React from 'react';
import { FC } from 'react';
import Avatar from '@material-ui/core/Avatar';
import { FieldProps, useRefresh } from 'react-admin';
import api from '../api';
import CloseIcon from '@material-ui/icons/Close';

interface Props extends FieldProps {
  className?: string;
  size?: string;
  includeDelete?: boolean;
}

const AvatarField: FC<Props> = ({
  record,
  size = '25',
  className,
  includeDelete,
}) => {
  const [profilePictureFile, setProfilePictureFile] = React.useState<any>();
  const [error, setError] = React.useState<any>();
  const refresh = useRefresh();

  const removeProfilePictureForUser = async () => {
    if (
      window.confirm(
        `Are you sure you want to remove ${record?.User?.firstName}'s profile picture?`,
      )
    ) {
      const userId = record?.User?.id;
      await api.removeProfilePicture(userId);
      refresh();
    }
  };

  React.useEffect(() => {
    const getProfilePictureFile = async () => {
      setError(undefined);
      if (record?.User?.profilePicture) {
        try {
          const newProfilePictureFile = await api.getProfilePicture(
            record?.User?.profilePicture,
          );
          if (newProfilePictureFile.status === 403) {
            const jsonResult = await newProfilePictureFile.json();
            if (!jsonResult?.success) {
              setError('No profile picture file at URL provided.');
            }
          } else {
            const fileBlob = await newProfilePictureFile?.body?.blob();
            const reader = new FileReader();
            reader.onload = function (e) {
              const base64pic = e?.target?.result;
              if (base64pic) {
                setProfilePictureFile(base64pic);
              } else {
                setError('No profile picture file at URL provided.');
              }
            };
            fileBlob.end = fileBlob.size;
            reader.readAsDataURL(fileBlob);
          }
        } catch (err: any) {
          setError(err?.message);
        }
      }
    };

    getProfilePictureFile();

    return setProfilePictureFile(null);
  }, [record?.User?.profilePicture]);

  return record ? (
    <div style={{ position: 'relative' }}>
      {includeDelete && (
        <CloseIcon
          onClick={removeProfilePictureForUser}
          style={{ position: 'absolute', top: 0, left: 175, cursor: 'pointer' }}
        />
      )}
      <Avatar
        src={profilePictureFile}
        style={{ width: parseInt(size, 10), height: parseInt(size, 10) }}
        className={className}
      />
      {error}
    </div>
  ) : null;
};

export default AvatarField;
