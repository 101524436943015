import { TextInput } from 'react-admin';

const MultipleChoicePromptFormFields = ({ autogenerated }: any) => (
  <>
    <TextInput
      autoFocus
      source="text"
      label="Prompt Text"
      fullWidth
      helperText={false}
      disabled={autogenerated}
    />
  </>
);

export default MultipleChoicePromptFormFields;
