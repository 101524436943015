import { json as codeMirrorJSON } from '@codemirror/lang-json';
import { EditorView } from '@codemirror/view';
import * as MUI from '@material-ui/core';
import * as codeMirrorEvents from '@uiw/codemirror-extensions-events';
import CodeMirror from '@uiw/react-codemirror';
import JSON5 from 'json5';
import React from 'react';
import { useInput } from 'react-admin';

const styleTheme = EditorView.baseTheme({
  '&.cm-editor.cm-focused': { outline: 'none' },
  '&.cm-editor': { background: 'transparent', fontSize: '15px' },
  '&.cm-editor .cm-line': { padding: 0 },
  '&.cm-editor .cm-content': { padding: 0 },
});

const codeMirrorExtensions = [styleTheme, codeMirrorJSON()];

const CodeEditorInput = ({
  onChange,
  inputRef,
  onFocus,
  onBlur,
  ...props
}: any) => {
  const ref = React.useRef<any>();

  const inputOnChange = React.useCallback(
    (value: any) => {
      onChange({ target: { value } });
    },
    [onChange, ref],
  );

  React.useImperativeHandle(inputRef, () => ({
    focus: () => {
      ref.current.view.focus();
    },
  }));

  const events = codeMirrorEvents.content({
    focus: onFocus,
    blur: onBlur,
  });

  return (
    <CodeMirror
      ref={ref}
      onChange={inputOnChange}
      value={props.value === undefined ? props.defaultValue : props.value}
      minHeight={`${props.rows}lh`}
      width="100%"
      extensions={[events, ...codeMirrorExtensions]}
      basicSetup={{
        lineNumbers: false,
        foldGutter: false,
        searchKeymap: false,
        foldKeymap: false,
        highlightActiveLine: false,
      }}
      style={{ width: '100%' }}
      readOnly={props.disabled}
    />
  );
};

const JSONField = (props: any) => (
  <MUI.TextField
    multiline
    fullWidth
    size="small"
    rows={1}
    variant="filled"
    InputProps={{ inputComponent: CodeEditorInput }}
    InputLabelProps={{
      shrink: props.value ? true : undefined,
    }}
    {...props}
  />
);

export const JSONInput = (props: any) => {
  const [value, setValue] = React.useState(null);

  const {
    input: { name, onChange, ...rest },
    meta: { touched, error },
    isRequired,
  } = useInput({
    format: (formValue: any) =>
      formValue == null
        ? ''
        : JSON5.stringify(formValue, { replacer: null, space: 2, quote: '"' }),
    ...props,
    beforeSubmit: () => {
      setValue(null);
    },
    validate: (value) => {
      if (value instanceof Error) {
        return value.message || 'Error parsing JSON';
      }

      return props.validate?.(value);
    },
  });

  const handleChange = (e: any) => {
    if (e.target.value === '') {
      onChange(null);
      setValue(null);
      return;
    }

    try {
      onChange(JSON5.parse(e.target.value));
    } catch (error: any) {
      onChange(error);
    }

    setValue(e.target.value);
  };

  return (
    <JSONField
      name={name}
      label={props.label}
      onChange={handleChange}
      error={!!(touched && error)}
      helperText={touched && error}
      required={isRequired}
      value={value || rest.value}
      disabled={props.disabled}
    />
  );
};

export default JSONField;
