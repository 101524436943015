// @ts-nocheck

import React from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import styled from 'styled-components';
import { useInput } from 'react-admin';
import { useForm } from 'react-final-form';

const ChallengeOrderInput = (props: any) => {
  const { id, choices, label = true } = props;

  const {
    input: { onChange, value: values },
  } = useInput(props);

  const { change } = useForm();

  //   const children = React.cloneElement(props.children, props);
  const selectedChoices = (values || [])
    .map((v: any) => choices.find((c: any) => c.id === v))
    .filter(identity);

  // updates fromToValues and associations
  React.useEffect(() => {
    const items = [];
    if (values?.length) {
      values.forEach((value) => {
        const newItem = {
          id: value,
        };

        items.push(newItem);
      });
    }

    change('items', items);
  }, [values]);

  const onDragEnd = (result: any) => {
    const { destination, source, draggableId } = result;

    if (!destination || destination.index === source.index) return;

    const newValues = Array.from(values);
    newValues.splice(source.index, 1);
    newValues.splice(destination.index, 0, draggableId);

    onChange(newValues);
  };

  return (
    <>
      {/* {children} */}
      {selectedChoices.length ? (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId={id}>
            {(provided, snapshot) => (
              <List
                component="nav"
                ref={provided.innerRef}
                isDraggingOver={snapshot.isDraggingOver}
                {...provided.droppableProps}
              >
                <Title>{label}</Title>
                {selectedChoices.map((v, i) => (
                  <ChoiceItem value={v} index={i} key={v.id} />
                ))}
                {provided.placeholder}
              </List>
            )}
          </Droppable>
        </DragDropContext>
      ) : null}
    </>
  );
};

const ChoiceItem = (props) => {
  const { value, index } = props;
  const isPracticeSet = value.contentType === 'PracticeSet';
  const urlOfContentType =
    value.contentType === 'Video'
      ? 'video'
      : value.contentType === 'Conversation'
      ? 'conversation'
      : 'rapidfire';
  const linkToContent = isPracticeSet
    ? null
    : `/#/content/${urlOfContentType}/${value.content.id}`;

  return (
    <Draggable draggableId={value.id} index={index}>
      {(provided, snapshot) => (
        <ListItem
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          isDragging={snapshot.isDragging}
        >
          <FlexRow>
            <Item style={{ display: 'flex', flexDirection: 'row', flex: 1 }}>
              <span>#{index + 1} - </span>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  flex: 1,
                }}
              >
                <a href={linkToContent} target="_blank">
                  <b>
                    {value?.contentType}: {value?.content?.name}
                  </b>
                </a>
                <span
                  style={{
                    fontSize: '.75rem',
                    color: 'gray',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  id {value?.content?.id}
                </span>
              </div>
            </Item>
          </FlexRow>
        </ListItem>
      )}
    </Draggable>
  );
};

export default ChallengeOrderInput;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
`;

const Item = styled.div`
  ${(p) => p.flex && `flex: ${p.flex};`}
`;

const ListItem = styled.div<StyledDivProps>`
  padding: 8px;
  margin: 8px;
  border: 1px solid grey;
  background-color: ${(props) => (props.isDragging ? 'lightgrey' : 'white')};
  border-radius: 16px;
`;

const List = styled.div<StyledDivProps>`
  padding: 8px;
  border: 1px solid grey;
  background-color: ${(props) => (props.isDraggingOver ? '#2097F3' : 'white')};
`;

const Title = styled.div`
  font-weight: bold;
  font-size: 1.2rem;
  padding: 8px;
`;

interface StyledDivProps {
  isDragging?: boolean;
  isDraggingOver?: boolean;
}

const identity = (v: any) => v;
