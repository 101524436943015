import * as React from 'react';
import { FC } from 'react';
import { FieldProps, Link } from 'react-admin';

import FullNameField from './FullNameField';

const UserLinkField: FC<FieldProps> = (props) =>
  props.record ? (
    <Link to={`/users/${props.record.userID}`}>
      <FullNameField {...props} />
    </Link>
  ) : null;

UserLinkField.defaultProps = {
  source: 'firstName',
  label: 'User',
  addLabel: true,
};

export default UserLinkField;
