/**
 * @generated SignedSource<<92f34d4de469140b69b172d11c101b4d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PhonemesSelect_phonemesForString_RefetchQuery$variables = {
  search?: string | null | undefined;
};
export type PhonemesSelect_phonemesForString_RefetchQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"PhonemesSelect_phonemesForString">;
};
export type PhonemesSelect_phonemesForString_RefetchQuery = {
  response: PhonemesSelect_phonemesForString_RefetchQuery$data;
  variables: PhonemesSelect_phonemesForString_RefetchQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": "",
    "kind": "LocalArgument",
    "name": "search"
  }
],
v1 = {
  "kind": "Variable",
  "name": "search",
  "variableName": "search"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PhonemesSelect_phonemesForString_RefetchQuery",
    "selections": [
      {
        "args": [
          (v1/*: any*/)
        ],
        "kind": "FragmentSpread",
        "name": "PhonemesSelect_phonemesForString"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PhonemesSelect_phonemesForString_RefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Literal",
            "name": "first",
            "value": 20
          },
          (v1/*: any*/)
        ],
        "concreteType": "QueryPhonemesForStringConnection",
        "kind": "LinkedField",
        "name": "phonemesForString",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Phoneme",
            "kind": "LinkedField",
            "name": "nodes",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "arpabet",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "ipa",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "nodeID",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "8ebd430ef9932185b55abad41e346dc1",
    "id": null,
    "metadata": {},
    "name": "PhonemesSelect_phonemesForString_RefetchQuery",
    "operationKind": "query",
    "text": "query PhonemesSelect_phonemesForString_RefetchQuery(\n  $search: String = \"\"\n) {\n  ...PhonemesSelect_phonemesForString_40zwac\n}\n\nfragment PhonemesSelect_phonemesForString_40zwac on Query {\n  phonemesForString(search: $search, first: 20) {\n    nodes {\n      id\n      arpabet\n      ipa\n      nodeID\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "2a796e1748edc01e320985fcb3ffa54c";

export default node;
