import * as React from 'react';
import { FC, memo } from 'react';

import { FieldProps } from 'react-admin';

interface Props extends FieldProps {
  conversationId?: any;
}

const Line = () => (
  <div style={{ width: '100%', borderBottom: '1px solid #4f3cc9' }} />
);

const ConversationNameField: FC<Props> = ({ record, conversationId }) => {
  const name = record?.conversations?.find(
    (conversation: any) => conversation.id === conversationId,
  )?.name;
  return record && name ? (
    <>
      <div>{name}</div>
      <Line />
    </>
  ) : null;
};

ConversationNameField.defaultProps = {
  source: 'name',
  label: 'Name',
};

export default memo<Props>(ConversationNameField);
