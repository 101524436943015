/**
 * @generated SignedSource<<e211d883399e787739d33a381eca6427>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DLPAssessmentsTaken2_DetailedResults$data = {
  readonly audioLogsLatest: ReadonlyArray<{
    readonly audioFile: string | null | undefined;
    readonly modelName: string | null | undefined;
    readonly score: number | null | undefined;
    readonly speechace: any | null | undefined;
  }> | null | undefined;
  readonly " $fragmentType": "DLPAssessmentsTaken2_DetailedResults";
};
export type DLPAssessmentsTaken2_DetailedResults$key = {
  readonly " $data"?: DLPAssessmentsTaken2_DetailedResults$data;
  readonly " $fragmentSpreads": FragmentRefs<"DLPAssessmentsTaken2_DetailedResults">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DLPAssessmentsTaken2_DetailedResults",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "AudioLog",
      "kind": "LinkedField",
      "name": "audioLogsLatest",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "score",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "speechace",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "audioFile",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "modelName",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "AssessmentTaken",
  "abstractKey": null
};

(node as any).hash = "c0e551fce7aeeeb1031dcd869b5a6ae7";

export default node;
