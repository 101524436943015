import * as MUI from '@material-ui/core';
import * as MUIColors from '@material-ui/core/colors';
import graphql from 'babel-plugin-relay/macro';
import * as d3 from 'd3';
import * as df from 'date-fns';
import _ from 'lodash';
import { useCallback } from 'react';
import React from 'react';
import { useLazyLoadQuery } from 'react-relay';

import PlotContainer, { Plot } from '../components/PlotContainer';

const ImprovementStatsWrapper = () => {
  return (
    <MUI.Box display="flex" flexDirection="column" height="100%">
      <React.Suspense
        fallback={
          <MUI.Box
            display="flex"
            flexGrow={1}
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
          >
            <MUI.Typography
              variant="h5"
              gutterBottom
              style={{ marginBottom: 30 }}
            >
              This page takes a while to load 😅
            </MUI.Typography>
            <MUI.CircularProgress />
          </MUI.Box>
        }
      >
        <ImprovementStats />
      </React.Suspense>
    </MUI.Box>
  );
};

const ImprovementStats = () => {
  const query = useLazyLoadQuery(
    graphql`
      query ImprovementStatsQuery {
        usersWithAccurateAggregateAssessments {
          id
          firstName
          lastName
          ageGroup
          # audioLogsCount
          completedCurriculumItemsCount
          hasUserJourney

          languages {
            englishName
          }

          accurateAggregateAssessments
        }
      }
    `,
    {},
  ) as any;

  const users = React.useMemo(() => {
    return (
      query.usersWithAccurateAggregateAssessments
        .map((user: any) => {
          const days =
            user.accurateAggregateAssessments.length > 0
              ? df.differenceInDays(
                  user.accurateAggregateAssessments[
                    user.accurateAggregateAssessments.length - 1
                  ].createdAt,
                  user.accurateAggregateAssessments[0].createdAt,
                )
              : 0;

          const change =
            user.accurateAggregateAssessments.length > 0
              ? user.accurateAggregateAssessments[
                  user.accurateAggregateAssessments.length - 1
                ].bottomAvg - user.accurateAggregateAssessments[0].bottomAvg
              : 0;

          return {
            ...user,
            days,
            change,
          };
        })
        // .filter((user) => user.days >= 3)
        .filter((user) => user.days <= 120)
        .sort((a, b) => b.days - a.days)
    );
    // .sort((a, b) => b.change - a.change);
  }, [query]);

  const byLanguage = React.useMemo(
    () =>
      Object.entries(_.groupBy(users, (u) => u.languages?.[0]?.englishName))
        .filter(([_language, users]) => users.length > 90)
        .map(([language, users]) => ({
          language,
          users,
          summaryStats: computeSummaryStats(users),
        }))
        .sort(
          (a: any, b: any) =>
            b.summaryStats.avgRateOfChange - a.summaryStats.avgRateOfChange,
        ),
    [users],
  );

  const byAgeGroup = React.useMemo(
    () =>
      _.sortBy(
        Object.entries(_.groupBy(users, (u) => u.ageGroup))
          .filter(
            ([ageGroup, users]) => users.length > 90 && ageGroup !== 'null',
          )
          .map(([ageGroup, users]) => ({
            ageGroup,
            users,
            summaryStats: computeSummaryStats(users),
          })),
        ({ ageGroup }) =>
          ageGroup === 'Under 20' ? 0 : parseInt(ageGroup, 10),
      ),
    [users],
  );

  const summaryStats = React.useMemo(
    () => computeSummaryStats(users),
    [users],
  ) as any;

  return (
    <MUI.Box style={{ paddingTop: 10 }}>
      <MUI.Typography variant="h4" gutterBottom>
        Improvement Statistics
      </MUI.Typography>
      <MUI.Typography variant="body1" style={{ maxWidth: 630 }}>
        <p>
          This page shows improvement statistics for{' '}
          {summaryStats.count.toLocaleString('en-US')} users in the new user
          journey. Each green{' '}
          <span style={{ color: MUIColors.green[500] }}>⏺︎</span> represents a
          user who improved, each gray{' '}
          <span style={{ color: MUIColors.grey[500] }}>⏺︎</span> a user who
          stayed roughly the same, and each red{' '}
          <span style={{ color: MUIColors.red[500] }}>⏺︎</span> a user who
          regressed. <em>Change</em> is the difference in the user's aggregate
          assessment score (0.0-1.0) from when they joined the app to their most
          recent lesson.
        </p>
      </MUI.Typography>

      <SummaryStats users={users} summaryStats={summaryStats} />

      <MUI.Typography variant="body1" style={{ maxWidth: 630, marginTop: -10 }}>
        <p>
          <MUI.Typography variant="body2">
            <strong>
              Why so many <span style={{ color: MUIColors.red[500] }}>⏺︎</span>
              ?
            </strong>
            <br />
            Red <span style={{ color: MUIColors.red[500] }}>⏺︎</span> represent
            users whose aggregate assessment score decreased since starting. We
            don't expect users to actually regress, so most of these are likely
            due to inaccuracies in the assessment system. I don't know if the
            error only skews in one direction, however, so I've left them in.
          </MUI.Typography>
        </p>
      </MUI.Typography>

      <MUI.Typography variant="body1" style={{ maxWidth: 630, marginTop: 8 }}>
        <p>
          <MUI.Typography variant="body2">
            <strong>Viewing Details</strong>
            <br />
            To dive deeper into an individual user's data, click on their dot,
            then on the <em>View Details</em> link in the tooltip.
          </MUI.Typography>
        </p>
      </MUI.Typography>

      <MUI.Divider style={{ marginTop: 30 }} />

      <MUI.Typography variant="h5" gutterBottom style={{ marginTop: 25 }}>
        Improvement over Time
      </MUI.Typography>

      <MUI.Typography variant="body1" style={{ maxWidth: 630 }}>
        <p>
          Users improve at an average rate of{' '}
          {(summaryStats.avgRateOfChange * 30).toLocaleString('en-US', {
            maximumFractionDigits: 3,
            minimumFractionDigits: 3,
          })}{' '}
          per month.
        </p>
      </MUI.Typography>

      <PlotByDuration users={users} summaryStats={summaryStats} />

      <MUI.Typography variant="h6" style={{ marginTop: 15 }}>
        By Language
      </MUI.Typography>

      <MUI.Box
        display="grid"
        gridTemplateColumns="repeat(auto-fill, minmax(400px, 1fr))"
        gridGap={15}
        marginTop={2}
      >
        {byLanguage.map(({ language, users, summaryStats }) => (
          <MUI.Paper key={language} variant="outlined">
            <MUI.Box key={language} padding={1}>
              <MUI.Typography
                variant="body2"
                style={{ fontWeight: 500 }}
                gutterBottom
              >
                {language}
              </MUI.Typography>
              <MUI.Typography variant="body1">
                These users improve at an average rate of{' '}
                {(summaryStats.avgRateOfChange! * 30).toLocaleString('en-US', {
                  maximumFractionDigits: 3,
                  minimumFractionDigits: 3,
                })}{' '}
                per month.
              </MUI.Typography>
            </MUI.Box>
            <MUI.Divider />
            <MUI.Box key={language} padding={1}>
              <PlotByDuration
                users={users}
                summaryStats={summaryStats}
                height={320}
              />
            </MUI.Box>
          </MUI.Paper>
        ))}
      </MUI.Box>

      <MUI.Typography variant="h6" style={{ marginTop: 30 }}>
        By Age Group
      </MUI.Typography>

      <MUI.Box
        display="grid"
        gridTemplateColumns="repeat(auto-fill, minmax(400px, 1fr))"
        gridGap={15}
        marginTop={2}
      >
        {byAgeGroup.map(({ ageGroup, users, summaryStats }) => (
          <MUI.Paper key={ageGroup} variant="outlined">
            <MUI.Box key={ageGroup} padding={1}>
              <MUI.Typography
                variant="body2"
                style={{ fontWeight: 500 }}
                gutterBottom
              >
                {ageGroup}
              </MUI.Typography>
              <MUI.Typography variant="body1">
                These users improve at an average rate of{' '}
                {(summaryStats.avgRateOfChange! * 30).toLocaleString('en-US', {
                  maximumFractionDigits: 3,
                  minimumFractionDigits: 3,
                })}{' '}
                per month.
              </MUI.Typography>
            </MUI.Box>
            <MUI.Divider />
            <MUI.Box key={ageGroup} padding={1}>
              <PlotByDuration
                users={users}
                summaryStats={summaryStats}
                height={320}
              />
            </MUI.Box>
          </MUI.Paper>
        ))}
      </MUI.Box>

      <MUI.Divider style={{ marginTop: 30 }} />

      <MUI.Typography variant="h5" gutterBottom style={{ marginTop: 25 }}>
        Improvement by Lessons Completed
      </MUI.Typography>

      <PracticesPlot users={users} />
    </MUI.Box>
  );
};

const SummaryStats = ({ users }: any) => {
  const drawPlot = useCallback(
    ({ width, height }) => {
      return Plot.plot({
        width,
        height,

        marginLeft: 0,
        marginRight: 0,

        marks: [
          Plot.dotX(
            users,
            Plot.dodgeY({
              x: 'change',
              fill: dotFill,
              title: (d) => d,
              tip: { lineWidth: 25, clip: false, render: renderTip },
              clip: true,
            }),
          ),
          Plot.frame({ opacity: 0.2 }),
        ],
      });
    },
    [users],
  );

  return (
    <MUI.Box>
      <PlotContainer drawPlot={drawPlot} style={{ height: 240, flexGrow: 1 }} />
    </MUI.Box>
  );
};

const dotFill = (d) =>
  d.change > -0.004999 && d.change < 0.004999
    ? MUIColors.grey[500]
    : d.change > 0
      ? MUIColors.green[500]
      : MUIColors.red[500];

const PlotByDuration = ({ users, summaryStats, height = 800 }: any) => {
  const drawPlot = useCallback(
    ({ width, height }) => {
      return Plot.plot({
        width,
        height,

        x: {
          domain: [0, 120],
          ticks: Plot.numberInterval(7),
          label: 'days',
        },

        y: {
          domain: [-0.12, 0.12],
        },

        clip: true,
        grid: true,

        marks: [
          Plot.link([0], {
            x1: 0,
            y1: 0,
            x2: 120,
            y2: 120 * summaryStats.avgRateOfChange,
            stroke:
              summaryStats.avgRateOfChange >= 0
                ? MUIColors.green[500]
                : MUIColors.red[500],
            strokeWidth: 2,
            opacity: 0.75,
          }),
          // Plot.text(['Avg. Rate of Change'], {
          //   x: 120,
          //   y: 120 * summaryStats.avgRateOfChange,
          //   // dx: -5,
          //   dy: -10,
          //   textAnchor: 'end',
          //   fontWeight: 500,
          //   fill: MUIColors.green[500],
          // }),
          Plot.dot(users, {
            x: (d) => d.days + decimalHash(d.id) - 0.5,
            y: 'change',
            fill: dotFill,
            opacity: 0.5,
            title: (d) => d,
            tip: { lineWidth: 25, clip: false, render: renderTip },
          }),
          // Plot.linearRegressionY(users, {
          //   x: 'days',
          //   y: 'change',
          //   stroke: 'green',
          // }),
          // Plot.link(
          //   users,
          //   Plot.pointer({
          //     px: (d) => d.days + decimalHash(d.id) - 0.5,
          //     py: 'change',
          //     x1: 0,
          //     y1: 0,
          //     x2: (d) => d.days + decimalHash(d.id) - 0.5,
          //     y2: 'change',
          //     stroke: (d) =>
          //       d.change > 0 ? MUIColors.green[500] : MUIColors.red[500],
          //     opacity: 0.5,
          //   }),
          // ),
        ],
      });
    },
    [users],
  );

  return <PlotContainer drawPlot={drawPlot} style={{ height, flexGrow: 1 }} />;
};

const PracticesPlot = ({ users }) => {
  const drawPlot = useCallback(
    ({ width, height }) => {
      return Plot.plot({
        width,
        height,

        marginLeft: 10,

        x: {
          domain: [0, 120],
          label: 'days',
          // labelArrow: true,
          // labelAnchor: 'right',
        },

        y: {
          type: 'pow',
          exponent: 0.8,
          // reverse: true,
          label: 'lessons completed',
          // labelArrow: true,
          // labelAnchor: 'center',
          // tickFormat: (d) => `${d}k`,
        },

        color: {
          scheme: 'RdYlGn',
          legend: true,
          domain: [-0.1, 0.1],
        },

        clip: true,
        // grid: true,

        marks: [
          Plot.rect(
            users,
            Plot.bin(
              {
                fill: (d) => d3.mean(d, (d: any) => d.change),
                opacity: () => 0.5,
              },
              {
                x: { interval: 3, value: 'days' },
                y: {
                  thresholds: Array.from(
                    { length: 100 },
                    (_, i) => (i * 5) ** (1 / 0.8),
                  ),
                  value: 'completedCurriculumItemsCount',
                },
              },
            ),
          ),
          // Plot.crosshair(
          //   users,
          //   Plot.pointer({
          //     x: (d) => d.days + decimalHash(d.id) - 0.5,
          //     y: 'completedCurriculumItemsCount',
          //   }),
          // ),
          Plot.dot(users, {
            x: (d) => d.days + decimalHash(d.id) - 0.5,
            y: (d) => d.completedCurriculumItemsCount + decimalHash(d.id) - 0.5,
            fill: 'change',
            title: (d) => d,
            tip: { lineWidth: 25, clip: false, render: renderTip },
            r: 2,
            strokeWidth: 0.5,
            stroke: 'black',
          }),
        ],
      });
    },
    [users],
  );

  return <PlotContainer drawPlot={drawPlot} style={{ height: 1000 }} />;
};

const changeFormatter = Intl.NumberFormat('en-US', {
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
  signDisplay: 'exceptZero',
});

const renderTip = (index, scales, values, dimensions, context, next) => {
  const g = next(index, scales, values, dimensions, context);
  const [i] = index;
  if (i === undefined) return g;

  // clear the default channel based tip content to make way for below
  const text = d3.select(g).select(`g[aria-label="tip"] text`).html('');

  const d = values.title[i];

  text
    .selectAll('tspan')
    .data(
      `${d.firstName} ${d.lastName}
${d.languages?.[0]?.englishName}

${changeFormatter.format(d.change)} over ${d.days} days and ${d.completedCurriculumItemsCount} lessons
`
        .split('\n')
        .map((l) => (l === '' ? '\u200b' : l)),
    )
    .join('tspan')
    .attr('x', 0)
    .attr('dy', '1em')
    .text((d) => d);

  text
    .append('tspan')
    .attr('x', 0)
    .attr('dy', '1em')
    .append('a')
    .text(`View Details`)
    .attr('fill', MUIColors.indigo[500])
    // .attr('style', 'text-decoration: underline;')
    .attr('target', '_blank')
    .attr('xlink:href', `/#/skill-assessments?userID=${d.id}`);

  return g;
};

const decimalHash = (string) => {
  let sum = 0;
  for (let i = 0; i < string.length; i++)
    sum += ((i + 1) * string.codePointAt(i)) / (1 << 8);
  return sum % 1;
};

const computeSummaryStats = (users) => ({
  count: users.length,
  countOnLegacy: users.filter((u) => !u.hasUserJourney).length,
  avgDays: d3.mean(users.map((u) => u.days)),
  avgChange: d3.mean(users.map((u) => u.change)),
  avgRateOfChange: d3.mean(
    users.filter((u) => u.days >= 30).map((u) => u.change / u.days),
  ),
  // avgRateOfChangeByPractices: d3.mean(
  //   users
  //     .filter((u) => u.audioLogsCount >= 30)
  //     .map((u) => u.change / u.audioLogsCount),
  // ),
  avgRateOfChangeByCurriculumItems: d3.mean(
    users
      .filter((u) => u.completedCurriculumItemsCount >= 1)
      .map((u) => u.change / u.completedCurriculumItemsCount),
  ),
});

export default ImprovementStatsWrapper;
