import * as React from 'react';
import { FC } from 'react';
import { /* Link, */ FieldProps } from 'react-admin';

import CurriculumNameField from './CurriculumNameField';

const CurriculumLinkField: FC<FieldProps> = (props) =>
  props.record ? <CurriculumNameField {...props} /> : null;

CurriculumLinkField.defaultProps = {
  source: 'curriculumId',
  label: 'Curriculum',
  addLabel: true,
};

export default CurriculumLinkField;
