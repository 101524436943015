import React, { useContext } from 'react';
import { TextField, Input } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import { InVideoFormContext } from './InVideoFormProvider';

const useStyles = makeStyles({
  image: { maxWidth: 500 },
  inputField: {
    marginTop: 20,
  },
});

export default function ImageCreationForm() {
  const { data, setData } = useContext(InVideoFormContext);

  const classes = useStyles();

  const updateInternalData = (e: any) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <div style={{ marginTop: '20px' }}>
        <Input
          type="file"
          name="image"
          title="Image"
          style={{
            marginTop: 20,
          }}
          aria-label="Image"
          onChange={(e: any) => {
            setData({ ...data, src: e.target.files[0] });
          }}
        />
      </div>

      <TextField
        type="text"
        required
        label="Title"
        name="title"
        id="image-creation-title"
        className={classes.inputField}
        onChange={updateInternalData}
      />
      <TextField
        type="text"
        label="Subtitle"
        name="textSubtitle"
        id="image-creation-textSubtitle"
        className={classes.inputField}
        onChange={updateInternalData}
      />

      <TextField
        type="text"
        label="Photo Group Name"
        name="groupName"
        id="image-creation-groupName"
        className={classes.inputField}
        onChange={updateInternalData}
      />
      <TextField
        type="text"
        label="Group integer"
        name="groupOrder"
        id="image-creation-groupOrder"
        className={classes.inputField}
        onChange={updateInternalData}
      />
      <TextField
        type="text"
        label="phonemeId"
        name="phonemeId"
        id="image-creation-phonemeId"
        className={classes.inputField}
        onChange={updateInternalData}
      />
    </div>
  );
}
