import * as React from 'react';
import { FC } from 'react';
import { Link, FieldProps } from 'react-admin';

const MultipleChoiceLinkField: FC<FieldProps> = (props) =>
  props.record ? (
    <Link to={`/content/multiplechoice/${props.record?.multipleChoiceId}`}>
      <div>
        {props.record?.multipleChoiceId
          ? props.record?.MultipleChoice?.prompt
          : ''}
      </div>
    </Link>
  ) : null;

MultipleChoiceLinkField.defaultProps = {
  source: 'multipleChoiceId',
  label: 'Multiple Choice',
  addLabel: true,
};

export default MultipleChoiceLinkField;
