import * as MUI from '@material-ui/core';
import { RadioButtonGroupInput, SelectInput, TextInput } from 'react-admin';

import { AudioUploadInput } from '../components/AudioUpload';
import { MultipleChoicePromptInput } from '../components/MultipleChoicePromptSelect';

const MultipleChoiceQuestionFormFields = ({ autogenerated }: any) => (
  <>
    <TextInput
      autoFocus
      source="internalName"
      label="Internal Name"
      helperText={false}
      disabled={autogenerated}
      style={{ width: 600 }}
    />

    <MultipleChoicePromptInput
      source="multipleChoicePromptID"
      label="Prompt"
      isRequired
    />

    <TextInput
      autoFocus
      source="text"
      label="Question Text"
      fullWidth
      helperText={false}
      disabled={autogenerated}
    />

    <AudioUploadInput source="audioUrl" label="Question Audio" />

    <MUI.Box marginTop={1}>
      <SelectInput
        source="optionsType"
        label="Options Type"
        choices={[
          { id: 'READ', name: 'Read' },
          { id: 'LISTEN', name: 'Listen' },
          { id: 'READ_AND_LISTEN', name: 'Read and Listen' },
        ]}
        style={{ width: 600 }}
      />
    </MUI.Box>

    <RadioButtonGroupInput
      label="Status"
      source="active"
      helperText="If inactive, won't be included in future lesson plans, but will remain in already generated plans."
      choices={[
        {
          id: false,
          name: 'Inactive',
        },
        {
          id: true,
          name: 'Active',
        },
      ]}
    />
  </>
);

export default MultipleChoiceQuestionFormFields;
