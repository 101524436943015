import * as React from 'react';
import { FC } from 'react';
import { FieldProps } from 'react-admin';
import { Link } from '@material-ui/core';

const LessonLinkField: FC<FieldProps> = (props) =>
  props?.record?.muse_video_id ? (
    <Link
      target="_blank"
      href={`https://muse.ai/e/${props?.record?.muse_video_id}`}
    >
      {props?.record?.muse_video_id}
    </Link>
  ) : null;

LessonLinkField.defaultProps = {
  label: 'Manage Link',
  addLabel: true,
};

export default LessonLinkField;
