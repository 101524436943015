import * as React from 'react';
import { Route } from 'react-router-dom';

import DLPExplorer from './dlp/DLPExplorer';
import DLPPlayground from './dlp/DLPPlayground';
import ImprovementStats from './dlp/ImprovementStats';
import SkillAssessments from './dlp/SkillAssessments';
import JobQueues from './jobs/Queues';
import PhoneticSearch from './phoneticSearch/PhoneticSearch';
import SkillPathsDisplay from './skills/SkillPathsDisplay/SkillPathsDisplay';
import SkillsTextScan from './skills/SkillsTextScan';

export default [
  <Route exact path="/curriculum-playground" component={DLPPlayground} />,
  <Route exact path="/curriculum-explorer" component={DLPExplorer} />,
  <Route exact path="/skill-paths-display" component={SkillPathsDisplay} />,
  <Route exact path="/skills-text-scan" component={SkillsTextScan} />,
  <Route exact path="/phonetic-search" component={PhoneticSearch} />,
  <Route exact path="/skill-assessments" component={SkillAssessments} />,
  <Route exact path="/improvement-stats" component={ImprovementStats} />,
  <Route exact path="/job-queues" component={JobQueues} />,
];
