import * as React from 'react';
import { FC } from 'react';
import { Link, FieldProps } from 'react-admin';

import PracticeSetNameField from './PracticeSetNameField';

const PracticeSetLinkField: FC<FieldProps> = (props) => {
  const practiceSetId = props.record?.practiceSetId;
  const lessonId = props.record?.lessonId;

  return practiceSetId ? (
    <Link to={`/content/lesson/${lessonId}`}>
      <PracticeSetNameField {...props} />
    </Link>
  ) : null;
};

PracticeSetLinkField.defaultProps = {
  label: 'Practice Set',
  addLabel: true,
};

export default PracticeSetLinkField;
