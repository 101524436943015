import * as MUI from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import graphql from 'babel-plugin-relay/macro';
import _ from 'lodash';
import React from 'react';
import { useLazyLoadQuery, useRefetchableFragment } from 'react-relay';

import LittlePracticeItemCard from '../skills/SkillEdit/LittlePracticeItemCard';

const PracticesSelect = (props: any) => (
  <React.Suspense
    fallback={
      <PracticesSelectField
        {...props}
        isLoading
        selectedPractices={[]}
        practices={[]}
      />
    }
  >
    <PracticesSelectWithData {...props} />
  </React.Suspense>
);

const PracticesSelectWithData = (props: any) => {
  const [_isPending, startTransition] = React.useTransition();

  const initiallySelectedIDs = React.useMemo(() => props.value ?? [], []);

  const data: any = useLazyLoadQuery(
    graphql`
      query PracticesSelect_Query($initiallySelectedIDs: [ID!]!) {
        ...PracticesSelect_practicesForString
        ...PracticesSelect_practicesByIDs @arguments(ids: $initiallySelectedIDs)
      }
    `,
    { initiallySelectedIDs },
    {},
  );

  const [searchedData, refetch] = useRefetchableFragment(
    graphql`
      fragment PracticesSelect_practicesForString on Query
      @refetchable(queryName: "PracticesSelect_practicesForString_RefetchQuery")
      @argumentDefinitions(search: { type: "String", defaultValue: "" }) {
        practiceTextsForString(search: $search, first: 20) {
          nodes {
            id
            text
            coachSample
            aiCoachSample
          }
        }
      }
    `,
    data,
  );

  const refetchSearch = React.useCallback(
    (search: string) => {
      refetch({ search }, { fetchPolicy: 'network-only' });
    },
    [refetch],
  );

  const [selectedData, refetchSelected] = useRefetchableFragment(
    graphql`
      fragment PracticesSelect_practicesByIDs on Query
      @refetchable(queryName: "PracticesSelect_practicesByIDs_RefetchQuery")
      @argumentDefinitions(ids: { type: "[ID!]", defaultValue: [] }) {
        practiceTextsByIDs(ids: $ids) {
          id
          text
          coachSample
          aiCoachSample
          isSentence
        }
      }
    `,
    data,
  );

  React.useEffect(() => {
    startTransition(() => {
      refetchSelected({ ids: props.value }, { fetchPolicy: 'network-only' });
    });
  }, [props.value, refetchSelected, startTransition]);

  const [sortedSelectedPracticeWords, sortedSelectedPracticeSentences] =
    React.useMemo(() => {
      const { true: sentences, false: words } = _.groupBy(
        [...selectedData.practiceTextsByIDs].sort((a: any, b: any) =>
          a.text.localeCompare(b.text),
        ),
        'isSentence',
      );

      return [words ?? [], sentences ?? []];
    }, [selectedData.practiceTextsByIDs]);

  return (
    <>
      <PracticesSelectField
        {...props}
        practices={searchedData.practiceTextsForString.nodes}
        selectedPractices={selectedData.practiceTextsByIDs}
        refetchSearch={refetchSearch}
      />

      {!(props.disabled && sortedSelectedPracticeWords.length === 0) && (
        <MUI.Box marginTop={1}>
          <MUI.Typography variant="body2" color="textSecondary">
            {sortedSelectedPracticeWords.length}{' '}
            {`practice${sortedSelectedPracticeWords.length === 1 ? '' : 's'}`}{' '}
            (A-Z)
          </MUI.Typography>

          <MUI.Box marginTop={1} style={{ columnWidth: 250, columnGap: 16 }}>
            {sortedSelectedPracticeWords?.map((practice: any) => (
              <LittlePracticeItemCard
                practice={practice}
                showRemove
                onChange={props.onChange}
                value={props.value}
              />
            ))}
          </MUI.Box>
        </MUI.Box>
      )}

      {!(sortedSelectedPracticeSentences.length === 0) && (
        <MUI.Box marginTop={2}>
          <MUI.Typography variant="body2" color="textSecondary">
            {sortedSelectedPracticeSentences.length}{' '}
            {`practice sentence${sortedSelectedPracticeSentences.length === 1 ? '' : 's'}`}{' '}
            (A-Z)
          </MUI.Typography>

          <MUI.Box marginTop={1} style={{ columnWidth: 500, columnGap: 16 }}>
            {sortedSelectedPracticeSentences?.map((practice: any) => (
              <LittlePracticeItemCard
                practice={practice}
                showRemove
                onChange={props.onChange}
                value={props.value}
              />
            ))}
          </MUI.Box>
        </MUI.Box>
      )}
    </>
  );
};

const PracticesSelectField = ({
  practices,
  selectedPractices,
  refetchSearch,
  onChange,
  isLoading,
  value,
  disabled,
}: any) => {
  const [_isPending, startTransition] = React.useTransition();

  const practicesByID = React.useMemo(
    () =>
      Object.fromEntries(
        [...practices, ...selectedPractices].map((practice: any) => [
          practice.id,
          practice,
        ]),
      ),
    [practices, selectedPractices],
  );

  const practiceIDs = React.useMemo(
    () => practices.map((practice: any) => practice.id),
    [practices],
  );

  const onInputChange = (e: any, value: any) => {
    startTransition(() => {
      refetchSearch(value);
    });
  };

  return (
    <Autocomplete
      multiple
      autoHighlight
      blurOnSelect={false}
      options={practiceIDs}
      filterOptions={(x) => x}
      renderTags={() => null}
      closeIcon={null}
      getOptionLabel={(practiceID: any) =>
        practicesByID[practiceID]?.text || 'Invalid Practice'
      }
      style={{ width: 600 }}
      // debug
      size="small"
      selectOnFocus
      loading={isLoading}
      renderOption={(practiceID: any) => {
        const practice = practicesByID[practiceID];
        return (
          <MUI.Box display="flex" flexDirection="column">
            {practice.text}
            <MUI.Box component="span" color="text.secondary" fontSize={12}>
              {practice.id}
            </MUI.Box>
          </MUI.Box>
        );
      }}
      renderInput={(params) => (
        <MUI.TextField
          {...params}
          variant="filled"
          margin="normal"
          label="Search for practices"
        />
      )}
      onInputChange={onInputChange}
      onChange={(e, value, reason) => {
        if (reason !== 'remove-option') {
          onChange?.(value);
        }
      }}
      value={value}
      disabled={disabled}
    />
  );
};

export default PracticesSelect;
