// @ts-nocheck

import React from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import styled from 'styled-components';

const ListItem = styled.div<StyledDivProps>`
  padding: 8px;
  margin: 8px;
  border: 1px solid grey;
  background-color: ${(props) => (props.isDragging ? 'lightgrey' : 'white')};
  border-radius: 16px;
`;

const List = styled.div<StyledDivProps>`
  padding: 8px;
  border: 1px solid grey;
  background-color: ${(props) => (props.isDraggingOver ? '#2097F3' : 'white')};
`;

const Title = styled.div`
  font-weight: bold;
  font-size: 1.2rem;
  padding: 8px;
`;

interface StyledDivProps {
  isDragging?: boolean;
  isDraggingOver?: boolean;
}

const identity = (v: any) => v;

const OrderedArrayInput = (props: any) => {
  const {
    id,
    choices,
    input,
    label,
    elementLinkUrl,
    customValueRenderer,
  } = props;
  const { value: values } = input;

  const children = React.cloneElement(props.children, props);

  const selectedChoices = (values || [])
    .map((v: any) => choices.find((c: any) => c.id === v))
    .filter(identity);

  const onDragEnd = (result: any) => {
    const { destination, source, draggableId } = result;

    if (!destination || destination.index === source.index) return;

    const newValues = Array.from(values);
    newValues.splice(source.index, 1);
    newValues.splice(destination.index, 0, draggableId);

    input.onChange(newValues);
  };

  return (
    <div>
      {children}
      {selectedChoices.length ? (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId={id}>
            {(provided, snapshot) => (
              <List
                component="nav"
                ref={provided.innerRef}
                isDraggingOver={snapshot.isDraggingOver}
                {...provided.droppableProps}
              >
                <Title>{label} Order</Title>
                {selectedChoices.map((v, i) => (
                  <ChoiceItem
                    value={v}
                    index={i}
                    key={v.id}
                    customValueRenderer={customValueRenderer}
                    elementLinkUrl={elementLinkUrl}
                  />
                ))}

                {provided.placeholder}
              </List>
            )}
          </Droppable>
        </DragDropContext>
      ) : null}
    </div>
  );
};

const InnerValue = ({ value }) => (
  <b>
    {value?.Curriculum?.name ? `${value?.Curriculum?.name} - ` : ``}
    {value.name || value.word || value.text || value.imageUrl || (
      <i> &quot;DELETED&quot; </i>
    )}{' '}
    {value.tags ? `- ${value.tags}` : ``}
  </b>
);

const ChoiceItem = (props) => {
  const { value, index, elementLinkUrl, customValueRenderer } = props;

  return (
    <Draggable draggableId={value.id} index={index}>
      {(provided, snapshot) => (
        <ListItem
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          isDragging={snapshot.isDragging}
        >
          {customValueRenderer ? (
            customValueRenderer(value, index)
          ) : (
            <>
              #{index + 1} -{' '}
              {elementLinkUrl ? (
                <a href={`${elementLinkUrl}${value.id}`} target="_blank">
                  <InnerValue value={value} />
                </a>
              ) : (
                <InnerValue value={value} />
              )}
            </>
          )}
        </ListItem>
      )}
    </Draggable>
  );
};

export default OrderedArrayInput;
