import * as React from 'react';
import { FC } from 'react';
import {
  Create,
  CreateProps,
  SimpleForm,
  TextInput,
  required,
  ImageInput,
  ImageField,
} from 'react-admin';

import { makeStyles, Theme } from '@material-ui/core/styles';
import { Styles } from '@material-ui/styles/withStyles';
import { Box } from '@material-ui/core';

export const styles: Styles<Theme, any> = {
  name: { display: 'inline-block' },
  photo: { display: 'inline-block' },
};

const useStyles = makeStyles(styles);

const Separator = () => <Box pt="1em" />;

const UserCreate: FC<CreateProps> = (props) => {
  const classes = useStyles(props);

  return (
    <Create {...props}>
      <SimpleForm autoComplete="off">
        <TextInput
          autoFocus
          source="name"
          label="Curriculum Name"
          className={classes.name}
          validate={requiredValidate}
          fullWidth
        />
        <Separator />
        <ImageInput
          source="coverPhoto"
          label="Cover Photo"
          accept="image/*"
          formClassName={classes.photo}
        >
          <ImageField source="src" title="Cover Photo" />
        </ImageInput>
      </SimpleForm>
    </Create>
  );
};

const requiredValidate = [required()];

export default UserCreate;
