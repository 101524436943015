import React, { useState } from 'react';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Typography,
  TextField,
  Select,
  MenuItem,
} from '@material-ui/core';
import { apiURL } from '../api';

export default function MockAdCard({ variant }: { variant: 'ig' | 'adwords' }) {
  const [siteSourceName, setSiteSourceName] = useState(variant);
  const [campaignName, setCampaignName] = useState('');
  const [adsetId, setAdsetId] = useState('');
  const [adName, setAdName] = useState('');
  const [adId, setAdId] = useState('');
  const [adDestination, setAdDestination] = useState('store');

  const getQueryString = () => {
    let queryString = '';
    if (variant === 'ig') {
      queryString += `site_source_name=${siteSourceName}&`;
      if (campaignName !== '') {
        queryString += `campaign_name=${campaignName}&`;
      }
      if (adsetId !== '') {
        queryString += `adset_id=${adsetId}&`;
      }
      if (adName !== '') {
        queryString += `ad_name=${adName}&`;
      }
      if (adId !== '') {
        queryString += `ad_id=${adId}&`;
      }
    } else if (variant === 'adwords') {
      queryString += `utm_source=${siteSourceName}&`;
      if (campaignName !== '') {
        queryString += `utm_campaign=${campaignName}&`;
      }
      if (adsetId !== '') {
        queryString += `utm_medium=${adsetId}&`;
      }
      if (adName !== '') {
        queryString += `adName=${adName}&`;
      }
    }
    return queryString.slice(0, -1);
  };

  const handleButtonClick = () => {
    const queryString = getQueryString();
    console.log(queryString);
    window.open(`${apiURL}/api/v1/auth/welcome?${queryString}`);
  };

  let headerName = '';
  switch (variant) {
    case 'ig':
      headerName = 'Instagram';
      break;
    case 'adwords':
      headerName = 'Adwords';
      break;
    default:
      break;
  }

  return (
    <Card
      style={{
        maxWidth: '500px',
      }}
    >
      <CardContent>
        <Typography gutterBottom>{headerName}</Typography>
        <img
          style={{
            borderRadius: '1rem',
          }}
          alt=""
          src={`https://images.placeholders.dev/?width=275&height=100&text=${headerName}&bgColor=%23${
            variant === 'ig' ? 'fbad50' : '0F9D58'
          }&textColor=%23ffffff`}
        />
        <Typography variant="subtitle2">Target Enpoint:</Typography>
        <Typography
          variant="body2"
          style={{
            wordBreak: 'break-all',
          }}
        >
          {`${apiURL}/api/v1/auth/welcome?${getQueryString()}`}
        </Typography>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            marginTop: '1rem',
          }}
        >
          <TextField
            label="Platform Name"
            value={siteSourceName}
            disabled
            // @ts-ignore
            onChange={(e) => setSiteSourceName(e.target.value as string)}
          />
          <TextField
            label="Campaign Name"
            value={campaignName}
            onChange={(e) => setCampaignName(e.target.value)}
          />
          <TextField
            label="Adset ID"
            value={adsetId}
            onChange={(e) => setAdsetId(e.target.value)}
          />
          <TextField
            label="Ad Name"
            value={adName}
            onChange={(e) => setAdName(e.target.value)}
          />
          <TextField
            label="Ad ID"
            value={adId}
            onChange={(e) => setAdId(e.target.value)}
          />
          <Typography
            variant="body2"
            style={{
              marginTop: '1rem',
            }}
          >
            Ad Destination: {adDestination}
          </Typography>
          <Select
            value={adDestination}
            onChange={(e) => setAdDestination(e.target.value as string)}
            label="Ad Destination"
          >
            <MenuItem value="app store">App Store</MenuItem>
            <MenuItem value="web">Web</MenuItem>
          </Select>
        </div>
      </CardContent>
      <CardActions>
        <Button size="small" variant="contained" onClick={handleButtonClick}>
          Simulate Ad Click
        </Button>
      </CardActions>
    </Card>
  );
}
