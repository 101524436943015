/**
 * @generated SignedSource<<63e49e2c096c68da1203c9f635d13884>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DLPPlayground_MergedSkills_playgroundCurriculum$data = {
  readonly mergedSkills: ReadonlyArray<{
    readonly skill: {
      readonly id: string | null | undefined;
    } | null | undefined;
    readonly " $fragmentSpreads": FragmentRefs<"DLPPlayground_userSkill">;
  }> | null | undefined;
  readonly " $fragmentType": "DLPPlayground_MergedSkills_playgroundCurriculum";
};
export type DLPPlayground_MergedSkills_playgroundCurriculum$key = {
  readonly " $data"?: DLPPlayground_MergedSkills_playgroundCurriculum$data;
  readonly " $fragmentSpreads": FragmentRefs<"DLPPlayground_MergedSkills_playgroundCurriculum">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DLPPlayground_MergedSkills_playgroundCurriculum",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "DLPPlaygroundUserSkill",
      "kind": "LinkedField",
      "name": "mergedSkills",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Skill",
          "kind": "LinkedField",
          "name": "skill",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "id",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "DLPPlayground_userSkill"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "DLPPlaygroundResult",
  "abstractKey": null
};

(node as any).hash = "7703a7f325ff0abf2fe8fcf2d0b791e2";

export default node;
