import * as MUI from '@material-ui/core';
import { Box } from '@material-ui/core';
import React from 'react';
import {
  BooleanInput,
  NumberInput,
  RadioButtonGroupInput,
  TextInput,
} from 'react-admin';

const SkillFormFields = ({ autogenerated }: any) => (
  <>
    <TextInput
      autoFocus
      source="name"
      label="Name"
      fullWidth
      helperText={false}
      disabled={autogenerated}
    />

    <Box style={{ marginBottom: 10, width: '100%' }}>
      <TextInput
        source="rules"
        label="Rules"
        fullWidth
        multiline
        helperText={false}
        disabled={autogenerated}
      />
    </Box>

    <Box
      style={{
        marginBottom: 10,
        width: '100%',
        borderColor: 'lightgray',
        borderTopWidth: 1,
        borderBottomWidth: 1,
        borderLeftWidth: 0,
        borderRightWidth: 0,
        borderStyle: 'solid',
        paddingTop: 5,
        paddingBottom: 5,
      }}
    >
      <RadioButtonGroupInput
        label="Status"
        source="active"
        helperText="If inactive, skill won't be included in future lesson plans, but will remain in already generated plans."
        choices={[
          {
            id: false,
            name: 'Inactive',
          },
          {
            id: true,
            name: 'Active',
          },
        ]}
      />
    </Box>

    <MUI.Typography variant="h6">Metadata</MUI.Typography>
    <Box
      style={{ marginBottom: 10, width: '100%' }}
      display="flex"
      flexDirection="row"
      alignItems="center"
    >
      <Box flex={1}>
        <BooleanInput
          source="quickWin"
          label="Is a quick win?"
          helperText={false}
        />

        <BooleanInput
          source="speechaceCanScoreSkillWell"
          label="Speechace scores skill well?"
          helperText={false}
        />
      </Box>
      <Box flex={1}>
        <NumberInput
          min={1}
          max={5}
          step={1}
          source="importance"
          label="Importance (1 highest - 5 lowest)"
          fullWidth
          helperText={false}
        />

        <TextInput
          source="languages"
          label="Languages most likely to experience"
          fullWidth
          helperText={false}
        />
      </Box>
    </Box>

    <Box style={{ marginBottom: 10, width: '100%' }}>
      <TextInput
        source="internalNotes"
        label="Internal Notes"
        fullWidth
        multiline
        helperText={false}
      />
    </Box>
  </>
);

export default SkillFormFields;
