import FaceIcon from '@material-ui/icons/Face';

import CoachList from './CoachList';
import CoachEdit from './CoachEdit';
import CoachCreate from './CoachCreate';

export default {
  list: CoachList,
  edit: CoachEdit,
  icon: FaceIcon,
  create: CoachCreate,
};
