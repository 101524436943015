/**
 * @generated SignedSource<<59740324744e8d66a8aa591ecdf29ce8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type WordEdit_Query$variables = {
  id?: string | null | undefined;
};
export type WordEdit_Query$data = {
  readonly raWord: {
    readonly " $fragmentSpreads": FragmentRefs<"WordEdit_word">;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"WordEdit_SourceEdge_query">;
};
export type WordEdit_Query = {
  response: WordEdit_Query$data;
  variables: WordEdit_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "nodeID",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "PartOfSpeech",
  "kind": "LinkedField",
  "name": "partsOfSpeech",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "tag",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "WordEdit_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Word",
        "kind": "LinkedField",
        "name": "raWord",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "WordEdit_word"
          }
        ],
        "storageKey": null
      },
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "WordEdit_SourceEdge_query"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "WordEdit_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Word",
        "kind": "LinkedField",
        "name": "raWord",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "text",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Pronunciation",
            "kind": "LinkedField",
            "name": "pronunciations",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "active",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "default",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "bvIPAUnsyllabified",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "bvIPA",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "form",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PronunciationPronunciationSource",
                "kind": "LinkedField",
                "name": "sourceEdges",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PronunciationSources",
                    "kind": "LinkedField",
                    "name": "pronunciationSource",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      },
                      (v3/*: any*/),
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "original",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "sourceDefault",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "removed",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "autoRemoved",
                    "storageKey": null
                  },
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Pronunciation",
                    "kind": "LinkedField",
                    "name": "pronunciation",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      },
      (v4/*: any*/)
    ]
  },
  "params": {
    "cacheID": "21f3c5fa8e58042f2a6f66033adc5a56",
    "id": null,
    "metadata": {},
    "name": "WordEdit_Query",
    "operationKind": "query",
    "text": "query WordEdit_Query(\n  $id: ID\n) {\n  raWord(id: $id) {\n    ...WordEdit_word\n    nodeID\n  }\n  ...WordEdit_SourceEdge_query\n}\n\nfragment WordEdit_Pronunciation on Pronunciation {\n  id\n  bvIPAUnsyllabified\n  bvIPA\n  active\n  default\n  form\n  sourceEdges {\n    id\n    pronunciationSource {\n      name\n      nodeID\n    }\n    ...WordEdit_SourceEdge\n    nodeID\n  }\n}\n\nfragment WordEdit_SourceEdge on PronunciationPronunciationSource {\n  id\n  original\n  sourceDefault\n  removed\n  autoRemoved\n  partsOfSpeech {\n    tag\n    description\n  }\n  pronunciation {\n    id\n    nodeID\n  }\n  pronunciationSource {\n    id\n    name\n    nodeID\n  }\n}\n\nfragment WordEdit_SourceEdge_query on Query {\n  partsOfSpeech {\n    tag\n    description\n  }\n}\n\nfragment WordEdit_word on Word {\n  text\n  pronunciations {\n    id\n    active\n    default\n    ...WordEdit_Pronunciation\n    nodeID\n  }\n}\n"
  }
};
})();

(node as any).hash = "8001eee574a57f909234f53a3ab4eed1";

export default node;
