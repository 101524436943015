/**
 * @generated SignedSource<<2807f38274013d4f93129bcfe5cec1b4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Queues_Queue$data = {
  readonly active: boolean | null | undefined;
  readonly failedJobs: ReadonlyArray<{
    readonly endedAt: any | null | undefined;
    readonly enqueuedAt: any | null | undefined;
    readonly id: string | null | undefined;
    readonly startedAt: any | null | undefined;
    readonly status: string | null | undefined;
  }> | null | undefined;
  readonly name: string | null | undefined;
  readonly recentJobs: ReadonlyArray<{
    readonly endedAt: any | null | undefined;
    readonly enqueuedAt: any | null | undefined;
    readonly id: string | null | undefined;
    readonly startedAt: any | null | undefined;
    readonly status: string | null | undefined;
  }> | null | undefined;
  readonly " $fragmentType": "Queues_Queue";
};
export type Queues_Queue$key = {
  readonly " $data"?: Queues_Queue$data;
  readonly " $fragmentSpreads": FragmentRefs<"Queues_Queue">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "id",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "status",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "enqueuedAt",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "startedAt",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "endedAt",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Queues_Queue",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "active",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SystemJob",
      "kind": "LinkedField",
      "name": "recentJobs",
      "plural": true,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SystemJob",
      "kind": "LinkedField",
      "name": "failedJobs",
      "plural": true,
      "selections": (v0/*: any*/),
      "storageKey": null
    }
  ],
  "type": "SystemJobQueue",
  "abstractKey": null
};
})();

(node as any).hash = "a5bb7948307a29ec71f05b43e44d3ca5";

export default node;
