import * as React from 'react';
import { List, Datagrid, DateField, TextInput, Filter } from 'react-admin';
import UserLinkField from './UserLinkField';
import ContactUsFeedbackShow from './ContactUsFeedbackShow';

const ContactUsFeedbackFilter = (props: any) => (
  <Filter {...props}>
    <TextInput
      label="User First Name"
      source="User.firstName"
      defaultValue=""
    />
    <TextInput label="User Last Name" source="User.lastName" defaultValue="" />
  </Filter>
);

export const ContactUsFeedbackList = (props: any) => (
  <List
    {...props}
    perPage={10}
    sort={{ field: 'createdAt', order: 'DESC' }}
    filters={<ContactUsFeedbackFilter />}
  >
    <Datagrid rowClick="expand" expand={<ContactUsFeedbackShow />}>
      <UserLinkField />
      <DateField showTime source="createdAt" label="Date" />
    </Datagrid>
  </List>
);

export default ContactUsFeedbackList;
