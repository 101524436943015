/**
 * @generated SignedSource<<ebbd065bdfe36d22ed755eab88b803f3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SkillsSelect_skillsForString_RefetchQuery$variables = {
  search?: string | null | undefined;
};
export type SkillsSelect_skillsForString_RefetchQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"SupersedingSkillsSelect_skillsForString">;
};
export type SkillsSelect_skillsForString_RefetchQuery = {
  response: SkillsSelect_skillsForString_RefetchQuery$data;
  variables: SkillsSelect_skillsForString_RefetchQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": "",
    "kind": "LocalArgument",
    "name": "search"
  }
],
v1 = {
  "kind": "Variable",
  "name": "search",
  "variableName": "search"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SkillsSelect_skillsForString_RefetchQuery",
    "selections": [
      {
        "args": [
          (v1/*: any*/)
        ],
        "kind": "FragmentSpread",
        "name": "SupersedingSkillsSelect_skillsForString"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SkillsSelect_skillsForString_RefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Literal",
            "name": "first",
            "value": 20
          },
          (v1/*: any*/)
        ],
        "concreteType": "QuerySkillsForStringConnection",
        "kind": "LinkedField",
        "name": "skillsForString",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Skill",
            "kind": "LinkedField",
            "name": "nodes",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "nodeID",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e5f07f9a671bace40a394b1d38fa4308",
    "id": null,
    "metadata": {},
    "name": "SkillsSelect_skillsForString_RefetchQuery",
    "operationKind": "query",
    "text": "query SkillsSelect_skillsForString_RefetchQuery(\n  $search: String = \"\"\n) {\n  ...SupersedingSkillsSelect_skillsForString_40zwac\n}\n\nfragment SupersedingSkillsSelect_skillsForString_40zwac on Query {\n  skillsForString(search: $search, first: 20) {\n    nodes {\n      id\n      name\n      nodeID\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "b28c679ed751741fb79f7441fa6a3225";

export default node;
