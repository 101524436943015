/**
 * @generated SignedSource<<029cc81c2215cdc9852d95216e20f8f9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MultipleChoicePromptSelect_multipleChoicePromptsByIDs_RefetchQuery$variables = {
  ids?: ReadonlyArray<string> | null | undefined;
};
export type MultipleChoicePromptSelect_multipleChoicePromptsByIDs_RefetchQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"MultipleChoicePromptSelect_multipleChoicePromptsByIDs">;
};
export type MultipleChoicePromptSelect_multipleChoicePromptsByIDs_RefetchQuery = {
  response: MultipleChoicePromptSelect_multipleChoicePromptsByIDs_RefetchQuery$data;
  variables: MultipleChoicePromptSelect_multipleChoicePromptsByIDs_RefetchQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": ([]/*: any*/),
    "kind": "LocalArgument",
    "name": "ids"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "ids",
    "variableName": "ids"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "MultipleChoicePromptSelect_multipleChoicePromptsByIDs_RefetchQuery",
    "selections": [
      {
        "args": (v1/*: any*/),
        "kind": "FragmentSpread",
        "name": "MultipleChoicePromptSelect_multipleChoicePromptsByIDs"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "MultipleChoicePromptSelect_multipleChoicePromptsByIDs_RefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "MultipleChoicePrompt",
        "kind": "LinkedField",
        "name": "multipleChoicePromptsByIDs",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "text",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "nodeID",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "6948773646de9e3fcdd1ad2d81af2134",
    "id": null,
    "metadata": {},
    "name": "MultipleChoicePromptSelect_multipleChoicePromptsByIDs_RefetchQuery",
    "operationKind": "query",
    "text": "query MultipleChoicePromptSelect_multipleChoicePromptsByIDs_RefetchQuery(\n  $ids: [ID!] = []\n) {\n  ...MultipleChoicePromptSelect_multipleChoicePromptsByIDs_3pY7A9\n}\n\nfragment MultipleChoicePromptSelect_multipleChoicePromptsByIDs_3pY7A9 on Query {\n  multipleChoicePromptsByIDs(ids: $ids) {\n    id\n    text\n    nodeID\n  }\n}\n"
  }
};
})();

(node as any).hash = "aaabba48581a12b9c521e12487af8963";

export default node;
