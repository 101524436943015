import * as MUI from '@material-ui/core';
import { Box } from '@material-ui/core';
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';
import { Alert, AlertTitle } from '@material-ui/lab';
import graphql from 'babel-plugin-relay/macro';
import React, { useCallback, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useFragment, useMutation } from 'react-relay';

import SupersedingSkillsSelect from '../components/SupersedingSkillsSelect';

function SkillFormAssociatedSkills({ skill }) {
  const data = useFragment(
    graphql`
      fragment SkillFormAssociatedSkills_skill on Skill {
        id

        supersedingSkills {
          id
          name
        }
        childSkills {
          id
          name
        }
      }
    `,
    skill,
  );

  const [commitAssociatedSkillsMutation, _isReorderMutationInFlight] =
    useMutation(graphql`
      mutation SkillFormAssociatedSkills_AssociatedSkills_Mutation(
        $skillID: ID!
        $newChildSkillIDs: [ID!]
        $newSupersedingSkillIDs: [ID!]
      ) {
        changeAssociatedSkills(
          skillID: $skillID
          newChildSkillIDs: $newChildSkillIDs
          newSupersedingSkillIDs: $newSupersedingSkillIDs
        ) {
          ...SkillFormAssociatedSkills_skill
        }
      }
    `);

  const childSkillIDs = useMemo(
    () => (data?.childSkills ?? [])?.map((i) => i.id),
    [data?.childSkills],
  );

  const supersedingSkillIDs = useMemo(
    () => (data?.supersedingSkills ?? [])?.map((i) => i.id),
    [data?.supersedingSkills],
  );

  const { control, watch } = useForm({
    defaultValues: {
      supersedingSkillIDs,
      childSkillIDs,
    },
  });

  const formFields = watch();

  const isChanged = React.useMemo(
    () =>
      [...(formFields.supersedingSkillIDs ?? [])].sort().join(',') !==
        [...(supersedingSkillIDs ?? [])].sort().join(',') ||
      [...(formFields.childSkillIDs ?? [])].sort().join(',') !==
        [...(childSkillIDs ?? [])].sort().join(','),
    [supersedingSkillIDs, childSkillIDs, formFields],
  );

  const handleSaveAssociatedSkills = useCallback(() => {
    commitAssociatedSkillsMutation({
      variables: {
        skillID: data.id,
        newChildSkillIDs: formFields.childSkillIDs,
        newSupersedingSkillIDs: formFields.supersedingSkillIDs,
      },
    });
  }, [formFields, data.id]);

  return (
    <MUI.Card>
      <Box style={{ width: '100%', padding: '1em' }}>
        <MUI.FormLabel>Superseding Skills</MUI.FormLabel>

        <Controller
          control={control}
          name="supersedingSkillIDs"
          defaultValue={supersedingSkillIDs}
          render={({ field: { onChange, onBlur, value } }) => (
            <SupersedingSkillsSelect
              onChange={onChange}
              onBlur={onBlur}
              value={value}
            />
          )}
        />
        <Box marginBottom={2} />

        <MUI.FormLabel>Child Skills</MUI.FormLabel>

        <Controller
          control={control}
          name="childSkillIDs"
          defaultValue={childSkillIDs}
          render={({ field: { onChange, onBlur, value } }) => (
            <SupersedingSkillsSelect
              onChange={onChange}
              onBlur={onBlur}
              value={value}
            />
          )}
        />
        <MUI.Collapse
          in={isChanged}
          style={{ position: 'sticky', bottom: 0, zIndex: 1200 }}
        >
          <Box marginBottom={2} />
          <MUI.Box paddingBottom={2} paddingTop={1}>
            <Alert
              severity="warning"
              icon={<FormatListNumberedIcon fontSize="inherit" />}
            >
              <AlertTitle>Associated Skills changed</AlertTitle>
              Associated skills have been edited, but not saved.
              <MUI.Box marginTop={2}>
                <MUI.Button
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={handleSaveAssociatedSkills}
                >
                  Save Changes
                </MUI.Button>
              </MUI.Box>
            </Alert>
          </MUI.Box>
        </MUI.Collapse>
      </Box>
    </MUI.Card>
  );
}

export default SkillFormAssociatedSkills;
