import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline';

import CoachQuipsList from './CoachQuipsList';
import CoachQuipsEdit from './CoachQuipsEdit';
import CoachQuipsCreate from './CoachQuipsCreate';

export default {
  list: CoachQuipsList,
  edit: CoachQuipsEdit,
  icon: ChatBubbleOutlineIcon,
  create: CoachQuipsCreate,
};
