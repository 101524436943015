import * as React from 'react';
import { FC } from 'react';
import { FieldProps, Link } from 'react-admin';

import LessonNameField from './LessonNameField';

const LessonLinkField: FC<FieldProps> = (props) => {
  const lessonId = props.record?.lessonId;

  return lessonId ? (
    <Link to={`/content/lesson/${lessonId}`}>
      <LessonNameField {...props} />
    </Link>
  ) : null;
};

LessonLinkField.defaultProps = {
  label: 'Lesson',
  addLabel: true,
};

export default LessonLinkField;
