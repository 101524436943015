import * as React from 'react';
import {
  Datagrid,
  List,
  TextField,
  ListProps,
  NullableBooleanInput,
} from 'react-admin';
import { FC } from 'react';
import { Link, FieldProps, Filter, TextInput } from 'react-admin';

import { ReactElement } from 'react';

const GoalTemplateLinkField: FC<FieldProps> = (props) =>
  props.record ? (
    <Link
      to={`/content/generative_conversation/goal_template/${props.record.id}`}
      style={{
        alignItems: 'center',
      }}
    >
      {props.record.title}
    </Link>
  ) : null;

const ScenarioLinkField: FC<FieldProps> = (props) =>
  props.record ? (
    <Link
      to={`/content/generative_conversation/topic_config/${props.record.scenarioId}`}
      style={{
        alignItems: 'center',
      }}
    >
      {props.record?.scenario?.conversation_topic || props.record.scenarioId}
    </Link>
  ) : null;

const GoalTemplateFilter = (props: any) => (
  <Filter {...props}>
    <TextInput label="Title" source="title" defaultValue="" />
    <TextInput label="Type" source="type" defaultValue="" />
    <TextInput label="Metric" source="metric" defaultValue="" />
    <TextInput label="Target" source="target" defaultValue="" />
    <TextInput label="Scenario Id" source="scenarioId" defaultValue="" />
    <NullableBooleanInput
      source="isGenericGoal"
      label="Is Generic Goal?"
      helperText={false}
    />
  </Filter>
);

const GoalTemplateList = (props: ListProps): ReactElement => (
  <List {...props} perPage={25} filters={<GoalTemplateFilter />}>
    <Datagrid optimized>
      <GoalTemplateLinkField label="Title" />
      <TextField label="Type" source="type" />
      <TextField label="Metric Length" source="metricLength" />
      <TextField label="Metric" source="metric" />
      <TextField label="Target" source="target" />
      <ScenarioLinkField label="Scenario" source="scenarioId" />
    </Datagrid>
  </List>
);

export default GoalTemplateList;
