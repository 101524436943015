import * as MUI from '@material-ui/core';
import { Box } from '@material-ui/core';
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';
import { Alert, AlertTitle } from '@material-ui/lab';
import graphql from 'babel-plugin-relay/macro';
import React, { useCallback, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useFragment, useMutation } from 'react-relay';

import MultipleChoiceQuestionsSelect from '../components/MultipleChoiceQuestionsSelect';

function SkillFormAssociatedMultipleChoiceQuestions({ skill }) {
  const data = useFragment(
    graphql`
      fragment SkillFormAssociatedMultipleChoiceQuestions_skill on Skill {
        id

        allMultipleChoiceQuestions {
          id
          text
        }
      }
    `,
    skill,
  );

  const [
    commitAssociatedMultipleChoiceQuestionsMutation,
    _isReorderMutationInFlight,
  ] = useMutation(graphql`
    mutation SkillFormAssociatedMultipleChoiceQuestions_AssociatedMultipleChoiceQuestions_Mutation(
      $skillID: ID!
      $multipleChoiceQuestionIDs: [ID!]!
    ) {
      setSkillMultipleChoiceQuestions(
        skillID: $skillID
        multipleChoiceQuestionIDs: $multipleChoiceQuestionIDs
      ) {
        ...SkillFormAssociatedMultipleChoiceQuestions_skill
      }
    }
  `);

  const multipleChoiceQuestionIDs = useMemo(
    () => (data?.allMultipleChoiceQuestions ?? [])?.map((i) => i.id),
    [data?.allMultipleChoiceQuestions],
  );

  const { control, watch } = useForm({
    defaultValues: {
      multipleChoiceQuestionIDs,
    },
  });

  const formFields = watch();

  const isChanged = React.useMemo(
    () =>
      [...(formFields.multipleChoiceQuestionIDs ?? [])].sort().join(',') !==
      [...(multipleChoiceQuestionIDs ?? [])].sort().join(','),
    [multipleChoiceQuestionIDs, formFields],
  );

  const handleSaveAssociatedMultipleChoiceQuestions = useCallback(() => {
    commitAssociatedMultipleChoiceQuestionsMutation({
      variables: {
        skillID: data.id,
        multipleChoiceQuestionIDs: formFields.multipleChoiceQuestionIDs,
      },
    });
  }, [formFields, data.id]);

  return (
    <MUI.Card>
      <Box style={{ width: '100%', padding: '1em' }}>
        <MUI.FormLabel>Multiple Choice Questions</MUI.FormLabel>

        <Controller
          control={control}
          name="multipleChoiceQuestionIDs"
          defaultValue={multipleChoiceQuestionIDs}
          render={({ field: { onChange, onBlur, value } }) => (
            <MultipleChoiceQuestionsSelect
              onChange={onChange}
              onBlur={onBlur}
              value={value}
            />
          )}
        />

        <MUI.Collapse
          in={isChanged}
          style={{ position: 'sticky', bottom: 0, zIndex: 1200 }}
        >
          <Box marginBottom={2} />
          <MUI.Box paddingBottom={2} paddingTop={1}>
            <Alert
              severity="warning"
              icon={<FormatListNumberedIcon fontSize="inherit" />}
            >
              <AlertTitle>MultipleChoiceQuestions changed</AlertTitle>
              MultipleChoiceQuestions have been edited, but not saved.
              <MUI.Box marginTop={2}>
                <MUI.Button
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={handleSaveAssociatedMultipleChoiceQuestions}
                >
                  Save Changes
                </MUI.Button>
              </MUI.Box>
            </Alert>
          </MUI.Box>
        </MUI.Collapse>
      </Box>
    </MUI.Card>
  );
}

export default SkillFormAssociatedMultipleChoiceQuestions;
