import React, { SetStateAction, createContext, useMemo, useState } from 'react';

export const InVideoFormContext = createContext<
  Partial<{
    from: number;
    setFrom: any;
    to: number;
    setTo: any;
    category: string;
    data: any;
    setData: SetStateAction<any>;
    record: any;
  }>
>({});

export default function InVideoFormProvider({
  children,
  category,
  record,
}: {
  children: React.ReactNode;
  category: string;
  record: any;
}) {
  const [from, setFrom] = useState();
  const [to, setTo] = useState();

  const [data, setData] = useState();

  const context = useMemo(
    () => ({
      from,
      setFrom,
      to,
      setTo,
      category,
      data,
      setData,
      record,
    }),
    [from, setFrom, to, setTo, data, setData, category, record],
  );

  return (
    <InVideoFormContext.Provider value={context}>
      {children}
    </InVideoFormContext.Provider>
  );
}
