import * as React from 'react';
import {
  List,
  DateField,
  NumberField,
  TextField,
  Datagrid,
  BooleanField,
  FieldProps,
  useGetList,
  Filter,
  NumberInput,
  NullableBooleanInput,
  TextInput,
} from 'react-admin';
import { Link } from 'react-router-dom';
import AudioField from '../practice/AudioField';

// const CoachQuipsFilter = (props: any) => (
//   <Filter {...props}>
//     <TextInput
//       label="User First Name"
//       source="User.firstName"
//       defaultValue=""
//     />
//     <TextInput label="User Last Name" source="User.lastName" defaultValue="" />
//     <BooleanInput label="Completed" source="completed" />
//   </Filter>
// );

const TruncatedIdField: React.FC<FieldProps> = (props) =>
  props.record ? (
    <Link to={`/content/quips/${props.record.id}`}>
      {String(props?.record?.id).slice(0, 6)}
    </Link>
  ) : null;

TruncatedIdField.defaultProps = {};

const CoachNameField: React.FC<FieldProps> = (props) => {
  const { data } = useGetList('coach');
  const coach = data?.[props?.record?.coachId];

  return coach ? <Link to={`/coach/${coach.id}`}>{coach.name}</Link> : null;
};

const QuipFilter = (props: any) => (
  <Filter {...props}>
    <TextInput label="Blurb" source="blurb" />
    <NumberInput label="State Key" source="stateKey" />
    <TextInput label="language" source="language" />
    <NullableBooleanInput label="Is for Sentence" source="isForSentence" />
  </Filter>
);

export const CoachQuipsList = (props: any) => (
  <List
    {...props}
    perPage={25}
    sort={{ field: 'createdAt', order: 'DESC' }}
    filters={<QuipFilter />}
  >
    <Datagrid optimized>
      <TruncatedIdField label="Id" />
      <CoachNameField source="coachId" label="Coach" />
      <TextField source="blurb" label="blurb" />
      <AudioField source="audioFile" label="Audio File" />
      <NumberField source="stateKey" />
      <BooleanField source="isForSentence" />
      <TextField source="language" />
      <DateField showTime source="createdAt" label="Created At" />
    </Datagrid>
  </List>
);

export default CoachQuipsList;
