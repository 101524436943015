/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from 'react';
import { Button } from '@material-ui/core';
import {
  TextInput,
  NumberInput,
  BooleanInput,
  DateInput,
  useRefresh,
} from 'react-admin';
import { Form } from 'react-final-form';
import api from '../../../api';

const ImageEditForm = ({ record }: any) => {
  const refresh = useRefresh();
  const handleSave = async (data: any) => {
    const { id, videoId, createdAt, updatedAt, graphicType, ...rest } = data;

    try {
      const response = await api.patchVideoImage(data.videoId, data.id, rest);

      if (response.status < 300) {
        refresh();
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  const handleDelete = async () => {
    const response = window.confirm('Are you sure you want to do that?');

    if (response) {
      try {
        await api.deleteVideoImage(record?.videoId, record.id);
        refresh();
      } catch (error) {
        console.log('error', error);
      }
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
      }}
    >
      <Form
        initialValues={record}
        onSubmit={handleSave}
        render={({ handleSubmit }: any) => (
          <form
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
            onSubmit={handleSubmit}
          >
            <div
              style={{
                minWidth: 300,
                marginRight: 10,
              }}
            >
              <TextInput label="Title" source="title" fullWidth />
              <NumberInput
                label="From"
                source="from"
                style={{ marginRight: 12 }}
              />
              <NumberInput label="To" source="to" />
              <BooleanInput label="Is In Ms" source="isInMs" />
              <TextInput
                label="URL"
                source="url"
                fullWidth
                helperText="Files are saved to the 'content_photos' folder. Changes take effect only once saved"
              />

              <DateInput
                disabled
                label="Created At"
                source="createdAt"
                fullWidth
              />
              <DateInput
                disabled
                label="Updated At"
                source="updatedAt"
                fullWidth
              />
            </div>

            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  onClick={handleSubmit}
                >
                  Save
                </Button>
              </div>

              {record && typeof record.id !== 'undefined' && (
                <Button onClick={handleDelete}>Delete</Button>
              )}
            </div>
          </form>
        )}
      />
      <div style={{ padding: 12 }}>
        <p>Preview</p>
        <VideoImagePreview url={record?.url} />
      </div>
    </div>
  );
};

function VideoImagePreview({ url }: { url: string | undefined }) {
  const [signedUrl, setSignedUrl] = useState();

  useEffect(() => {
    (async () => {
      if (url === undefined) return;
      const response = await api.getContentImage(url);
      setSignedUrl(response.json.signedUrl);
    })();
  }, []);

  return (
    <img
      src={url?.startsWith('http') ? url : signedUrl}
      alt="Preview"
      style={{
        maxWidth: 500,
      }}
    />
  );
}

export default ImageEditForm;
