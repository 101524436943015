import { Box, Card, CardContent } from '@material-ui/core';
import { Theme, makeStyles } from '@material-ui/core/styles';
import { Styles } from '@material-ui/styles/withStyles';
import * as React from 'react';
import { FC } from 'react';
import {
  AutocompleteArrayInput,
  DeleteButton,
  Edit,
  EditProps,
  FieldProps,
  FormWithRedirect,
  NumberInput,
  ReferenceArrayInput,
  SaveButton,
  TextInput,
  Toolbar,
} from 'react-admin';
import { JsonInput } from 'react-admin-json-view';

import OrderedArrayInput from '../components/OrderedArrayInput';
import { matchSuggestion } from '../lesson/utils';

const SongTitle: FC<FieldProps> = ({ record }) =>
  record ? (
    <span>
      Song &quot;
      {record.title}&quot;
    </span>
  ) : null;

export const styles: Styles<Theme, any> = {
  name: { display: 'inline-block' },
  photo: { display: 'inline-block' },
  spacing: { marginRight: 10 },
  image: {
    margin: '0.5rem',
    maxHeight: '10rem',
  },
  button: {
    marginRight: 10,
  },
};

const useStyles = makeStyles(styles);

const SongForm = (props: any) => {
  const classes = useStyles(props);
  return (
    <FormWithRedirect
      {...props}
      render={(formProps: any) => (
        <Card>
          <form>
            <CardContent>
              <Box>
                <TextInput
                  fullWidth
                  autoFocus
                  source="title"
                  label="Title"
                  className={classes.name}
                />
              </Box>
              <Box>
                <TextInput
                  fullWidth
                  autoFocus
                  source="artist"
                  label="Arist"
                  className={classes.name}
                />
              </Box>
              <Box>
                <TextInput
                  fullWidth
                  autoFocus
                  source="audio_file_name"
                  label="Audio File Name"
                  className={classes.name}
                />
              </Box>
              <Box>
                <TextInput
                  fullWidth
                  autoFocus
                  source="thumbnail_url"
                  label="Thumbnail URL"
                  className={classes.name}
                />
              </Box>
              <Box>
                <NumberInput
                  fullWidth
                  autoFocus
                  source="duration"
                  label="Duration"
                  className={classes.name}
                />
              </Box>
              <Box>
                <JsonInput
                  source="target_sounds"
                  jsonString={false}
                  reactJsonOptions={{
                    name: 'Target Sounds',
                    collapsed: false,
                    enableClipboard: true,
                    displayDataTypes: false,
                    sortKeys: true,
                    displayObjectSize: false,
                  }}
                />
              </Box>
              <Box>
                <JsonInput
                  source="highscores"
                  jsonString={false}
                  reactJsonOptions={{
                    name: 'High Scores',
                    collapsed: false,
                    enableClipboard: true,
                    displayDataTypes: false,
                    sortKeys: true,
                    displayObjectSize: false,
                  }}
                />
              </Box>
              <Box>
                <ReferenceArrayInput
                  filterToQuery={(searchText: any) => ({ word: searchText })}
                  label="Practice Items"
                  source="practiceId"
                  reference="content/practice"
                  filter={{ isUserGeneratedContent: false }}
                >
                  <OrderedArrayInput elementLinkUrl="/#/content/practice/">
                    <AutocompleteArrayInput
                      optionValue="id"
                      optionText={(wordRecord: any) => `${wordRecord?.word}`}
                      matchSuggestion={(filterValue: string, choice: any) =>
                        matchSuggestion(filterValue, choice, 'word')
                      }
                    />
                  </OrderedArrayInput>
                </ReferenceArrayInput>
              </Box>
              <Toolbar
                record={formProps.record}
                basePath={formProps.basePath}
                undoable={false}
                invalid={formProps.invalid}
                handleSubmit={formProps.handleSubmit}
                saving={formProps.saving}
                resource="practices"
                style={{ display: 'flex', justifyContent: 'space-between' }}
              >
                <div>
                  <SaveButton
                    label="Save & Continue"
                    handleSubmitWithRedirect={
                      formProps.handleSubmitWithRedirect ||
                      formProps.handleSubmit
                    }
                    disabled={formProps.disabled}
                    invalid={formProps.invalid}
                    redirect="edit"
                    saving={formProps.saving}
                    submitOnEnter={formProps.submitOnEnter}
                    className={classes.button}
                  />

                  <SaveButton
                    label="Save & Exit"
                    handleSubmitWithRedirect={
                      formProps.handleSubmitWithRedirect ||
                      formProps.handleSubmit
                    }
                    disabled={formProps.disabled}
                    invalid={formProps.invalid}
                    redirect={formProps.redirect}
                    saving={formProps.saving}
                    submitOnEnter={formProps.submitOnEnter}
                    className={classes.button}
                  />
                </div>

                {formProps.record &&
                  typeof formProps.record.id !== 'undefined' && (
                    <DeleteButton
                      basePath={formProps.basePath}
                      record={formProps.record}
                      resource={formProps.resource}
                      undoable={formProps.undoable}
                      mutationMode={formProps.mutationMode}
                    />
                  )}
              </Toolbar>
            </CardContent>
          </form>
        </Card>
      )}
    />
  );
};

const SongEdit: FC<EditProps> = (props) => (
  <Edit undoable={false} title={<SongTitle />} {...props}>
    <SongForm />
  </Edit>
);

export default SongEdit;
