import * as MUI from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import graphql from 'babel-plugin-relay/macro';
import React from 'react';
import { useInput } from 'react-admin';
import { useLazyLoadQuery, useRefetchableFragment } from 'react-relay';

const MultipleChoicePromptSelect = (props: any) => (
  <React.Suspense
    fallback={
      <MultipleChoicePromptSelectField
        {...props}
        isLoading
        selectedMultipleChoicePrompts={[]}
        multipleChoicePrompts={[]}
      />
    }
  >
    <MultipleChoicePromptSelectWithData {...props} />
  </React.Suspense>
);

const MultipleChoicePromptSelectWithData = (props: any) => {
  const [_isPending, startTransition] = React.useTransition();

  const initiallySelectedID = React.useMemo(() => props.value || null, []);

  const data: any = useLazyLoadQuery(
    graphql`
      query MultipleChoicePromptSelect_Query($initiallySelectedIDs: [ID!]!) {
        ...MultipleChoicePromptSelect_multipleChoicePromptsForString
        ...MultipleChoicePromptSelect_multipleChoicePromptsByIDs
          @arguments(ids: $initiallySelectedIDs)
      }
    `,
    { initiallySelectedIDs: [initiallySelectedID].filter(Boolean) },
    {},
  );

  const [searchedData, refetch] = useRefetchableFragment(
    graphql`
      fragment MultipleChoicePromptSelect_multipleChoicePromptsForString on Query
      @refetchable(
        queryName: "MultipleChoicePromptSelect_multipleChoicePromptsForString_RefetchQuery"
      )
      @argumentDefinitions(search: { type: "String", defaultValue: "" }) {
        multipleChoicePromptsForString(search: $search, first: 20) {
          nodes {
            id
            text
          }
        }
      }
    `,
    data,
  );

  const refetchSearch = React.useCallback(
    (search: string) => {
      refetch({ search }, { fetchPolicy: 'network-only' });
    },
    [refetch],
  );

  const [selectedData, refetchSelected] = useRefetchableFragment(
    graphql`
      fragment MultipleChoicePromptSelect_multipleChoicePromptsByIDs on Query
      @refetchable(
        queryName: "MultipleChoicePromptSelect_multipleChoicePromptsByIDs_RefetchQuery"
      )
      @argumentDefinitions(ids: { type: "[ID!]", defaultValue: [] }) {
        multipleChoicePromptsByIDs(ids: $ids) {
          id
          text
        }
      }
    `,
    data,
  );

  React.useEffect(() => {
    startTransition(() => {
      refetchSelected(
        { ids: [props.value].filter(Boolean) },
        { fetchPolicy: 'network-only' },
      );
    });
  }, [props.value, refetchSelected, startTransition]);

  return (
    <>
      <MultipleChoicePromptSelectField
        {...props}
        multipleChoicePrompts={
          searchedData.multipleChoicePromptsForString.nodes
        }
        selectedMultipleChoicePrompt={
          selectedData.multipleChoicePromptsByIDs[0] ?? null
        }
        refetchSearch={refetchSearch}
      />
    </>
  );
};

const MultipleChoicePromptSelectField = ({
  multipleChoicePrompts,
  selectedMultipleChoicePrompt,
  refetchSearch,
  onChange,
  isLoading,
  value,
  disabled,
  label,
  required,
}: any) => {
  const [_isPending, startTransition] = React.useTransition();

  const multipleChoicePromptsByID = React.useMemo(
    () =>
      Object.fromEntries(
        [
          ...multipleChoicePrompts,
          ...(selectedMultipleChoicePrompt
            ? [selectedMultipleChoicePrompt]
            : []),
        ].map((multipleChoicePrompt: any) => [
          multipleChoicePrompt.id,
          multipleChoicePrompt,
        ]),
      ),
    [multipleChoicePrompts, selectedMultipleChoicePrompt],
  );

  const multipleChoicePromptIDs = React.useMemo(
    () =>
      multipleChoicePrompts.map(
        (multipleChoicePrompt: any) => multipleChoicePrompt.id,
      ),
    [multipleChoicePrompts],
  );

  const onInputChange = (e: any, value: any) => {
    startTransition(() => {
      refetchSearch?.(value);
    });
  };

  return (
    <Autocomplete
      autoHighlight
      blurOnSelect={false}
      options={multipleChoicePromptIDs}
      filterOptions={(x) => x}
      renderTags={() => null}
      closeIcon={null}
      getOptionLabel={(multipleChoicePromptID: any) =>
        multipleChoicePromptsByID[multipleChoicePromptID]?.text ||
        'Invalid Multiple Choice Prompt'
      }
      style={{ width: 600 }}
      // debug
      size="small"
      selectOnFocus
      loading={isLoading}
      renderOption={(multipleChoicePromptID: any) => {
        const multipleChoicePrompt =
          multipleChoicePromptsByID[multipleChoicePromptID];
        return (
          <MUI.Box display="flex" flexDirection="column">
            {multipleChoicePrompt.text}
            <MUI.Box component="span" color="text.secondary" fontSize={12}>
              {multipleChoicePrompt.id}
            </MUI.Box>
          </MUI.Box>
        );
      }}
      renderInput={(params) => (
        <MUI.TextField
          {...params}
          variant="filled"
          margin="dense"
          label={label ?? 'Multiple Choice Prompt'}
          required={required}
        />
      )}
      onInputChange={onInputChange}
      onChange={(e, value, reason) => {
        if (reason !== 'remove-option') {
          onChange?.(value || null);
        }
      }}
      value={value}
      disabled={disabled}
    />
  );
};

export const MultipleChoicePromptInput = (props: any) => {
  const {
    input: { onChange, value },
    isRequired,
  } = useInput(props);

  return (
    <MultipleChoicePromptSelect
      {...props}
      onChange={onChange}
      value={value || null}
      required={isRequired}
    />
  );
};

export default MultipleChoicePromptSelect;
