import FaceIcon from '@material-ui/icons/Face';

import UserList from './UserList';
import UserCreate from './UserCreate';
import UserEdit from './UserEdit';

const UserIcon = FaceIcon;

export default {
  icon: UserIcon,
  list: UserList,
  create: UserCreate,
  edit: UserEdit,
};
