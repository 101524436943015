import ViewDayIcon from '@material-ui/icons/ViewDay';

import PracticeList from './PracticeList';
import PracticeEdit from './PracticeEdit';
import PracticeCreate from './PracticeCreate';

export default {
  list: PracticeList,
  edit: PracticeEdit,
  icon: ViewDayIcon,
  create: PracticeCreate,
};
