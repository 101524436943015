import PropTypes from 'prop-types';
import React from 'react';

import DataStore from './DataStore';
import { BodyCell, HeaderCell, ScrollableContent } from './components';
import {
  fixedTablePart,
  scrollableTablePart, // scrollableTableContent,
  table,
  tableBody,
  tableHeading,
  tableRow,
  wrapper,
} from './styles';
// import { VALUE_KEYS } from './constants';
import {
  DEFAULT_BODY_CELL_COLOR,
  DEFAULT_HEADER_CELL_COLOR,
  DEFAULT_KEY_CELL_COLOR,
  DEFAULT_SHADE_COLOR,
} from './styles';

const getStore = (inputProps) => {
  const {
    data = {},
    shadeColor = DEFAULT_SHADE_COLOR,
    headerCellColor = DEFAULT_HEADER_CELL_COLOR,
    bodyCellColor = DEFAULT_BODY_CELL_COLOR,
    keyCellColor = DEFAULT_KEY_CELL_COLOR,
    columnLabel,
  } = inputProps;

  const newDataStore = new DataStore(data, {
    shadeColor,
    headerCellColor,
    bodyCellColor,
    keyCellColor,
    columnLabel,
  });
  return newDataStore;
};

const isFixed = (index) => index < 2;

const ReactCohortGraph = (props) => {
  const savedData = React.useRef();
  const [state, setState] = React.useState({
    dataStore: getStore(props),
    currentType: '',
    valueType: props.defaultValueType,
  });

  React.useEffect(() => {
    const { data, onStoreUpdate } = props;
    const keys = Object.keys(data);
    if (keys.length > 0) {
      const store = getStore(props);
      const currentType = keys[0];
      if (typeof onStoreUpdate === 'function') {
        onStoreUpdate(store, currentType, state.valueType);
      }
      savedData.current = JSON.stringify(data);
      setState((existingState) => ({
        ...existingState,
        currentType,
        dataStore: store,
      }));
    }
  }, []);

  React.useEffect(() => {
    const { data, dataType, onStoreUpdate } = props;
    const { currentType } = state;
    const keys = Object.keys(data);
    if (keys.length > 0) {
      const store = getStore(props);

      const newData = JSON.stringify(data);

      const currentDataType = dataType || Object.keys(data)[0];
      if (newData !== savedData.current || currentType === '') {
        setState((existingState) => ({
          ...existingState,
          dataStore: store,
        }));
      } else if (valueType) {
        setState((existingState) => ({
          ...existingState,
          currentType: currentDataType,
          valueType,
        }));
      } else {
        setState((existingState) => ({
          ...existingState,
          currentType: currentDataType,
        }));
      }
      if (typeof onStoreUpdate === 'function') {
        onStoreUpdate(store, currentDataType, valueType);
      }
    }
  }, [props]);

  const renderChildren = (inputProps) =>
    React.Children.map(props.children, (child) =>
      React.cloneElement(child, inputProps),
    );

  const {
    showEmptyDataMessage = true,
    customEmptyDataMessage,
    showHeaderValues,
    cellFormatter,
    headerFormatter,
    bodyCellStyles = {},
    headerCellStyles = {},
    tableStyles,
    tableRowStyles,
    tableHeadingStyles,
    tableBodyStyles,
    fixedTablePartStyles,
    wrapperStyles,
    scrollableTablePartStyles,
    scrollableTableContentStyles,
    headerLabelStyles,
    tableCellStyles,
  } = props;
  const { dataStore, currentType, valueType } = state;

  const header = dataStore.getHeader(currentType);

  const rows = dataStore.getRows(currentType);

  const TableStyles = table(tableStyles);
  const TableRowStyles = tableRow(tableRowStyles);
  const TableHeadingStyles = tableHeading(tableHeadingStyles);
  const TableBodyStyles = tableBody(tableBodyStyles);
  const FixedTablePartStyles = fixedTablePart(fixedTablePartStyles);
  const WrapperStyles = wrapper(wrapperStyles);
  const ScrollableTablePartStyles = scrollableTablePart(
    scrollableTablePartStyles,
  );
  // const ScrollableTableContentStyles = scrollableTableContent(
  //   scrollableTableContentStyles,
  // );

  if (header && header.length > 0) {
    return (
      <div style={WrapperStyles}>
        {renderChildren({ ...props, ...state })}
        <div style={TableStyles}>
          <div style={TableBodyStyles}>
            <div style={TableRowStyles}>
              <div style={FixedTablePartStyles}>
                <div style={TableStyles}>
                  <div style={TableHeadingStyles}>
                    {header.map(
                      (headerCell, i) =>
                        isFixed(i) && (
                          <HeaderCell
                            tableCellStyles={tableCellStyles}
                            headerLabelStyles={headerLabelStyles}
                            style={headerCellStyles}
                            key={`header${i.toString()}`}
                            {...headerCell}
                            formatter={
                              typeof headerFormatter === 'function'
                                ? headerFormatter
                                : cellFormatter
                            }
                            showHeaderValues={showHeaderValues}
                            valueType={valueType}
                            isFixed
                          />
                        ),
                    )}
                  </div>
                  <div style={TableBodyStyles}>
                    {rows.map((row, j) => (
                      <div style={TableRowStyles} key={`row${j.toString()}`}>
                        {row.map(
                          (cell, k) =>
                            isFixed(k) && (
                              <BodyCell
                                tableCellStyles={tableCellStyles}
                                style={bodyCellStyles}
                                key={`cell${k.toString()}`}
                                {...cell}
                                valueType={valueType}
                                formatter={cellFormatter}
                                isFixed
                              />
                            ),
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div style={ScrollableTablePartStyles}>
                <ScrollableContent
                  scrollableTableContentStyles={scrollableTableContentStyles}
                >
                  <div style={TableStyles}>
                    <div style={TableHeadingStyles}>
                      {header.map(
                        (headerCell, i) =>
                          !isFixed(i) && (
                            <HeaderCell
                              tableCellStyles={tableCellStyles}
                              style={headerCellStyles}
                              key={`header${i.toString()}`}
                              {...headerCell}
                              formatter={
                                typeof headerFormatter === 'function'
                                  ? headerFormatter
                                  : cellFormatter
                              }
                              showHeaderValues={showHeaderValues}
                              valueType={valueType}
                              isFixed={false}
                            />
                          ),
                      )}
                    </div>
                    <div style={TableBodyStyles}>
                      {rows.map((row, j) => (
                        <div style={TableRowStyles} key={`row${j.toString()}`}>
                          {row.map(
                            (cell, k) =>
                              !isFixed(k) && (
                                <BodyCell
                                  tableCellStyles={tableCellStyles}
                                  style={bodyCellStyles}
                                  key={`cell${k.toString()}`}
                                  {...cell}
                                  valueType={valueType}
                                  formatter={cellFormatter}
                                  isFixed={false}
                                />
                              ),
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                </ScrollableContent>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (showEmptyDataMessage) {
    return customEmptyDataMessage || <h3>No Data..!</h3>;
  }

  return null;
};

ReactCohortGraph.propTypes = {
  data: PropTypes.object.isRequired,
  dataType: PropTypes.string, // keys of data
  defaultValueType: PropTypes.string, // ["value", "percent"]
  cellClickEvent: PropTypes.func,
  showEmptyDataMessage: PropTypes.bool,
  customEmptyDataMessage: PropTypes.any,
  columnClickEvent: PropTypes.func,
  shadeColor: PropTypes.string, // #3f83a3
  headerCellColor: PropTypes.string,
  bodyCellColor: PropTypes.string,
  keyCellColor: PropTypes.string,
  headerFormatter: PropTypes.func,
  cellFormatter: PropTypes.func,
  /* maxDays : PropTypes.number,
    maxWeeks : PropTypes.number, //TODO:
    maxMonths : PropTypes.number, */
  // enableTooltip : PropTypes.bool, TODO
  showAbsolute: PropTypes.bool,
  toggleValues: PropTypes.bool,
  showHeaderValues: PropTypes.bool,
  onStoreUpdate: PropTypes.func, // function(store, currentType, valueType)
  // Styles
  headerCellStyles: PropTypes.object,
  bodyCellStyles: PropTypes.object,
  tableCellStyles: PropTypes.object,
  tableStyles: PropTypes.object,
  tableRowStyles: PropTypes.object,
  tableHeadingStyles: PropTypes.object,
  tableBodyStyles: PropTypes.object,
  fixedTablePartStyles: PropTypes.object,
  wrapperStyles: PropTypes.object,
  scrollableTablePartStyles: PropTypes.object,
  scrollableTableContentStyles: PropTypes.object,
  headerValueStyles: PropTypes.object,
  headerLabelStyles: PropTypes.object,
  columnLabel: PropTypes.string,
};

export default ReactCohortGraph;
