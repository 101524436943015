import React, { useContext } from 'react';

import { InVideoFormContext } from './InVideoFormProvider';
import { BooleanInput, SelectInput, useGetList } from 'react-admin';
import { Typography } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import MouthDiagramsDisplay from '../../../components/MouthDiagramsDisplay';

export default function MouthDiagramSetCreationForm() {
  const { data, setData } = useContext(InVideoFormContext);

  const {
    ids: mouthDiagramSetIds,
    data: mouthDiagramSets,
  } = useGetList('content/mouthDiagramSet', { page: 1, perPage: 1000 });
  // this is just to match mouth diagrams set to this interactable, not actually image is uploaded
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <BooleanInput
        label="Should Auto Pause?"
        source="shouldAutoPause"
        onChange={(val) => {
          setData({ ...data, shouldAutoPause: val });
        }}
      />
      <BooleanInput
        label="Should be included in Video Summary?"
        source="shouldBeIncludedInSummary"
        defaultValue
        onChange={(val) => {
          setData({ ...data, shouldBeIncludedInSummary: val });
        }}
      />
      <SelectInput
        optionValue="id"
        source="mouthDiagramSet"
        choices={[...mouthDiagramSetIds]
          .sort((a, b) =>
            mouthDiagramSets[a].internalName > mouthDiagramSets[b].internalName
              ? 1
              : -1,
          )
          .map((id) => mouthDiagramSets[id])}
        optionText={(record: any) =>
          `${record.publicName} / ${record.internalName}`
        }
        onChange={(e: any) => {
          // a single set for a single interactable
          const { value } = e.target;
          setData({ ...data, mouthDiagramSetId: value });
        }}
      />
      <div>
        <Typography variant="body1" style={{ color: grey[600] }}>
          Attach a set to see it here.
        </Typography>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            columnGap: 12,
            marginTop: data?.mouthDiagramIds?.length ? 12 : 2,
            marginBottom: data?.mouthDiagramIds?.length ? 12 : 2,
          }}
        >
          {data?.mouthDiagramSetId ? (
            <MouthDiagramsDisplay
              diagrams={mouthDiagramSets[data.mouthDiagramSetId]?.mouthDiagrams}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
}
