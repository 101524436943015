import * as React from 'react';
import { FC } from 'react';
import { Link, FieldProps } from 'react-admin';

import CurriculumNameField from './CurriculumNameField';

const CurriculumLinkField: FC<FieldProps> = (props) => {
  const curricula = props.record?.curricula;

  return curricula?.length
    ? curricula.map((curriculum: any) => (
        <Link key={curriculum.id} to={`/content/curriculum/${curriculum.id}`}>
          <CurriculumNameField {...props} curriculum={curriculum.id} />
        </Link>
      ))
    : null;
};

CurriculumLinkField.defaultProps = {
  label: 'Curricula',
  addLabel: true,
};

export default CurriculumLinkField;
