/**
 * @generated SignedSource<<52218e2d9b09bbf079ac2024cf23d6e0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PhonemesSelect_phonemesForString$data = {
  readonly phonemesForString: {
    readonly nodes: ReadonlyArray<{
      readonly arpabet: string | null | undefined;
      readonly id: string | null | undefined;
      readonly ipa: string | null | undefined;
    } | null | undefined>;
  } | null | undefined;
  readonly " $fragmentType": "PhonemesSelect_phonemesForString";
};
export type PhonemesSelect_phonemesForString$key = {
  readonly " $data"?: PhonemesSelect_phonemesForString$data;
  readonly " $fragmentSpreads": FragmentRefs<"PhonemesSelect_phonemesForString">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": "",
      "kind": "LocalArgument",
      "name": "search"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [],
      "operation": require('./PhonemesSelect_phonemesForString_RefetchQuery.graphql')
    }
  },
  "name": "PhonemesSelect_phonemesForString",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 20
        },
        {
          "kind": "Variable",
          "name": "search",
          "variableName": "search"
        }
      ],
      "concreteType": "QueryPhonemesForStringConnection",
      "kind": "LinkedField",
      "name": "phonemesForString",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Phoneme",
          "kind": "LinkedField",
          "name": "nodes",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "id",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "arpabet",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "ipa",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "2a796e1748edc01e320985fcb3ffa54c";

export default node;
