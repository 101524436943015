import * as React from 'react';
import { FC } from 'react';
import { Link, FieldProps } from 'react-admin';

import LessonNameField from './LessonNameField';

const LessonLinkField: FC<FieldProps> = (props) =>
  props.record ? (
    <Link to={`/content/lesson/${props.record.lessonId}`}>
      <LessonNameField {...props} />
    </Link>
  ) : null;

LessonLinkField.defaultProps = {
  source: 'lessonId',
  label: 'Lesson',
  addLabel: true,
};

export default LessonLinkField;
