/**
 * @generated SignedSource<<8bc6ae76b72fdf5a413e6c0fa8ef45fa>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type ImprovementStatsQuery$variables = Record<PropertyKey, never>;
export type ImprovementStatsQuery$data = {
  readonly usersWithAccurateAggregateAssessments: ReadonlyArray<{
    readonly accurateAggregateAssessments: ReadonlyArray<any> | null | undefined;
    readonly ageGroup: string | null | undefined;
    readonly completedCurriculumItemsCount: number | null | undefined;
    readonly firstName: string | null | undefined;
    readonly hasUserJourney: boolean | null | undefined;
    readonly id: string | null | undefined;
    readonly languages: ReadonlyArray<{
      readonly englishName: string | null | undefined;
    }> | null | undefined;
    readonly lastName: string | null | undefined;
  }> | null | undefined;
};
export type ImprovementStatsQuery = {
  response: ImprovementStatsQuery$data;
  variables: ImprovementStatsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ageGroup",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "completedCurriculumItemsCount",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hasUserJourney",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "englishName",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "accurateAggregateAssessments",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "nodeID",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ImprovementStatsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "usersWithAccurateAggregateAssessments",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Language",
            "kind": "LinkedField",
            "name": "languages",
            "plural": true,
            "selections": [
              (v6/*: any*/)
            ],
            "storageKey": null
          },
          (v7/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "ImprovementStatsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "usersWithAccurateAggregateAssessments",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Language",
            "kind": "LinkedField",
            "name": "languages",
            "plural": true,
            "selections": [
              (v6/*: any*/),
              (v8/*: any*/)
            ],
            "storageKey": null
          },
          (v7/*: any*/),
          (v8/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c46311eb5a9b1d5237c0fcb1a3b0bfb6",
    "id": null,
    "metadata": {},
    "name": "ImprovementStatsQuery",
    "operationKind": "query",
    "text": "query ImprovementStatsQuery {\n  usersWithAccurateAggregateAssessments {\n    id\n    firstName\n    lastName\n    ageGroup\n    completedCurriculumItemsCount\n    hasUserJourney\n    languages {\n      englishName\n      nodeID\n    }\n    accurateAggregateAssessments\n    nodeID\n  }\n}\n"
  }
};
})();

(node as any).hash = "e9c06f7c6735afd8d62133bf4963f1f7";

export default node;
