/**
 * @generated SignedSource<<d29375ae28d94bddc760960f0812e5e6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DLPAssessmentsTaken2_AssessmentTaken$data = {
  readonly averageScore: number | null | undefined;
  readonly completed: boolean | null | undefined;
  readonly createdAt: any | null | undefined;
  readonly id: string | null | undefined;
  readonly oldScores: any | null | undefined;
  readonly " $fragmentType": "DLPAssessmentsTaken2_AssessmentTaken";
};
export type DLPAssessmentsTaken2_AssessmentTaken$key = {
  readonly " $data"?: DLPAssessmentsTaken2_AssessmentTaken$data;
  readonly " $fragmentSpreads": FragmentRefs<"DLPAssessmentsTaken2_AssessmentTaken">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DLPAssessmentsTaken2_AssessmentTaken",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "completed",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "averageScore",
      "storageKey": null
    },
    {
      "alias": "oldScores",
      "args": null,
      "kind": "ScalarField",
      "name": "score",
      "storageKey": null
    }
  ],
  "type": "AssessmentTaken",
  "abstractKey": null
};

(node as any).hash = "875108c3d25fe2d00319375adf04c1ad";

export default node;
