import React, { useMemo, useState } from 'react';
import useInterval from '../utils/hooks/useInterval';
import { Box, Button } from '@material-ui/core';
import { MouthDiagram } from '../mouthDiagram';
import { useMouthDiagramImages } from '../mouthDiagramSet/utils';

export default function MouthDiagramsDisplay({
  diagrams,
}: {
  diagrams: MouthDiagram[] | undefined;
}) {
  const [activeIndex, setActiveIndex] = useState(0);
  const [active, setActive] = useState(false);

  const { urls } = useMouthDiagramImages(diagrams);

  const imageArray = useMemo(
    () => [...(diagrams?.map((d) => urls?.[d.imageUrl] ?? '') ?? [])],
    [urls],
  );

  // some kind of effect that toggle between the two
  useInterval(
    (idx?: number) => {
      if (idx === undefined) {
        return;
      }
      setActiveIndex(idx);
    },
    [...(diagrams?.map((d) => d.MouthDiagramSetItem.duration) ?? [])],
    active,
  );

  if (!diagrams?.length) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: 10,
          margin: 10,
          maxWidth: 300,
          minHeight: 150,
          borderRadius: 10,
          backgroundColor: 'lightgray',
        }}
      >
        Attach a mouth diagram to get started
      </div>
    );
  }

  return (
    <Box display="flex" gridColumnGap="40px">
      <img
        src={imageArray[activeIndex]}
        style={{
          aspectRatio: '16/9',
          height: 230,
          borderRadius: 10,
          backgroundColor: 'lightgray',
        }}
        alt="mouth diagram display"
      />
      <Button
        color="primary"
        style={{
          paddingLeft: 10,
          paddingRight: 10,
          marginLeft: 10,
          marginBottom: 10,
          minWidth: 70,
        }}
        onClick={() => {
          if (!active) {
            setActiveIndex(0);
          }
          setActive(!active);
        }}
      >
        {active ? 'Pause' : 'Play'}
      </Button>
    </Box>
  );
}
