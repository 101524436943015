/**
 * @generated SignedSource<<f0159a11e0360c9e878c6386a53e7727>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UserSelect_userByID_RefetchQuery$variables = {
  id?: string | null | undefined;
};
export type UserSelect_userByID_RefetchQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"UserSelect_userByID">;
};
export type UserSelect_userByID_RefetchQuery = {
  response: UserSelect_userByID_RefetchQuery$data;
  variables: UserSelect_userByID_RefetchQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UserSelect_userByID_RefetchQuery",
    "selections": [
      {
        "args": (v1/*: any*/),
        "kind": "FragmentSpread",
        "name": "UserSelect_userByID"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UserSelect_userByID_RefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "userByID",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "email",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "firstName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lastName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "language",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "nodeID",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "10d5ea09e98456cd38bf16640b9e5d0a",
    "id": null,
    "metadata": {},
    "name": "UserSelect_userByID_RefetchQuery",
    "operationKind": "query",
    "text": "query UserSelect_userByID_RefetchQuery(\n  $id: ID\n) {\n  ...UserSelect_userByID_1Bmzm5\n}\n\nfragment UserSelect_userByID_1Bmzm5 on Query {\n  userByID(id: $id) {\n    id\n    email\n    firstName\n    lastName\n    language\n    nodeID\n  }\n}\n"
  }
};
})();

(node as any).hash = "d66ec88fed224c2f75bde2f2c06d1697";

export default node;
