import * as MUI from '@material-ui/core';
import * as MUIColors from '@material-ui/core/colors';
import * as MUIIcons from '@material-ui/icons';
import graphql from 'babel-plugin-relay/macro';
import * as df from 'date-fns';
import _ from 'lodash';
import React from 'react';
import {
  useFragment,
  useLazyLoadQuery,
  useMutation,
  useRefetchableFragment,
} from 'react-relay';

import PlotContainer, { Plot } from '../components/PlotContainer';

const STATUS_COLORS = {
  ENQUEUED: MUIColors.grey[700],
  RUNNING: MUIColors.blue[500],
  COMPLETED: MUIColors.green[500],
  ERRORED: MUIColors.red[500],
  TIMED_OUT: MUIColors.orange[500],
};

export default function () {
  const [_isPending, startTransition] = React.useTransition();

  const queryRef: any = useLazyLoadQuery(
    graphql`
      query QueuesQuery {
        ...Queues_recentSystemJobs
      }
    `,
    {},
  );

  const [recentSystemJobsData, refetchRecentSystemJobs] =
    useRefetchableFragment(
      graphql`
        fragment Queues_recentSystemJobs on Query
        @refetchable(queryName: "Queues_recentSystemJobs_RefetchQuery") {
          systemJobQueues {
            name

            ...Queues_Queue
          }
        }
      `,
      queryRef,
    );

  const [lastFetchTime, setLastFetchTime] = React.useState<Date | null>(null);

  React.useEffect(() => {
    const interval = setInterval(() => {
      startTransition(() => {
        refetchRecentSystemJobs(
          {},
          {
            fetchPolicy: 'network-only',
            onComplete: () => setLastFetchTime(new Date()),
          },
        );
      });
    }, 1_500);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <MUI.Box>
      <MUI.Box marginTop={2} marginBottom={3}>
        <MUI.Typography variant="h4" gutterBottom>
          System Job Queues
        </MUI.Typography>
      </MUI.Box>

      <MUI.Box display="flex" flexDirection="column" gridRowGap={20}>
        {recentSystemJobsData.systemJobQueues.map((queue) => (
          <Queue key={queue.name} queue={queue} lastFetchTime={lastFetchTime} />
        ))}
      </MUI.Box>
    </MUI.Box>
  );
}

const Queue = ({ queue: queueRef, lastFetchTime }) => {
  const queue = useFragment(
    graphql`
      fragment Queues_Queue on SystemJobQueue {
        name
        active

        recentJobs {
          id
          status
          enqueuedAt
          startedAt
          endedAt
        }

        failedJobs {
          id
          status
          enqueuedAt
          startedAt
          endedAt
        }
      }
    `,
    queueRef,
  );

  const recentJobs = React.useMemo(
    () =>
      queue.recentJobs.map((job) => ({
        ...job,
        enqueuedAt: new Date(job.enqueuedAt),
        startedAt: job.startedAt && new Date(job.startedAt),
        endedAt: job.endedAt && new Date(job.endedAt),
      })),
    [queue.recentJobs],
  );

  const orderedRecentJobs = React.useMemo(
    () => _.orderBy(recentJobs, 'enqueuedAt', 'desc').slice(0, 100),
    [recentJobs],
  );

  const [commitSetSystemJobQueueActive, _isSetSystemJobQueueActiveInFlight] =
    useMutation(graphql`
    mutation Queues_SetSystemJobQueueActiveMutation(
      $name: String!
      $active: Boolean!
    ) {
      setSystemJobQueueActive(name: $name, active: $active) {
        ...Queues_Queue
      }
    }
  `);

  const drawPlot = React.useCallback(
    ({ width }) => {
      const data = _.orderBy(
        recentJobs
          .map((job) => ({ ...job, endedAt: job.endedAt ?? new Date() }))
          .filter((job) => job.startedAt),
        'queueName',
      );

      console.log(queue.name, _.min(data.map((job) => job.startedAt)));

      return Plot.plot({
        width,
        height: 50,
        marginTop: 0,
        marginBottom: 30,
        marginLeft: 0,
        marginRight: 0,
        y: { label: null },
        x: {
          domain: [
            df.subHours(lastFetchTime ?? new Date(), 1),
            lastFetchTime ?? new Date(),
          ],
          tickSpacing: 60,
        },
        color: {
          domain: _.keys(STATUS_COLORS),
          range: _.values(STATUS_COLORS),
        },
        marks: [
          Plot.rect(data, {
            x1: 'startedAt',
            x2: 'endedAt',
            fill: 'status',
            opacity: 0.15,
          }),
          Plot.tickX(data, {
            x: 'startedAt',
            stroke: 'status',
          }),
          // Plot.frame({ opacity: 0.5 }),
        ],
      });
    },
    [recentJobs, lastFetchTime],
  );

  return (
    <MUI.Box>
      <MUI.Paper variant="outlined">
        <MUI.Box
          paddingX={1.5}
          paddingY={1}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <MUI.Box display="flex" alignItems="center">
            {queue.name.startsWith('cron/') ? (
              <MUIIcons.Update
                style={{
                  marginRight: 10,
                  color: queue.active
                    ? MUIColors.green[600]
                    : MUIColors.red[600],
                  fontSize: '125%',
                }}
              />
            ) : (
              <MUIIcons.FormatAlignJustify
                style={{
                  marginRight: 10,
                  color: queue.active
                    ? MUIColors.green[600]
                    : MUIColors.red[600],
                  fontSize: '125%',
                }}
              />
            )}
            <MUI.Typography
              variant="body2"
              style={{ fontWeight: 600, fontFamily: 'monospace' }}
            >
              {queue.name}
            </MUI.Typography>
          </MUI.Box>

          <MUI.Button
            size="small"
            variant="contained"
            color="inherit"
            style={{
              backgroundColor: queue.active
                ? MUIColors.green[400]
                : MUIColors.red[400],
              color: 'white',
            }}
            startIcon={
              queue.active ? <MUIIcons.Pause /> : <MUIIcons.PlayArrow />
            }
            onClick={() => {
              commitSetSystemJobQueueActive({
                variables: {
                  name: queue.name,
                  active: !queue.active,
                },
              });
            }}
          >
            {queue.active ? 'Pause' : 'Resume'}
          </MUI.Button>
        </MUI.Box>

        <MUI.Divider />

        <MUI.Box>
          <PlotContainer drawPlot={drawPlot} />
        </MUI.Box>
        <MUI.Divider />

        <MUI.Box paddingY={1.5} paddingX={1.5}>
          <MUI.Typography variant="body2" style={{ paddingBottom: 6 }}>
            Recent Jobs
          </MUI.Typography>

          <MUI.TableContainer
            component={MUI.Paper}
            variant="outlined"
            style={{ maxHeight: 180 }}
          >
            <MUI.Table size="small" stickyHeader>
              {orderedRecentJobs.length === 0 && (
                <caption>No recent jobs.</caption>
              )}

              <MUI.TableHead>
                <MUI.TableRow>
                  <MUI.TableCell>Status</MUI.TableCell>
                  <MUI.TableCell>Enqueued At</MUI.TableCell>
                  <MUI.TableCell>Started At</MUI.TableCell>
                  <MUI.TableCell>Ended At</MUI.TableCell>
                </MUI.TableRow>
              </MUI.TableHead>

              <MUI.TableBody>
                {orderedRecentJobs.map((job) => (
                  <MUI.TableRow key={job.id}>
                    <MUI.TableCell style={{ color: STATUS_COLORS[job.status] }}>
                      {job.status}
                    </MUI.TableCell>
                    <MUI.TableCell>
                      {df.format(job.enqueuedAt, 'MMM do HH:mm:ss O')}
                    </MUI.TableCell>
                    <MUI.TableCell>
                      {job.startedAt &&
                        df.format(job.startedAt, 'MMM do HH:mm:ss O')}
                    </MUI.TableCell>
                    <MUI.TableCell>
                      {job.endedAt &&
                        df.format(job.endedAt, 'MMM do HH:mm:ss O')}
                    </MUI.TableCell>
                  </MUI.TableRow>
                ))}
              </MUI.TableBody>
            </MUI.Table>
          </MUI.TableContainer>
        </MUI.Box>

        <MUI.Box paddingY={1.5} paddingX={1.5}>
          <MUI.Typography variant="body2" style={{ paddingBottom: 6 }}>
            Failed Jobs
          </MUI.Typography>

          <MUI.TableContainer
            component={MUI.Paper}
            variant="outlined"
            style={{ maxHeight: 180 }}
          >
            <MUI.Table size="small" stickyHeader>
              {queue.failedJobs.length === 0 && (
                <caption>No failed jobs.</caption>
              )}

              <MUI.TableHead>
                <MUI.TableRow>
                  <MUI.TableCell>Status</MUI.TableCell>
                  <MUI.TableCell>Enqueued At</MUI.TableCell>
                  <MUI.TableCell>Started At</MUI.TableCell>
                  <MUI.TableCell>Ended At</MUI.TableCell>
                </MUI.TableRow>
              </MUI.TableHead>

              <MUI.TableBody>
                {queue.failedJobs.map((job) => (
                  <MUI.TableRow key={job.id}>
                    <MUI.TableCell style={{ color: STATUS_COLORS[job.status] }}>
                      {job.status}
                    </MUI.TableCell>
                    <MUI.TableCell>
                      {df.format(job.enqueuedAt, 'MMM do HH:mm:ss O')}
                    </MUI.TableCell>
                    <MUI.TableCell>
                      {job.startedAt &&
                        df.format(job.startedAt, 'MMM do HH:mm:ss O')}
                    </MUI.TableCell>
                    <MUI.TableCell>
                      {job.endedAt &&
                        df.format(job.endedAt, 'MMM do HH:mm:ss O')}
                    </MUI.TableCell>
                  </MUI.TableRow>
                ))}
              </MUI.TableBody>
            </MUI.Table>
          </MUI.TableContainer>
        </MUI.Box>
      </MUI.Paper>
    </MUI.Box>
  );
};
