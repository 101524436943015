import { useEffect, useState } from 'react';

async function getIcon(iconName: any) {
  try {
    // @ts-ignore
    const { [iconName]: iconModule } = await import('@phosphor-icons/react');

    // Check if the icon module exists
    if (!iconModule) {
      throw new Error(`Icon ${iconName} not found`);
    }

    return iconModule;
  } catch (error) {
    console.error(`Error loading icon ${iconName}:`, error);
    return null;
  }
}

export default function useDynamicPhospherIcon(iconName: string) {
  const [PreviewIcon, setPreviewIcon] = useState<any>();

  useEffect(() => {
    if (iconName) {
      // miracle this works
      getIcon(iconName).then((icon) => {
        setPreviewIcon(icon);
      });
    }
  }, [iconName]);

  return PreviewIcon;
}
