import * as React from 'react';
import { FC } from 'react';
import { Link, FieldProps } from 'react-admin';
import UnitNameField from './UnitNameField';

const UnitLinkField: FC<FieldProps> = (props) => {
  const unitId = props.record?.unitId;

  return unitId ? (
    <Link to={`/content/unit/${unitId}`}>
      <UnitNameField {...props} />
    </Link>
  ) : null;
};

UnitLinkField.defaultProps = {
  label: 'Unit',
  addLabel: true,
};

export default UnitLinkField;
