import * as React from 'react';
import {
  List,
  Datagrid,
  TextField,
  DateField,
  TextInput,
  Filter,
  BooleanInput,
} from 'react-admin';
import UserLinkField from '../common/UserLinkField';
import ReferralCodeLinkField from '../common/ReferralCodeLinkField';
import ReferralCodeOwnerLinkField from '../common/ReferralCodeOwnerLinkField';
// import TruncatedIdField from '../../practice/TruncatedIdField';
// import { Tooltip } from '@material-ui/core';

const ReferralCodeRedemptionFilter = (props: any) => (
  <Filter {...props}>
    <TextInput
      label="Redeemer First Name"
      source="redeemer.firstName"
      defaultValue=""
    />
    <TextInput
      label="Redeemer Last Name"
      source="redeemer.lastName"
      defaultValue=""
    />
    <TextInput label="Redeemer Email" source="redeemer.email" defaultValue="" />
    <BooleanInput label="Redeemer has product?" source="hasSubProduct" />
    <TextInput
      label="Referral Code"
      source="referralCode.slug"
      defaultValue=""
    />
    <TextInput
      label="Referral Code Owner Id"
      source="referralCode.ownerId"
      defaultValue=""
    />
  </Filter>
);

export const ReferralCodeRedemptionList = (props: any) => (
  <List
    {...props}
    perPage={10}
    sort={{ field: 'createdAt', order: 'DESC' }}
    filters={<ReferralCodeRedemptionFilter />}
  >
    <Datagrid rowClick="expand">
      {/* <TruncatedIdField
        sortable={false}
        label={
          <Tooltip placement="bottom" title="First 4 of UUID">
            <div>Id</div>
          </Tooltip>
        }
      /> */}
      <ReferralCodeOwnerLinkField label="Referral Code Owner" />
      <ReferralCodeLinkField label="Referral Code" source="referralCodeId" />
      <UserLinkField label="Redeemer" source="redeemer" />
      <DateField showTime source="createdAt" label="Clicked At" />
      <DateField showTime source="signedUpOn" label="Signed Up At" />
      <TextField source="redeemer.subProduct" label="Subscribed Product" />
      <TextField source="redeemer.subStatus" label="Sub Status" />
    </Datagrid>
  </List>
);

export default ReferralCodeRedemptionList;
