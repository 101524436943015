/**
 * @generated SignedSource<<142655ccded34de49e9067331469e1ae>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MultipleChoicePromptSelect_Query$variables = {
  initiallySelectedIDs: ReadonlyArray<string>;
};
export type MultipleChoicePromptSelect_Query$data = {
  readonly " $fragmentSpreads": FragmentRefs<"MultipleChoicePromptSelect_multipleChoicePromptsByIDs" | "MultipleChoicePromptSelect_multipleChoicePromptsForString">;
};
export type MultipleChoicePromptSelect_Query = {
  response: MultipleChoicePromptSelect_Query$data;
  variables: MultipleChoicePromptSelect_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "initiallySelectedIDs"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "ids",
    "variableName": "initiallySelectedIDs"
  }
],
v2 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "id",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "text",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "nodeID",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "MultipleChoicePromptSelect_Query",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "MultipleChoicePromptSelect_multipleChoicePromptsForString"
      },
      {
        "args": (v1/*: any*/),
        "kind": "FragmentSpread",
        "name": "MultipleChoicePromptSelect_multipleChoicePromptsByIDs"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "MultipleChoicePromptSelect_Query",
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Literal",
            "name": "first",
            "value": 20
          },
          {
            "kind": "Literal",
            "name": "search",
            "value": ""
          }
        ],
        "concreteType": "QueryMultipleChoicePromptsForStringConnection",
        "kind": "LinkedField",
        "name": "multipleChoicePromptsForString",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "MultipleChoicePrompt",
            "kind": "LinkedField",
            "name": "nodes",
            "plural": true,
            "selections": (v2/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": "multipleChoicePromptsForString(first:20,search:\"\")"
      },
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "MultipleChoicePrompt",
        "kind": "LinkedField",
        "name": "multipleChoicePromptsByIDs",
        "plural": true,
        "selections": (v2/*: any*/),
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "dcf2f52b26ca944cb0e8044433f62369",
    "id": null,
    "metadata": {},
    "name": "MultipleChoicePromptSelect_Query",
    "operationKind": "query",
    "text": "query MultipleChoicePromptSelect_Query(\n  $initiallySelectedIDs: [ID!]!\n) {\n  ...MultipleChoicePromptSelect_multipleChoicePromptsForString\n  ...MultipleChoicePromptSelect_multipleChoicePromptsByIDs_1SqFeW\n}\n\nfragment MultipleChoicePromptSelect_multipleChoicePromptsByIDs_1SqFeW on Query {\n  multipleChoicePromptsByIDs(ids: $initiallySelectedIDs) {\n    id\n    text\n    nodeID\n  }\n}\n\nfragment MultipleChoicePromptSelect_multipleChoicePromptsForString on Query {\n  multipleChoicePromptsForString(search: \"\", first: 20) {\n    nodes {\n      id\n      text\n      nodeID\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "0e43277a0cc0d794f1f92abf9474c229";

export default node;
