/**
 * @generated SignedSource<<f6260aaaf40eb7bc9ed377ab99be2569>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type WordEdit_addPronunciation_Mutation$variables = {
  ipa: string;
  word: string;
};
export type WordEdit_addPronunciation_Mutation$data = {
  readonly addIPAToSource: {
    readonly word: {
      readonly " $fragmentSpreads": FragmentRefs<"WordEdit_word">;
    } | null | undefined;
  } | null | undefined;
};
export type WordEdit_addPronunciation_Mutation = {
  response: WordEdit_addPronunciation_Mutation$data;
  variables: WordEdit_addPronunciation_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "ipa"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "word"
},
v2 = [
  {
    "kind": "Variable",
    "name": "ipa",
    "variableName": "ipa"
  },
  {
    "kind": "Literal",
    "name": "sourceName",
    "value": "boldvoice"
  },
  {
    "kind": "Variable",
    "name": "word",
    "variableName": "word"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "nodeID",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "WordEdit_addPronunciation_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Pronunciation",
        "kind": "LinkedField",
        "name": "addIPAToSource",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Word",
            "kind": "LinkedField",
            "name": "word",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "WordEdit_word"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "WordEdit_addPronunciation_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Pronunciation",
        "kind": "LinkedField",
        "name": "addIPAToSource",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Word",
            "kind": "LinkedField",
            "name": "word",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "text",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Pronunciation",
                "kind": "LinkedField",
                "name": "pronunciations",
                "plural": true,
                "selections": [
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "active",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "default",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "bvIPAUnsyllabified",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "bvIPA",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "form",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PronunciationPronunciationSource",
                    "kind": "LinkedField",
                    "name": "sourceEdges",
                    "plural": true,
                    "selections": [
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PronunciationSources",
                        "kind": "LinkedField",
                        "name": "pronunciationSource",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "name",
                            "storageKey": null
                          },
                          (v4/*: any*/),
                          (v3/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "original",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "sourceDefault",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "removed",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "autoRemoved",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PartOfSpeech",
                        "kind": "LinkedField",
                        "name": "partsOfSpeech",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "tag",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "description",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Pronunciation",
                        "kind": "LinkedField",
                        "name": "pronunciation",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          (v4/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v4/*: any*/)
                ],
                "storageKey": null
              },
              (v4/*: any*/)
            ],
            "storageKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f9bc92d9e3a036402c98a91b68bb96d3",
    "id": null,
    "metadata": {},
    "name": "WordEdit_addPronunciation_Mutation",
    "operationKind": "mutation",
    "text": "mutation WordEdit_addPronunciation_Mutation(\n  $word: String!\n  $ipa: String!\n) {\n  addIPAToSource(word: $word, ipa: $ipa, sourceName: \"boldvoice\") {\n    word {\n      ...WordEdit_word\n      nodeID\n    }\n    nodeID\n  }\n}\n\nfragment WordEdit_Pronunciation on Pronunciation {\n  id\n  bvIPAUnsyllabified\n  bvIPA\n  active\n  default\n  form\n  sourceEdges {\n    id\n    pronunciationSource {\n      name\n      nodeID\n    }\n    ...WordEdit_SourceEdge\n    nodeID\n  }\n}\n\nfragment WordEdit_SourceEdge on PronunciationPronunciationSource {\n  id\n  original\n  sourceDefault\n  removed\n  autoRemoved\n  partsOfSpeech {\n    tag\n    description\n  }\n  pronunciation {\n    id\n    nodeID\n  }\n  pronunciationSource {\n    id\n    name\n    nodeID\n  }\n}\n\nfragment WordEdit_word on Word {\n  text\n  pronunciations {\n    id\n    active\n    default\n    ...WordEdit_Pronunciation\n    nodeID\n  }\n}\n"
  }
};
})();

(node as any).hash = "594fa5ab521d0e3fcdedb4a633e52d57";

export default node;
