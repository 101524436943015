/**
 * @generated SignedSource<<2b0adc8542db968d22e90544be688e32>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MultipleChoiceOptionsEditor_CreateMultipleChoiceOption_Mutation$variables = {
  audioUrl?: string | null | undefined;
  isCorrectAnswer?: boolean | null | undefined;
  multipleChoiceQuestionID?: string | null | undefined;
  text?: string | null | undefined;
};
export type MultipleChoiceOptionsEditor_CreateMultipleChoiceOption_Mutation$data = {
  readonly raCreateMultipleChoiceOption: {
    readonly question: {
      readonly options: ReadonlyArray<{
        readonly " $fragmentSpreads": FragmentRefs<"MultipleChoiceOptionsEditor_option">;
      }> | null | undefined;
    } | null | undefined;
  } | null | undefined;
};
export type MultipleChoiceOptionsEditor_CreateMultipleChoiceOption_Mutation = {
  response: MultipleChoiceOptionsEditor_CreateMultipleChoiceOption_Mutation$data;
  variables: MultipleChoiceOptionsEditor_CreateMultipleChoiceOption_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "audioUrl"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isCorrectAnswer"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "multipleChoiceQuestionID"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "text"
},
v4 = [
  {
    "kind": "Variable",
    "name": "audioUrl",
    "variableName": "audioUrl"
  },
  {
    "kind": "Variable",
    "name": "isCorrectAnswer",
    "variableName": "isCorrectAnswer"
  },
  {
    "kind": "Variable",
    "name": "multipleChoiceQuestionID",
    "variableName": "multipleChoiceQuestionID"
  },
  {
    "kind": "Variable",
    "name": "text",
    "variableName": "text"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "nodeID",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "MultipleChoiceOptionsEditor_CreateMultipleChoiceOption_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "MultipleChoiceOption",
        "kind": "LinkedField",
        "name": "raCreateMultipleChoiceOption",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "MultipleChoiceQuestion",
            "kind": "LinkedField",
            "name": "question",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "MultipleChoiceOption",
                "kind": "LinkedField",
                "name": "options",
                "plural": true,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "MultipleChoiceOptionsEditor_option"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v3/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "MultipleChoiceOptionsEditor_CreateMultipleChoiceOption_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "MultipleChoiceOption",
        "kind": "LinkedField",
        "name": "raCreateMultipleChoiceOption",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "MultipleChoiceQuestion",
            "kind": "LinkedField",
            "name": "question",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "MultipleChoiceOption",
                "kind": "LinkedField",
                "name": "options",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "text",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "audioUrl",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "order",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isCorrectAnswer",
                    "storageKey": null
                  },
                  (v5/*: any*/)
                ],
                "storageKey": null
              },
              (v5/*: any*/)
            ],
            "storageKey": null
          },
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c2e05dcc927d4f878f69ea2e8f76454c",
    "id": null,
    "metadata": {},
    "name": "MultipleChoiceOptionsEditor_CreateMultipleChoiceOption_Mutation",
    "operationKind": "mutation",
    "text": "mutation MultipleChoiceOptionsEditor_CreateMultipleChoiceOption_Mutation(\n  $multipleChoiceQuestionID: ID\n  $text: String\n  $audioUrl: String\n  $isCorrectAnswer: Boolean\n) {\n  raCreateMultipleChoiceOption(multipleChoiceQuestionID: $multipleChoiceQuestionID, text: $text, audioUrl: $audioUrl, isCorrectAnswer: $isCorrectAnswer) {\n    question {\n      options {\n        ...MultipleChoiceOptionsEditor_option\n        nodeID\n      }\n      nodeID\n    }\n    nodeID\n  }\n}\n\nfragment MultipleChoiceOptionsEditor_option on MultipleChoiceOption {\n  id\n  text\n  audioUrl\n  order\n  isCorrectAnswer\n}\n"
  }
};
})();

(node as any).hash = "c7d2d6752d10851cc106073c638c7090";

export default node;
