import VideoLibraryIcon from '@material-ui/icons/VideoLibrary';
import { Rating } from '@material-ui/lab';
import { ReactElement } from 'react';
import {
  Datagrid,
  DateField,
  Filter,
  FunctionField,
  List,
  ListProps,
  NumberInput,
  SelectInput,
  TextField,
  TextInput,
} from 'react-admin';

import UserLinkField from './UserLinkField';

const ScoringFeedbackFilter = (props: any) => (
  <Filter {...props}>
    <TextInput label="User ID" source="userID" defaultValue="" />
    <NumberInput
      label="Selected Option"
      source="selectedOption"
      min={1}
      max={5}
      defaultValue={5}
    />

    <TextInput label="Feedback Text" source="feedbackText" defaultValue="" />
    <SelectInput
      label="Has Feedback Text"
      source="hasFeedbackText"
      defaultValue={JSON.stringify({ feedbackText: '', operation: '!=' })}
      choices={[
        {
          id: JSON.stringify({ feedbackText: '', operation: '!=' }),
          name: 'Yes',
        },
        {
          id: JSON.stringify({ feedbackText: '', operation: '=' }),
          name: 'No',
        },
      ]}
    />
  </Filter>
);

const ScoringFeedbackList = (props: ListProps): ReactElement => (
  <List
    {...props}
    sort={{ field: 'createdAt', order: 'DESC' }}
    perPage={25}
    filters={<ScoringFeedbackFilter />}
  >
    <Datagrid optimized>
      <UserLinkField />
      <FunctionField
        render={(record: any) => {
          const { selectedOption } = record;
          return <Rating readOnly value={selectedOption} />;
        }}
        label="Score"
      />
      <TextField source="feedbackText" label="Feedback Text" />
      <DateField source="createdAt" label="Created At" />
    </Datagrid>
  </List>
);

export default {
  list: ScoringFeedbackList,
  icon: VideoLibraryIcon,
};
