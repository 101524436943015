import React, { FC } from 'react';
import {
  AutocompleteArrayInput,
  Create,
  CreateProps,
  ReferenceArrayInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
} from 'react-admin';

import { Typography } from '@material-ui/core';
import OrderedArrayInput from '../components/OrderedArrayInput';
import { matchSuggestion } from '../lesson/utils';

const MouthDiagramCreate: FC<CreateProps> = (props) => (
  <Create {...props}>
    <SimpleForm>
      <Typography variant="h6" gutterBottom style={{ width: '100%' }}>
        Public/Internal Names are optional, Mouth Diagrams are not. Please add
        at least one.
      </Typography>
      <TextInput fullWidth autoFocus source="publicName" label="Public Name" />
      <TextInput
        fullWidth
        autoFocus
        source="internalName"
        label="BoldVoice Internal Name"
      />

      <ReferenceArrayInput
        filterToQuery={(searchText: any) => ({ imageUrl: searchText })}
        label="Mouth Diagrams"
        source="mouthDiagramIds"
        reference="content/mouthDiagram"
        perPage={100}
        fullWidth
      >
        <OrderedArrayInput elementLinkUrl="/#/content/mouthDiagram/">
          <AutocompleteArrayInput
            optionValue="id"
            optionText={(record: any) => `${record?.imageUrl} `}
            matchSuggestion={(filterValue: string, choice: any) =>
              matchSuggestion(filterValue, choice, 'imageUrl')
            }
          />
        </OrderedArrayInput>
      </ReferenceArrayInput>
      <ReferenceInput
        filterToQuery={(searchText: any) => ({ text: searchText })}
        label="Phoneme"
        source="phonemeId"
        reference="content/phoneme"
        perPage={50}
        fullWidth
      >
        <SelectInput optionText="text" source="phonemeId" />
      </ReferenceInput>
    </SimpleForm>
  </Create>
);

export default MouthDiagramCreate;
