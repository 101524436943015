import { Box, Card, CardContent } from '@material-ui/core';
import { Theme, makeStyles } from '@material-ui/core/styles';
import IconContentAdd from '@material-ui/icons/Add';
import { Styles } from '@material-ui/styles/withStyles';
import React, { FC, useEffect, useState } from 'react';
import {
  BooleanInput,
  Button,
  DeleteButton,
  Edit,
  EditProps,
  FieldProps,
  FormWithRedirect,
  NumberInput,
  SaveButton,
  TextInput,
  Toolbar,
} from 'react-admin';

import CreateOrLinkScenarioGoalModal from './CreateOrLinkScenarioGoalModal';
import ScenarioGoalRow from './ScenarioGoalRow';

// import * from '@phosphor-icons/react'

async function getIcon(iconName: any) {
  try {
    // @ts-ignore
    const { [iconName]: iconModule } = await import('@phosphor-icons/react');

    // Check if the icon module exists
    if (!iconModule) {
      throw new Error(`Icon ${iconName} not found`);
    }

    return iconModule;
  } catch (error) {
    console.error(`Error loading icon ${iconName}:`, error);
    return null;
  }
}

const TopicConfigTitle: FC<FieldProps> = ({ record }) =>
  record ? (
    <span>
      Generative Conversation Topic for &quot;
      {record.category}&quot;
    </span>
  ) : null;

export const styles: Styles<Theme, any> = {
  button: {
    marginRight: 10,
  },
};

const useStyles = makeStyles(styles);

const TopicConfigForm = (props: any) => {
  const classes = useStyles(props);
  const [PreviewIcon, setPreviewIcon] = useState<any>();
  const [modalOpen, setModalOpen] = useState(false);

  const { record } = props;

  const openCreateGoalModal = () => {
    setModalOpen(true);
  };

  useEffect(() => {
    if (record.icon) {
      // miracle this works
      getIcon(record.icon).then((icon) => {
        setPreviewIcon(icon);
      });
    }
  }, [record.icon]);

  return (
    <>
      <FormWithRedirect
        {...props}
        redirect={modalOpen ? false : 'edit'}
        render={(formProps: any) => (
          <Card>
            <form>
              <CardContent>
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="flex-start"
                >
                  <h3>Just diplayed to the user</h3>
                  <TextInput
                    autoFocus
                    source="title"
                    label="Title"
                    fullWidth
                    helperText={false}
                    placeholder="The Family Dog"
                  />
                  <TextInput
                    source="subtitle"
                    label="Subtitle"
                    fullWidth
                    helperText={false}
                    placeholder="Chat with a sibling in a coffee shop"
                  />
                  <TextInput
                    multiline
                    source="coachDescription"
                    label="Coach description of scenario"
                    fullWidth
                    helperText={false}
                    placeholder="This will come up before the conversation starts. think of Eliza saying 'For dinner today, you’re getting ingredients at the grocery store...'"
                  />
                  <TextInput
                    source="coachDescriptionAudio"
                    label="Audio version of the coach description"
                    fullWidth
                    helperText={false}
                    placeholder="S3 File location"
                  />
                </Box>
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="flex-start"
                >
                  <h3>Passed to Model</h3>
                  <Box flex={1}>
                    <TextInput
                      source="category"
                      label="Category"
                      fullWidth
                      helperText={false}
                      placeholder="Work, School, Cooking, etc."
                    />
                    <TextInput
                      source="counterpartyArticle"
                      label="Counterparty Article"
                      fullWidth
                      helperText={false}
                      placeholder="the, a, an, your, etc. (will read as 'Kai {ARTICLE} {COUNTERPARTY})"
                    />
                    <TextInput
                      source="counterparty"
                      label="Counterparty"
                      fullWidth
                      helperText={false}
                      placeholder='e.g. "your boss", "your teacher", "your friend"'
                    />

                    <TextInput
                      source="counterpartyGender"
                      label="Counterparty Gender"
                      fullWidth
                      helperText={false}
                      placeholder="F or M"
                    />

                    <TextInput
                      autoFocus
                      source="location"
                      label="Location"
                      fullWidth
                      helperText={false}
                      placeholder='e.g. "at work", "at school", "at home"'
                    />
                    <TextInput
                      autoFocus
                      source="conversation_topic"
                      label="Conversation Topic"
                      fullWidth
                      helperText={false}
                      placeholder='e.g. "your weekend plans", "your favorite food"'
                    />
                    <TextInput
                      source="seedingText"
                      label="Seeding Text"
                      helperText="Fed to GPT before the first chat message. Use this to steer the conversation to this Scenario specifically"
                    />
                  </Box>
                </Box>
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="flex-start"
                >
                  <h3>Meta</h3>
                  <Box
                    flex={1}
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-start"
                  >
                    <Box>
                      <Box display="flex" flexDirection="row">
                        <TextInput
                          source="icon"
                          label="Icon"
                          helperText="https://phosphoricons.com/"
                        />
                        <Box
                          display="flex"
                          flexDirection="column"
                          alignItems="center"
                          justifyContent="center"
                        >
                          {PreviewIcon ? <PreviewIcon size={40} /> : null}
                          <div
                            style={{
                              marginRight: 10,
                              marginLeft: 10,
                              fontSize: '.8em',
                              color: 'gray',
                            }}
                          >
                            Icon Preview
                          </div>
                        </Box>
                      </Box>
                    </Box>
                    <BooleanInput
                      source="requiresSubscription"
                      label="Requires Pro Subscription?"
                      helperText={false}
                    />
                    <TextInput
                      source="level"
                      label="Level"
                      helperText="The bucket that the scenario will be grouped into. Think of this as the 'Unit' of the scenario. Used to organize scenarios in the UI, not sent to the model."
                    />
                    <NumberInput
                      source="difficulty"
                      label="Difficulty"
                      helperText="The order of the scenario within the level. Mainly used for sorting scenarios within a level, not shown to the user, represented as integers."
                    />
                  </Box>
                </Box>
                <Box>
                  <h3>Scenario Specific Goals</h3>

                  <Box
                    display="flex"
                    flexWrap="wrap"
                    style={{
                      columnGap: 20,
                    }}
                  >
                    {record?.scenarioSpecificGoalTemplates?.map((goal: any) => (
                      <ScenarioGoalRow goal={goal} key={goal.id} />
                    ))}
                    <Button
                      onClick={openCreateGoalModal}
                      label="ra.action.create"
                    >
                      <IconContentAdd />
                    </Button>
                  </Box>
                </Box>

                <Toolbar
                  record={formProps.record}
                  basePath={formProps.basePath}
                  mutationMode={formProps.mutationMode}
                  invalid={formProps.invalid}
                  handleSubmit={modalOpen ? () => null : formProps.handleSubmit}
                  saving={formProps.saving}
                  resource="generative_conversation/topic_config"
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <div>
                    <SaveButton
                      label="Save & Continue"
                      handleSubmitWithRedirect={
                        modalOpen
                          ? () => null
                          : formProps.handleSubmitWithRedirect ||
                            formProps.handleSubmit
                      }
                      disabled={formProps.disabled}
                      invalid={formProps.invalid}
                      redirect="edit"
                      saving={formProps.saving}
                      submitOnEnter={formProps.submitOnEnter}
                      className={classes.button}
                    />

                    <SaveButton
                      label="Save & Exit"
                      handleSubmitWithRedirect={
                        modalOpen
                          ? () => null
                          : formProps.handleSubmitWithRedirect ||
                            formProps.handleSubmit
                      }
                      disabled={formProps.disabled}
                      invalid={formProps.invalid}
                      redirect={formProps.redirect}
                      saving={formProps.saving}
                      submitOnEnter={formProps.submitOnEnter}
                      className={classes.button}
                    />
                  </div>

                  {formProps.record &&
                    typeof formProps.record.id !== 'undefined' && (
                      <DeleteButton
                        basePath={formProps.basePath}
                        record={formProps.record}
                        resource={formProps.resource}
                        undoable={formProps.undoable}
                        mutationMode={formProps.mutationMode}
                      />
                    )}
                </Toolbar>
              </CardContent>
            </form>
          </Card>
        )}
      />
      <CreateOrLinkScenarioGoalModal
        record={record}
        modalOpen={modalOpen}
        onModalClose={() => setModalOpen(false)}
      />
    </>
  );
};

const TopicConfigEdit: FC<EditProps> = (props) => (
  <Edit undoable={false} title={<TopicConfigTitle />} {...props}>
    <TopicConfigForm />
  </Edit>
);

export default TopicConfigEdit;
