import { Box, Card, CardContent } from '@material-ui/core';
import { Theme, makeStyles } from '@material-ui/core/styles';
import { Styles } from '@material-ui/styles/withStyles';
import React, { FC } from 'react';
import {
  AutocompleteArrayInput,
  Button,
  DeleteButton,
  Edit,
  EditProps,
  FieldProps,
  FormWithRedirect,
  NumberInput,
  ReferenceArrayInput,
  ReferenceInput,
  SaveButton,
  SelectInput,
  TextInput,
  Toolbar,
  useDataProvider,
  useNotify,
} from 'react-admin';

import MouthDiagramsDisplay from '../components/MouthDiagramsDisplay';
import { matchSuggestion } from '../lesson/utils';
import { MouthDiagram } from '../mouthDiagram';
import { useMouthDiagramImages } from './utils';

const MouthDiagramSetTitle: FC<FieldProps> = ({ record }) =>
  record ? (
    <span>&quot;{record.publicName}&quot; Mouth Diagram Set</span>
  ) : null;

export const styles: Styles<Theme, any> = {
  name: { display: 'inline-block' },
  photo: { display: 'inline-block' },
  spacing: { marginRight: 10 },
  image: {
    margin: '0.5rem',
    maxHeight: '10rem',
  },
  button: {
    marginRight: 10,
  },
};

const useStyles = makeStyles(styles);

const DuplicateButton = ({ record }: any) => {
  const dataProvider = useDataProvider();
  const notify = useNotify();

  const handleDuplicate = async () => {
    try {
      // Make a copy of the existing record with "duplicate" appended
      const duplicatedRecord = {
        ...record,
        id: undefined,
        publicName: `${record.publicName} (duplicate)`,
      };

      // Create a new record with the duplicated data
      const { data } = await dataProvider.create('content/mouthDiagramSet', {
        data: duplicatedRecord,
      });

      notify('Record duplicated successfully', 'info');
      window.open(`#/content/mouthDiagramSet/${data.id}/`, '_blank');
    } catch (error) {
      notify('Error duplicating record', 'error');
      console.error('Error duplicating record:', error);
    }
  };

  return (
    <Button color="primary" onClick={handleDuplicate}>
      <>Duplicate</>
    </Button>
  );
};

const MouthDiagramSetForm = (props: any) => {
  const classes = useStyles(props);

  const { urls } = useMouthDiagramImages(props.record.mouthDiagrams);

  return (
    <FormWithRedirect
      {...props}
      render={(formProps: any) => {
        const sortedDiagrams = formProps.record?.mouthDiagrams?.sort(
          (a: MouthDiagram, b: MouthDiagram) =>
            a.MouthDiagramSetItem.orderNumber -
            b.MouthDiagramSetItem.orderNumber,
        );

        return (
          <Card>
            <form>
              <CardContent>
                <Box>
                  <DuplicateButton record={formProps.record} />
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="center"
                    marginBottom={2}
                    marginTop={1}
                  >
                    <MouthDiagramsDisplay diagrams={sortedDiagrams} />
                  </Box>
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="center"
                    gridColumnGap={12}
                  >
                    {sortedDiagrams?.map(
                      (diagram: MouthDiagram, mdIdx: number) => (
                        <Card>
                          <CardContent>
                            <Box display="flex" justifyContent="center">
                              <img
                                src={urls[diagram.imageUrl]}
                                style={{
                                  aspectRatio: '16/9',
                                  height: 200,
                                  borderRadius: 14,
                                  marginTop: 5,
                                }}
                                alt="mouth diagram display"
                              />
                            </Box>

                            <NumberInput
                              fullWidth
                              source={`mouthDiagrams[${mdIdx}].MouthDiagramSetItem.duration`}
                              label="Duration (In Milliseconds)"
                            />
                            <NumberInput
                              fullWidth
                              source={`mouthDiagrams[${mdIdx}].MouthDiagramSetItem.orderNumber`}
                              label="Order Number"
                            />
                          </CardContent>
                        </Card>
                      ),
                    )}
                  </Box>
                  <Box marginTop={2}>
                    <ReferenceArrayInput
                      filterToQuery={(searchText: any) => ({
                        imageUrl: searchText,
                      })}
                      label="Mouth Diagrams"
                      source="mouthDiagramIds"
                      reference="content/mouthDiagram"
                      perPage={50}
                      fullWidth
                    >
                      <AutocompleteArrayInput
                        optionValue="id"
                        // onSelect={(value: any) => {
                        //   console.log('value on select', value);
                        // }}
                        allowDuplicates
                        optionText={(record: any) => `${record?.imageUrl} `}
                        matchSuggestion={(filterValue: string, choice: any) =>
                          matchSuggestion(filterValue, choice, 'imageUrl')
                        }
                      />
                    </ReferenceArrayInput>
                  </Box>
                  <TextInput
                    fullWidth
                    autoFocus
                    source="publicName"
                    label="Public Name"
                  />
                  <TextInput
                    fullWidth
                    autoFocus
                    source="internalName"
                    label="BoldVoice Internal Name"
                  />
                  <Box>
                    <ReferenceInput
                      filterToQuery={(searchText: any) => ({
                        text: searchText,
                      })}
                      label="Phoneme"
                      source="phonemeId"
                      reference="content/phoneme"
                      perPage={50}
                      fullWidth
                    >
                      <SelectInput optionText="text" source="phonemeId" />
                    </ReferenceInput>
                  </Box>

                  <TextInput
                    multiline
                    source="adminNotes"
                    label="Admin Notes"
                    fullWidth
                    helperText={false}
                  />
                </Box>

                <Toolbar
                  record={formProps.record}
                  basePath={formProps.basePath}
                  mutationMode="undoable"
                  invalid={formProps.invalid}
                  handleSubmit={formProps.handleSubmit}
                  saving={formProps.saving}
                  resource="phonemes"
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <div>
                    <SaveButton
                      label="Save & Continue"
                      handleSubmitWithRedirect={
                        formProps.handleSubmitWithRedirect ||
                        formProps.handleSubmit
                      }
                      disabled={formProps.disabled}
                      invalid={formProps.invalid}
                      redirect="edit"
                      saving={formProps.saving}
                      submitOnEnter={formProps.submitOnEnter}
                      className={classes.button}
                    />

                    <SaveButton
                      label="Save & Exit"
                      handleSubmitWithRedirect={
                        formProps.handleSubmitWithRedirect ||
                        formProps.handleSubmit
                      }
                      disabled={formProps.disabled}
                      invalid={formProps.invalid}
                      redirect={formProps.redirect}
                      saving={formProps.saving}
                      submitOnEnter={formProps.submitOnEnter}
                      className={classes.button}
                    />
                  </div>

                  {formProps.record &&
                    typeof formProps.record.id !== 'undefined' && (
                      <DeleteButton
                        basePath={formProps.basePath}
                        record={formProps.record}
                        resource={formProps.resource}
                        undoable={formProps.undoable}
                        mutationMode={formProps.mutationMode}
                      />
                    )}
                </Toolbar>
              </CardContent>
            </form>
          </Card>
        );
      }}
    />
  );
};

const MouthDiagramSetEdit: FC<EditProps> = (props) => (
  <Edit mutationMode="optimistic" title={<MouthDiagramSetTitle />} {...props}>
    <MouthDiagramSetForm />
  </Edit>
);

export default MouthDiagramSetEdit;
