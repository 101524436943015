/**
 * @generated SignedSource<<01fb651ecc4b6db453921b42db4317b9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type IPAText_Query$variables = Record<PropertyKey, never>;
export type IPAText_Query$data = {
  readonly bvSegments: ReadonlyArray<{
    readonly aliases: ReadonlyArray<string> | null | undefined;
    readonly ipa: string | null | undefined;
    readonly representations: ReadonlyArray<{
      readonly alphabetDisplayName: string | null | undefined;
      readonly representation: string | null | undefined;
    }> | null | undefined;
  }> | null | undefined;
};
export type IPAText_Query = {
  response: IPAText_Query$data;
  variables: IPAText_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ipa",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "aliases",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "SegmentRepresentation",
  "kind": "LinkedField",
  "name": "representations",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "alphabetDisplayName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "representation",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "IPAText_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Segment",
        "kind": "LinkedField",
        "name": "bvSegments",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "IPAText_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Segment",
        "kind": "LinkedField",
        "name": "bvSegments",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "nodeID",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "9c232a51b4529082556e7e2abe6dfab4",
    "id": null,
    "metadata": {},
    "name": "IPAText_Query",
    "operationKind": "query",
    "text": "query IPAText_Query {\n  bvSegments {\n    ipa\n    aliases\n    representations {\n      alphabetDisplayName\n      representation\n    }\n    nodeID\n  }\n}\n"
  }
};
})();

(node as any).hash = "ec1f3df44112f55bfffb26566e1905ab";

export default node;
