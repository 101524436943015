import * as React from 'react';
import {
  Datagrid,
  DateField,
  Filter,
  FunctionField,
  List,
  NumberInput,
  SelectInput,
  TextInput,
} from 'react-admin';

import AudioField from './AudioField';
import AudioLogShow from './AudioLogShow';
import PracticeLinkField from './PracticeLinkField';
import UserLinkField from './UserLinkField';

const AudioLogFilter = (props: any) => (
  <Filter {...props}>
    <TextInput label="ID" source="id" defaultValue="" />
    <TextInput label="Request ID" source="requestId" defaultValue="" />
    <TextInput label="User ID" source="User.id" defaultValue="" />
    <TextInput
      label="User Email (Exact Match Only)"
      source="User.email"
      defaultValue=""
    />
    <TextInput
      label="User First Name"
      source="User.firstName"
      defaultValue=""
    />
    <TextInput label="User Language" source="User.language" defaultValue="" />
    <TextInput label="User Last Name" source="User.lastName" defaultValue="" />
    <TextInput label="Word ID" source="PracticeItem.id" defaultValue="" />
    <TextInput label="Lesson ID" source="lessonId" defaultValue="" />
    <TextInput label="Word Text" source="PracticeItem.word" defaultValue="" />
    <NumberInput label="SpeechAce Score" source="score" defaultValue="" />
    <NumberInput label="Score Less Than" source="scoreLessThan" />
    <NumberInput label="Score Greater Than" source="scoreGreaterThan" />
    <SelectInput
      label="Model"
      source="model_name"
      choices={[
        { id: 'speechace', name: 'Speechace' },
        { id: 'boldvoice', name: 'BoldVoice' },
      ]}
      defaultValue={null}
    />

    {/* <TextInput label="Audio File Link" source="audioFile" defaultValue="" /> */}
  </Filter>
);

export const AudioLogList = (props: any) => (
  <List
    {...props}
    perPage={10}
    sort={{ field: 'createdAt', order: 'DESC' }}
    filters={<AudioLogFilter />}
  >
    <Datagrid rowClick="expand" expand={<AudioLogShow />}>
      <UserLinkField />
      <PracticeLinkField />
      <FunctionField
        render={(record: any) => {
          const { speechace } = record;
          let parsedSpeechace;
          try {
            parsedSpeechace = JSON.parse(speechace);
            const { quality_score, original_quality_score } = parsedSpeechace;
            return `QS: ${quality_score}%; ORIG: ${original_quality_score}%`;
          } catch (err) {
            return speechace;
          }
        }}
        label="Speech Ace Scores"
      />
      <AudioField />
      <DateField showTime source="createdAt" label="Created" />
    </Datagrid>
  </List>
);

export default AudioLogList;
