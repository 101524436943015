import Icon from '@material-ui/icons/RecordVoiceOver';

import PhonemeList from './PhonemeList';
import PhonemeEdit from './PhonemeEdit';
import PhonemeCreate from './PhonemeCreate';

export type Phoneme = {
  id: string;
  arpabet: string;
  unicode: string;
  ipa: string;
  text: string;
  tip: string;
  examples: any;
  related: any;
  fullCoachExample: string;
  orderNumber: number;
  createdAt: Date;
  updatedAt: Date;
};

export default {
  list: PhonemeList,
  edit: PhonemeEdit,
  icon: Icon,
  create: PhonemeCreate,
};
