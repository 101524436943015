import {
  Box,
  Card,
  CardContent,
  Checkbox,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { Theme, makeStyles } from '@material-ui/core/styles';
import { DataGrid } from '@material-ui/data-grid';
import { Styles } from '@material-ui/styles/withStyles';
import moment from 'moment';
import React, { FC, useState } from 'react';
import {
  BooleanInput,
  Edit,
  EditProps,
  FormWithRedirect,
  Link,
  NumberInput,
  SaveButton,
  SelectInput,
  TextInput,
  Toolbar,
} from 'react-admin';

import api from '../../api';
import { maybePluralize } from '../common/utils';

const Statistic = ({ value, label }: { value: any; label: string }) => (
  <Box
    display="flex"
    flex={1}
    flexDirection="column"
    justifyContent="center"
    alignItems="center"
    border="1px solid #e0e0e0"
    padding="1.5rem"
    borderRadius={10}
  >
    <Typography variant="h3" gutterBottom>
      {value}
    </Typography>
    <Typography variant="caption">{label}</Typography>
  </Box>
);

export const styles: Styles<Theme, any> = {
  name: { display: 'inline-block' },
  photo: { display: 'inline-block' },
  spacing: { marginRight: 10 },
  image: {
    margin: '0.5rem',
    maxHeight: '10rem',
  },
  button: {
    marginRight: 10,
  },
};

const useStyles = makeStyles(styles);

const ReferralCodeForm = (props: any) => {
  const classes = useStyles(props);
  const [showPayoutEditAlert, setShowPayoutEditAlert] = useState(false);

  return (
    <FormWithRedirect
      {...props}
      render={(formProps: any) => (
        <Card>
          <form>
            <CardContent>
              <Box flex={2} mr={{ md: 0, lg: '1em' }}>
                <Typography variant="h6" gutterBottom>
                  {props.record.owner.firstName}
                  {props.record.owner.lastName &&
                    ` ${props.record.owner.lastName}`}
                  &apos;s Referral Code
                </Typography>
                <Typography variant="body1" gutterBottom color="textSecondary">
                  Created {moment(props.record.createdAt).format('YYYY-MM-DD')}
                </Typography>
              </Box>
              <Box display="flex" mt="1rem">
                <TextInput
                  autoFocus
                  source="slug"
                  label="Url Slug"
                  className={classes.name}
                />
                <Box
                  ml="1rem"
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <BooleanInput label="Is Affiliate" source="isAffiliate" />
                </Box>
              </Box>
              <Box mt="1rem">
                <Typography variant="h6" gutterBottom>
                  Payout Details
                </Typography>
                <Box display="flex" flexDirection="row">
                  <Box display="flex" flexDirection="column" borderRadius={10}>
                    <NumberInput
                      source="payout_amount"
                      helperText="In cents"
                      onFocus={() => setShowPayoutEditAlert(true)}
                    />
                  </Box>
                  <Box
                    flex
                    display={showPayoutEditAlert ? 'flex' : 'none'}
                    padding="1rem"
                    justifyContent="center"
                    flexDirection="column"
                  >
                    <Typography variant="body1" gutterBottom color="error">
                      ⚠️ There is no history of payout amounts.
                    </Typography>
                    <Typography
                      variant="body2"
                      gutterBottom
                      color="error"
                      style={{ marginLeft: '2rem' }}
                    >
                      - Change this and the whole history will change.
                    </Typography>

                    <Typography
                      variant="body2"
                      gutterBottom
                      color="error"
                      style={{ marginLeft: '2rem' }}
                    >
                      - Make sure you know what you&apos;re doing
                    </Typography>
                  </Box>
                </Box>
                <Box display={{ xs: 'block', sm: 'flex' }}>
                  <Box mr={{ xs: 0, sm: '0.5em' }}>
                    <SelectInput
                      source="payout_type"
                      choices={[
                        { id: 'cash', name: 'Cash' },
                        { id: 'gift_card', name: 'Gift Card' },
                      ]}
                      helperText={"How to pay out referrer's rewards"}
                    />
                  </Box>
                  <Box ml={{ xs: 0, sm: '0.5em' }}>
                    <TextInput
                      source="payout_timeout"
                      fullWidth
                      type="number"
                      helperText={
                        "How long to wait in days before paying out referral's rewards"
                      }
                    />
                  </Box>
                  <Tooltip title="Can't change for now">
                    <Box ml="1em">
                      <Typography variant="caption" color="secondary">
                        Payout Substatus threshold
                      </Typography>
                      <Typography variant="body1" align="right">
                        {props.record.payout_substatus_threshold}
                      </Typography>
                    </Box>
                  </Tooltip>
                </Box>
                <Box mt="2rem">
                  <Typography variant="h6" gutterBottom>
                    Admin
                  </Typography>
                  <Box display="flex" flexDirection="row" alignItems="center">
                    <img
                      src="https://media.tenor.com/4hXv71S_-bMAAAAi/ban-ban-hammer.gif"
                      alt="ban hammer"
                      style={{
                        marginRight: '1rem',
                        height: '4.5rem',
                      }}
                    />
                    <Box display="flex" flexDirection="column">
                      <BooleanInput
                        source="payout_eligible"
                        label="Payout Eligible"
                      />
                      <Typography variant="caption">
                        Ban user receiving payouts
                      </Typography>
                    </Box>
                    <Box ml="1rem">
                      <TextInput
                        source="redeemer_discount"
                        label="Redeemer Discount"
                        helperText="Leave blank for no discount. Use a stripe discount code to apply a discount."
                        style={{ width: '15rem' }}
                        className={classes.name}
                      />
                    </Box>
                    <Box alignSelf="flex-start">
                      <TextInput
                        disabled
                        source="minimum_subscription_length_required"
                        label="Sub Length Required"
                        helperText="In months"
                        className={classes.name}
                      />
                    </Box>
                  </Box>
                  <Box>
                    <NumberInput
                      source="extra_owed"
                      label="Extra $ Owed"
                      helperText="Amount owed to referrer from various things they do for us"
                      style={{ width: '15rem' }}
                      className={classes.name}
                    />
                    <NumberInput
                      source="extra_paid"
                      label="Extra $ Paid"
                      helperText="Amount ALREADY paid to referrer from various things they do for us"
                      style={{ width: '15rem' }}
                      className={classes.name}
                    />
                    <NumberInput
                      source="number_of_qualifying_actions"
                      label="# qualifying actions"
                      helperText="Aka how many videos have they produced for us"
                      style={{ width: '15rem' }}
                      className={classes.name}
                    />
                    <Typography variant="body1">
                      Everything is in cents
                    </Typography>
                    <TextInput
                      multiline
                      source="admin_notes"
                      label="Admin Notes"
                      fullWidth
                      helperText={false}
                    />
                  </Box>
                </Box>
              </Box>
              <Toolbar
                record={formProps.record}
                basePath={formProps.basePath}
                undoable={false}
                invalid={formProps.invalid}
                handleSubmit={formProps.handleSubmit}
                saving={formProps.saving}
                resource="logs/referral_codes"
                style={{ display: 'flex', justifyContent: 'space-between' }}
              >
                <div>
                  <SaveButton
                    label="Save & Continue"
                    handleSubmitWithRedirect={
                      formProps.handleSubmitWithRedirect ||
                      formProps.handleSubmit
                    }
                    disabled={formProps.disabled}
                    invalid={formProps.invalid}
                    redirect="edit"
                    saving={formProps.saving}
                    submitOnEnter={formProps.submitOnEnter}
                    className={classes.button}
                  />

                  <SaveButton
                    label="Save & Exit"
                    handleSubmitWithRedirect={
                      formProps.handleSubmitWithRedirect ||
                      formProps.handleSubmit
                    }
                    disabled={formProps.disabled}
                    invalid={formProps.invalid}
                    redirect={formProps.redirect}
                    saving={formProps.saving}
                    submitOnEnter={formProps.submitOnEnter}
                    className={classes.button}
                  />
                </div>
              </Toolbar>
              <Box style={{ marginTop: 10 }}>
                <Typography variant="h6" gutterBottom align="left">
                  Redemptions
                </Typography>
                <Box
                  display="flex"
                  justifyContent="space-around"
                  mb="1.5rem"
                  gridColumnGap="2.5rem"
                >
                  <Statistic
                    value={props.record.number_of_payouts_in_timeout || 0}
                    label={`${maybePluralize(
                      props.record.number_of_payouts_in_timeout,
                      'Subscriber',
                    )} in waiting period`}
                  />
                  <Statistic
                    value={`$${
                      (props.record?.currently_owed_to_owner || 0) / 100
                    }`}
                    label="Owed"
                  />
                  <Statistic
                    value={`$${(props.record?.paid_out_to_owner || 0) / 100}`}
                    label="Total Paid"
                  />
                  <Statistic
                    value={`$${
                      (props.record?.total_owner_earnings || 0) / 100
                    }`}
                    label="Total Earned"
                  />
                </Box>
                <div style={{ marginBottom: 10 }}>
                  Owner IP Address:{' '}
                  {props.record?.owner?.ipAddressList?.join(', ') ||
                    props.record?.owner?.ipAddress ||
                    'unavailable'}
                </div>
                <DataGrid
                  autoHeight
                  rows={props.record?.redemptions || []}
                  columns={[
                    {
                      field: 'id',
                      headerName: 'Redemption Id',
                      flex: 1,
                      valueGetter: ({ row }) => row?.id,
                    },
                    {
                      field: 'userLink',
                      headerName: 'User',
                      flex: 1,
                      renderCell: ({ row }) => {
                        const isRealUser = row.userId;
                        let href = '';
                        if (isRealUser) {
                          href = `/users/${row.userId}`;
                        } else {
                          href = `/provisional_users/${row.provisionalUserId}`;
                        }
                        const label = row?.redeemer
                          ? `${row.redeemer.firstName} ${row.redeemer.lastName}`
                          : row?.provisionalUserId
                            ? `Prov: ${row.provisionalUserId?.slice(0, 8)}`
                            : '';
                        return (
                          <Link to={href}>
                            <div>{label}</div>
                          </Link>
                        );
                      },
                    },
                    {
                      field: 'ipAddressList',
                      headerName: 'IP Address(es)',
                      flex: 1,
                      renderCell: ({ row }) => {
                        const label = row?.redeemer
                          ? row?.redeemer?.ipAddressList?.join(', ') ||
                            row?.redeemer?.ipAddress
                          : row?.provisionalUserId
                            ? row?.prov_redeemer?.ipAddressList?.join(', ') ||
                              row?.prov_redeemer?.ipAddress
                            : '';
                        return (
                          <div>
                            <div>{label}</div>
                          </div>
                        );
                      },
                    },
                    {
                      field: 'signedUpOn',
                      headerName: 'First click',
                      flex: 1,
                      valueGetter: ({ row }) =>
                        moment(row?.createdAt).format('YYYY-MM-DD'),
                    },
                    {
                      field: 'createdAt',
                      headerName: 'Signed up',
                      flex: 1,
                      renderCell: ({ row }) =>
                        row?.signedUpOn ? (
                          <div>
                            {moment(row?.signedUpOn).format('YYYY-MM-DD')}
                          </div>
                        ) : (
                          <div></div>
                        ),
                    },
                    {
                      field: 'subscribedOn',
                      headerName: 'Subscribed on',
                      flex: 1,
                      renderCell: ({ row }) => {
                        if (row.redeemer) {
                          const productIdentifier =
                            row.redeemer.subscriptionData?.activeSubscriptions
                              ?.subscription?.product_identifier;
                          const activeProduct =
                            row.redeemer.subscriptionData?.subscriptions?.[
                              productIdentifier
                            ];
                          if (activeProduct?.purchase_date) {
                            return (
                              <div>
                                {moment(
                                  activeProduct?.original_purchase_date,
                                ).format('YYYY-MM-DD')}
                              </div>
                            );
                          }
                        }
                        return <div></div>;
                      },
                    },
                    {
                      field: 'payoutStatus',
                      headerName: 'Payout Status',
                      flex: 1,
                      renderCell: ({ row }) => {
                        if (row.payoutRequirementWillBeSatisfiedAt) {
                          const isPayoutEligible = moment().isAfter(
                            row.payoutRequirementWillBeSatisfiedAt,
                          );
                          if (isPayoutEligible) {
                            if (row.paidOut) {
                              return 'paid';
                            }
                            return 'to be paid';
                          }
                          return 'in holding period';
                        }
                        return <div></div>;
                      },
                    },
                    {
                      field: 'paidOut',
                      headerName: 'Paid Out',
                      flex: 1,
                      renderCell: ({ row }) => {
                        // checkbox that updates backend
                        if (!row.payoutRequirementWillBeSatisfiedAt) {
                          return <div></div>;
                        }

                        const inHoldingPeriod = moment().isBefore(
                          row.payoutRequirementWillBeSatisfiedAt,
                        );

                        const adminPayer = row?.payoutLogger?.firstName
                          ? `${row?.payoutLogger?.firstName} ${row?.payoutLogger?.lastName}`
                          : row.paidOutBy;

                        return (
                          <Tooltip
                            title={
                              inHoldingPeriod
                                ? 'In holding period'
                                : row.paidOut
                                  ? `Marked paid by ${adminPayer}`
                                  : 'Pending Payout'
                            }
                          >
                            <Checkbox
                              disabled={inHoldingPeriod}
                              checked={row.paidOut}
                              onChange={async (e) => {
                                const { checked } = e.target;
                                try {
                                  await api.updateReferralRedemption(row.id, {
                                    paidOut: checked,
                                  });
                                  window.location.reload();
                                } catch (err) {
                                  console.error(err);
                                }
                              }}
                            />
                          </Tooltip>
                        );
                      },
                    },
                  ]}
                />
              </Box>
            </CardContent>
          </form>
        </Card>
      )}
    />
  );
};

const ReferralCodeEdit: FC<EditProps> = (props) => (
  <Edit undoable={false} {...props}>
    <ReferralCodeForm />
  </Edit>
);

export default ReferralCodeEdit;
