/**
 * @generated SignedSource<<6ca15f3ab4d24b2c2cd5a800cee7582e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PracticesSelect_practicesByIDs$data = {
  readonly practiceTextsByIDs: ReadonlyArray<{
    readonly aiCoachSample: string | null | undefined;
    readonly coachSample: string | null | undefined;
    readonly id: string | null | undefined;
    readonly isSentence: boolean | null | undefined;
    readonly text: string | null | undefined;
  }> | null | undefined;
  readonly " $fragmentType": "PracticesSelect_practicesByIDs";
};
export type PracticesSelect_practicesByIDs$key = {
  readonly " $data"?: PracticesSelect_practicesByIDs$data;
  readonly " $fragmentSpreads": FragmentRefs<"PracticesSelect_practicesByIDs">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": [],
      "kind": "LocalArgument",
      "name": "ids"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [],
      "operation": require('./PracticesSelect_practicesByIDs_RefetchQuery.graphql')
    }
  },
  "name": "PracticesSelect_practicesByIDs",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "ids",
          "variableName": "ids"
        }
      ],
      "concreteType": "PracticeText",
      "kind": "LinkedField",
      "name": "practiceTextsByIDs",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "text",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "coachSample",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "aiCoachSample",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isSentence",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "3dee781a6e905a15a287eb8c75612943";

export default node;
