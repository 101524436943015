/**
 * @generated SignedSource<<9076f837438544306529d04fb5753626>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SkillFormAssociatedMultipleChoiceQuestions_skill$data = {
  readonly allMultipleChoiceQuestions: ReadonlyArray<{
    readonly id: string | null | undefined;
    readonly text: string | null | undefined;
  }> | null | undefined;
  readonly id: string | null | undefined;
  readonly " $fragmentType": "SkillFormAssociatedMultipleChoiceQuestions_skill";
};
export type SkillFormAssociatedMultipleChoiceQuestions_skill$key = {
  readonly " $data"?: SkillFormAssociatedMultipleChoiceQuestions_skill$data;
  readonly " $fragmentSpreads": FragmentRefs<"SkillFormAssociatedMultipleChoiceQuestions_skill">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SkillFormAssociatedMultipleChoiceQuestions_skill",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "MultipleChoiceQuestion",
      "kind": "LinkedField",
      "name": "allMultipleChoiceQuestions",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "text",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Skill",
  "abstractKey": null
};
})();

(node as any).hash = "fb529058900e10b5aa2295dd975de769";

export default node;
