import { TextInput } from 'react-admin';

const SkillFormFields = () => (
  <>
    <TextInput
      autoFocus
      source="title"
      label="Title"
      fullWidth
      helperText={false}
    />
  </>
);

export default SkillFormFields;
