import * as React from 'react';
import {
  Datagrid,
  DateField,
  List,
  TextField,
  BooleanField,
  ListProps,
  useGetList,
  SelectInput,
} from 'react-admin';
import { FC } from 'react';
import { Link, FieldProps, Filter, TextInput, BooleanInput } from 'react-admin';

import LessonLinkField from './LessonLinkField';
import ConversationLinkField from './ConversationLinkField';
import VideoLinkField from './VideoLinkField';

import PracticeListAside from './PracticeListAside';
import { ReactElement } from 'react';

import { makeStyles, Theme } from '@material-ui/core/styles';
import { Styles } from '@material-ui/styles/withStyles';
import AudioField from './AudioField';
import TruncatedIdField from './TruncatedIdField';
import { Tooltip } from '@material-ui/core';

export const styles: Styles<Theme, any> = {
  maxTwenty: {
    maxWidth: 300,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
};

const useStyles = makeStyles(styles);

const PracticeLinkField: FC<FieldProps> = (props) =>
  props.record ? (
    <Link to={`/content/practice/${props.record.id}`}>{props.record.word}</Link>
  ) : null;

PracticeLinkField.defaultProps = {
  source: 'word',
  addLabel: true,
};

const PracticeFilter = (props: any) => (
  <Filter {...props}>
    <TextInput label="Text" source="word" defaultValue="" />

    <BooleanInput
      label="Is Sentence?"
      source="isSentence"
      defaultValue={false}
    />
    <BooleanInput
      label="Is Instruction?"
      source="isInstruction"
      defaultValue={false}
    />
    <BooleanInput
      label="Is User Generated?"
      source="isUserGeneratedContent"
      defaultValue
    />
    <BooleanInput label="Has Audio?" source="hasAudio" defaultValue />

    <BooleanInput label="Has Ipa?" source="hasIpa" defaultValue />

    <BooleanInput label="Has Syllables?" source="hasSyllables" defaultValue />
    <SelectInput
      source="coachId"
      label="Coach"
      choices={Object.values(props.coaches)}
    />
  </Filter>
);

const PracticeList = (props: ListProps): ReactElement => {
  const classes = useStyles(props);
  const { data: coachData } = useGetList('coach');

  return (
    <List
      {...props}
      sort={{ field: 'word', order: 'ASC' }}
      perPage={25}
      filters={<PracticeFilter coaches={coachData} />}
      aside={<PracticeListAside />}
      filterDefaultValues={{ isUserGeneratedContent: false }}
    >
      <Datagrid optimized>
        <TruncatedIdField
          sortable={false}
          label={
            <Tooltip placement="bottom" title="First 4 of UUID">
              <div>Id</div>
            </Tooltip>
          }
        />
        <PracticeLinkField cellClassName={classes.maxTwenty} label="Text" />
        <TextField label="Coach" source="Coach.name" />
        <AudioField sortBy="coachSample" />
        <TextField source="syllables" />
        <TextField source="ipaFull" />
        <BooleanField source="isSentence" label="Is Sentence?" />
        <LessonLinkField />
        <ConversationLinkField />
        <VideoLinkField />
        <DateField showTime source="createdAt" label="Created" />
      </Datagrid>
    </List>
  );
};

export default PracticeList;
