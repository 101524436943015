/**
 * @generated SignedSource<<c90fbfeace9d1e978cfb0b5a3e61f9f6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PracticesSelect_practicesForString$data = {
  readonly practiceTextsForString: {
    readonly nodes: ReadonlyArray<{
      readonly aiCoachSample: string | null | undefined;
      readonly coachSample: string | null | undefined;
      readonly id: string | null | undefined;
      readonly text: string | null | undefined;
    } | null | undefined>;
  } | null | undefined;
  readonly " $fragmentType": "PracticesSelect_practicesForString";
};
export type PracticesSelect_practicesForString$key = {
  readonly " $data"?: PracticesSelect_practicesForString$data;
  readonly " $fragmentSpreads": FragmentRefs<"PracticesSelect_practicesForString">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": "",
      "kind": "LocalArgument",
      "name": "search"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [],
      "operation": require('./PracticesSelect_practicesForString_RefetchQuery.graphql')
    }
  },
  "name": "PracticesSelect_practicesForString",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 20
        },
        {
          "kind": "Variable",
          "name": "search",
          "variableName": "search"
        }
      ],
      "concreteType": "QueryPracticeTextsForStringConnection",
      "kind": "LinkedField",
      "name": "practiceTextsForString",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "PracticeText",
          "kind": "LinkedField",
          "name": "nodes",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "id",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "text",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "coachSample",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "aiCoachSample",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "8f429b538cc185116cbd12e922e851c8";

export default node;
