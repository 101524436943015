import * as MUI from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import graphql from 'babel-plugin-relay/macro';
import React from 'react';
import { useLazyLoadQuery } from 'react-relay';

const SkillLessonSelect = (props: any) => (
  <React.Suspense
    fallback={<SkillLessonSelectField {...props} isLoading skillLessons={[]} />}
  >
    <SkillLessonSelectWithData {...props} />
  </React.Suspense>
);

const SkillLessonSelectWithData = (props: any) => {
  const [isRefreshPending, startRefreshTransition] = React.useTransition();
  const [refreshedQueryOptions, setRefreshedQueryOptions] = React.useState({});

  const data: any = useLazyLoadQuery(
    graphql`
      query SkillLessonSelect_allSkillLessons_Query {
        allNonLinkedSkillLessons {
          id
          video {
            title
          }
          conversation {
            name
          }
          skill {
            id
          }
        }
      }
    `,
    {},
    refreshedQueryOptions,
  );

  const refresh = React.useCallback(() => {
    startRefreshTransition(() => {
      setRefreshedQueryOptions((prev: any) => ({
        fetchKey: (prev.fetchKey ?? 0) + 1,
        fetchPolicy: 'network-only',
      }));
    });
  }, [startRefreshTransition, setRefreshedQueryOptions]);

  return (
    <SkillLessonSelectField
      {...props}
      skillLessons={data.allNonLinkedSkillLessons}
      onRefresh={refresh}
      isRefreshPending={isRefreshPending}
    />
  );
};

const SkillLessonSelectField = ({
  skillLessons,
  onChange,
  value,
  onRefresh,
  isLoading,
  isRefreshPending,
}: any) => {
  const skillLessonsByID = React.useMemo(
    () =>
      Object.fromEntries(
        skillLessons?.map((skillLesson: any) => [skillLesson?.id, skillLesson]),
      ),
    [skillLessons],
  );

  const skillLessonIDs = React.useMemo(
    () => skillLessons?.map((skillLesson: any) => skillLesson?.id),
    [skillLessons],
  );

  return (
    <Autocomplete
      options={skillLessonIDs}
      getOptionLabel={(skillLessonID: any) => {
        const { video, conversation } = skillLessonsByID[skillLessonID] || {};
        return `${
          video?.title || conversation?.name
            ? video?.title ?? conversation?.name
            : `Invalid Skill Lesson`
        }`;
      }}
      style={{ width: 600 }}
      size="small"
      blurOnSelect
      selectOnFocus
      disabled={isLoading}
      renderOption={(skillLessonID: any) => {
        const skillLesson = skillLessonsByID[skillLessonID];
        return (
          <MUI.Box display="flex" flexDirection="column">
            {skillLesson.video?.title ?? skillLesson.conversation?.name}
            <MUI.Box component="span" color="text.secondary" fontSize={12}>
              {skillLesson?.id}
            </MUI.Box>
          </MUI.Box>
        );
      }}
      renderInput={(params) => (
        <MUI.TextField
          {...params}
          label="Skill Lesson"
          variant="filled"
          required={false}
          margin="normal"
          InputLabelProps={{ shrink: value ? true : undefined }}
          helperText={
            isLoading ? (
              'Loading Skill Lessons...'
            ) : isRefreshPending ? (
              'Refreshing Skill Lessons...'
            ) : (
              <MUI.Box>
                <MUI.Link
                  style={{ cursor: 'pointer' }}
                  onClick={(e: any) => {
                    onRefresh?.();
                    e.stopPropagation();
                  }}
                >
                  Refresh
                </MUI.Link>{' '}
                Skill Lessons{' | '}
                {!!value && (
                  <MUI.Link
                    style={{ cursor: 'pointer' }}
                    href={`/#/g/skills/${skillLessonsByID?.[value]?.skill?.id}`}
                    target="_blank"
                  >
                    Go to parent skill of this skill lessons
                  </MUI.Link>
                )}
              </MUI.Box>
            )
          }
        />
      )}
      onChange={(e, value) => onChange(value)}
      value={value}
    />
  );
};

export default SkillLessonSelect;
