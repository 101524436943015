import * as React from 'react';
import { FC } from 'react';
import {
  Create,
  CreateProps,
  SimpleForm,
  TextInput,
  BooleanInput,
  required,
} from 'react-admin';

import { makeStyles, Theme } from '@material-ui/core/styles';
import { Styles } from '@material-ui/styles/withStyles';

export const styles: Styles<Theme, any> = {
  name: { display: 'inline-block' },
  other: { display: 'block' },
};

const useStyles = makeStyles(styles);

const PracticeCreate: FC<CreateProps> = (props) => {
  const classes = useStyles(props);

  return (
    <Create {...props}>
      <SimpleForm
        autoComplete="off"
        initialValues={{ isUserGeneratedContent: false }}
      >
        <TextInput
          autoFocus
          source="word"
          label="Practice Text"
          className={classes.name}
          validate={requiredValidate}
          fullWidth
          multiline
        />
        <TextInput
          source="markedUpText"
          label="Practice Text w/ Markup (if entered this gets sent to SpeechAce)"
          className={classes.name}
          fullWidth
          multiline
        />
        <TextInput
          source="syllables"
          label="Syllables"
          formClassName={classes.other}
          fullWidth
        />
        <TextInput
          source="ipaFull"
          label="IPA Spelling"
          formClassName={classes.other}
          fullWidth
        />
        <TextInput
          source="coachSample"
          label="AWS S3 Filename"
          formClassName={classes.other}
          fullWidth
        />
        <TextInput
          source="aiCoachSample"
          label="AWS S3 Filename (AI/Polly)"
          formClassName={classes.other}
          fullWidth
        />
        <BooleanInput
          source="isSentence"
          label="Is Sentence?"
          formClassName={classes.other}
        />
        <BooleanInput
          source="isInstruction"
          label="Is Instruction?"
          formClassName={classes.other}
        />
      </SimpleForm>
    </Create>
  );
};

const requiredValidate = [required()];

export default PracticeCreate;
