/**
 * @generated SignedSource<<2b123dd937a7a2069f34fc733b7e9aaa>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MultipleChoiceQuestionsSelect_Query$variables = {
  initiallySelectedIDs: ReadonlyArray<string>;
};
export type MultipleChoiceQuestionsSelect_Query$data = {
  readonly " $fragmentSpreads": FragmentRefs<"MultipleChoiceQuestionsSelect_multipleChoiceQuestionsByIDs" | "MultipleChoiceQuestionsSelect_multipleChoiceQuestionsForString">;
};
export type MultipleChoiceQuestionsSelect_Query = {
  response: MultipleChoiceQuestionsSelect_Query$data;
  variables: MultipleChoiceQuestionsSelect_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "initiallySelectedIDs"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "ids",
    "variableName": "initiallySelectedIDs"
  }
],
v2 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "id",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "internalName",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "nodeID",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "MultipleChoiceQuestionsSelect_Query",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "MultipleChoiceQuestionsSelect_multipleChoiceQuestionsForString"
      },
      {
        "args": (v1/*: any*/),
        "kind": "FragmentSpread",
        "name": "MultipleChoiceQuestionsSelect_multipleChoiceQuestionsByIDs"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "MultipleChoiceQuestionsSelect_Query",
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Literal",
            "name": "first",
            "value": 20
          },
          {
            "kind": "Literal",
            "name": "search",
            "value": ""
          }
        ],
        "concreteType": "QueryMultipleChoiceQuestionsForStringConnection",
        "kind": "LinkedField",
        "name": "multipleChoiceQuestionsForString",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "MultipleChoiceQuestion",
            "kind": "LinkedField",
            "name": "nodes",
            "plural": true,
            "selections": (v2/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": "multipleChoiceQuestionsForString(first:20,search:\"\")"
      },
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "MultipleChoiceQuestion",
        "kind": "LinkedField",
        "name": "multipleChoiceQuestionsByIDs",
        "plural": true,
        "selections": (v2/*: any*/),
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f0c996e00f53b9e5616251bbc923dcba",
    "id": null,
    "metadata": {},
    "name": "MultipleChoiceQuestionsSelect_Query",
    "operationKind": "query",
    "text": "query MultipleChoiceQuestionsSelect_Query(\n  $initiallySelectedIDs: [ID!]!\n) {\n  ...MultipleChoiceQuestionsSelect_multipleChoiceQuestionsForString\n  ...MultipleChoiceQuestionsSelect_multipleChoiceQuestionsByIDs_1SqFeW\n}\n\nfragment MultipleChoiceQuestionsSelect_multipleChoiceQuestionsByIDs_1SqFeW on Query {\n  multipleChoiceQuestionsByIDs(ids: $initiallySelectedIDs) {\n    id\n    internalName\n    nodeID\n  }\n}\n\nfragment MultipleChoiceQuestionsSelect_multipleChoiceQuestionsForString on Query {\n  multipleChoiceQuestionsForString(search: \"\", first: 20) {\n    nodes {\n      id\n      internalName\n      nodeID\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "752993dc62ffba9ff5386ed30169dd19";

export default node;
