import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { useShowController } from 'react-admin';

const STRESS_LEVELS: any = {
  0: 'Unstressed',
  1: 'Primary Stress',
  2: 'Secondary Stress',
};

const SongsTakenShow = (props: any) => {
  const { record } = useShowController(props);
  const classes = useStyles();

  if (!record) return null;
  const parsedSpeechace: any = [];

  try {
    const { AudioLog } = record;
    AudioLog.forEach((logItem: any) => {
      parsedSpeechace.push(JSON.parse(logItem.speechace));
    });
  } catch (err) {
    console.log('err', err);
    const { AudioLog } = record;
    return <div>{AudioLog?.toString()}</div>;
  }

  if (!parsedSpeechace?.length) return <div>Nothing here!</div>;

  return (
    <Card className={classes.root}>
      {parsedSpeechace.map((speechaceItem: any, speechaceItemIndex: any) => {
        const {
          original_quality_score,
          quality_score,
          speechaceApiTime,
          scoringApiTime,
          urlToUse,
          word_score_list,
          text,
        } = speechaceItem;

        return (
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom>
                  Item # {speechaceItemIndex + 1}
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography variant="h6" gutterBottom align="left">
                  Quality Score: {quality_score}% / Original:{' '}
                  {original_quality_score}%
                </Typography>
              </Grid>
            </Grid>
            <div className={classes.spacer}>&nbsp;</div>

            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="body1" gutterBottom align="left">
                  <b>Text:</b> {text}
                </Typography>
                <Typography variant="h6" gutterBottom align="left">
                  Word Score List
                </Typography>
                {word_score_list.map((word: any, index: number) => (
                  <div
                    key={`${index + 1}`}
                    style={{ borderBottom: '1px solid black' }}
                  >
                    <Typography
                      key={`${index + 1}`}
                      variant="body1"
                      gutterBottom
                      align="left"
                      style={{ borderBottom: '1px solid black' }}
                    >
                      <Box display="inline-block" fontWeight="fontWeightBold">
                        {word.word}
                      </Box>{' '}
                      ({Math.round(word.quality_score)}% /{' '}
                      {Math.round(word.original_quality_score)}%)
                    </Typography>

                    {word.phone_score_list.map(
                      (phone: any, phoneIndex: number) => (
                        <Typography
                          key={`${phoneIndex + 1}`}
                          variant="body1"
                          gutterBottom
                          align="left"
                        >
                          <Box
                            display="inline-block"
                            fontWeight="fontWeightBold"
                          >
                            {phone.phone}
                          </Box>{' '}
                          ({Math.round(phone.quality_score)}% /{' '}
                          {Math.round(phone.original_quality_score)}%)
                          {phone.sound_most_like &&
                            phone.sound_most_like !== phone.phone && (
                              <Typography>
                                Sounds Like: {phone.sound_most_like}
                              </Typography>
                            )}
                          {phone.predicted_stress_level !== undefined &&
                          phone.predicted_stress_level !== null ? (
                            <Typography>
                              Predicted Stress:{' '}
                              {STRESS_LEVELS[phone.predicted_stress_level]}
                            </Typography>
                          ) : null}
                          {phone.stress_level !== null ? (
                            <Typography>
                              Actual Stress: {STRESS_LEVELS[phone.stress_level]}{' '}
                              - {Math.round(phone.stress_score)}%
                            </Typography>
                          ) : null}
                        </Typography>
                      ),
                    )}
                  </div>
                ))}
              </Grid>
            </Grid>

            {(speechaceApiTime || scoringApiTime) && (
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Typography variant="h6" gutterBottom align="left">
                    SpeechAce API Runtime: {speechaceApiTime ?? scoringApiTime}{' '}
                    secs
                  </Typography>
                </Grid>
              </Grid>
            )}
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography variant="h6" gutterBottom align="left">
                  URL: {urlToUse || 'https://api.speechaceedeprecated.co'}
                </Typography>
              </Grid>
            </Grid>
            <div style={{ borderBottom: '3px solid black' }}>&nbsp;</div>
          </CardContent>
        );
      })}
    </Card>
  );
};

export default SongsTakenShow;

const useStyles = makeStyles({
  root: { margin: 'auto' },
  spacer: { height: 20 },
  invoices: { margin: '10px 0' },
});
