/**
 * @generated SignedSource<<b9828606ad5067d54d4f671e1c6cb6db>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SkillAssessments_Skill_userSkill$data = {
  readonly skill: {
    readonly id: string | null | undefined;
    readonly name: string | null | undefined;
  } | null | undefined;
  readonly skillAssessments: ReadonlyArray<{
    readonly assessmentTakenID: string | null | undefined;
    readonly createdAt: any | null | undefined;
    readonly id: string | null | undefined;
    readonly maxScore: number | null | undefined;
    readonly minScore: number | null | undefined;
    readonly sampleSize: number | null | undefined;
    readonly score: number | null | undefined;
  }> | null | undefined;
  readonly " $fragmentType": "SkillAssessments_Skill_userSkill";
};
export type SkillAssessments_Skill_userSkill$key = {
  readonly " $data"?: SkillAssessments_Skill_userSkill$data;
  readonly " $fragmentSpreads": FragmentRefs<"SkillAssessments_Skill_userSkill">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SkillAssessments_Skill_userSkill",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Skill",
      "kind": "LinkedField",
      "name": "skill",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SkillAssessment",
      "kind": "LinkedField",
      "name": "skillAssessments",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "createdAt",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "score",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "minScore",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "maxScore",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "sampleSize",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "assessmentTakenID",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "UserSkill",
  "abstractKey": null
};
})();

(node as any).hash = "4fb90ed9b5c5dee04c70f580eafa294b";

export default node;
