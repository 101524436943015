import { Button, Typography } from '@material-ui/core';
import React, { useContext } from 'react';
import { useRefresh } from 'react-admin';

import api from '../../api';
import { IVP_TYPES } from './constants';
import FromToForm from './forms/FromToForm';
import ImageCreationForm from './forms/ImageCreationForm';
import InVideoFormProvider, {
  InVideoFormContext,
} from './forms/InVideoFormProvider';
import MouthDiagramSetCreationForm from './forms/MouthDiagramSetCreationForm';
import TableCreationForm from './forms/TableCreationForm';
import VideoTextWithHighlightCreationForm from './forms/VideoTextWithHighlightCreationForm';

const DEFAULT_ITEM_LENGTH = 3;

export default function InteractableCreationFormFactory({
  category,
  startTime,
  record,
}: {
  category: string | null | undefined;
  startTime: number | null | undefined;
  record: any;
}) {
  if (
    !category ||
    Number.isNaN(startTime) ||
    startTime === null ||
    startTime === undefined
  ) {
    return null;
  }

  // @ts-ignore
  const title = IVP_TYPES[category];
  let form: React.ReactNode = null;
  switch (category) {
    case 'Practice':
      form = (
        <div
          style={{
            marginTop: 20,
            fontSize: 30,

            textDecoration: 'underline',
          }}
        >
          Use the old way above
        </div>
      );
      break;
    case 'VideoTableGraphics':
      form = <TableCreationForm />;
      break;
    // case 'VideoMultipleChoice':
    //   form = <IV not implemented
    case 'VideoTextWithHighlights':
      form = <VideoTextWithHighlightCreationForm />;
      break;
    case 'VideoImage':
      form = <ImageCreationForm />;
      break;
    case 'VideoMouthDiagramSet':
      form = <MouthDiagramSetCreationForm />;
      break;
    default:
      break;
  }

  return (
    <InVideoFormProvider category={category} record={record}>
      <Typography variant="h3">{title}</Typography>
      <FromToForm from={startTime} to={startTime + DEFAULT_ITEM_LENGTH} />
      {form}
      <ActionRow />
    </InVideoFormProvider>
  );
}

function ActionRow() {
  const { from, to, category, data, record } = useContext(InVideoFormContext);
  const refresh = useRefresh();

  const saveActiveRecord = async () => {
    let response: any = null;
    try {
      const dataToSend = {
        from,
        to,
        ...data,
      };

      switch (category) {
        case 'VideoImage':
          // eslint-disable-next-line no-case-declarations
          const formData = new FormData();
          formData.append('image', data.src);

          Object.keys(dataToSend).forEach((key) => {
            formData.append(key, dataToSend[key]);
          });

          response = await api.createVideoImage(record?.id, formData);
          break;
        case 'VideoTableGraphics':
          response = await api.createVideoTableGraphics(record?.id, dataToSend);
          break;
        case 'VideoTextWithHighlights':
          response = await api.createVideoTextWithHighlight(
            record?.id,
            dataToSend,
          );
          break;
        case 'VideoMouthDiagramSet':
          response = await api.createVideoMouthDiagramSet(
            record?.id,
            dataToSend,
          );
          break;

        default:
          break;
      }
    } catch (error) {
      console.log('error', error);
    }

    if (response && response?.status < 300) {
      refresh();
    }
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
      <Button variant="contained" color="primary" onClick={saveActiveRecord}>
        Save
      </Button>
    </div>
  );
}
