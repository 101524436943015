import Icon from '@material-ui/icons/Forum';

import List from './ConversationList';
import Edit from './ConversationEdit';
import Create from './ConversationCreate';

export default {
  list: List,
  edit: Edit,
  icon: Icon,
  create: Create,
};
