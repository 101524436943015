import * as React from 'react';
import {
  Datagrid,
  List,
  ListProps,
  FunctionField,
  TextField,
} from 'react-admin';
import { FC } from 'react';
import { Link, FieldProps } from 'react-admin';

// import SongListAside from './SongListAside';
import { ReactElement } from 'react';

import LessonLinkField from './LessonLinkField';
import PhonemeLinkField from './PhonemeLinkField';

const ConversationLinkField: FC<FieldProps> = (props) =>
  props.record ? (
    <Link to={`/content/conversation/${props.record.id}`}>
      {props.record.name}
    </Link>
  ) : null;

ConversationLinkField.defaultProps = {
  source: 'name',
  addLabel: true,
};

const ConversationList = (props: ListProps): ReactElement => (
  <List {...props} sort={{ field: 'name', order: 'ASC' }} perPage={25}>
    <Datagrid optimized>
      <ConversationLinkField label="Conversation" />

      {/* <TextField label="Name" source="name" /> */}
      <TextField label="Description" source="description" />
      <TextField label="Thumbnail Url" source="thumbnail_url" />
      <TextField label="Tags" source="tags" />
      <TextField label="Coach" source="Coach.name" />
      <FunctionField
        render={(record: any) => record && record.items.length}
        label="Num. speech bubbles"
      />

      <LessonLinkField />
      <PhonemeLinkField />
    </Datagrid>
  </List>
);

export default ConversationList;
