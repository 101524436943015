import * as MUI from '@material-ui/core';
import * as MUIColors from '@material-ui/core/colors';
import * as MUIIcons from '@material-ui/icons';
import graphql from 'babel-plugin-relay/macro';
import _ from 'lodash';
import React, { useCallback, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useFragment, useLazyLoadQuery, useMutation } from 'react-relay';
import { useLocation } from 'react-router-dom';

import DLPCurriculumItem from '../components/DLPCurriculumItem';
import IPAText from '../components/IPAText';
import UserSelect from '../components/UserSelect';
import { getScaleColor } from '../gen_conv/GenConvShow';
import DLPAssessmentsTaken2 from './DLPAssessmentsTaken2';

const DLPPlayground = () => {
  const paramsData: any = useLazyLoadQuery(
    graphql`
      query DLPPlayground_params_Query {
        playgroundGenParams
      }
    `,
    {},
  );

  const { control, watch } = useForm({
    defaultValues: _.defaults(
      { includeLessonsWithoutContent: true, includeExistingProgress: false },
      _.mapValues(paramsData.playgroundGenParams, 'default'),
    ),
  });

  const query = useQuery();

  const formFields = watch();

  const [genParams, setGenParams] = useState(formFields);

  const debouncedSetParams = React.useCallback(
    _.debounce((newState) => {
      setGenParams((oldState) =>
        _.isEqual(oldState, newState) ? oldState : newState,
      );
    }, 250),
    [],
  );

  React.useEffect(() => {
    debouncedSetParams(formFields);
  }, [formFields]);

  const [isUserDrawerOpen, setIsUserDrawerOpen] = useState(false);
  const [isDrawerRight, setIsDrawerRight] = useState(false);

  const [userID, setUserID] = useState<string | null>(
    query.get('userID') ?? null,
  );

  const onUserSelect = React.useCallback((userID: string) => {
    query.set('userID', userID);
    window.location.hash = `/curriculum-playground?${query.toString()}`;
    setUserID(userID);
  }, []);

  const [isConfirmSaveDialogOpen, setIsConfirmSaveDialogOpen] = useState(false);

  const [commitSaveCurriculum, isSaveCurriculumInFlight] = useMutation(graphql`
    mutation DLPPlayground_PlaygroundWriteCurriculumMutation(
      $userID: ID!
      $genParams: JSONObject!
    ) {
      playgroundWriteCurriculum(userID: $userID, genParams: $genParams) {
        __typename
      }
    }
  `);

  return (
    <>
      <MUI.Box display="flex" height="100%" paddingY={2} gridGap={10}>
        <MUI.Paper
          elevation={1}
          style={{
            height: 'auto',
            width: 225,
            flexShrink: 0,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <MUI.Box
            marginY={3}
            paddingX={2}
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            flexGrow="1"
            style={{ height: 0, overflowY: 'scroll' }}
          >
            <MUI.Box>
              <MUI.Box marginBottom={4}>
                <MUI.Button
                  onClick={() => setIsUserDrawerOpen(true)}
                  fullWidth
                  color="primary"
                  variant="contained"
                  size="small"
                >
                  Select User
                </MUI.Button>

                {process.env.NODE_ENV === 'development' && (
                  <MUI.Button
                    onClick={() =>
                      setGenParams((state) => ({
                        ...state,
                        key: (state.key ?? 0) + 1,
                      }))
                    }
                    fullWidth
                    color="primary"
                    variant="outlined"
                    size="small"
                    style={{ marginTop: 10 }}
                  >
                    Reload
                  </MUI.Button>
                )}

                <MUI.Button
                  onClick={() => setIsConfirmSaveDialogOpen(true)}
                  fullWidth
                  color="primary"
                  variant="outlined"
                  size="small"
                  style={{ marginTop: 10 }}
                  disabled={!userID}
                >
                  Save Curriculum
                </MUI.Button>
              </MUI.Box>

              {Object.entries(paramsData.playgroundGenParams).map(
                ([key, param]: any) =>
                  param.constraints.type === 'boolean' ? (
                    <CheckboxInput
                      key={key}
                      control={control}
                      name={key}
                      label={param.label}
                      description={param.description}
                    />
                  ) : (
                    <SliderInput
                      key={key}
                      control={control}
                      name={key}
                      label={param.label}
                      description={param.description}
                      defaultValue={param.default}
                      min={param.constraints.min}
                      max={param.constraints.max}
                      step={param.constraints.step}
                    />
                  ),
              )}
            </MUI.Box>
          </MUI.Box>
        </MUI.Paper>
        {userID ? (
          <React.Suspense
            fallback={
              <MUI.Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                flexGrow="1"
              >
                <MUI.CircularProgress />
              </MUI.Box>
            }
          >
            <GenerationDisplay userID={userID} genParams={genParams} />
          </React.Suspense>
        ) : (
          <MUI.Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexGrow="1"
          >
            <MUI.Typography variant="body1" color="textSecondary">
              No user selected.
            </MUI.Typography>
          </MUI.Box>
        )}
      </MUI.Box>

      {/* <MUI.Backdrop */}
      {/*   open={isUserDrawerOpen} */}
      {/*   onClick={() => setIsUserDrawerOpen(false)} */}
      {/*   style={{ zIndex: 1110 }} */}
      {/* /> */}

      <MUI.Drawer
        open={isUserDrawerOpen}
        anchor={isDrawerRight ? 'right' : 'left'}
        variant="persistent"
        onClose={() => setIsUserDrawerOpen(false)}
        style={{ zIndex: 1200 }}
        PaperProps={{ elevation: 2 }}
      >
        <MUI.Toolbar />
        <MUI.Box
          padding={2}
          paddingTop={0}
          display="flex"
          flexDirection="column"
          height="100%"
          width={550}
        >
          <MUI.Box>
            <MUI.ButtonGroup
              size="small"
              variant="outlined"
              color="secondary"
              style={{ float: isDrawerRight ? 'left' : 'right' }}
            >
              {isDrawerRight && (
                <MUI.Tooltip title="Move to Left Side">
                  <MUI.Button onClick={() => setIsDrawerRight(false)}>
                    <MUIIcons.KeyboardArrowLeft />
                  </MUI.Button>
                </MUI.Tooltip>
              )}
              <MUI.Button onClick={() => setIsUserDrawerOpen(false)}>
                Close Drawer
              </MUI.Button>
              {!isDrawerRight && (
                <MUI.Tooltip title="Move to Right Side">
                  <MUI.Button onClick={() => setIsDrawerRight(true)}>
                    <MUIIcons.KeyboardArrowRight />
                  </MUI.Button>
                </MUI.Tooltip>
              )}
            </MUI.ButtonGroup>
          </MUI.Box>
          <MUI.Box marginTop={2.5} marginBottom={1}>
            <UserSelect onChange={onUserSelect} value={userID} />
          </MUI.Box>
          {userID && (
            <>
              <MUI.Box marginTop={2}>
                User ID:{' '}
                <MUI.Link target="_blank" href={`/#/users/${userID}`}>
                  {userID}
                </MUI.Link>
              </MUI.Box>
              <MUI.Box
                marginTop={2}
                style={{ height: 0, overflowY: 'scroll', flexGrow: 1 }}
              >
                <React.Suspense fallback={<MUI.CircularProgress />}>
                  <DLPAssessmentsTaken2 key={userID} userID={userID} />
                </React.Suspense>
              </MUI.Box>
            </>
          )}
        </MUI.Box>
      </MUI.Drawer>
      <ConfirmSaveDialog
        open={isConfirmSaveDialogOpen}
        onClose={() => setIsConfirmSaveDialogOpen(false)}
        onConfirm={() => {
          commitSaveCurriculum({
            variables: { userID, genParams },
            onCompleted: () => setIsConfirmSaveDialogOpen(false),
          });
        }}
        pending={isSaveCurriculumInFlight}
      />
    </>
  );
};

const GenerationDisplay = ({ userID, genParams }: any) => {
  const deferredUserID = React.useDeferredValue(userID);
  const deferredGenParams = React.useDeferredValue(genParams);
  const isPending =
    deferredUserID !== userID || deferredGenParams !== genParams;

  const data: any = useLazyLoadQuery(
    graphql`
      query DLPPlayground_Query($userID: ID!, $genParams: JSONObject!) {
        playgroundCurriculum(userID: $userID, genParams: $genParams) {
          ...DLPPlayground_ScoredSkills_playgroundCurriculum
          ...DLPPlayground_MergedSkills_playgroundCurriculum

          curriculum {
            id

            ...DLPPlayground_curriculum
          }
        }
      }
    `,
    { userID: deferredUserID, genParams: deferredGenParams },
    { fetchPolicy: 'network-only' },
  );

  if (!data?.playgroundCurriculum) {
    return null;
  }

  return (
    <MUI.Box
      flexGrow={1}
      display="flex"
      flexDirection="column"
      marginLeft={3}
      style={{ height: '100%' }}
    >
      <MUI.Box flexGrow={1} display="flex" gridGap={40} style={{ height: 0 }}>
        <Column header="Scored Skills">
          <UserSkills playgroundResult={data.playgroundCurriculum} />
        </Column>

        <Column header="Selected Skills">
          <MergedSkills playgroundResult={data.playgroundCurriculum} />
        </Column>

        {/* <Column header="Lesson Candidates"> */}
        {/*   <CurriculumItemCandidates */}
        {/*     key={data.playgroundCurriculum.curriculum.id} */}
        {/*     playgroundResult={data.playgroundCurriculum} */}
        {/*   /> */}
        {/* </Column> */}

        <Column header="Curriculum" flex={1.5}>
          <Curriculum curriculum={data.playgroundCurriculum.curriculum} />
        </Column>
      </MUI.Box>
      <MUI.Collapse in={isPending}>
        <MUI.Box marginTop={2}>
          <MUI.LinearProgress color="primary" />
        </MUI.Box>
      </MUI.Collapse>
    </MUI.Box>
  );
};

const ConfirmSaveDialog = ({ open, onClose, onConfirm, pending }) => {
  return (
    <MUI.Dialog open={open} onClose={onClose}>
      <MUI.DialogTitle>Save Curriculum to User</MUI.DialogTitle>
      <MUI.DialogContent>
        <MUI.DialogContentText>
          Are you sure you want to save this curriculum?{' '}
          <strong>
            This will change the user's currently active curriculum.
          </strong>
        </MUI.DialogContentText>
      </MUI.DialogContent>
      <MUI.DialogActions>
        <MUI.Button onClick={onClose} color="primary">
          Cancel
        </MUI.Button>
        <MUI.Button
          onClick={onConfirm}
          disabled={pending}
          style={{
            color: MUIColors.red[600],
          }}
        >
          {pending && (
            <MUI.CircularProgress
              size={16}
              color="inherit"
              style={{ marginRight: 10 }}
            />
          )}
          Save to User
        </MUI.Button>
      </MUI.DialogActions>
    </MUI.Dialog>
  );
};

const Column = ({ header, children, flex }: any) => (
  <MUI.Box flex={flex ?? 1} display="flex" flexDirection="column">
    <MUI.Box marginBottom={2}>
      <MUI.Typography variant="h6" gutterBottom>
        {header}
      </MUI.Typography>
      <MUI.Divider />
    </MUI.Box>

    <MUI.Box
      flexGrow={1}
      style={{
        overflowY: 'scroll',
        marginLeft: -5,
        marginRight: -5,
        paddingLeft: 5,
        paddingRight: 5,
      }}
    >
      {children}
    </MUI.Box>
  </MUI.Box>
);

const SliderInput = ({
  control,
  name,
  defaultValue,
  min = 0,
  max = 1,
  step = 0.1,
  label,
  description,
  ...restProps
}) => {
  const handleInputEvent = useCallback(
    (e) =>
      e.target.value ? _.clamp(parseFloat(e.target.value), min, max) : null,
    [],
  );

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value } }) => (
        <MUI.Tooltip title={description || ''} placement="right">
          <MUI.Box marginBottom={2} marginTop={2}>
            <MUI.Box
              display="flex"
              justifyContent="space-between"
              alignItems="flex-end"
              gridGap={5}
            >
              <MUI.Typography
                variant="body2"
                color={value == null ? 'textSecondary' : 'textPrimary'}
              >
                {label}
              </MUI.Typography>
              <MUI.InputBase
                margin="dense"
                style={{ width: 40, flexShrink: 0 }}
                inputProps={{
                  min,
                  max,
                  step,
                  style: { textAlign: 'right', padding: 0 },
                  inputMode: 'numeric',
                }}
                value={value}
                onChange={(e) => onChange(handleInputEvent(e))}
              />
            </MUI.Box>
            <MUI.Slider
              min={min}
              max={max}
              step={step}
              value={value}
              marks={[
                ...(min + (max - min) * 0.05 < defaultValue
                  ? [{ value: min, label: min }]
                  : []),
                { value: defaultValue, label: '↑' },
                ...(max - (max - min) * 0.05 > defaultValue
                  ? [{ value: max, label: max }]
                  : []),
              ]}
              onChange={(_, value) => onChange(value)}
              {...restProps}
            />
          </MUI.Box>
        </MUI.Tooltip>
      )}
    />
  );
};

const CheckboxInput = ({ control, name, label, description }) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value } }) => (
        <MUI.Tooltip title={description || ''} placement="right">
          <MUI.Box marginTop={1} marginBottom={1}>
            <MUI.FormControlLabel
              control={<MUI.Checkbox checked={value} onChange={onChange} />}
              labelPlacement="start"
              style={{
                marginLeft: 0,
                width: 'calc(100% + 9px)',
                justifyContent: 'space-between',
              }}
              label={<MUI.Typography variant="body2">{label}</MUI.Typography>}
            />
          </MUI.Box>
        </MUI.Tooltip>
      )}
    />
  );
};

const UserSkills = React.memo(
  ({ playgroundResult: playgroundResultFragment }: any) => {
    const data = useFragment(
      graphql`
        fragment DLPPlayground_ScoredSkills_playgroundCurriculum on DLPPlaygroundResult {
          userSkills {
            assessmentScore

            skill {
              id
            }

            ...DLPPlayground_userSkill
          }
        }
      `,
      playgroundResultFragment,
    );

    const orderedUserSkills = React.useMemo(
      () => _.orderBy(data.userSkills, 'assessmentScore', 'asc'),
      [data.userSkills],
    );

    return (
      <MUI.Box>
        {orderedUserSkills.map((userSkill: any) => (
          <UserSkill key={userSkill.skill.id} userSkillFragment={userSkill} />
        ))}
      </MUI.Box>
    );
  },
);

const MergedSkills = React.memo(
  ({ playgroundResult: playgroundResultFragment }: any) => {
    const data = useFragment(
      graphql`
        fragment DLPPlayground_MergedSkills_playgroundCurriculum on DLPPlaygroundResult {
          mergedSkills {
            skill {
              id
            }

            ...DLPPlayground_userSkill
          }
        }
      `,
      playgroundResultFragment,
    );

    return (
      <MUI.Box>
        {data.mergedSkills.map((userSkill: any) => (
          <UserSkill key={userSkill.skill.id} userSkillFragment={userSkill} />
        ))}
      </MUI.Box>
    );
  },
);

const UserSkill = ({ userSkillFragment, superseded = false }: any) => {
  const data = useFragment(
    graphql`
      fragment DLPPlayground_userSkill on DLPPlaygroundUserSkill
      @argumentDefinitions(
        includeSuperseding: { type: "Boolean", defaultValue: true }
      ) {
        score
        assessmentScore
        assessmentMinScore
        assessmentMaxScore
        isLanguageRelevant

        skill {
          id
          name
          quickWin
          importance
          speechaceCanScoreSkillWell
        }

        superseding {
          ...DLPPlayground_userSkill
            @arguments(includeSuperseding: false)
            @include(if: $includeSuperseding)
        }
      }
    `,
    userSkillFragment,
  );

  return (
    <MUI.Box marginBottom={0.5}>
      <MUI.Paper
        style={{
          padding: 5,
          zIndex: 10,
          ...(superseded && { backgroundColor: MUIColors.grey[50] }),
        }}
        variant="outlined"
      >
        <MUI.Box display="flex" justifyContent="space-between">
          <MUI.Typography variant="body2">
            <MUI.Link
              href={`/#/g/skills/${data.skill.id}`}
              target="_blank"
              color="inherit"
            >
              <IPAText text={data.skill.name} />
            </MUI.Link>
          </MUI.Typography>
          <Score
            score={data.score}
            assessmentScores={[
              data.assessmentMinScore,
              data.assessmentMaxScore,
              data.assessmentScore,
            ]}
          />
        </MUI.Box>
        <MUI.Box
          display="flex"
          alignItems="flex-end"
          style={{ marginBottom: -3, opacity: 0.75 }}
        >
          <MUI.Box
            display="inline-block"
            marginTop={0.5}
            marginRight={1.5}
            style={{ fontSize: 16 }}
          >
            <MUI.Tooltip title="Importance" arrow>
              <MUI.Typography variant="body2" color="secondary">
                <span
                  style={{
                    opacity: ((5 - data.skill.importance) / 4) * 0.4 + 0.4,
                  }}
                >
                  {data.skill.importance}
                </span>
                <span style={{ opacity: 0.4 }}>/5</span>
              </MUI.Typography>
            </MUI.Tooltip>
          </MUI.Box>
          {data.isLanguageRelevant && (
            <MUI.Box
              display="inline-block"
              marginTop={0.5}
              marginRight={1.5}
              style={{ fontSize: 16, lineHeight: '100%' }}
            >
              <MUI.Tooltip title="Language Relevant" arrow>
                <MUIIcons.Translate
                  style={{ color: MUIColors.blue[500] }}
                  fontSize="inherit"
                />
              </MUI.Tooltip>
            </MUI.Box>
          )}
          {data.skill.quickWin && (
            <MUI.Box
              display="inline-block"
              marginTop={0.5}
              marginRight={1.5}
              style={{ fontSize: 16, lineHeight: '100%' }}
            >
              <MUI.Tooltip title="Quick Win" arrow>
                <MUIIcons.Star
                  style={{ color: MUIColors.amber[700] }}
                  fontSize="inherit"
                />
              </MUI.Tooltip>
            </MUI.Box>
          )}
          {!data.skill.speechaceCanScoreSkillWell && (
            <MUI.Box
              display="inline-block"
              marginTop={0.5}
              marginRight={1.5}
              style={{ fontSize: 16, lineHeight: '100%' }}
            >
              <MUI.Tooltip title="Speechace Doesn't Score Well" arrow>
                <MUIIcons.Close
                  style={{ color: MUIColors.red[400] }}
                  fontSize="inherit"
                />
              </MUI.Tooltip>
            </MUI.Box>
          )}
        </MUI.Box>
        {data.superseding && (
          <MUI.Box marginTop={1} marginBottom={-0.5}>
            {data.superseding.map((supersedingUserSkill: any, i: number) => (
              <MUI.Box key={i} marginTop={0.5} style={{ opacity: 0.6 }}>
                <UserSkill
                  userSkillFragment={supersedingUserSkill}
                  superseded
                />
              </MUI.Box>
            ))}
          </MUI.Box>
        )}
      </MUI.Paper>
    </MUI.Box>
  );
};

const Curriculum = React.memo(({ curriculum: curriculumFragment }: any) => {
  const data = useFragment(
    graphql`
      fragment DLPPlayground_curriculum on DLPCurriculum {
        id
        chapters {
          number
          units {
            number
            items {
              id
              dayNumber

              ...DLPCurriculumItem_dlpCurriculumItem
            }
          }
        }
      }
    `,
    curriculumFragment,
  );

  return (
    <MUI.Box gridRowGap={25} display="flex" flexDirection="column">
      {data.chapters.map((chapter: any, i: number) => (
        <MUI.Box key={chapter.number}>
          {i !== 0 && <MUI.Divider />}
          <MUI.Typography
            variant="subtitle1"
            style={{ fontWeight: 500, marginTop: 5 }}
          >
            <MUI.Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <span>Chapter {chapter.number}</span>
              <MUIIcons.MenuBook
                fontSize="inherit"
                style={{ marginBottom: -2, marginRight: 4 }}
              />
            </MUI.Box>
          </MUI.Typography>
          {chapter.units.map((unit: any, unitIndex) => (
            <MUI.Box key={unit.number} marginTop={3}>
              <MUI.Divider />
              <MUI.Typography
                gutterBottom
                variant="subtitle2"
                style={{ marginTop: 5, marginBottom: 10 }}
              >
                <MUI.Typography color="textSecondary" variant="inherit">
                  Chapter {chapter.number},{' '}
                </MUI.Typography>
                Unit {unit.number}
              </MUI.Typography>
              {Object.entries(_.groupBy(unit.items, 'dayNumber')).map(
                ([day, items]) => (
                  <MUI.Box key={day} marginTop={1}>
                    <MUI.Typography
                      variant="body2"
                      color="textSecondary"
                      gutterBottom
                    >
                      Day {day}
                    </MUI.Typography>
                    {items.map((item) => (
                      <DLPCurriculumItem
                        key={item.id}
                        curriculumItem={item}
                        isEndOfChapter={unitIndex === chapter.units.length - 1}
                      />
                    ))}
                  </MUI.Box>
                ),
              )}
            </MUI.Box>
          ))}
        </MUI.Box>
      ))}
    </MUI.Box>
  );
});

const Score = React.memo(({ score, assessmentScores }: any) => (
  <MUI.Box display="inline-flex" gridColumnGap={8}>
    <MUI.Box style={{ width: 45, textAlign: 'right' }}>
      <MUI.Tooltip
        title={
          <>
            User's Skill Assessment Score
            <br />
            {assessmentScores[2] == null ? (
              <>
                <br />
                This skill has not been assessed for this user.
              </>
            ) : (
              <>
                (estimate: {assessmentScores[2].toFixed(2)})
                <br />
                <br />
                Our skill scoring model assesses that there is a 95% likelihood
                that the user's true skill score is within this range. The wider
                the range, the less confident we are in our assessment of the
                user's skill.
                <br />
                <br />
                The estimate above represents our best guess at a single score.
              </>
            )}
          </>
        }
        placement="bottom"
        arrow
      >
        <MUI.Typography variant="body2">
          {assessmentScores[0] == null ? (
            '–'
          ) : (
            <span
              style={{
                color: getScaleColor(Math.round(assessmentScores[2] * 100)),
              }}
            >
              {assessmentScores[0].toFixed(1).replace(/^0\./, '.')}-
              {assessmentScores[1].toFixed(1).replace(/^0\./, '.')}
            </span>
          )}
        </MUI.Typography>
      </MUI.Tooltip>
    </MUI.Box>

    <MUI.Box style={{ width: 20, textAlign: 'right' }}>
      <MUI.Tooltip
        title="Computed Skill Priority Score"
        placement="bottom"
        arrow
      >
        <MUI.Typography variant="body2" color="primary">
          {score == null
            ? '–'
            : score.toFixed(2).replace(/^0\./, '.').replace('1.00', '1.0')}
        </MUI.Typography>
      </MUI.Tooltip>
    </MUI.Box>
  </MUI.Box>
));

const useQuery = () => {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
};

export default DLPPlayground;
