import React, { useEffect, useState } from 'react';
import api from '../../api';
import {
  ReferralChartContainer,
  StyledCard,
  TitleParent,
} from '../charts/Styled';
import { CardHeader, Tooltip } from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { ResponsiveContainer } from 'recharts';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import CircularProgress from '@material-ui/core/CircularProgress';
import moment from 'moment';

type LanguageStat = {
  language: string;
  installs: number;
  subscriptions: number;

  // all the substatuses
  subscribed?: number;
  subscribed_not_renew?: number;
  trial?: number;
  trial_not_renew?: number;
  expired_trial?: number;
  expired_paid?: number;
  refunded?: number;
  never_subscribed?: number;
  promo?: number;
  failed_payment?: number;
};

export default function SubscribersByLanguageTable({
  startDate,
  endDate,
  phoneOS,
  timezone,
}: any) {
  const [pending, setPending] = useState(false);
  const [data, setData] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState<LanguageStat>();

  const getStats = async () => {
    setPending(true);
    try {
      const newStats: any = await api.getStatsForSubscribersByLanguage(
        startDate,
        endDate,
        phoneOS,
        timezone,
      );

      if (newStats.status === 200) {
        setData(newStats.json);
      } else {
        setData([]);
      }
    } catch (error) {
      console.log(error);

      setData([]);
    } finally {
      setPending(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      getStats();
    }
  }, [isOpen, startDate, endDate, phoneOS, timezone]);

  const isEndDateWithin7Days = moment(endDate).isAfter(
    moment().subtract(7, 'days'),
  );

  const safeFraction = (numerator: number, denominator: number) =>
    Math.round((10000 * (numerator || 0)) / (denominator || 1)) / 100;

  return (
    <StyledCard fullWidth>
      <TitleParent onClick={() => setIsOpen((o) => !o)}>
        <CardHeader title="Subscribers by language" />
        {isOpen ? <ExpandLess /> : <ExpandMore />}
        {pending && <CircularProgress style={{ marginLeft: 10 }} size={20} />}
      </TitleParent>
      {isOpen && (
        <ReferralChartContainer>
          <ResponsiveContainer>
            <TableContainer style={{ maxHeight: 800, display: 'flex' }}>
              <Table
                style={{ width: 1200 }}
                stickyHeader
                aria-label="simple table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell>Language</TableCell>
                    <Tooltip title="Not 100% exact, just provisional users who got past the language step">
                      <TableCell>Rough # Installs</TableCell>
                    </Tooltip>
                    <Tooltip title="Where subcription status is trial, trial_not_renew, expired_trial or failed_payment">
                      <TableCell>Trials</TableCell>
                    </Tooltip>
                    <TableCell>Trial start ratio</TableCell>
                    <Tooltip title="Users who started a trial but now have subscription status of trial_not_renew, expired_trial, failed_payment">
                      <TableCell>Non converted trials</TableCell>
                    </Tooltip>

                    <Tooltip title="People who actaully paid us at one point. subscribed or subscribed_not_renew">
                      <TableCell>Subscriptions</TableCell>
                    </Tooltip>
                    <Tooltip title="Subscriptions / (Subscriptions + Trials)">
                      <TableCell>Trial → Sub</TableCell>
                    </Tooltip>
                    <TableCell>
                      Install → Sub{' '}
                      {isEndDateWithin7Days ? (
                        <Tooltip title="End date within 7 days so there could be outstanding trials">
                          <span>⚠️</span>
                        </Tooltip>
                      ) : null}
                    </TableCell>

                    <Tooltip title="Has the profile attribute isSubscriber and means they have time gated access to the app">
                      <TableCell>Has access</TableCell>
                    </Tooltip>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.map((languageStats: LanguageStat) => {
                    const subscribers =
                      (languageStats.subscribed || 0) +
                      (languageStats.subscribed_not_renew || 0) +
                      (languageStats.expired_paid || 0);

                    const trials =
                      subscribers +
                      (languageStats.trial || 0) +
                      (languageStats.trial_not_renew || 0) +
                      (languageStats.expired_trial || 0) +
                      (languageStats.failed_payment || 0) +
                      (languageStats.refunded || 0);

                    const nonConvertingTrials =
                      (languageStats.trial_not_renew || 0) +
                      (languageStats.expired_trial || 0) +
                      (languageStats.failed_payment || 0) +
                      (languageStats.refunded || 0);

                    return (
                      <TableRow
                        hover
                        key={languageStats.language}
                        onClick={() => setSelectedLanguage(languageStats)}
                        style={{
                          cursor: 'pointer',
                        }}
                      >
                        <TableCell scope="row">
                          {languageStats.language}
                        </TableCell>
                        <TableCell>{languageStats.installs}</TableCell>
                        <TableCell>{trials}</TableCell>
                        <TableCell>
                          {safeFraction(trials, languageStats.installs)}%
                        </TableCell>
                        <TableCell>{nonConvertingTrials}</TableCell>

                        <TableCell>{subscribers}</TableCell>
                        <TableCell>
                          {safeFraction(subscribers, trials)}%
                        </TableCell>
                        <TableCell>
                          {safeFraction(subscribers, languageStats.installs)}%
                        </TableCell>
                        <TableCell>{languageStats.subscriptions}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',

                  flex: 1,
                }}
              >
                {selectedLanguage && (
                  <div>
                    Raw stats for: {selectedLanguage.language}
                    <pre>
                      {JSON.stringify(selectedLanguage, null, 4).replaceAll(
                        '"',
                        '',
                      )}
                    </pre>
                  </div>
                )}
              </div>
            </TableContainer>
          </ResponsiveContainer>
        </ReferralChartContainer>
      )}
    </StyledCard>
  );
}
