import * as React from 'react';
import { FC, memo } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { FieldProps } from 'react-admin';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
  },
  avatar: {
    marginRight: theme.spacing(1),
    marginTop: -theme.spacing(0.5),
    marginBottom: -theme.spacing(0.5),
  },
}));

const FullNameField: FC<FieldProps> = ({ record }) => {
  const classes = useStyles();
  return record ? (
    <div className={classes.root}>
      {record.user?.firstName} {record.user?.lastName}
    </div>
  ) : null;
};

FullNameField.defaultProps = {
  source: 'lastName',
  label: 'Name',
};

export default memo<FieldProps>(FullNameField);
