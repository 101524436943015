/**
 * @generated SignedSource<<cdccc4bf71dc939db0f66a92df4fc66d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SupersedingSkillsSelect_Query$variables = {
  initiallySelectedIDs: ReadonlyArray<string>;
};
export type SupersedingSkillsSelect_Query$data = {
  readonly " $fragmentSpreads": FragmentRefs<"SupersedingSkillsSelect_skillsByIDs" | "SupersedingSkillsSelect_skillsForString">;
};
export type SupersedingSkillsSelect_Query = {
  response: SupersedingSkillsSelect_Query$data;
  variables: SupersedingSkillsSelect_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "initiallySelectedIDs"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "ids",
    "variableName": "initiallySelectedIDs"
  }
],
v2 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "id",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "nodeID",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SupersedingSkillsSelect_Query",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "SupersedingSkillsSelect_skillsForString"
      },
      {
        "args": (v1/*: any*/),
        "kind": "FragmentSpread",
        "name": "SupersedingSkillsSelect_skillsByIDs"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SupersedingSkillsSelect_Query",
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Literal",
            "name": "first",
            "value": 20
          },
          {
            "kind": "Literal",
            "name": "search",
            "value": ""
          }
        ],
        "concreteType": "QuerySkillsForStringConnection",
        "kind": "LinkedField",
        "name": "skillsForString",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Skill",
            "kind": "LinkedField",
            "name": "nodes",
            "plural": true,
            "selections": (v2/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": "skillsForString(first:20,search:\"\")"
      },
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Skill",
        "kind": "LinkedField",
        "name": "skillsByID",
        "plural": true,
        "selections": (v2/*: any*/),
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d487a059c4fd174309c20cf9ae6c395b",
    "id": null,
    "metadata": {},
    "name": "SupersedingSkillsSelect_Query",
    "operationKind": "query",
    "text": "query SupersedingSkillsSelect_Query(\n  $initiallySelectedIDs: [ID!]!\n) {\n  ...SupersedingSkillsSelect_skillsForString\n  ...SupersedingSkillsSelect_skillsByIDs_1SqFeW\n}\n\nfragment SupersedingSkillsSelect_skillsByIDs_1SqFeW on Query {\n  skillsByID(ids: $initiallySelectedIDs) {\n    id\n    name\n    nodeID\n  }\n}\n\nfragment SupersedingSkillsSelect_skillsForString on Query {\n  skillsForString(search: \"\", first: 20) {\n    nodes {\n      id\n      name\n      nodeID\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "185570bb87f9b298cb8627745a2f9d6e";

export default node;
