import BuildIcon from '@material-ui/icons/Build';

import TutorialConfigList from './TutorialConfigList';
import TutorialConfigEdit from './TutorialConfigEdit';
import TutorialConfigCreate from './TutorialConfigCreate';

export default {
  list: TutorialConfigList,
  edit: TutorialConfigEdit,
  icon: BuildIcon,
  create: TutorialConfigCreate,
};
