import PhotoLibrary from '@material-ui/icons/PhotoLibrary';

import MouthDiagramSetList from './MouthDiagramSetList';
import MouthDiagramSetEdit from './MouthDiagramSetEdit';
import MouthDiagramSetCreate from './MouthDiagramSetCreate';
import type { MouthDiagram } from '../mouthDiagram';

export type MouthDiagramSet = {
  id: string;
  publicName: string;
  internalName: string;
  adminNotes: string;
  mouthDiagrams: MouthDiagram[];
};

export default {
  list: MouthDiagramSetList,
  edit: MouthDiagramSetEdit,
  icon: PhotoLibrary,
  create: MouthDiagramSetCreate,
};
